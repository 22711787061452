import Database from '@/services/offline/database.js'
import cashDeskService from '@/services/cashDesks/cashDesks.service.js'

class CashDeskDatabase extends Database {
  constructor() {
    super()
  }

  _storeName() {
    return 'openedCashDesk'
  }

  saveOpenedCashDesk(cashDesk, operator, log) {
    let self = this
    return new Promise((resolve, reject) => {
      if (!cashDesk || !cashDesk.id) {
        reject(new Error("cash desk is not specified"))
        return
      } else if (!operator || !operator.id) {
        reject(new Error("operator is not specified"))
        return
      }

      this.dbPromise.then((db) => {
        const objectToStore = {
          ...cashDesk,
          operator: operator,
          cashDeskLog: log
        }

        db.put(self._storeName(), objectToStore, cashDesk.id)
        resolve(objectToStore)
      })
    })
  }

  checkOpenedCashDesk() {
    return new Promise((resolve, reject) => {
      this.getOpenedCashDesk().then((cashDesk) => {
        if (!cashDesk || !cashDesk.id) {
          resolve()
          return
        }
        cashDeskService.get(cashDesk.id).then((results) => {
          if (!results || results.status == 'closed') {
            this.clearOpenedCashDesk().then(() => {
              resolve()
            }).catch((error) => {
              reject(error)
            })
          } else {
            resolve(results)
          }
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getOpenedCashDesk() {
    return new Promise((resolve, reject) => {
      this.dbPromise.then((db) => {
        db.getAll(this._storeName()).then((results) => {
          resolve(results[0])
        }).catch((error) => {
          reject(error)
        })
      })
    })
  }

  clearOpenedCashDesk() {
    return new Promise((resolve, reject) => {
      this.dbPromise.then((db) => {
        db.clear(this._storeName()).then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    })
  }

  async clear() {
    const db = await this.dbPromise
    return await db.clear(this._storeName())
  }
}

export default new CashDeskDatabase()