<template>
  <div>
    <TitleWithBackButton title="Automazioni Marketing" back-route="Marketing" icon="mdi-graphql"></TitleWithBackButton>
    <v-container>
      <h2 class="text-left">Messaggi e Promemoria</h2>
      <v-row>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.weeklyReminder.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="weeklyReminder"
              settingKey="weeklyReminder"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.weeklyReminder.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.dailyReminder.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="dailyReminder"
              settingKey="dailyReminder"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.dailyReminder.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row v-if="viewGoals">
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.goalsDailyReminder.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="goalsDailyReminder"
              settingKey="goalsDailyReminder"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.goalsDailyReminder.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row v-if="viewGoals">
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.goalsWeeklyReminder.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="goalsWeeklyReminder"
              settingKey="goalsWeeklyReminder"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.goalsWeeklyReminder.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.automaticBirthdayMessage.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="automaticBirthdayMessage"
              settingKey="automaticBirthdayMessage"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.automaticBirthdayMessage.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row v-if="!!viewMarketingCalendar">
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.automaticReminderMarketingEvent.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="automaticReminderMarketingEvent"
              settingKey="automaticReminderMarketingEvent"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.automaticReminderMarketingEvent.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-divider class="mt-5 mb-3"></v-divider>
      <h2 class="text-left">Promozioni</h2>
      <v-row>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.showPromoNewCustomer.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="showPromoNewCustomer"
              settingKey="showPromoNewCustomer"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.showPromoNewCustomer.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.showPromoCustomer.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="showPromoCustomer"
              settingKey="showPromoCustomer"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.showPromoCustomer.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row>
        <v-card
          class=""
          flat
          color="transparent"
        >
          <v-card-title class="pb-0">{{$translations.t('settings.showPromoMinorCustomer.title')}}</v-card-title>
          <v-card-text class="text-left d-flex">
            <BooleanSetting
              :value="showPromoMinorCustomer"
              settingKey="showPromoMinorCustomer"
              @set="setSetting"
            ></BooleanSetting>
            <div class="pb-2">{{$translations.t('settings.showPromoMinorCustomer.description')}}</div>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import * as components from "../generalSettingComponents";
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "AutomationMarketing",
  components: { 
    ...components,
    TitleWithBackButton
  },
  data: function () {
    return {
      taxForBar: undefined,
      taxForSaloon: undefined,
      colorizeServiceSelector: undefined,
      colorizeOperatorSelector: undefined,
      weeklyReminder: undefined,
      showPromoNewCustomer: undefined,
      showPromoCustomer: undefined,
      showPromoMinorCustomer: undefined,
      automaticBirthdayMessage: undefined,
      checkCustomerGdpr: undefined,
      confirmLogout: undefined,
      dailyReminder: undefined,
      goalsDailyReminder: undefined,
      goalsWeeklyReminder: undefined,
      automaticReminderMarketingEvent: undefined,
      checkTimelogBarcode: undefined,
      viewBar: undefined,
      viewMarketingCalendar: undefined,
      viewGoals: undefined,
      emulateRtPrinter: undefined
    };
  },
  methods: {
    translator(settingKey) {
      return this.$translations.t("settings." + settingKey + ".title");
    },
    setSetting(key, value) {
      this.$settings.set(key, value)
    }
  },
  settings: {
    taxForBar: {
      bind: "taxForBar",
    },
    taxForSaloon: {
      bind: "taxForSaloon",
    },
    weeklyReminder: {
      bind: "weeklyReminder"
    },
    dailyReminder: {
      bind: "dailyReminder"
    },
    colorizeServiceSelector: {
      bind: "colorizeServiceSelector"
    },
    colorizeOperatorSelector: {
      bind: "colorizeOperatorSelector"
    },
    showPromoCustomer: {
      bind: "showPromoCustomer"
    },
    showPromoNewCustomer: {
      bind: "showPromoNewCustomer"
    },
    showPromoMinorCustomer: {
      bind: "showPromoMinorCustomer"
    },
    automaticBirthdayMessage: {
      bind: "automaticBirthdayMessage"
    },
    checkCustomerGdpr: {
      bind: "checkCustomerGdpr"
    },
    confirmLogout: {
      bind: "confirmLogout"
    },
    goalsDailyReminder: {
      bind: "goalsDailyReminder"
    },
    goalsWeeklyReminder: {
      bind: "goalsWeeklyReminder"
    },
    automaticReminderMarketingEvent: {
      bind: "automaticReminderMarketingEvent"
    },
    checkTimelogBarcode: {
      bind: "checkTimelogBarcode"
    },
    emulateRtPrinter: {
      bind: "emulateRtPrinter"
    }
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    },
    viewMarketingCalendar: {
      bind: "viewMarketingCalendar"
    },
    viewGoals: {
      bind: "viewGoals"
    },
  }
};
</script>

<style>
</style>