var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FullScreenDialog',{attrs:{"without-padding":"","title":"Modelli SMS/Whatsapp","route-father":_vm.routeFather},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center mb-3 flex-column",style:({
            'height': _vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg ? 
              '28%' : '40%',
            'width': '100%'
          })},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',[_c('AdvancedFilter',{staticStyle:{"width":"99%","height":"100%"},attrs:{"filters":_vm.filterTypes,"advance-filters":_vm.advanceFilters,"external-filter":_vm.externalFilter},on:{"apply-filters":_vm.applyFilters},scopedSlots:_vm._u([{key:"chip-custom-smsName",fn:function({filter}){return [_vm._v(" Nome: "+_vm._s(filter.value)+" ")]}},{key:"chip-custom-categories",fn:function({filter}){return [_vm._v(" Reparti: "+_vm._s(filter.value.map(el => el.name).join(', '))+" ")]}},{key:"chip-custom-smsText",fn:function({filter}){return [_vm._v(" Descrizione: "+_vm._s(filter.value)+" ")]}},{key:"custom-categories",fn:function({filter}){return [_c('ServiceTypesCategoryAutocomplete',{attrs:{"multiple":true,"return-object":true},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}},{key:"custom-smsText",fn:function({filter}){return [_c('v-text-field',{attrs:{"label":filter.label,"placeholder":filter.label,"outlined":true,"clearable":true,"rows":1,"dense":true,"filled":true,"hide-details":true,"max-height":100,"max-width":300,"min-height":100,"min-width":300},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}}]),model:{value:(_vm.filtersValue),callback:function ($$v) {_vm.filtersValue=$$v},expression:"filtersValue"}})],1)],1),_c('v-row',[_c('v-col',[_c('ResponsiveCardSelector',{attrs:{"items":_vm.goals,"item-key":"name","card-max-height":"80","card-min-height":"80","card-max-width":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.md) ? '130' : 
                _vm.$vuetify.breakpoint.lg ? '200' : '250',"card-min-width":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.md) ? '130' : 
                _vm.$vuetify.breakpoint.lg ? '200' : '250',"align-items-item":"center","justify-center":true,"colors":['#ada2e0'],"colorOnSelected":true},on:{"input":() => {
                _vm.fetchSmsModels()
              }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"text-center",staticStyle:{"font-size":"0.85rem"}},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}]),model:{value:(_vm.selectedGoal),callback:function ($$v) {_vm.selectedGoal=$$v},expression:"selectedGoal"}})],1)],1)],1),_c('div',{ref:"dataTableWrapper",style:({
          'height': _vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg ? 
            '70%' : '58%',
          'overflow-y': 'auto'
        })},[_c('TypeDataTable',{attrs:{"loading-text":"Caricamento modelli ...","headers":_vm.headers,"loading":_vm.loading,"items":_vm.paginatedSmsModelPresets,"page":_vm.page,"row-per-page":_vm.rowPerPage,"total-pages":_vm.totalPages,"height":_vm.dataTableHeight,"show-select":false,"show-actions":true},on:{"update:page":function($event){_vm.page=$event},"update:rowPerPage":function($event){_vm.rowPerPage=$event},"update:row-per-page":function($event){_vm.rowPerPage=$event},"update:totalPages":function($event){_vm.totalPages=$event},"update:total-pages":function($event){_vm.totalPages=$event}},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return (() => {
                  _vm.selectedSmsPreset = item
                  _vm.previewDialog = true
                }).apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}}])})],1)]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('StandardDialog',{attrs:{"dialog-height":null,"title":!!_vm.selectedSmsPreset ? _vm.selectedSmsPreset.name : '',"dialog-max-width":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '90%' : '50%'},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('div',{staticClass:"d-flex justify-center align-center mt-12"},[_c('SmsMobilePreviewForm',{attrs:{"width":"250px","height":"500px","sms-model":_vm.selectedSmsPreset,"send-time":!!_vm.selectedSmsPreset ? _vm.selectedSmsPreset.sendTime : undefined,"selected-image":!!_vm.selectedSmsPreset ? !!_vm.selectedSmsPreset.selectedImages &&
          _vm.selectedSmsPreset.selectedImages.length > 0 ? _vm.selectedSmsPreset.selectedImages[0] : undefined
          : undefined}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }