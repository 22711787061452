<template>
  <div class="d-flex flex-wrap align-center">
    <div
      :style="{
        'width': datePickerWidth
      }"
    >
      <DatePicker
        :solo="solo"
        :filled="filled"
        :label="label"
        v-model="localValue"
        :prependIcon="datePickerPrependIcon"
        :appendIcon="datePickerAppendIcon"
        :styles="styles"
        :hours="hours"
        :minutes= "minutes"
        :min="minForCommon"
        :max="max"
        :allowedDates="allowedDates"
        :readonly="readonly"
        :disabled="disabled"
        :rounded="datePickerRounded"
        :outlined="datePickerOutlined"
        :date-type="dateType"
      ></DatePicker>
    </div>
    <TimePicker
      v-if="showTime"
      :label="null"
      v-model="time"
      @input="handleTimeChange"
      :styles="styles"
      :disabled="disabled"
      :readonly="readonly"
    ></TimePicker>
  </div>
</template>

<script>
import DatePicker from './DatePickerCommon.vue'
import TimePicker from './TimePicker.vue'

export default {
  name: "DateTimePicker",
  components: {
    DatePicker, 
    TimePicker
  },
  data: function() {
    let time,hours,minutes;
    if(this.value) {
      time = ('0' + (this.value.getHours())).slice(-2) + ':' + ('0' + (this.value.getMinutes())).slice(-2)
      hours = parseInt(time.split(':')[0])
      minutes = parseInt(time.split(':')[1])

    }
    return {
      // should always be a date
      localValue: (!!this.rangeMode && !!this.startRange) ? new Date(this.handleRangeStartDate()) : this.value,
      // should always be a string with 'hh:mm'
      time: time,
      hours: hours,
      minutes: minutes,
      index: 0,
    }
  },
  props: {
    value: {
      type: Date,
      default: function() {
        new Date()
      }
    },
    bus: {
      type: Object,
      default: undefined
    },
    solo: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Date'
    },
    datePickerWidth: {
      type: String,
      default: "180px"
    },
    datePickerPrependIcon: {
      type: String,
      default: undefined
    },
    datePickerAppendIcon: {
      type: String,
      default: undefined
    },
    styles: {
      type: Object
    },
    min: {
      type: Date,
      default: undefined,
    },
    rangeMode: {
      type: Boolean,
      default: false,
    },
    startRange: {
      type: Date,
      default: undefined,
    },
    max: {
      type: String,
      default: undefined,
    },
    allowedDates: {
    },
    showTime: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false
    },
    datePickerRounded: {
      type: Boolean,
      default: true
    },
    datePickerOutlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dateType: {
      type: String,
      default: 'date'
    }
  },
  methods: {
    dateEqual(date1, date2) {
      if((date1 && !date2) || (date2 && !date1)) return false
      if(!date1 && !date2) return true

      let equal = true
      if(date1.getMonth() != date2.getMonth()) equal = false
      if(date1.getDate() != date2.getDate()) equal = false
      if(date1.getFullYear() != date2.getFullYear()) equal = false
      if(date1.getHours() != date2.getHours()) equal = false
      if(date1.getMinutes() != date2.getMinutes()) equal = false
      return equal
    },
    emit(event, ...params) {
      this.$emit(event, ...params)
      if (this.bus) {
        this.bus.$emit(event, ...params)
      }
    },
    handleTimeChange(time) {
      if(!!this.localValue) {
        this.hours = parseInt(time.split(':')[0])
        this.localValue.setHours(this.hours)
        this.minutes = parseInt(time.split(':')[1])
        this.localValue.setMinutes(this.minutes)

        this.emit('input', this.localValue)
      }
    },
    handleRangeStartDate() {
      let dateTemp = new Date(this.startRange)
      const offset = dateTemp.getTimezoneOffset()
      dateTemp = new Date(this.startRange.getTime() - (offset * 60 * 1000))
      if (!!this.value && this.value.getTime() > new Date(this.startRange).getTime())
        return this.value.toISOString()

      return dateTemp.toISOString().split('T')[0]
    }
  },
  computed: {
    minForCommon() {
      if (!!this.rangeMode && !!this.startRange) {
        return this.handleRangeStartDate()
      }
      else if (!!this.min)
        return this.min.toISOString()
    }
  },
  watch: {
    localValue(newVal) {
      if(!newVal) {
        this.emit('input', undefined)
      } else if(!this.dateEqual(this.localValue, this.value)) {
        this.emit('input', this.localValue)
      }
    },
    value(newVal) {
      this.localValue = newVal
      
      this.index++;

      if(newVal) {
        this.time = ('0' + (this.value.getHours())).slice(-2) + ':' + ('0' + (this.value.getMinutes())).slice(-2)
        this.hours = parseInt(this.time.split(':')[0])
        this.minutes = parseInt(this.time.split(':')[1])
      } else {
        this.time = ""
      }
    },
    minForCommon(newVal) {
      if (newVal && !!this.rangeMode && !!this.startRange) {
        let newValDate = new Date(newVal)
        if(!!this.showTime) {
          newValDate.setHours(this.value.getHours())
          newValDate.setMinutes(this.value.getMinutes())
        }
        this.localValue = newValDate
      }
    }
  }
}
</script>

<style>

</style>