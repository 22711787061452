<template>
  <div class="loading" :style="cssProps">
    <div class="dot" ></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    dimension: {
      type: String,
      default: "small",
    },
  },
  computed: {
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.defaults.light.primary,
      };
    },
  },
};
</script>

<style lang="scss" scoped >
.loading {
  $color: var(--primary-color);
  $opacities: 0.2, 0.4, 0.6, 0.8, 1;
  display: flex;

  .dot {
    position: relative;
    width: 0.6em;
    height: 0.6em;
    margin: 0.2em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 2s ease-out infinite;
    }

    background: var(--primary-color);
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        opacity: nth($opacities, $i);
        &::before {
          animation-delay: $i * 0.2s;
        }
      }
    }
  }
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}
</style>