import BalanceByTags from "@/components/areas/analytics/BalanceByTags.vue"
import CompareBalanceByTags from "@/components/areas/analytics/CompareBalanceByTags.vue"
import CustomersByFidelization from "@/components/areas/analytics/CustomersByFidelization.vue"
import CustomersTurnover from "@/components/areas/analytics/CustomersTurnover.vue"
import CustomersFrequencyList from "@/components/areas/analytics/CustomersFrequencyList.vue"
import CustomersByAgeGroup from "@/components/areas/analytics/CustomersByAgeGroup.vue"
import OperatorsBalance from "@/components/areas/analytics/OperatorsBalance.vue"
import Tags from "@/components/areas/analytics/Tags.vue"
import TagsCategories from "@/components/areas/analytics/TagsCategories.vue"
import TopSellingItems from "@/components/areas/analytics/TopSellingItems.vue"
import TimeRanges from "@/components/areas/analytics/TimeRangesProduction.vue"
import HistoryOperations from "@/components/areas/analytics/HistoryOperations.vue"
import ServiceConsume from "@/components/areas/analytics/ServiceConsume.vue"
import FinancialByTags from "@/components/areas/analytics/FinancialByTags.vue"
import WarehouseStatus from "@/components/areas/analytics/WarehouseStatus.vue"
import BistrotBalance from "@/components/areas/analytics/BistrotBalance.vue"
import BalanceByYear from "@/components/areas/analytics/BalanceByYear.vue"
import ServiceTypesBalance from "@/components/areas/analytics/ServiceTypesBalance.vue"
import GoalsBalance from "@/components/areas/analytics/GoalsBalance.vue"
import Analytics from "@/views/PrivateSection/Analytics.vue"

import licenseSettingsService from "../../services/licenseSettings/licenseSettings.service"

const analyticsRoutes = [
  {
    path: "/analytics/financialByTags",
    name: "FinancialByTags",
    component: FinancialByTags,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewFinancialByTags')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/balanceByTags",
    name: "BalanceByTags",
    component: BalanceByTags,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewBalanceByTags')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/compareBalanceByTags",
    name: "CompareBalanceByTags",
    component: CompareBalanceByTags,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewCompareBalanceByTags')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/customersByFidelization",
    name: "CustomersByFidelization",
    component: CustomersByFidelization,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewCustomersByFidelization')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/customersTurnover",
    name: "CustomersTurnover",
    component: CustomersTurnover,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewCustomersTurnover')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/customersFrequencyList",
    name: "CustomersFrequencyList",
    component: CustomersFrequencyList,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewCustomersFrequencyList')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/customersByAgeGroup",
    name: "CustomersByAgeGroup",
    component: CustomersByAgeGroup,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewCustomersByAgeGroup')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/operatorsBalance",
    name: "OperatorsBalance",
    component: OperatorsBalance,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewOperatorsBalance')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/tags",
    name: "Tags",
    component: Tags,
  },
  {
    path: "/analytics/tagsCategories",
    name: "TagsCategories",
    component: TagsCategories,
  },
  {
    path: "/analytics/topSellingItems",
    name: "TopSellingItems",
    component: TopSellingItems,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewTopSellingItems')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/timeRanges",
    name: "TimeRanges",
    component: TimeRanges,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewTimeRanges')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/historyOperations",
    name: "HistoryOperations",
    component: HistoryOperations,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewHistoryOperations')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/serviceConsume",
    name: "ServiceConsume",
    component: ServiceConsume,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewServiceConsume')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/warehouseStatus",
    name: "WarehouseStatus",
    component: WarehouseStatus,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewWarehouseStatus')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/bistrotBalance",
    name: "BistrotBalance",
    component: BistrotBalance,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewBistrotBalance')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/balanceByYear",
    name: "BalanceByYear",
    component: BalanceByYear,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewBalanceByYear')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/serviceTypesBalance",
    name: "ServiceTypesBalance",
    component: ServiceTypesBalance,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewServiceTypesBalance')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics/goalsBalance",
    name: "GoalsBalance",
    component: GoalsBalance,
    props: async (route) => {
      let setting = await licenseSettingsService.getFromCache('viewGoalsBalance')
      if(!setting.active)
        window.location.replace('/')
    }
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
  },
];
export default analyticsRoutes;
