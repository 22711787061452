<template>
  <StandardDialog
    v-model="localDialog"
    @input="$emit('update:dialog', $event)"
    :dialog-height="null"
    :title="title"
    dialog-max-width="600px"
  >
    <v-form v-model="localValid">
      <v-select
        v-model="localValue.absenceTypeId"
        :items="absenceTypeItems"
        item-value="id"
        item-text="name"
        filled
        class="mt-2"
        label="Tipologia Assenza"
        dense
        :rules="[presenceRule]"
        @input="$emit('input', valueToEmit)"
      ></v-select>
      <!--KEEP COMMENT
      <v-text-field
        v-model="localValue.description"
        filled
        class="mt-2"
        label="Descrizione"
        dense
        :rules="[presenceRule]"
        @input="$emit('input', valueToEmit)"
      ></v-text-field>-->
      <v-row>
        <v-col>
          <DateTimePickerCircular
            filled
            label="Inizio"
            v-model="localValue.start"
            :rules="[presenceRule]"
            @input="emitValueAndRuleChange"
          ></DateTimePickerCircular>
        </v-col>
        <v-col>
          <DateTimePickerCircular
            filled
            label="Fine"
            v-model="localValue.end"
            :range-mode="true"
            :start-range="localValue.start"
            :rules="[presenceRule]"
            @input="emitValueAndRuleChange"
          ></DateTimePickerCircular>
        </v-col>
      </v-row>
      <template v-if="showFrequencyOptions">
        <v-row class="mt-3 d-flex justify-center align-center">
          <v-radio-group
            v-model="ruleType"
            row
            @change="$emit('update:rule', localRule)"
          >
            <v-radio
              label="Una sola volta"
              value="once"
            ></v-radio>
            <v-radio
              label="Ripeti ogni giorno"
              value="daily"
            ></v-radio>
          </v-radio-group>
        </v-row>
        <v-row class="mt-2">
          <v-alert
            border="left"
            colored-border
            color="primary"
            elevation="2"
            style="width: 100%"
          >
            <div class="text-center">
              {{messages[ruleType]}}
            </div>
          </v-alert>
        </v-row>
      </template>
    </v-form>

    <template v-slot:header-actions>
      <v-btn icon @click="$emit('delete', valueToEmit)" v-if="showDelete">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </template>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn 
        @click="$emit('update:dialog', false)"
        text
        color="error"
      >Annulla</v-btn>
      <v-btn 
        @click="$emit('save', valueToEmit, localRule)"
        text
        :disabled="!computedValid || disabled"
        color="default"
      >Salva</v-btn>
    </template>
  </StandardDialog>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'
import DateTimePickerCircular from '@/components/common/DateTimePickerCircular.vue';
import absenceTypeService from '@/services/presences/absenceTypes/absenceTypes.service.js'

export default {
  name: "AbsenceForm",
  components: { 
    StandardDialog,
    DateTimePickerCircular
  },
  data: function() {
    return {
      localDialog: this.dialog,
      localValid: false,
      localValue: {...this.value},
      absenceTypeItems: [],
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      ruleType: 'once',
      messages: {
        once: "L'assenza verrà creata con inizio e fine coincidenti a quelli inseriti",
        daily: "L'assenza verrà creata in ogni giorno compreso tra quelli inseriti"
      },
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Assenza"
    },
    operator: {
      type: Object,
      default: () => ({})
    },
    rule: {
      type: Object,
      default: () => ({})
    },
    showFrequencyOptions: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.$emit('update:valid', this.localValid)
    if(this.operator) {
      this.localValue.operatorId = this.operator.id
    }
    this.fetchAbsenceTypes()
  },
  methods: {
    emitValueAndRuleChange() {
      this.$emit('update:rule', this.localRule)
      this.$emit('input', this.valueToEmit)
    },
    fetchAbsenceTypes() {
      absenceTypeService.list().then((results) => {
        this.absenceTypeItems = results.rows.map((at) => {
          at.id = at.id.toString()
          return at
        }); 
      })
    },
  },
  computed: {
    localRule() {
      return {
        type: this.ruleType,
        start: this.localValue.start,
        end: this.localValue.end,
      }
    },
    valueToEmit() {
      return {
        ...this.localValue,
        operatorId: !!this.operator ? this.operator.id : undefined
      }
    },
    computedValid() {
      const startPresent = !!this.localValue.start
      const endPresent = !!this.localValue.end
      const startLessThanEnd = startPresent && endPresent && (this.localValue.start < this.localValue.end)
      return this.localValid && startLessThanEnd
    }
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal
    },
    value(newVal) {
      this.$nextTick(() => {
        this.localValue = newVal
      })
    },
    operator(newVal) {
      if(!!newVal) {
        this.localValue.operatorId = newVal.id
      }
    }
  }
}
</script>

<style>

</style>