import suppliesNameMappings from "@/services/suppliers/suppliesNameMappings.js"
import paymentMethodsNameMappings from "@/services/suppliers/paymentMethodsNameMappings.js"
import statesNameMappings from "@/services/operators/statesNameMappings.js"
import currentUser from "@/services/currentUser.service"
import settingsService from "@/services/settings/settings.service.js"
import translationsService from "@/services/translations/translations.service.js"
import permissionService from "@/services/auth/permissions.service.js"
import cashDeskDatabase from '@/services/cashDesks/cashDesk.database.js'
import DevicesDatabase from '@/services/devices/devices.database'

import barcodeScannerDevice from "@/services/devices/barcodeScanner.device.js"
import cameraDevice from "@/services/devices/camera.device.js"

import EventsHandler from "@/services/common/eventsHandler.js"
import axios from "@/services/axios.js"
import licenseSettingsService from "./services/licenseSettings/licenseSettings.service"

class Initializer extends EventsHandler {
  constructor() {
    super()
    this.hasInitialize = false
    this.initializeFinished = false

    let accessDeniedHandler = async () => {
      this.hasInitialize = false
      await this._executeAsyncCallbacksOf('access-denied')
    }
    accessDeniedHandler = accessDeniedHandler.bind(this)
    axios.on('access-denied', accessDeniedHandler)

    let noResponseHandler = async () => {
      this.hasInitialize = false
      await this._executeAsyncCallbacksOf('no-response')
    }
    noResponseHandler = noResponseHandler.bind(this)
    axios.on('no-response', noResponseHandler)
  }

  initializeApp() {
    if(!this.hasInitialize) {
      return new Promise(async (resolve, reject) => {
        await this._executeAsyncCallbacksOf('loading')
        DevicesDatabase.createDatabase()
        Promise.all([
          suppliesNameMappings.options(),
          paymentMethodsNameMappings.options(),
          statesNameMappings.options(),
          currentUser.loadUser(),
          settingsService.load(),
          licenseSettingsService.load(),
          permissionService.load(),
          translationsService.loadLanguages(),
          barcodeScannerDevice.loadFavouriteDevice(),
          cameraDevice.loadFavouriteDevice(),
          cashDeskDatabase.checkOpenedCashDesk()
        ]).then(() => {
          this.initializeFinished = true

          this._executeAsyncCallbacksOf('loaded').finally(() => {
            this.hasInitialize = true
            resolve('completed')
          })
        }).catch((error) => {
          console.log(error)
          this.initializeFinished = true

          if(error.message == 'not-found') {
            this._executeAsyncCallbacksOf('not-found').finally(() => {
              resolve(error.message)
            })
          } else {
            this._executeAsyncCallbacksOf('crashed').finally(() => {
              resolve(error.message)
            })
          }
        })
      })
    } else {
      return Promise.resolve('completed')
    }
  }

  removeLoader() {
    return new Promise((resolve, reject) => {
      this.initializeFinished = true
      this._executeAsyncCallbacksOf('crashed').finally(() => {
        resolve()
      })
    })
  }
}

const initializer = new Initializer()
window.applicationInitializer = initializer
export default initializer