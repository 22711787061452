<template>
  <v-app>
    <v-app-bar
      v-if="drawerVisible && hasInitialized && !$vuetify.breakpoint.lgAndUp"
      class="back-color-gradient-app-bar"
      dark
      app
    >
      <v-app-bar-nav-icon @click.stop="handleHamburgerClick"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      class="back-color-gradient nav-drawer"
      v-if="drawerVisible && hasInitialized"
      v-model="drawer"
      :mini-variant.sync="mini"
      disable-resize-watcher
      touchless
      :permanent="$vuetify.breakpoint.lgAndUp"
      app
      width="170"
      height="100dvh"
      style="overflow-y: auto"
    >
      <template v-slot:prepend>
        <v-list-item class="px-2 mt-2">
          <v-list-item-title>
            <div
              class="text-center pt-2 pb-4"
            >
              <Clock></Clock>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-divider color="white"></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in customMenu"
            :key="item.title"
            link
            v-on:click="goToItemPage(item)"
          >
            <v-tooltip bottom :disabled="!mini">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon v-bind="attrs" v-on="on">
                  <div
                    v-if="item.title == 'Sparklet'"
                    class="d-flex justify-center align-center"
                    style="width: 100%"
                  >
                    <v-img
                      max-width="17"
                      src="./assets/S_Sparklet.png"
                    ></v-img>
                  </div>

                  <v-icon
                    v-else
                    color="white"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
              </template>
              <span color="white"> {{ item.title }}</span>
            </v-tooltip>

            <v-list-item-content>
              <v-list-item-title class="font-white">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>


      <template v-slot:append>
        <v-list dense>
          <v-list-item
            v-for="item in defaultMenu"
            :key="item.title"
            link
            v-on:click="goToItemPage(item)"
          >
            <v-tooltip bottom :disabled="!mini">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon v-bind="attrs" v-on="on">
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>
              </template>
              <span color="white"> {{ item.title }}</span>
            </v-tooltip>

            <v-list-item-content>
              <v-list-item-title class="h2 font-white">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list-item class="px-2 mt-2">
          <v-list-item-title>
            <div
              class="d-flex justify-center pb-4"
            >
              <v-img
                max-width="125"
                src="./assets/LOGO_LULUU_bianco.png"
              ></v-img>
            </div>
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <Chat
      v-if="drawerVisible && hasInitialized"
      icon-default="mdi-message-processing-outline"
      @show-barcode="openBarcode"
      @open="openClose"
      @logout="handleLogout"
      @close="openClose"
    ></Chat>
    <v-main
      :style="{
        background: backgroundColor,
        'pointer-events': pointerEvent,
        opacity: pointerEvent === 'all' ? '1' : '0.5',
      }"
      style="min-height: 100dvh;"
    >
      <div class="height: 100%">
        <router-view></router-view>
      </div>
      <v-dialog
        persistent
        :value="updateExists"
        :disable="!updateExists"
        :timeout="-1"
        :style="{display: 'flex', 'justify-content': 'center', 'align-items': 'center'}"
        width="fit-content"
      >
        <div
          class="update-content"
          style="display: flex; justify-content: space-between; align-items: center; gap: 20px; z-index: 100;"
        >
          <div>
            <span style="font-size: 16px; font-weight: bold; line-height: 2rem;">Un aggiornamento è disponibile</span><br>
            <span style="font-size: 13px;">
              In questo aggiornamento saranno aggiornate o modificate le voci che potete visualizzare a 
              <a 
                style="color: #fff; font-size: 13px;" 
                href="https://docs.google.com/document/d/1GTmUnbLNMW4xVS1WqI22dAGWcYYWVLlL_SA7cNOD5GA/edit?usp=drive_link" 
                target="_blank"
              > 
                questo link 
              </a>
            </span>
          </div>
          <div style="height: fit-content;"><v-btn text class="update-btn" @click="refreshApp" style="color: #fff"> Aggiorna </v-btn></div>
        </div>
      </v-dialog>
      <DevicesDialog v-if="hasInitialized"></DevicesDialog>
      <SnackBarer></SnackBarer>
      <WarningDialog></WarningDialog>
      <PrivilegesDialog></PrivilegesDialog>
      <StandardDialog
        v-model="showBarcodeDialog"
        persistent
        :dialog-height="null"
        dialog-max-width="500px"
      >
        <div v-if="loadingBarcodeOp" class="text-center mt-2">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <div v-if="!loadingBarcodeOp">
          <ManualBarcodeInput
            v-if="!checkTimelogBarcode 
              || (!!checkTimelogBarcode && !operator)"
            v-model="operatorBarcode"
            @confirm="handleConfirmBarcode"
          >
          </ManualBarcodeInput>
          <span
            class="d-flex"
            style="text-align: center; font-weight: 500; font-size: 16px"
            v-if="!resultCheckTimeLog.isOnTime && (checkTimelogBarcode && operator)"
          >
            {{translateStatusTimeLog[resultCheckTimeLog.status]}}. Compilare le note per proseguire
          </span>
          <div 
            v-if="checkTimelogBarcode && operator"
            class="d-flex justify-center align-center mt-2"
          >
            <OperatorAvatar
              class="mr-2"
              :value="operator"
            ></OperatorAvatar>
            <span>{{operator.firstname + " " + operator.lastname}}</span>
          </div>
          <v-textarea
            v-model="operatorBarcodeNote"
            filled
            label="Note"
            class="mt-2"
          >
          </v-textarea>
        </div>
        <template v-slot:footer-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="() => {
              operator = undefined
              showBarcodeDialog = false
            }"
          >Annulla</v-btn>
          <v-btn
            v-if="checkTimelogBarcode && operator"
            :disabled="!operatorBarcodeNote"
            text
            color="default"
            @click="badgeTimeLog"
          >Salva</v-btn>
        </template>
      </StandardDialog>
      <OperatorPresenceBadgeDialog
        :disabled="!showBarcodeDialog"
        ref="badgeDialog"
      ></OperatorPresenceBadgeDialog>
      
      <SimplePopUp
        v-model="showConfirmLogoutPopUp"
        :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '65vw' :
          $vuetify.breakpoint.xl ? '15vw' : 
          $vuetify.breakpoint.lg ? '25vw' : '35vw'"
        alert-text="Sei sicuro di voler uscire?"
        :dismissible="false"
      >
        <template v-slot:footer>
          <v-row class="pt-7 d-flex justify-space-around">
            <v-btn
              style="min-width: 30%;"
              color="error"
              @click="showConfirmLogoutPopUp = false"
            >
              No
            </v-btn>
            <v-btn
              style="min-width: 30%;"
              color="success"
              @click="logoutConfirmed"
            >
              Si
            </v-btn>
          </v-row>
        </template>
      </SimplePopUp>
    </v-main>
  </v-app>
</template>

<script>
import updateApp from "@/mixins/common/updateApp.js";
import DevicesDialog from "@/components/DevicesDialog.vue";
import initializer from "./initializer";
import SnackBarer from "@/components/common/SnackBarer.vue";
import WarningDialog from "@/components/common/WarningDialog.vue";
import PrivilegesDialog from "@/components/common/PrivilegesDialog.vue";
import ManualBarcodeInput from "@/components/common/ManualBarcodeInput.vue";
import OperatorPresenceBadgeDialog from "@/components/homepage/OperatorPresenceBadgeDialog.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import Clock from "@/components/common/Clock.vue";
import OperatorAvatar from '@/components/common/OperatorAvatar.vue';

import Chat from "@/components/common/Chat.vue";

import SimplePopUp from '@/components/common/SimplePopUp.vue'

import currentUser from "@/services/currentUser.service";
import authService from "@/services/auth/auth.service.js";
import operatorService from '@/services/operators/operators.service.js';
import timeLogService from '@/services/presences/timeLogs/timeLogs.service'

import allCache from "./services/all.cache";
import cashDeskDatabase from '@/services/cashDesks/cashDesk.database.js'
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import licenseSettingsService from "@/services/licenseSettings/licenseSettings.service";

export default {
  name: "App",
  components: {
    DevicesDialog,
    SnackBarer,
    PrivilegesDialog,
    WarningDialog,
    StandardDialog,
    ManualBarcodeInput,
    OperatorPresenceBadgeDialog,
    Clock,
    Chat,
    SimplePopUp,
    OperatorAvatar
  },
  mixins: [updateApp],
  data: function () {
    let date = new Date();
    let initialClock =
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2);

    return {
      currentUser: undefined,
      drawerVisible: false,
      drawer: this.$vuetify.breakpoint.lgAndUp,
      darkSetting: false,
      colorizeAppSelector: undefined,
      viewBar: undefined,
      hasInitialized: false,
      defaultMenu: [
        {
          title: "Timbrature",
          icon: "mdi-barcode",
        },
        {
          title: "Anagrafiche",
          icon: "mdi-book",
          route: "Registries",
        },
        {
          title: "Presenze",
          icon: "mdi-account-clock",
          route: "Presence",
        },
        {
          title: "Marketing",
          icon: "mdi-shopping",
          route: "Marketing",
        },
        {
          title: "Magazzino",
          icon: "mdi-warehouse",
          route: "Warehouse",
        },
        {
          title: "Pagamenti",
          icon: "mdi-cash-check",
          route: "Payments",
        },
        {
          title: "Analisi Dati",
          icon: "mdi-chart-areaspline",
          route: "Analytics",
        },
        {
          title: "Impostazioni",
          icon: "mdi-cog",
          route: "Settings",
        },
        {
          title: "Logout",
          icon: "mdi-logout",
        },
      ],
      mini: false,
      clock: initialClock,
      showBarcodeDialog: false,
      operator: undefined,
      operatorBarcode: undefined,
      pointerEvent: "all",
      operatorBarcodeNote: undefined,
      confirmLogout: undefined,
      translateStatusTimeLog: {
        delay: 'Entrata in ritardo',
        earlyExit: 'Uscita in anticipo',
        overtime: 'Effettuato straordinario'
      },
      showConfirmLogoutPopUp: false,
      overtimeThreshold: undefined,
      checkTimelogBarcode: undefined,
      resultCheckTimeLog: false,
      loadingBarcodeOp: false
    };
  },
  beforeCreate: function () {},
  mounted: function () {
    let self = this;
    currentUser.getUser().then((user) => {
      self.currentUser = user;
    });

    currentUser.on("change", function (user) {
      self.currentUser = user;
    });

    this.hasInitialized = initializer.hasInitialize;
    initializer.on("loaded", () => {
      this.hasInitialized = true;
    });

    console.log("Luluu, all right reserved");

    localStorage.setItem('use-promo-bills', JSON.stringify([]))
  },
  methods: {
    openClose(value) {
      this.pointerEvent = value;
    },
    openBarcode () {
      console.log("test")
      this.showBarcodeDialog = true
    },
    goToItemPage(item) {
      if (item.title == "Logout") {
        this.handleLogout();
      } else if (item.title == "Timbrature") {
        this.showBarcodeDialog = true;
      } else if (item.title == "Sparklet") {
        const sparkletLink = 'https://sparklet.it/'
        window.open(sparkletLink, '_blank')
      } else {
        if (item.route) {
          this.goTo(item.route);
        }
      }
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    goBack() {
      if (this.backRoute) {
        this.$router.push({ name: this.backRoute });
      } else {
        this.$router.go(-1);
      }
    },
    async handleLogout() {
      let openedCashDesk = await cashDeskService.getOpened()
      if(!!openedCashDesk){
        this.$delegates.snackbar('Chiudere la cassa prima di fare Logout')
        return
      }

      if (!!this.confirmLogout)
        this.showConfirmLogoutPopUp = true
      else
        await this.logoutConfirmed()

    },
    async logoutConfirmed() {
      this.showConfirmLogoutPopUp = false

      await allCache.clear();
      localStorage.clear()
      cashDeskDatabase.clear()
      licenseSettingsService.clear()
    
      authService
        .logout()
        .then(() => {
          this.$router
            .push({ name: "Login", params: {} })
            .then(() => {})
            .catch(() => {});
        })
        .catch(() => {});
    },
    async badgeTimeLog() {
      this.$refs.badgeDialog.manageManualScan(this.operatorBarcode, this.operatorBarcodeNote);
      this.operator = undefined
      this.operatorBarcodeNote = undefined;
      this.operatorBarcode = "";
      this.showBarcodeDialog = false;
    },
    async handleConfirmBarcode() {
      if (!!this.checkTimelogBarcode) {
        this.loadingBarcodeOp = true
        this.operator = await operatorService.getByBarcode(this.operatorBarcode)
        this.resultCheckTimeLog = await timeLogService.checkOperatorTimeLog(this.operator)
        if (!!this.resultCheckTimeLog.isOnTime) {
          await this.badgeTimeLog()
        }
        this.loadingBarcodeOp = false
      }
      else {
        await this.badgeTimeLog()
      }
    },
    handleHamburgerClick() {
      this.drawer = !this.drawer
    }
  },
  computed: {
    backgroundColor() {
      return this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes.dark.background
        : this.$vuetify.theme.themes.light.background;
    },
    customMenu() {
      let menus = [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "Dashboard",
        },
        {
          title: "Beauty",
          icon: "mdi-home",
          route: "Homepage",
        },
      ]

      if(!!this.viewBar.active)
        menus.push({
          title: "Ristorante",
          icon: "mdi-glass-cocktail",
          route: "Bar",
        })

      menus.push(
        {
          title: "Agenda",
          icon: "mdi-calendar-blank-multiple",
          route: "Calendar",
        },
      )

      menus.push(
        {
          title: "Sparklet",
          icon: "mdi-gift",
        },)

      return menus
    }
  },
  watch: {
    $route: function (from) {
      let routeToCheck = from;

      if (routeToCheck.name != "Login" && routeToCheck.name != "Registration") {
        this.drawerVisible = true;
      } else {
        this.drawerVisible = false;
      }
    },
    darkSetting(newVal) {
      this.$vuetify.theme.dark = newVal;
    },
    colorizeAppSelector(newVal) {
      this.$vuetify.theme.colorizeAppSelector = newVal;
    },
  },
  settings: {
    dark: {
      bind: "darkSetting",
    },
    colorizeAppSelector: {
      bind: "colorizeAppSelector",
    },
    confirmLogout: {
      bind: "confirmLogout"
    },
    checkTimelogBarcode: {
      bind: "checkTimelogBarcode"
    },
    overtimeThreshold: {
      bind: "overtimeThreshold"
    }
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    }
  }
};
</script>

<style>

.back-color-gradient {
  background-image: linear-gradient(#8a77e2, #50bfe1);
}

.back-color-gradient-app-bar {
  background-image: linear-gradient(#8a77e2, #8a77e2);
  z-index: 10 !important;
}

.nav-drawer {
  z-index: 15 !important;
}

.font-white {
  color: white;
}

html {
  overflow-y: auto;
}

.update-content {
  background-image: linear-gradient(rgb(139, 119, 226), rgb(80, 191, 225));
  color: #fff;
  padding: 20px;
  min-width: 450px;
  max-width: 600px;
}

.update-btn {
  border: 1px solid white;
}

@media screen and (max-width: 500px) {
  .update-content {
    max-width: 100%;
    min-width: auto;
  }
}
</style>
