<template>
  <div class="">
    <FullScreenDialog
      v-model="openDialog"
      title="Impostazioni Procacciatori D'Affari"
      :routeFather="pathToGoBack"
    >
      <template v-slot:content>
        <div>
          <v-subheader>Prodotti ignorati dal procacciatore</v-subheader>
          <div class="d-flex">
            <v-slide-y-transition>
              <v-btn
                class="my-2 ml-3"
                outlined
                color="error"
                v-if="!!itemsToRemove && itemsToRemove.length > 0"
                @click="removeItems"
                :loading="loadingItemsDelete"
              >
                <v-icon>mdi-delete</v-icon>
                Rimuovi selzionati
              </v-btn>
            </v-slide-y-transition>
            <v-spacer></v-spacer>
            <v-btn
              @click="addItemsDialog = true"
              color="primary"
              class="my-2"
              :disabled="loadingItemsAdd"
            >
              <v-icon>mdi-plus</v-icon>
              Aggiungi prodotto
            </v-btn>
          </div>
          <v-data-table
            item-key="id"
            :items="ignoredItems"
            disable-pagination
            hide-default-footer
            show-select
            v-model="itemsToRemove"
            :headers="ignoredItemsHeader"
            :loading="loadingItemsFetch"
            height="50vh"
            fixed-header
          ></v-data-table>
          <v-subheader>Servizi ignorati dal procacciatore</v-subheader>
          <div class="d-flex">
            <v-slide-y-transition>
              <v-btn
                class="my-2 ml-3"
                outlined
                color="error"
                v-if="!!servicesToRemove && servicesToRemove.length > 0"
                @click="removeServices"
                :loading="loadingItemsDelete"
              >
                <v-icon>mdi-delete</v-icon>
                Rimuovi selzionati
              </v-btn>
            </v-slide-y-transition>
            <v-spacer></v-spacer>
            <v-btn
              @click="addServicesDialog = true"
              color="primary"
              class="my-2"
              :disabled="loadingServicesAdd"
            >
              <v-icon>mdi-plus</v-icon>
              Aggiungi servizio
            </v-btn>
          </div>
          <v-data-table
            item-key="id"
            :items="ignoredServices"
            disable-pagination
            hide-default-footer
            show-select
            v-model="servicesToRemove"
            :headers="ignoredServicesHeader"
            :loading="loadingServicesFetch"
            height="50vh"
            fixed-header
          ></v-data-table>
        </div>
      </template>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="$router.push({path: pathToGoBack})"
        >Chiudi</v-btn>
      </template>
    </FullScreenDialog>

    <StandardDialog
      v-model="addItemsDialog"
      @close="addItemsDialog = false"
      dialog-width="80vw"
      dialog-height="85vh"
      title="Aggiungi prodotto"
    >
      <ItemsTable
        table-height="60vh"
        :headers="[
          { text: 'Barcode', value: 'code' },
          { text: 'Codice Interno', value: 'internalCode' },
          { text: 'Descrizione', value: 'description'}
        ]"
        selectable
        :show-actions="false"
        :selection.sync="itemsToAdd"
      ></ItemsTable>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="addItemsDialog = false"
        >
          Chiudi
        </v-btn>
        <v-btn
          color="default"
          text
          @click="addItems"
          :loading="loadingItemsAdd"
        >
          Aggiungi
        </v-btn>
      </template>
    </StandardDialog>


    <StandardDialog
      v-model="addServicesDialog"
      @close="addServicesDialog = false"
      dialog-width="80vw"
      dialog-height="85vh"
      title="Aggiungi servizio"
    >
      <ServicesTable
        table-height="70vh"
        selectable
        :show-actions="false"
        :selection.sync="servicesToAdd"
      ></ServicesTable>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="addServicesDialog = false"
        >
          Chiudi
        </v-btn>
        <v-btn
          color="default"
          text
          @click="addServices"
          :loading="loadingServicesAdd"
        >
          Aggiungi
        </v-btn>
      </template>
    </StandardDialog>
  </div>

</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import ItemsTable from "@/components/common/wharehouse/ItemsTable.vue";
import ServicesTable from "@/components/common/registry/ServicesTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import IntermediaryService from '@/services/intermediary/intermediary.service.js'

export default {
  name: "IntermediarySettings",
  components: {
    FullScreenDialog,
    ItemsTable,
    ServicesTable,
    StandardDialog
  },
  data: function () {
    return {
      openDialog: true,
      addItemsDialog: false,
      loadingItemsAdd: false,
      loadingItemsDelete: false,
      loadingItemsFetch: false,
      itemsToAdd: [],
      itemsToRemove: [],
      ignoredItems: [],
      ignoredServices: [],
      ignoredItemsHeader: [
        {text: 'Codice', value: 'code'},
        {text: 'Codice Interno', value: 'internalCode'},
        {text: 'Descrizione', value: 'description'}
      ],


      addServicesDialog: false,
      loadingServicesAdd: false,
      loadingServicesDelete: false,
      loadingServicesFetch: false,
      servicesToAdd: [],
      servicesToRemove: [],
      ignoredServicesHeader: [
        {text: 'Nome', value: 'name'},
        {text: 'Descrizione', value: 'description'},
      ],
    };
  },
  props: {
    pathToGoBack: {
      type: String,
      default: "/marketing"
    },
  },
  mounted: function () {
    this.fetchIgnoredItemsAndServices()
  },
  methods: {
    fetchIgnoredItemsAndServices() {
      this.loadingItemsFetch = true
      IntermediaryService.ignoredItemsAndServices().then((result) => {
        this.ignoredItems = result.items.map((i) => {
          return {
            id: i.id,
            itemId: i.itemId,
            code: i.item.code,
            internalCode: i.item.internalCode,
            description: i.item.description,
          }
        })
        this.ignoredServices = result.services.map((i) => {
          return {
            id: i.id,
            serviceId: i.serviceId,
            name: i.service.name,
            description: i.service.description,
          }
        })

        this.loadingItemsFetch = false
      })
    },
    addItems() {
      this.loadingItemsAdd = true

      let items = this.itemsToAdd.map((i) => {
        return {
          id: i.id
        }
      })

      IntermediaryService.addIgnoredItems(items).then(() => {
        this.addItemsDialog = false
        this.loadingItemsAdd = false
        this.itemsToAdd = []
        this.fetchIgnoredItemsAndServices()
      })
    },
    removeItems() {
      this.loadingItemsDelete = true

      let items = this.itemsToRemove.map((i) => {
        return {
          id: i.itemId
        }
      })

      IntermediaryService.removeIgnoredItems(items).then(() => {
        this.loadingItemsDelete = false
        this.itemsToRemove = []
        this.fetchIgnoredItemsAndServices()
      })
    },
    addServices() {
      this.loadingServicesAdd = true

      let services = this.servicesToAdd.map((i) => {
        return {
          id: i.id
        }
      })

      IntermediaryService.addIgnoredServices(services).then(() => {
        this.addServicesDialog = false
        this.loadingServicesAdd = false
        this.servicesToAdd = []
        this.fetchIgnoredItemsAndServices()
      })
    },
    removeServices() {
      this.loadingServicesDelete = true

      let services = this.servicesToRemove.map((i) => {
        return {
          id: i.serviceId
        }
      })

      IntermediaryService.removeIgnoredServices(services).then(() => {
        this.loadingServicesDelete = false
        this.servicesToRemove = []
        this.fetchIgnoredItemsAndServices()
      })
    },
  },
  watch: {
  }
};
</script>

<style>
</style>



