const hairTypesRoutes = [
  {
    path: "/registries/hairTypes",
    name: "HairTypesRegistryNewForm",
    component: () => import("@/components/areas/registries/hairTypes/HairTypesRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
  },
  {
    path: "/registries/hairTypes/:id/edit",
    name: "HairTypesRegistryEditForm",
    component: () => import("@/components/areas/registries/hairTypes/HairTypesRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
  },
  {
    path: "/registries/hairTypes/list",
    name: "HairTypesRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/hairTypes/HairTypesRegistryList.vue"
      ),
  }
];

export default hairTypesRoutes;