import axios from "axios";
import CookieService from "@/services/cookies.service";
import EventsHandler from "@/services/common/eventsHandler"
import FileDownload from 'downloadjs'

class CubeAxios extends EventsHandler {
  constructor() {
    super()
  }

  get(url, params) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {
        axios
          .get(url, { params: params, headers: headers })
          .then((response) => {
            resolve(response.data, response);
          })
          .catch((error) => {
            if (!error.response) {
              this._executeAsyncCallbacksOf('no-response').then(() => {
              }).finally(() => {
                reject(new Error('no-response'));
              })
            } else if (error.response.data.message == "access denied" || error.response.data.message == "token or user not known") {
              this._executeAsyncCallbacksOf('access-denied').then(() => {
              }).finally(() => {
                reject(new Error('access-denied'));
              })
            }
          });
      })
    });
  }

  post(url, params) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {
        axios
          .post(url, params, { headers: headers })
          .then((response) => {
            resolve(response.data, response);
          })
          .catch((error) => {
            if (!error.response) {
              this._executeAsyncCallbacksOf('no-response').then(() => {
              }).finally(() => {
                reject(new Error('no-response'));
              })
            } else if (error.response.data.message == "access denied" || error.response.data.message == "token or user not known") {
              this._executeAsyncCallbacksOf('access-denied').then(() => {
              }).finally(() => {
                reject(new Error('access-denied'));
              })
            }
          });
      })
    });
  }

  async _buildHeaders() {
    return {
      authorization: CookieService.get('user-id') + ':' + CookieService.get('auth-token')
    }
  }
}

export default new CubeAxios();
