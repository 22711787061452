<template>
  <div id="chart">
    <TitleWithBackButton
      title="Prodotti Best Seller"
      back-route="Analytics"
    ></TitleWithBackButton>
    <div class="d-flex align-center ml-3 mt-3 flex-wrap">
      <RangeDateSelector
        :start-date.sync="startDate"
        :end-date.sync="endDate"
        :loading="loading"
        :disabled="loading"
        width="50vw"
      ></RangeDateSelector>
      <SuppliersAutocomplete
        class="ml-1"
        :class="{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
          || this.$vuetify.breakpoint.md}"
        :style="{
          'margin-top': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
            || this.$vuetify.breakpoint.md ? '10px' : '0px'
        }"
        v-model="supplier"
        :return-object="true"
        :auto-detect-new-supplier="false"
      ></SuppliersAutocomplete>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="calculate"
        :loading="loading"
        :disabled="!startDate || !endDate"
      >
        Calcola</v-btn
      >
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="print"
        :loading="loading"
        :disabled="!startDate || !endDate"
      >
        <v-icon> mdi-printer </v-icon></v-btn
      >
      <v-spacer></v-spacer>
      <v-btn 
        icon 
        large
        style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!ghost ? 'red' : ''"
      >
      <v-icon>mdi-shield-account-variant</v-icon>
    </v-btn>
    </div>
    <div class="d-flex justify-center mt-10">
      <GroupBasedChart
        type="bar"
        height="70vh"
        width="85vw"
        :colors="colors"
        :point-colors="borderColors"
        :getYValue="
          (object) => {
            return object[`BillItems.quantity`];
          }
        "
        :getXValue="
          (object) => {
            return object[`Items.description`];
          }
        "
        :tooltip-label-modifier="
          (tooltipLabel) => {
            return 'Quantità: ' + tooltipLabel.yLabel;
          }
        "
        :x-axes-modifier="xAxes"
        :data="[data]"
        :loading="loading"
      ></GroupBasedChart>
    </div>
    <v-container fluid class="mt-10 pt-10">
      <v-row class="text-h4 font-weight-bold pl-3 pb-3">
        Tutti i prodotti venduti
      </v-row>
      <v-row>
        <v-col 
          lg="4"
          xl="4"
          md="12" 
          cols="12"
          sm="12"   
          class="d-flex justify-center align-start"
        >
          <DynamicContentTable
            id="table"
            v-if="!loading && groupedRows1.length > 0"
            :headers="headers"
            headers-key="value"
            headers-text="text"
            headers-width="minWidth"
            headers-color="#C4BBF0"
            :table-content-width="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? null : '98dvw'"
            :content-rows="groupedRows1"
            content-rows-key="itemId"
            :results-for-loading="!items"
            :loading="loading"
            :show-load="false"
            :pagination="false"
          >
          </DynamicContentTable>
          <div
            v-else-if="!loading && groupedRows1.length == 0"
            class="text-center font-weight-light mt-2"
          >
            Nessun risultato
          </div>
          <v-progress-circular v-else indeterminate></v-progress-circular>
        </v-col>
        <v-col 
          lg="4"
          xl="4"
          md="12" 
          cols="12"
          sm="12"           
          class="d-flex justify-center align-start"
        >
          <DynamicContentTable
            id="table"
            v-if="!loading && groupedRows2.length > 0"
            :headers="headers"
            headers-key="value"
            headers-text="text"
            headers-width="minWidth"
            headers-color="#C4BBF0"
            :table-content-width="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl 
              ? null : '98dvw'"
            :content-rows="groupedRows2"
            content-rows-key="itemId"
            :results-for-loading="!items"
            :loading="loading"
            :show-load="false"
            :pagination="false"
          >
          </DynamicContentTable>
          <div
            v-else-if="!loading && groupedRows2.length == 0"
            class="text-center font-weight-light mt-2"
          >
            
          </div>
          <v-progress-circular v-else indeterminate></v-progress-circular>
        </v-col>
        <v-col 
          lg="4"
          xl="4"
          md="12" 
          cols="12"
          sm="12"  
          class="d-flex justify-center align-start"
        >
          <DynamicContentTable
            id="table"
            v-if="!loading && groupedRows3.length > 0"
            :headers="headers"
            headers-key="value"
            headers-text="text"
            headers-width="minWidth"
            headers-color="#C4BBF0"
            :table-content-width="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl 
              ? null : '98dvw'"
            :content-rows="groupedRows3"
            content-rows-key="itemId"
            :results-for-loading="!items"
            :loading="loading"
            :show-load="false"
            :pagination="false"
          >
          </DynamicContentTable>
          <div
            v-else-if="!loading && groupedRows3.length == 0"
            class="text-center font-weight-light mt-2"
          >
            
          </div>
          <v-progress-circular v-else indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice"
        @confirm="activeBarcode"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import RangeDateSelector from "@/components/areas/analytics/RangeDateSelector.vue";
import TitleWithBackButton from "@/components/common/TitleWithBackButton.vue";
import balanceService from "@/services/balance/balance.service.js";
import GroupBasedChart from "@/components/charts/GroupBasedChart.vue";
import DynamicContentTable from "@/components/common/DynamicContentTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import operatorService from '@/services/operators/operators.service.js'
import html2canvas from "@/assets/js/html2canvas.min.js";
import * as XLSX from "xlsx";

export default {
  name: "TopSellingItems",
  components: {
    RangeDateSelector,
    TitleWithBackButton,
    GroupBasedChart,
    DynamicContentTable,
    ManualBarcodeInput,
    StandardDialog,
    SuppliersAutocomplete
  },
  data: () => {
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);

    var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    lastDay.setHours(0);
    lastDay.setMinutes(0);

    return {
      startDate: firstDay,
      endDate: lastDay,
      loading: false,
      data: {},
      items: [],
      ghost: false,
      supplier: {},
      dialogBarcode: false,
      operatorBarcode: undefined,
      colorSet: ['rgba(138,119,226,0.5)', 'rgba(99,143,239,0.5)', 'rgba(63,162,241,0.5)', 'rgba(54,178,235,0.5)', 'rgba(80,191,225,0.5)'],
      borderColorSet: ['rgba(138,119,226,1)', 'rgba(99,143,239,1)', 'rgba(63,162,241,1)', 'rgba(54,178,235,1)', 'rgba(80,191,225,1)'],
      colors: [],
      borderColors: [],
      xAxes: [{
        ticks: {
          callback: function(label) {
            if(label.length > 18)
              return label.slice(0, 18) + '...';
            else
              return label;
          }
        },
      }],
      fileName: ""
    };
  },
  mounted: function() {
    this.calculate()
  },
  methods: {
    exportToExcel() {
      let workbook = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws, [
        [
          !!this.supplier ? this.supplier.businessName : "Tutti i fornitori"
        ],
        [],
        [
          "Posizione",
          "Nome Prodotto",
          "Quantità Venduta"
        ],
        ...this.groupedRows1.map((el) => {
          return [
            !!el.position ? el.position : "",
            el.itemName,
            el.soldQuantity
          ]
        }),
        ...this.groupedRows2.map((el) => {
          return [
            !!el.position ? el.position : "",
            el.itemName,
            el.soldQuantity
          ]
        }),
        ...this.groupedRows3.map((el) => {
          return [
            !!el.position ? el.position : "",
            el.itemName,
            el.soldQuantity
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws, "Prodotti Best Seller");

      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    async calculate() {
      this.loading = true;

      this.items = await balanceService.getTopSellingItems({
        from: this.startDate,
        to: this.endDate,
        ghost: this.ghost,
        supplierId: !!this.supplier ? this.supplier.id : undefined
      });
      
      this.fileName = "Prodotti_Best_Seller_da_" + this.startDate.toLocaleDateString('it-IT') + "_a_" + this.endDate.toLocaleDateString('it-IT') + ".xls";

      let color = [];
      let items = this.items.slice(0, 10);

      for(let i = items.length; i < 10; i++){
        items.push({
          'Items.id': 'Void_' + i,
          'Items.description': 'Vuoto ' + (i + 1),
          'BillItems.quantity': 0
        })
      }

      for (let i = 0; i < this.items.length; i++) {
        let idx = Math.floor(i / 2)
        color.push({
          backgroundColor: this.colorSet[idx % this.colorSet.length],
          borderColor: this.borderColorSet[idx % this.borderColorSet.length],
        });
      }
      this.colors = color.map((c) => c.backgroundColor);
      this.borderColors = color.map((c) => c.borderColor);

      this.data = {
        name: "Prodotti più venduti",
        label: "Prodotti più venduti",
        data: items,
      };

      this.loading = false;
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost
          this.calculate()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    print() {
      let id = 'chart'
      this.printLoading = true;

      html2canvas(document.getElementById(id), { scale: 2 })
        .then((canvas) => {
          let a = document.createElement("a");
          a.download =
            "Prodotti_Best_Seller_" +
            this.startDate.getDate() +
            "-" +
            this.startDate.getMonth() +
            ".png";
          a.href = canvas.toDataURL("image/png");
          a.click(); // MAY NOT ALWAYS WORK!
          this.printLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.printLoading = false;
        });
    },
  },
  computed: {
    groupedRows1() {
      if (!this.items || this.items.length == 0) {
        return [];
      }

      let results = [];

      for (let i = 0; i < Math.ceil(this.items.length / 3); i += 1) {
        results.push({
          itemId: this.items[i]["Items.id"],
          itemName: this.items[i]["Items.description"],
          soldQuantity: this.items[i]["BillItems.quantity"],
          position: i < 50? i + 1 : undefined,
        });
      }

      return results;
    },
    groupedRows2() {
      if (!this.items || this.items.length < 2) {
        return [];
      }

      let results = [];

      for (let i = Math.ceil(this.items.length / 3); i < Math.ceil(this.items.length / 3) * 2; i += 1) {
        results.push({
          itemId: this.items[i]["Items.id"],
          itemName: this.items[i]["Items.description"],
          soldQuantity: this.items[i]["BillItems.quantity"],
          position: i < 50? i + 1 : undefined,
        });
      }

      return results;
    },
    groupedRows3() {
      if (!this.items || this.items.length < 3) {
        return [];
      }

      let results = [];

      for (let i = Math.ceil(this.items.length / 3) * 2; i < this.items.length; i += 1) {
        results.push({
          itemId: this.items[i]["Items.id"],
          itemName: this.items[i]["Items.description"],
          soldQuantity: this.items[i]["BillItems.quantity"],
          position: i < 50? i + 1 : undefined,
        });
      }

      return results;
    },
    headers() {
      if (!this.items || this.items.length == 0) return [];
      else
        return [
          {
            value: 'position',
            text: 'Posizione',
          },
          {
            value: "itemName",
            text: "Nome Prodotto",
            minWidth: "200px",
          },
          {
            value: "soldQuantity",
            text: "Quantità Venduta",
            minWidth: "80px",
          },
        ];
    },
  },
};
</script>