import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import DateHandler from "@/services/common/dateHandler";

class PaymentCollectedService {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentCollected/list', {
        page, rowPerPage, filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }


  detailList(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentCollected/detailList', {
        page, rowPerPage, filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  fields(ghost = false) {
    if (ghost)
      return Promise.resolve([
        { text: 'Cliente', value: 'customer', type: 'custom' },
        { text: 'Tipo Transazione', value: 'typeTransaction', type: 'custom' },
        { text: 'Numero Scontrino', value: 'documentNumber', type: 'custom' },
        { text: 'Assegno', value: 'checkPaid', type: 'custom' },
        { text: 'Contanti', value: 'cashPaid', type: 'custom' },
        { text: 'Pagamento Elettronico', value: 'cardPaid', type: 'custom' },
        { text: 'Altro Metodo', value: 'otherPaid', type: 'custom' },
        { text: 'Pagamento Con Tessera', value: 'customerCardPaid', type: 'custom' },
        { text: 'Concordato', value: 'ghostPaid', type: 'custom' },
        { text: 'Totale', value: 'total', type: 'custom' },
        { text: 'Chiuso Il', value: 'date', type: 'datetime' },
        { text: 'Servizi', value: 'services', type: 'custom' },
        { text: 'Prodotti', value: 'items', type: 'custom' },
        { text: 'Operatore Cassa', value: 'cashDeskOperatorName', type: 'custom' },
      ])

    return Promise.resolve([
      { text: 'Cliente', value: 'customer', type: 'custom' },
      { text: 'Tipo Transazione', value: 'typeTransaction', type: 'custom' },
      { text: 'Numero Scontrino', value: 'documentNumber', type: 'custom' },
      { text: 'Assegno', value: 'checkPaid', type: 'custom' },
      { text: 'Contanti', value: 'cashPaid', type: 'custom' },
      { text: 'Pagamento Elettronico', value: 'cardPaid', type: 'custom' },
      { text: 'Altro Metodo', value: 'otherPaid', type: 'custom' },
      { text: 'Pagamento Con Tessera', value: 'customerCardPaid', type: 'custom' },
      { text: 'Totale', value: 'total', type: 'custom' },
      { text: 'Chiuso Il', value: 'date', type: 'datetime' },
      { text: 'Servizi', value: 'services', type: 'custom' },
      { text: 'Prodotti', value: 'items', type: 'custom' },
      { text: 'Operatore Cassa', value: 'cashDeskOperatorName', type: 'custom' },
    ])
  }

  detailsFields(ghost = false) {
    return Promise.resolve([
      { text: 'Cliente', value: 'customer', type: 'custom' },
      //{ text: 'Tipo Transazione', value: 'typeTransaction', type: 'custom' },
      { text: 'Numero Scontrino', value: 'documentNumber', type: 'custom' },
      { text: 'Chiuso Il', value: 'date', type: 'datetime' },
      { text: 'Prodotto/Servizio', value: 'item', type: 'custom' },
      { text: 'Quantità', value: 'quantity', type: 'custom' },
      { text: 'Operatore', value: 'operator', type: 'custom' },
      { text: 'Prezzo di Vendita', value: 'price', type: 'custom' },
      { text: 'Operatore Cassa', value: 'cashDeskOperatorName', type: 'custom' },
    ])
  }

  totalDetailsFields() {
    return Promise.resolve([
      { text: 'N° Transazioni', value: 'transactions' },
      { text: 'N° Servizi/Prodotti', value: 'count' },
      { text: 'Totale', value: 'total', type: 'price' },
    ])
  }

  totalFields(ghost) {
    if (ghost)
      return Promise.resolve([
        { text: 'Cliente', value: 'customer', type: 'custom' },
        { text: 'Tipo Transazione', value: 'typeTransaction', type: 'custom' },
        { text: 'Numero Scontrino', value: 'documentNumber', type: 'custom' },
        { text: 'Assegno', value: 'checkPaid', type: 'custom' },
        { text: 'Contanti', value: 'cashPaid', type: 'custom' },
        { text: 'Pagamento Elettronico', value: 'cardPaid', type: 'custom' },
        { text: 'Altro Metodo', value: 'otherPaid', type: 'custom' },
        { text: 'Pagamento Con Tessera', value: 'customerCardPaid', type: 'custom' },
        { text: 'Concordato', value: 'ghostPaid', type: 'custom' },
        { text: 'Storni', value: 'paymentDebit', type: 'custom' },
        { text: 'Totale', value: 'total', type: 'custom' },
      ])

    return Promise.resolve([
      { text: 'Cliente', value: 'customer', type: 'custom' },
      { text: 'Tipo Transazione', value: 'typeTransaction', type: 'custom' },
      { text: 'Numero Scontrino', value: 'documentNumber', type: 'custom' },
      { text: 'Assegno', value: 'checkPaid', type: 'custom' },
      { text: 'Contanti', value: 'cashPaid', type: 'custom' },
      { text: 'Pagamento Elettronico', value: 'cardPaid', type: 'custom' },
      { text: 'Altro Metodo', value: 'otherPaid', type: 'custom' },
      { text: 'Pagamento Con Tessera', value: 'customerCardPaid', type: 'custom' },
      { text: 'Storni', value: 'paymentDebit', type: 'custom' },
      { text: 'Totale', value: 'total', type: 'custom' },
    ])
  }

  _fieldsToExport(ghost) {
    let fields = {
      'Cliente': {
        callback: (row) => {
          if (row.typeTransaction != 'promo')
            return row.customer
          if (!!row.info.customer)
            return row.info.customer.firstname + ' ' + row.info.customer.lastname
          else
            return 'Nessun cliente'
        }
      },
      'Tipo Transazione': {
        field: "typeTransaction",
        callback: (type) => {
          let types = [
            { key: "paymentCredit", text: "Saldo Debito" },
            { key: "customerCards", text: "Ricarica Tessera" },
            { key: "billPayment", text: "Scontrino" },
            { key: "paymentDebit", text: "Storno" },
            { key: "promo", text: "Promozione" },
            { key: "voucher", text: "Voucher da Storno" },
          ]
          return types.find(z => z.key == type).text
        },
      },
      'Numero Scontrino': 'documentNumber',
      'Assegno': {
        field: "checkPaid",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      'Contanti': {
        field: "cashPaid",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      'Pagamento Elettronico': {
        field: "cardPaid",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      'Altro Metodo': {
        field: "otherPaid",
        callback: (value) => {

          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      'Pagamento Con Tessera': {
        field: "customerCardPaid",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
    }
    if (!!ghost)
      fields = {
        ...fields,
        'Concordato': {
          field: "ghostPaid",
          callback: (value) => {
            return `${Number(value).toFixed(2).replace('.', ',')}`
          },
        },
      }

    fields = {
      ...fields,
      'Totale': {
        field: "total",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Chiuso il": {
        field: "date",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`, true);
        },
      },
      'Servizi': {
        field: "services",
        callback: (services) => {
          let serviceList = "";
          services.map(service => {
            serviceList += service.quantity + ' ' + service.name + ': ' + `${Number(service.price).toLocaleString('it-IT')}` +
              (!!service.operators && !!service.operators[0] ? (' - ' + service.operators[0].firstname + ' ' + service.operators[0].lastname) : '') +
              "\n";
          })
          return serviceList
        },
      },
      'Prodotti': {
        field: "items",
        callback: (items) => {
          let itemList = "";
          items.map(item => {
            itemList += item.quantity + ' ' + item.description + ': ' + `${Number(item.priceSelling).toLocaleString('it-IT')}` +
              (!!item.operators && !!item.operators[0] ? (' - ' + item.operators[0].firstname + ' ' + item.operators[0].lastname) : '') +
              "\n";
          })
          return itemList
        },
      },
      'Operatore Cassa': 'cashDeskOperatorName',
    }

    //   if (ghost) {
    //     let concordatoObj = {
    //       field: "ghostPaid",
    //       callback: (value) => {
    //         return `${Number(value).toLocaleString('it-IT')}`
    //       },
    //     };

    //     var addToObject = function (obj, key, value, index) {
    //       var temp = {};
    //       var i = 0;
    //       for (var prop in obj) {
    //         if (obj.hasOwnProperty(prop)) {
    //           if (i === index && key && value) {
    //             temp[key] = value;
    //           }
    //           temp[prop] = obj[prop];
    //           i++;

    //         }
    //       }
    //       if (!index && key && value) {
    //         temp[key] = value;
    //       }
    //       return temp;
    //     };
    // }

    //     exportFileds = addToObject(exportFileds, 'Concordato', concordatoObj, 8);

    //   return exportFileds

    return fields
  }

  /*
    { text: 'Cliente', value: 'customer', type: 'custom' },
    { text: 'Numero Scontrino', value: 'documentNumber', type: 'custom' },
    { text: 'Chiuso Il', value: 'date', type: 'datetime' },
    { text: 'Prodotto/Servizio', value: 'item', type: 'custom' },
    { text: 'Quantità', value: 'quantity', type: 'custom' },
    { text: 'Operatore', value: 'operator', type: 'custom' },
    { text: 'Prezzo di Vendita', value: 'price', type: 'custom' },
    { text: 'Operatore Cassa', value: 'cashDeskOperatorName', type: 'custom' },
  */

  _fieldsToExportDetails() {
    return {
      'Cliente': {
        callback: (row) => {
          return row.customer == " " ? "Cliente dal bar" : row.customer
        }
      },
      'Numero Scontrino': 'documentNumber',
      "Chiuso il": {
        field: "date",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`, true);
        },
      },
      'Prodotto/Servizio': {
        callback: (row) => {
          return row.object.name || row.object.description
        },
      },
      'Quantità': {
        callback: (row) => {
          return row.object.quantity
        },
      },
      'Prezzo di Vendita': {
        callback: (row) => {
          return Number(row.object.price).toFixed(2).replace('.', ',')
        },
      },
      'Operatore': {
        callback: (row) => {
          if (!!row.object && !!row.object.operators && !!row.object.operators[0])
            return row.object.operators.map(op => op.firstname + ' ' + op.lastname).join(', ')
          else
            return 'Nessun Operatore'
        },
      },
      'Operatore Cassa': 'cashDeskOperatorName',
    }
  }
}

export default new PaymentCollectedService()
