import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class galleryManagment {

  async list({ page, limit, filter, tagIds }) {
    const apiUrl = UrlKeeper.getUrl()

    return await axios
      .post(`${apiUrl}/gallery/images/list`, {
        page: page,
        limit: limit,
        tagIds: tagIds ?? [],
        filter: filter
      })
      .then((res) => res)
  }

  async get(id, isUserUploaded = false) {
    const apiUrl = UrlKeeper.getUrl()

    return await axios
      .get(`${apiUrl}/gallery/images/${id}/${isUserUploaded}`)
      .then((res) => res)
  }

  async addImage(file) {
    const formData = new FormData();
    const apiUrl = UrlKeeper.getUrl()

    formData.append('imageFile', file);
    formData.append('name', file.name);

    return await axios
      .post(`${apiUrl}/gallery/images/add-if-not-exists`, formData)
      .then((res) => res)
  }
}

export default new galleryManagment();
