const enablingTypesRoutes = [
  {
    path: "/registries/enablingTypes",
    name: "EnablingTypesRegistryNewForm",
    component: () =>
      import(
        "@/components/areas/registries/enablingTypes/EnablingTypesRegistryNewForm.vue"
      ),
    children: [
      {
        path: "new/general-tab",
        name: "EnablingTypesGeneralNewFormTab",
        component: () =>
          import(
            "@/components/areas/registries/enablingTypes/form/GeneralFormTab.vue"
          ),
      },
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack
      }
    },
  },
  {
    path: "/registries/enablingTypes/:id/edit",
    name: "EnablingTypesRegistryEditForm",
    component: () => import("@/components/areas/registries/enablingTypes/EnablingTypesRegistryEditForm.vue"),
    children: [
      {
        path: "general-tab",
        name: "EnablingTypesGeneralEditFormTab",
        component: () =>
          import(
            "@/components/areas/registries/enablingTypes/form/GeneralFormTab.vue"
          ),
      }
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack
      }
    },
  },
  {
    path: "/registries/enablingTypes/list",
    name: "EnablingTypesRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/enablingTypes/EnablingTypesRegistryList.vue"
      ),
  }
];

export default enablingTypesRoutes;