<template>
  <div class="d-flex justify-center align-center" v-if="loadingSmsModels">
    <v-progress-circular indeterminate class="mt-2"></v-progress-circular>
  </div>
  <div v-else>
    <CollapsableList
      :items="smsModelsAndCustomText"
      item-key="id"
      item-title="name"
    >
      <template v-slot:expansion="{item}">
        <SmsTextVisualizer
          v-if="item.id != '_personalized'"
          :value="item.text"
        ></SmsTextVisualizer>
        <v-textarea
          filled
          hide-details="auto"
          label="Testo del messaggio"
          v-model="customTextMessage"
          v-else
          @click.stop.prevent
          counter
        >
          <template v-slot:counter="{ props }">
            <span v-bind="props" :style="{
              'color': countCharStringColor
            }">{{ countCharString }} </span>
          </template>
        </v-textarea>
      </template>
      <template v-slot:extra-icons="{item}">
        <v-btn
          v-if="item.id != '_personalized'"
          icon
          @click.stop.prevent="handleEditSmsModel(item)"
        >
          <v-icon medium>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="item.id != '_personalized'"
          class="ml-2"
          outlined
          color="grey darken-2"
          :loading="loading"
          @click.stop.prevent="() => {
            alertAction = sendModelMassive
            alertParams = item
            alertOverlay = true
          }"
        >
          Invia
        </v-btn>
        <v-btn
          v-else
          class="ml-2" 
          outlined
          color="grey darken-2"
          :disabled="item.id == '_personalized' && !customTextMessage"
          :loading="loading"
          @click.stop.prevent="() => {
            alertAction = sendCustomTextMassive
            alertParams = undefined
            alertOverlay = true
          }"
        >
          Invia
        </v-btn>
      </template>
    </CollapsableList>

    <StandardDialog
      v-model="editSmsModelDialog" 
      :title="editSmsModelDialogTitle" 
      :dialog-height="null" 
      persistent
      dialog-width="1000"
    >
      <div>
        <SmsModelForm 
          v-model="editSmsModel" 
          class="py-2"
          :hide-upload-image="true"
          :valid.sync="editSmsModelValid"
        ></SmsModelForm>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn 
          text 
          color="error" 
          @click="editSmsModelDialog = false" 
          :disabled="loadingUpdate"
        >Annulla</v-btn>
        <v-btn 
          text 
          color="default" 
          @click="updateSmsModel"
          :loading="loadingUpdate" 
          :disabled="!editSmsModelValid"
        >Salva</v-btn>
      </template>
    </StandardDialog>

    <SimplePopUp
      v-model="alertOverlay"
      :alert-text="alertText"
      :dismissible="false"
    >
      <template v-slot:footer>
        <v-row class="pt-7 d-flex justify-space-around">
          <v-btn
            style="min-width: 30%;"
            color="error"
            @click="alertOverlay = false"
          >
            Annulla
          </v-btn>
          <v-btn
            style="min-width: 30%;"
            color="success"
            @click="() => {
              alertAction(alertParams)
              alertOverlay = false
              $delegates.snackbar('Sms inviato con successo');
            }"
          >
            Invia
          </v-btn>
        </v-row>
      </template>
    </SimplePopUp>
  </div>
</template>

<script>
import smsModelsService from '@/services/smsModels/smsModels.service.js'
import smsService from '@/services/sms/sms.service.js'
import CollapsableList from '@/components/common/CollapsableList.vue'
import SmsTextVisualizer from '@/components/setting/sms/SmsTextVisualizer.vue'
import SmsModelForm from '@/components/setting/sms/SmsModelForm.vue'
import StandardDialog from '@/components/common/StandardDialog.vue';
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import settingService from "@/services/settings/settings.service.js";

export default {
  name: "SendPromoSmsModel",
  components: {
    SmsTextVisualizer,
    CollapsableList,
    SmsModelForm,
    StandardDialog,
    SimplePopUp
  },
  data: function() {
    return { 
      smsModels: [],
      loadingSmsModels: false,
      customTextMessage: "",
      loading: false,
      editSmsModelDialog: false,
      editSmsModelValid: false,
      editSmsModel: {},
      loadingUpdate: false,
      whatsappNumber: undefined,
      alertOverlay: false,
      alertAction: undefined,
      alertParams: undefined
    }
  },
  mounted: function() {
    this.fetchSmsModels();
    this.loadSettings();
  },
  props: {
    data: {
    },
    whatsapp: {
      default: false
    },
    alertText: {
      default: "Vuoi davvero inviare un messaggio a tutti i clienti che hanno un appuntamento oggi?"
    },
    withList: {
      default: false
    },
    sendAll: {
      default: false
    },
    customers: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    sendSmsModelMethod: {
      type: Function,
      default: undefined
    }
  },
  methods: {
    fetchSmsModels() {
      this.loadingSmsModels = true
      smsModelsService.list().then((models) => {
        this.smsModels = models.rows
        this.loadingSmsModels = false
      })
    },
    loadSettings: async function () {
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    sendModelMassive(model){
      this.loading = true
      if(!!this.sendSmsModelMethod && typeof this.sendSmsModelMethod === 'function'){
        this.sendSmsModelMethod(this.whatsappNumber, model, false, undefined, this.withList, this.sendAll, this.customers, this.filters).then(() => {
          this.loading = false
          this.$emit('close')
        }).catch(() => {
          this.loading = false
        })
        return
      }
      smsService.sendMassivePromo(this.whatsappNumber, model, false, undefined, this.withList, this.sendAll, this.customers, this.filters).then(() => {
        this.loading = false
        this.$emit('close')
      }).catch(() => {
        this.loading = false
      })
    },
    sendCustomTextMassive(){
      this.loading = true
      smsService.sendMassivePromo(this.whatsappNumber, undefined, true, this.customTextMessage, this.withList, this.sendAll, this.customers, this.filters).then(() => {
        this.loading = false
        this.$emit('close')
      }).catch(() => {
        this.loading = false
      })
    },
    handleEditSmsModel(smsModel) {
      this.editSmsModel = smsModel
      this.editSmsModelDialog = true
    },
    updateSmsModel() {
      this.loadingUpdate = true
      smsModelsService.update(this.editSmsModel).then((models) => {
        this.fetchSmsModels()
        this.editSmsModel = {}
        this.loadingUpdate = false
        this.editSmsModelDialog = false
      })
    }
  },
  computed: {
    smsModelsAndCustomText() {
      return [...this.smsModels, {
        id: '_personalized',
        name: 'Testo Personalizzato',
      }]
    },
    editSmsModelDialogTitle() {
      return !!this.editSmsModel && !!this.editSmsModel.name ? this.editSmsModel.name : 'Nuovo modello'
    },
    countCharString(){
      return this.customTextMessage.length % 160 + '/160 - ' + Math.ceil(this.customTextMessage.length / 160) + ' Messaggi'
    },
    countCharStringColor(){
      return this.customTextMessage.length > 160 ? 'red' : 'black'
    },
  },
  settings: {
    whatsappNumber: {
      bind: 'whatsappNumber'
    },
  }
}
</script>

<style>

</style>