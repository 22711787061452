<template>
  <div class="d-flex">
    <v-text-field
      v-model="localValue"
      @input="$emit('input', localValue)"
      :filled="filled"
      :dense="dense"
      :rounded="rounded"
      :disabled="disabled"
      :loading="loading"
      :label="label"
      :hide-details="hideDetails"
      :type="type"
      v-if="textVisible"
      :prepend-icon="prependIcon"
      :rules="rules"
      ref="textInput"
      :autocomplete="type === 'password' ? 'new-password' : 'off'"
      name="barcode"
    >
      <template v-slot:append v-if="appendSlotVisible">
        <VueBarcode
          v-model="localValue"
          class="ma-0"
          height="30px"
          :display-value="!textVisible"
          :margin="0"
          background="transparent"
        ></VueBarcode>
      </template>
    </v-text-field>
    <VueBarcode
      v-model="localValue"
      height="35px"
      :display-value="!textVisible"
      v-if="barcodeVisible"
    ></VueBarcode>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode';
import Vue from 'vue'

export default {
  name: "BarcodeDrawer",
  components: {
    VueBarcode
  },
  data: function() {
    return {
      localValue: this.value
    }
  },
  props: {
    value: { },
    bus: {
      default: () => new Vue()
    },
    filled: {
      type: Boolean,
      default: false      
    },
    dense: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Barcode'
    },
    hideDetails: {
      type: String
    },
    prependIcon: {
      type: String,
      default: 'mdi-barcode-scan'
    },
    rules: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: 'barcodeAndText',
      validator: function(value) {
        return ['barcodeAndText', 'onlyText', 'onlyBarcode', 'textAndBarcodeInside'].includes(value)
      },
    },
    type: {
      type: String,
      default: undefined,
    }
  },
  mounted: function() {
    if(!!this.bus) {
      this.bus.$on('focus', () => {
        this.$nextTick(() => {
          this.$refs.textInput.focus()
        })
      })
    }
  },
  computed: {
    textVisible() {
      return this.variant == 'barcodeAndText' || this.variant == 'onlyText' || this.variant == 'textAndBarcodeInside'
    },
    barcodeVisible() {
      return this.variant == 'barcodeAndText' || this.variant == 'onlyBarcode'
    },
    appendSlotVisible() {
      return this.variant == 'textAndBarcodeInside'
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  }
}
</script>

<style>

</style>