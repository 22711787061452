import { render, staticRenderFns } from "./SuppliersRegistryEditFormTab.vue?vue&type=template&id=9db8574c"
import script from "./SuppliersRegistryEditFormTab.vue?vue&type=script&lang=js"
export * from "./SuppliersRegistryEditFormTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports