var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"chart"}},[_c('TitleWithBackButton',{attrs:{"title":"Riepilogo Bistrot","back-route":"Analytics","icon":"mdi-graphql"}}),_c('div',{staticClass:"d-flex align-center ml-3 mt-3 flex-wrap"},[_c('RangeDateSelector',{attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"loading":_vm.loading,"disabled":_vm.loading,"max-width":!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : this.$vuetify.breakpoint.md ? '70vw' : '50vw'},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event}}}),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.calculate}},[_vm._v("Calcola ")]),_c('v-btn',{staticClass:"btn-export",attrs:{"loading":_vm.loading},on:{"click":_vm.exportToExcel}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.print}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right","margin-right":"2.5%"},attrs:{"icon":"","large":"","disabled":_vm.loading,"color":!!_vm.isB ? 'red' : ''},on:{"click":() => { _vm.dialogBarcode = !_vm.dialogBarcode }}},[_c('v-icon',[_vm._v("mdi-shield-account-variant")])],1)],1),(_vm.loading)?_c('div',{staticClass:"text-center mt-2"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('div',[_c('div',[_c('v-row',{staticClass:"mt-5",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":_vm.generalChartData,"styles":{
                height: '300px',
                width: '300px'
              },"options":{
                mainAspectRatio: true,
                responsive: true,
                legend: {
                  display: false
                },
                showTooltips: false,
              }}})],1)],1),_c('v-row',{staticStyle:{"width":"100%","justify-content":"center","margin":"auto"}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4","lg":"4","xl":"4","order":"1"}},[_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticStyle:{"padding-right":"5px"}},[_c('TypeDataTable',{staticClass:"mt-5 balance-table",staticStyle:{"background-color":"transparent","min-width":"25vw"},attrs:{"items":_vm.costTotalData,"height":undefined,"item-key":"name","headersColor":"transparent","headers":[
                    { text: '', value: 'color', type: 'color'},
                    { text: '', value: 'name' },
                    { text: '€', value: 'total' },
                    { text: '%', value: 'percentage' },
                    { text: 'di cui IVA', value: 'iva' }
                  ],"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '3' : '2'}},[_c('v-row',{staticClass:"d-flex justify-center",style:({
                'width': '100%',
                'margin': this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs  
                  ? 'auto' : '-12px'
              })},[_c('v-col',[_c('TypeDataTable',{staticClass:"mt-5 balance-table",staticStyle:{"background-color":"transparent","min-width":"20vw"},attrs:{"items":_vm.delta,"height":undefined,"item-key":"name","headersColor":"transparent","headers":[
                    { text: '', value: 'name' },
                    { text: '€', value: 'total' },
                    { text: '%', value: 'percentage' },
                    { text: 'di cui IVA', value: 'iva' }
                  ],"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4","lg":"4","xl":"4","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '2' : '3'}},[_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticStyle:{"padding-left":"5px"}},[_c('TypeDataTable',{staticClass:"mt-5 balance-table",staticStyle:{"background-color":"transparent","min-width":"25vw"},attrs:{"items":_vm.revenueTotalData,"height":undefined,"item-key":"name","headersColor":"transparent","headers":[
                    { text: '', value: 'color', type: 'color'},
                    { text: '', value: 'name' },
                    { text: '€', value: 'total' },
                    { text: '%', value: 'percentage' },
                    { text: 'di cui IVA', value: 'iva' }
                  ],"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{staticStyle:{"width":"100%","margin-bottom":"0px"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('TypeDataTable',{staticClass:"mt-5 balance-table",staticStyle:{"background-color":"rgba(50,50,50,0.1)","width":"40vw"},attrs:{"headersColor":"rgb(154, 154, 154)","height":undefined,"item-key":"name","headers":[
                { text: '', value: 'name' },
                { text: '€', value: 'total' },
                { text: '%', value: 'percentage' },
              ],"items":_vm.costDetailsData,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":"","show-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function({headers, item}){return [_c('td',{staticClass:"pa-0 pl-5",attrs:{"colspan":headers.length}},[_c('TypeDataTable',{staticClass:"balance-table",staticStyle:{"background-color":"rgba(50,50,50,0.1)"},attrs:{"headersColor":"rgb(154, 154, 154)","item-key":"name","headers":[
                      { text: '', value: 'name' },
                      { text: '€', value: 'total' },
                      { text: '%', value: 'percentage' },
                    ],"items":item.subItems,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)]}}])})],1),_c('v-divider',{staticClass:"mt-5",attrs:{"vertical":""}}),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('TypeDataTable',{staticClass:"mt-5 balance-table",staticStyle:{"background-color":"rgba(50,50,50,0.1)","width":"40vw"},attrs:{"headersColor":"rgb(154, 154, 154)","height":undefined,"item-key":"name","headers":[
                { text: '', value: 'name' },
                { text: '€', value: 'total' },
                { text: '%', value: 'percentage' },
              ],"items":_vm.revenueDetailsData,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":"","show-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function({headers, item}){return [_c('td',{staticClass:"pa-0 pl-5",attrs:{"colspan":headers.length}},[_c('TypeDataTable',{staticClass:"balance-table",staticStyle:{"background-color":"rgba(50,50,50,0.1)"},attrs:{"headersColor":"rgb(154, 154, 154)","item-key":"name","headers":[
                      { text: '', value: 'name' },
                      { text: '€', value: 'total' },
                      { text: '%', value: 'percentage' },
                    ],"items":item.subItems,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":"","show-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function({headers, item}){return [_c('td',{staticClass:"pa-0 pl-5",attrs:{"colspan":headers.length}},[_c('TypeDataTable',{staticClass:"balance-table",staticStyle:{"background-color":"rgba(50,50,50,0.1)"},attrs:{"headersColor":"rgb(154, 154, 154)","headers":[
                            { text: '', value: 'name' },
                            { text: '€', value: 'total' },
                            { text: '%', value: 'percentage' },
                          ],"items":item.subItems,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)]}}],null,true)})],1)]}}])})],1)],1)],1)]),_c('StandardDialog',{attrs:{"title":"Area Riservata Sviluppatori","dialog-height":null,"dialog-max-width":"500px"},model:{value:(_vm.dialogBarcode),callback:function ($$v) {_vm.dialogBarcode=$$v},expression:"dialogBarcode"}},[_c('ManualBarcodeInput',{attrs:{"scan-your-barcode-message":"Immettere Codice"},on:{"confirm":_vm.activeBarcode},model:{value:(_vm.operatorBarcode),callback:function ($$v) {_vm.operatorBarcode=$$v},expression:"operatorBarcode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }