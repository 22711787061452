<template>
  <div style="height: calc(100vh - 132px)">    
    <ActionMarketingGeneralForm
      :disabled="!!disabled || (type == 'duplicateFrom' ||
        status == 'approved' || !!actionValue && !!actionValue.hasStarted)"
      v-model="actionValue"
      :event-type="type"
      :event-start-date="startDate"
      :event-end-date="endDate"
      :valid.sync="actionValid"
      :show-configuration-tool="!!editEventType || type == 'duplicateFrom' ? true : false"
      @input="$emit('input', localValue)"
      @update:img="$emit('update:img', $event)"
    ></ActionMarketingGeneralForm>
  </div>
</template>

<script>
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import ActionMarketingGeneralForm from "@/components/areas/marketing/actions/ActionMarketingGeneralForm.vue";

export default {
  name: "EventMarketingGeneralForm",
  components: {
    DateTimePicker,
    ActionMarketingGeneralForm
  },
  data: function() {
    return {
      actionValid: false,
      localStartDate: this.startDate,
      localEndDate: this.endDate,
      actionValue: {},
    };
  },
  mounted() {
    this.$emit('update:valid', this.computedValid)
  },
  props: {
    value: {
      type: Object,
    },
    valid: {
      type: Boolean
    },
    startDate: {
      type: Date,
      default: () => undefined,
    },
    endDate: {
      type: Date,
      default:() => undefined,
    },
    status: {
      type: String,
      default: 'draft'
    },
    type: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editEventType: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  },
  watch: {
    computedValid(newVal) {
      this.$emit('update:valid', newVal)
    },
    value(newVal) {
      if (!!newVal) {
        let localSelectedTool = newVal.actions.selectedTool
        if (!newVal.actions.selectedTool) {
          localSelectedTool = !!newVal.actions.smsModel ? 'sms' : newVal.actions.receiptModel ? 'receipt'
            : newVal.actions.landingPage ? 'landingPage' : undefined          
        }
        this.actionValue = {
          ...newVal.actions,
          recurrenceType: newVal.recurrenceType,
          customRecurrence: newVal.customRecurrence,
          selectedTool: localSelectedTool,
          selectedImages: !!newVal.selectedImages ? newVal.selectedImages : newVal.actions.selectedImages,
        }
      }
    }
  },
  computed: {
    computedValid() {
      return !!this.actionValid
    },
    localValue() {
      return {
        id: !!this.value ? this.value.id : undefined,
        from: this.actionValue.eventStartDate,
        to: this.actionValue.eventEndDate,
        recurrenceType: this.actionValue.recurrenceType,
        customRecurrence: this.actionValue.customRecurrence,
        recurringDates: this.actionValue.recurringDates,
        actions: this.actionValue,
      }
    },
  }
};
</script>

<style>
</style>