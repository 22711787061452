import NameMapper from '@/services/common/nameMapper.js'

class statesNameMapping extends NameMapper {

  _optionsGetter() {
    return Promise.resolve([
      { value: 'valid', text: 'Operatore in forza' },
      { value: 'disabled', text: 'Operatore cessato' },
    ])
  }
}

export default new statesNameMapping()