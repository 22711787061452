import axios from "axios";
import CookieService from "@/services/cookies.service";
import EventsHandler from "@/services/common/eventsHandler"
import FileDownload from 'downloadjs'

class Axios extends EventsHandler {
  constructor() {
    super()
    this.headers = {};
    this.tempHeaders = {};

    this.headers['auth-token'] = CookieService.get('auth-token');
    this.headers['user-id'] = CookieService.get('user-id');
    this.headers['cashdesk-token'] = CookieService.get('cashdesk-token');
    this.headers['operator-barcode'] = CookieService.get('operator-barcode');
  }

  //#region Public Methods
  get(url, params) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {
        axios
          .get(url, {params: params, headers: headers})
          .then((response) => {
            resolve(response.data, response);
          })
          .catch((error) => {
            if(!error.response) {
              this._executeAsyncCallbacksOf('no-response').then(() => {
                }).finally(() => {
                  reject(new Error('no-response'));
                })
            } else if (error.response.data.message == "access denied" || error.response.data.message == "token or user not known") {
              this._executeAsyncCallbacksOf('access-denied').then(() => {
                }).finally(() => {
                  reject(new Error('access-denied'));
                })
            } else if(error.response.status == 404) {
              reject(new Error('not-found'))
            }
          });
      })
    });
  }

  post(url, params) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {
        axios
          .post(url, params, {headers: headers})
          .then((response) => {
            resolve(response.data, response);
          })
          .catch((error) => {
            if (!error.response) {
              this._executeAsyncCallbacksOf('no-response').then(() => {
              }).finally(() => {
                reject(new Error('no-response'));
              })
            } else if (error.response.data.message == "access denied" || error.response.data.message == "token or user not known") {
              this._executeAsyncCallbacksOf('access-denied').then(() => {
              }).finally(() => {
                reject(new Error('access-denied'));
              })
            } else if (error.response.status == 404) {
              reject(new Error('not-found'))
            }
          });
      })
    });
  }

  getWithDownload(url, params, filename) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {
        axios.get(url, { 
          params: params, 
          headers: headers,
          responseType: 'blob'
        }).then((response) => {
          if(response.success == false) {
            reject(response.results)
          } else {
            FileDownload(response.data, filename)
            resolve(response.data, response)
          }
        })
      })
    })
  }

  postWithDownload(url, params, filename) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {

        axios.post(url, params, { 
          headers: headers,
          responseType: 'blob'
        }).then((response) => {
          if (!!response.data && response.data.success == false) {
            reject(response.data.results)
          } else {
            FileDownload(response.data, filename,response.headers['content-type'])
            resolve(response.data, response)
          }
        })
      })
    })
  }

  setHeader(name, value, daysUntilExpired) {
    CookieService.set(name, value, daysUntilExpired);
    this.headers[name] = value;
  }

  setTemporaryHeader(name, value) {
    this.tempHeaders[name] = value;
  }

  headerPresent(name) {
    return new Promise((resolve, reject) => {
      let coockie = CookieService.get(name)
      if(coockie) resolve(true)
      else resolve(false)
    })
  }

  getBlob(url) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {
        fetch(url, { headers: headers })
          .then(r => r.blob())
          .then((response) => {
            resolve(response)
          })
      })
    })
  }
  //#endregion

  //#region Private Methods
  async _buildHeaders() {
    let headers = {}
    for (const [key, value] of Object.entries(this.headers)) {
      this.headers[key] = CookieService.get(key);
      headers[key] = this.headers[key]
    }

    for (const [key, value] of Object.entries(this.tempHeaders)) {
      headers[key] = value
    }

    return headers;
  }
  //#endregion
}

export default new Axios();
