<template>
  <div
    id="chart"
  >
    <TitleWithBackButton title="Riepilogo Bistrot" back-route="Analytics" icon="mdi-graphql">
    </TitleWithBackButton>
    <div 
      class="d-flex align-center ml-3 mt-3 flex-wrap"
    >
      <RangeDateSelector 
        :start-date.sync="startDate" 
        :end-date.sync="endDate" 
        :loading="loading" 
        :disabled="loading"
				:max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : this.$vuetify.breakpoint.md ? '70vw' : '50vw'"
      >
      </RangeDateSelector>
      <v-btn class="mr-2 ml-2" text @click="calculate" :loading="loading" :disabled="!startDate || !endDate">Calcola
      </v-btn>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn class="mr-2 ml-2" text @click="print" :loading="loading" :disabled="!startDate || !endDate"><v-icon> mdi-printer </v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-btn icon large :disabled="loading" style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!isB ? 'red' : ''"
        >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>
    <div v-if="loading" class="text-center mt-2">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div>
      <div>
        <v-row style="width: 100%;" class="mt-5">
          <v-col  class="d-flex justify-center">
            <PieChart 
              :chart-data="generalChartData" 
              :styles="{
                height: '300px',
                width: '300px'
              }" 
              :options="{
                mainAspectRatio: true,
                responsive: true,
                legend: {
                  display: false
                },
                showTooltips: false,
              }"
            >
            </PieChart>
          </v-col>
        </v-row>
        <v-row style="width: 100%; justify-content: center; margin: auto;">
          <v-col
            cols="6"
            sm="6"
            md="4"
            lg="4"
            xl="4"
            order="1"
          >
            <v-row style="width: 100%;" class="d-flex justify-center">
              <v-col 
                style="padding-right: 5px;"
              >
                <TypeDataTable
                  class="mt-5 balance-table"
                  :items="costTotalData"
                  :height="undefined"
                  item-key="name"              
                  headersColor="transparent" 
                  style="background-color: transparent; min-width: 25vw;"
                  :headers="[
                    { text: '', value: 'color', type: 'color'},
                    { text: '', value: 'name' },
                    { text: '€', value: 'total' },
                    { text: '%', value: 'percentage' },
                    { text: 'di cui IVA', value: 'iva' }
                  ]" 
                  :show-select="false" 
                  :select-on-row-click="false"
                  :show-actions="false" 
                  :disablePagination="true" 
                  dense 
                  disable-sort
                >
                </TypeDataTable>
              </v-col>
            </v-row>
          </v-col>
          <v-col 
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '3' : '2' "
          >
            <v-row 
              :style="{
                'width': '100%',
                'margin': this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs  
                  ? 'auto' : '-12px'
              }" 
              class="d-flex justify-center"
            >
              <v-col>
                <TypeDataTable
                  class="mt-5 balance-table"
                  :items="delta"
                  :height="undefined"
                  item-key="name"
                  headersColor="transparent" 
                  style="background-color: transparent; min-width: 20vw;"
                  :headers="[
                    { text: '', value: 'name' },
                    { text: '€', value: 'total' },
                    { text: '%', value: 'percentage' },
                    { text: 'di cui IVA', value: 'iva' }
                  ]" 
                  :show-select="false" 
                  :select-on-row-click="false"
                  :show-actions="false" 
                  :disablePagination="true" 
                  dense 
                  disable-sort
                >
                </TypeDataTable>
              </v-col>
            </v-row>
          </v-col>
          <v-col 
            cols="6"
            sm="6"
            md="4"
            lg="4"
            xl="4"
            :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '2' : '3' "
          >
            <v-row style="width: 100%;" class="d-flex justify-center">
              <v-col
                style="padding-left: 5px;"
              >
                <TypeDataTable
                  class="mt-5 balance-table"
                  :items="revenueTotalData"
                  :height="undefined"
                  item-key="name"
                  headersColor="transparent" 
                  style="background-color: transparent; min-width: 25vw;"
                  :headers="[
                    { text: '', value: 'color', type: 'color'},
                    { text: '', value: 'name' },
                    { text: '€', value: 'total' },
                    { text: '%', value: 'percentage' },
                    { text: 'di cui IVA', value: 'iva' }
                  ]" 
                  :show-select="false" 
                  :select-on-row-click="false"
                  :show-actions="false" 
                  :disablePagination="true" 
                  dense 
                  disable-sort
                >
                </TypeDataTable>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <v-row style="width: 100%; margin-bottom: 0px;">
          <v-col cols="6" class="d-flex justify-center">
            <TypeDataTable
              class="mt-5 balance-table" 
              headersColor="rgb(154, 154, 154)" 
              style="background-color: rgba(50,50,50,0.1); width:40vw"
              :height="undefined"
              item-key="name"
              :headers="[
                { text: '', value: 'name' },
                { text: '€', value: 'total' },
                { text: '%', value: 'percentage' },
              ]" 
              :items="costDetailsData" 
              :show-select="false" 
              :select-on-row-click="false"
              :show-actions="false" 
              :disablePagination="true" 
              dense 
              disable-sort
              show-expand
            >
              <template v-slot:expanded-item="{headers, item}">
                <td :colspan="headers.length" class="pa-0 pl-5">
                  <TypeDataTable
                    class="balance-table"
                    headersColor="rgb(154, 154, 154)" 
                    style="background-color: rgba(50,50,50,0.1);"
                    item-key="name"
                    :headers="[
                      { text: '', value: 'name' },
                      { text: '€', value: 'total' },
                      { text: '%', value: 'percentage' },
                    ]" 
                    :items="item.subItems" 
                    :show-select="false" 
                    :select-on-row-click="false"
                    :show-actions="false" 
                    :disablePagination="true" 
                    dense 
                    disable-sort
                  >
                  </TypeDataTable>
                </td>
              </template>
            </TypeDataTable>
          </v-col>
          <v-divider vertical class="mt-5"></v-divider>
          <v-col cols="6" class="d-flex justify-center">
            <TypeDataTable
              class="mt-5 balance-table" 
              headersColor="rgb(154, 154, 154)" 
              style="background-color: rgba(50,50,50,0.1); width:40vw;"
              :height="undefined"
              item-key="name"
              :headers="[
                { text: '', value: 'name' },
                { text: '€', value: 'total' },
                { text: '%', value: 'percentage' },
              ]" 
              :items="revenueDetailsData" 
              :show-select="false" 
              :select-on-row-click="false"
              :show-actions="false" 
              :disablePagination="true" 
              dense 
              disable-sort
              show-expand
            >
              <template v-slot:expanded-item="{headers, item}">
                <td :colspan="headers.length" class="pa-0 pl-5">
                  <TypeDataTable
                    class="balance-table"
                    headersColor="rgb(154, 154, 154)" 
                    style="background-color: rgba(50,50,50,0.1);"
                    item-key="name"
                    :headers="[
                      { text: '', value: 'name' },
                      { text: '€', value: 'total' },
                      { text: '%', value: 'percentage' },
                    ]" 
                    :items="item.subItems" 
                    :show-select="false" 
                    :select-on-row-click="false"
                    :show-actions="false" 
                    :disablePagination="true" 
                    dense 
                    disable-sort
                    show-expand
                  >
                    <template v-slot:expanded-item="{headers, item}">
                      <td :colspan="headers.length" class="pa-0 pl-5">
                        <TypeDataTable
                          class="balance-table"
                          headersColor="rgb(154, 154, 154)" 
                          style="background-color: rgba(50,50,50,0.1);"
                          :headers="[
                            { text: '', value: 'name' },
                            { text: '€', value: 'total' },
                            { text: '%', value: 'percentage' },
                          ]" 
                          :items="item.subItems" 
                          :show-select="false" 
                          :select-on-row-click="false"
                          :show-actions="false" 
                          :disablePagination="true" 
                          dense 
                          disable-sort
                        >
                        </TypeDataTable>
                      </td>
                    </template>
                  </TypeDataTable>
                </td>
              </template>
            </TypeDataTable>
          </v-col>
        </v-row>
      </div>
    </div>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import balanceService from '@/services/balance/balance.service.js'
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue"
import FlowyGraph from "@/components/areas/analytics/FlowyGraph.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import operatorService from '@/services/operators/operators.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import PieChart from "@/components/charts/PieChart";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import _ from "lodash";
import html2canvas from '@/assets/js/html2canvas.min.js'
import * as XLSX from "xlsx";

export default {
  name: "BalanceByTags",
  components: {
    RangeDateSelector,
    TitleWithBackButton,
    TagsCategoriesAutocomplete,
    FlowyGraph,
    TypeDataTable,
    StandardDialog,
    ManualBarcodeInput,
    PieChart,
    GroupBasedChart
  },
  data: function () {
    let startDate = new Date();
    startDate.setDate(1)
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    return {
      startDate: startDate,
      endDate: endDate,
      loading: false,
      isB: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      generalChartData: {},
      revenueTotalData: [{}],
      costTotalData: [{}],
      delta: [{}],
      revenueDetailsData: [],
      costDetailsData: [],
      fileName: ""
    }
  },
  mounted: async function () {
    this.calculate()
  },
  methods: {
    exportToExcel(){
      let workbook = XLSX.utils.book_new();
      let costsRevenueDeltaTotal = [],
      revenuesDetail = [],
      costsDetail = []
      //Aggiunta dei costi
      for (let i = 0; i < this.costTotalData.length; i++) {
        costsRevenueDeltaTotal.push(this.costTotalData[i])
      }
      costsRevenueDeltaTotal.push({
        name: "",
        total: "",
        percentage: "",
        iva: "",
      })
      //Aggiunta degli incassi
      for (let i = 0; i < this.revenueTotalData.length; i++) {
        costsRevenueDeltaTotal.push(this.revenueTotalData[i])
      }
      costsRevenueDeltaTotal.push({
        name: "",
        total: "",
        percentage: "",
        iva: "",
      })
      //Aggiunta dell'utile
      for (let i = 0; i < this.delta.length; i++) {
        costsRevenueDeltaTotal.push(this.delta[i])
      }
      let ws1 = XLSX.utils.json_to_sheet(costsRevenueDeltaTotal.map((element) => {
        return {
          "": element.name,
          "€": element.total,
          "%": element.percentage,
          "di cui Iva": element.iva,
        }
      }));
      XLSX.utils.book_append_sheet(workbook, ws1, "Costi - Incassi - Utile");

      //Aggiunta del dettaglio Incassi
      for (let i = 0; i < this.revenueDetailsData.length; i++) {
        revenuesDetail.push(this.revenueDetailsData[i])
        for (let j = 0; j < this.revenueDetailsData[i].subItems.length; j++) {
            this.revenueDetailsData[i].subItems[j].categoryName = this.revenueDetailsData[i].name
          revenuesDetail.push(this.revenueDetailsData[i].subItems[j])
          for (let k = 0; k < this.revenueDetailsData[i].subItems[j].subItems.length; k++) {
            this.revenueDetailsData[i].subItems[j].subItems[k].categoryName = this.revenueDetailsData[i].subItems[j].name
            revenuesDetail.push(this.revenueDetailsData[i].subItems[j].subItems[k])
          }
        }
      }
      let ws2 = XLSX.utils.json_to_sheet(revenuesDetail.map((element) => {
        return {
          "": element.name,
          "€": element.total,
          "%": element.percentage,
          "Categoria": element.categoryName
        }
      }));
      XLSX.utils.book_append_sheet(workbook, ws2, "Incassi Dettaglio");
      //Aggiunta del dettaglio Costi
      for (let i = 0; i < this.costDetailsData.length; i++) {
        costsDetail.push(this.costDetailsData[i])
        for (let j = 0; j < this.costDetailsData[i].subItems.length; j++) {    
          this.costDetailsData[i].subItems[j].categoryName = this.costDetailsData[i].name      
          costsDetail.push(this.costDetailsData[i].subItems[j])
        }
      }
      let ws3 = XLSX.utils.json_to_sheet(costsDetail.map((element) => {
        return {
          "": element.name,
          "€": element.total,
          "%": element.percentage,
          "Categoria": element.categoryName
        }
      }));
      XLSX.utils.book_append_sheet(workbook, ws3, "Costi Dettaglio");

      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    async calculate() {
      const startDate = new Date(this.startDate),
      endDate = new Date(this.endDate)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      this.loading = true
      let result = await balanceService.bistrotBalance({
        from: startDate,
        to: endDate,
        ghost: this.isB
      })
      this.fileName = "Riepilogo_Bistrot_" + startDate.toLocaleDateString('it-IT') + "_a_" + endDate.toLocaleDateString('it-IT') + ".xls";
      result.totals.total = Number(result.totals.total)

      let costTotal = _.sumBy(result.costsDetails, item => Number(item.amount))
      let costIva = _.sumBy(result.costsDetails, item => Number(item.tax))
      let total = result.totals.total + costTotal


      this.generalChartData = {
        labels: ['Ricavi', 'Costi'],
        datasets: [
          {
            label: 'Label',
            pointBackgroundColor: 'black',
            borderWidth: 2,
            pointBorderColor: 'black',
            data: [result.totals.total, costTotal],
            backgroundColor: ['rgba(138,119,226,0.6)', 'rgba(80,191,225,0.6)'],
          }
        ]
      }

      let revenuePercentage = (result.totals.total / total * 100)
      if(!revenuePercentage) revenuePercentage = 0

      this.revenueTotalData = [{
        color: 'rgba(138,119,226,0.6)',
        name: 'Ricavi',
        total: result.totals.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }), 
        percentage: revenuePercentage.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        iva: (Number(result.totals.tax)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
      }]

      let costPercentage = (costTotal / total * 100)
      if(!costPercentage) costPercentage = 0

      this.costTotalData = [{
        color: 'rgba(80,191,225,0.6)',
        name: 'Costi',
        total: (costTotal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        percentage: costPercentage.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        iva: (costIva).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
      }]

      let delta = (result.totals.total - costTotal)
      this.delta = [{
        name: 'Delta',
        total: (delta < 0 ? '' : '+') + delta.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        percentage: (delta < 0 ? '' : '+') + (revenuePercentage - costPercentage).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        iva: (Number(result.totals.tax) - costIva).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
      }]

      let tagsDetails = []
      let serviceTotal = Number(result.totals.totalServices) || 1

      let groupedByTagService = _.groupBy(result.servicesDetails, 'tagId')

      for(let tagId in groupedByTagService) {
        let tag = groupedByTagService[tagId]
        let detail = {}
        detail.name = tag[0].tagName
        detail.subItems = []
        detail.total = 0
        for(let i = 0; i < tag.length; i++) {
          detail.total += Number(tag[i].total)
          detail.subItems.push({
            name: tag[i].serviceName,
            total: Number(tag[i].total)
          })
        }

        for(let i = 0; i < detail.subItems.length; i++) {
          detail.subItems[i].percentage = (Number(detail.subItems[i].total) / detail.total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'
          detail.subItems[i].total = detail.subItems[i].total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        }

        if(serviceTotal == 0) 
          detail.percentage = '0,00%'
        else
          detail.percentage = (detail.total / serviceTotal * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'
        detail.total = detail.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        tagsDetails.push(detail)
      }

      let itemsDetails = []
      let itemsTotal = Number(result.totals.totalItems) || 1

      let groupedByTagItem = _.groupBy(result.itemsDetails, 'itemId')

      for(let itemId in groupedByTagItem) {
        let item = groupedByTagItem[itemId]
        let detail = {}
        detail.name = item[0].itemName
        detail.subItems = []
        detail.total = 0
        for(let i = 0; i < item.length; i++) {
          detail.total += Number(item[i].total)
          detail.subItems.push({
            name: item[i].itemName,
            total: Number(item[i].total)
          })
        }

        for(let i = 0; i < detail.subItems.length; i++) {
          if(detail.total === 0)
            detail.subItems[i].percentage = '0,00%'
          else
            detail.subItems[i].percentage = (Number(detail.subItems[i].total) / detail.total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'
          detail.subItems[i].total = detail.subItems[i].total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        }

        if(itemsTotal === 0) 
          detail.percentage = '0,00%'
        else
          detail.percentage = (detail.total / itemsTotal * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'
        detail.total = detail.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        itemsDetails.push(detail)
      }

      this.revenueDetailsData = [
        {
          name: 'Servizi',
          total: Number(result.totals.totalServices).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          percentage: (!!result.totals.total ? 
            Number(result.totals.totalServices) / result.totals.total * 100
            : 0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          subItems: tagsDetails
        },
        {
          name: 'Prodotti',
          total: Number(result.totals.totalItems).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          percentage: (!!result.totals.total ? 
            Number(result.totals.totalItems) / result.totals.total * 100
            : 0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          subItems: itemsDetails
        } 
      ]

      let groupedByTagCost = _.groupBy(result.costsDetails, 'categoryId')

      this.costDetailsData = []
      for(let costId in groupedByTagCost){
        let item = groupedByTagCost[costId]

        let detail = {}
        detail.name = item[0].categoryName
        detail.subItems = []
        detail.total = 0

        for(let i = 0; i < item.length; i++) {
          detail.total += Number(item[i].amount)
          detail.subItems.push({
            name: item[i].tagName,
            total: Number(item[i].amount)
          })
        }

        for(let i = 0; i < detail.subItems.length; i++) {
          if(detail.total == 0) {
            detail.subItems[i].percentage = '0,00%'
          } else {
            detail.subItems[i].percentage = (Number(detail.subItems[i].total) / detail.total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'
          }
          detail.subItems[i].total = detail.subItems[i].total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        }

        if(costTotal == 0) {
          detail.percentage = '0,00%'
        } else {
          detail.percentage = (detail.total / costTotal * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'
        }
        detail.total = detail.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })

        this.costDetailsData.push(detail)
      }


      this.loading = false
    },
    changeWithB() {
      this.isB = !this.isB
      this.calculate()
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.changeWithB()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    print() {
      this.printLoading = true

      html2canvas(document.getElementById('chart'), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Riepilogo_Bistrot_" + this.startDate.getDate() + "-" + this.startDate.getMonth() + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error) => {
        console.error(error)
        this.printLoading = false
      });
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>