var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadingSmsModels)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{staticClass:"mt-2",attrs:{"indeterminate":""}})],1):_c('div',[_c('CollapsableList',{attrs:{"items":_vm.smsModelsAndCustomText,"item-key":"id","item-title":"name"},scopedSlots:_vm._u([{key:"expansion",fn:function({item}){return [(item.id != '_personalized')?_c('SmsTextVisualizer',{attrs:{"value":item.text}}):_c('v-textarea',{attrs:{"filled":"","hide-details":"auto","label":"Testo del messaggio","counter":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},scopedSlots:_vm._u([{key:"counter",fn:function({ props }){return [_c('span',_vm._b({style:({
            'color': _vm.countCharStringColor
          })},'span',props,false),[_vm._v(_vm._s(_vm.countCharString)+" ")])]}}],null,true),model:{value:(_vm.customTextMessage),callback:function ($$v) {_vm.customTextMessage=$$v},expression:"customTextMessage"}})]}},{key:"extra-icons",fn:function({item}){return [(item.id != '_personalized')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleEditSmsModel(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(item.id != '_personalized')?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"grey darken-2","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {
          _vm.alertAction = _vm.sendModelMassive
          _vm.alertParams = item
          _vm.alertOverlay = true
        }).apply(null, arguments)}}},[_vm._v(" Invia ")]):_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"grey darken-2","disabled":item.id == '_personalized' && !_vm.customTextMessage,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {
          _vm.alertAction = _vm.sendCustomTextMassive
          _vm.alertParams = undefined
          _vm.alertOverlay = true
        }).apply(null, arguments)}}},[_vm._v(" Invia ")])]}}])}),_c('StandardDialog',{attrs:{"title":_vm.editSmsModelDialogTitle,"dialog-height":null,"persistent":"","dialog-width":"1000"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.loadingUpdate},on:{"click":function($event){_vm.editSmsModelDialog = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"text":"","color":"default","loading":_vm.loadingUpdate,"disabled":!_vm.editSmsModelValid},on:{"click":_vm.updateSmsModel}},[_vm._v("Salva")])]},proxy:true}]),model:{value:(_vm.editSmsModelDialog),callback:function ($$v) {_vm.editSmsModelDialog=$$v},expression:"editSmsModelDialog"}},[_c('div',[_c('SmsModelForm',{staticClass:"py-2",attrs:{"hide-upload-image":true,"valid":_vm.editSmsModelValid},on:{"update:valid":function($event){_vm.editSmsModelValid=$event}},model:{value:(_vm.editSmsModel),callback:function ($$v) {_vm.editSmsModel=$$v},expression:"editSmsModel"}})],1)]),_c('SimplePopUp',{attrs:{"alert-text":_vm.alertText,"dismissible":false},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-row',{staticClass:"pt-7 d-flex justify-space-around"},[_c('v-btn',{staticStyle:{"min-width":"30%"},attrs:{"color":"error"},on:{"click":function($event){_vm.alertOverlay = false}}},[_vm._v(" Annulla ")]),_c('v-btn',{staticStyle:{"min-width":"30%"},attrs:{"color":"success"},on:{"click":() => {
            _vm.alertAction(_vm.alertParams)
            _vm.alertOverlay = false
            _vm.$delegates.snackbar('Sms inviato con successo');
          }}},[_vm._v(" Invia ")])],1)]},proxy:true}]),model:{value:(_vm.alertOverlay),callback:function ($$v) {_vm.alertOverlay=$$v},expression:"alertOverlay"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }