var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative mx-auto bg-gray-800",style:({
    'border-width': '14px', 
    'border-radius': '2.5rem', 
    'width': this.width, 
    'height': this.height
  })},[_c('div',{staticClass:"mx-auto bg-gray-800 rounded-l-lg",staticStyle:{"position":"absolute","width":"3px","height":"32px","top":"72px","left":"-3px"}}),_c('div',{staticClass:"mx-auto bg-gray-800 rounded-l-lg",staticStyle:{"position":"absolute","width":"3px","height":"46px","top":"124px","left":"-3px"}}),_c('div',{staticClass:"mx-auto bg-gray-800 rounded-l-lg",staticStyle:{"position":"absolute","width":"3px","height":"32px","top":"178px","left":"-3px"}}),_c('div',{staticClass:"mx-auto bg-gray-800 rounded-l-lg",staticStyle:{"position":"absolute","width":"3px","height":"64px","top":"142px","right":"-3px"}}),_c('div',{staticStyle:{"border-radius":"2rem","overflow":"hidden","width":"91%","height":"95%","background-color":"white","right":"4.3%","top":"2.3%","position":"absolute"}},[(!!_vm.smsModel && !!_vm.smsModel.text && _vm.smsModel.text != '')?_c('div',{staticClass:"mt-1 d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.sendTime.length === 8 ? _vm.sendTime.slice(0, -3) : _vm.sendTime)+" ")]):_vm._e(),_c('div',{staticClass:"yours messages mt-1",style:({
        'max-height': this.$vuetify.breakpoint.xl ? '92%' : '92%',  
        'overflow': 'auto'
      })},[(!!_vm.smsModel && !!_vm.smsModel.text && _vm.smsModel.text != '')?_c('div',{ref:"mobilePreview",staticClass:"message last",style:({
          'max-width': '80%',
          'font-size': '0.7rem',
          'margin-left': this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ?
            '0.5vw' : this.$vuetify.breakpoint.md ? '0.5vw' : '0.5vw'
        })},[_vm._v(" "+_vm._s(_vm.calculateMessageText(_vm.smsModel))+" ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }