import recentOperationTracker from '@/services/common/recentOperationTracker'

class registriesRecentOperationsDatabase extends recentOperationTracker {
  constructor() {
    super()
  }

  name() {
    return 'registry'
  }

  _validateOperation(operation) {
    if(!operation.registry) {
      return 'registry must be defined'
    } else {
      return true
    }
  }
}

export default new registriesRecentOperationsDatabase()