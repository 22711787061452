<template>
  <div
    class="receipt-style"
    :style="{
      width: this.width,
      height: this.height,
      'min-height': this.minHeight,
      'max-height': this.maxHeight,
    }"
  >
    <div
      style="display: flex; flex: 1; flex-direction: column; overflow-y: auto"
    >
      <div class="d-flex justify-center">
        <v-img
          max-width="125"
          src="@/assets/LOGO_LULUU.png"
        ></v-img>
      </div>

      <div
        v-if="!!receiptModel && !!receiptModel.factoryData"
        class="d-flex justify-center align-center flex-column"
      >
        <span> GILDA SRL </span>
        <span> Viale Monza 76 - Milano 20127 </span>
        <span> P.I. 837491485 </span>
        <span> Tel 022828256 </span>
      </div>


      <div class="d-flex justify-center align-center mt-10">
        <span 
          style="font-size: 0.9rem" 
          class="title-section-receipt"
        > 
          DOCUMENTO GESTIONALE 
        </span>
      </div>
      
      <div
        v-if="!!receiptModel && !!receiptModel.title"
        class="d-flex justify-center align-center mt-6 mb-5"
      >
        <span 
          style="font-size: 1.3rem" 
          class="title-section-receipt"
        > 
          {{ receiptModel.title }}
        </span>
      </div>

      <div 
        v-if="!!receiptModel && !!receiptModel.text"
        class="justify-center align-center flex-column mb-5"
        style="display: grid;"
      >
        <span 
          v-for="(row, index) in receiptModel.text" :key="index"
          class="text-center"
          style="height: fit-content; white-space: break-spaces; 
            overflow: hidden; width: 100%;
            justify-self: center; align-self: center;"
        >
          {{ row }}              
        </span>
      </div> 

      <div>
        <v-img
          v-if="!!receiptModel && receiptModel.type == 'barcode' && !!receiptModel.barcodeId"
          style="right: 0.5px;"
          src="@/assets/code-39.png"
        >
        </v-img>
      </div>

      <div>
        <v-img
          v-if="!!receiptModel && receiptModel.type == 'qrcode' && !!receiptModel.qrcode"
          width="8vw"
          class="mx-auto"
          src="@/assets/qr-code.png"
        >
        </v-img>
      </div>
      
      <div 
        v-if="!!receiptModel && !!receiptModel.additionalText"
        class="justify-center align-center flex-column mt-5 mb-6"
        style="display: grid;"
      >
        <span 
          v-for="(row, index) in receiptModel.additionalText" :key="index"
          class="text-center"
          style="height: fit-content; white-space: break-spaces; 
          overflow: hidden; width: 100%;
          justify-self: center; align-self: center;"
        >
          {{ row }}              
        </span>
      </div>

      <div class="d-flex justify-center align-center">
        <span v-if="!!receiptModel && !!receiptModel.cashDeskOperatorThanks">
            *Nome Operatore* è stato lieto di servirla
        </span>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex justify-center align-center flex-column">
        <span> 
          {{ new Date().toISOString().split('T')[0] }} 
          {{ new Date().toISOString().split('T')[1].slice(0, -8) }} 
        </span>
        <span> DOCUMENTO N. 0284 </span>
        <span> CASSA NUMERO 1</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReceiptPreview',
  components: {},
  data: function() {
    return {
    }
  },
  props: {
    receiptModel: {
      type: Object,
      default: undefined
    },
    width: {
      type: String,
      default: '15vw'
    },
    height: {
      type: String,
      default: '45vh'
    },
    minHeight: {
      type: String,
      default: '45vh'
    },
    maxHeight: {
      type: String,
      default: '45vh'
    }
  },
  mounted: function() {
  }
}

</script>

<style>
.receipt-style {
  background: #eee;
  position: relative;
  padding: 8px 8px 32px 8px;
  display: flex;
  flex-direction: column;
  border-radius: 0px !important;
}

.receipt-style::before {
  background: linear-gradient(-45deg, #eee 16px, transparent 0), linear-gradient(45deg, #eee 16px, transparent 0);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 18px 30px;
  content: " ";
  display: block;
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  height: 20px;
}


.receipt-style:after {
  background: linear-gradient(-45deg, #ffffff 16px, transparent 0), linear-gradient(45deg, #ffffff 16px, transparent 0);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: 18px 30px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 20px;
}
</style>