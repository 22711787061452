var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"chart"}},[_c('TitleWithBackButton',{attrs:{"title":"Riepilogo Periodo Commerciale","back-route":"Analytics","icon":"mdi-graphql"}}),_c('div',[_c('div',{staticClass:"d-flex align-center ml-3 mt-3 flex-wrap"},[_c('RangeDateSelector',{attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"loading":_vm.loading,"disabled":_vm.loading,"max-width":!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : '50vw'},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event}}}),_c('div',{staticClass:"ml-7",style:({
          'max-width': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
            ? '60vw' : '20vw',
          'margin-top': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ?
            '10px' : '0px'
        })},[_c('TagsCategoriesAutocomplete',{attrs:{"label":"Scelta Tag","return-object":"","prepend-icon":null,"filters":{ typeCost: false, excludeInBalance: false, notGeneral: true }},model:{value:(_vm.selectedTagCategories),callback:function ($$v) {_vm.selectedTagCategories=$$v},expression:"selectedTagCategories"}})],1),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.calculate}},[_vm._v(" Calcola ")]),_c('v-btn',{staticClass:"btn-export",attrs:{"loading":_vm.loading},on:{"click":_vm.exportToExcel}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1),_c('v-btn',{staticClass:"mr-2 ml-2",class:{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm},attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.print}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right","margin-right":"2.5%"},attrs:{"icon":"","large":"","disabled":_vm.checkObjectLength() || _vm.loading,"color":!!_vm.isB ? 'red' : ''},on:{"click":() => { _vm.dialogBarcode = !_vm.dialogBarcode }}},[_c('v-icon',[_vm._v("mdi-shield-account-variant")])],1)],1),(_vm.loading)?_c('div',{staticClass:"text-center mt-2"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.checkObjectLength())?_c('div',{staticClass:"text-center font-weight-light mt-2"},[_vm._v(" Nessun risultato ")]):_vm._e(),_c('div',[_c('div',[_c('v-col',{staticClass:"mt-5"},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":_vm.generalChartData,"styles":{
              height: '300px',
              width: '300px'
            },"options":{
              mainAspectRatio: true,
              responsive: true,
              legend: {
                display: false
              },
              showTooltips: false,
            }}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center"},[_c('TypeDataTable',{staticClass:"mb-10",staticStyle:{"background-color":"transparent"},attrs:{"show-tooltips":true,"fixed-headers":"","headersColor":"transparent","tooltip-delay":1500,"headers":[
                { text: '', value: 'color', type: 'color' },
                { text: '', value: 'name' },
                { text: 'Totale', value: 'total', type: 'price' },
                { text: 'Percentuale', value: 'percentage' },
                { text: 'N° Tran.', value: 'count' },
                { text: 'Fiche Media', value: 'avg', type: 'price' },
                { text: 'N° Clienti', value: 'customersCount'}
              ],"items":_vm.generalTableData,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true}})],1)],1)],1),(!_vm.checkObjectLength())?_c('div',{staticClass:"px-5"},[_c('span',{staticClass:"pl-5 mb-5 subtitle"},[_vm._v("Δ Valore")]),_c('v-divider'),_c('v-row',{staticClass:"mt-5 d-flex flex-nowrap",staticStyle:{"overflow-x":"auto"}},[_vm._l((_vm.results),function(result,key){return _c('v-col',{key:key,staticClass:"px-1",attrs:{"cols":Math.min(3, Math.round(12 / (_vm.results.length + 1)))}},[_c('v-expansion-panels',{staticClass:"mb-5",attrs:{"popout":"","flat":"","multiple":""},model:{value:(result.panel),callback:function ($$v) {_vm.$set(result, "panel", $$v)},expression:"result.panel"}},[_c('v-expansion-panel',{attrs:{"disabled":""}},[_c('v-expansion-panel-header',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(result.productAndService.name)+" ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":result.productAndServiceChartData,"styles":{
                        height: '300px',
                        width: '300px'
                      },"options":{
                        mainAspectRatio: true,
                        responsive: true,
                        legend: {
                          display: false
                        },
                        showTooltips: false,
                      }}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: '€', value: 'total', type: 'price' },
                        { text: '%', value: 'percentage' },
                        { text: 'N°', value: 'count' },
                        { text: 'F.M.', value: 'avg', type: 'price' }
                      ],"items":result.productAndServiceRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Penetrazione Acquisti vs Clienti ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":result.purchaseCustomersTotalChartData,"styles":{
                          height: '150px',
                          width: '150px'
                        },"options":{
                          mainAspectRatio: true,
                          responsive: true,
                          legend: {
                            display: false
                          },
                          showTooltips: false,
                        }}})],1),_c('v-col',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":result.purchaseCustomersGenderWithItemsChartData,"styles":{
                        height: '150px',
                        width: '150px'
                      },"options":{
                        mainAspectRatio: true,
                        responsive: true,
                        legend: {
                          display: false
                        },
                        showTooltips: false,
                      }}})],1)],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[_c('TypeDataTable',{staticClass:"mb-5 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: 'N°', value: 'count' },
                        { text: '%', value: 'percentage' },
                      ],"items":result.purchaseCustomersTotalRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1),_c('v-row',{staticClass:"pt-1 d-flex justify-center px-1"},[_c('TypeDataTable',{staticClass:"mb-5 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: 'N°', value: 'count' },
                        { text: '%', value: 'percentage' },
                        { text: 'F.M.', value: 'avgItems', type: 'price' },
                        { text: 'N° Pz.', value: 'countItems' },
                        { text: 'X̅ Pz.', value: 'avgCountItems' },
                      ],"items":result.purchaseCustomersGenderWithItemsRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1),_c('v-row',{staticClass:"pt-1 d-flex justify-center px-1"},[_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: 'N°', value: 'count' },
                        { text: '%', value: 'percentage' },
                      ],"items":result.purchaseCustomersGenderWithoutItemsRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Totale Servizi vs Passaggi Cassa ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":result.servicesCustomersTotalChartData,"styles":{
                        height: '300px',
                        width: '300px'
                      },"options":{
                        mainAspectRatio: true,
                        responsive: true,
                        legend: {
                          display: false
                        },
                        showTooltips: false,
                      }}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: '%', value: 'percentage' },
                        { text: 'N°', value: 'count' },
                        { text: 'M.S.', value: 'avg' }
                      ],"items":result.servicesCustomersTotalRows,"show-select":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""},model:{value:(_vm.servicesCustomersTotalSelected),callback:function ($$v) {_vm.servicesCustomersTotalSelected=$$v},expression:"servicesCustomersTotalSelected"}})],1),_c('v-row',{staticClass:"d-flex justify-center"},[(!_vm.servicesCustomersReload)?_c('PieChart',{attrs:{"chart-data":result[_vm.servicesCustomersChartData],"styles":{
                        height: '300px',
                        width: '300px'
                      },"options":{
                        mainAspectRatio: true,
                        responsive: true,
                        legend: {
                          display: false
                        },
                        showTooltips: false,
                      }}}):_c('div',[_c('v-progress-circular',{attrs:{"size":300,"indeterminate":""}})],1)],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: 'N°', value: 'count' },
                        { text: '%', value: 'percentage' },
                        { text: 'M. S.', value: 'avg' }
                      ],"items":result[_vm.servicesCustomersRows],"show-select":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""},model:{value:(_vm.servicesCustomersDetailsKeysRow),callback:function ($$v) {_vm.servicesCustomersDetailsKeysRow=$$v},expression:"servicesCustomersDetailsKeysRow"}})],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('GroupBasedChart',{attrs:{"no-data-text":"Selezionare una fascia di età","height":"200px","width":"100%","type":"bar","transparent":"","colors":_vm.servicesCustomersDetailsColors,"point-colors":[],"getYValue":(object) => {
                          return object[`countServices`];
                        },"getXValue":(object) => {
                          return object[`tagName`];
                        },"tooltip-label-modifier":(tooltipLabel) => {
                          return 'Quantità: ' + tooltipLabel.yLabel;
                        },"x-axes-modifier":_vm.xAxesServicesCustomersDetail,"data":[_vm.servicesCustomersDetailsChartData(result)],"loading":_vm.servicesCustomersDetailReload}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[(!_vm.servicesCustomersDetailReload)?_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"no-data-text":"Selezionare una fascia di età","headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: '%', value: 'percentage' },
                        { text: 'N°', value: 'count' },
                      ],"items":_vm.servicesCustomersDetailsRows(result),"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}}):_vm._e()],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Transazioni di cassa e Clienti ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('GroupBasedChart',{attrs:{"height":"200px","width":"100%","type":"bar","transparent":"","colors":_vm.paymentTransactionsColors,"point-colors":[],"getYValue":(object) => {
                          return object[`count`];
                        },"getXValue":(object) => {
                          return object[`name`];
                        },"tooltip-label-modifier":(tooltipLabel) => {
                          return 'Quantità: ' + tooltipLabel.yLabel;
                        },"x-axes-modifier":_vm.xAxesServicesCustomersDetail,"data":[result.paymentTransactionsTotalChartData],"loading":_vm.servicesCustomersDetailReload}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[(!_vm.servicesCustomersDetailReload)?_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: '%', value: 'percentage' },
                        { text: 'N°', value: 'count' },
                      ],"items":result.paymentTransactionsTotalRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}}):_vm._e()],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('GroupBasedChart',{attrs:{"height":"200px","width":"100%","type":"bar","transparent":"","colors":_vm.paymentTransactionsCustomersColors,"point-colors":[],"getYValue":(object) => {
                          return object[`count`];
                        },"getXValue":(object) => {
                          return object[`name`];
                        },"tooltip-label-modifier":(tooltipLabel) => {
                          return 'Quantità: ' + tooltipLabel.yLabel;
                        },"x-axes-modifier":_vm.xAxesServicesCustomersDetail,"data":[result.paymentTransactionsCustomersChartData],"loading":_vm.servicesCustomersDetailReload}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[(!_vm.servicesCustomersDetailReload)?_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: '%', value: 'percentage' },
                        { text: 'N°', value: 'count' },
                      ],"items":result.paymentTransactionsCustomersRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}}):_vm._e()],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Penetrazione Marketing ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"pt-2"},[_vm._v(" vs Transazioni di Cassa ")]),_c('v-row',[_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'name' },
                        { text: 'N°', value: 'count' },
                        { text: '%', value: 'percentage' },
                      ],"items":result.marketingTransactionsRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1),_c('v-row',[_vm._v(" vs Clienti ")]),_c('v-row',[_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'name' },
                        { text: 'N°', value: 'count' },
                        { text: '%', value: 'percentage' },
                      ],"items":result.marketingCustomersRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)],1)],1)],1)],1)}),_c('v-col',{attrs:{"cols":Math.min(3, Math.round(12 / (_vm.results.length + 1)))}},[_c('v-expansion-panels',{staticClass:"mb-5",attrs:{"popout":"","flat":"","multiple":""},model:{value:(_vm.commercial.panel),callback:function ($$v) {_vm.$set(_vm.commercial, "panel", $$v)},expression:"commercial.panel"}},[_c('v-expansion-panel',{attrs:{"disabled":""}},[_c('v-expansion-panel-header',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(_vm.commercial.productAndService.name)+" ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":_vm.commercial.productAndServiceChartData,"styles":{
                        height: '300px',
                        width: '300px'
                      },"options":{
                        mainAspectRatio: true,
                        responsive: true,
                        legend: {
                          display: false
                        },
                        showTooltips: false,
                      }}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center px-1"},[_c('TypeDataTable',{staticClass:"mb-10 balance-table",staticStyle:{"background-color":"transparent","width":"100%"},attrs:{"headersColor":"transparent","headers":[
                        { text: '', value: 'color', type: 'color' },
                        { text: '', value: 'name' },
                        { text: '€', value: 'total', type: 'price' },
                        { text: '%', value: 'percentage' },
                        { text: 'N°', value: 'count' },
                        { text: 'F.M.', value: 'avg', type: 'price' }
                      ],"items":_vm.commercial.productAndServiceRows,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":"","disable-sort":""}})],1)],1)],1)],1)],1)],2)],1):_vm._e()])]),_c('StandardDialog',{attrs:{"title":"Area Riservata Sviluppatori","dialog-height":null,"dialog-max-width":"500px"},model:{value:(_vm.dialogBarcode),callback:function ($$v) {_vm.dialogBarcode=$$v},expression:"dialogBarcode"}},[_c('ManualBarcodeInput',{attrs:{"scan-your-barcode-message":"Immettere Codice"},on:{"confirm":_vm.activeBarcode},model:{value:(_vm.operatorBarcode),callback:function ($$v) {_vm.operatorBarcode=$$v},expression:"operatorBarcode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }