<template>
  <div>
    <TitleWithBackButton
      title="Tags"
      back-route="Analytics"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <crudCardList
      :service="service"
      :item-description="getTagDescription"
      item-type="tags"
      class="ma-2"
      :new-valid="newFormValid"
      :edit-valid="editFormValid"
      :hide-filter="true"
      :hide-loading="true"
      :deletable="currentUser.system"
      :editable="currentUser.system"
      :creatable="currentUser.system"
      no-data-message="Nessun tag disponibile"
      item-name="description"
    >
      <template v-slot:new-form="{ item }">
        <v-form v-model="newFormValid" class="mt-2">
          <v-text-field
            v-model="item.description"
            label="Description"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
          ></v-text-field>
          <TagsCategoriesAutocomplete
            class="mt-2"
            v-model="item.tagCategories"
            return-object
            :multiple="false"
            :rules="[presenceCategory]"
          ></TagsCategoriesAutocomplete>
        </v-form>
      </template>

      <template v-slot:update-form="{ item }">
        <v-form v-model="editFormValid" class="mt-2">
          <v-text-field
            v-model="item.description"
            label="Description"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
          ></v-text-field>

          <TagsCategoriesAutocomplete
            class="mt-2"
            v-model="item.tagCategories"
            return-object
            :multiple="false"
            :rules="[presenceCategory]"
          ></TagsCategoriesAutocomplete>
        </v-form>
      </template>
    </crudCardList>
  </div>
</template>

<script>
import crudCardList from "@/components/common/crudCardList.vue"
import tagsService from "@/services/tags/tags.service.js"
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue"
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "Tags",
  components: {
    crudCardList,
    TagsCategoriesAutocomplete,
    TitleWithBackButton
  },
  data: function() {
    return {
      service: {
        list: () => {
          return tagsService.list().then((response) => {
            return response.rows.sort((a, b) => {
              return a.tagCategories.name.localeCompare(b.tagCategories.name)
            })
          })
        },
        create: tagsService.create,
        update: tagsService.update,
        delete: tagsService.delete
      },
      newFormValid: false,
      editFormValid: false,
      currentUser: {},
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      presenceCategory: (v) => {
        return !!v
      },
    }
  },
  currentUser: {
    bind: 'currentUser'
  },
  methods: {
    getTagDescription(tag) {
      if(!!tag.tagCategories) {
        return tag.tagCategories.name
      } else {
        return "Nessuna categoria"
      }
    },
  },
  computed: {
  }
}
</script>

<style>

</style>