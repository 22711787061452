<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        :hide-details="hideDetails"
        @click:prepend="menu = true"
        readonly
        :loading="loading"
        :filled="filled"
        :rounded="rounded"
        :outlined="outlined"
        :solo="solo"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :style="!!styles && !!styles.date ? styles.date : undefined"
        :dense="dense"
        :solo-inverted="soloInverted"
        :error="error"
      ></v-text-field>
    </template>
    <v-date-picker
      locale="it"
      v-model="datepicker"
      @input="menu = false"
      :allowed-dates="allowedDates"
      :min="min"
      :max="max"
      :readonly="readonly"
      :disabled="disabled"
      :rules="rules"
      first-day-of-week="1"
      :type="dateType"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  data: function() {
    let isString = typeof this.value === "string";

    return {
      menu: false,
      // this should always be yyyy-mm-dd
      datepicker: this.dateType == 'date' ? this.dateToString('yyyy-mm-dd', this.value) : this.dateToString('yyyy-mm', this.value),
      // this should always be Date object
      localValue: !isString ? this.value : new Date(this.value),
    }
  },
  props: {
    value: {
      type: Date | String,
    },
    bus: {
      type: Object,
      default: undefined
    },
    solo: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Date'
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar"
    },
    appendIcon: {
      type: String,
      default: undefined
    },
    hideDetails: {
      default: 'auto'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'dd/mm/yyyy',
      validator: function (value) {
        return ['dd/mm/yyyy', 'yyyy-mm-dd'].indexOf(value) !== -1
      }
    },
    min: {
      type: String,
      default: undefined,
    },
    max: {
      type: String,
      default: undefined,
    },
    allowedDates: {
    },
    styles: {
      type: Object
    },
    hours: {
      type: Number,
      default: undefined
    },
    minutes: {
      type: Number,
      default: undefined
    },
    rules: {
      type: Array,
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    error:{
      type: Boolean,
      default: false
    },
    soloInverted: {
      type: Boolean,
      default: false
    },
    dateType: {
      type: String,
      default: 'date'
    }
  },
  methods: {
    dateToString(format, date) {
      let date_string = ""
      if(!date) {
        // return this.dateToString(format, new Date())
      } else if(Object.prototype.toString.call(date) !== "[object Date]") {
        return "not valid date"
      } else if(format == "yyyy-mm-dd") {
        date_string += date.getFullYear() + '-'
        date_string += ('0' + (date.getMonth()+1)).slice(-2) + '-'
        date_string += ('0' + date.getDate()).slice(-2)
      } else if(format == "dd/mm/yyyy") {
        date_string += ('0' + date.getDate()).slice(-2) + '/'
        date_string += ('0' + (date.getMonth()+1)).slice(-2) + '/'
        date_string += date.getFullYear()
      } else if (format == 'yyyy-mm') {
        date_string += date.getFullYear() + '-'
        date_string += ('0' + (date.getMonth()+1)).slice(-2)
      }  else {
        return "format_not_valid"
      }
      
      return date_string
    },
    stringToDate(format, date, fromDate = null) { 
      if(format == 'yyyy-mm-dd') {
        let info = date.split('-')
        let dateOutput = new Date(fromDate)
        dateOutput.setMilliseconds(0)
        dateOutput.setHours(0)
        dateOutput.setMinutes(0)
        dateOutput.setFullYear(parseInt(info[0]))
        dateOutput.setMonth(parseInt(info[1]) - 1)
        dateOutput.setDate(parseInt(info[2]))
        if(!!this.hours)
          dateOutput.setHours(this.hours)
        if(!!this.minutes)
          dateOutput.setMinutes(this.minutes)
        return dateOutput
      } else if(format == 'dd/mm/yyyy') {
        let info = date.split('/')
        let dateOutput = new Date(fromDate)
        dateOutput.setMilliseconds(0)
        dateOutput.setHours(0)
        dateOutput.setMinutes(0)
        dateOutput.setFullYear(info[2])
        dateOutput.setMonth(parseInt(info[1]) - 1)
        dateOutput.setDate(info[0])
        if(!!this.hours)
          dateOutput.setHours(this.hours)
        if(!!this.minutes)
          dateOutput.setMinutes(this.minutes) 
        return dateOutput
      } else if (format == 'yyyy-mm') {
        let info = date.split('-')
        let dateOutput = new Date(fromDate)
        dateOutput.setMilliseconds(0)
        dateOutput.setSeconds(0)
        dateOutput.setHours(0)
        dateOutput.setMinutes(0)
        dateOutput.setFullYear(parseInt(info[0]))
        dateOutput.setMonth(parseInt(info[1]) - 1)
        dateOutput.setDate(1)
        return dateOutput
      } else {
        return "format_not_valid"
      }
    },
    dateEqual(date1, date2) {
      if((date1 && !date2) || (date2 && !date1)) return false
      if(!date1 && !date2) return true

      let equal = true
      if(date1.getMonth() != date2.getMonth()) equal = false
      if(date1.getDate() != date2.getDate()) equal = false
      if(date1.getFullYear() != date2.getFullYear()) equal = false
      if(date1.getHours() != date2.getHours()) equal = false
      if(date1.getMinutes() != date2.getMinutes()) equal = false
      return equal
    },
    isDate(variable) {
      return Object.prototype.toString.call(variable) === "[object Date]"
    },
    emit(event, ...params) {
      this.$emit(event, ...params)
      if (this.bus) {
        this.bus.$emit(event, ...params)
      }
    }
  },
  computed: {
    formattedDate() {
      return this.dateToString(this.format, this.localValue)
    }
  },
  watch: {
    datepicker(newVal) {
      if(!newVal) this.localValue = undefined
      else {
        if(this.localValue && this.isDate(this.localValue)) {
          this.localValue = this.dateType == 'date' ? this.stringToDate('yyyy-mm-dd', newVal, this.localValue)
            : this.stringToDate('yyyy-mm', newVal, this.localValue)
        }
        else {
          this.localValue = this.dateType == 'date' ? this.stringToDate('yyyy-mm-dd', newVal)
            : this.stringToDate('yyyy-mm', newVal, new Date())
        }
      } 
    },
    value(newVal) {
      if(!this.dateEqual(newVal, this.localValue)) {
        this.localValue = newVal
      }
    },
    localValue(newVal) {
      if(!newVal) {
        this.datepicker = ''
      } else {
        let valueToDatepicker = this.dateType == 'date' ? this.dateToString('yyyy-mm-dd', newVal)
          : this.dateToString('yyyy-mm', newVal)
        if(valueToDatepicker != this.datepicker) {
          this.datepicker = valueToDatepicker
        }
      }
      this.emit('input', newVal)
    }
  }
}
</script>

<style>

</style>