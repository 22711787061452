<template>
  <v-card
    :width="width"
    :height="height"
    :color="transparent ? 'transparent' : undefined"
    :flat="transparent"
    :loading="!transparent && loading"
  >
    <div style="height: 50px" v-if="showTitle" class="d-flex flex-no-wrap align-end">
      <slot name="title">
        <div class="ml-3 text-h6">{{title}}</div>
      </slot>
      <div class="ml-auto pr-2">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="chart-wrapper">
      <div 
        v-if="loading" 
        class="text-caption font-weight-light d-flex align-center justify-center"
        :style="{
          height: computedStyles['height']
        }"
      >
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <template v-else-if="chartData.datasets.length > 0">
        <LineChart
          v-if="type == 'line'"
          :chart-data="chartData"
          :styles="computedStyles"
          :options="chartOptions"
        ></LineChart>
      </template>
      <div 
        v-else 
        class="text-caption font-weight-light d-flex align-center justify-center"
        :style="{
          height: computedStyles['height']
        }"
      >
        <slot name="no-data">
          <div>
            {{noDataText}}
          </div>
        </slot>
      </div>
    </div>
    <div 
      style="height: 70px" 
      class="px-2 d-flex align-center justify-end"
      v-if="showFooter"
    >
      <v-slot name="footer"></v-slot>
    </div>
  </v-card>
</template>

<script>
import LineChart from './LineChart.vue'
import chartMixin from './chart.mixin'

export default {
  name: "Chart",
  mixins: [chartMixin],
  components: { LineChart },
  data: function() {
    return {
    }
  },
  mounted: function() {
  },
  props: {
    type: {
      type: String,
      default: 'line',
      validator: function(value) {
        return ['line'].indexOf(value) != -1
      } 
    },
    data: {
      type: Array,
      default: () => {
        /* 
          Array structure:
          [
            {
              name: String (a uniq name representing the data)
              label: String (a name to show for those data),
              data: [
                ... (whatever you want, define getX and getY functions)
              ]
            }
          ]
        */
        return []
      }
    },
    getXDate: {
      type: Function,
      default: (object) => {
        return object[Object.keys(object)[0]]
      }
    },
    getYValue: {
      type: Function,
      default: (object) => {
        return object[Object.keys(object)[1]]
      }
    },
    granularity: {
      type: String,
      default: 'month',
      validator(value) {
        return ['day', 'week', 'month', 'quarter', 'year'].indexOf(value) != -1
      }
    },
    filters: {
      type: Object,
      default: () => {
        /*
          possible filters:
          {
            from: Date (the date from),
            to: Date (the date to),
            last: String (duration based string, e.g. 15d, 1w, 1M 15d)
          }
        */
       const beginningOfYear = new Date(new Date().getFullYear(), 0, 1);
       const endOfYear = new Date(new Date().getFullYear(), 11, 31);

        return {
          from: beginningOfYear,
          to: endOfYear,
        }
      }
    },
    maintainAspectRatio: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: true
    },
    noDataText: {
      type: String,
      default: 'DATI INSUFFICIENTI'
    },
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Array,
      default: undefined
    },
    pointColors: {
      type: Array,
      default: undefined
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "400px"
    },
    width: {
      type: String,
      default: "600px"
    },
    styles: {
      type: Object,
      default: function() {
        return { }
      }
    },
    maxValue:{
      type: Number,
      default: undefined
    },
    minValue:{
      type: Number,
      default: 0
    }
  },
  methods: {
    getLabelsByGranularity() {
      if(this.granularity == 'month') 
        return this.monthLabels(this.filters.from, this.filters.to)
      else if(this.granularity == 'week')
        return this.weekLabels(this.filters.from, this.filters.to)
      else if(this.granularity == 'day')
        return this.dayLabels(this.filters.from, this.filters.to)
      else if(this.granularity == 'quarter')
        return this.quarterLabels(this.filters.from, this.filters.to)
      else if(this.granularity == 'year')
        return this.yearLabels(this.filters.from, this.filters.to)
    },
    handleClick(event, element) {
      if (!!element && element.length > 0) {
        let activeElement = element[0];
        let data = activeElement._chart.data;
        let barIndex = activeElement._index;
        let datasetIndex = activeElement._datasetIndex;

        let datasetLabel = data.datasets[datasetIndex].label;
        let xLabel = data.labels[barIndex];
        let yLabel = data.datasets[datasetIndex].data[barIndex];

        this.$emit('on-tooltip-click', {
          'datasetLabel': datasetLabel,
          'xLabel': xLabel,
          'yLabel': yLabel,
        })
      }
      else
        this.$emit('on-tooltip-click', undefined)
    }
  },
  computed: {
    chartOptions() {
      return {
        maintainAspectRatio: this.maintainAspectRatio,
        responsive: this.responsive,
        tooltips: {
          enabled: true,
          callbacks: {
            label: this.tooltipLabelModifier
          }
        },
        onClick: this.handleClick,
        scales: {
          yAxes:[{
            display:true,
            ticks:{
              min: this.minValue,
              max: this.maxValue
            }
          }]
        }
      }
    },
    computedStyles() {
      const heightToRemove = 0
      if(this.showTitle) heightToRemove += 50
      if(this.showFooter) heightToRemove += 70

      return {
        ...this.styles,
        height: "calc(" + this.height + " - " + heightToRemove + "px)",
      }
    },
    labels() {
      return this.getLabelsByGranularity()
    },
    chartData() {
      let datasets = []

      for(let i = 0; i < this.data.length; i += 1) {
        if(!this.data[i].data || !Array.isArray(this.data[i].data)) continue
        const datasetName = this.data[i].name
        const datasetLabel = this.data[i].label
        let datasetHash = {}, datasetData = []

        for(let k = 0; k < this.data[i].data.length; k += 1) {
          const date = this.getXDate(this.data[i].data[k])
          const labelKey = this.getLabelKeyFromDate(date, this.granularity)

          if(!!datasetHash[labelKey]) 
            datasetHash[labelKey] += Number(this.getYValue(this.data[i].data[k]))
          else
            datasetHash[labelKey] = Number(this.getYValue(this.data[i].data[k]))
        }

        for(let k = 0; k < this.labels.length; k += 1) {
          datasetData.push(datasetHash[this.labels[k].key])
        }

        if (!this.colors || !this.colors.length) {
          datasets.push({
            label: datasetLabel,
            color: this.getColor(i),
            fill: false,
            //backgroundColor: this.color,
            pointBackgroundColor: 'white', 
            borderWidth: 1, 
            pointBorderColor: 'black',
            data: datasetData
          })
        } 
        else {
          datasets.push({
            label: datasetLabel,
            color: this.colors[i % this.colors.length],
            fill: false,
            backgroundColor: this.colors,
            borderColor: !!this.pointColors ? this.pointColors: 'black',
            pointBackgroundColor: !!this.pointColors ? this.pointColors[i % this.pointColors.length] : 'black', 
            borderWidth: 2, 
            pointBorderColor: !!this.pointColors ? this.pointColors[i % this.pointColors.length] :'black',
            data: datasetData,
          })
        }

      }

      return {
        labels: this.labels.map(lab => lab.label),
        datasets: datasets
      }
    }
  }
}
</script>

<style>

</style>