import authService from "@/services/auth/auth.service.js"

const promosRoutes = [
  {
    path: "/payments/promos/list",
    name: "PromosList",
    component: () => import("@/components/areas/payments/promos/PromosList.vue"),
    props: (route) => {
      return {
        filters: route.params.filters,
      }
    }
  },
  {
    path: "/payments/promos/new",
    name: "PromosNewForm",
    component: () => import("@/components/areas/payments/promos/PromosNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/promos/:id/edit",
    name: "PromosEditForm",
    component: () => import("@/components/areas/payments/promos/PromosEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters,
      }
    },
  }
];

export default promosRoutes;