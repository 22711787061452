var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"ageGroupCustomers"}},[_c('TitleWithBackButton',{attrs:{"title":"Clienti per Fasce d'età","back-route":"Analytics"}}),_c('div',{staticClass:"d-flex align-center ml-8 mt-3 flex-wrap"},[_c('RangeDateSelector',{attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"loading":_vm.loading,"disabled":_vm.loading,"width":"50vw"},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event}}}),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.calculate}},[_vm._v("Calcola")]),_c('v-spacer'),_c('v-btn',{staticClass:"btn-export",attrs:{"loading":_vm.loading},on:{"click":_vm.exportToExcel}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.print}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),_c('v-btn',{staticStyle:{"float":"right","margin-right":"2.5%"},attrs:{"icon":"","large":"","disabled":_vm.loading,"color":!!_vm.ghost ? 'red' : ''},on:{"click":() => { _vm.dialogBarcode = !_vm.dialogBarcode }}},[_c('v-icon',[_vm._v("mdi-shield-account-variant")])],1)],1),_c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[(!_vm.loading)?_c('div',{staticClass:"mb-2 ml-10"},[_c('span',{staticClass:"card-title"},[_vm._v("Clienti per Fasce d'età di tutto il salone")])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"12","md":"9","lg":"9","xl":"9","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
         ? '2' : '1'}},[(_vm.loading)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
            height: '100%'
          })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.groupedAgeRowsAllCustomers.length > 0)?_c('TypeDataTable',{staticClass:"pb-5",staticStyle:{"background-color":"transparent"},attrs:{"id":"table","fixed-header":"","headersColor":"rgb(224, 224, 230)","headers":_vm.headersAllCustomers,"items":_vm.groupedAgeRowsAllCustomers,"results-for-loading":!_vm.graphResults,"loading":_vm.loading,"show-select":false,"disablePagination":true,"select-on-row-click":false},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('v-row',{on:{"mouseover":function($event){return _vm.preFetch(item, 'allCustomers')}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.customersSendMessage = true}}},[_c('v-icon',{attrs:{"dense":"","color":"blue darken-2"}},[_vm._v("mdi-message-text-fast")])],1)],1)]}}])}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3","xl":"3","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
         ? '1' : '2'}},[(_vm.loading)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
            height: '100%'
          })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.groupedAgeRowsAllCustomers.length > 0)?_c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"margin-bottom":"10px","margin-top":"-5px","font-size":"larger","font-weight":"500"}},[_c('span',{staticClass:"d-flex title-section",staticStyle:{"font-size":"1rem"}},[_vm._v(" Numero Clienti : "+_vm._s(_vm.totalAllCustomers)+" ")])]),_c('div',{staticClass:"d-flex justify-center"},[(!_vm.loading || !!_vm.graphResults)?_c('DoughnutChart',{attrs:{"chart-data":_vm.allCustomersChartData,"styles":{
                height: this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                  ? '30vw' : '20vw',
                width: this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                  ? '30vw' : '20vw'
              },"options":_vm.chartOptions}}):_vm._e()],1)]):_vm._e()])],1),(!_vm.loading && _vm.groupedAgeRowsAllCustomers.length == 0)?_c('div',{staticClass:"text-center font-weight-light mt-2"},[_vm._v(" Nessun risultato ")]):_vm._e(),_c('v-divider',{staticStyle:{"margin-top":"30px","margin-bottom":"30px"}}),(!_vm.loading)?_c('div',{staticClass:"mb-2 ml-10"},[_c('span',{staticClass:"card-title"},[_vm._v("Clienti per Fasce d'età del periodo selezionato")])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"lg":"9","xl":"9","md":"12","cols":"12","sm":"12","order":this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
         ? '2' : '1'}},[(_vm.loading)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
            height: '100%'
          })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.groupedAgeRows.length > 0)?_c('TypeDataTable',{staticClass:"pb-5",staticStyle:{"background-color":"transparent"},attrs:{"id":"table_paying","fixed-header":"","headersColor":"rgb(224, 224, 230)","headers":_vm.headers,"items":_vm.groupedAgeRows,"results-for-loading":!_vm.results,"loading":_vm.loading,"show-load":false,"show-select":false,"select-on-row-click":false,"disablePagination":true},on:{"rowClicked":_vm.handleClickPayingCustomers},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('v-row',{staticStyle:{"width":"fit-content","flex-wrap":"nowrap"},attrs:{"no-gutters":""},on:{"mouseover":function($event){return _vm.preFetch(item, 'payingCustomers')}}},[_c('v-col',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.customersSendMessage = true}}},[_c('v-icon',{attrs:{"dense":"","color":"blue darken-2"}},[_vm._v("mdi-message-text-fast")])],1)],1),_c('v-col',[_c('ExportExcelTable',{attrs:{"worksheetName":_vm.worksheetName,"fileName":_vm.generateExcelName(item),"fieldsToExport":_vm.fieldsToExport,"fetch":_vm.fetchExcel},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"dense":"","color":"green darken-2"}},[_vm._v("mdi-microsoft-excel")])],1)]},proxy:true}],null,true)})],1)],1)]}}])}):_vm._e()],1),_c('v-col',{attrs:{"lg":"3","xl":"3","md":"12","cols":"12","sm":"12","order":this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
         ? '1' : '2'}},[(_vm.loading)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
            height: '100%'
          })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.groupedAgeRows.length > 0)?_c('div',{staticClass:"d-flex justify-center align-center flex-column",style:({
            'margin-bottom': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
              ? '10px' : 0
          })},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"margin-bottom":"10px","margin-top":"-5px","font-size":"larger","font-weight":"500"}},[_c('span',{staticClass:"d-flex title-section",staticStyle:{"font-size":"1rem"}},[_vm._v(" Numero Clienti : "+_vm._s(_vm.totalCustomers)+" ")])]),_c('div',{staticClass:"d-flex justify-center"},[(!_vm.loading || !!_vm.results)?_c('DoughnutChart',{attrs:{"chart-data":_vm.payingCustomersChartData,"styles":{
                height: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                  ? '30vw' : '20vw',
                width: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                  ? '30vw' : '20vw'
              },"options":_vm.chartOptions}}):_vm._e()],1)]):_vm._e()])],1),(!_vm.loading && _vm.groupedAgeRows.length == 0)?_c('div',{staticClass:"text-center font-weight-light mt-2"},[_vm._v(" Nessun risultato ")]):_vm._e(),_c('div',[(!!_vm.loadingDetail || _vm.groupedServices.length > 0)?_c('v-divider',{staticStyle:{"margin-top":"30px","margin-bottom":"30px"}}):_vm._e(),_c('v-row',{staticStyle:{"margin-top":"25px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"lg":"9","xl":"9","md":"12","cols":"12","sm":"12","order":this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
            ? '2' : '1'}},[(_vm.loadingDetail)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
              height: '100%'
            })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('div',[(_vm.groupedServices.length > 0)?_c('div',{staticClass:"d-flex align-content justify-center",staticStyle:{"font-size":"larger","font-weight":"500"}},[_c('div',{staticClass:"title-section"},[_vm._v(_vm._s(_vm.rowSelected))])]):_vm._e(),_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"25px","margin-bottom":"20px"}},[(!_vm.loading && _vm.groupedServices.length > 0)?_c('TypeDataTable',{staticStyle:{"background-color":"transparent"},attrs:{"fixed-header":"","headersColor":"rgb(224, 224, 230)","headers":_vm.headersDetail,"items":_vm.groupedServices,"results-for-loading":!_vm.detailAgeGroupServices,"loading":_vm.loadingDetail,"show-load":false,"show-select":false,"show-actions":false,"select-on-row-click":false,"disablePagination":true}}):_vm._e()],1)],1)]),(!!_vm.results)?_c('v-col',{attrs:{"lg":"3","xl":"3","md":"12","cols":"12","sm":"12","order":this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
            ? '1' : '2'}},[(_vm.loadingDetail)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
              height: '100%'
            })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.groupedServices.length > 0)?_c('div',[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"margin-bottom":"10px","font-size":"larger","font-weight":"500"}},[_c('span',{staticClass:"title-section",staticStyle:{"font-size":"1rem"}},[_vm._v(" Numero Servizi : "+_vm._s(_vm.totalServicesDetails)+" ")])]),_c('div',{staticClass:"d-flex justify-center"},[(!_vm.loadingDetail || !!_vm.detailAgeGroupServices)?_c('DoughnutChart',{attrs:{"chart-data":_vm.servicesDetailsChartData,"styles":{
                  height: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                    ? '30vw' : '20vw',
                  width: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                    ? '30vw' : '20vw'
                },"options":_vm.chartOptions}}):_vm._e()],1)]):_vm._e()]):_vm._e()],1)],1)],1),_c('SendCustomersSms',{attrs:{"dialog-height":null,"dialog-max-width":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 
      '95vw' : '60vw',"customers":_vm.messageCustomers},on:{"fetch-customers":_vm.sendMassiveMsg},model:{value:(_vm.customersSendMessage),callback:function ($$v) {_vm.customersSendMessage=$$v},expression:"customersSendMessage"}}),_c('StandardDialog',{attrs:{"title":"Area Riservata Sviluppatori","dialog-height":null,"dialog-max-width":"500px"},model:{value:(_vm.dialogBarcode),callback:function ($$v) {_vm.dialogBarcode=$$v},expression:"dialogBarcode"}},[_c('ManualBarcodeInput',{attrs:{"scan-your-barcode-message":"Immettere Codice"},on:{"confirm":_vm.activeBarcode},model:{value:(_vm.operatorBarcode),callback:function ($$v) {_vm.operatorBarcode=$$v},expression:"operatorBarcode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }