<template>
  <div>
    <v-form 
      v-model="formValid"
      style="height: calc(100vh - 130px);"
    >
      
    <v-row style="height: 100%">
      <v-col cols="6" style="height: 100%; overflow-y: auto">
        <v-card flat color="transparent">
          <v-card-title class="font-weight-bold">1. Nome Campagna*</v-card-title>
          <v-card-text class="text-left">
            <v-row>
              <v-col>
                <v-text-field
                  style="width: 40.1vw"  
                  v-model="name"
                  :disabled="disabled"
                  :rules="[max255CharRule, presenceRule]"
                  dense
                  filled
                  label="Inserisci il nome della campagna"
                  hide-details="auto"
                  @input="$emit('input', localValue)"
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card flat color="transparent">
          <v-card-title class="font-weight-bold">2. Descrizione Campagna*</v-card-title>
          <v-card-text class="text-left">
            <v-row>
              <v-col>
                <v-textarea
                  style="width: 40.1vw"  
                  v-model="description"
                  :disabled="disabled"
                  :rules="[presenceRule]"
                  dense
                  filled
                  label="Inserisci la descrizione della campagna"
                  hide-details="auto"
                  @input="$emit('input', localValue)"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card flat color="transparent">
          <v-card-title class="font-weight-bold">3. Obbiettivo*</v-card-title>
          <v-card-text class="text-left">
            <v-row>
              <v-col cols="auto">
                <ResponsiveCardSelector
                  :items="goals"
                  :disabled="disabled"
                  v-model="selectedGoal"
                  item-key="name"
                  card-max-height="80"
                  card-min-height="80"
                  :card-max-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  :card-min-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  align-items-item="center"
                  @input="() => {
                    importedActionsFilters.goal = selectedGoal
                    loadImportedActions()
                    showRecipients()
                    $emit('input', localValue)
                  }"
                  :colors="['#ada2e0']"
                  :colorOnSelected="true"
                  :rules="[presenceRule]"
                >
                  <template v-slot:item="{ item }">
                    <div class="pa-2 d-flex flex-column justify-center align-center">
                      <div 
                        class="text-center"
                        style="font-size: 0.85rem"
                      >
                        {{ item.text }}
                    </div>
                    </div>
                  </template>
                </ResponsiveCardSelector>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        
        <v-card flat color="transparent">
          <v-card-title class="font-weight-bold">4. Reparto*</v-card-title>
          <v-card-text class="text-left">
            <v-row>
              <v-col>
                <ResponsiveCardSelector
                  :items="serviceTypesCategories"
                  :disabled="disabled"
                  v-model="serviceTypesCategoriesSelected"
                  item-key="name"
                  card-max-height="80"
                  card-min-height="80"
                  :card-max-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  :card-min-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  align-items-item="center"
                  align-center
                  @input="() => {
                    let localCategories = serviceTypesCategoriesSelected.map(el => el.name)
                    importedActionsFilters.categories = localCategories.length > 0 ? localCategories : undefined
                    loadImportedActions()
                    $emit('input', localValue)
                  }"
                  :colors="['#ada2e0']"
                  :colorOnSelected="true"
                  :rules="[presenceRule]"
                  return-object
                  multiple
                >
                  <template v-slot:item="{ item }">
                    <div class="pa-2 d-flex flex-column justify-center align-center">
                      <div 
                        class="text-center"
                        style="font-size: 0.85rem"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </ResponsiveCardSelector>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card flat color="transparent">
          <v-card-title class="font-weight-bold">5. Strumento*</v-card-title>
          <v-card-text class="text-left">
            <v-row>
              <v-col>
                <ResponsiveCardSelector
                  :items="tools"
                  :disabled="disabled"
                  v-model="selectedTool"
                  item-key="name"
                  card-max-height="80"
                  card-min-height="80"
                  :card-max-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  :card-min-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  align-items-item="center"
                  @input="() => {
                    smsModel = undefined
                    receiptModel = undefined
                    landingPageModel = undefined

                    if (selectedTool == 'receipt')
                      sendTime = undefined
                    else {
                      sendTime = '8:00'
                      importedActionsFilters.receiptOption = undefined
                    }
                    
                    importedActionsFilters.tool = selectedTool
                    loadImportedActions()
                    showRecipients()
                    $emit('input', localValue)
                  }"
                  :colors="['#ada2e0']"
                  :colorOnSelected="true"
                  :rules="[presenceRule]"
                >
                  <template v-slot:item="{ item }">
                    <div class="pa-2 d-flex flex-column justify-center align-center">
                      <div 
                        class="mt-2"
                        style="font-size: 0.85rem"
                      >
                        {{ item.text }}
                      </div>
                    </div>
                  </template>
                </ResponsiveCardSelector>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-if="!!selectedTool && selectedTool == 'receipt'" flat color="transparent">
          <v-card-title class="font-weight-bold">5. Tipologia Scontrino</v-card-title>
          <v-card-text class="text-left">
            <v-row>
              <v-col>
                <ResponsiveCardSelector
                  :items="receiptOptions"
                  :disabled="disabled"
                  item-key="name"
                  v-model="selectedReceiptOption"
                  card-max-height="80"
                  card-min-height="80"
                  :card-max-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  :card-min-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  align-items-item="center"
                  @input="() => {
                    importedActionsFilters.receiptOption = selectedReceiptOption
                    loadImportedActions()
                    $emit('input', localValue)
                  }"
                  :colors="['#ada2e0']"
                  :colorOnSelected="true"
                >
                  <template v-slot:item="{ item }">
                    <div class="pa-2 d-flex flex-column justify-center align-center">
                      <div 
                        class="mt-2"
                        style="font-size: 0.85rem"
                      >
                        {{ item.text }}
                      </div>
                      <v-icon style="margin-top: -5px" large>{{ item.icon }}</v-icon>
                    </div>
                  </template>
                </ResponsiveCardSelector>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
        <v-card v-if="!!selectedTool && (!!selectedReceiptOption || selectedTool != 'receipt')" 
          flat
          color="transparent"
        >
          <v-card-title class="font-weight-bold">6. Configurazione Strumento*</v-card-title>
          <v-card-text class="text-left">
            <v-row v-if="!newConfigurationTool && !showConfigurationTool">
              <v-col
                class="d-flex align-center"
                cols="6"
              >
                <v-select
                  v-model="selectedImportedAction"
                  label="Seleziona un modello"
                  :items="importedActions"
                  filled
                  clearable
                  hide-details="auto"
                  @input="previewTemplateSelected"
                  @click:clear="() => {
                    selectedPreview = undefined
                    smsModel = {}
                    receiptModel = undefined
                  }"
                ></v-select>
              </v-col>
              <v-col 
                v-if="!selectedPreview"
                class="d-flex align-center justify-center"
              >
                <v-btn
                  text
                  outlined
                  class="mt-2 mb-2"
                  :x-small="$vuetify.breakpoint.xs"
                  :small="($vuetify.breakpoint.sm || $vuetify.breakpoint.md)"
                  :large="($vuetify.breakpoint.lg || $vuetify.breakpoint.xl)"
                  @click="() => {
                    smsModel = {}
                    receiptModel = undefined
                    newConfigurationTool = true
                  }"
                >
                  Crea nuovo +
                </v-btn>
              </v-col>
            </v-row>
            <v-row 
              style="width: fit-content"
              v-if="!!newConfigurationTool || !!showConfigurationTool || !!selectedPreview"
            >
              <v-card
                :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '68.8vw' : '40.8vw'"
                class="d-flex flex-column"
                rounded="xl"
              >
                <v-card-title
                  v-if="!showConfigurationTool"
                  class="flex-column align-start">
                  <v-btn
                    text 
                    @click="() => {
                      newConfigurationTool = false
                      selectedImportedAction = undefined
                      selectedPreview = undefined
                      smsModel = {}
                      receiptModel = undefined
                    }" 
                    class
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                    <span class="ml-1"> Torna alla selezione di modelli </span>
                  </v-btn>
                </v-card-title>
                <v-card-text class="d-flex">
                  <v-col :cols="12">
                    <SmsModelForm
                      v-if="selectedTool == 'sms'"
                      :disabled="disabled"
                      v-model="smsModel"
                      class="py-2"
                      :selected-images="selectedImages"
                      :image-tags="imageTags"
                      :valid.sync="smsModelValid"
                      :passed-placeholders="[
                        { name: 'customer.firstname', text: 'Nome Cliente' },
                        { name: 'customer.lastname', text: 'Cognome Cliente' },
                        { name: 'customer.fullname', text: 'Nome Completo Cliente' },
                      ]"
                      @update:selectedImage="handleSelectedImageUpdate"
                      @input="$emit('input', localValue)"
                    ></SmsModelForm>

                    <ReceiptModelForm
                      v-if="selectedTool == 'receipt'"
                      height="55vh"
                      :disabled="disabled"
                      v-model="receiptModel"
                      :type="selectedReceiptOption"
                      class="py-2"
                      :valid.sync="receiptModelValid"
                      @input="$emit('input', localValue)"
                    ></ReceiptModelForm>

                    <LandingPageModelForm
                      v-if="selectedTool == 'landingPage'"
                      :disabled="disabled"
                      v-model="landingPageModel"
                      class="py-2"
                      :valid.sync="landingPageModelValid"
                      @input="$emit('input', localValue)"
                    ></LandingPageModelForm>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-if="!!selectedTool" flat color="transparent">
          <v-card-title class="font-weight-bold">7. Destinatari*</v-card-title>
          <v-card-text class="text-left">
            <v-row>
              <v-col>
                <ResponsiveCardSelector
                  :items="shownRecipients"
                  :disabled="disabled"
                  v-model="selectedRecipients"
                  item-key="name"
                  card-max-height="80"
                  card-min-height="80"
                  :card-max-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  :card-min-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                    $vuetify.breakpoint.lg ? '200' : '250'"
                  align-items-item="center"
                  :colors="['#ada2e0']"
                  :colorOnSelected="true"
                  multiple
                  :rules="[presenceRule]"
                  @click="(input) => {
                    handleSelectedRecipients(input)
                  }"
                > 
                  <template v-slot:item="{ item }">
                    <div class="pa-2 d-flex flex-column justify-center align-center">
                      <div 
                        class="text-center"
                        style="font-size: 0.85rem"
                      >
                        {{ item.text }}
                    </div>
                    </div>
                  </template>
                </ResponsiveCardSelector>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <RangeDateSelector
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0 
                    && selectedRecipients.some(el => shownRecipients.filter(item => !!item.showRangeDate).map(item => item.name).includes(el)))"
                  class="mb-2"
                  :disabled="disabled"
                  :start-date.sync="fromFilter"
                  :end-date.sync="toFilter"
                  :chip-items="[
                    {text: 'Questo mese', value: 'thisMonth'},
                    {text: 'Ultimi 6 mesi', value: 'lastSixMonths'},
                    {text: 'Scorso anno', value: 'thisYear'},
                  ]"
                  @change-date="() => {
                    calculateRecipients()
                  }"
                ></RangeDateSelector>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendToAllActiveCustomers')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">CLIENTI ATTIVI</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in serviceTypesCategories" :key="item['id']"
                    v-model="checkedActiveCustomers[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendToInactiveCustomers')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">CLIENTI INATTIVI</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in serviceTypesCategories" :key="item['id']"
                    v-model="checkedInactiveCustomers[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendToNewCustomers')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">CLIENTI NUOVI</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in serviceTypesCategories" :key="item['id']"
                    v-model="checkedNewCustomers[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendToCustomersByAgeGroup')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">CLIENTI FASCE DI ETA'</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in optionsAgeGroupCustomers" :key="item['id']"
                    v-model="optionsAgeGroupCustomers[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendToCustomersByGender')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">GENERE</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in optionsGenderCustomers" :key="item['id']"
                    v-model="optionsGenderCustomers[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendToCustomersWithService')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">CLIENTI CON SERVIZIO</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in serviceTypes" :key="item['id']"
                    v-model="checkedCustomersWithService[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendToCustomersWithoutService')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">CLIENTI SENZA SERVIZIO</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in checkedCustomersWithoutService" :key="item['id']"
                    v-model="checkedCustomersWithoutService[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

                <div
                  v-if="!!selectedRecipients && selectedRecipients.includes('sendByFidelization')"
                >
                  <div class="ml-2 mb-2 text-subtitle-1 font-weight-bold">GRADO DI FIDELIZZAZIONE</div>
                  <div class="ml-2">
                    <div>SOLO PARRUCCHIERE</div>
                    <v-checkbox
                      class="mt-0 ml-0 mr-0 pa-0 pl-2"
                      :disabled="disabled"
                      style="margin-bottom: -15px; width: fit-content;"
                      v-for="(item, key) in optionsOnlyBarberFidelity" :key="'Barber_' + item['id']"
                      v-model="optionsOnlyBarberFidelity[key]['checked']"
                      @click="() => {
                        calculateRecipients()
                      }"
                    >
                      <template v-slot:label>
                        <span
                          style="font-size: 13px;"
                        >
                          {{ item['name'] }}
                        </span>
                      </template>
                    </v-checkbox>

                    <div>SOLO ESTETICA</div>
                    <v-checkbox
                      class="mt-0 ml-0 mr-0 pa-0 pl-2"
                      :disabled="disabled"
                      style="margin-bottom: -15px; width: fit-content;"
                      v-for="(item, key) in optionsOnlyBeautyFidelity" :key="'Beauty_' + item['id']"
                      v-model="optionsOnlyBeautyFidelity[key]['checked']"
                      @click="() => {
                        calculateRecipients()
                      }"
                    >
                      <template v-slot:label>
                        <span
                          style="font-size: 13px;"
                        >
                          {{ item['name'] }}
                        </span>
                      </template>
                    </v-checkbox>

                    <div>PARRUCCHIERE ED ESTETICA</div>
                    <v-checkbox
                      class="mt-0 ml-0 mr-0 pa-0 pl-2"
                      :disabled="disabled"
                      style="margin-bottom: -15px; width: fit-content;"
                      v-for="(item, key) in optionsBeautyAndBarberFidelity" :key="'BeautyAndBarber_' + item['id']"
                      v-model="optionsBeautyAndBarberFidelity[key]['checked']"
                      @click="() => {
                        calculateRecipients()
                      }"
                    >
                      <template v-slot:label>
                        <span
                          style="font-size: 13px;"
                        >
                          {{ item['name'] }}
                        </span>
                      </template>
                    </v-checkbox>
                  </div>          
                </div>

                <div
                  v-if="(!!selectedRecipients && selectedRecipients.length > 0) && selectedRecipients.includes('sendByFinancialService')"
                >
                  <div class="ml-2 mb-2 mt-1 text-subtitle-1 font-weight-bold">SEGMENTO FINANZIARIO</div>
                  <v-checkbox
                    class="mt-0 ml-0 mr-0 pa-0 pl-2"
                    :disabled="disabled"
                    style="margin-bottom: -15px; width: fit-content;"
                    v-for="(item, key) in optionsFinancialService" :key="item['id']"
                    v-model="optionsFinancialService[key]['checked']"
                    @click="() => {
                      calculateRecipients()
                    }"
                  >
                    <template v-slot:label>
                      <span
                        style="font-size: 13px;"
                      >
                        {{ item['name'] }}
                      </span>
                    </template>
                  </v-checkbox>
                </div>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-if="!!selectedTool" flat color="transparent">
        <v-card-title
          v-if="selectedTool == 'receipt'"
          class="font-weight-bold"
        >
          8. Programma la stampa*
        </v-card-title>
        <v-card-title v-else class="font-weight-bold">8. Programma l'invio*</v-card-title>
        <v-card-text class="text-left">
          <v-row>
            <v-col cols="auto">
              <div class="d-flex flex-column">
                <span 
                    class="mb-3"
                  >
                    Periodo campagna
                  </span>
                <DateTimePicker
                  v-model="localEventStartDate"
                  dense
                  :date-picker-prepend-icon="null"
                  date-picker-append-icon="mdi-calendar"
                  hide-details="auto"
                  label="Inizio"
                  :show-time="false"
                  :min="(() => {
                    let today = new Date()
                    let tomorrow = new Date()
                    tomorrow.setDate(today.getDate() + 1)
                    return tomorrow
                  })()"
                  :disabled="!!disabled && eventType != 'duplicateFrom'"
                  :date-picker-rounded="false"
                  date-picker-outlined
                  @input="() => {
                    handleChangeStartDate()
                    handleSelectRepeatEvent()
                  }"
                ></DateTimePicker>
              </div>
            </v-col>
            <v-col cols="auto">
            <div class="d-flex flex-column">
                <DateTimePicker
                  style="margin-top: 34px"
                  v-model="localEventEndDate"
                  :date-picker-prepend-icon="null"
                  date-picker-append-icon="mdi-calendar"
                  hide-details="auto"
                  label="Fine"
                  :min="nextStartDate"
                  :show-time="false"
                  :disabled="!!disabled && eventType != 'duplicateFrom'"
                  :date-picker-rounded="false"
                  date-picker-outlined
                  @input="() => {
                    $emit('input', localValue)
                    handleSelectRepeatEvent()
                  }"
                ></DateTimePicker>
              </div> 
            </v-col>
            <v-col cols="auto">
              <div
                v-if="selectedTool != 'receipt'"
                class="d-flex flex-column"
              >
                <span 
                  class="mb-3"
                >
                  Orario di invio
                </span>
                <BetterTimePicker
                  :disabled="disabled"
                  :value="sendTime"
                  :handle-time-change="handleTimeChange"
                ></BetterTimePicker>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="!!localEventStartDate && !!localEventEndDate
              && eventType != 'duplicateFrom'"
          >
            <v-col cols="auto">
              <div class="d-flex flex-column">
                <span 
                  class="mb-3"
                >
                  Ripeti evento
                </span>
                <v-select
                  v-model="selectedRepeatType"
                  :items="repeatTypes"
                  outlined
                  :disabled="disabled || (!!this.value && !!this.value.id)"
                  @input="() => {
                    if (selectedRepeatType == 'custom')
                      customRepeatEventDialog = true
                    else
                      handleSelectRepeatEvent()
                  }"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      </v-col>
      <v-col cols="1">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col 
        cols="5" 
        class="d-flex align-center justify-center"
        style="height: 100%"
      >
        <SmsMobilePreviewForm
          v-if="selectedTool == 'sms'"
          width="250px"
          height="500px"
          :selected-image="selectedImg"
          :sms-model="smsModel"
          :send-time="sendTime"
        ></SmsMobilePreviewForm>

        <ReceiptPreviewForm
          v-if="selectedTool == 'receipt'"
          :width="'38vh'"
          :height="null"
          :min-height="'90%'"
          :max-height="'90%'"
          class="mt-6"
          :receipt-model="receiptModel"
        ></ReceiptPreviewForm>
      </v-col>
    </v-row>
    </v-form>

    <StandardDialog
      v-model="customRepeatEventDialog"
      persistent
      title="Ricorrenza personalizzata"
      :dialog-height="null"
      :dialog-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 
        '90vw' : '32vw'"
    >
      <v-form
        v-model="customRepeatEventFormValid"
      >
        <v-row
          class="d-flex justify-center align-center mt-2"
          :style="{
            'padding-bottom': '0px'
          }"
        >
          <v-col
            xs="2"
            sm="2"
            md="2"
            lg="2"
            xl="2"
            class="d-flex justify-end"
          >
            <span
              style="margin-bottom: 25px;"
            >Ripeti ogni</span>
          </v-col>
          <v-col
            xs="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
            <v-text-field
              v-model="customRepeatNumber"
              filled
              type="number"
              :rules="[greaterThan0, lessThanMaxRepeatNumber ,presenceRule]"
              min="1"
              :max="maxRepeatNumber"
              @input="() => {
                if (customRepeatEndOccurrences > maxEndOccurences
                  && selectedCustomRepeatEndType == 'afterNumberOccurences')
                  customRepeatEndOccurrences = maxEndOccurences
              }"
            ></v-text-field>
          </v-col>
          <v-col
            xs="5"
            sm="5"
            md="5"
            lg="5"
            xl="5"
          >
            <v-select
              v-model="selectedCustomRepeatType"
              filled
              :items="customRepeatTypes"
              :rules="[presenceRule]"
              @input="() => {
                if (customRepeatEndOccurrences > maxEndOccurences
                  && selectedCustomRepeatEndType == 'afterNumberOccurences')
                  customRepeatEndOccurrences = maxEndOccurences
              }"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="selectedCustomRepeatType == 'week'"
          class="d-flex justify-center align-center mt-2 flex-column"
          :style="{
            'padding-top': '0px'
          }"
        >
          <v-col
            xs="10"
            sm="10"
            md="10"
            lg="10"
            xl="10"
            :style="{
              'padding-bottom': '0px'
            }"
          >
            <span
              :style="{
                'margin-left': '9px'
              }"
            > 
              Si ripete il
            </span>
          </v-col>
          <v-col
            xs="10"
            sm="10"
            md="10"
            lg="10"
            xl="10"
            :style="{
              'padding-bottom': '0px'
            }"
          >
            <v-chip-group
              v-model="selectedRepeatDays"
              active-class="primary--text"
              mandatory
              multiple
              :rules="[presenceRule]"
              :style="{
                'margin-left': '9px'
              }" 
            >
              <v-chip
                v-for="(item, key) in days"
                :key="key"
              >
                {{ item.text }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row
          v-if="selectedCustomRepeatType == 'month'"
          class="d-flex justify-center align-center mt-2"
          :style="{
            'padding-top': '0px'
          }"
        >
          <v-col
            xs="10"
            sm="10"
            md="10"
            lg="10"
            xl="10"
            :style="{
              'padding-bottom': '0px'
            }"
          >
            <v-select
              v-model="selectedCustomRepeatMonthType"
              filled
              :items="customRepeatMonthTypes"
              :rules="[presenceRule]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          :style="{
            'padding-bottom': '0px'
          }"
        >
          <v-col
            xs="10"
            sm="10"
            md="10"
            lg="10"
            xl="10"
            :style="{
              'padding-bottom': '0px'
            }"
          >
            <span
              :style="{
                'margin-left': '9px'
              }" 
            >
              Fine
            </span>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          :style="{
            'padding-top': '0px'
          }"
        >
          <v-col
            xs="5"
            sm="5"
            md="5"
            lg="5"
            xl="5"
            :style="{
              'padding-top': '0px'
            }"
          >
            <v-radio-group
              v-model="selectedCustomRepeatEndType"
              mandatory
              :rules="[presenceRule]"
              :style="{
                'margin-left': '9px',
                'margin-top': '0px'
              }"
              @change="() => {
                if (customRepeatEndOccurrences > maxEndOccurences
                  && selectedCustomRepeatEndType == 'afterNumberOccurences')
                    customRepeatEndOccurrences = maxEndOccurences
              }"
            >
              <v-radio
                v-for="(item, key) in customRepeatEndTypes"
                :key="key"
                :label="item.text"
                :value="item.value"
                :style="{
                  'padding-bottom': key === 0 ? '20px' : '0px',
                  'padding-top': key === 1 ? '15px' : '0px'
                }"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            xs="5"
            sm="5"
            md="5"
            lg="5"
            xl="5"
          >
            <div
              class="d-flex flex-column"
              :style="{
                'gap': '10px'
              }"
            >
              <DateTimePicker
                v-model="customRepeatEndDate"
                :date-picker-prepend-icon="null"
                date-picker-append-icon="mdi-calendar"
                hide-details="auto"
                :show-time="false"
                :date-picker-rounded="false"
                date-picker-outlined
                :disabled="selectedCustomRepeatEndType == 'afterNumberOccurences'"
                :rules="[presenceRule]"
                :min="eventStartDate"
                :max="(() => {
                  if (eventStartDate) {
                    let maxDate = new Date(eventStartDate)
                    maxDate.setFullYear(maxDate.getFullYear() + 2)
                    return maxDate.toISOString()
                  }
                })()"
              ></DateTimePicker>
              <v-text-field
                v-model="customRepeatEndOccurrences"
                filled
                type="number"
                :rules="[greaterThan0, lessThanMaxEndOccurences, presenceRule]"
                suffix="occorrenze"
                :disabled="selectedCustomRepeatEndType == 'onSpecificDate'"
                min="1"
                :max="maxEndOccurences"
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <template v-slot:footer>
        <div
          class="d-flex justify-end"
          style="width: 100%"
        >
          <v-btn
            color="error"
            text
            @click="() => {
              customRepeatEventDialog = false
              if (selectedRepeatType == 'custom')
                selectedRepeatType = 'noRepeat'
            }"
          >
            Chiudi
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="handleSaveCustomRepeatEvent"
            :disabled="!customRepeatEventFormValid"
          >
            Fine  
          </v-btn>
        </div>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import ServiceTypeCategoriesAutocomplete from '@/components/common/ServiceTypeCategoriesAutocomplete.vue'
import ServiceTypesCategoryAutocomplete from '@/components/common/ServiceTypesCategoryAutocomplete.vue'
import SmsModelForm from "@/components/setting/sms/SmsModelForm.vue";
import ReceiptModelForm from "@/components/areas/marketing/ReceiptModelForm.vue";
import LandingPageModelForm from "@/components/areas/marketing/LandingPageModelForm.vue";
import BetterTimePicker from '@/components/common/BetterTimePicker.vue';
import ReceiptPreviewForm from '@/components/areas/marketing/actions/previews/ReceiptPreviewForm.vue';
import SmsMobilePreviewForm from '@/components/areas/marketing/actions/previews/SmsMobilePreviewForm.vue';
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import VueBarcode from "vue-barcode";

import serviceTypeService from '@/services/serviceTypes/serviceTypes.service.js';
import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js";
import marketingActionsService from "@/services/marketingActions/marketingActions.service.js";
import DateHandler from "@/services/common/dateHandler";
import { weekdays } from 'moment';

export default {

  name: 'ActionMarketingGeneralForm',
  components: {
    ResponsiveCardSelector,
    ServiceTypeCategoriesAutocomplete,
    ServiceTypesCategoryAutocomplete,
    SmsModelForm,
    ReceiptModelForm,
    LandingPageModelForm,
    BetterTimePicker,
    ReceiptPreviewForm,
    SmsMobilePreviewForm,
    VueBarcode,
    DateTimePicker,
    RangeDateSelector,
    StandardDialog
  },
  data: function() {
    let today = new Date()
    today.setHours(0, 0, 0)
    let tomorrow = new Date(today)
    tomorrow.setHours(23, 59, 59)
    return {
      selectedImg: undefined,
      imageTags: undefined,
      selectedImages: undefined,
      presetDialog: this.openDialog,
      localEventStartDate: this.eventStartDate,
      localEventEndDate: this.eventEndDate,
      formValid: false,
      loading: false,
      goals: [
        {
          name: 'increaseCustomerFrequency',
          text: 'Aumenta Frequenza Clienti',
          icon: 'mdi-human-capacity-increase'
        },
        {
          name: 'developSales',
          text: 'Sviluppa vendite',
          icon: 'mdi-cash-plus'
        },
        {
          name: 'developServiceTypeCategory',
          text: 'Promuovi un servizio o un reparto',
          icon: 'mdi-bullseye-arrow'
        },
        {
          name: 'newCustomers',
          text: 'Acquisisci nuovi Clienti',
          icon: 'mdi-account-plus'
        },
        {
          name: 'mostLoyalCustomers',
          text: 'Premia clienti più fedeli',
          icon: 'mdi-account-heart'
        },
        {
          name: 'informational',
          text: 'Informazionale ',
          icon: 'mdi-email-newsletter'
        },
      ],
      tools: [
        {
          name: 'sms',
          text: 'SMS',
          icon: 'mdi-message-check'
        },
        {
          name: 'receipt',
          text: 'Scontrino',
          icon: 'mdi-receipt'
        },
/*         {
          name: 'landingPage',
          text: 'Landing Page',
          icon: 'mdi-application-brackets'
        }, */
      ],
      receiptOptions: [
        {
          name: 'barcode',
          text: 'Barcode + testo',
          icon: 'mdi-barcode'
        }, 
        {
          name: 'qrcode',
          text: 'QR Code + testo',
          icon: 'mdi-qrcode'
        },
        {
          name: 'onlyText',
          text: 'Solo testo',
          icon: 'mdi-text-short'
        }
      ],
      recipients: {
        receipt: {
          'increaseCustomerFrequency': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithActivePromo',
            'sendToCustomersWithInactivePromo',
            'sendToUnderageCustomers',
            'sendToBirthdayCustomers',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendByFinancialService'
          ],
          'developSales': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithActivePromo',
            'sendToCustomersWithInactivePromo',
            'sendToUnderageCustomers',
            'sendToBirthdayCustomers',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendByFinancialService',
            'sendToCustomersWhoHasBought',
            'sendToCustomersWhoDontBought',
          ],
          'developServiceTypeCategory': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithActivePromo',
            'sendToCustomersWithInactivePromo',
            'sendToUnderageCustomers',
            'sendToBirthdayCustomers',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendByFinancialService',
            'sendToCustomersWhoHasBought',
            'sendToCustomersWhoDontBought',
          ],
          'newCustomers': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendByFinancialService',
          ],
          'mostLoyalCustomers': [
            'sendToAllActiveCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendByFinancialService',
            'sendByFidelization',
          ],
          'informational': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
          ],
        },
        othersSend: {
          'increaseCustomerFrequency': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithActivePromo',
            'sendToCustomersWithInactivePromo',
            'sendToUnderageCustomers',
            'sendToBirthdayCustomers',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
          ],
          'developSales': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithActivePromo',
            'sendToCustomersWithInactivePromo',
            'sendToUnderageCustomers',
            'sendToBirthdayCustomers',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendToCustomersWhoHasBought',
            'sendToCustomersWhoDontBought',
          ],
          'developServiceTypeCategory': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithActivePromo',
            'sendToCustomersWithInactivePromo',
            'sendToUnderageCustomers',
            'sendToBirthdayCustomers',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendToCustomersWhoHasBought',
            'sendToCustomersWhoDontBought',
          ],
          'newCustomers': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
          ],
          'mostLoyalCustomers': [
            'sendToAllActiveCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
            'sendToCustomersWithService',
            'sendToCustomersWithoutService',
            'sendByFidelization',
          ],
          'informational': [
            'sendToAllCustomers',
            'sendToAllActiveCustomers',
            'sendToInactiveCustomers',
            'sendToNewCustomers',
            'sendToCustomersByAgeGroup',
            'sendToCustomersByGender',
          ],
        }
      },
      typesOfRecipients: [
        {
          name: 'sendToAllCustomers',
          text: 'Invia a tutti i clienti',
          showRangeDate: false
        },
        {
          name: 'sendToAllActiveCustomers',
          text: 'Invia a tutti i clienti attivi',
          showRangeDate: true
        },
        {
          name: 'sendToInactiveCustomers',
          text: 'Invia ai clienti inattivi',
          showRangeDate: true
        },
        {
          name: 'sendToNewCustomers',
          text: 'Invia ai clienti nuovi',
          showRangeDate: true
        },
        {
          name: 'sendToCustomersByAgeGroup',
          text: 'Invia ai clienti per fasce d\'età',
          showRangeDate: false
        },
        {
          name: 'sendToCustomersByGender',
          text: 'Invia ai clienti per genere',
          showRangeDate: false
        },
        {
          name: 'sendToCustomersWithActivePromo',
          text: 'Invia ai clienti che hanno promo attive',
          showRangeDate: false
        },
        {
          name: 'sendToCustomersWithInactivePromo',
          text: 'Invia ai clienti che non hanno promo attive',
          showRangeDate: false
        },
        {
          name: 'sendToUnderageCustomers',
          text: 'Invia ai clienti minorenni',
          showRangeDate: false
        },
        {
          name: 'sendToBirthdayCustomers',
          text: 'Invia ai clienti che compiono gli anni',
          showRangeDate: false
        },
        {
          name: 'sendToCustomersWithService',
          text: 'Invia ai clienti che usufruiscono del servizio',
          showRangeDate: true
        },
        {
          name: 'sendToCustomersWithoutService',
          text: 'Invia ai clienti che non usufruiscono del servizio',
          showRangeDate: true
        },
        {
          name: 'sendByFinancialService',
          text: 'Stampa per segmento finanziario',
          showRangeDate: false
        },
        {
          name: 'sendToCustomersWhoHasBought',
          text: 'Invia ai clienti che hanno già acquistato',
          showRangeDate: true
        },
        {
          name: 'sendToCustomersWhoDontBought',
          text: 'Invia ai clienti che non hanno già acquistato',
          showRangeDate: true
        },
        {
          name: 'sendByFidelization',
          text: 'Invia ai clienti per grado di fidelizzazione',
          showRangeDate: true
        },
      ],
      shownRecipients: [],
      selectedRecipients: undefined,
      selectedReceiptOption: undefined,
      selectedTool: undefined,
      selectedGoal: undefined,
      name: undefined,
      description: undefined,
      serviceTypes: undefined,
      serviceTypesCategories: undefined,
      serviceTypesCategoriesSelected: undefined,
      smsModel: {},
      receiptModel: undefined,
      landingPageModel: undefined,
      landingPageModelValid: false,
      receiptModelValid: false,
      smsModelValid: false,
      sendTime: '8:00',
      importedActions: [],
      importedActionsFilters: {},
      newConfigurationTool: false,
      selectedPreview: undefined,
      selectedImportedAction: undefined,
      
      checkedActiveCustomers: [],
      checkedInactiveCustomers: [],
      checkedNewCustomers: [],
      optionsAgeGroupCustomers: [
        {
          id: '0-15',
          name: '0-15',
          checked: false,
        },
        {
          id: '16-25',
          name: '16-25',
          checked: false,
        },
        {
          id: '26-35',
          name: '26-35',
          checked: false,
        },
        {
          id: '36-45',
          name: '36-45',
          checked: false,
        },
        {
          id: '46-55',
          name: '46-55',
          checked: false,
        },
        {
          id: '56-65',
          name: '56-65',
          checked: false,
        },
        {
          id: 'Over 65',
          name: 'Over 65',
          checked: false,
        },
        {
          id: 'Senza definizione',
          name: 'Senza definizione',
          checked: false,
        },
      ],
      optionsGenderCustomers: [
        {
          id: 'female',
          name: 'DONNA',
          checked: false,
        },
        {
          id: 'male',
          name: 'UOMO',
          checked: false,
        },
      ],
      optionsFidelization: [
      ],
      optionsFinancialService: [
        {
          id: '50-100',
          name: '50-100',
          checked: false,
        },
        {
          id: '100-200',
          name: '100-200',
          checked: false,
        },        {
          id: '200-300',
          name: '200-300',
          checked: false,
        },
        {
          id: '300+',
          name: '300+',
          checked: false,
        },
      ],
      optionsOnlyBeautyFidelity: [
        {
          id: 'premiumFidelityBeautyActive',
          name: 'Premium',
          checked: false,
        },
        {
          id: 'highFidelityBeautyActive',
          name: 'Alto Fidelizzato',
          checked: false,
        },        {
          id: 'mediumFidelityBeautyActive',
          name: 'Medio Fidelizzato',
          checked: false,
        },
        {
          id: 'lowFidelityBeautyActive',
          name: 'Basso Fidelizzato',
          checked: false,
        },
        {
          id: 'noFidelityBeautyActive',
          name: 'Da Fidelizzare',
          checked: false,
        },
      ],
      optionsOnlyBarberFidelity: [
        {
          id: 'premiumFidelityBarberActive',
          name: 'Premium',
          checked: false,
        },
        {
          id: 'highFidelityBarberActive',
          name: 'Alto Fidelizzato',
          checked: false,
        },        {
          id: 'mediumFidelityBarberActive',
          name: 'Medio Fidelizzato',
          checked: false,
        },
        {
          id: 'lowFidelityBarberActive',
          name: 'Basso Fidelizzato',
          checked: false,
        },
        {
          id: 'noFidelityBarberActive',
          name: 'Da Fidelizzare',
          checked: false,
        },
      ],
      optionsBeautyAndBarberFidelity: [
        {
          id: 'premiumFidelityBothBeautyAndBarberActive',
          name: 'Premium',
          checked: false,
        },
        {
          id: 'highFidelityBothBeautyAndBarberActive',
          name: 'Alto Fidelizzato',
          checked: false,
        },        {
          id: 'mediumFidelityBothBeautyAndBarberActive',
          name: 'Medio Fidelizzato',
          checked: false,
        },
        {
          id: 'lowFidelityBothBeautyAndBarberActive',
          name: 'Basso Fidelizzato',
          checked: false,
        },
        {
          id: 'noFidelityBothBeautyAndBarberActive',
          name: 'Da Fidelizzare',
          checked: false,
        },
      ],
      checkedCustomersWithService: [],
      checkedCustomersWithoutService: [],
      objectRecipient: {},
      fromFilter: today,
      toFilter: tomorrow,
      selectedRepeatType: 'noRepeat',
      customRepeatEventDialog: false,
      customRepeatNumber: 1,
      selectedCustomRepeatType: 'week',
      customRepeatTypes: [
        {
          value: 'day',
          text: 'Giorno'
        },
        {
          value: 'week',
          text: 'Settimana'
        },
        {
          value: 'month',
          text: 'Mese'
        },
        {
          value: 'year',
          text: 'Anno'
        },
      ],
      days: [
        {
          value: 0,
          text: 'D'
        },
        {
          value: 1,
          text: 'L'
        },
        {
          value: 2,
          text: 'M'
        },
        {
          value: 3,
          text: 'M'
        },
        {
          value: 4,
          text: 'G'
        },
        {
          value: 5,
          text: 'V'
        },
        {
          value: 6,
          text: 'S'
        },
      ],
      selectedRepeatDays: [
      ],
      customRepeatEndTypes: [
        {
          value: 'onSpecificDate',
          text: 'Data'
        },
        {
          value: 'afterNumberOccurences',
          text: 'Dopo'
        },
      ],
      selectedCustomRepeatEndType: 'onSpecificDate',
      customRepeatEndDate: undefined,
      customRepeatEndOccurrences: 1,
      customRepeatEvent: undefined,
      recurringDates: [],
      selectedCustomRepeatMonthType: 'exactDay',
      customRepeatEventFormValid: false,

      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      greaterThan0: (v) => {
        return (!isNaN(v) && v > 0 ) || "Valore non valido";
      },
      lessThanMaxRepeatNumber: (v) => {
        return (!isNaN(v) && v <= this.maxRepeatNumber ) || "Valore non valido";
      },
      lessThanMaxEndOccurences: (v) => {
        return (!isNaN(v) && v <= this.maxEndOccurences ) || "Valore non valido";
      },
    };
  },
  props: {
    value: {
      type: Object
    },
    valid: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    eventType: {
      type: String,
      default: undefined
    },
    showConfigurationTool: {
      type: Boolean,
      default: false
    },
    eventStartDate: {
      type: Date,
      default: () => undefined
    },
    eventEndDate: {
      type: Date,
      default: () => undefined
    }
  },
  async mounted() {
    this.$emit('update:valid', this.computedValid)
    await this.loadServiceTypes()
    await this.loadServiceTypesCategories()

    if (!!this.eventType && this.eventType == 'createFrom')
      this.newConfigurationTool = true
    
    if (!!this.localEventStartDate) {
      let localCustomRepeatEndDate = new Date(this.eventStartDate)
      localCustomRepeatEndDate.setMonth(localCustomRepeatEndDate.getMonth() + 3)
      this.customRepeatEndDate = localCustomRepeatEndDate

      if (this.selectedRepeatDays.length == 0) {
        this.selectedRepeatDays = [this.localEventStartDate.getDay()]
      }
    } 
  },
  methods: {
    handleSelectedImageUpdate(image) {
      this.selectedImg = image
      this.$emit('update:img', image)
    },
    updateObject() {

    },
    handleSelectRepeatEvent() {
      this.recurringDates = []

      if (this.selectedRepeatType == 'noRepeat') {
        this.recurringDates = []
      }

      else if ((typeof this.selectedRepeatType === 'object' && 
        !Array.isArray(this.selectedRepeatType) && this.selectedRepeatType !== null) || 
        this.selectedRepeatType == 'custom') {
          this.calculateRepeatEventCustom(this.recurringDates)
      } 
      
      else {
        let limitDate = new Date(this.localEventStartDate)
        limitDate.setFullYear(limitDate.getFullYear() + 2)
        this.calculateRepeatEvent(limitDate, this.recurringDates, this.selectedRepeatType)
      }

      this.$emit('input', this.localValue)
    },
    calculateRepeatEventCustom(recurringDates) {
      let startDateRecurring =  new Date(this.localEventStartDate)
      startDateRecurring.setHours(0, 0, 0, 0)

      let endDateRecurring = new Date(this.localEventEndDate)
      endDateRecurring.setHours(23, 59, 59)

      let interval = Number(this.customRepeatNumber)
      let occurenceOfWeekday = this.getOccurenceOfWeekday(startDateRecurring)
      
      if (this.selectedCustomRepeatEndType == 'onSpecificDate') {
        let limitDate = new Date(this.customRepeatEndDate)
        limitDate.setHours(0, 0, 0, 0)
                
        while (startDateRecurring < limitDate) {
          this.internalCalculationCustomRepeatEvent(startDateRecurring, endDateRecurring, interval, limitDate, recurringDates, occurenceOfWeekday)
        }
      } 
      
      else if (this.selectedCustomRepeatEndType == 'afterNumberOccurences') {
        let occurences = Number(this.customRepeatEndOccurrences)
        
        while (occurences > 0) {
          let previousLength = recurringDates.length
          this.internalCalculationCustomRepeatEvent(startDateRecurring, endDateRecurring, interval, undefined, recurringDates, occurenceOfWeekday)
          if (recurringDates.length > previousLength)
            occurences--
        }

      }
    },
    internalCalculationCustomRepeatEvent(startDateRecurring, endDateRecurring, interval, limitDate, recurringDates, occurenceOfWeekday) {
      if (this.selectedCustomRepeatType == 'day') {
        startDateRecurring.setDate(startDateRecurring.getDate() + interval)
        endDateRecurring.setDate(endDateRecurring.getDate() + interval)
            
        if ((!!limitDate && startDateRecurring <= limitDate)
          || !limitDate) {
          recurringDates.push({
            'from': new Date(startDateRecurring),
            'to': new Date(endDateRecurring)
          })
        }
      }

      if (this.selectedCustomRepeatType == 'week') {
        startDateRecurring.setDate(startDateRecurring.getDate() + 1)
        endDateRecurring.setDate(endDateRecurring.getDate() + 1)

        if (this.selectedRepeatDays.includes(startDateRecurring.getDay())
          && ((!!limitDate && startDateRecurring <= limitDate)
          || !limitDate)) {
          recurringDates.push({
            'from': new Date(startDateRecurring),
            'to': new Date(endDateRecurring)
          })
        }

        if (startDateRecurring.getDay() == 0) {
          startDateRecurring.setDate(startDateRecurring.getDate() - 7 + (interval * 7))
          endDateRecurring.setDate(endDateRecurring.getDate() - 7 + (interval * 7))
        }
      }

      if (this.selectedCustomRepeatType == 'month') {
        if (this.selectedCustomRepeatMonthType == 'exactDay') {
          startDateRecurring.setMonth(startDateRecurring.getMonth() + interval)
          endDateRecurring.setMonth(endDateRecurring.getMonth() + interval)

          if ((!!limitDate && startDateRecurring <= limitDate)
            || !limitDate) {
            recurringDates.push({
              'from': new Date(startDateRecurring),
              'to': new Date(endDateRecurring)
            })
          }
        }

        if (this.selectedCustomRepeatMonthType == 'exactWeekday') {
          if (this.localEventStartDate.getTime() == startDateRecurring.getTime() 
            || this.getOccurenceOfWeekday(startDateRecurring) >= 4) {
            startDateRecurring.setMonth(startDateRecurring.getMonth() + interval)
            endDateRecurring.setMonth(endDateRecurring.getMonth() + interval)
            startDateRecurring.setDate(1)
            endDateRecurring.setDate(1)
          }

          if (startDateRecurring.getDay() == this.localEventStartDate.getDay()) {
            startDateRecurring.setDate(startDateRecurring.getDate() + 7)
            endDateRecurring.setDate(endDateRecurring.getDate() + 7)
          } 

          else {
            startDateRecurring.setDate(startDateRecurring.getDate() + 1)
            endDateRecurring.setDate(endDateRecurring.getDate() + 1)
          }

          if (occurenceOfWeekday == this.getOccurenceOfWeekday(startDateRecurring)
            && ((!!limitDate && startDateRecurring <= limitDate)
            || !limitDate)) {
            recurringDates.push({
              'from': new Date(startDateRecurring),
              'to': new Date(endDateRecurring)
            })
          }
        }

      }

      if (this.selectedCustomRepeatType == 'year') {
        startDateRecurring.setFullYear(startDateRecurring.getFullYear() + interval)
        endDateRecurring.setFullYear(endDateRecurring.getFullYear() + interval)

        if ((!!limitDate && startDateRecurring <= limitDate)
          || !limitDate) {
          recurringDates.push({
            'from': new Date(startDateRecurring),
            'to': new Date(endDateRecurring)
          })
        }
      }
    },
    calculateRepeatEvent(limitDate, recurringDates, type) {
      let startDateRecurring =  new Date(this.localEventStartDate)
      startDateRecurring.setHours(0, 0, 0, 0)

      let endDateRecurring = new Date(this.localEventEndDate)
      endDateRecurring.setHours(23, 59, 59)

      limitDate.setHours(0, 0, 0, 0)

      let occurenceOfWeekday = this.getOccurenceOfWeekday(startDateRecurring)

      while (startDateRecurring < limitDate) {
        if (type == 'daily') {
          startDateRecurring.setDate(startDateRecurring.getDate() + 1)
          endDateRecurring.setDate(endDateRecurring.getDate() + 1)

          if (startDateRecurring <= limitDate) {
            recurringDates.push({
              'from': new Date(startDateRecurring),
              'to': new Date(endDateRecurring)
            })
          }
        }

        if (type == 'weekly') {
          startDateRecurring.setDate(startDateRecurring.getDate() + 7)
          endDateRecurring.setDate(endDateRecurring.getDate() + 7)

          if (startDateRecurring <= limitDate) {
            recurringDates.push({
            'from': new Date(startDateRecurring),
            'to': new Date(endDateRecurring)
            })
          }
        }

        if (type == 'monthly') {
          startDateRecurring.setDate(startDateRecurring.getDate() + 7)
          endDateRecurring.setDate(endDateRecurring.getDate() + 7)

          if (occurenceOfWeekday == this.getOccurenceOfWeekday(startDateRecurring)
            && startDateRecurring <= limitDate) {
            recurringDates.push({
              'from': new Date(startDateRecurring),
              'to': new Date(endDateRecurring)
            })
          }
        }

        if (type == 'yearly') {
          startDateRecurring.setFullYear(startDateRecurring.getFullYear() + 1)
          endDateRecurring.setFullYear(endDateRecurring.getFullYear() + 1)

          if (startDateRecurring <= limitDate) {
            recurringDates.push({
            'from': new Date(startDateRecurring),
            'to': new Date(endDateRecurring)
            })
          }
        }

        if (type == 'everyday') {
          startDateRecurring.setDate(startDateRecurring.getDate() + 1)
          endDateRecurring.setDate(endDateRecurring.getDate() + 1)

          if (startDateRecurring.getDay() != 0 && startDateRecurring.getDay() != 6
            && startDateRecurring <= limitDate) {
            recurringDates.push({
              'from': new Date(startDateRecurring),
              'to': new Date(endDateRecurring)
            })
          }
        }

      }
    },
    handleSaveCustomRepeatEvent() {
      this.customRepeatEvent = {
        repeatType: this.selectedCustomRepeatType,
        repeatNumber: this.customRepeatNumber,
        repeatDays: this.selectedRepeatDays,
        repeatEndType: this.selectedCustomRepeatEndType,
        repeatEndDate: this.customRepeatEndDate,
        repeatEndOccurrences: this.customRepeatEndOccurrences,
      }
      this.handleSelectRepeatEvent()
      this.customRepeatEventDialog = false
    },
    getOccurenceOfWeekday(date) {
      if (date.getDate() <= 7) {
        return 1;
      } else if (date.getDate() <= 14) {
        return 2;
      } else if (date.getDate() <= 21) {
        return 3;
      } else if (date.getDate() <= 28) {
        return 4;
      } else {
        return 5;
      }
    },
    async resetCheckboxRecipients(itemName)  {
      switch (itemName) {
        case 'sendToAllActiveCustomers':
          this.checkedActiveCustomers.forEach(el => el.checked = false)
          break;
        case 'sendToInactiveCustomers':
          this.checkedInactiveCustomers.forEach(el => el.checked = false)
          break;
        case 'sendToNewCustomers':
          this.checkedNewCustomers.forEach(el => el.checked = false)
          break;
        case 'sendToCustomersByAgeGroup':
          this.optionsAgeGroupCustomers.forEach(el => el.checked = false)
          break;
        case 'sendToCustomersByGender':
          this.optionsGenderCustomers.forEach(el => el.checked = false)
          break;
        case 'sendToCustomersWithService':
          this.checkedCustomersWithService.forEach(el => el.checked = false)
          break;
        case 'sendToCustomersWithoutService':
          this.checkedCustomersWithoutService.forEach(el => el.checked = false)
          break;
        case 'sendByFinancialService':
          this.optionsFinancialService.forEach(el => el.checked = false)
          break;
        case 'sendByFidelization':
          this.optionsFidelization.forEach(el => el.checked = false)
          break;
      
        default:
          break;
      }
    },
    async handleSelectedRecipients(item) {
      if (!!this.selectedRecipients && this.selectedRecipients[this.selectedRecipients.length - 1] == 'sendToAllCustomers')
        this.selectedRecipients = ['sendToAllCustomers']
      
      if (!!this.selectedRecipients && this.selectedRecipients[this.selectedRecipients.length - 1] != 'sendToAllCustomers'
        && this.selectedRecipients.includes('sendToAllCustomers'))
          this.selectedRecipients.splice(this.selectedRecipients.indexOf('sendToAllCustomers'), 1)
      
      if (!!this.selectedRecipients && this.selectedRecipients[this.selectedRecipients.length - 1] == 'sendToCustomersWithActivePromo'
        && this.selectedRecipients.includes('sendToCustomersWithInactivePromo'))
          this.selectedRecipients.splice(this.selectedRecipients.indexOf('sendToCustomersWithInactivePromo'), 1)
      
      if (!!this.selectedRecipients && this.selectedRecipients[this.selectedRecipients.length - 1] == 'sendToCustomersWithInactivePromo'
        && this.selectedRecipients.includes('sendToCustomersWithActivePromo'))
          this.selectedRecipients.splice(this.selectedRecipients.indexOf('sendToCustomersWithActivePromo'), 1)
      
      if (!!this.selectedRecipients && this.selectedRecipients[this.selectedRecipients.length - 1] == 'sendToCustomersWhoHasBought'
        && this.selectedRecipients.includes('sendToCustomersWhoDontBought'))
          this.selectedRecipients.splice(this.selectedRecipients.indexOf('sendToCustomersWhoDontBought'), 1)
      
      if (!!this.selectedRecipients && this.selectedRecipients[this.selectedRecipients.length - 1] == 'sendToCustomersWhoDontBought'
        && this.selectedRecipients.includes('sendToCustomersWhoHasBought'))
          this.selectedRecipients.splice(this.selectedRecipients.indexOf('sendToCustomersWhoHasBought'), 1)

      await this.resetCheckboxRecipients(item.name)

      await this.calculateRecipients()
    },
    async calculateRecipients() {
      this.objectRecipient = {}
      for (let i = 0; i < this.selectedRecipients.length; i++) {
        let selectedRecipient = this.selectedRecipients[i]
        switch (selectedRecipient) {
          case 'sendToAllActiveCustomers':
            this.objectRecipient["sendToAllActiveCustomers"] = this.checkedActiveCustomers.filter(el => !!el.checked).map(el => el.name)
            break;
          case 'sendToInactiveCustomers':
            this.objectRecipient["sendToInactiveCustomers"] = this.checkedInactiveCustomers.filter(el => !!el.checked).map(el => el.name)
            break;
          case 'sendToNewCustomers':
            this.objectRecipient["sendToNewCustomers"] = this.checkedNewCustomers.filter(el => !!el.checked).map(el => el.name)
            break;
          case 'sendByFinancialService': 
            this.objectRecipient["sendByFinancialService"] = this.optionsFinancialService.filter(el => !!el.checked).map(el => el.name)
            break;
          case 'sendToCustomersByAgeGroup':
            if (!this.objectRecipient["customerRegistryData"])
              this.objectRecipient["customerRegistryData"] = {}

            this.objectRecipient["customerRegistryData"]["sendToCustomersByAgeGroup"] = this.optionsAgeGroupCustomers.filter(el => !!el.checked).map(el => el.name)
            break;
          case 'sendToCustomersByGender':
            if (!this.objectRecipient["customerRegistryData"])
              this.objectRecipient["customerRegistryData"] = {}

            this.objectRecipient["customerRegistryData"]["sendToCustomersByGender"] = this.optionsGenderCustomers.filter(el => !!el.checked).map(el => el.id)
            break;
          case 'sendToCustomersWithService':
            this.objectRecipient["sendToCustomersWithService"] = this.checkedCustomersWithService.filter(el => !!el.checked).map(el => el.name)
            break;
          case 'sendToCustomersWithoutService':
            this.objectRecipient["sendToCustomersWithoutService"] = this.checkedCustomersWithoutService.filter(el => !!el.checked).map(el => el.name)
            break;
          case 'sendToBirthdayCustomers':
            if (!this.objectRecipient["customerRegistryData"])
              this.objectRecipient["customerRegistryData"] = {}

            this.objectRecipient["customerRegistryData"]["sendToBirthdayCustomers"] = true
            break;
          case 'sendToUnderageCustomers':
            if (!this.objectRecipient["customerRegistryData"])
              this.objectRecipient["customerRegistryData"] = {}

            this.objectRecipient["customerRegistryData"]["sendToUnderageCustomers"] = true
            break;
          case 'sendByFidelization':
            if (!this.objectRecipient["sendByFidelization"])
              this.objectRecipient["sendByFidelization"] = {}

            if (this.optionsOnlyBeautyFidelity.filter(el => el.checked == true).length > 0) {
              this.objectRecipient['sendByFidelization']['onlyBeauty'] = this.optionsOnlyBeautyFidelity.filter(el => !!el.checked).map(el => el.id)
            }

            if (this.optionsOnlyBarberFidelity.filter(el => el.checked == true).length > 0) {
              this.objectRecipient['sendByFidelization']['onlyBarber'] = this.optionsOnlyBarberFidelity.filter(el => !!el.checked).map(el => el.id)
            }

            if (this.optionsBeautyAndBarberFidelity.filter(el => el.checked == true).length > 0) {
              this.objectRecipient['sendByFidelization']['bothBarberAndBeauty'] = this.optionsBeautyAndBarberFidelity.filter(el => !!el.checked).map(el => el.id)
            }
            break;
  
          default:
            this.objectRecipient[selectedRecipient] = true;
        }
      }

      this.objectRecipient["fromFilter"] = this.fromFilter;
      this.objectRecipient["toFilter"] = this.toFilter;

      this.$emit('input', this.localValue)      
    },
    async showRecipients() {

      let recipientTool = !!this.selectedTool ? this.selectedTool == 'receipt' ? 'receipt'
        : 'othersSend' : undefined

      if (!this.selectedGoal || !recipientTool) {
        this.shownRecipients = []
        return
      }

      let localRecipients = !!this.recipients[recipientTool] && 
        !!this.recipients[recipientTool][this.selectedGoal] ? 
          this.recipients[recipientTool][this.selectedGoal] : []
      
      this.shownRecipients = this.typesOfRecipients.filter(el => localRecipients.includes(el.name))

    },
    handleChangeStartDate() {
      if (!this.localEventEndDate || new Date(this.localEventEndDate) < new Date(this.localEventStartDate))
        this.localEventEndDate = this.localEventStartDate
      
      this.$emit('input', this.localValue)
    },
    previewTemplateSelected() {
      if (this.selectedPreview == this.selectedImportedAction) {
        this.selectedPreview = undefined
        this.smsModel = {}
        this.receiptModel = undefined
      } else {
        this.selectedPreview = this.selectedImportedAction
        this.newConfigurationTool = false
        this.sendTime = !!this.selectedImportedAction.sendTime ? this.selectedImportedAction.sendTime.slice(1) : undefined
        if (this.selectedTool == 'sms') {
          this.smsModel = !!this.selectedImportedAction ? this.selectedImportedAction.smsModel : {}
          if (!!this.smsModel && Object.keys(this.smsModel).length > 0)
            this.smsModelValid = true
          else
            this.smsModelValid = false
        }
        if (this.selectedTool == 'receipt') {
          this.receiptModel = !!this.selectedImportedAction ? this.selectedImportedAction.receiptModel : undefined
          if (!!this.receiptModel)
            this.receiptModelValid = true
          else
            this.receiptModelValid = false
        }

        this.selectedImages = !!this.selectedImportedAction && !!this.selectedImportedAction.selectedImages
          ? this.selectedImportedAction.selectedImages : undefined
        this.selectedImg = !!this.selectedImportedAction && !!this.selectedImportedAction.selectedImages
          && this.selectedImportedAction.selectedImages.length > 0 ? this.selectedImportedAction.selectedImages[0] : undefined
        this.imageTags = !!this.selectedImportedAction && !!this.selectedImportedAction.imageTags 
          ? this.selectedImportedAction.imageTags.map(imageTag => imageTag.tagId) : undefined

        this.$emit('input', this.localValue)
        this.$emit('update:img', this.selectedImg)
      }
      
    }, 
    async loadImportedActions() {
      marketingActionsService.importedList({ ...this.importedActionsFilters, licenseId: true }).then((result) => {
        this.importedActions = !!result ? result.map((el) => {
          return {
            text: el.name,
            value: el
          }
        }) : []
        this.selectedImportedAction = undefined
        this.selectedPreview = undefined
      })
    },
    handleTimeChange(time) {
      if (!!this.localValue) {
        this.sendTime = time
        this.$emit('input', this.localValue)
      }
    },
    async loadServiceTypesCategories() {
      serviceTypesCategoriesService.list(undefined, undefined, {isBar: undefined}).then((serviceTypesCategories) => {
        this.serviceTypesCategories = serviceTypesCategories.rows

        this.checkedActiveCustomers = serviceTypesCategories.rows.map((el) => {
          return {
            id: el['id'],
            name: el['name'],
            checked: false
          }
        })

        this.checkedInactiveCustomers = serviceTypesCategories.rows.map((el) => {
          return {
            id: el['id'],
            name: el['name'],
            checked: false
          }
        })

        this.checkedNewCustomers = serviceTypesCategories.rows.map((el) => {
          return {
            id: el['id'],
            name: el['name'],
            checked: false
          }
        })

        if (!!this.value && Object.keys(this.value).length > 0)
          this.populateRecipientsFromValue(this.value.recipients)

      })
    },
    async loadServiceTypes() {
      serviceTypeService.list(undefined, undefined, {
        categories: ['PARRUCCHIERE', 'ESTETICA']
      }).then((serviceTypes) => {
        this.serviceTypes = serviceTypes.rows

        this.checkedCustomersWithService = serviceTypes.rows.map((el) => {
          return {
            id: el['id'],
            name: el['name'],
            checked: false
          }
        })

        this.checkedCustomersWithoutService = serviceTypes.rows.map((el) => {
          return {
            id: el['id'],
            name: el['name'],
            checked: false
          }
        })

        if (!!this.value && Object.keys(this.value).length > 0)
          this.populateRecipientsFromValue(this.value.recipients)
      })
    },
    async populateRecipientsFromValue(externalValue) {
      if (!externalValue || Object.keys(externalValue).length == 0 || 
        this.checkedActiveCustomers.length == 0 || this.checkedInactiveCustomers.length == 0 ||
        this.checkedNewCustomers.length == 0 || this.checkedCustomersWithService.length == 0 ||
        this.checkedCustomersWithoutService.length == 0 || this.optionsFinancialService.length == 0 ||
        this.optionsAgeGroupCustomers.length == 0 || this.optionsBeautyAndBarberFidelity.length == 0) return
      
      this.selectedRecipients = []
      for (const [key, value] of Object.entries(externalValue)) {
        switch (key) {
          case "sendToAllCustomers":
            await this.selectedRecipients.push(key)
            break;
          case "sendToAllActiveCustomers":
            this.checkedActiveCustomers.filter(el => value.includes(el.name)).forEach(el => el.checked = true)
            await this.selectedRecipients.push(key)
            break;
          case "sendToInactiveCustomers":
            this.checkedInactiveCustomers.filter(el => value.includes(el.name)).forEach(el => el.checked = true)
            await this.selectedRecipients.push(key)
            break;
          case "sendToNewCustomers":
            this.checkedNewCustomers.filter(el => value.includes(el.name)).forEach(el => el.checked = true)
            await this.selectedRecipients.push(key)
            break;
          case "sendToCustomersWithActivePromo":
            await this.selectedRecipients.push(key)
            break;
          case "sendToCustomersWithInactivePromo":
            await this.selectedRecipients.push(key)
            break;
          case "sendToCustomersWithService":
            this.checkedCustomersWithService.filter(el => value.includes(el.name)).forEach(el => el.checked = true)
            await this.selectedRecipients.push(key)
            break;    
          case "sendToCustomersWithoutService":
            this.checkedCustomersWithoutService.filter(el => value.includes(el.name)).forEach(el => el.checked = true)
            await this.selectedRecipients.push(key)
            break;    
          case "sendByFinancialService":
            this.optionsFinancialService.filter(el => value.includes(el.name)).forEach(el => el.checked = true)
            await this.selectedRecipients.push(key)
            break;    
          case "sendToCustomersWhoHasBought":
            await this.selectedRecipients.push(key)
            break;    
          case "sendToCustomersWhoDontBought":
            await this.selectedRecipients.push(key)
            break;    
          case "customerRegistryData":
            for (const [insideKey, insideValue] of Object.entries(value)) {
              switch (insideKey) {
                case "sendToCustomersByAgeGroup":
                  this.optionsAgeGroupCustomers.filter(el => insideValue.includes(el.name)).forEach(el => el.checked = true)
                  await this.selectedRecipients.push(insideKey)
                  break;
                case "sendToCustomersByGender":
                  this.optionsGenderCustomers.filter(el => insideValue.includes(el.id)).forEach(el => el.checked = true)
                  await this.selectedRecipients.push(insideKey)
                  break;
                case "sendToUnderageCustomers":
                  await this.selectedRecipients.push(insideKey)
                  break;
                case "sendToBirthdayCustomers":
                  await this.selectedRecipients.push(insideKey)
                  break;
              
                default:
                  break;
              }
            }
            break;
          case 'sendByFidelization':
            await this.selectedRecipients.push('sendByFidelization')
            for (const [insideKey, insideValue] of Object.entries(value)) {
              switch (insideKey) {
                case "onlyBeauty":
                  this.optionsOnlyBeautyFidelity.filter(el => insideValue.includes(el.id)).forEach(el => el.checked = true)
                  break;
                case "onlyBarber":
                  this.optionsOnlyBarberFidelity.filter(el => insideValue.includes(el.id)).forEach(el => el.checked = true)
                  break;
                case "bothBarberAndBeauty":
                  this.optionsBeautyAndBarberFidelity.filter(el => insideValue.includes(el.id)).forEach(el => el.checked = true)
                  break;
              
                default:
                  break;
              }
            }
            break;
        
          default:
            break;
        }
      }
    }
  },
  watch: {
    computedValid(newVal) {
      this.$emit('update:valid', newVal)
    },
    async value(newVal) {
      if (!!newVal) {
        let onMount = false
        
        if (!this.selectedGoal && !this.selectedTool) {
          onMount = true
        }
        
        this.name = newVal.name
        this.description = newVal.description
        this.selectedGoal = newVal.goal
        this.serviceTypesCategoriesSelected = !!newVal.categories ? newVal.categories.map((el) => {
          return { name: el }
        }) : []
        this.smsModel = !!newVal.smsModel ? newVal.smsModel : {}
        this.landingPageModel = newVal.landingPage
        this.receiptModel = newVal.receiptModel
        this.selectedTool = !newVal.selectedTool && !!newVal.smsModel ? 'sms' : !newVal.selectedTool && newVal.receiptModel ? 'receipt'
          : !newVal.selectedTool && newVal.landingPage ? 'landingPage' : newVal.selectedTool
        this.sendTime = !!newVal.sendTime ? newVal.sendTime.length === 8 ? 
          newVal.sendTime.slice(0, -3) : newVal.sendTime : undefined
        this.selectedReceiptOption = !!newVal.selectedReceiptOption ? newVal.selectedReceiptOption : 
          !!newVal.receiptModel ? newVal.receiptModel.type : undefined

        if (!this.selectedImg) {
          this.selectedImg = !!newVal.selectedImages && newVal.selectedImages.length > 0 ? newVal.selectedImages[0] : undefined
          this.$emit('update:img', this.selectedImg)
        }

        if (!this.selectedImages) {
          this.selectedImages = newVal.selectedImages
        }

        if (!this.imageTags) {
          this.imageTags = !!newVal.imageTags ? newVal.imageTags.map(imageTag => imageTag.tagId) : undefined
        }

        if (!this.selectedRecipients) {
          this.objectRecipient = newVal.recipients
          this.selectedRecipients = []

          if (!!newVal.recipients) {
            if (!!newVal.recipients.fromFilter)
              this.fromFilter = new Date(newVal.recipients.fromFilter)

            if (!!newVal.recipients.toFilter)
              this.toFilter = new Date(newVal.recipients.toFilter)

            await this.populateRecipientsFromValue(newVal.recipients)
          }
        }

        this.selectedRepeatType = !!newVal.recurrenceType ? newVal.recurrenceType : 'noRepeat'
        
        if (!!newVal.customRecurrence) {
          this.selectedCustomRepeatType = newVal.customRecurrence.repeatType
          this.customRepeatNumber = !!newVal.customRecurrence.repeatNumber ? 
            Number(newVal.customRecurrence.repeatNumber) : undefined
          this.selectedRepeatDays = !!newVal.customRecurrence.repeatDays ? 
            newVal.customRecurrence.repeatDays.map((el) => {
              return Number(el)
            }) : []
          this.selectedCustomRepeatEndType = newVal.customRecurrence.repeatEndType
          this.customRepeatEndDate = !!newVal.customRecurrence.repeatEndDate ? 
            new Date(newVal.customRecurrence.repeatEndDate) : undefined
          this.customRepeatEndOccurrences = !!newVal.customRecurrence.repeatEndOccurrences ?
            Number(newVal.customRecurrence.repeatEndOccurrences) : undefined
          
          this.customRepeatEvent = {
            repeatType: this.selectedCustomRepeatType,
            repeatNumber: this.customRepeatNumber,
            repeatDays: this.selectedRepeatDays,
            repeatEndType: this.selectedCustomRepeatEndType,
            repeatEndDate: this.customRepeatEndDate,
            repeatEndOccurrences: this.customRepeatEndOccurrences,
          }
        }
        
        if (!!onMount) {
          this.showRecipients()
          onMount = false
        }
      }
    },
    localEventStartDate(newVal) {
      if (!!newVal && this.selectedRepeatDays.length == 0 || 
        this.selectedRepeatDays.length > 0 && !this.selectedRepeatDays.includes(newVal.getDay())) {
        this.selectedRepeatDays = [newVal.getDay()]
      }
    },
    selectedRepeatDays(newVal) {
      if (!!newVal && !newVal.includes(this.localEventStartDate.getDay())) {
        this.selectedRepeatDays = [
          this.localEventStartDate.getDay(),
          ...newVal
        ]
      }
    }
  },
  computed: {
    computedValid() {
      return !!this.localEventStartDate && !!this.localEventEndDate 
        && this.formValid && !!this.selectedGoal && !!this.selectedTool 
        && !!this.serviceTypesCategoriesSelected && this.serviceTypesCategoriesSelected.length > 0
        && (((this.selectedTool == 'sms' || this.selectedTool == 'landingPage') && !!this.sendTime)
          || this.selectedTool == 'receipt')
        && ((this.selectedTool == 'sms' && !!this.smsModelValid)
        || (this.selectedTool == 'receipt' && !!this.receiptModelValid)
        || (this.selectedTool == 'landingPage' && !!this.landingPageModelValid))
        && (
          (!!this.selectedRecipients && !this.selectedRecipients.includes('sendToAllActiveCustomers')
            || (!!this.checkedActiveCustomers && this.checkedActiveCustomers.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && !this.selectedRecipients.includes('sendToInactiveCustomers')
            || (!!this.checkedInactiveCustomers && this.checkedInactiveCustomers.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && !this.selectedRecipients.includes('sendToNewCustomers')
            || (!!this.checkedNewCustomers && this.checkedNewCustomers.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && !this.selectedRecipients.includes('sendToCustomersByAgeGroup')
            || (!!this.optionsAgeGroupCustomers && this.optionsAgeGroupCustomers.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && !this.selectedRecipients.includes('sendToCustomersByGender')
            || (!!this.optionsGenderCustomers && this.optionsGenderCustomers.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && !this.selectedRecipients.includes('sendToCustomersWithService')
            || (!!this.checkedCustomersWithService && this.checkedCustomersWithService.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && !this.selectedRecipients.includes('sendToCustomersWithoutService')
            || (!!this.checkedCustomersWithoutService && this.checkedCustomersWithoutService.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && !this.selectedRecipients.includes('sendByFinancialService')
            || (!!this.optionsFinancialService && this.optionsFinancialService.filter(el => el.checked == true).length > 0))

        && (!!this.selectedRecipients && this.selectedRecipients.length > 0)
        )
    },
    nextStartDate() {
      if (!!this.localEventStartDate) {
        let dateTemp = new Date(this.localEventStartDate)
        const offset = dateTemp.getTimezoneOffset()
        dateTemp = new Date(this.localEventStartDate.getTime() - (offset * 60 * 1000))
        return dateTemp
      }
      else 
        return undefined
    },
    repeatTypes() {
      let repeatTypes = [
        {
          value: 'noRepeat',
          text: 'Non ripetere'
        },
        {
          value: 'daily',
          text: 'Ogni giorno'
        },
        {
          value: 'weekly',
          text: 'Ogni settimana'
        },
        {
          value: 'monthly',
          text: 'Ogni mese'
        },
        {
          value: 'yearly',
          text: 'Ogni anno'
        },
        {
          value: 'everyday',
          text: 'Tutti i giorni della settimana (dal lunedì al venerdì)'
        },
        {
          value: 'custom',
          text: 'Personalizza'
        },
      ]

      if (!!this.localEventStartDate) {
        let eventStartDateDay = this.localEventStartDate.getDay()
        let eventStartDateDayString = DateHandler.WEEKDAYS[eventStartDateDay]
        repeatTypes = [
          {
            value: 'noRepeat',
            text: 'Non ripetere'
          },
          {
            value: 'daily',
            text: 'Ogni giorno'
          },
          {
            value: 'weekly',
            text: 'Ogni settimana il ' + eventStartDateDayString
          },
          {
            value: 'monthly',
            text: 'Ogni mese il ' + this.getOccurenceOfWeekday(this.localEventStartDate) + '° ' + eventStartDateDayString
          },
          {
            value: 'yearly',
            text: 'Ogni anno il ' + this.localEventStartDate.getDate() + ' ' + DateHandler.MONTHS[this.localEventStartDate.getMonth()]
          },
          {
            value: 'everyday',
            text: 'Tutti i giorni della settimana (dal lunedì al venerdì)'
          },
          {
            value: 'custom',
            text: 'Personalizza'
          },
        ]
      }

      if (!!this.customRepeatEvent && !!this.customRepeatNumber && !!this.selectedCustomRepeatType) {
        let calculateText = 'Ogni ' + this.customRepeatNumber + ' ' + 
          this.customRepeatTypes.filter(el => el.value == this.selectedCustomRepeatType).map(el => el.text)
        
        if (this.selectedCustomRepeatType == 'week' && !!this.selectedRepeatDays && 
          this.selectedRepeatDays.length > 0) {
          calculateText += ' il ' + this.selectedRepeatDays.map(i => DateHandler.WEEKDAYS[i]).join(', ')
        }

        if (this.selectedCustomRepeatType == 'month' && !!this.selectedCustomRepeatMonthType) {
          calculateText += ' il ' + 
            this.customRepeatMonthTypes.filter(el => el.value == this.selectedCustomRepeatMonthType).map(el => el.text.toLowerCase().replace('mensilmente il', ''))
        }

        if (this.selectedCustomRepeatEndType == 'onSpecificDate' && !!this.customRepeatEndDate) {
          calculateText += ', fino al ' + this.customRepeatEndDate.toLocaleDateString('it-IT')
        } else if (!!this.customRepeatEndOccurrences) {
          calculateText += ', per ' + this.customRepeatEndOccurrences + ' volte'
        }
        repeatTypes.splice(repeatTypes.length - 1, 0, {
          value: this.customRepeatEvent,
          text: calculateText
        })

        this.selectedRepeatType = this.customRepeatEvent
      }

      return repeatTypes
    },
    maxEndOccurences() {
      if (!!this.selectedCustomRepeatType) {
        if (this.selectedCustomRepeatType == 'day') {
          return Math.floor(731 / Number(this.customRepeatNumber))
        } else if (this.selectedCustomRepeatType == 'week') {
          return Math.floor(104 / Number(this.customRepeatNumber)) * this.selectedRepeatDays.length
        } else if (this.selectedCustomRepeatType == 'month') {
          return Math.floor(24 / Number(this.customRepeatNumber))
        } else if (this.selectedCustomRepeatType == 'year') {
          return Math.floor(2 / Number(this.customRepeatNumber))
        }
      }
    },
    maxRepeatNumber() {
      if (!!this.selectedCustomRepeatType) {
        if (this.selectedCustomRepeatType == 'day') {
          return 731
        } else if (this.selectedCustomRepeatType == 'week') {
          return 104
        } else if (this.selectedCustomRepeatType == 'month') {
          return 24
        } else if (this.selectedCustomRepeatType == 'year') {
          return 2
        }
      }
    },
    customRepeatMonthTypes() {
      return [
        {
          value: 'exactDay',
          text: 'Mensilmente il giorno ' + this.localEventStartDate.getDate(),
        },
        {
          value: 'exactWeekday',
          text: 'Mensilmente il ' + this.getOccurenceOfWeekday(this.localEventStartDate) + '° ' + DateHandler.WEEKDAYS[this.localEventStartDate.getDay()],
        }
      ]
    },
    localValue() {
      return {
        id: !!this.value ? this.value.id : undefined,
        eventStartDate: this.localEventStartDate,
        eventEndDate: this.localEventEndDate,
        name: this.name,
        description: this.description,
        goal: this.selectedGoal,
        categories: !!this.serviceTypesCategoriesSelected ? 
          this.serviceTypesCategoriesSelected.map(el => el.name) : undefined,
        smsModel: !!this.smsModel ? Object.keys(this.smsModel).length > 0 ? this.smsModel : undefined : undefined,
        landingPage: this.landingPageModel,
        receiptModel: this.receiptModel,
        selectedTool: this.selectedTool,
        selectedReceiptOption: this.selectedReceiptOption,
        sendTime: this.sendTime,
        recipients: this.objectRecipient,
        recurringDates: this.recurringDates,
        recurrenceType: (typeof this.selectedRepeatType === 'object' && 
          !Array.isArray(this.selectedRepeatType) && this.selectedRepeatType !== null) ? 
            'custom' : this.selectedRepeatType,
        customRecurrence: this.customRepeatEvent,
        imageTags: this.imageTags,
        selectedImages: this.selectedImages,
        selectedImageId: !!this.selectedImages && this.selectedImages.length > 0 
          ? this.selectedImages[0].id : undefined
      }
    },
  },
};
</script>

<style>
</style>