import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class barcodeManagment {
  list(entityName, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/barcode/" + entityName + "/list")
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get(entityName, entityId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/barcode/" + entityName + "/get/" + entityId + "")
        .then((response) => {
          if (response.success) {
            resolve(response.data.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  update(entityName, entity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!entityName || !entity || !entity.id) {
        reject(new Error("entityName or entity not specified"));
        return;
      }
      axios
        .post(apiUrl + "/barcode/" + entityName + "/update", entity)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateDefault(entityName, entity) {
    return new Promise(async (resolve, reject) => {
      let oldBarcode = { id: entity.currentBarcode.id, default: false };
      await this.update(entityName, oldBarcode);
      let newBarcode = { id: entity.id, default: true};
      await this.update(entityName, newBarcode);
      resolve()
    });
  }
  
  getByCode(entityName, barcode) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/barcode/" + entityName + "/get", { barcode: barcode })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  create(barcode, entityName, entity, defaultValue = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!barcode || !entityName || !entity || !entity.id) {
        reject(new Error("barcode, entityName or entity not specified"));
        return;
      }

      axios
        .post(apiUrl + "/barcode/" + entityName + "/create", {
          code: barcode,
          entityTableName: entityName,
          entityId: entity.id,
          default: defaultValue,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
          else reject(response.results.message)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(entityName, entity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!entityName || !entity || !entity.id) {
        reject(new Error("barcode, entityName or entity not specified"));
        return;
      }

      axios
        .post(apiUrl + "/barcode/" + entityName + "/delete", entity)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
export default new barcodeManagment();
