<template>
  <div>
    <v-select
      v-if="!hideSubcategories"
      :label="label"
      no-data-text="Reparto - Famiglia"
      :dense="dense"
      :filled="filled"
      :hide-details="hideDetails"
      :value="selectedItems"
      :items="[selectedItems]"
      :rules="rules"
    >
      <template v-slot:append-item>
        <GroupedSingleSelectList
          v-model="localSingleValue"
          :items="serviceTypes"
          :categories-identifier-key="categoriesIdentifierKey"
          :items-identifier-key="itemsIdentifierKey"
          :categories-text-key="categoriesTextKey"
          :items-key="itemsKey"
          :items-text-key="itemsTextKey"
          @input="$emit('input', $event)"
        >
        </GroupedSingleSelectList>
      </template>
    </v-select>
    <v-select
      v-else
      :dense="dense"
      :filled="filled"
      multiple
      :label="label"
      :items="serviceTypes"
      :item-text="categoriesTextKey"
      :item-value="categoriesIdentifierKey"
      @input="$emit('input', $event)"
    >
    </v-select>
  </div>
</template>

<script>
import Vue from "vue";
import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js";
import GroupedSelectList from "@/components/common/GroupedSelectList";
import GroupedSingleSelectList from "@/components/common/GroupedSingleSelectList";
import StandardDialog from "@/components/common/StandardDialog";

export default {
  name: "ServiceTypeCategoriesAutocomplete",
  components: {
    GroupedSelectList,
    GroupedSingleSelectList,
    StandardDialog,
  },
  data: function () {
    return {
      localSingleValue: undefined,
      localLoading: false,
      serviceTypes: [],
      selectedItems: "",
      showAlert: false,
      currentEvent: undefined,
    };
  },
  props: {
    value: {},
    categoriesIdentifierKey: {
      type: String,
      default: "id",
    },
    itemsIdentifierKey: {
      type: String,
      default: "id",
    },
    categoriesTextKey: {
      type: String,
      default: "name",
    },
    itemsKey: {
      type: String,
      default: "serviceTypes",
    },
    itemsTextKey: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "Reparto - Famiglia",
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    itemValue: {
      type: String,
      default: "name",
    },
    itemText: {
      type: String,
      default: "name",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideSubcategories: {
      type: Boolean,
      default: false,
    },    
    hideDetails: {
      type: String,
      default: "auto",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    bus: {
      type: Object,
      default: () => new Vue(),
    },
    rules: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    }
  },
  mounted: function () {
    this.loadServiceTypeCategories();

    if (!!this.bus) {
      this.bus.$on("reload", () => {
        this.loadServiceTypeCategories();
      });
    }
  },
  methods: {
    loadServiceTypeCategories() {
      this.localLoading = true;
      this.filters.serviceTypes = true;
      serviceTypesCategoriesService.list(1, 300, this.filters)
        .then((response) => {
          this.serviceTypes = response.rows;
          this.localLoading = false;
        });
    },
  },
  computed: {
    doesLoading() {
      return this.localLoading || this.loading;
    },
  },
  watch: {
    value(newVal) {
      !!newVal ? this.localSingleValue = newVal : this.localSingleValue = {}
      if (!!newVal) {
        this.selectedItems = newVal.name
        this.$emit('input', this.localSingleValue)
      }
    },
  },
};
</script>

<style>
</style>