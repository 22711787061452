import authService from '@/services/auth/auth.service.js';
const paymentTermsRoutes = [
  {
    path: "/payments/paymentTerms",
    name: "PaymentTermsNewForm",
    component: () => import("@/components/areas/payments/paymentTerms/PaymentTermsNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/paymentTerms/:id/edit",
    name: "PaymentTermsEditForm",
    component: () => import("@/components/areas/payments/paymentTerms/PaymentTermsEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/paymentTerms/list",
    name: "PaymentTermsList",
    component: () =>
      import(
        "@/components/areas/payments/paymentTerms/PaymentTermsList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  },
  {
    path: "/payments/paymentConditions",
    name: "PaymentConditionsNewForm",
    component: () => import("@/components/areas/payments/paymentConditions/PaymentConditionsNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/paymentConditions/:id/edit",
    name: "PaymentConditionsEditForm",
    component: () => import("@/components/areas/payments/paymentConditions/PaymentConditionsEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
  },
  {
    path: "/payments/paymentConditions/list",
    name: "PaymentConditionsList",
    component: () =>
      import(
        "@/components/areas/payments/paymentConditions/PaymentConditionsList.vue"
      ),
  },
  // {
  //   path: "/payments/paymentTypeNatureExempions",
  //   name: "PaymentTypeNatureExempionsNewForm",
  //   component: () => import("@/components/areas/payments/paymentTypeNatureExempions/PaymentTypeNatureExempionsNewForm.vue"),
  //   props: (route) => {
  //     return {
  //       openDialog: true,
  //       pathToGoBack: route.query.pathToGoBack,
  //       tabName: route.query.tabName || 'general'
  //     }
  //   },
  // },
  // {
  //   path: "/payments/paymentTypeNatureExempions/:id/edit",
  //   name: "PaymentTypeNatureExempionsEditForm",
  //   component: () => import("@/components/areas/payments/paymentTypeNatureExempions/PaymentTypeNatureExempionsEditForm.vue"),
  //   props: (route) => {
  //     return {
  //       openDialog: true,
  //       pathToGoBack: route.query.pathToGoBack,
  //       tabName: route.query.tabName || 'general'
  //     }
  //   },
  // },
  // {
  //   path: "/payments/paymentTypeNatureExempions/list",
  //   name: "PaymentTypeNatureExempionsList",
  //   component: () =>
  //     import(
  //       "@/components/areas/payments/paymentTypeNatureExempions/PaymentTypeNatureExempionsList.vue"
  //     ),
  // }
];

export default paymentTermsRoutes;