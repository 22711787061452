<template>
  <div>
    <StandardDialog
      v-model="smsModelDialog"
      title="Modifica Modelli"
      without-padding
      dialog-height="fit-content"
    >
      <v-list
        v-if="smsModels.length > 0" 
        two-line 
        color="transparent"
      >
        <div class="text-center">
          <v-btn icon class="my-2" @click="handleNewSmsModel">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div v-for="smsModel in smsModels" v-bind:key="smsModel.id">
          <v-list-item v-if="searchMatch(smsModel)">
            <v-list-item-content class="text-left">
              <v-list-item-title>
                {{smsModel.name}}
              </v-list-item-title>
              <v-list-item-subtitle>
                <SmsTextVisualizer
                  :value="smsModel.text"
                ></SmsTextVisualizer>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn 
                icon
                @click="handleEditSmsModel(smsModel)"
              >
                <v-icon medium>mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <StandardMenu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon medium>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item 
                    link 
                    v-on:click="handleEditSmsModel(smsModel)"
                  >
                    <v-list-item-title>Modifica</v-list-item-title>
                  </v-list-item>
                  <v-list-item 
                    link 
                    v-on:click="deleteSmsModel(smsModel)"
                  >
                    <v-list-item-title>Elimina</v-list-item-title>
                  </v-list-item>
                </v-list>
              </StandardMenu>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
      <div
        v-else
        class="font-weight-thin font-italic d-flex align-content-center mt-5"
        style="width: 100%"
      >
        <span style="margin: auto">Nessun Modello</span>
      </div>
      <template v-slot:footer-actions>
        <v-btn
          text
          v-on:click="goBack"
          color="error"
        >
          Annulla
        </v-btn>
      </template>
    </StandardDialog>

    <StandardDialog 
      v-model="newSmsModelDialog" 
      title="Creazione Modello" 
      :dialog-height="null" 
      persistent
      dialog-width="1000"
    >
      <div>
        <SmsModelForm 
          v-model="newSmsModel" 
          class="py-2"
          :valid.sync="newSmsModelValid"
          :hide-upload-image="true"
        ></SmsModelForm>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn 
          text 
          color="error" 
          @click="newSmsModelDialog = false" 
          :disabled="loadingCreate"
        >Annulla</v-btn>
        <v-btn 
          text 
          color="default" 
          @click="createSmsModel" 
          :loading="loadingCreate" 
          :disabled="!newSmsModelValid"
        >Salva</v-btn>
      </template>
    </StandardDialog>

    <StandardDialog 
      v-model="editSmsModelDialog" 
      title="Modifica Modello" 
      :dialog-height="null" 
      persistent
      dialog-width="1000"
    >
      <div>
        <SmsModelForm 
          v-model="editSmsModel" 
          class="py-2"
          :valid.sync="editSmsModelValid"
          :hide-upload-image="true"
        ></SmsModelForm>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn 
          text 
          color="error" 
          @click="editSmsModelDialog = false" 
          :disabled="loadingUpdate"
        >Annulla</v-btn>
        <v-btn 
          text 
          color="default" 
          @click="updateSmsModel"
          :loading="loadingUpdate" 
          :disabled="!editSmsModelValid"
        >Salva</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
  import smsModelsService from '@/services/smsModels/smsModels.service.js';
  import SmsModelForm from '@/components/setting/sms/SmsModelForm.vue';
  import SmsTextVisualizer from '@/components/setting/sms/SmsTextVisualizer.vue';
  import StandardDialog from '@/components/common/StandardDialog.vue';
  import StandardMenu from '@/components/common/StandardMenu.vue';
  import Vue from 'vue';

export default {
  name: "SmsModelEdit",
  components: {
    SmsModelForm,
    StandardDialog,
    StandardMenu,
    SmsTextVisualizer
  },
  data : function() {
    return {
      smsModels: [],
      loadingSmsModels: false,
      editSmsModel: {},
      editSmsModelDialog: undefined,
      newSmsModel: {},
      smsModelDialog: true,
      newSmsModelDialog: false,
      newSmsModelValid: false,
      editSmsModelValid: false,
      loadingUpdate: false,
      loadingCreate: false,
    }
  },
  props: {
    pathToGoBack: {
      type: String,
      default: ""
    },
  },
  mounted: function() {
    this.fetchSmsModels();
  },
  methods: {
    fetchSmsModels() {
      this.loadingSmsModels = true
      smsModelsService.list().then((models) => {
        this.smsModels = models.rows
        this.loadingSmsModels = false
      })
    },
    openSmsModelDialog(){
      this.smsModelDialog = true
    },
    createSmsModel() {
      this.loadingCreate = true
      smsModelsService.create(this.newSmsModel).then((models) => {
        this.fetchSmsModels()
        this.newSmsModel = {}
        this.loadingCreate = false
        this.newSmsModelDialog = false
      })
    },
    searchMatch(smsModel) {
      if (
        smsModel == undefined ||
        smsModel == null ||
        this.searchText == undefined ||
        this.searchText == null ||
        this.searchText == ""
      ) {
        return true;
      } else {
        return smsModel.name.toLowerCase().includes(this.searchText.toLowerCase()) 
      }
    },
    handleEditSmsModel(smsModel) {
      this.editSmsModel = smsModel
      this.editSmsModelDialog = true
    },
    deleteSmsModel(smsModel) {
      let confirmed = confirm('Vuoi davvero cancellare il modello SMS?')

      if(confirmed) {
        smsModelsService.delete(smsModel).then((models) => {
          this.fetchSmsModels()
        })
      }
    },
    handleNewSmsModel() {
      this.newSmsModel = {}
      this.newSmsModelDialog = true
    },
    goBack() {
      this.smsModelDialog = false
      this.$router.push({path: this.pathToGoBack})
    },
    updateSmsModel() {
      this.loadingUpdate = true
      smsModelsService.update(this.editSmsModel).then((models) => {
        this.fetchSmsModels()
        this.editSmsModel = {}
        this.loadingUpdate = false
        this.editSmsModelDialog = false
      })
    },
  }
}




</script>