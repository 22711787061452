export default {
  namespaced: true,
  state: {
    data: { 
      item: { },
      valid: false
    },
  },
  mutations: {
    UPDATE(state, value) {
      state.data.item = value;
    },
    SET_FIELD(state, params) {
      state.data.item[params.field] = params.value 
    },
    SET_INVALID(state) {
      state.data.valid = false
    },
    SET_VALID(state) {
      state.data.valid = true
    }
  },
  actions: {
    updateField({ commit }, params) {
      if(!!params.field && (params.value !== undefined || typeof params.value == "boolean")) {
        commit("SET_FIELD", params);
      }
    },
    resetFields({ commit }) {
      commit("UPDATE", {});
    },
    setInvalid({ commit }) {
      commit("SET_INVALID")
    },
    setValid({ commit }) {
      commit("SET_VALID")
    },
    setValidState({commit}, valid) {
      if(valid) {
        commit("SET_VALID")
      } else {
        commit("SET_INVALID")
      }
    }
  }
};
