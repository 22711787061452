import authService from "./auth.service"

export default {
  install: (Vue, options) => {
    Vue.mixin({
      created: function () {
        if (!!this.$options.isLicenseBlocked) {
          if (!!this.$options.isLicenseBlocked.change) {
            this.$options.isLicenseBlocked.change = this.$options.isLicenseBlocked.change.bind(this)
            authService.on('login', this.$options.isLicenseBlocked.change)
          }

          if (!!this.$options.isLicenseBlocked.bind) {
            authService.getIsLicenseBlocked().then((isBlocked) => {
              this[this.$options.isLicenseBlocked.bind] = isBlocked

              let updateCallback = (user) => {
                this[this.$options.isLicenseBlocked.bind] = isBlocked
              }
              updateCallback = updateCallback.bind(this)
              authService.on('login', updateCallback)
            })
          }
        }

        if (!!this.$options.isOnlyWheelOfFortune) {
          if (!!this.$options.isOnlyWheelOfFortune.change) {
            this.$options.isOnlyWheelOfFortune.change = this.$options.isOnlyWheelOfFortune.change.bind(this)
            authService.on('login', this.$options.isOnlyWheelOfFortune.change)
          }

          if (!!this.$options.isOnlyWheelOfFortune.bind) {
            authService.getIsOnlyWheelOfFortune().then((isBlocked) => {
              this[this.$options.isOnlyWheelOfFortune.bind] = isBlocked

              let updateCallback = (user) => {
                this[this.$options.isOnlyWheelOfFortune.bind] = isBlocked
              }
              updateCallback = updateCallback.bind(this)
              authService.on('login', updateCallback)
            })
          }
        }
      }
    })
  }
}