const interestsRoutes = [
  {
    path: "/registries/interests/list",
    name: "InterestsRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/interests/InterestsRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      } 
    }
  }
];

export default interestsRoutes;