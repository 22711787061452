import translationService from './translations.service'

export default {
  install: (Vue, options) => {
    Vue.mixin({
      created: function () {
        let handler = function (newLang) {
          setTimeout(() => {
            this.$forceUpdate()
          }, 100);
        }
        handler = handler.bind(this)
        translationService.on('locale-changed', handler)

        this.$once('hook:beforeDestroy', function () {
          translationService.off('locale-changed', handler)
        })
      }
    })

    Vue.prototype.$translations = {
      t: function (key) {
        return translationService.translate(key)
      },
      setLocale: function(lang) {
        return new Promise((resolve, reject) => {
          translationService.setLocale(lang).then((result) => {
            resolve(result)
          }).catch((err) => {
            reject(err)
          })
        })
      }
    }
  }
}