
<template>
  <div>
    <v-btn
      id="chat-avatar"
      v-show="!voiceRecording"
      class="chat-button"
      fab
      @click="openChat"
    >
      <v-icon color="primary"> mdi-face-agent</v-icon>
    </v-btn>

    <div class="chat-popup" id="chat">
      <v-form
        class="form-container"
        id="chat-form"
        style="min-height: 82vh; max-height: 82vh"
      >
        <div class="header">
          <v-divider class="ma-2"></v-divider>
          <v-icon style="float: right" @click="closeChat"> mdi-close </v-icon>
          <v-chip style="width: 100%; height: 60px">
            <v-icon class="mr-3"> mdi-face-agent </v-icon>
            <div class="d-flex flex-row justify-center align-center">
              <div class="d-flex flex-column pt-3">
                <div class="d-flex">
                  <div class="luluu">Luluu</div>
                  <v-badge dot color="success"></v-badge>
                </div>
                <div class="small-text">{{ statusBot }}</div>
              </div>
              <v-divider vertical class="ml-3 mr-3"></v-divider>
              <span class="small-text mt-5"
                >{{ currentWeekday }} , {{ currentDay }}/{{ currentMonth }}/{{
                  currentYear
                }}
                <Clock :is-white="false"></Clock>
              </span>
            </div>
          </v-chip>
        </div>
        <div
          class="imessage"
          id="chat-messages"
          :style="cssProps"
          style="max-height: 65vh; min-height: 65vh; overflow-y: scroll"
        >
          <p
            class="luluu-text-white"
            v-for="mes in messages"
            :key="mes.idx"
            :class="mes.class"
          >
            <!-- <UserAvatar :size="20" :value="mes.avatar"> </UserAvatar> -->

            {{ mes.text }}

            <template v-if="!!mes.links">
              <template v-for="link in mes.links">
                <!-- <br :key="link.url" /> -->
                <a
                  :href="link.url"
                  target="”_blank”"
                  style="color: white"
                  >{{ link.label }}</a
                >
              </template>
            </template>

            <span v-if="mes.idx === indexFAQ">
              <br />
              <br />
              <span class="">
                {{ defaultQuestion.title }}
              </span>
              <br />

              <span
                style="text-decoration: underline; cursor: pointer"
                v-for="q in defaultQuestion.questions"
                :key="q.idx"
                @click="
                  askDefault(
                    q.entity,
                    q.action,
                    q.text,
                    q.answer,
                    q.links,
                    q.query,
                    q.audio
                  )
                "
              >
                <br />
                {{ q.text }}
                <br />
              </span>
            </span>

            <span
              class="d-flex justify-end mt-2"
              :class="{
                'small-text-white': mes.fromLuluu,
                'small-text-white': !mes.fromLuluu,
              }"
            >
              {{ mes.hour }}
            </span>
          </p>
          <LoadingMessage v-if="isTyping"></LoadingMessage>

          <!-- <div v-for="mes in messages" :key="mes.idx">
            <v-avatar :size="30">
              <v-icon>mdi-face-agent</v-icon>
            </v-avatar>
          </div> -->
        </div>
        <!--<v-row>
          <v-col cols="12">
            <div class="d-flex flex-row">
              <input
                placeholder="Message"
                @keypress="pressEnter"
                class="border-default"
                v-model="messageToSend"
              />

              <v-btn
                :disabled="messageToSend == ''"
                class="ml-5"
                fab
                small
                @click="sendMessage"
              >
                <v-icon> mdi-send-outline </v-icon>
              </v-btn>

            </div>
          </v-col>
        </v-row>-->
      </v-form>
    </div>

    <StandardDialog
      v-model="voiceRecording"
      dialog-height="calc(600px-1)"
      dialog-max-height="600px"
      dialog-min-height="600px"
      dialog-width="400px"
      style="z-index: 9999;"
    >
      <v-chip
        class="d-flex aling-center justify-center mt-5"
        style="width: 100%; height: 60px"
      >
        <v-icon large class="mr-3"> mdi-face-agent </v-icon>
        <div class="luluu">Luluu</div>
        <v-badge dot color="success"></v-badge>
      </v-chip>
      <div class="ma-5 d-flex align-center justify-center flex-column">
        <div v-if="!answerIsPresent">
          <div class="subtitle">Ciao, come posso aiutarti?</div>
          <div class="subtitle">Sono Luluu, il tuo assistente personale</div>
          <div class="subtitle">
            Cosa vuoi consultare? Muoviti nelle varie sezioni
          </div>
        </div>

        <div v-if="answerIsPresent">
          <LoadingMessage v-if="answerIsLoading" class="mt-6 mb-6"> </LoadingMessage>
          <div v-if="!answerIsLoading" class="subtitle">{{ answerIsPresent }}</div>
        </div>

        <div
          v-if="!isRecording"
          class="d-flex flex-column justify-center aling-center"
        >
<!--           <p class="d-flex justify-center aling-center">Cosa ti serve ?</p> -->

          <v-icon v-if="!answerIsLoading" class="mt-3 mb-3" color="primary" @click="micOn">
            mdi-microphone</v-icon
          >
        </div>

        <div v-if="!isRecording">
          <p class="d-flex flex-column justify-center aling-center">
            {{ upperCase(messageRecording) }}

            <a
              class="mb-3 d-flex flex-row justify-center aling-center"
              :href="currentYouTubeLink"
            >
              <!-- <v-icon> mdi-youtube </v-icon> Link YouTube: -->
              {{ currentYouTubeLink }}</a
            >
          </p>
        </div>

        <LoadingMessage v-if="isRecording" class="mt-6 mb-6"> </LoadingMessage>
        <p
          v-if="isRecording"
          class="d-flex flex-column justify-center aling-center"
        >
          {{ upperCase(messageRecording) }}
        </p>

        <div class="d-flex flex-row">
          <v-tooltip bottom v-for="action in pricipalRoutes" :key="action.idx">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-5"
                @click="goTo(action.routeName)"
                v-bind="attrs"
                v-on="on"
                >{{ action.icon }}</v-icon
              >
            </template>
            <span> {{ action.name }}</span>
          </v-tooltip>
        </div>
        <div class="d-flex flex-row action-default">
          <v-tooltip
            bottom
            v-for="action in privateAreaRoutes"
            :key="action.idx"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-5"
                @click="goTo(action.routeName)"
                v-bind="attrs"
                v-on="on"
                >{{ action.icon }}</v-icon
              >
            </template>
            <span> {{ action.name }}</span>
          </v-tooltip>
        </div>
      </div>
    </StandardDialog>
  </div>
</template>

<script>
import Vue from 'vue';
import LoadingMessage from "@/components/common/LoadingMessage.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import Clock from "@/components/common/Clock.vue";
import UserAvatar from "@/components/common/UserAvatar.vue";
import currentUser from "@/services/currentUser.service";
import chatService from "@/services/chat/chat.service";
import analyticService from "@/services/analytics/analytics.service.js";
import customersService from '@/services/customers/customers.service.js'

import _helloAudioPath1 from "@/assets/audio/hello/hello_0.mp3";
// import _helloAudioPath2 from "@/assets/audio/hello/hello_1.mp3";
// import _helloAudioPath3 from "@/assets/audio/hello/hello_2.mp3";
// import _helloAudioPath4 from "@/assets/audio/hello/hello_3.mp3";
import _start from "@/assets/audio/start.mp3";
import _close from "@/assets/audio/close.mp3";
import _linkAudioPath1 from "@/assets/audio/link/link_0.mp3";
import _linkAudioPath2 from "@/assets/audio/link/link_1.mp3";
import _linkAudioPath3 from "@/assets/audio/link/link_2.mp3";
import _linkAudioPath4 from "@/assets/audio/link/link_3.mp3";
import _errorAudioPath1 from "@/assets/audio/error/error_0.mp3";
import _errorAudioPath2 from "@/assets/audio/error/error_1.mp3";
import _errorAudioPath3 from "@/assets/audio/error/error_2.mp3";
import _errorAudioPath4 from "@/assets/audio/error/error_3.mp3";
import _newOperatorCalendar from "@/assets/audio/operators/new_operator_calendar.mp3";
import _newPaymentTransactionCollected from "@/assets/audio/payments/new_payment_transactions_collected.mp3";
import _debtList from "@/assets/audio/payments/debt_list.mp3";
import _openCashDesk from "@/assets/audio/payments/open_cash_desk.mp3";
import _newServices from "@/assets/audio/services/new_services.mp3";
import _newEvent from "@/assets/audio/calendar/new_event.mp3";
import _support from "@/assets/audio/support/support.mp3";
import _jova from "@/assets/audio/songs/jova.mp3";



const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition
const sr = !!Recognition ? new Recognition() : false

export default {
  name: "Chat",
  components: {
    UserAvatar,
    Clock,
    LoadingMessage,
    StandardDialog,
  },
  props: {
    iconDefault: {
      type: String,
      default: "mdi-account-circle",
    },
  },
  data: function () {
    return {
      bus: new Vue(),
      currentYouTubeLink: "",
      currentQuestion: "Cosa ti serve",
      voiceCommand: "",
      voiceRecording: false,
      startAudioPath: _start,
      closeAudioPath: _close,
      linksAudioPath: [
        _linkAudioPath1,
        _linkAudioPath2,
        _linkAudioPath3,
        _linkAudioPath4,
      ],
      hellosAudioPath: [
        _helloAudioPath1,
        // _helloAudioPath2,
        // _helloAudioPath3,
        // _helloAudioPath4,
      ],
      errorsAudioPath: [
        _errorAudioPath1,
        _errorAudioPath2,
        _errorAudioPath3,
        _errorAudioPath4,
      ],
      operatorsAudioPath: {
          'operator-calendar': _newOperatorCalendar
      },
      paymentsAudioPath: {
          'payment-transaction-collected': _newPaymentTransactionCollected,
          'debt-list': _debtList,
          'open-cash-desk': _openCashDesk
      },
      servicesAudioPath: {
          'new-services': _newServices
      },
      calendarAudioPath: {
          'new-event': _newEvent
      },
      supportAudioPath: {
          'support': _support
      },
      songsAudioPath: {
          'jova': _jova
      },
      isRecording: false,
      isTyping: true,
      currentUser: undefined,
      defaultAnswer: chatService._defaultAnswer(),
      indexFAQ: -1,
      defaultQuestion: chatService._defaultQuestion(),
      userMessageClass: "from-user margin-b_one margin-t_one",
      luluuMessageClass: "from-luluu margin-b_one margin-t_one",
      luluu: {
        firstname: "L",
        lastname: "U",
      },
      messageToSend: "",
      messageRecorded: "",
      messageRecording: "",
      statusBot: "online",
      messages: [],
      weekday: [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ],
      pricipalRoutes: [
        { idx: 0, icon: "mdi-home", name: "Home", routeName: "Homepage" },
        {
          idx: 1,
          icon: "mdi-calendar",
          name: "Agenda",
          routeName: "Calendar",
        },
        {
          idx: 2,
          icon: "mdi-glass-cocktail",
          name: "Ristorante",
          routeName: "Bar",
        },
        /*{
          idx: 4,
          icon: "mdi-apps",
          name: "Area Privata",
          routeName: "PrivateArea",
        },*/
      ],
      privateAreaRoutes: [
        {
          idx: 4,
          icon: "mdi-barcode",
          name: "Timbrature",
          routeName: "Registries",
        },
        {
          idx: 5,
          icon: "mdi-book",
          name: "Anagrafiche",
          routeName: "Registries",
        },
        {
          idx: 6,
          name: "Presenze",
          icon: "mdi-account-clock",
          routeName: "Presence",
        },
        {
          idx: 7,
          name: "Marketing",
          icon: "mdi-shopping",
          routeName: "Marketing",
        },
        {
          idx: 8,
          icon: "mdi-warehouse",
          name: "Magazzino",
          routeName: "Warehouse",
        },
        {
          idx: 9,
          icon: "mdi-cash-check",
          name: "Pagamenti",
          routeName: "Payments",
        },
        {
          idx: 10,
          icon: "mdi-chart-areaspline",
          name: "Analisi statistica",
          routeName: "Analytics",
        },

        {
          idx: 11,
          icon: "mdi-cog",
          name: "Impostazioni",
          routeName: "Settings",
        },
      ],
      answerIsPresent: undefined,
      answerIsLoading: false,
      numOfIncomprension: 0,
      commandFound: false,
      dateMonthMappings: {
        'gennaio': '01',
        'febbraio': '02',
        'marzo': '03',
        'aprile': '04',
        'maggio': '05',
        'giugno': '06',
        'luglio': '07',
        'agosto': '08',
        'settembre': '09',
        'ottobre': '10',
        'novembre': '11',
        'dicembre': '12',
      },
      newOrderMappings: {
        'fornitore': 'new/insertion-tab',
        'fornitore sotto scorta': 'new/under-stock-insertion-tab',
        'generico': 'new/items-not-present-in-warehouse-tab',
      },
      orderType: undefined
    };
  },
  mounted: async function () {
    let self = this;
    currentUser.getUser().then((user) => {
      self.currentUser = user;
    });

    currentUser.on("change", function (user) {
      self.currentUser = user;
    });
    this.startingChat();
    if(!!sr){
      this.initializeVoice();
      this.startRecording();
    }

    this.defaultQuestion = await chatService._defaultQuestion();
  },

  computed: {},
  methods: {
    upperCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    audioPlay(audioPath) {
      return new Promise((resolve, reject) => {
        if (!!audioPath) {
          var audioElement = new Audio(audioPath);
          audioElement.load();
          audioElement.play();
          audioElement.onended = () => {
            resolve();
          };
        }
      });
    },

    generatorAudio(text) {
      chatService
        .generator(text)
        .then((response) => {
          if (!!audioPath) this.audioPlay(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initializeVoice() {
      sr.continuos = true;
      sr.interimResults = true;

      sr.onstart = () => {};

      sr.onend = () => {
        setTimeout(() => {
          this.startRecording();
        }, 3000);
      };

      sr.onresult = (evt) => {
        if (evt.results[0].isFinal) {
          Array.from(evt.results)
            .map((result) => result[0])
            .map((result) => {
              console.log(result)
              // if (!result && !result.transcript) throw new Error("No result");
              this.checkCommand(result.transcript);
            });
          let t = Array.from(evt.results)
            .map((result) => result[0])
            .map((result) => result.transcript)
            .join("");
          this.messageRecording = t;
        }
      };
    },
    async checkCommand(result) {
      this.currentYouTubeLink = "";
      let command = result.toLowerCase();

      let _defaultOpenCommands = [
        "mi aiuti",
        "aiuto",
        "e lulu'",
        "e lui",
        "i lulu'",
        "lulu",
        " lulu'",
        "lulù",
        "lulu'",
        "ehi lulù",
        "ciao lulù",
        "ciao lulù",
        "hey lulù",
        "hey lulu'",
        "hey lulu",
        "ehi lulù",
        "ehi lulu'",
        "ehi lulu",
        "buongiorno lulù",
        "buongiorno lulu'",
        "buongiorno lulu",
        "haylou",
      ];

      let _defaultCloseCommands = [
        "chiudi lulu",
        "chiudi",
        "esci lulu",
        "esci",
        "esci da lulu",
        "esci da",
        "addio",
        "grazie",
        "grazie lulu",
        "buonanotte",
      ];

      let _defaultQuestion = [
        {
          commands: [
            "come faccio a fare uno storno in cassa",
            "storno in cassa",
            "effettuare uno storno in cassa",
            "come posso effettuare uno storno",
            "come faccio a effettuare uno storno",
            "come faccio a eseguire uno storno",
            "effettuare uno storno",
            "uno storno",
            "fare uno storno",
          ],
          // link: "https://bit.ly/3LojQ2O",
          // audio: this.linksAudioPath[Math.floor(Math.random() * 3 + 1)],
          routeName: "PaymentTransactionsCollectedNewForm",
          audio: this.paymentsAudioPath['payment-transaction-collected'],
        },
        {
          commands: [
            "come faccio ad inserire un nuovo operatore in agenda",
            "come faccio a inserire un nuovo operatore in agenda",
            "come faccio ad aggiungere un nuovo operatore in agenda",
            "come faccio ad aggiungere un operatore in agenda",
            "nuovo operatore in agenda",            
            "inserire un nuovo operatore in agenda",            
            "inserire nuovo operatore in agenda",
            "inserire operatore in agenda",
          ],
          routeName: "OperatorsRegistryList",
          audio: this.operatorsAudioPath['operator-calendar'],
        },
        {
          commands: [
            "come faccio inserire un nuovo servizio",
            "inserire un nuovo servizio",
            "nuovo servizio",
          ],
          routeName: "ServicesRegistryNewForm",
          audio: this.servicesAudioPath['new-services'],

        },
        {
          commands: [
            "come faccio a prendere un appuntamento in agenda",
            "come faccio a prendere un appuntamento",
            "aggiungere un appuntamento",
            "inserire un appuntamento",
            "inserire un nuovo appuntamento",
            "nuovo appuntamento in agenda",
            "nuovo appuntamento",
          ],
          routeName: "Calendar",
          audio: this.calendarAudioPath['new-event'],
        },
        {
          commands: [
            "come faccio a riscuotere un debito lasciato da un cliente",
            "riscuotere un debito lasciato da un cliente",
            "riscuotere un debito di un cliente",
            "saldare un debito",
            "salda un debito",
            "riscuotere un debito",
            "pagare un debito",
            "paga un debito",
            "saldare un sospeso",
            "salda un sospeso",
            "riscuotere un sospeso",
            "pagare un sospeso",
          ],
          routeName: "AddCustomerDialog",
          audio: this.paymentsAudioPath['debt-list'], 
        },
        {
          commands: [
            "come faccio ad aprire la cassa",
            "come faccio a aprire la cassa",
            "aprire la cassa",
            "apertura cassa",
          ],
          routeName: "Homepage",
          audio: this.paymentsAudioPath['open-cash-desk'], 
        },        
        {
          commands: [
            "non funziona la stampante cosa devo fare",
            "la stampante non funziona cosa devo fare",
            "la stampante non funziona",
            "la stampante non va",
            "errore stampante",
            "errore nella stampante",
            "ho un problema con la stampante cosa devo fare",
            "non va la stampante",
          ],
          link: "https://bit.ly/3tpNZaL", //fermare l'ascolto
          audio: this.supportAudioPath['support'], 
        },                
        {
          commands: [
            "metti su un pò di musica",
            "metti su un po di musica",
            "metti su una canzone",
            "cantami una canzone",
            "balliamo",
            "divertiamoci",
          ],
          audio: this.songsAudioPath['jova'], 
        },
      ];

      let _defaultLogoutCommand = [
        "logout",
        "disconnettiti",
        "disconnettimi",
        "sloggati",
      ];

      let _defaultViewRedirect = [
        {
          commands: [
            "magazzino",
            "mostra magazzino",
            "mostra il magazzino",
            "visualizza magazzino",
            "visualizza il magazzino",
            "apri magazzino",
          ],
          routeName: "Warehouse",
        },
        {
          commands: [
            "anagrafiche",
            "mostra anagrafica",
            "mostra le anagrafiche",
            "visualizza anagrafiche",
            "visualizza le anagrafiche",
            "apri anagrafica",
          ],
          routeName: "Registries",
        },
        {
          commands: [
            "calendario",
            "agenda",
            "mostra agenda",
            "mostra la mia agenda",
            "mostra l'agenda",
            "apri agenda",
            "mostra calendario",
            "mostra il calendario",
            "mostra la mia agenda",
            "visualizza agenda",
            "visualizza calendario",
            "visualizza la agenda",
            "apri calendario",
          ],
          routeName: "Calendar",
        },
        {
          commands: [
            "apri bistrot",
            "apri ristorante",
            "apri bar",
            "ristorante",
          ],
          routeName: "Bar",
        },
        {
          commands: [
            "analitiche",
            "mostra analitiche",
            "mostra le analitiche",
            "mostra analisi",
            "analitica",
            "apri analitica",
            "apri analisi",
            "apri analitiche",
          ],
          routeName: "Analytics",
        },
        {
          commands: [
            "pagamenti",
            "mostra pagamenti",
            "mostra i pagamenti",
            "mostra fatture",
            "fatture ciclo attivo",
            "fatture ciclo passivo",
          ],
          routeName: "Payments",
        },
        {
          commands: ["apri marketing", "mostra marketing", "marketing"],
          routeName: "Marketing",
        },
        /*{
          commands: [
            "apri area privata",
            "area privata",
            "mostra area privata",
          ],
          routeName: "PrivateArea",
        },*/
        {
          commands: [
            "impostazioni",
            "mostra impostazioni",
            "apri impostazioni",
          ],
          routeName: "Settings",
        },
        {
          commands: ["home", "homepage", "pagina iniziale", "inizio"],
          routeName: "Homepage",
        },
        {
          commands: [
            "effettua logout",
            "disconnettiti",
            "effettua disconnessione",
            "disconnettimi",
            "sloggati",
          ],
          routeName: "Logout",
        },
      ];

      let _defaultActionsToPerform = [
        //Fai entrare cliente in cassa
        {
          name: "addToCashDesk",
          action: {
            commands: [
              "aggiungi in cassa",
              "porta in cassa",
              "aggiungi cliente in cassa",
              "aggiungi cliente"
            ],
            routeName: "Homepage",
            textAnswer: "Ok, dimmi il \"nome del cliente\"",
            audioAnswer: ""
          }
        },
        //Cerca l'appuntamento in agenda
        {
          name: "findCalendar",
          action: {
            commands: [
              "cerca appuntamento in agenda",
              "cerca appuntamenti in agenda",
              "cerca in agenda",
              "cerca appuntamenti",
              "cerca cliente in agenda",
              "cerca clienti in agenda",
              "cerca clienti",
              "trova appuntamenti",
              "cerca cliente"
            ],
            routeName: "Calendar",
            textAnswer: "Ok, dimmi il \"nome del cliente\" che desideri trovare",
            audioAnswer: ""
          }
        },
        //Cerca data (+ interazione)
        {
          name: 'goToDateCalendar',
          action: {
            commands: [
              "vai alla data",
              "cerca data in agenda",
              "vai al",
              "cerca data",
              "trova data"
            ],
            routeName: "Calendar",
            textAnswer: "Ok, dimmi giorno, mese e anno",
            audioAnswer: ""
          }
        },
        //Timbratura
        {
          name: "badge",
          action: {
            commands: [
              "timbratura",
              "devo timbrarmi",
              "timbrami",
              "timbra",
              "ingresso",
              "uscita"
            ],
            actionToPerform: () => {
              return this.$emit('show-barcode')
            }
          }
        },
        //Mostra turni del mese
        {
          name: "presences",
          action: {
            commands: [
              "stampare i cedolini",
              "stampa i cedolini",
              "cedolini",
              "stampa presenze",
              "stampami presenze",
              "stampa le presenze"
            ],
            textAnswer: "Di quale mese?",
            audioAnswer: ""
          }
        },
        //Analisi dati
        {
          name: "performAnalysis",
          action: {
            commands: [
              "analisi dati",
              "fammi l'analisi dati",
              "analizza i dati",
              "portami all'analisi dati",
              "analitiche",
              "analitica",
              "fammi l'analitica",
              "analizziamo",
              "riepilogo"
            ],
            textAnswer: "Quale analisi desideri fare?",
            audioAnswer: ""
          }
        },
        //Intervistare la scheda cliente sulle abitudini in store (+ interazione)
        {
          name: "customerSheet",
          action: {
            commands: [
              "trova scheda tecnica",
              "trova la scheda tecnica",
              "scheda tecnica",
              "vai a scheda tecnica",
              "scheda tecnica cliente",
              "scheda tecnica colore",
              "scheda colore"
            ],
            textAnswer: "Di quale cliente?",
            audioAnswer: ""
          }
        },
        //Magazzino
        {
          name: "whareouseMovement",
          action: {
            commands: [
              "nuovo ordine",
              "vorrei fare un nuovo ordine",
              "facciamo un nuovo ordine",
              "ordine",
              "ordine prodotti",
              "fai nuovo ordine"
            ],
            textAnswer: "Desideri fare un ordine da fornitore, ordine da fornitore sotto scorta o ordine generico?",
            audioAnswer: "",
/*             actionToPerform: () => {
              return this.goTo("OrderList")
            } */
          }
        },
        //Magazzino parte 2
        {
          name: "wharehouseMovementSupplier",
          action: {
            commands: [
              "fornitore",
              "fornitore sotto scorta",
              "generico"
            ],
            routeName: "OrderList",
            textAnswer: "Dimmi il nome del fornitore",
            audioAnswer: ""
          }
        }
      ]

      let t = command;
      if (this.voiceRecording) {
        
        _defaultViewRedirect.map((redirect) => {
          if (redirect.commands.includes(t)) {
            this.commandFound = true
            this.numOfIncomprension = 0
            this.messageRecorded = t;
            this.goTo(redirect.routeName);
          }
        });

        _defaultQuestion.map((question) => {
          if (question.commands.includes(t)) {
            this.commandFound = true
            this.numOfIncomprension = 0
            this.currentYouTubeLink = question.link;
            this.messageRecorded = t;
            if(!!question.routeName)
              this.goTo(question.routeName)

            this.audioPlay(question.audio).then((value) => {
              this.isRecording = false;
            }).catch((err) => {
              console.log(err)
            });
          }
        });

        for (let el of _defaultActionsToPerform) {
          if (el.action.commands.includes(t)) {
            this.commandFound = true
            this.numOfIncomprension = 0
            this.messageRecorded = t;
            this.answerIsLoading = true
            this.answerIsPresent = el.action.textAnswer

            if (el.name === 'wharehouseMovementSupplier') {
              this.orderType = t
            }
            
            setTimeout(() => {
              this.answerIsLoading = false
              if (el.action.actionToPerform) {
                el.action.actionToPerform.call(window)
              }
            }, 3500);


            break;
          } else {
            if (el.name === 'findCalendar' && 
              el.action.commands.includes(this.messageRecorded)) {
                this.commandFound = true
                this.numOfIncomprension = 0
                this.messageRecorded = t
                this.answerIsPresent = " "
                this.goTo(el.action.routeName, {
                  customerFromVoice: t
                })

                this.answerIsLoading = true
                setTimeout(() => {
                  this.answerIsLoading = false
                  this.answerIsPresent = undefined
                }, 3500);
              break;
            }

            if (el.name === 'performAnalysis' &&
              el.action.commands.includes(this.messageRecorded)) {
                this.commandFound = true
                this.numOfIncomprension = 0
                this.messageRecorded = t
                this.answerIsPresent = " "
                let listRoute = await analyticService.returnListRouteFromHumanName(t)
                await this.goTo(listRoute)

                this.answerIsLoading = true
                setTimeout(() => {
                  this.answerIsLoading = false
                  this.answerIsPresent = undefined
                }, 3500);
                break;
            }
            
            if (el.name === 'goToDateCalendar' &&
              el.action.commands.includes(this.messageRecorded)) {
                this.commandFound = true
                this.numOfIncomprension = 0
                this.messageRecorded = t
                this.answerIsPresent = " "

                let dateArray = t.split(' ')
                dateArray[1] = this.dateMonthMappings[dateArray[1]]
                if (dateArray[2] == undefined)
                  dateArray[2] = new Date().getFullYear()
                let dateString = dateArray[1] +  '-' + dateArray[0] + '-' + dateArray[2]
                let date = new Date(dateString)
                date.setHours(2, 0, 0, 0)
                dateString = date.toString()
                await this.goTo(el.action.routeName, {
                  calendarDateFromVoice: dateString
                })
                
                this.answerIsLoading = true
                setTimeout(() => {
                  this.answerIsLoading = false
                  this.answerIsPresent = undefined
                }, 3500);
                break;
            }

            if (el.name === 'addToCashDesk' &&
              el.action.commands.includes(this.messageRecorded)) {
                this.commandFound = true
                this.numOfIncomprension = 0
                this.messageRecorded = t
                this.answerIsPresent = " "

                await this.goTo("Homepage", {
                  addCustomerDialog: true,
                  customerFromVoice: t
                })

                this.answerIsLoading = true
                setTimeout(() => {
                  this.answerIsLoading = false
                  this.answerIsPresent = undefined
                }, 3500);
                break;
            }

            if (el.name === 'customerSheet' &&
              el.action.commands.includes(this.messageRecorded)) {
                this.commandFound = true
                this.numOfIncomprension = 0
                this.messageRecorded = t
                this.answerIsPresent = " "

                let customers = await customersService.search(t)
                await this.goTo("CustomersRegistryEditForm", {
                  id: customers[0].id
                }, {
                  tabName: 'barberDataSheet',
                  barberDataSheetTabName: 'barberColoring'
                })

                this.answerIsLoading = true
                setTimeout(() => {
                  this.answerIsLoading = false
                  this.answerIsPresent = undefined
                }, 3500);
                break;
            }

            if (el.name === 'wharehouseMovementSupplier' &&
              el.action.commands.includes(this.messageRecorded)) {
                this.commandFound = true
                this.numOfIncomprension = 0
                this.messageRecorded = t
                this.answerIsPresent = " "

                await this.goTo("OrdersHome", {
                  supplierFromVoice: t
                }, {
                  tab: this.newOrderMappings[this.orderType],
                })

                this.answerIsLoading = true
                setTimeout(() => {
                  this.answerIsLoading = false
                  this.answerIsPresent = undefined
                }, 3500);
                break;
            }

            if (el.name === 'presences' &&
              el.action.commands.includes(this.messageRecorded)) {
                this.commandFound = true
                this.numOfIncomprension = 0
                this.messageRecorded = t
                this.answerIsPresent = " " 
                
                await this.goTo('OperatorsTimeLogs', {
                  bus: this.bus
                })
                await this.bus.$emit('print-time-logs', '2023-' + this.dateMonthMappings[t])

                this.answerIsLoading = true
                setTimeout(() => {
                  this.answerIsLoading = false
                  this.answerIsPresent = undefined
                }, 3500);
                break;
            }

            this.commandFound = false
          }
        }
        
        if (_defaultCloseCommands.includes(t)) {
          this.closeChat();
        } 
        
        else if (_defaultLogoutCommand.includes(t)) {
          this.voiceRecording = false;
          this.$emit("logout");
        } 
        else if (this.commandFound == false) {
          this.numOfIncomprension++
          if (this.numOfIncomprension >= 2) {
            this.answerIsPresent = "Spiacente ma non posso aiutarti"
            this.answerIsLoading = true
            this.numOfIncomprension = 0
            setTimeout(() => {
              this.answerIsLoading = false
            }, 3500);
            setTimeout(() => {
              this.closeChat()
            }, 6000)
          } else {
            this.answerIsPresent = "Spiacente non ho compreso il comando"
            this.answerIsLoading = true
            setTimeout(() => {
              this.answerIsLoading = false
            }, 3500);
          }
        }

      }

      if (_defaultOpenCommands.includes(t)) {
        console.log(t);
        this.commandFound = true
        this.openRecoder();
      } else  {
        console.log(t)
      }
    },
    micOn() {
      this.isRecording = true;
      this.messageToSend = "";
    },
    async goTo(routeName, params, query) {
      await this.$router.push({ name: routeName, params: params, query: query }).catch(() => {});
    },
    startingChat() {
      setTimeout(() => {
        this.isTyping = false;
        this.generateMessage(
          0,
          "Ehi! Io sono Luluu.👋 Come posso aiutarti?",
          this.luluuMessageClass,
          this.luluu,
          this.currentUser,
          true,
          null
        );
        this.alignBottom();
      }, 2500);

      this.showFAQ();
    },
    askDefault(
      entity,
      action,
      question,
      answer,
      links,
      query = false,
      audio = null
    ) {
      this.generateMessage(
        this.messages.length + 1,
        question,
        this.userMessageClass,
        this.currentUser,
        audio
      );

      if (query) {
        chatService
          .ask(entity, action, question.split(" "))
          .then((customers) => {
            let answer;
            answer = "I Clienti in archivio sono " + customers.length + ": ";
            let i = 0;
            customers.map((customer) => {
              i++;
              answer += " " + customer.firstname + " " + customer.lastname;
              answer += customers.length === i ? "." : ",";
            });

            this.generateMessage(
              this.messages.length + 1,
              answer,
              this.luluuMessageClass,
              this.luluu,
              links,
              true
            );
          });
      } else {
        this.generateMessage(
          this.messages.length + 1,
          answer,
          this.luluuMessageClass,
          this.luluu,
          links,
          true,
          audio
        );
      }
    },
    async openRecoder() {
      this.messageToSend = "";
      this.isRecording = true;
      this.currentYouTubeLink = "";
      this.voiceRecording = true;

      this.goTo("Dashboard")
      
      setTimeout(() => {
        this.answerIsPresent = undefined
        this.answerIsLoading = false

        this.audioPlay(this.startAudioPath).then(() => {
          this.isRecording = false;
        });
        this.isRecording = false;
      }, 3500);

    },
    openChat() {
      this.$emit("open", "none");
      document.getElementById("chat").style.display = "block";
      document.getElementById("chat-avatar").style.display = "none";
    },

    async closeChat() {
      this.voiceRecording = false;
      await this.audioPlay(this.closeAudioPath);
      this.$emit("close", "all");
      document.getElementById("chat").style.display = "none";
      document.getElementById("chat-avatar").style.display = "block";
    },
    pressEnter(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.sendMessage();
      }
    },
    getZeroPadded(number) {
      return number < 10 ? "0" + number.toString() : number;
    },
    startRecording() {
      sr.start();
    },
    stopRecording() {
      sr.stop();
    },
    showFAQ() {
      setTimeout(() => {
        this.isTyping = true;
        if (this.messages.length == 1)
          this.indexFAQ = 1
        else
          this.indexFAQ = this.messages.length

        this.alignBottom();
      }, 500);
    },
    sendMessage() {
      this.generateMessage(
        this.messages.length,
        this.messageToSend,
        this.userMessageClass,
        this.currentUser,
        [],
        false
      );

      this.generateMessage(
        this.messages.length,
        this.defaultAnswer[Math.floor(Math.random() * 3 + 1)],
        this.luluuMessageClass,
        this.luluu,
        [],
        true
      );
    },

    generateMessage(
      index,
      messageText,
      messageClass,
      avatar,
      links,
      fromLuluu = false,
      audio = null
    ) {
      if (fromLuluu) {
        this.isTyping = true;
        this.statusBot = "sta scrivendo...";
        setTimeout(() => {
          this.populateMessageList(
            index,
            messageText,
            messageClass,
            avatar,
            links,
            fromLuluu
          );
          this.statusBot = "online";
          this.isTyping = false;
          if (audio) this.audioPlay(audio);
        }, 1600);
      } else
        this.populateMessageList(
          index,
          messageText,
          messageClass,
          avatar,
          links,
          fromLuluu
        );

      this.messageToSend = "";
      this.alignBottom();
    },

    populateMessageList(
      index,
      messageText,
      messageClass,
      avatar,
      links,
      fromLuluu
    ) {
      let d = new Date();


      this.messages.push({
        idx: index,
        text: messageText,
        hour:
          this.getZeroPadded(d.getHours()) +
          ":" +
          this.getZeroPadded(d.getMinutes()),
        class: messageClass,
        avatar: avatar,
        links: links,
        fromLuluu: fromLuluu,
      });


      this.alignBottom();
    },

    alignBottom() {
      var chatMessages = document.getElementById("chat-messages");
      chatMessages.scrollTop = chatMessages.scrollHeight;
    },
  },
  computed: {
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.defaults.light.primary,
        "--secondary-color": this.$vuetify.theme.defaults.light.secondary,
      };
    },
    currentWeekday() {
      let d = new Date();
      return this.weekday[d.getDay()];
    },
    currentDay() {
      let d = new Date();
      return this.getZeroPadded(d.getDay());
    },
    currentMonth() {
      let d = new Date();
      return this.getZeroPadded(d.getMonth());
    },
    currentYear() {
      let d = new Date();
      return d.getFullYear();
    },
  },

  watch: {},
};
</script>

<style>
.header {
  display: flex;
  flex-flow: row wrap;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  margin-left: 2px;
  font-size: 1.2em;
  letter-spacing: -1px;
  font-weight: 300;
  color: black;
}

.textspeeched {
  margin-left: 2px;
  font-size: 1em;
  letter-spacing: -1px;
  font-weight: 300;
  color: black;
  min-width: 250px;
  max-width: 250px;
  min-height: 100px;
  max-height: 100px;
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.luluu {
  margin-left: 2px;
  font-size: 2em;
  letter-spacing: -3px;
  font-weight: 700;
  color: black;
}
.small-text {
  font-size: 0.76em;
  color: black;
}
.small-text-white {
  font-size: 0.76em;
  font-weight: 7;
  color: rgb(255, 255, 255);
}

.luluu-text-white {
  font-size: 0.97em;
  color: rgb(255, 255, 255);
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
* {
  box-sizing: border-box;
}

/* Button used to open the chat form - fixed at the bottom of the page */
/* The popup chat - hidden by default */
.clock {
  position: fixed;
  bottom: 1;
  /* margin-top: 10px; */
  right: 140px;
}

.chat-button {
  margin-bottom: 15px;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  border-radius: 10px;
  z-index: 9;
}

.chat-recorder {
  transition: transform 0.2s ease-out;
  margin-left: auto;
  margin-right: auto;
  border: 3px solid #f1f1f1;
  border-radius: 10px;
}

.chat-recorder-container {
  border-radius: 10px;
  max-width: 350px;
  padding: 10px;
  background-color: #ffffff;
}

.chat-popup {
  margin-bottom: 15px;
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  border-radius: 10px;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  border-radius: 10px;
  max-width: 350px;
  padding: 10px;
  background-color: #ffffff;
}

/* Full-width textarea */
.form-container.textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;

  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container.textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #dcd8d8;
  transition: all 0.2s;
  transform: scale(1);
}

.imessage {
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.5rem 1.5rem;
  margin-top: 15px;
}

.imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.from-user {
  align-self: flex-end;
  background-color: #525255;
  color: #fff;
}

p.from-user::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #525255;

  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-user::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.from-user ~ p.from-user {
  margin: 0.25rem 0 0;
}

p.from-user ~ p.from-user:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.from-user ~ p.from-user:last-child {
  margin-bottom: 0.5rem;
}

p.from-luluu {
  align-items: flex-start;
  background-color: var(--primary-color);
  color: #fff;
}

p.from-luluu:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid var(--primary-color);
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-luluu::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"].emoji {
  background: none;
  font-size: 2rem;
}

p[class^="from-"].emoji::before {
  content: none;
}

.no-tail::before {
  display: none;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 0.3rem !important;
}

.margin-t_one {
  margin-top: 0.3em !important;
}

.chat-fade-in {
  animation: smooth 0.8s ease-in;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 0.5rem;
  }

  .imessage {
    font-size: 1.05rem;
    margin: 0 auto 1rem;
    max-width: 600px;
    padding: 0.25rem 0.875rem;
  }

  .imessage p {
    margin: 0.5rem 0;
  }
}
</style>