<template>
  <v-list style="width: 100%">
    <v-list-group
      v-for="category in localItems"
      :key="category[categoriesIdentifierKey]"
      no-action
      v-show="filterCtrl(category[categoriesIdentifierKey])"
    >
      <template v-slot:activator>
        <span v-if="allowStartLabel">{{category[categoriesStartLabelTextKey]}}</span>
        <v-list-item-action v-if="allowAllSubcat" >
          <v-checkbox :disabled="disabled" :input-value="category._active" @click.stop.prevent="selectAllCategory(category)"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="category[categoriesTextKey]"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-chip 
            small 
            v-if="categoryHasSelected(category) && badges"
            :color="badgesColor"
          >{{numberOfSelected(category)}}</v-chip>
        </v-list-item-icon>
      </template>

      <v-list-group
        v-if="multiStage"
        v-for="subCategory in category[subCategoriesKey]"
        :key="subCategory[subCategoriesIdentifierKey]"
        no-action
        sub-group
      >
        <template v-slot:activator>
          <v-list-item-action v-if="allowAllSubcat">
            <v-checkbox :disabled="disabled" :input-value="subCategory._active" @click.stop.prevent="selectAllSubCategory(subCategory)"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="subCategory[subCategoriesTextKey]"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item-group
          :multiple="multiple"
          :disabled="disabled"
          v-model="subCategory._selected" 
          @change="handleSelection(subCategory, $event)" 
        >

          <v-list-item  v-for="item in subCategory[itemsKey]" :key="item[itemsIdentifierKey]" :disabled="disabled">
            <template v-slot:default="{ active }">
              <v-list-item-action v-if="allowSelect">
                <v-checkbox :disabled="disabled" :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{item[itemsTextKey]}}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon class="ma-0" @click.stop.prevent="">
                <slot name="extra-actions" v-bind="{ item, active, emitInput }"></slot>
              </v-list-item-icon>
            </template>
          </v-list-item>
        </v-list-item-group>
    
      </v-list-group>

      <v-list-item-group 
        v-if="!multiStage"
        :multiple="multiple" 
        v-model="category._selected" 
        @change="handleSelection(category, $event)" 
        :disabled="disabled"
      >

        <v-list-item class="pl-12" v-for="item in category[itemsKey]" :key="item[itemsIdentifierKey]" :disabled="disabled">
          <template v-slot:default="{ active }">
            <span v-if="allowStartLabel">{{item[itemsStartLabelTextKey]}}</span>
            <v-list-item-action v-if="allowSelect">
              <v-checkbox :disabled="disabled" :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{item[itemsTextKey]}}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon class="ma-0" @click.stop.prevent="">
              <slot name="extra-actions" v-bind="{ item, active, emitInput }"></slot>
            </v-list-item-icon>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list-group>
  </v-list>
</template>

<script>
import _ from 'lodash'

export default {
  name: "GroupedSelectList",
  data: function() {
    return {
      localItems: _.cloneDeep(this.items),
    }
  },
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    items: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectedItems: {
      type: Array,
      default: function() {
        return []
      }
    },
    badges: {
      type: Boolean,
      default: true
    },
    multiStage: {
      type: Boolean,
      default: false
    },
    badgesColor: {
      type: String,
      default: 'primary'
    },
    categoriesIdentifierKey: {
      type: String,
      default: 'id'
    },
    categoriesTextKey: {
      type: String,
      default: 'code'
    },
    subCategoriesIdentifierKey: {
      type: String,
      default: 'id'
    },
    subCategoriesTextKey: {
      type: String,
      default: 'code'
    },
    subCategoriesKey: {
      type: String,
      default: 'code'
    },
    itemsIdentifierKey: {
      type: String,
      default: 'id'
    },
    itemsKey: {
      type: String,
      default: 'items'
    },
    itemsTextKey: {
      type: String,
      default: 'code'
    },
    filters: {
      type: Array,
      default: function() {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    allowAllSubcat: {
      type: Boolean,
      default: false
    },
    allowSelect: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemsStartLabelTextKey: {
      type: String,
      default: 'startLabel'
    },
    categoriesStartLabelTextKey: {
      type: String,
      default: 'startLabel'
    },
    allowStartLabel: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.calculateSelected()
  },
  methods: {
    selectAllCategory(category) {
      if(!!this.multiStage){
        if (!!category._active) {
          for (let i = 0; i < category[this.subCategoriesKey].length; i++) {
            category[this.subCategoriesKey][i]._active = true
            this.selectAllSubCategory(category[this.subCategoriesKey][i])
          }
          category._active = false
        } else {
          for (let i = 0; i < category[this.subCategoriesKey].length; i++) {
            category[this.subCategoriesKey][i]._active = false
            this.selectAllSubCategory(category[this.subCategoriesKey][i])
          }
          category._active = true
        }
      } else {
        if (!!category._active) {
          category._selected = []
          category._active = false
        } else {
          category._selected = category[this.itemsKey].map((el, index) => {return index})
          category._active = true
        }
        this.handleSelection(category, category._selected)
      }
    },
    selectAllSubCategory(subCategory) {
      if (!!subCategory._active) {
        subCategory._selected = []
        subCategory._active = false
      }
      else {
        subCategory._selected = subCategory[this.itemsKey].map((el, index) => {return index})
        subCategory._active = true
      }

      let selectedIndexes = []
      let selectedValues = []
      for (let i = 0; i < this.localItems.length; i++) {
        for (let j = 0; j < this.localItems[i][this.subCategoriesKey].length; j++) {
          let subCat = this.localItems[i][this.subCategoriesKey][j]
          for (let k = 0; k < subCat._selected.length; k++) {
            selectedIndexes.push(subCat._selected[k])
            selectedValues.push(subCat[this.itemsKey][subCat._selected[k]])
          }
        }
      }

      this.$emit('input', _.cloneDeep(_.uniqBy(selectedValues, this.itemsIdentifierKey)))
      this.$emit('change', subCategory, selectedIndexes)
    },
    findCategoryById(id) {
      for(let i = 0; i < this.localItems.length; i++) {
        if(this.localItems[i][this.categoriesIdentifierKey] == id) {
          return this.localItems[i]
        }
      }
    },

    calculateSelected() {
      if (!this.multiStage) {
        let itemIds = this.value.map((el) => { return el[this.itemsIdentifierKey]})
        for(let i = 0; i < this.localItems.length; i++) {
          this.$set(this.localItems[i], '_selected', [])
          this.$set(this.localItems[i], '_active', false)
          for(let k = 0; k < this.localItems[i][this.itemsKey].length; k++) {
            let itemId = this.localItems[i][this.itemsKey][k][this.itemsIdentifierKey]
            if(itemIds.includes(itemId)) {
              this.localItems[i]._selected.push(k)
            }
          }
          if (this.localItems[i][this.itemsKey].length == this.localItems[i]._selected.length)
            this.localItems[i]._active = true
        }
      } else {
        let itemIds = this.value.map((el) => { return el[this.itemsIdentifierKey]})
        for (let i = 0; i < this.localItems.length; i++) {
          this.$set(this.localItems[i], '_active', false)
          for (let j = 0; j < this.localItems[i][this.subCategoriesKey].length; j++) {
            this.$set(this.localItems[i][this.subCategoriesKey][j], '_selected', [])
            this.$set(this.localItems[i][this.subCategoriesKey][j], '_active', false)
            for (let k = 0; k < this.localItems[i][this.subCategoriesKey][j][this.itemsKey].length; k++) {
              let itemId = this.localItems[i][this.subCategoriesKey][j][this.itemsKey][k][this.itemsIdentifierKey]
              if (itemIds.includes(itemId)) {
                this.localItems[i][this.subCategoriesKey][j]._selected.push(k)
              }
            }
            if (this.localItems[i][this.subCategoriesKey][j][this.itemsKey].length == this.localItems[i][this.subCategoriesKey][j]._selected.length)
              this.localItems[i][this.subCategoriesKey][j]._active = true
          }
          if (this.localItems[i][this.subCategoriesKey].every(el => !!el._active))
            this.localItems[i]._active = true
        }
      }
    },
    emitInput() {
      this.$emit('input', _.cloneDeep(_.uniqBy(this.value, this.itemsIdentifierKey)))
    },
    handleSelection(cat, selectedIndexes) {
      if(this.disabled) return

      const category = this.findCategoryById(cat[this.categoriesIdentifierKey])
      let alreadySelected = [ ...this.value ]

      if (!this.multiStage) {

        for(let i = 0; i < category[this.itemsKey].length; i++) {
          if(typeof selectedIndexes === 'object' && selectedIndexes.includes(i) ||
            typeof selectedIndexes === 'number' && selectedIndexes === i) {
            alreadySelected.push({
              ...category[this.itemsKey][i],
              'categoryObject': category
              })
          } else {
            alreadySelected = alreadySelected.filter((el) => { return el[this.itemsIdentifierKey] != category[this.itemsKey][i][this.itemsIdentifierKey]})
          }
        }

      } else {

        for (let i = 0; i < cat[this.itemsKey].length; i++) {
          if(typeof selectedIndexes === 'object' && selectedIndexes.includes(i) ||
            typeof selectedIndexes === 'number' && selectedIndexes === i) {
            alreadySelected.push({
              ...cat[this.itemsKey][i],
              'subCategoryObject': cat,
              'categoryObject': category
              })
          } else {
            alreadySelected = alreadySelected.filter((el) => { return el[this.itemsIdentifierKey] != cat[this.itemsKey][i][this.itemsIdentifierKey]})
          }
        }
      }

      this.$emit('input', _.cloneDeep(_.uniqBy(alreadySelected, this.itemsIdentifierKey)))
      this.$emit('change', cat, selectedIndexes)
      this.$nextTick(() => {
        this.$emit('update:selected-items', this.localSelectedItems)
      })
    },

    numberOfSelected(cat) {
      if (!this.multiStage) {
        if(cat._selected) {
          return cat._selected.length
        } else {
          return 0
        }
      }
      else {
        if (cat[this.subCategoriesKey].some(el => el._selected)) {
          return cat[this.subCategoriesKey].filter(el => el._selected).map(el => el._selected.length).reduce((prev, next) => prev + next)
        } else {
          return 0
        }
      }

    },
    categoryHasSelected(cat) {
      return !cat._selected || cat._selected.length > 0
    },
    filterCtrl(key){
      if(this.filters.length > 0){
        return this.filters.includes(key)
      }
      else  
        return true
    }
  },
  computed: {
    localSelectedItems() {
      let results = [];
      if (!this.multiStage) {
        let itemIds = this.value.map((el) => { return el[this.itemsIdentifierKey]})

        for(let i = 0; i < this.localItems.length; i++) {
          let alreadyAddCategory = false;
          for(let k = 0; k < this.localItems[i][this.itemsKey].length; k++) {
            let itemId = this.localItems[i][this.itemsKey][k][this.itemsIdentifierKey]
            if(itemIds.includes(itemId)) {
              if (alreadyAddCategory) 
                results[results.length - 1][this.itemsKey].push(this.localItems[i][this.itemsKey][k]);
              else {
                let category = {...this.localItems[i]}
                category[this.itemsKey] = [this.localItems[i][this.itemsKey][k]]
                delete category._selected
                results.push(category)
                alreadyAddCategory = true;
              }
            }
          }
        }
      } else {
        //KEEP COMMENT
/*         let itemIds = this.value.map((el) => { return el[this.itemsIdentifierKey] })
        for (let i = 0; this.localItems.length; i++) {
          let alreadyAddCategory = false
          for (let j = 0; j < this.localItems[i][this.subCategoriesKey].length; j++) {
            let alreadyAddSubCategory = false
            for (let k = 0; k < this.localItems[i][this.subCategoriesKey][j][this.itemsKey].length; k++) {
              let itemId = this.localItems[i][this.subCategoriesKey][j][this.itemsKey][k][this.itemsIdentifierKey]
              if (itemIds.includes(itemId)) {
                if (alreadyAddCategory) {

                  if (alreadyAddSubCategory) {
                    results[i][this.subCategoriesKey][results[i][this.subCategoriesKey].length - 1][this.itemsKey].push(this.localItems[i][this.subCategoriesKey][j][this.itemsKey][k])
                  }
                  else {
                    let subCategory = {...this.localItems[i][this.subCategoriesKey][j]}
                    subCategory[this.itemsKey] = [this.localItems[i][this.subCategoriesKey][j][this.itemsKey][k]]
                    delete subCategory._selected
                    results[i][this.subCategoriesKey].push(subCategory)
                    alreadyAddSubCategory = true
                  }

                }
                else {
                  let category = {...this.localItems[i]}
                  category[this.subCategoriesKey] = [this.localItems[i][this.subCategoriesKey][j]]
                  results.push(category)
                  alreadyAddCategory = true
                }
              }
            }

          }
        } */
      }
      return _.cloneDeep(results);
    }
  },
  watch: {
    value() {
      this.calculateSelected()
      this.$nextTick(() => {
        this.$emit('update:selected-items', _.cloneDeep(this.localSelectedItems))
      })
    },
    items(newVal) {
      this.localItems = newVal
      this.calculateSelected()
      this.$nextTick(() => {
        this.$emit('update:selected-items', _.cloneDeep(this.localSelectedItems))
      })
    }
  }
}
</script>

<style>

</style>