import NameMapper from '@/services/common/nameMapper.js'

class suppliesNameMapping extends NameMapper {

  _optionsGetter() {
    return Promise.resolve([
      { value: 'hairProducts', text: 'Prodotti per capelli' },
      { value: 'nailsProducts', text: 'Prodotti per unghie' },
      { value: 'shampoos', text: 'Shampoo' },
      { value: 'gels', text: 'Gel' },
      { value: 'perfumes', text: 'Profumi' },
    ])
  }
}

export default new suppliesNameMapping()