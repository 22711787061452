import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class paymentTypeNatureExemptionsManagment {

  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentTypeNatureExemptions/list', {
        page: page,
        rowPerPage: rowPerPage
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  create(paymentCondition) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentTypeNatureExemptions/create', paymentCondition).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }


  update(paymentCondition) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentTypeNatureExemptions/update', paymentCondition).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  delete(paymentCondition) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!paymentCondition.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/payments/paymentTypeNatureExemptions/delete', {
        id: paymentCondition.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(paymentConditionId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/payments/paymentTypeNatureExemptions/get', { id: paymentConditionId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new paymentTypeNatureExemptionsManagment();
