<template>
  <div id="chart">
    <TitleWithBackButton
      title="Fidelizzazione Clienti"
      back-route="Analytics"
    ></TitleWithBackButton>
    <div class="d-flex align-center ml-3 mt-3 flex-wrap">
      <RangeDateSelector
        ref="dateSelector"
        :start-date.sync="startDate"
        :end-date.sync="endDate"
        :loading="loading"
        :disabled="loading"
        :chip-items="[
          {text: 'Ultimi 365 giorni', value: 'last365Days'},
          {text: 'Quest\'anno', value: 'thisYear'},
          {text: 'Scorso anno', value: 'lastYear'},
        ]"
        :max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : this.$vuetify.breakpoint.md ? '70vw' : '50vw'"
      ></RangeDateSelector>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="calculate()"
        :loading="loading"
        :disabled="!startDate || !endDate"
      >Calcola</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="btn-export"
        :loading="loading"
        @click="exportToExcel"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="print"
        :loading="loading"
        :disabled="!startDate || !endDate"
      ><v-icon> mdi-printer </v-icon></v-btn>
      <v-btn 
        icon 
        large 
        :disabled="loading" 
        style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!ghost ? 'red' : ''"
        >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>
    <div>
      <v-row class="px-2 py-5">
        <v-col cols="12">
          <v-row class="py-5">
            <span class="card-title ml-10">
              Clienti Attivi
            </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="6" 
              lg="6"
              xl="6"
              class="d-flex justify-center align-center"
              :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                ? '2' : '1' "
            >
              <div
                v-if="loading"
                class="text-caption font-weight-light d-flex align-center justify-center"
                :style="{
                  height: '100%'
                }"
              >
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
              <TypeDataTable
                v-else
                class="pb-5"
                fixed-headers
                headersColor="transparent" 
                style="background-color: transparent;width: 70%;min-width: 400px;"
                :headers="[
                  { text: '', value: 'color', type: 'color' },
                  { text: 'Categoria', value: 'name' },
                  { text: 'N° Clienti', value: 'value', type: 'number' },
                ]" 
                :items="activeCustomerTableData" 
                :show-select="false"
                :select-on-row-click="false" 
                :show-actions="true" 
                :disablePagination="true"
                @rowClicked="openActiveCustomers"
                :loading="loading"
              >
                <template v-slot:actions="{ item }">
                  <v-row @mouseover="preFetch(item, 1)" style="min-width: 80px" class="d-flex justify-end">
                    <v-btn icon @click.stop="() => { customersSendMessage = true }">
                      <v-icon dense color="blue darken-2">mdi-message-text-fast</v-icon>
                    </v-btn>
                    <ExportExcelTable
                      :worksheetName="worksheetName"
                      :fileName="generateExcelName(item, 1)"
                      :fieldsToExport="fieldsToExport"
                      :fetch="fetchExcel"
                    >
                      <template v-slot:button>
                        <v-btn icon>
                          <v-icon dense color="green darken-2">mdi-microsoft-excel</v-icon>
                        </v-btn>
                      </template>
                    </ExportExcelTable>
                  </v-row>
                </template>
              </TypeDataTable>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6" 
              lg="6"
              xl="6"
              class="d-flex justify-center align-center"
              :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                ? '1' : '2'"
            >
              <v-card
                width="400"
                height="400"
                color="transparent"
                flat
              >
                <div
                  v-if="loading"
                  class="text-caption font-weight-light d-flex align-center justify-center"
                  :style="{
                    height: '100%'
                  }"
                >
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
                <DoughnutChart
                  v-else
                  :chart-data="activeCustomerChartData"
                  :styles="{
                    height: '400px',
                    width: '400px'
                  }"
                  :options="chartOptions"
                ></DoughnutChart>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="py-5" v-if="selectedActiveCustomer">
            <span class="card-title ml-10">
              Dettaglio Clienti Attivi
            </span>
          </v-row>
          <v-row class="py-5" v-if="selectedNewCustomer">
            <span class="card-title ml-10">
              Dettaglio Nuovi Clienti
            </span>
          </v-row>
          <v-row class="py-5" v-if="selectedInactiveCustomer">
            <span class="card-title ml-10">
              Dettaglio Clienti Inattivi
            </span>
          </v-row>
          <v-row class="d-flex justify-center" v-if="selectedActiveCustomer || selectedNewCustomer
            || selectedInactiveCustomer">
            <v-col
              cols="12"
              sm="12"
              md="6" 
              lg="6"
              xl="6"
              :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                ? '2' : '1' "
              class="d-flex justify-center align-center"
            >
              <TypeDataTable
                v-if="selectedActiveCustomer || selectedNewCustomer || selectedInactiveCustomer"
                class="pb-5"
                fixed-headers
                headersColor="transparent" 
                style="background-color: transparent; min-width: 400px;"
                :headers="[
                  { text: '', value: 'color', type: 'color' },
                  { text: 'Categoria', value: 'name' },
                  { text: 'N° Clienti', value: 'value', type: 'number' },
                ]" 
                :items="selectedActiveCustomer ? activeCustomerDetailTableData : selectedNewCustomer ? 
                  newCustomerDetailTableData : inactiveCustomerDetailTableData" 
                :show-select="false"
                :select-on-row-click="false" 
                :show-actions="true" 
                :disablePagination="true"
                :loading="loadingSecondLevel"
                @rowClicked="openActiveCustomersDetail"
              >
                <template v-slot:actions="{ item }">
                  <v-row @mouseover="preFetch(item, 2)">
                    <v-btn icon @click.stop="() => { customersSendMessage = true }">
                      <v-icon dense color="blue darken-2">mdi-message-text-fast</v-icon>
                    </v-btn>
                    <ExportExcelTable
                      :worksheetName="worksheetName"
                      :fileName="generateExcelName(item, 2)"
                      :fieldsToExport="fieldsToExport"
                      :fetch="fetchExcel"
                    >
                      <template v-slot:button>
                        <v-btn icon>
                          <v-icon dense color="green darken-2">mdi-microsoft-excel</v-icon>
                        </v-btn>
                      </template>
                    </ExportExcelTable>
                  </v-row>
                </template>
              </TypeDataTable>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6" 
              lg="6"
              xl="6"
              :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                ? '1' : '2' "
              class="d-flex justify-center align-center"
            >
              <v-card
                width="420"
                height="400"
                color="transparent"
                flat
              >
                <div
                  v-if="loading || loadingSecondLevel"
                  class="text-caption font-weight-light d-flex align-center justify-center"
                  :style="{
                    height: '100%'
                  }"
                >
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
                <DoughnutChart
                  v-else
                  :chart-data="selectedActiveCustomer ? activeCustomerDetailChartData : selectedNewCustomer ? 
                    newCustomerDetailChartData : inactiveCustomerDetailChartData"
                  :styles="{
                    height: '400px',
                    width: '420px'
                  }"
                  :options="chartOptions"
                ></DoughnutChart>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="py-5" v-if="!!selectedActiveCustomerDetailDataset && selectedActiveCustomer">
            <span class="card-title ml-10">
              Dettaglio del Dettaglio Clienti Attivi
            </span>
          </v-row>
          <div id="active-customer-detail">
            <v-row class="d-flex justify-center" v-if="!!selectedActiveCustomerDetailDataset && selectedActiveCustomer">
              <v-card
                height="750"
                color="transparent"
                flat
              >
                <v-row 
                  style="height: 60%;"
                  class="d-flex justify-center align-center"
                >
                  <GroupBasedChart
                    v-if="!!selectedActiveCustomerDetailDataset"
                    style="width: 80vw;"
                    transparent
                    type="bar"
                    :data="activeCustomerDetailDataset[selectedActiveCustomerDetailDataset]"
                    :getYValue="
                      (object) => {
                        return object[`value`];
                      }"
                    :getXValue="
                      (object) => {
                        return object[`category`];
                      }"
                    :tooltip-label-modifier="
                      (tooltipLabel, idk) => {
                        return idk.datasets[tooltipLabel.datasetIndex].label + ': ' + tooltipLabel.yLabel;
                      }
                    "
                    :loading="loading"
                  >
                  </GroupBasedChart>
                </v-row>
                <v-row style="height: 40%">
                  <TypeDataTable
                    v-if="!!selectedActiveCustomerDetailDataset"
                    fixed-headers
                    headersColor="transparent"
                    style="background-color: transparent; width: 100%;height: 100%; overflow-x: auto"
                    :headers="[
                      { text: 'N° Clienti', value: 'countCustomer', type: 'number' },
                      { text: 'N° Settimane', value: 'nWeek', type: 'number' },
                      { text: '%', value: 'perc' },
                      { text: 'Target Fidelizzazione', value: 'name' },
                    ]"
                    :items="activeCustomerDetailDataSetTableData[selectedActiveCustomerDetailDataset]"
                    :show-select="false"
                    :select-on-row-click="false"
                    :disablePagination="true"
                    @rowClicked="loadFrequentServicesCustomers"
                  >
                    <template v-slot:actions="{ item }">
                      <v-row @mouseover="preFetch(item, 3)">
                        <v-btn icon @click.stop="() => { customersSendMessage = true }">
                          <v-icon dense color="blue darken-2">mdi-message-text-fast</v-icon>
                        </v-btn>
                        <ExportExcelTable
                          :worksheetName="worksheetName"
                          :fileName="fileNameThirdLevel"
                          :fieldsToExport="fieldsToExport"
                          :fetch="fetchExcel"
                        > 
                          <template v-slot:button>
                            <v-btn icon>
                              <v-icon dense color="green darken-2">mdi-microsoft-excel</v-icon>
                            </v-btn>
                          </template>
                        </ExportExcelTable>
                      </v-row>
                    </template>
                  </TypeDataTable>
                </v-row>
              </v-card>
            </v-row>
          </div>
          <v-row class="py-5" v-if="!!selectedActiveCustomerDetailDataset && selectedNewCustomer">
            <span class="card-title ml-10">
              Dettaglio del Dettaglio Nuovi Clienti 
            </span>
          </v-row>
          <div id="new-customer-detail">
            <v-row class="d-flex justify-center" v-if="!!selectedActiveCustomerDetailDataset && selectedNewCustomer">
              <v-card
                height="750"
                color="transparent"
                flat  
              >
                <v-row 
                  style="height: 60%;"
                  class="d-flex justify-center align-center"
                >
                  <GroupBasedChart
                    v-if="!!selectedActiveCustomerDetailDataset"
                    style="width: 80vw;"
                    transparent
                    type="bar"
                    :data="newCustomerDetailDataset[selectedActiveCustomerDetailDataset]"
                    :getYValue="
                      (object) => {
                        return object[`value`];
                      }"
                    :getXValue="
                      (object) => {
                        return object[`category`];
                      }"
                    :tooltip-label-modifier="
                      (tooltipLabel, idk) => {
                        return idk.datasets[tooltipLabel.datasetIndex].label + ': ' + tooltipLabel.yLabel;
                      }
                    "
                    :loading="loading"
                  >
                  </GroupBasedChart>
                </v-row>
                <v-row style="height: 40%">
                  <TypeDataTable
                    v-if="!!selectedActiveCustomerDetailDataset"
                    fixed-headers
                    headersColor="transparent"
                    style="background-color: transparent; width: 80vw;height: 100%"
                    :headers="[
                      { text: 'N° Clienti', value: 'countCustomer', type: 'number' },
                      { text: 'N° Settimane', value: 'nWeek', type: 'number' },
                      { text: '%', value: 'perc' },
                      { text: 'Target Fidelizzazione', value: 'name' },
                    ]"
                    :items="newCustomerDetailDataSetTableData[selectedActiveCustomerDetailDataset]"
                    :show-select="false"
                    :select-on-row-click="false"
                    :disablePagination="true"
                    @rowClicked="loadFrequentServicesCustomers"
                  >
                    <template v-slot:actions="{ item }">
                      <v-row @mouseover="preFetch(item, 3)">
                        <v-btn icon @click.stop="() => { customersSendMessage = true }">
                          <v-icon dense color="blue darken-2">mdi-message-text-fast</v-icon>
                        </v-btn>
                        <ExportExcelTable
                          :worksheetName="worksheetName"
                          :fileName="fileNameThirdLevel"
                          :fieldsToExport="fieldsToExport"
                          :fetch="fetchExcel"
                        > 
                          <template v-slot:button>
                            <v-btn icon>
                              <v-icon dense color="green darken-2">mdi-microsoft-excel</v-icon>
                            </v-btn>
                          </template>
                        </ExportExcelTable>
                      </v-row>
                    </template>
                  </TypeDataTable>
                </v-row>
              </v-card>
            </v-row>
          </div>
          <div
            v-if="loadingCustomerServices"
            class="text-caption font-weight-light d-flex align-center justify-center"
          >
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <v-row class="py-5" v-if="!!selectedFrequentSTCustomersDataset 
            && selectedFrequentSTCustomersDataset.length > 0"
          >
            <span class="card-title ml-10">
              Famiglie più utilizzate
            </span>
          </v-row>
          <div id="frequent-customer-service">
            <v-row class="d-flex justify-center mb-2" v-if="!!selectedFrequentSTCustomersDataset 
              && selectedFrequentSTCustomersDataset.length > 0"
            >
              <v-card
                height="750"
                color="transparent"
                flat
              >
                <v-row 
                  style="height: 60%;"
                  class="d-flex justify-center align-center"
                >
                  <GroupBasedChart
                    v-if="!!selectedFrequentSTCustomersDataset && selectedFrequentSTCustomersDataset.length > 0"
                    style="width: 80vw;"
                    transparent
                    type="bar"
                    :data="selectedFrequentSTCustomersDataset"
                    :getYValue="
                      (object) => {
                        return object[`numServices`];
                      }"
                    :getXValue="
                      (object) => {
                        return object[`serviceTypeName`];
                      }"
                    :tooltip-label-modifier="
                      (tooltipLabel, idk) => {
                        return idk.datasets[tooltipLabel.datasetIndex].label + ': ' + tooltipLabel.yLabel;
                      }
                    "
                    :loading="loadingCustomerServices"
                  >
                  </GroupBasedChart>
                </v-row>
              </v-card>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
    
    <div>
      <SendCustomersSms
        v-model="customersSendMessage"
        :dialog-height=null
        :customers="messageCustomers"
        :loading="smsLoading"
        @fetch-customers="smsFetchCustomers"
      ></SendCustomersSms>
    </div>
  </div>
</template>

<script>

import StandardDialog from '@/components/common/StandardDialog.vue'
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import ServiceTypeAutocomplete from '@/components/common/ServiceTypeAutocomplete.vue'
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import DynamicContentTable from '@/components/common/DynamicContentTable.vue';
import balanceService from '@/services/balance/balance.service.js'
import serviceTypesManagment from "@/services/serviceTypes/serviceTypes.service.js";
import SendSmsModel from '@/components/calendar/SendSmsModel.vue';
import SendCustomersSms from "@/components/areas/marketing/SendCustomersSms.vue"
import DoughnutChart from "@/components/charts/DoughnutChart";
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import _ from 'lodash'
import html2canvas from '@/assets/js/html2canvas.min.js'
import dateUtils from "@/mixins/common/dateUtils"
import operatorService from '@/services/operators/operators.service.js'
import * as XLSX from "xlsx";

export default {
  name: "CustomersByFidelization",
  components: {
    TitleWithBackButton,
    StandardDialog,
    ServiceTypeAutocomplete,
    DynamicContentTable,
    RangeDateSelector,
    GroupBasedChart,
    SendSmsModel,
    SendCustomersSms,
    DoughnutChart,
    DateTimePicker,
    TypeDataTable,
    ExportExcelTable,
    ManualBarcodeInput
  },
  data: function() {
    return {
      summaryResults: undefined,
      startDate: undefined,
      endDate: undefined,
      loading: false,
      loadingSecondLevel: false,
      selected: undefined,
      page: 1,
      selectedMonth: undefined,
      selectedCustomer: undefined,
      selectedYear: undefined,
      month: undefined,
      menuDate: undefined,
      data: [],
      inactiveCustomersIds: [],
      newCustomersIds: [],
      customerToMsg: [],
      messageToSend: false,
      smsLoading: false,
      customersSendMessage: false,
      serviceToMsg: undefined,
      msgData: {},
      messageCustomers: [],
      showSmsModel: false,
      xAxes: [{
        stacked: true,
        ticks: {
          callback: function(label) {
            if(label.length > 15) {
              return label.substring(0, 12) + '...'
            } else {
              return label
            }
          },
          maxRotation: 18,
          minRotation: 18
        },
      }],
      chartOptions: { 
        mainAspectRatio: true,
        responsive: true,
        legend: {
          position: 'top'
        },
        showTooltips: false,
      },
      activeCustomerChartData: {},
      activeCustomerTableData: [],
      selectedActiveCustomer: false,
      selectedInactiveCustomer: false,
      activeCustomerDetailChartData: {},
      activeCustomerDetailTableData: [],
      activeCustomerDetailDataset: {},
      inactiveCustomerDetailChartData: {},
      inactiveCustomerDetailTableData: [],
      inactiveCustomerDetailDataset: {},
      selectedActiveCustomerDetailDataset: undefined,
      selectedNewCustomer: false,
      newCustomerDetailTableData: [],
      newCustomerDetailChartData: {},
      newCustomerDetailDataset: {},
      newCustomerDetailDataSetTableData: {},
      xAxesActiveCustomerDetail: [],
      activeCustomerDetailDataSetTableData: {},
      worksheetName: "",
      fileNameThirdLevel:"",
      fieldsToExport: {},
      fetchItem: {},
      fetchLevel: undefined,
      ghost: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      resultsServices: undefined,
      loadingCustomerServices: false,
      fileName: ""
    }
  },
  mixins:[dateUtils],
  mounted: async function () {
    await this.$refs.dateSelector.setThisYear()
    this.fieldsToExport = await balanceService._fieldsToExportCustomersByFidelization();
    this.worksheetName = "Export Fidelizzazione Clienti";
    this.fileNameThirdLevel =
      "Fidelizzazione_Clienti_" + this._buildDateToExport(new Date()) + ".xls";

    await this.calculate()
  },
  methods: {
    exportToExcel(){
      this.loading = true
      let workbook = XLSX.utils.book_new();
      this.fileName = "Fidelizzazione_Clienti_da_" + this.startDate.toLocaleDateString('it-IT') + "_a_" + this.endDate.toLocaleDateString('it-IT') + ".xls"
      
      //Aggiunta tabella Clienti Attivi
      let ws = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws, [
        [
          "Categoria",
          "N° Clienti"
        ],
        ...this.activeCustomerTableData.map((el) => {
          return [
            el.name,
            el.value
          ]
        }),
      ])
      XLSX.utils.book_append_sheet(workbook, ws, "Clienti Attivi");
      //Aggiunta tabella Dettaglio Totale Clienti Attivi
      let ws1 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws1, [
        ["Dettaglio Clienti Attivi"],
        [],
        [
          "Categoria",
          "N° Clienti"
        ],
        ...this.activeCustomerDetailTableData.map((el) => {
          return [
            el.name,
            el.value
          ]
        }),
        [],
        ["Dettaglio Nuovi Clienti"],
        [],
        [
          "Categoria",
          "N° Clienti"
        ],
        ...this.newCustomerDetailTableData.map((el) => {
          return [
            el.name,
            el.value
          ]
        }),
        [],
        ["Dettaglio Clienti Inattivi"],
        [],
        [
          "Categoria",
          "N° Clienti"
        ],
        ...this.inactiveCustomerDetailTableData.map((el) => {
          return [
            el.name,
            el.value
          ]
        }),
      ])
      XLSX.utils.book_append_sheet(workbook, ws1, "Dettaglio Clienti");
      
      //Aggiunta tabella Dettaglio del Dettaglio Clienti Attivi
      let ws2 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws2, [
        ["Solo Parrucchiere"],
        [],        
        [
          "N° Clienti",
          "N° Settimane",
          "%",
          "Target Fidelizzazione"
        ],
        ...this.activeCustomerDetailDataSetTableData.onlyBarber.map((el) => {
          return [
            el.countCustomer,
            el.nWeek,
            el.perc,
            el.name
          ]
        }),
        [],
        ["Solo Estetica"],
        [],        
        [
          "N° Clienti",
          "N° Settimane",
          "%",
          "Target Fidelizzazione"
        ],
        ...this.activeCustomerDetailDataSetTableData.onlyBeauty.map((el) => {
          return [
            el.countCustomer,
            el.nWeek,
            el.perc,
            el.name
          ]
        }),
        [],
        ["Parrucchiere ed Estetica"],
        [],        
        [
          "N° Clienti",
          "N° Settimane",
          "%",
          "Target Fidelizzazione"
        ],
        ...this.activeCustomerDetailDataSetTableData.bothBarberAndBeauty.map((el) => {
          return [
            el.countCustomer,
            el.nWeek,
            el.perc,
            el.name
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws2, "Det. Dettaglio Clienti Attivi");
      
      //Aggiunta tabella Dettaglio del Dettaglio Nuovi Clienti
      let ws3 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws3, [
        ["Solo Parrucchiere"],
        [],        
        [
          "N° Clienti",
          "N° Settimane",
          "%",
          "Target Fidelizzazione"
        ],
        ...this.newCustomerDetailDataSetTableData.onlyBarber.map((el) => {
          return [
            el.countCustomer,
            el.nWeek,
            el.perc,
            el.name
          ]
        }),
        [],
        ["Solo Estetica"],
        [],        
        [
          "N° Clienti",
          "N° Settimane",
          "%",
          "Target Fidelizzazione"
        ],
        ...this.newCustomerDetailDataSetTableData.onlyBeauty.map((el) => {
          return [
            el.countCustomer,
            el.nWeek,
            el.perc,
            el.name
          ]
        }),
        [],
        ["Parrucchiere ed Estetica"],
        [],        
        [
          "N° Clienti",
          "N° Settimane",
          "%",
          "Target Fidelizzazione"
        ],
        ...this.newCustomerDetailDataSetTableData.bothBarberAndBeauty.map((el) => {
          return [
            el.countCustomer,
            el.nWeek,
            el.perc,
            el.name
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws3, "Det. Dettaglio Nuovi Clienti");
      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
      this.loading = false
    },
    generateExcelName(row, level) {
      let name = row.name.replaceAll(' ', '_')
      let date = this._buildDateToExport(new Date())
      if (level == 1)
        return "Fidelizzazione_Clienti_" + name + '_' + date + ".xls" 
      else if (level == 2) {
        if (this.selectedActiveCustomer)
          return "Fidelizzazione_Clienti_Attivi_" + name + '_' + date + ".xls"
        else if (this.selectedNewCustomer)
          return "Fidelizzazione_Clienti_Nuovi_" + name + '_' + date + ".xls"
        else if (this.selectedInactiveCustomer)
          return "Fidelizzazione_Clienti_Inattivi_" + name + '_' + date + ".xls"
      }
      else
        return "Fidelizzazione_Clienti_Attivi_" + date + ".xls"
    },
    getXValue(object) {
      return this.getFidelityName(Object.keys(object)[0])
    },
    getYValue(object) {
      return Number(Object.values(object)[0])
    },
    getFidelityName(fidelityValue) {
      let monthMapper = {
        highFidelity: 'Alta Fidelizzazione',
        mediumFidelity: 'Media Fidelizzazione',
        lowFidelity: 'Bassa Fidelizzazione',
        newCustomers: "Nuovi Clienti",
        inactiveCustomers: 'Clienti Inattivi'
      }

      return monthMapper[fidelityValue]
    },    
    async loadAllServiceType() {
      let serviceTypes = await serviceTypesManagment.list(undefined, undefined, { isBar: false })
      this.serviceType = serviceTypes.rows
    },
    async calculate() {
      this.loading = true

      this.selectedActiveCustomer = false
      this.selectedInactiveCustomer = false
      this.selectedNewCustomer = false
      this.selectedActiveCustomerDetailDataset = undefined
      this.resultsServices = []

      let fidelityInfoAndTotalCustomers
      let fidelityActiveAndNewCustomers
      await this.loadAllServiceType()
      
      await Promise.all([this.loadFidelityInfoAndTotalCustomers(this.serviceType), 
        this.loadFidelityActiveAndNewCustomers()]).then((values) => {
          fidelityInfoAndTotalCustomers = values[0]
          fidelityActiveAndNewCustomers = values[1]
      })


      //KEEP COMMENT - Primo Livello
      this.activeCustomerChartData = {
        labels: ['Nuovi Clienti', 'Clienti Attivi', 'Clienti Inattivi'],
        datasets: [
          {
            backgroundColor: ['rgba(138,119,226,0.7)', 'rgba(80,191,225,0.7)', '#d3d3d3'],
            data: [
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.newCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.activeCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveCustomers, 
            ]
          }
        ]
      }

      this.activeCustomerTableData = [
        {
          color: 'rgba(80,191,225,0.7)',
          name: 'Totale Clienti Attivi',
          category: 'activeCustomers',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.activeCustomers,
        },
        {
          color: 'rgba(138,119,226,0.7)',
          name: 'Nuovi Clienti',
          category: 'newCustomers',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.newCustomers,
        },
        {
          color: '#d3d3d3',
          name: 'Clienti Inattivi',
          category: 'inactiveCustomers',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveCustomers,
        },
      ]

      //KEEP COMMENT - Secondo Livello
      this.activeCustomerDetailChartData = {
        labels: ['Solo Parrucchiere', 'Solo Estetica', 'Parrucchiere ed Estetica'],
        datasets: [
          {
            backgroundColor: ['#2e294e', '#be97c6', '#efbcd5'],
            data: [
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBarberActiveCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBeautyActiveCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.bothBarberAndBeautyActiveCustomers, 
            ]
          }
        ]
      }

      this.activeCustomerDetailTableData = [
        {
          color: '#2e294e',
          name: 'Solo Parrucchiere',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBarberActiveCustomers,
          dataset: 'onlyBarber',
          category: 'onlyBarberActiveCustomers'
        },
        {
          color: '#be97c6',
          name: 'Solo Estetica',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBeautyActiveCustomers,
          dataset: 'onlyBeauty',
          category: 'onlyBeautyActiveCustomers'
        },
        {
          color: '#efbcd5',
          name: 'Parrucchiere ed Estetica',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.bothBarberAndBeautyActiveCustomers,
          dataset: 'bothBarberAndBeauty',
          category: 'bothBarberAndBeautyActiveCustomers'
        },
      ]
      
      this.newCustomerDetailChartData = {
        labels: ['Solo Parrucchiere', 'Solo Estetica', 'Parrucchiere ed Estetica'],
        datasets: [
          {
            backgroundColor: ['#2e294e', '#be97c6', '#efbcd5'],
            data: [
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBarberNewCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBeautyNewCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.bothBarberAndBeautyNewCustomers, 
            ]
          }
        ]
      }

      this.newCustomerDetailTableData = [
        {
          color: '#2e294e',
          name: 'Solo Parrucchiere',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBarberNewCustomers,
          dataset: 'onlyBarber',
          category: 'onlyBarberNewCustomers',
        },
        {
          color: '#be97c6',
          name: 'Solo Estetica',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.onlyBeautyNewCustomers,
          dataset: 'onlyBeauty',
          category: 'onlyBeautyNewCustomers'
        },
        {
          color: '#efbcd5',
          name: 'Parrucchiere ed Estetica',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.bothBarberAndBeautyNewCustomers,
          dataset: 'bothBarberAndBeauty',
          category: 'bothBarberAndBeautyNewCustomers'
        },
      ]

      this.inactiveCustomerDetailChartData = {
        labels: ['Solo Parrucchiere', 'Solo Estetica', 'Parrucchiere ed Estetica'],
        datasets: [
          {
            backgroundColor: ['#2e294e', '#be97c6', '#efbcd5'],
            data: [
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveBarberCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveBeautyCustomers,
              fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveBeautyAndBarberCustomers, 
            ]
          }
        ]
      }

      this.inactiveCustomerDetailTableData = [
        {
          color: '#2e294e',
          name: 'Solo Parrucchiere',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveBarberCustomers,
          dataset: 'inactiveBarberCustomers',
          category: 'inactiveBarberCustomers'
        },
        {
          color: '#be97c6',
          name: 'Solo Estetica',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveBeautyCustomers,
          dataset: 'inactiveBeautyCustomers',
          category: 'inactiveBeautyCustomers'
        },
        {
          color: '#efbcd5',
          name: 'Parrucchiere ed Estetica',
          value: fidelityInfoAndTotalCustomers.totalFidelityCustomers.inactiveBeautyAndBarberCustomers,
          dataset: 'inactiveBeautyAndBarberCustomers',
          category: 'inactiveBeautyAndBarberCustomers'
        },
      ]

      this.activeCustomerDetailDataset = {
        onlyBarber: [],
        onlyBeauty: [],
        bothBarberAndBeauty: []
      }
      this.newCustomerDetailDataset = {
        onlyBarber: [],
        onlyBeauty: [],
        bothBarberAndBeauty: []
      }

      this.activeCustomerDetailDataSetTableData = {
        onlyBarber: [],
        onlyBeauty: [],
        bothBarberAndBeauty: []
      }
      this.newCustomerDetailDataSetTableData = {
        onlyBarber: [],
        onlyBeauty: [],
        bothBarberAndBeauty: []
      }

      //KEEP COMMENT - Terzo Livello
      if (!!fidelityActiveAndNewCustomers) {
        this.activeCustomerDetailDataset.onlyBarber = [{
          label: 'Solo Parrucchiere',
          backgroundColor: '#2e294e',
          data: [{
              category: 'Da Fidelizzare (da 1 a 2)',
              value: fidelityActiveAndNewCustomers.noFidelityBarberActive,
            },
            {
              category: 'Basso Fidelizzato (da 3 a 10)',
              value: fidelityActiveAndNewCustomers.lowFidelityBarberActive,
            },
            {
              category: 'Medio Fidelizzato (da 11 a 26)',
              value: fidelityActiveAndNewCustomers.mediumFidelityBarberActive,
            },
            {
              category: 'Alto Fidelizzato (da 27 a 38)',
              value: fidelityActiveAndNewCustomers.highFidelityBarberActive,
            },
            {
              category: 'Premium (da 38 in su)',
              value: fidelityActiveAndNewCustomers.premiumFidelityBarberActive,
            }
          ]
        }]
        
        this.activeCustomerDetailDataSetTableData.onlyBarber = [
          {
            countCustomer: fidelityActiveAndNewCustomers.noFidelityBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.noFidelityBarberActive / fidelityActiveAndNewCustomers.onlyBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Da Fidelizzare (da 1 a 2)',
            category: 'noFidelityBarberActive',
            dataset: 'onlyBarber'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.lowFidelityBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.lowFidelityBarberActive / fidelityActiveAndNewCustomers.onlyBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Basso Fidelizzato (da 3 a 10)',
            category: 'lowFidelityBarberActive',
            dataset: 'onlyBarber'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.mediumFidelityBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.mediumFidelityBarberActive / fidelityActiveAndNewCustomers.onlyBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Medio Fidelizzato (da 11 a 26)',
            category: 'mediumFidelityBarberActive',
            dataset: 'onlyBarber'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.highFidelityBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.highFidelityBarberActive / fidelityActiveAndNewCustomers.onlyBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Alto Fidelizzato (da 27 a 38)',
            category: 'highFidelityBarberActive',
            dataset: 'onlyBarber'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.premiumFidelityBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.premiumFidelityBarberActive / fidelityActiveAndNewCustomers.onlyBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Premium (da 38 in su)',
            category: 'premiumFidelityBarberActive',
            dataset: 'onlyBarber'
          },
        ]
        
        this.newCustomerDetailDataset.onlyBarber = [{
          label: 'Solo Parrucchiere',
          backgroundColor: '#2e294e',
          data: [{
              category: 'Da Fidelizzare (da 1 a 2)',
              value: fidelityActiveAndNewCustomers.noFidelityBarberNew,
            },
          ]
        }]
        
        this.newCustomerDetailDataSetTableData.onlyBarber =[{
          countCustomer: fidelityActiveAndNewCustomers.noFidelityBarberNew,
          nWeek: 52,
          perc: Number(100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          name: 'Da Fidelizzare (da 1 a 2)',
          category: 'noFidelityBarberNew',
          dataset: 'onlyBarber'
        }]
      
        this.activeCustomerDetailDataset.onlyBeauty = [{
          label: 'Solo Estetica',
          backgroundColor: '#be97c6',
          data: [{
              category: 'Da Fidelizzare (da 1 a 2)',
              value: fidelityActiveAndNewCustomers.noFidelityBeautyActive,
            },
            {
              category: 'Basso Fidelizzato (da 3 a 10)',
              value: fidelityActiveAndNewCustomers.lowFidelityBeautyActive,
            },
            {
              category: 'Medio Fidelizzato (da 11 a 26)',
              value: fidelityActiveAndNewCustomers.mediumFidelityBeautyActive,
            },
            {
              category: 'Alto Fidelizzato (da 27 a 38)',
              value: fidelityActiveAndNewCustomers.highFidelityBeautyActive,
            },
            {
              category: 'Premium (da 38 in su)',
              value: fidelityActiveAndNewCustomers.premiumFidelityBeautyActive,
            }
          ]
        }]
      
        this.activeCustomerDetailDataSetTableData.onlyBeauty = [
          {
            countCustomer: fidelityActiveAndNewCustomers.noFidelityBeautyActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.noFidelityBeautyActive / fidelityActiveAndNewCustomers.onlyBeautyActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Da Fidelizzare (da 1 a 2)',
            category: 'noFidelityBeautyActive',
            dataset: 'onlyBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.lowFidelityBeautyActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.lowFidelityBeautyActive / fidelityActiveAndNewCustomers.onlyBeautyActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Basso Fidelizzato (da 3 a 10)',
            category: 'lowFidelityBeautyActive',
            dataset: 'onlyBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.mediumFidelityBeautyActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.mediumFidelityBeautyActive / fidelityActiveAndNewCustomers.onlyBeautyActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Medio Fidelizzato (da 11 a 26)',
            category: 'mediumFidelityBeautyActive',
            dataset: 'onlyBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.highFidelityBeautyActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.highFidelityBeautyActive / fidelityActiveAndNewCustomers.onlyBeautyActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Alto Fidelizzato (da 27 a 38)',
            category: 'highFidelityBeautyActive',
            dataset: 'onlyBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.highFidelityBeautyActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.highFidelityBeautyActive / fidelityActiveAndNewCustomers.onlyBeautyActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Premium (da 38 in su)',
            category: 'premiumFidelityBeautyActive',
            dataset: 'onlyBeauty'
          },
        ]
                        
        this.newCustomerDetailDataset.onlyBeauty = [{
          label: 'Solo Estetica',
          backgroundColor: '#be97c6',
          data: [{
              category: 'Da Fidelizzare (da 1 a 2)',
              value: fidelityActiveAndNewCustomers.noFidelityBeautyNew,
            },
          ]
        }]
        this.newCustomerDetailDataSetTableData.onlyBeauty =[          {
          countCustomer: fidelityActiveAndNewCustomers.noFidelityBeautyNew,
          nWeek: 52,
          perc: Number(100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          name: 'Da Fidelizzare (da 1 a 2)',
          category: 'noFidelityBeautyNew',
          dataset: 'onlyBeauty'
        }]

        this.activeCustomerDetailDataset.bothBarberAndBeauty = [{
          label: 'Parrucchiere ed Estetica',
          backgroundColor: '#efbcd5',
          data: [{
              category: 'Da Fidelizzare (da 1 a 2)',
              value: fidelityActiveAndNewCustomers.noFidelityBothBeautyAndBarberActive,
            },
            {
              category: 'Basso Fidelizzato (da 3 a 10)',
              value: fidelityActiveAndNewCustomers.lowFidelityBothBeautyAndBarberActive,
            },
            {
              category: 'Medio Fidelizzato (da 11 a 26)',
              value: fidelityActiveAndNewCustomers.mediumFidelityBothBeautyAndBarberActive,
            },
            {
              category: 'Alto Fidelizzato (da 27 a 38)',
              value: fidelityActiveAndNewCustomers.highFidelityBothBeautyAndBarberActive,
            },
            {
              category: 'Premium (da 38 in su)',
              value: fidelityActiveAndNewCustomers.premiumFidelityBothBeautyAndBarberActive,
            }
          ]
        }]

        this.activeCustomerDetailDataSetTableData.bothBarberAndBeauty = [
          {
            countCustomer: fidelityActiveAndNewCustomers.noFidelityBothBeautyAndBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.noFidelityBothBeautyAndBarberActive / fidelityActiveAndNewCustomers.bothBeautyAndBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Da Fidelizzare (da 1 a 2)',
            category: 'noFidelityBothBeautyAndBarberActive',
            dataset: 'bothBarberAndBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.lowFidelityBothBeautyAndBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.lowFidelityBothBeautyAndBarberActive / fidelityActiveAndNewCustomers.bothBeautyAndBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Basso Fidelizzato (da 3 a 10)',
            category: 'lowFidelityBothBeautyAndBarberActive',
            dataset: 'bothBarberAndBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.mediumFidelityBothBeautyAndBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.mediumFidelityBothBeautyAndBarberActive / fidelityActiveAndNewCustomers.bothBeautyAndBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Medio Fidelizzato (da 11 a 26)',
            category: 'mediumFidelityBothBeautyAndBarberActive',
            dataset: 'bothBarberAndBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.highFidelityBothBeautyAndBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.highFidelityBothBeautyAndBarberActive / fidelityActiveAndNewCustomers.bothBeautyAndBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Alto Fidelizzato (da 27 a 38)',
            category: 'highFidelityBothBeautyAndBarberActive',
            dataset: 'bothBarberAndBeauty'
          },
          {
            countCustomer: fidelityActiveAndNewCustomers.premiumFidelityBothBeautyAndBarberActive,
            nWeek: 52,
            perc: Number((fidelityActiveAndNewCustomers.premiumFidelityBothBeautyAndBarberActive / fidelityActiveAndNewCustomers.bothBeautyAndBarberActiveCustomers * 100).toFixed(2)).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            name: 'Premium (da 38 in su)',
            category: 'premiumFidelityBothBeautyAndBarberActive',
            dataset: 'bothBarberAndBeauty'
          },
        ]
                        
        this.newCustomerDetailDataset.bothBarberAndBeauty = [{
          label: 'Parrucchiere ed Estetica',
          backgroundColor: '#efbcd5',
          data: [{
              category: 'Da Fidelizzare (da 1 a 2)',
              value: fidelityActiveAndNewCustomers.noFidelityBothBeautyAndBarberNew,
            },
          ]
        }]
        this.newCustomerDetailDataSetTableData.bothBarberAndBeauty =[{
          countCustomer: fidelityActiveAndNewCustomers.noFidelityBothBeautyAndBarberNew,
          nWeek: 52,
          perc: Number(100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          name: 'Da Fidelizzare (da 1 a 2)',
          category: 'noFidelityBothBeautyAndBarberNew',
          dataset: 'bothBarberAndBeauty'
        }]
      }

      this.loading = false
    },
    openActiveCustomers(row){
      this.loadingSecondLevel = true
      this.resultsServices = []
      setTimeout(() => {
        if(row.name == "Nuovi Clienti") {
          this.selectedActiveCustomer = false
          this.selectedInactiveCustomer = false
          this.selectedNewCustomer = true
        } else if(row.name == "Totale Clienti Attivi") {
          this.selectedNewCustomer = false
          this.selectedInactiveCustomer = false
          this.selectedActiveCustomer = true
        } else {
          this.selectedNewCustomer = false
          this.selectedActiveCustomer = false
          this.selectedInactiveCustomer = true
        }
        this.loadingSecondLevel = false
      }, 400)
    },
    openActiveCustomersDetail(row) {
      this.selectedActiveCustomerDetailDataset = row.dataset
      this.resultsServices = []
      setTimeout(() => {
        const id = !!this.selectedActiveCustomer ? "active-customer-detail" : "new-customer-detail"
        const container = document.getElementById(id)
        container.scrollIntoView(false);
      }, 300)
    },
    async smsFetchCustomers(filters) {
      const level = this.fetchLevel
      const row = this.fetchItem
      this.smsLoading = true

      const commonParams = {
        from: this.startDate,
        to: this.endDate,
        category: row.category,
        ghost: this.ghost,
        filters: filters
      }

      let customers;
      if (level === 1) {
        customers = await balanceService.fidelityCustomersDetail(commonParams)
      } else if (level === 2) {
        customers = (!!this.selectedActiveCustomer || !!this.selectedNewCustomer) ?
          await balanceService.fidelityActiveCustomersDetail(commonParams) :
          await balanceService.fidelityInactiveCustomersDetail(commonParams)
      } else {
        customers = (!!this.selectedActiveCustomer || !!this.selectedNewCustomer) ?
          await balanceService.fidelityActiveCustomersDetailDetail(commonParams) :
          null
      }

      if (customers !== null || customers !== undefined) {
        this.messageCustomers = customers
      }

      this.smsLoading = false
    },
    async fetchExcel() {
      const level = this.fetchLevel
      const row = this.fetchItem

      const commonParams = {
        from: this.startDate,
        to: this.endDate,
        category: row.category,
        ghost: this.ghost,
      }

      let customersToExport;
      if (level === 1) {
        customersToExport = await balanceService.fidelityCustomersDetail(commonParams)
      } else if (level === 2) {
        customersToExport = (!!this.selectedActiveCustomer || !!this.selectedNewCustomer) ?
          await balanceService.fidelityActiveCustomersDetail(commonParams) :
          await balanceService.fidelityInactiveCustomersDetail(commonParams)
      } else {
        customersToExport = (!!this.selectedActiveCustomer || !!this.selectedNewCustomer) ?
          await balanceService.fidelityActiveCustomersDetailDetail(commonParams) :
          null
      }

      return customersToExport
    },
    preFetch(row, level){
      this.fetchItem = row
      this.fetchLevel = level
      this.messageCustomers = []
    },
    async loadFidelityInfoAndTotalCustomers(serviceType) {
      const startDate = this.startDate,
      endDate = this.endDate

      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      const results = await balanceService.fidelityInfoAndTotalCustomers({
        from: startDate,
        to: endDate,
        serviceTypes: serviceType.map(st => st.id),
        ghost: this.ghost
      })

      return results
    },
    async loadFidelityActiveAndNewCustomers() {
      const startDate = this.startDate,
      endDate = this.endDate

      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      const results = await balanceService.fidelityActiveAndNewCustomers({
        from: startDate,
        to: endDate,
        ghost: this.ghost
      })

      return results
    },
    async loadFrequentServicesCustomers(row) {
      this.loadingCustomerServices = true
      this.resultsServices = []
            
      if(!!row && row.countCustomer > 0) {
        const startDate = this.startDate,
        endDate = this.endDate

        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        startDate.setMilliseconds(0)

        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)
        endDate.setMilliseconds(999)

        this.resultsServices = await balanceService.frequentServiceTypeActiveCustomers({
          from: this.startDate,
          to: this.endDate,
          category: row.category,
          ghost: this.ghost,
        })
      }

      this.loadingCustomerServices = false
      setTimeout(() => {
        const id = "frequent-customer-service"
        const container = document.getElementById(id)
        container.scrollIntoView(false);
      }, 300)
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost
          this.calculate()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    allowedDatesStart(date) {
      return (new Date(date).getDay()) == 1
    },
    allowedDatesEnd(date) {
      return new Date(date) >= this.startDate && (new Date(date).getDay()) == 0
    },
    print() {
      let id = 'chart'

      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Fidelizzazione_Clienti_" + this.startDate.getDate() + "-" + (this.startDate.getMonth() + 1) + '_' + this.endDate.getDate() + "-" + (this.endDate.getMonth() + 1) + ".png";
        a.href = canvas.toDataURL("image/jpeg");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    },
  },
  computed: {    
    selectedFrequentSTCustomersDataset() {
      if(!this.resultsServices || this.resultsServices.length == 0) {
        return []
      }

      let localServices = this.resultsServices["frequentServiceTypeActiveCustomers"].slice()
      let category = this.resultsServices["category"]

      let count = 0
      for (let i = 10; this.resultsServices["frequentServiceTypeActiveCustomers"].length < i; i--) {
        count++
        localServices.push({
          numServices: 0,
          serviceTypeName: ("Non Presente " + " " + count)
        })
      }

      let results = []
      
      if (category === 'isBarber') {
        results.push({
          label: 'Solo Parrucchiere',
          backgroundColor: '#2e294e',
          data: localServices
        })
      }

      if (category === 'isBeauty') {
        results.push({
          label: 'Solo Estetica',
          backgroundColor: '#be97c6',
          data: localServices
        })
      }

      if (category === 'isBoth') {
        results.push({
          label: 'Parrucchiere ed Estetica',
          backgroundColor: '#efbcd5',
          data: localServices
        })
      }

      return results
    },
  }
}
</script>

<style>
  .bordered {
    border-spacing: 0;
  }

  .bordered th {
    /* border-bottom: 1px solid; */
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
  }

  .hoverable-table-cell:hover {
    background-color: rgb(0, 0, 0, 0.06) !important;
  }

  .red-background {
    background-color: rgb(255 94 94 / 20%);
  }

  .green-background {
    background-color: rgb(119 255 94 / 20%);
  }

  .card-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 0.5rem;
}

</style>