export const monthNameMapper = {
  0: 'Gennaio',
  1: 'Febbraio',
  2: 'Marzo',
  3: 'Aprile',
  4: 'Maggio',
  5: 'Giugno',
  6: 'Luglio',
  7: 'Agosto',
  8: 'Settembre',
  9: 'Ottobre',
  10: 'Novembre',
  11: 'Dicembre',
}

export function monthLabels(from, to=new Date(), modifier) {
  const labels = []
  const fromMonth = from.getMonth()
  const toMonth = to.getMonth()
  const fromYear = from.getFullYear()
  const toYear = to.getFullYear()

  const diffYear = toYear - fromYear
  for(let i = 0; i <= diffYear; i += 1) {
    for(let j = 0; j < 12; j += 1) {
      if((i == 0 && j < fromMonth) || (i == diffYear - 1 && j > toMonth) || (diffYear == 0 && j > toMonth)) {
        continue
      }
      let key = (i * 12) + j

      let label = monthNameMapper[j] + ' ' + (fromYear + i)
      if(!!modifier) {
        label = modifier({
          label: label,
          month: j,
          year: fromYear + i,
          monthNameMapper: monthNameMapper,
          granularity: 'month'
        })
      }

      labels.push({
        key: key,
        label: label
      })
    }
  }

  return labels
}

export function weekLabels(from, to=new Date(), modifier) {
  const labels = []
  
  const diff = Math.round((to - from) / (1000 * 60 * 60 * 24 * 7))
  for(let i = 1; i <= diff; i += 1) {
    let label = `week ${i}`

    let key = i

    if(!!modifier) {
      label = modifier({
        label: label,
        week: i,
        granularity: 'week'
      })
    }

    labels.push({
      key: key,
      label: label
    })
  }
  
  return labels
}

export function quarterLabels(from, to=new Date(), modifier) {
  const labels = []

  const diff = Math.round((to - from) / (1000 * 60 * 60 * 24 * 90))
  for(let i = 1; i <= diff; i += 1) {
    let label = `quarter ${i}`

    let key = i

    if(!!modifier) {
      label = modifier({
        label: label,
        quarter: i,
        granularity: 'quarter'
      })
    }

    labels.push({
      key: key,
      label: label
    })
  }
  
  return labels
}

export function dayLabels(from, to=new Date(), modifier) {
  const labels = []
  
  const diff = Math.round((to - from) / (1000 * 60 * 60 * 24))
  for(let i = 0; i <= diff; i += 1) {
    let date = new Date(from.getTime() + (i * (1000 * 60 * 60 * 24)))
    date.setHours(0, 0, 0, 0)
    let key = date.getTime()
    let label = `${date.getDate()} ${monthNameMapper[date.getMonth()]} ${date.getFullYear()}`

    if(!!modifier) {
      label = modifier({
        label: label,
        date: date,
        granularity: 'day'
      })
    }

    labels.push({
      key: key,
      label: label
    })
  }
  
  return labels
}

export function getLabelKeyFromDate(date, granularity) {
  date = new Date(date)
  if(granularity === 'month') {
    return date.getMonth()
  } else if (granularity == 'week') {
    let dayNum = date.getDay()
    let localDate = date
    localDate.setUTCDate(date.getUTCDate() + 4 - dayNum)
    let yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
    return Math.ceil((((localDate - yearStart) / 86400000) + 1) / 7)
  } else if (granularity == 'quarter') {
    return Math.floor((date.getMonth() + 3) / 3)
  }
  else {
    date.setHours(0, 0, 0, 0)
    return date.getTime()
  }
}