<template>
  <div>
    <v-switch 
      class="pa-0 ma-0" 
      hide-details="auto" 
      v-model="localValue" 
      @change="handleInput"
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: 'BooleanSetting',
  data: function() {
    return { 
      localValue: this.value
    }
  },
  props: {
    value: {
      type: Boolean,
    },
    settingKey: {
      type: String
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.localValue)
      this.$emit('set', this.settingKey, this.localValue)
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  }
}
</script>
