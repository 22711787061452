<template>
  <div>
    <v-text-field
      v-model.number="confirmedLocalValue"
      :filled="filled"
      :dense="dense"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :hide-details="hideDetails"
      :label="label"
      type="number"
      :min="min"
      :max="max"
      :disabled="disabled"
      :rules="rules"
      :rounded="rounded"
      @click:append-outer="openNumericKeyboard"
      @input="handleConfirmedLocalValueChange"
      :suffix="!Number.isInteger(confirmedLocalValue) ? suffixs[0] : suffixs[1]"
    >
      <template v-slot:prepend v-if="prependSlot">
        <slot name="prepend">

        </slot>
      </template>
    </v-text-field>
    <v-dialog
      v-model="numericKeyboardDialog"
      max-width="300px"
      transition="scroll-y-transition"
    >
      <v-card 
        color="grey darken-3"
        dark
        class="pa-2"
      >
        <div 
          class="text-right text-overline my-2"
          style="width: 100%; font-size: 1.4em !important; min-height: 30px"
        >{{localValueString}}</div>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(1)"
              >1</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(2)"
              >2</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(3)"
              >3</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(4)"
              >4</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(5)"
              >5</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(6)"
              >6</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(7)"
              >7</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(8)"
              >8</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(9)"
              >9</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick('.')"
              >.</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick(0)"
              >0</v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="grey darken-2"
                fab
                style="font-size: 1.2em"
                @click="handleButtonClick('cancel')"
              >C</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!live">
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="white"
                icon
                @click="cancel"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col class="d-flex align-center justify-center">
              <v-btn
                color="white"
                icon
                @click="confirm"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'

export default {
  name: "NumericKeyboardInput",
  components: {
    StandardDialog
  },
  data: function() {
    return {
      numericKeyboardDialog: false,
      number: undefined,
      localValue: this.value,
      localValueString: undefined,
      confirmedLocalValue: this.value
    }
  },
  props: {
    value: {
      type: Number,
      default: undefined
    },
    filled: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    appendIcon: {
      type: String,
      default: undefined
    },
    rules: {
    },
    appendOuterIcon: {
      type: String,
      default: "mdi-dialpad"
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    prependInnerIcon: {
      type: String,
      default: undefined
    },
    hideDetails: {
      default: 'auto'
    },
    label: {
      type: String,
    },
    live: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    suffixs: {
      type: Array,
      default: ()=> {return ['','']}
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    prependSlot: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleConfirmedLocalValueChange(newVal) {
      this.localValue = Number(newVal)
      this.localValueString = newVal.toString()
      this.$emit('input', this.localValue)
    },
    openNumericKeyboard() {
      this.numericKeyboardDialog = true
    },
    handleButtonClick(buttonValue) {
      if(buttonValue === '.') {
        if(this.localValueString[this.localValueString.length - 1] == '.') return
        this.localValueString = this.localValueString != undefined && this.localValueString != null 
          ? this.localValueString + '.' 
          : '0.'
      } else if(buttonValue == 'cancel') {
        if(this.localValueString[this.localValueString.length - 1] != '.') {
          const localValueString = this.localValue.toString()
          if(localValueString.length == 1) {
            this.localValue = undefined
          } else {
            this.localValue = Number(localValueString.slice(0, localValueString.length - 1))
          }
          if(this.live) {
            this.confirmedLocalValue = this.localValue
            this.$emit('input', this.localValue)
          }
        }
        this.localValueString = this.localValueString.slice(0, this.localValueString.length - 1)
      } else {
        if(!this.localValue) this.localValue = ''
        if(!!this.localValueString && this.localValueString[this.localValueString.length - 1] == '.') {
          this.localValue = Number(this.localValue.toString() + '.' + buttonValue.toString())
        } else {
          this.localValue = Number(this.localValue.toString() + buttonValue.toString())
        }
        this.localValueString = this.localValue.toString()
        if(this.live) {
          this.confirmedLocalValue = this.localValue
          this.$emit('input', this.localValue)
        }
      }
    },
    confirm() {
      this.confirmedLocalValue = this.localValue
      this.$emit('input', this.localValue)
      this.numericKeyboardDialog = false
    },
    cancel() {
      this.localValue = this.confirmedLocalValue
      this.numericKeyboardDialog = false 
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
      this.confirmedLocalValue = this.localValue
      this.localValueString = newVal == undefined || newVal == null ? undefined : newVal.toString()
    }
  },
  computed: {
  },
}
</script>

<style>

</style>