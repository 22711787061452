export default [
  {
    path: "/:billId/pay",
    name: "PayBill",
    component: () => import("@/components/homepage/sections/CashDialog.vue"),
    props: (route) => {
      return {
        dialog: true,
        goBackTo: {
          name: !!route.params.goBack ? route.params.goBack : "Homepage",
          params: {
            selectedBill: route.params.bill
          }
        },
        billId: route.params.billId,
        bill: route.params.bill,
        cashDeskLog: route.params.cashDeskLog,
        useCustomerCard: !!route.query.useCustomerCard && route.query.useCustomerCard.toString() == 'true',
        usePromos: !!route.query.usePromos && route.query.usePromos.toString() == 'true',
        promoUsed: !!route.params.promoUsed ? route.params.promoUsed : undefined,
        promoRemaining: !!route.params.promoRemaining || route.params.promoRemaining === 0 ? route.params.promoRemaining : undefined,
        tableId: route.params.tableId,
        partialPayment: route.params.partialPayment,
        returnBill: !!route.params.returnBill ? route.params.returnBill : undefined,
        customerCardCreditGhost: !!route.params.customerCardCreditGhost ? route.params.customerCardCreditGhost : undefined,
      }
    },
  },
  {
    path: "/customerCards/:customerCardId/pay",
    name: "PayCustomerCardLoad",
    component: () => import("@/components/homepage/sections/CustomerCardLoadDialog.vue"),
    props: (route) => {
      return {
        dialog: true,
        goBackTo: {
          name: "Homepage",
          params: {
            selectedBill: route.params.bill,
          },
          query: {
            openCardDetailDialog: true
          }
        },
        customerCard: route.params.customerCard,
        customerCardId: route.params.customerCardId,
        customer: route.params.customer,
        loadValue: Number(route.query.loadValue)
      }
    },
  },
  {
    path: "/paymentCredits/:paymentCreditId/pay",
    name: "PayPaymentCredit",
    component: () => import("@/components/homepage/sections/PaymentCreditPayDialog.vue"),
    props: (route) => {
      return {
        dialog: true,
        goBackTo: {
          name: route.params.pathToGoBack,
        },
        paymentCredit: route.params.paymentCredit,
        paymentCreditId: route.params.paymentCreditId,
        customer: route.params.customer,
        filters: route.params.filters,
        pathName: route.params.pathName,
      }
    },
  },
  {
    path: "/addCustomerDialog",
    name: "AddCustomerDialog",
    component: () => import("@/components/homepage/AddCustomerDialog.vue"),
    props: (route) => {
      return {
        dialog: true,
        goBackTo: {        
          name: "Homepage",
        },
        fromLuluu:true,
      }
    },
  },
]