import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import barcodeService from '@/services/barcode/barcode.service.js'
import billsService from "@/services/bills/bills.service";

class promosManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/promos/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  create(promo) {
    const apiUrl = UrlKeeper.getUrl()

    delete promo.validDiscountItems

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/promos/create', promo).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  createCustom(promo) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/promos/createCustom', promo).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  archive(promo) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!promo.id)
        reject('id undefined')

      axios.post(apiUrl + '/payments/promos/archive', { id: promo.id }).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  selectPromoCustomer(promoCustomer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!promoCustomer || !promoCustomer.id)
        reject('id undefined')

      axios.post(apiUrl + '/payments/promos/' + promoCustomer.promo.id + '/selectPromoCustomer', { promoCustomer: 
        { 
          id: promoCustomer.id,
          customerId: promoCustomer.customerId
        }}).then(async (response) => {
          if (response.success) {
            await billsService.reloadAllTodayCachedFilterList()
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((error) => {
          reject(error)
        })
    })
  }

  update(promo) {
    const apiUrl = UrlKeeper.getUrl()

    delete promo.validDiscountItems

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/promos/update', promo).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(promoId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/payments/promos/get', { id: promoId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      })
    })
  }

  /*
    promo: {
      id: Number
    } Promo,
    params: {
      date: Date,
      documentType: constants.paymentTransactions.documentType,
      cashPaid: Number,
      cardPaid: Number,
      checkPaid: Number,
      otherPaid: Number,
      notPaid: Number,
      ghostPaid: Number,
      discounts: Number,
      cashDesk: {
        id: Number,
        name: String
      } CashDesk,
      dataInvoice: {
        (required invoice fields)
      },
      promoCode: string,
      customer: {
        id: Number,
      } Customer
    }
  */
  payPromo(promo, params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!promo || !promo.id) {
        reject(new Error('promo not specified'))
        return
      }

      if (!params.customer || !params.customer.id) {
        reject(new Error('customer not specified'))
        return
      }

      axios.post(apiUrl + '/payments/promos/payPromo', {
        promo,
        params: {
          date: params.date,
          documentType: params.documentType,
          amount: params.amount,
          cashPaid: params.cashPaid,
          cardPaid: params.cardPaid,
          checkPaid: params.checkPaid,
          otherPaid: params.otherPaid,
          notPaid: params.notPaid,
          ghostPaid: params.ghostPaid,
          discounts: params.discounts,
          cashDesk: params.cashDesk,
          dataInvoice: params.dataInvoice,
          customer: params.customer,
          schedulePaymentsSetting: params.schedulePaymentsSetting,
          promoAmount: params.promoAmount,
          finalScheduleDate: params.finalScheduleDate,
          documentNumber: params.documentNumber,
          cashDeskLog: params.cashDeskLog,
          customerToAdd: params.customerToAdd,
        },
        promoCode: params.promoCode
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /**
   * @param {Object} promo
   * @param {String} promo.barcode
   * @param {Object} customer
   * @param {Number} customer.id
   */
  async validatePromoCode(promo, customer) {
    const apiUrl = UrlKeeper.getUrl()

    let response = await axios.post(apiUrl + '/payments/promos/validatePromoCode', {
      promoCode: promo.barcode,
      customer
    })
    
    if(response.success) {
      return response.results
    } else {
      throw response.results
    }
  }

  addCustomer(promo, customer, transactionId, promoCode) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!promo || !promo.id) {
        reject(new Error('promo not specified'))
        return
      }

      if (!customer || !customer.id) {
        reject(new Error('customer not specified'))
        return
      }

      let obj
      if (!transactionId)
        obj = {
          customer,
          promoCode
        }
      else
        obj = {
          customer,
          promoCode,
          transaction: {
            id: transactionId
          }
        }

      axios.post(apiUrl + '/payments/promos/' + promo.id + '/addCustomer', obj).then(async (response) => {
        if (response.success) {
          await billsService.reloadAllTodayCachedFilterList()
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  removeCustomer(promoCustomer, customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!promoCustomer || !promoCustomer.id) {
        reject(new Error('promo not specified'))
        return
      }

      if (!customer || !customer.id) {
        reject(new Error('customer not specified'))
        return
      }
      customer.promosCustomers.forEach(promoCustomer => { delete promoCustomer.promo.gainedItems })

      axios.post(apiUrl + '/payments/promos/' + promoCustomer.id + '/removeCustomer', customer).then(async (response) => {
        if (response.success) {
          await billsService.reloadAllTodayCachedFilterList()
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  listPromoCustomerByPromoId(promo) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!promo || !promo.id) {
        reject(new Error('promo not specified'))
        return
      }

      axios.post(apiUrl + '/payments/promos/' + promo.id + '/listPromoCustomerByPromoId').then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }


  fields() {
    return Promise.resolve([
      { text: 'Nome', value: 'name' },
      { text: 'Descrizione', value: 'description' },
      { text: 'Attiva', value: 'status', type: 'custom' },
      { text: 'Come lo guadagna', value: 'gainType', type: 'enum' },
      { text: 'Cosa guadagna', value: 'itemGained', type: 'enum' },
      { text: 'Come lo utilizza', value: 'useTerms', type: 'enum' },
      { text: 'Barcode', value: 'barcode.code'}
    ])
  }

  _fieldsToExport() {
    return {
      'Nome': 'name',
      'Descrizione': 'description',
      'Attiva': {
        field: 'status',
        callback: (value) => {
          return value == 'active' ? 'Si' : 'No'
        }
      },
      'Come lo guadagna': {
        field: 'gainType',
        callback: (value) => {
          const gainTypeMapper = {
            target: 'A obiettivo',
            simple: 'Semplice',
            price: 'Prezzo',
          }

          return gainTypeMapper[value]
        }
      },
      'Cosa guadagna': {
        field: 'itemGained',
        callback: (value) => {
          const itemGainedMapper = {
            discount: 'Sconto',
            amount: 'Valore',
            services: 'Servizi',
            servicesDiscounted: 'Servizi scontati'
          }

          return itemGainedMapper[value]
        }
      },
      'Come lo utilizza': {
        field: 'useTerms',
        callback: (value) => {
          const useTermsMapper = {
            oneShot: 'One Shot',
            oneShotRange: 'One Shot Range',
            range: 'Range',
            untillEnd: 'Fino Esaurimento'
          }

          return useTermsMapper[value]
        }
      },
      'Barcode': 'barcode.code',
    }
  }
}

export default new promosManagment();
