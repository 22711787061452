import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class taxesManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/taxes/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {

        reject(err)
      })
    })
  }

  create(tax) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/taxes/create', tax).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }


  update(tax) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/taxes/update', tax).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  delete(tax) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!tax.id) {
        reject('id undefined')
      }
      axios.post(apiUrl + '/payments/taxes/delete', {
        id: tax.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(taxId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/payments/taxes/get', { id: taxId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
  
  fields() {
    return Promise.resolve([
      { text: 'Descrizione', value: 'description' },
      { text: 'Valore', value: 'value' },
      { text: 'Data Creazione', value: 'createdAt',type: "datetime"  },
    ])
  }
}

export default new taxesManagment();
