<template>
  <div style="max-height: 100%; overflow: auto;">
    <v-list 
      nav 
      dense 
      color="transparent" 
      v-if="!!items && items.length > 0"
      :disabled="disabled"
    >
      <v-list-item
        v-for="(item, index) in items" 
        :key="item.name" 
        @click="$emit('item-click', item, index, level)"
        :input-value="index == selectedIndex"
        :style="{
          'background-color': getItemColor(item),
          'color': getItemTextColor(item) + ' !important'
        }"
      >
        <v-list-item-content>
          <v-tooltip :open-delay="tooltipDelay" bottom :disabled="hideTooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-text="getItemTitle(item)" v-bind="attrs" v-on="on">
              </v-list-item-title>
            </template>
            <span> {{getItemDescription(item)}} </span>
          </v-tooltip>
          <v-list-item-subtitle 
            v-text="getItemDescription(item)" 
            class="font-weight-light"
            :style="{
              'color': getItemTextColor(item) + ' !important'
            }"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="!!item[itemChildren]" class="ma-0">
          <slot name="parent-action" v-bind="{item}">
            <v-icon>mdi-arrow-right</v-icon>
          </slot>
        </v-list-item-action>
        <v-list-item-action v-else-if="!item[itemChildren]" class="ma-0">
          <slot name="final-action" v-bind="{item}" >
          </slot>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div v-else-if="!!items && items.length == 0" class="text-caption text-center font-weight-light mt-2">
      Nessun elemento da mostrare
    </div>
  </div>
</template>

<script>
import colorConstrast from "@/mixins/common/colorContrast"

export default {
  name: "HierarchyItem",
  mixins: [colorConstrast],
  data: function() {
    return {}
  },
  mounted: function() {
  },
  props: {
    value: {
      type: Object,
      default: undefined
    },
    selectedIndex: {
      type: Number,
      default: undefined
    },
    items: {
      type: Array,
    },
    itemChildren: {
      type: String,
      default: 'children'
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemTitle: {
      type: String | Function,
      default: 'title'
    },
    itemDescription: {
      type: String | Function,
      default: 'description'
    },
    itemColor: {
      type: String | Function,
      default: 'color'
    },
    level: {
      type: Number,
    },
    adaptiveTextColor: {
      type: Boolean,
      default: true
    },
    hideTooltip: {
      type: Boolean,
      default: false
    },
    tooltipDelay: {
      type: String | Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleTimedTooltip(item) {
      item.tooltip = true
    },
    getItemTitle(item) {
      if(typeof this.itemTitle == 'function') {
        return this.itemTitle(item)
      } else {
        return item[this.itemTitle]
      }
    },
    getItemDescription(item) {
      if(typeof this.itemDescription == 'function') {
        return this.itemDescription(item)
      } else {
        return item[this.itemDescription]
      }
    },
    getItemColor(item) {
      if(!this.itemColor) {
        return undefined
      } else if(typeof this.itemColor === 'function') {
        return this.itemColor(item)
      } else {
        return item[this.itemColor]
      }
    },
    getItemTextColor(item) {
      const itemBackground = this.getItemColor(item)
      return this.adaptiveTextColor && !!itemBackground ? this.blackOrWhiteContrast(itemBackground) : '#000000'
    }
  },
  watch: {
    value(newVal) {
    },
  }
}
</script>

<style>

</style>