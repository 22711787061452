import EventsHandler from '@/services/common/eventsHandler'

class BarcodeScannerEmulator extends EventsHandler {
  constructor() {
    super()

    this._lastKeypressEvents = []
    this._listenForLasts = []
    this._keypressHandler = undefined
    this._removeBarcodeInputEnabled = false
    this._timeOut = undefined
  }

  startListen() {
    if(!this._keypressHandler) {
      this._keypressHandler = (e) => {
        if (e.keyCode != 13)
          this._addAndCheckEvent(e)
      }
      this._keypressHandler = this._keypressHandler.bind(this)

      window.addEventListener('keypress', this._keypressHandler)
    }
  }

  stopListen() {
    this._listenForLasts = []
    this._lastKeypressEvents = []
    window.removeEventListener('keypress', this._keypressHandler)
    this._keypressHandler = undefined
    this._timeOut = undefined
  }

  enableCharsRemoving() {
    this._removeBarcodeInputEnabled = true
  }

  disableCharsRemoving() {
    this._removeBarcodeInputEnabled = false
  }

  _addAndCheckEvent(event) {
    this._lastKeypressEvents.push({
      timeStamp: event.timeStamp,
      key: event.key,
      target: event.target
    })

    const maxDifferenceTimestamp = this._getMaxTimeStampDifference()
      if (maxDifferenceTimestamp > 0 && maxDifferenceTimestamp < 20){
      clearTimeout(this._timeOut)
    } 

    this._timeOut= setTimeout(() => {
      this._handleBarcodeEmitted()
    }, 100);
  }

  _getMaxTimeStampDifference() {
    let max = 0;
    let previousTimeStamp;
    for (let i = 0; i < this._lastKeypressEvents.length; i++) {
      if (!previousTimeStamp) previousTimeStamp = this._lastKeypressEvents[i].timeStamp
      else {
        const currentDifference = this._lastKeypressEvents[i].timeStamp - previousTimeStamp
        if (currentDifference > max) max = currentDifference
        previousTimeStamp = this._lastKeypressEvents[i].timeStamp
      }
    }
    return max
  }
  _handleBarcodeEmitted() {
    if(this._lastKeypressEvents.length == 0 || this._lastKeypressEvents.length < 4 ){
      this._timeOut = undefined
      this._lastKeypressEvents = []
      return
    }
    const barcode = this._lastKeypressEvents.map(el => el.key).reduce((p, c) => p += c)
    this._executeAsyncCallbacksOf('scan', {barcode})
    this._timeOut = undefined
    this._lastKeypressEvents = []

    if (this._removeBarcodeInputEnabled) {
      let target = lastEvents[0].target
      if(target.tagName.toUpperCase() == 'INPUT') {
        setTimeout(() => {
          target.value = target.value.substring(0, target.value.length - barcode.length)
          var event = new Event('input', {
            'bubbles': true,
            'cancelable': true
          });
          target.dispatchEvent(event);
        }, 100);
      }
    }
  }
}

export default new BarcodeScannerEmulator()