<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :items="departmentsList"
      filled
      :item-text="(entry) => entry.name"
      item-value="id"
      hide-details="auto"
      :return-object="returnObject"
      :loading="loading"
      :prepend-icon="prependIcon"
      :append-outer-icon="appendOuterIcon"
      :clearable="clearable"
      :label="label"
      :readonly="readonly"
      @input="handleInput"
      :multiple="multiple"
    ></v-autocomplete>
  </div>
</template>

<script>
import departmentService from '@/services/departments/departments.service.js'

export default {
  name: "DepartmentsAutocomplete",
  components: {
  },
  data: function() {
    return {
      selected: this.returnObject ? {...this.value} : this.value,
      departmentsList: undefined, 
    }
  },
  props: {
    value: {},
    returnObject: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    appendOuterIcon: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean, 
      default: true
    },
    label: {
      type: String,
      default: 'Seleziona Dipartimento ...'
    },
    readonly:{
      type: Boolean,
      default: false,
    },
    multiple:{
      type: Boolean,
      default: false,
    }
  },
  mounted: function(){
    this.loadDepartments()
  },
  methods: {
    handleInput() {
      this.$emit('input', this.selected)
    },
    async loadDepartments() {
      try {
        const departmentsRows = await departmentService.list()
        this.departmentsList = departmentsRows.rows
      } catch (error) {
        console.log(error)
      }
    },
    handleFormSubmit(supplier) {
      if(this.returnObject) {
        this.selected = {...supplier}
      } else {
        this.selected = supplier.id
      }
      this.$emit('input', this.selected)
    },
    handleEditFormSubmit(supplier) {
      if(this.returnObject) {
        this.selected = {...supplier}
      } else {
        this.selected = supplier.id
      }
      this.$emit('input', this.selected)
      this.supplierEditFormDialog = false
    },
  },
  computed: {
    hasToOpenNewForm() {
      return this.autoDetectNewSupplier && this.autoDetectNewSupplierSetting
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal
    }
  },
}
</script>

<style>

</style>e6