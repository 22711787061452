import EventsHandler from '@/services/common/eventsHandler'

class SnackBarer extends EventsHandler {
  constructor() {
    super()
  }

  call(params) {
    if (!params.message) {
      return
    } else {
      if(!params) params = {}
      if (!params.timeout) params.timeout = 1800
      this._executeAsyncCallbacksOf('call', params)
    }
  }
}

export default new SnackBarer()