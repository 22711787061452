import barcodeScannerDevice from "./barcodeScanner.device"

export default {
  install: (Vue, options) => {
    Vue.mixin({
      mounted: function () {
        if (!!this.$options.barcode && !!this.$options.barcode.scan) {
          barcodeScannerDevice.startListen()
          //barcodeScannerDevice.enableCharsRemoving()

          let scanHandler = this.$options.barcode.scan
          scanHandler = scanHandler.bind(this)
          let handler = ({ barcode }) => {
            scanHandler(barcode)
          }

          handler = handler.bind(this)
          let testHandler = ({ barcode }) => {
            handler({ barcode })
          }
          barcodeScannerDevice.on('scan', testHandler)
          this.$once('hook:beforeDestroy', function () {
            barcodeScannerDevice.off('scan', testHandler)
          })
        }
      },
      beforeDestroy: function () {
        if (!!this.$options.barcode) {
          if(this.$options.name != 'OperatorPresenceBadgeDialog')
              barcodeScannerDevice.stopListen()
        }
      }
    })
  }
}