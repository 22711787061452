<template>
  <div 
    id="chart"
  >
    <TitleWithBackButton title="Confronta Periodo Commerciale" back-route="Analytics" icon="mdi-graphql"></TitleWithBackButton>
    <div class="d-flex align-start ml-3 mt-3 flex-wrap">
      <v-select
        v-model="selectedYears"
        label="Seleziona Anni"
        :style="{
          'max-width': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
            ? '45dvw' : '300px'
        }"
        :items="years"
        filled
        rounded
        dense
        multiple
        :rules="[v => (!!v && v.length > 0) || 'Selezionare almeno un anno', v => v.length <= 3 || 'Selezionare al massimo 3 anni']"
        @update:error="(err) => { this.error = err }"
      >
      </v-select>
      <TagsCategoriesAutocomplete
        class="pl-2"
        label="Scelta Tag"
        style="max-width: 300px;"
        v-model="selectedTagCategories"
        return-object
        :prepend-icon="null"
        :filters="{ typeCost: false, excludeInBalance: false, notGeneral: true }"
        @input="handleTagCategoriesChange"
      >

      </TagsCategoriesAutocomplete>
      <v-btn
        class="mx-2"
        text
        @click="calculate"
        :loading="loading"
        :disabled="!!error"
      >
        Calcola
      </v-btn>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn
        class="mx-2"
        text
        @click="print"
        :loading="loading"
      >
        <v-icon> mdi-printer </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        icon
        large
        :disabled="loading"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!ghost ? 'red' : ''"
      >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>
    <div v-if="loading" class="text-center mt-2">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <v-row style="width: 100%; padding-left: 20px;  justify-content: center;" class="mt-5 pt-5">
        <v-col
          cols="12"
          sm="12"
          md="6" 
          lg="4"
          xl="4"
          v-for="(year, idx) in currentYears" 
          :key="idx" 
        >
          <v-row>
            <v-col class="text-center">
              <h3 class="text-h5">{{ year }}</h3>
            </v-col>
          </v-row>
          <v-row style="width: 100%;" class="d-flex justify-center">
            <v-col cols="12" class="d-flex justify-center">
              <PieChart
                :chart-data="generalChartData[year]"
                :styles="{
                  height: '300px',
                  width: '300px'
                }"
                :options="{
                  mainAspectRatio: true,
                  responsive: true,
                  legend: {
                    display: false
                  },
                  showTooltips: false,
                }"
              >
              </PieChart>
            </v-col>
          </v-row>
          <v-row 
            :style="{
              'width': '100%',
            }" 
            class="d-flex justify-center"
          >
            <v-col cols="12">
              <TypeDataTable
                class="mb-10 balance-table"
                :show-tooltips="true"
                fixed-headers
                headersColor="transparent"
                style="background-color: transparent;"
                :tooltip-delay="1500"
                :headers="[
                  { text: '', value: 'color', type: 'color' },
                  { text: '', value: 'name' },
                  { text: 'Totale', value: 'total', type: 'price' },
                  { text: '%', value: 'percentage' },
                  { text: 'N° Tran.', value: 'count' },
                  { text: 'Fiche Media', value: 'avg', type: 'price' },
                  { text: 'N° Clienti', value: 'customersCount'}
                ]"
                :items="generalTableData[year]"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :disablePagination="true"
              >
              </TypeDataTable>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="width: 100%;" class="mt-5 d-flex justify-center">
        <v-btn
          dense
          borderless
          text
          color="primary"
          depressed
          @click="() => { operatorDialog = true }"
        >
          <v-icon>mdi-account-hard-hat-outline</v-icon>
          Confronta Operatore
        </v-btn>
      </v-row>
      <v-row style="width: 100%;" class="mt-5 d-flex justify-center">
        <v-col cols="12" class="px-5">
          <TypeDataTable
            class="mb-10"
            :show-tooltips="true"
            fixed-headers
            style="background-color: transparent;"
            :tooltip-delay="1500"
            :headers="[
              { text: 'Periodo', value: 'name', type: 'custom', width: '220px', class: ['sticky-column-header', 'column-gray'], cellClass: ['sticky-column', 'column-gray'], overrideClass: true },
              { text: 'Fatturato Totale', value: 'total', type: 'custom', width: '180px' },
              { text: 'N° Clienti', value: 'countCustomers', type: 'custom-type', customType: 'customTypeNumber' },
              { text: 'Uomini', value: 'countCustomersMale', type: 'custom-type', customType: 'customTypeText' },
              { text: 'Donne', value: 'countCustomersFemale', type: 'custom-type', customType: 'customTypeText' },
              { text: '% Clienti Acq.', value: 'percCustomersItems', type: 'custom-type', customType: 'customTypeText' },
              { text: '% Rivendita', value: 'percTotalItems', type: 'custom-type', customType: 'customTypeText' },
              { text: 'N° P.Cassa', value: 'countTransactions', type: 'custom-type', customType: 'customTypeText' },
              { text: 'Nuovi Clienti', value: 'countNewCustomers', type: 'custom-type', customType: 'customTypeText' },
              ...this.serviceTypesRows
            ]"
            :items="detailTableData"
            :show-select="false"
            :select-on-row-click="false"
            :show-actions="false"
            :disablePagination="true"
          >
            <template v-slot:custom-total="{item}">
              <v-chip v-if="item.type != 'delta'" style="width: 80%;" class="d-flex justify-center">
                {{ !!item.total ? Number(item.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }} 
              </v-chip>
              <v-chip v-else :color="item.total < 0 ? 'error' : 'success'" style="width: 80%;" class="d-flex justify-center">
                {{ !!item.total ? Number(item.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }} 
              </v-chip>
            </template>3
            <template v-slot:custom-name="{item}">
              <span :class="{
                'warning--text': item.type == 'delta',
                }"
              >
                {{ item.name }}
              </span>
            </template>
            <template v-slot:custom-customTypeText="{value}">
              <div class="d-flex justify-center text-center">
                {{ value }}
              </div>
            </template>
            <template v-slot:custom-customTypePerc="{value}">
              <div class="d-flex justify-center text-center">
                {{ !!value ? Number(value).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%' : '0,00%' }}
              </div>
            </template>
            <template v-slot:custom-customTypeNumber="{value}">
              <div class="d-flex justify-center text-center">
                {{ !!value ? Number(value).toLocaleString('it-IT') : (0).toLocaleString('it-IT') }}
              </div>
            </template>
          </TypeDataTable>
        </v-col>
      </v-row>
    </div>

    <StandardDialog
      v-model="operatorDialog"
      title="Confronta Operatore"
      :dialog-height="null"
      dialog-max-width="1400px"
    >
      <v-col
        id="chart-operator"
      >
        <v-row class="pt-5 d-flex justify-space-between">
          <OperatorsAutocomplete
            v-model="operator"
            :multiple="false"
            :filled="false"
            :return-object="true"
            :filters="{ state: 'valid' }"
            dense
            label="Operatore"
          >
          </OperatorsAutocomplete>

          <div
            style="display: flex; gap: 20px"
          >
            <v-btn
              class="btn-export"
              @click="exportToExcelOperator"
              :loading="loading || operatorLoading"
              :disabled="!!error || (!!operator && !operator.id) || !operator"
            ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
            <v-btn
              class="mx-2"
              text
              @click="printOperator"
              :loading="loading || operatorLoading"
              :disabled="!!error || (!!operator && !operator.id) || !operator"
            >
              <v-icon> mdi-printer </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              text
              @click="calculateOperator"
              :loading="loading || operatorLoading"
              :disabled="!!error || (!!operator && !operator.id) || !operator"
            >
              Calcola
            </v-btn>
          </div>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-window
            v-model="operatorTableType"
          >
            <v-window-item
              style="overflow-y: auto; scroll-snap-type: y mandatory;"
            >
              <span
                class="title mb-2 mt-1 d-flex justify-center align-center"
              >
                Analisi Comparativa Fatturato
              </span>
              <div class="operator-graph-container">
                <GroupBasedChart 
                  no-data-text="Nessun dato"
                  height="200px" 
                  width="100%"
                  type="bar"
                  transparent
                  :point-colors="[]"
                  :colors="this.selectedYears.length == 2 ? this.operatorChartColors : this.operatorChartColors3"
                  :getYValue="
                    (object) => {
                      return object[`total`];
                    }
                  "
                  :getXValue="
                    (object) => {
                      return this.monthNameMapping[object[`month`]] + ' ' + object[`year`];
                    }
                  "
                  :tooltip-label-modifier="
                    (tooltipLabel) => {
                      return Number(tooltipLabel.yLabel).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
                    }
                  "
                  :x-axes-modifier="xAxesModifier"
                  :data="[operatorChartData]"
                >
                </GroupBasedChart>
                <TypeDataTable
                  class="mb-10 balance-table"
                  :show-tooltips="true"
                  fixed-headers
                  headersColor="transparent"
                  style="background-color: transparent;"
                  :tooltip-delay="1500"
                  :headers="[
                    { text: '', value: 'year' },
                    { text: 'Gennaio', value: 'total1', type: 'price' },
                    { text: 'Febbraio', value: 'total2', type: 'price' },
                    { text: 'Marzo', value: 'total3', type: 'price' },
                    { text: 'Aprile', value: 'total4', type: 'price' },
                    { text: 'Maggio', value: 'total5', type: 'price' },
                    { text: 'Giugno', value: 'total6', type: 'price' },
                    { text: 'Luglio', value: 'total7', type: 'price' },
                    { text: 'Agosto', value: 'total8', type: 'price' },
                    { text: 'Settembre', value: 'total9', type: 'price' },
                    { text: 'Ottobre', value: 'total10', type: 'price' },
                    { text: 'Novembre', value: 'total11', type: 'price' },
                    { text: 'Dicembre', value: 'total12', type: 'price' },
                  ]"
                  :items="operatorData"
                  :show-select="false"
                  :select-on-row-click="false"
                  :show-actions="false"
                  :disablePagination="true"
                >
                </TypeDataTable>
              </div>
              <span
                class="title mb-2 d-flex justify-center align-center"
              >
                Analisi Comparativa Fatturato Prodotti
              </span>
              <div class="operator-graph-container">
                <GroupBasedChart 
                  no-data-text="Nessun dato"
                  height="200px" 
                  width="100%"
                  type="bar"
                  transparent
                  :point-colors="[]"
                  :colors="operatorChartColors"
                  :getYValue="
                    (object) => {
                      return object[`totalItems`];
                    }
                  "
                  :getXValue="
                    (object) => {
                      return this.monthNameMapping[object[`month`]] + ' ' + object[`year`];
                    }
                  "
                  :tooltip-label-modifier="
                    (tooltipLabel) => {
                      return Number(tooltipLabel.yLabel).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
                    }
                  "
                  :x-axes-modifier="xAxesModifier"
                  :data="[operatorChartData]"
                >
                </GroupBasedChart>
                <TypeDataTable
                  class="mb-10 balance-table"
                  :show-tooltips="true"
                  fixed-headers
                  headersColor="transparent"
                  style="background-color: transparent;"
                  :tooltip-delay="1500"
                  :headers="[
                    { text: '', value: 'year' },
                    { text: 'Gennaio', value: 'totalItems1', type: 'price' },
                    { text:'Febbraio', value: 'totalItems2', type: 'price' },
                    { text: 'Marzo', value: 'totalItems3', type: 'price' },
                    { text: 'Aprile', value: 'totalItems4', type: 'price' },
                    { text: 'Maggio', value: 'totalItems5', type: 'price' },
                    { text: 'Giugno', value: 'totalItems6', type: 'price' },
                    { text: 'Luglio', value: 'totalItems7', type: 'price' },
                    { text: 'Agosto', value: 'totalItems8', type: 'price' },
                    { text: 'Settembre', value: 'totalItems9', type: 'price' },
                    { text: 'Ottobre', value: 'totalItems10', type: 'price' },
                    { text: 'Novembre', value: 'totalItems11', type: 'price' },
                    { text: 'Dicembre', value: 'totalItems12', type: 'price' },
                  ]"
                  :items="operatorData"
                  :show-select="false"
                  :select-on-row-click="false"
                  :show-actions="false"
                  :disablePagination="true"
                >
                </TypeDataTable>
              </div>
              <span 
                class="title mb-2 d-flex justify-center align-center"
              >
                Analisi Comparativa Passaggi Cassa
              </span>
              <div class="operator-graph-container">
                <GroupBasedChart 
                  no-data-text="Nessun dato"
                  height="200px" 
                  width="100%"
                  type="bar"
                  transparent
                  :point-colors="[]"
                  :colors="operatorChartColors"
                  :getYValue="
                    (object) => {
                      return object[`countTransactions`];
                    }
                  "
                  :getXValue="
                    (object) => {
                      return this.monthNameMapping[object[`month`]] + ' ' + object[`year`];
                    }
                  "
                  :tooltip-label-modifier="
                    (tooltipLabel) => {
                      return 'Quantità: ' + tooltipLabel.yLabel;
                    }
                  "
                  :x-axes-modifier="xAxesModifier"
                  :data="[operatorChartData]"
                >
                </GroupBasedChart>
                <TypeDataTable
                  class="mb-10 balance-table"
                  :show-tooltips="true"
                  fixed-headers
                  headersColor="transparent"
                  style="background-color: transparent;"
                  :tooltip-delay="1500"
                  :headers="[
                    { text: '', value: 'year' },
                    { text: 'Gennaio', value: 'countTransactions1' },
                    { text: 'Febbraio', value: 'countTransactions2' },
                    { text: 'Marzo', value: 'countTransactions3' },
                    { text: 'Aprile', value: 'countTransactions4' },
                    { text: 'Maggio', value: 'countTransactions5' },
                    { text: 'Giugno', value: 'countTransactions6' },
                    { text: 'Luglio', value: 'countTransactions7' },
                    { text: 'Agosto', value: 'countTransactions8' },
                    { text: 'Settembre', value: 'countTransactions9' },
                    { text: 'Ottobre', value: 'countTransactions10' },
                    { text: 'Novembre', value: 'countTransactions11' },
                    { text: 'Dicembre', value: 'countTransactions12' },
                  ]"
                  :items="operatorData"
                  :show-select="false"
                  :select-on-row-click="false"
                  :show-actions="false"
                  :disablePagination="true"
                >
                </TypeDataTable>
              </div>
            </v-window-item>
          </v-window>
        </v-row>
      </v-col>
      <template v-slot:footer-actions>
        <v-btn text color="error" v-on:click="handleCloseOperatorDialog">
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>

    <StandardDialog
      v-model="dialogBarcode"
      title="Area Riservata Sviluppatori"
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput
        v-model="operatorBarcode"
        scan-your-barcode-message="Immettere Codice"
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import balanceService from '@/services/balance/balance.service.js'
import serviceTypesCategoriesService from '@/services/serviceTypesCategories/serviceTypesCategories.service.js'
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js"
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue"
import FlowyGraph from "@/components/areas/analytics/FlowyGraph.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import operatorService from '@/services/operators/operators.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import PieChart from "@/components/charts/PieChart";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import _ from "lodash";
import html2canvas from '@/assets/js/html2canvas.min.js'
import * as XLSX from "xlsx";

export default {
  name: "BalanceByYears",
  components: {
    RangeDateSelector,
    TitleWithBackButton,
    TagsCategoriesAutocomplete,
    FlowyGraph,
    TypeDataTable,
    StandardDialog,
    ManualBarcodeInput,
    PieChart,
    GroupBasedChart,
    OperatorsAutocomplete
  },
  data: function () {
    return {
      loading: false,
      ghost: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      error: false,
      generalChartData: {},
      generalTableData: {},
      detailTableData: [],
      colorSet: ['rgba(138,119,226,0.6)', 'rgba(99,143,239,0.6)', 'rgba(63,162,241,0.6)', 'rgba(54,178,235,0.6)', 'rgba(80,191,225,0.6)'],
      years: [],
      selectedYears: [],
      currentYears: [],
      selectedTagCategories: [],
      serviceTypesRows: [],
      operatorDialog: false,
      operatorId: undefined,
      operator: {},
      operatorTableType: 0,
      operatorLoading: false,
      operatorData: [],
      operatorChartData: {},
      operatorChartColors: [
        '#ff000099', '#ff000099',
        '#ff800099', '#ff800099',
        '#ffff0099', '#ffff0099',
        '#80ff0099', '#80ff0099',
        '#00ff0099', '#00ff0099',
        '#00ff8099', '#00ff8099',
        '#00ffff99', '#00ffff99',
        '#0080ff99', '#0080ff99',
        '#0000ff99', '#0000ff99',
        '#8000ff99', '#8000ff99',
        '#ff00ff99', '#ff00ff99',
        '#ff008099', '#ff008099',
      ],
      operatorChartColors3: [
        '#ff000099', '#ff000099', '#ff000099',
        '#ff800099', '#ff800099', '#ff800099',
        '#ffff0099', '#ffff0099', '#ffff0099',
        '#80ff0099', '#80ff0099', '#80ff0099',
        '#00ff0099', '#00ff0099', '#00ff0099',
        '#00ff8099', '#00ff8099', '#00ff8099',
        '#00ffff99', '#00ffff99', '#00ffff99',
        '#0080ff99', '#0080ff99', '#0080ff99',
        '#0000ff99', '#0000ff99', '#0000ff99',
        '#8000ff99', '#8000ff99', '#8000ff99',
        '#ff00ff99', '#ff00ff99', '#ff00ff99',
        '#ff008099', '#ff008099', '#ff008099',
      ],
      monthNameMapping:{
        1: 'Gen',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'Mag',
        6: 'Giu',
        7: 'Lug',
        8: 'Ago',
        9: 'Set',
        10: 'Ott',
        11: 'Nov',
        12: 'Dic'
      },
      xAxesModifier: [{
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          callback: function(label) {
            if(label.length > 11) {
              return label.substring(0, 8) + '...'
            } else {
              return label
            }
          }
        },
      }],
      fileName: "",
      fileNameOperator: ""
    }
  },
  mounted: async function () {
    let today = new Date()
    let length = today.getFullYear() - 2020 + 1
    this.years = Array.from({length: length}, (v, k) => k + 2020)

    this.selectedYears = [today.getFullYear() - 1, today.getFullYear()]

    let response = await tagsCategoriesService.list(1, 4000, { 'typeCost': false, excludeInBalance: false, notGeneral: true })
    this.selectedTagCategories = response.rows.splice(0, 3)
    this.handleTagCategoriesChange(this.selectedTagCategories)

    this.generalChartData = {}
    this.generalChartData[this.selectedYears[0]] = {}
    this.generalChartData[this.selectedYears[1]] = {}

    this.calculate()
  },
  methods: {
    exportToExcel() {
      this.loading = true
      let workbook = XLSX.utils.book_new();
      //Creazione e popolamento worksheet Tabelle Anni
      for (let i = 0; i < this.selectedYears.length; i++) {
        let selectedYearData = []
        for (let j = 0; j < this.generalTableData[this.selectedYears[i]].length; j++) {
          this.generalTableData[this.selectedYears[i]][j].year = this.selectedYears[i]
          selectedYearData.push(this.generalTableData[this.selectedYears[i]][j])
        }
        let ws = XLSX.utils.aoa_to_sheet([[]]);
        XLSX.utils.sheet_add_aoa(ws, [
          [
            '',
            'Totale',
            '%',
            'N° Tran.',
            'Fiche Medie',
            'N° Clienti'
          ],
          ...selectedYearData.map((el) => {
            return [
              el.name,
              Number(Number(el.total).toFixed(2)),
              el.percentage,
              el.count,
              !!el.avg ? Number(Number(el.avg).toFixed(2)) + " €" : "0,00 €",
              el.customersCount
            ]
          })
        ])
        XLSX.utils.book_append_sheet(workbook, ws, this.selectedYears[i].toString());
      }
      let servicesTypesHeaders = []
      let servicesTypesValues = []
      for (let i = 0; i < this.serviceTypesRows.length; i++) {
        servicesTypesHeaders.push(this.serviceTypesRows[i].text)        
        servicesTypesValues.push(this.serviceTypesRows[i].value)        
      }

      let ws1 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws1, [
        [
          'Periodo',
          'Fatturato Totale',
          'N° Clienti',
          'Uomini',
          'Donne',
          '% Clienti Acq.',
          '% Rivendita',
          'N° P.Cassa',
          'Nuovi Clienti',
          ...servicesTypesHeaders
        ],
        ...this.detailTableData.map((el) => {
          return [
            el.name,
            Number(Number(el.total).toFixed(2)),
            el.countCustomers,
            el.countCustomersMale,
            el.countCustomersFemale,
            el.percCustomersItems,
            el.percTotalItems,
            el.countTransactions,
            el.countNewCustomers,
            ...servicesTypesValues.map((e) => {
              return this.serviceTypesRows.find((d) => d.value == e).value
            }).map((key) => !!el[key] ? Number(Number(el[key]).toFixed(2)) + " %" : "0,00 %")
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws1, "Dettaglio confronto anni");
      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
      this.loading = false
    },
    exportToExcelOperator() {
      this.loading = true
      let workbook = XLSX.utils.book_new();
      this.fileNameOperator = "Comparazione-Annuale-" + this.operator.fullname + "-"
      for (let i = 0; i < this.selectedYears.length; i++) {
        if (!(i  === (this.selectedYears.length - 1)))
          this.fileNameOperator += this.selectedYears[i] + "-"
        else
          this.fileNameOperator += this.selectedYears[i]
      }
      this.fileNameOperator += ".xls"
      let ws1 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws1, [
        ["Incassi"],
        [],
        [
          '',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ],
        ...this.operatorData.map((el) => {
          return [
            el.year + "",
            Number(Number(el.total1 || 0).toFixed(2)),
            Number(Number(el.total2 || 0).toFixed(2)),
            Number(Number(el.total3 || 0).toFixed(2)),
            Number(Number(el.total4 || 0).toFixed(2)),
            Number(Number(el.total5 || 0).toFixed(2)),
            Number(Number(el.total6 || 0).toFixed(2)),
            Number(Number(el.total7 || 0).toFixed(2)),
            Number(Number(el.total8 || 0).toFixed(2)),
            Number(Number(el.total9 || 0).toFixed(2)),
            Number(Number(el.total10 || 0).toFixed(2)),
            Number(Number(el.total11 || 0).toFixed(2)),
            Number(Number(el.total12 || 0).toFixed(2)),
          ]
        }),
        [],
        ["Prodotti"],
        [],
        [
          '',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ],
        ...this.operatorData.map((el) => {
          return [
            el.year + "",
            Number(Number(el.totalItems1 || 0).toFixed(2)),
            Number(Number(el.totalItems2 || 0).toFixed(2)),
            Number(Number(el.totalItems3 || 0).toFixed(2)),
            Number(Number(el.totalItems4 || 0).toFixed(2)),
            Number(Number(el.totalItems5 || 0).toFixed(2)),
            Number(Number(el.totalItems6 || 0).toFixed(2)),
            Number(Number(el.totalItems7 || 0).toFixed(2)),
            Number(Number(el.totalItems8 || 0).toFixed(2)),
            Number(Number(el.totalItems9 || 0).toFixed(2)),
            Number(Number(el.totalItems10 || 0).toFixed(2)),
            Number(Number(el.totalItems11 || 0).toFixed(2)),
            Number(Number(el.totalItems12 || 0).toFixed(2)),
          ]
        }),
        [],
        ["P. Cassa"],
        [],
        [
          '',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ],
        ...this.operatorData.map((el) => {
          return [
            el.year + "",
            Number(Number(el.countTransactions1 || 0).toFixed(2)),
            Number(Number(el.countTransactions2 || 0).toFixed(2)),
            Number(Number(el.countTransactions3 || 0).toFixed(2)),
            Number(Number(el.countTransactions4 || 0).toFixed(2)),
            Number(Number(el.countTransactions5 || 0).toFixed(2)),
            Number(Number(el.countTransactions6 || 0).toFixed(2)),
            Number(Number(el.countTransactions7 || 0).toFixed(2)),
            Number(Number(el.countTransactions8 || 0).toFixed(2)),
            Number(Number(el.countTransactions9 || 0).toFixed(2)),
            Number(Number(el.countTransactions10 || 0).toFixed(2)),
            Number(Number(el.countTransactions11 || 0).toFixed(2)),
            Number(Number(el.countTransactions12 || 0).toFixed(2)),
          ]
        }),
      ])
      XLSX.utils.book_append_sheet(workbook, ws1, "Confronta Operatore");
      XLSX.writeFile(workbook, this.fileNameOperator, { type: 'file' });
      this.loading = false
    },
    async handleTagCategoriesChange(val) {
      let names = val.map(el => el.name)
      let serviceTypes = []
      for(let name of names){
        let res = await serviceTypesCategoriesService.list(undefined, undefined, [{ type: 'string', operator: 'like', field: 'name', value: name  }])
        for(let category of res.rows)
          serviceTypes.push(...category.serviceTypes.map(el => {
            return {
              ...el,
              categoryId: category.id,
            }
          }))
      }

      this.serviceTypesRows = serviceTypes
        .sort((a,b)=> {
          return a.categoryId - b.categoryId || a.id - b.id
        })
        .map((el, idx) => {
          return {
            text: el.name,
            value: 'percentage' + el.id,
            type: 'custom-type', 
            customType: 'customTypePerc'
          }
        })
    },
    async calculate() {
      this.loading = true

      this.generalChartData = {}
      this.generalTableData = {}
      this.detailTableData = []
      let promises = []

      this.currentYears = [...this.selectedYears]
      this.currentYears.sort((a, b) => a - b)


      this.fileName = "Confronta_Periodi_Commerciali-"
      for (let i = 0; i < this.selectedYears.length; i++) {
        if (!(i  === (this.selectedYears.length - 1)))
          this.fileName += this.selectedYears[i] + "-"
        else
          this.fileName += this.selectedYears[i]
      }
      this.fileName += ".xls"

      for(let year of this.selectedYears){
        const startDate = new Date(year, 0, 1, 0, 0, 0, 0)
        const endDate = new Date(year, 11, 31, 23, 59, 59, 999)

        let ranges = Array.from({length: 12}, (v, k) => {
          return {
            from: new Date(year, k, 1, 0, 0, 0, 0),
            to: new Date(year, k + 1, 0, 23, 59, 59, 999),
            name: k + 1
          }
        })

        promises.push(balanceService.balanceByYear({
          year: year,
          from: startDate,
          to: endDate,
          tagCategoriesId: this.selectedTagCategories.map(el => el.id),
          ranges: ranges,
          ghost: this.ghost
        }).then(async results => {
          this.generalTableData[year] = []

          let chartData = {
            labels: [],
            datasets: [
              {
                label: 'Label',
                pointBackgroundColor: 'black',
                borderWidth: 2,
                pointBorderColor: 'black',
                data: [],
                backgroundColor: [],
              }
            ]
          }

          results.total.total -= _.sumBy(results["paymentDebitDetails"], (el) => {
            return Number(el.total) || 0
          })

          let counter = 0
          results.productAndServiceTotal.map((productAndService) => {
            if(!productAndService.name)
              productAndService.name = 'Non Categorizzato'

            productAndService.total = Number(productAndService.total).toFixed(2)

            chartData.labels.push(productAndService.name)
            chartData.datasets[0].data.push((Number(productAndService.total)).toFixed(2))
            chartData.datasets[0].backgroundColor.push(this.colorSet[counter % this.colorSet.length])
            this.generalTableData[year].push({
              color: this.colorSet[counter % this.colorSet.length],
              name: 'Di cui ' + productAndService.name,
              total: Number(productAndService.total),
              percentage: ((Number(productAndService.total) / results.total.total) * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              count: Number(productAndService.countTransactions),
              avg: Number(productAndService.total) / Number(productAndService.countTransactions),
              customersCount: Number(productAndService.countCustomers) > 0 ? Number(productAndService.countCustomers) : 0,
            })
            counter++
          })

          chartData.labels.push('Commerciale')
          chartData.datasets[0].data.push(Number(results.invoiceTotal.total) + Number(results.otherCashesTotal.total))
          chartData.datasets[0].backgroundColor.push(this.colorSet[counter % this.colorSet.length])
          this.generalTableData[year].push({
            color: this.colorSet[counter % this.colorSet.length],
            name: 'Commerciale',
            total: Number(results.invoiceTotal.total) + Number(results.otherCashesTotal.total),
            percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            count: Number(results.invoiceTotal.totalInvoices) + Number(results.otherCashesTotal.totalOtherCashes),
            customersCount: '-',
          })
            
          this.generalTableData[year].unshift({
            name: 'Totale Generale',
            total: results.total.total,
            percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            count: Number(results.total.countTransactions) > 0 ? Number(results.total.countTransactions) : 0,
            avg: Number(results.total.total) / Number(results.total.countTransactions),
            customersCount: Number(results.total.countCustomers) > 0 ? Number(results.total.countCustomers) : 0,
          })

          this.generalChartData[year] = chartData

          for(let i = 0; i < Object.keys(results.details.results).length; i++) {
            let month = Object.keys(results.details.results)[i]
            let details = results.details.results[month]
            let serviceTypes = results.details.topServiceTypes[month] || []

            let percCustomersItemsValue = !!Number(details.countCustomers) ? (Number(details.countCustomersItems) / Number(details.countCustomers) * 100) : 0
            let percTotalItemsValue = !!Number(details.total) ? (Number(details.totalItems) / Number(details.total) * 100) : 0

            this.detailTableData.push({
              name: this.monthNameMapping[month] + ' ' + year,
              month: Number(month),
              year: year,
              total: Number(details.total),
              countTransactions: Number(details.countTransactions),
              countCustomers: Number(details.countCustomers),
              countCustomersMale: Number(details.countCustomersMale),
              countCustomersFemale: Number(details.countCustomersFemale),
              percCustomersItems: percCustomersItemsValue.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              percCustomersItemsValue: percCustomersItemsValue,
              percTotalItems: percTotalItemsValue.toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              percTotalItemsValue: percTotalItemsValue,
              countNewCustomers: Number(details.countNewCustomers),
              ...serviceTypes.reduce((obj, item, idx) => {
                if(!!item) {
                  let perc = !!Number(details.countTransactions) ? (Number(item.countPaymentTransactions) / Number(details.countTransactions) * 100) : 0
                  obj['percentage'+item.id] = perc
                }
                return obj
              }, {})
            })
          }
        }))
      }

      Promise.all(promises).then(() => {
        this.detailTableData.sort((a, b) => {
          if(a.month > b.month)
            return 1
          else if(a.month < b.month)
            return -1
          else if(a.year < b.year)
            return 1
          else if(a.year > b.year)
            return -1
          else
            return 0
        })

        let rowsToInsert = []

        for(let i = 0; i < this.detailTableData.length - 1; i++) {
          if(this.detailTableData[i].year != this.detailTableData[i + 1].year && this.detailTableData[i].month == this.detailTableData[i + 1].month)
          {
            let firstRow = this.detailTableData[i]
            let secondRow = this.detailTableData[i + 1]

            let rowToInsert = {
              name: this.monthNameMapping[firstRow.month] + ' Delta ' + firstRow.year + '-' + secondRow.year,
              month: firstRow.month,
              year: firstRow.year,
              total: firstRow.total - secondRow.total,
              countTransactions: firstRow.countTransactions - secondRow.countTransactions,
              countCustomers: firstRow.countCustomers - secondRow.countCustomers,
              countCustomersMale: firstRow.countCustomersMale - secondRow.countCustomersMale,
              countCustomersFemale: firstRow.countCustomersFemale - secondRow.countCustomersFemale,
              percCustomersItems: (firstRow.percCustomersItemsValue - secondRow.percCustomersItemsValue).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              percTotalItems: (firstRow.percTotalItemsValue - secondRow.percTotalItemsValue).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              countNewCustomers: firstRow.countNewCustomers - secondRow.countNewCustomers,
              type: 'delta'
            }

            for (let i = 0; i < 100; i++) {
              if(!!firstRow["percentage"+i] && !!secondRow["percentage"+i]) {
                rowToInsert["percentage"+i] = firstRow["percentage"+i] - secondRow["percentage"+i]
              } else if (!firstRow["percentage"+i] && !!secondRow["percentage"+i]) {
                rowToInsert["percentage"+i] = 0 - secondRow["percentage"+i]
              } else if (!!firstRow["percentage"+i] && !secondRow["percentage"+i]) {
                rowToInsert["percentage"+i] = firstRow["percentage"+i]
              }
            }

            rowsToInsert.push(rowToInsert)
          }
        }

        rowsToInsert.forEach((row) => {
          let firstRowIdx = this.detailTableData.findIndex((r) => r.month == row.month && r.year == row.year)
          
          this.detailTableData.splice(firstRowIdx + 1, 0, row)
        })

        this.loading = false
      })
    },
    handleCloseOperatorDialog() {
      this.operatorDialog = false
      this.operatorData = []
      this.operatorChartData = {}
    },
    async calculateOperator(){
      this.operatorLoading = true
      let result = []
      let chartData = {
        label: this.selectedYears.join(' - '),
        name: this.selectedYears.join(' - '),
        data: [],
      }

      for(let year of this.selectedYears) {
        const startDate = new Date(year, 0, 1, 0, 0, 0, 0)
        const endDate = new Date(year, 11, 31, 23, 59, 59, 999)

        let res = await balanceService.balanceByYearOperator({
          year: year,
          from: startDate,
          to: endDate,
          tagCategoriesId: this.selectedTagCategories.map(el => el.id),
          ghost: this.ghost,
          operatorId: this.operator.id
        })
        chartData.data = [...chartData.data, ...res]

        let month1 = res.find(el => el.month == 1)
        let month2 = res.find(el => el.month == 2)
        let month3 = res.find(el => el.month == 3)
        let month4 = res.find(el => el.month == 4)
        let month5 = res.find(el => el.month == 5)
        let month6 = res.find(el => el.month == 6)
        let month7 = res.find(el => el.month == 7)
        let month8 = res.find(el => el.month == 8)
        let month9 = res.find(el => el.month == 9)
        let month10 = res.find(el => el.month == 10)
        let month11 = res.find(el => el.month == 11)
        let month12 = res.find(el => el.month == 12)

        result.push({
          year: year,
          total1: month1 ? month1.total : 0,
          totalItems1: month1 ? month1.totalItems : 0,
          countTransactions1: month1 ? month1.countTransactions : 0,
          total2: month2 ? month2.total : 0,
          totalItems2: month2 ? month2.totalItems : 0,
          countTransactions2: month2 ? month2.countTransactions : 0,
          total3: month3 ? month3.total : 0,
          totalItems3: month3 ? month3.totalItems : 0,
          countTransactions3: month3 ? month3.countTransactions : 0,
          total4: month4 ? month4.total : 0,
          totalItems4: month4 ? month4.totalItems : 0,
          countTransactions4: month4 ? month4.countTransactions : 0,
          total5: month5 ? month5.total : 0,
          totalItems5: month5 ? month5.totalItems : 0,
          countTransactions5: month5 ? month5.countTransactions : 0,
          total6: month6 ? month6.total : 0,
          totalItems6: month6 ? month6.totalItems : 0,
          countTransactions6: month6 ? month6.countTransactions : 0,
          total7: month7 ? month7.total : 0,
          totalItems7: month7 ? month7.totalItems : 0,
          countTransactions7: month7 ? month7.countTransactions : 0,
          total8: month8 ? month8.total : 0,
          totalItems8: month8 ? month8.totalItems : 0,
          countTransactions8: month8 ? month8.countTransactions : 0,
          total9: month9 ? month9.total : 0,
          totalItems9: month9 ? month9.totalItems : 0,
          countTransactions9: month9 ? month9.countTransactions : 0,
          total10: month10 ? month10.total : 0,
          totalItems10: month10 ? month10.totalItems : 0,
          countTransactions10: month10 ? month10.countTransactions : 0,
          total11: month11 ? month11.total : 0,
          totalItems11: month11 ? month11.totalItems : 0,
          countTransactions11: month11 ? month11.countTransactions : 0,
          total12: month12 ? month12.total : 0,
          totalItems12: month12 ? month12.totalItems : 0,
          countTransactions12: month12 ? month12.countTransactions : 0,
        })
      }

      result.sort((a, b) => {
        if(Number(a.year) < Number(b.year))
          return 1
        else if(Number(a.year) > Number(b.year))
          return -1
        else
          return 0
      })

      result.splice(1, 0, {
        year: 'Delta',
        total1: result[0].total1 - result[1].total1,
        totalItems1: result[0].totalItems1 - result[1].totalItems1,
        countTransactions1: result[0].countTransactions1 - result[1].countTransactions1,
        total2: result[0].total2 - result[1].total2,
        totalItems2: result[0].totalItems2 - result[1].totalItems2,
        countTransactions2: result[0].countTransactions2 - result[1].countTransactions2,
        total3: result[0].total3 - result[1].total3,
        totalItems3: result[0].totalItems3 - result[1].totalItems3,
        countTransactions3: result[0].countTransactions3 - result[1].countTransactions3,
        total4: result[0].total4 - result[1].total4,
        totalItems4: result[0].totalItems4 - result[1].totalItems4,
        countTransactions4: result[0].countTransactions4 - result[1].countTransactions4,
        total5: result[0].total5 - result[1].total5,
        totalItems5: result[0].totalItems5 - result[1].totalItems5,
        countTransactions5: result[0].countTransactions5 - result[1].countTransactions5,
        total6: result[0].total6 - result[1].total6,
        totalItems6: result[0].totalItems6 - result[1].totalItems6,
        countTransactions6: result[0].countTransactions6 - result[1].countTransactions6,
        total7: result[0].total7 - result[1].total7,
        totalItems7: result[0].totalItems7 - result[1].totalItems7,
        countTransactions7: result[0].countTransactions7 - result[1].countTransactions7,
        total8: result[0].total8 - result[1].total8,
        totalItems8: result[0].totalItems8 - result[1].totalItems8,
        countTransactions8: result[0].countTransactions8 - result[1].countTransactions8,
        total9: result[0].total9 - result[1].total9,
        totalItems9: result[0].totalItems9 - result[1].totalItems9,
        countTransactions9: result[0].countTransactions9 - result[1].countTransactions9,
        total10: result[0].total10 - result[1].total10,
        totalItems10: result[0].totalItems10 - result[1].totalItems10,
        countTransactions10: result[0].countTransactions10 - result[1].countTransactions10,
        total11: result[0].total11 - result[1].total11,
        totalItems11: result[0].totalItems11 - result[1].totalItems11,
        countTransactions11: result[0].countTransactions11 - result[1].countTransactions11,
        total12: result[0].total12 - result[1].total12,
        totalItems12: result[0].totalItems12 - result[1].totalItems12,
        countTransactions12: result[0].countTransactions12 - result[1].countTransactions12,
      })

      if(this.selectedYears.length == 3)
        result.splice(3, 0, {
          year: 'Delta',
          total1: result[2].total1 - result[3].total1,
          totalItems1: result[2].totalItems1 - result[3].totalItems1,
          countTransactions1: result[2].countTransactions1 - result[3].countTransactions1,
          total2: result[2].total2 - result[3].total2,
          totalItems2: result[2].totalItems2 - result[3].totalItems2,
          countTransactions2: result[2].countTransactions2 - result[3].countTransactions2,
          total3: result[2].total3 - result[3].total3,
          totalItems3: result[2].totalItems3 - result[3].totalItems3,
          countTransactions3: result[2].countTransactions3 - result[3].countTransactions3,
          total4: result[2].total4 - result[3].total4,
          totalItems4: result[2].totalItems4 - result[3].totalItems4,
          countTransactions4: result[2].countTransactions4 - result[3].countTransactions4,
          total5: result[2].total5 - result[3].total5,
          totalItems5: result[2].totalItems5 - result[3].totalItems5,
          countTransactions5: result[2].countTransactions5 - result[3].countTransactions5,
          total6: result[2].total6 - result[3].total6,
          totalItems6: result[2].totalItems6 - result[3].totalItems6,
          countTransactions6: result[2].countTransactions6 - result[3].countTransactions6,
          total7: result[2].total7 - result[3].total7,
          totalItems7: result[2].totalItems7 - result[3].totalItems7,
          countTransactions7: result[2].countTransactions7 - result[3].countTransactions7,
          total8: result[2].total8 - result[3].total8,
          totalItems8: result[2].totalItems8 - result[3].totalItems8,
          countTransactions8: result[2].countTransactions8 - result[3].countTransactions8,
          total9: result[2].total9 - result[3].total9,
          totalItems9: result[2].totalItems9 - result[3].totalItems9,
          countTransactions9: result[2].countTransactions9 - result[3].countTransactions9,
          total10: result[2].total10 - result[3].total10,
          totalItems10: result[2].totalItems10 - result[3].totalItems10,
          countTransactions10: result[2].countTransactions10 - result[3].countTransactions10,
          total11: result[2].total11 - result[3].total11,
          totalItems11: result[2].totalItems11 - result[3].totalItems11,
          countTransactions11: result[2].countTransactions11 - result[3].countTransactions11,
          total12: result[2].total12 - result[3].total12,
          totalItems12: result[2].totalItems12 - result[3].totalItems12,
          countTransactions12: result[2].countTransactions12 - result[3].countTransactions12,
        })


      this.operatorData = result

      for(let y = 0; y < this.selectedYears.length; y++){
        for(let i = 1; i <= 12; i++){
          let findedData = chartData.data.find(el => el.month == i && el.year == this.selectedYears[y])
          if(!findedData)
            chartData.data.push({
              month: i,
              year: this.selectedYears[y],
              total: 0
            })
        }
      }
      chartData.data = chartData.data.sort((a, b) => {
        if(a.month > b.month)
          return 1
        else if(a.month < b.month)
          return -1
        else if(a.year > b.year)
          return 1
        else if(a.year < b.year)
          return -1
        else
          return 0
      })
      this.operatorChartData = chartData
      this.operatorLoading = false
    },
    changeWithB() {
      this.ghost = !this.ghost
      this.calculate()
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.changeWithB()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    print() {
      this.printLoading = true

      html2canvas(document.getElementById('chart'), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Riepilogo_Periodo_Commerciale_" + this.selectedYears.join('-') + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error) => {
        console.error(error)
        this.printLoading = false
      });
    },
    printOperator() {
      this.printLoading = true

      html2canvas(document.getElementById('chart-operator'), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Confronta_Operatore_" + this.operator.fullname + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error) => {
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
.operator-graph-container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  margin-bottom: 30px;
}
</style>