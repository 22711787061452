<template>
  <v-list 
    :key="componentKey" 
    :disabled="disabled" 
    :nav="nav"
    :class="{'scrollable': scrollable, ...classes['list']}"
    :style="styles['list']"
    :dense="dense"
  >
    <template v-for="(item, index) in items">
      <v-list-item 
        class="my-0"
        :class="classes['item']"
        :style="styles['item']"
        :link="link"
        :key="item[identifierKey] || index" 
        v-on:click="clickItem(index)"
        v-if="filtersMatch(item)"
      >
        <v-list-item-icon v-if="item[iconKey]"><v-icon v-text="item[iconKey]"></v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="item[titleKey]"></v-list-item-title>
          <v-list-item-subtitle v-if="item[subtitleKey]" v-html="item[subtitleKey]"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="(index != items.length - 1) && divided && filtersMatch(item)" :key="item[identifierKey] + '-divider'"></v-divider>
    </template>
  </v-list>
</template>

<script>
import emittable from "@/mixins/common/emittable.js"

import {
  VList,
  VListItem,
  VListItemIcon,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VDivider
} from 'vuetify/lib'

export default {
  name: "SimpleList",
  components: {
    VList,
    VListItem,
    VListItemIcon,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VDivider
  },
  mixins: [emittable],
  data: function() {
    return {
      componentKey: 0,
      titleFilter: undefined
    }
  },
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    },
    filters: {
      type: Array,
      default: function() {
        return []
      }
    },
    identifierKey: {
      type: String,
      default: 'id'
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    divided: {
      type: Boolean,
      default: true
    },
    link: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    nav: {
      type: Boolean,
      default: false
    },
    titleKey: {
      type: String,
      default: 'title'
    },
    subtitleKey: {
      type: String,
      default: 'subtitle'
    },
    iconKey: {
      type: String,
      default: 'icon'
    },
    styles: {
      type: Object,
      default: function () {
        return {
          list: {},
          item: {},
        };
      },
    },
    classes: {
      type: Object,
      default: function () {
        return {
          list: {},
          item: {},
        };
      },
    },
  },
  methods: {
    clickItem(index) {
      this.emit('item-click', this.items[index])
    },
    filtersMatch(item) {
      return this.titleFilter == undefined || this.titleFilter == null || this.titleFilter == '' || item[this.titleKey].toLowerCase().includes(this.titleFilter.toLowerCase())
    }
  },
  watch: {
    items: function() {
      this.componentKey += 1
      // this.$forceUpdate();
    },
    filters: function(newVal) {
      this.componentKey += 1
      for(let i = 0; i < this.filters.length; i++) {
        if(this.filters[i]['key'] == this.titleKey) {
          this.titleFilter = this.filters[i]['value']
        }
      }
    },
  }
}
</script>

<style scoped>
.scrollable {
  height: 100%;
  overflow-y: scroll;
}
</style>