<template>
  <div>
    <StandardDialog
      v-model="localOpenErrorDialog"
      dialog-width="360"
      dialog-height="calc(160px-1px)"
      persistent
      :title="title"
      @close="close"
    >
      <v-alert class="ma-2" dense outlined :type="type">
        {{ localErrorMessage }}
      </v-alert>
      
      <template v-slot:footer-actions>
        <v-btn color="error" text @click="close">Chiudi</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";

export default {
  name: "ErrorDialogComponent",
  components: {
    StandardDialog,
  },
  props: {
    type: {
      type: String,
      default: "error",
    },
    openDialog: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "errore",
    },
    title: {
      type: String,
      default: "Title Error",
    },
  },
  data() {
    return {
      localOpenErrorDialog: this.openDialog,
      localErrorMessage: this.errorMessage,
    };
  },
  methods: {
   close(){
     this.localOpenErrorDialog = false
     this.$emit('close-error', false)
   }
  },
};
</script>

<style>
</style>