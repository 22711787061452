<template>
  <div class="d-flex justify-center align-center flex-column">
    <table
      :style="{
        'width': tableContentWidth,
        ...cssProps
      }"
      class="bordered"
    >
      <thead style="position: sticky; top: 0px">
        <template v-for="header in headers">
          <th
            :key="header[headersKey]"
            class="px-3 text-center headerColorTable"
            :style="{
              'min-width': header[headersWidth],
            }"
          >
            {{ header[headersText] }}
          </th>
        </template>
      </thead>
      <tbody>
        <tr v-for="row in contentPaginated" :key="row[contentRowsKey] + 'row'" @click="$emit('click-on-row', row)">
          <v-lazy
            v-for="header in headers"
            :key="header[headersKey] + row[header[headersKey]]"
            class="text-center py-2 px-1 hoverable-table-cell"
            min-height="50"
            tag="td"
            :class="classContent"
            :style="{
              'min-width': header[headersWidth],
              'border-bottom': '1px solid rgb(177 177 177)',
            }"
            @click="$emit('click-on-cell', {row: row, headersKey: header[headersKey]})"
          >
            <div class="d-flex justify-center align-content" >
              <v-avatar
                v-if="header['type'] == 'color'"
                :key="header['value']"
                :color="row[header['value']]"
                :size="20"
              ></v-avatar>
              <span v-else>{{ row[header[headersKey]] }}</span>
              <slot name="extra-content" v-bind:table="{header: header, row: row}">
              </slot>
            </div>
          </v-lazy>
        </tr>
      </tbody>
    </table>
    <!--<v-btn
      v-if="showLoad"
      class="mt-2"
      icon
      @click="localLoadMore"
      :loading="loading"
      :disabled="resultsForLoading"
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>-->
      <v-divider></v-divider>
      <div v-if="showPagination" class="d-flex justify-end align-center my-1">
        
        <div>Righe per pagina</div>
        <v-select
          style="max-width: 60px"
          class="mx-2"
          filled
          dense
          hide-details="auto"
          :items="rowPerPageOptions"
          :append-icon="null"
          :menu-props="{ offsetY: true }"
          v-model="rowPerPage"
        ></v-select>
        <div class="mx-2">{{ page }} di {{ totalPages }}</div>
        <v-btn
          icon
          class="mx-1"
          :disabled="minPage"
          @click="decrementPage"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          icon
          class="mx-1"
          :disabled="maxPage"
          @click="incrementPage"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
  </div>
</template>

<script>
export default {
  name: "DynamicContentTable",
  components: {},
  data: function () {
    return {
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      rowPerPageOptions: [20, 50, 100],
      contentPaginated: undefined,
      maxPage: false,
      minPage: true,
      showPagination: false
      };
  },
  props: {
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    classContent: {
      type: Object,
      default: () => {
        return {}
      }
    },
    contentRows: {
      type: Array,
      default: () => {
        return []
      }
    },
    contentRowsKey: {
      type: String ,
      default: "id"
    },
    headersKey: {
      type: String ,
      default: "value"
    },
    headersText: {
      type: String ,
      default: "text"
    },
    headersWidth: {
      type: String,
      default: '80px'
    },
    headersColor: {
      type: String,
      default: 'transparent',
    },
    tableContentWidth: {
      type: String,
      default: null
    },
    showLoad: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    resultsForLoading: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    this.contentPaginated = this.contentRows
  },
  methods: {
    localLoadMore() {
      this.$emit('load-more')
    },
    paginate(array, pageSize, pageNumber) {
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
    incrementPage() {
      this.page++;
      this.minPage = false
      if(this.page == this.totalPages)
        this.maxPage = true
    },
    decrementPage() {
      this.page--;
      this.maxPage = false
      if(this.page == 1)
        this.minPage = true
    }
  }, 
  computed: {
    cssProps() {
      return {
        '--headers-color': this.headersColor
      }
    }
  },
  watch: {
    page() {
      this.contentPaginated = this.paginate(this.contentRows, this.rowPerPage, this.page)
    },
    rowPerPage() {
      this.page = 1
      this.contentPaginated = this.paginate(this.contentRows, this.rowPerPage, this.page)
      this.totalPages = Math.ceil(this.contentRows.length / this.rowPerPage)
    },
    contentRows() {
      if(this.pagination){
        this.contentPaginated = this.paginate(this.contentRows, this.rowPerPage, this.page)
        this.totalPages = Math.ceil(this.contentRows.length / this.rowPerPage)
      }
    },
    contentPaginated(){
      if(this.totalPages > 1 && this.pagination)
        this.showPagination = true
      else 
        this.showPagination = false
    }
  },
};
</script>

<style>
.headersColorTable {
  background-color: var(--headers-color) !important;
}
</style>