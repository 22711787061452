var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"content-to-print"}},[_c('TitleWithBackButton',{attrs:{"title":"Stato Magazzino","back-route":"Analytics","icon":"mdi-graphql"}}),_c('div',{staticClass:"d-flex align-center ml-3 mt-3 pr-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.calculate}},[_vm._v("Calcola")]),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading || _vm.printLoading},on:{"click":_vm.print}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),_c('v-btn',{staticClass:"btn-export",on:{"click":_vm.exportToExcel}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1)],1),_c('div',{staticClass:"pa-4 mt-5"},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":_vm.level1ChartData,"styles":{
            height: '300px',
            width: '300px'
          },"options":{
            mainAspectRatio: true,
            responsive: true,
            legend: {
              display: false
            },
            showTooltips: false,
          }}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center"},[_c('TypeDataTable',{staticClass:"mb-10",staticStyle:{"background-color":"transparent"},attrs:{"show-tooltips":true,"fixed-headers":"","headersColor":"transparent","tooltip-delay":1500,"headers":[
              { text: '', value: 'color', type: 'color' },
              { text: '', value: 'name' },
              { text: 'N° Pezzi', value: 'qty' },
              { text: '% N° Pezzi', value: 'percentageQty' },
              { text: 'Valore €', value: 'total', type: 'price' },
              { text: '% Valore €', value: 'percentageTotal' },
            ],"items":_vm.level1TableData,"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true}})],1)],1)],1),_c('v-row',{staticClass:"d-flex"},_vm._l((_vm.level2ChartDatas),function(level2ChartData,idx){return _c('v-col',{key:idx,attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":Math.min(4, Math.round(12 / _vm.level2ChartDatas.length))}},[_c('v-row',{staticClass:"d-flex justify-center mb-2",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" "+_vm._s(_vm.level2Names[idx])+" ")]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('PieChart',{attrs:{"chart-data":level2ChartData,"styles":{
            height: '300px',
            width: '300px'
          },"options":{
            mainAspectRatio: true,
            responsive: true,
            legend: {
              display: false
            },
            showTooltips: false,
          }}})],1),_c('v-row',{staticClass:"pt-2 d-flex justify-center"},[_c('TypeDataTable',{staticClass:"mb-10",staticStyle:{"background-color":"transparent"},attrs:{"show-tooltips":true,"fixed-headers":"","headersColor":"transparent","tooltip-delay":1500,"headers":[
              { text: '', value: 'color', type: 'color' },
              { text: '', value: 'name' },
              { text: 'N° Pezzi', value: 'qty' },
              { text: '% N° Pezzi', value: 'percentageQty' },
              { text: 'Valore €', value: 'total', type: 'price' },
              { text: '% Valore €', value: 'percentageTotal' },
            ],"items":_vm.level2TableDatas[idx],"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true}})],1),_c('v-expansion-panels',{staticClass:"mb-5",attrs:{"popout":"","flat":"","multiple":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" Uso Interno - "+_vm._s(_vm.level2Names[idx])+" ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('GroupBasedChart',{attrs:{"height":"600px","width":"100%","type":"horizontalBar","transparent":"","colors":_vm.level3InternalColors[idx],"point-colors":[],"getYValue":(object) => {
                      return object[`y`];
                    },"getXValue":(object) => {
                      return object[`x`];
                    },"tooltip-label-modifier":(tooltipLabel) => {
                      return 'Valore: ' + Number(tooltipLabel.xLabel).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) ;
                    },"additional-y-axes-modifier":_vm.yAxes[0],"data":[_vm.level3InternalChartDatas[idx]]}})],1),_c('v-row',[_c('TypeDataTable',{staticClass:"mb-10",staticStyle:{"background-color":"transparent"},attrs:{"show-tooltips":true,"fixed-headers":"","headersColor":"transparent","tooltip-delay":1500,"headers":[
                    { text: '', value: 'color', type: 'color' },
                    { text: '', value: 'name' },
                    { text: 'N° Pezzi', value: 'qty' },
                    { text: '% N° Pezzi', value: 'percentageQty' },
                    { text: 'Valore €', value: 'total', type: 'price' },
                    { text: '% Valore €', value: 'percentageTotal' },
                  ],"items":_vm.level3InternalTableDatas[idx],"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":""},on:{"rowClicked":row => _vm.handleSelectLevel4(row, idx)}})],1),_c('v-row',[_c('TypeDataTable',{staticClass:"mt-10",staticStyle:{"background-color":"transparent"},attrs:{"loading":_vm.reload,"show-tooltips":true,"fixed-headers":"","headersColor":"transparent","tooltip-delay":1500,"headers":[
                    { text: '', value: 'name' },
                    { text: 'N° Pezzi', value: 'qty' },
                    { text: '% N° Pezzi', value: 'percentageQty' },
                    { text: 'Valore €', value: 'total', type: 'price' },
                    { text: '% Valore €', value: 'percentageTotal' },
                  ],"items":_vm.getLevel4TableData(_vm.level2DepartmentIds[idx], _vm.selectedSupplierIds[idx], true),"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":""}})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" Retail - "+_vm._s(_vm.level2Names[idx])+" ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('GroupBasedChart',{attrs:{"height":"600px","width":"100%","type":"horizontalBar","transparent":"","colors":_vm.level3RetailColors[idx],"point-colors":[],"getYValue":(object) => {
                      return object[`y`];
                    },"getXValue":(object) => {
                      return object[`x`];
                    },"tooltip-label-modifier":(tooltipLabel) => {
                      return 'Valore: ' + Number(tooltipLabel.xLabel).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) ;
                    },"additional-y-axes-modifier":_vm.yAxes[0],"data":[_vm.level3RetailChartDatas[idx]]}})],1),_c('v-row',[_c('TypeDataTable',{staticClass:"mb-10",staticStyle:{"background-color":"transparent"},attrs:{"show-tooltips":true,"fixed-headers":"","headersColor":"transparent","tooltip-delay":1500,"headers":[
                    { text: '', value: 'color', type: 'color' },
                    { text: '', value: 'name' },
                    { text: 'N° Pezzi', value: 'qty' },
                    { text: '% N° Pezzi', value: 'percentageQty' },
                    { text: 'Valore €', value: 'total', type: 'price' },
                    { text: '% Valore €', value: 'percentageTotal' },
                  ],"items":_vm.level3RetailTableDatas[idx],"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":""},on:{"rowClicked":row => _vm.handleSelectLevel4(row, idx)}})],1),_c('v-row',[_c('TypeDataTable',{staticClass:"mb-10",staticStyle:{"background-color":"transparent"},attrs:{"loading":_vm.reload,"show-tooltips":true,"fixed-headers":"","headersColor":"transparent","tooltip-delay":1500,"headers":[
                    { text: '', value: 'name' },
                    { text: 'N° Pezzi', value: 'qty' },
                    { text: '% N° Pezzi', value: 'percentageQty' },
                    { text: 'Valore €', value: 'total', type: 'price' },
                    { text: '% Valore €', value: 'percentageTotal' },
                  ],"items":_vm.getLevel4TableData(_vm.level2DepartmentIds[idx], _vm.selectedSupplierIds[idx], false),"show-select":false,"select-on-row-click":false,"show-actions":false,"disablePagination":true,"dense":""}})],1)],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }