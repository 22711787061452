import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class IntermediaryService {

  async ignoredItemsAndServices() {
    const apiUrl = UrlKeeper.getUrl()

    let response = await axios
      .get(apiUrl + "/payments/intermediary/ignoredItemsAndServices")

    if (!response.success) throw response.results

    return response.results
  }
  
  async addIgnoredItems(items) {
    const apiUrl = UrlKeeper.getUrl()

    let response = await axios
      .post(apiUrl + "/payments/intermediary/addIgnoredItems", {
        items: items
      })

    if(!response.success) throw response.results

    return response.results
  }

  async removeIgnoredItems(items) {
    const apiUrl = UrlKeeper.getUrl()

    let response = await axios
      .post(apiUrl + "/payments/intermediary/removeIgnoredItems", {
        items: items
      })

    if (!response.success) throw response.results

    return response.results
  }

  async addIgnoredServices(services) {
    const apiUrl = UrlKeeper.getUrl()

    let response = await axios
      .post(apiUrl + "/payments/intermediary/addIgnoredServices", {
        services: services
      })

    if (!response.success) throw response.results

    return response.results
  }

  async removeIgnoredServices(services) {
    const apiUrl = UrlKeeper.getUrl()

    let response = await axios
      .post(apiUrl + "/payments/intermediary/removeIgnoredServices", {
        services: services
      })

    if (!response.success) throw response.results

    return response.results
  }
}

export default new IntermediaryService();
