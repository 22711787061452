<template>
  <div>
    <AjaxAutocomplete
      v-model="selected"
      :searcher="searchSupplier"
      @input="handleInput"
      :filled="filled"
      :item-text="(entry) => {return entry.businessName}"
      item-value="id"
      hide-details="auto"
      rounded
      :search-threshold="1"
      :return-object="returnObject"
      :value-searcher="searchById"
      :loading="loading"
      :prepend-icon="prependIcon"
      :append-outer-icon="appendOuterIcon"
      :append-icon="appendIcon"
      :clearable="clearable"
      :disabled="disabled"
      :label="label"
      :error="error"
      :dense="dense"
      :outlined="outlined"
      no-data-text=""
      :external-search="externalSearch"
      @click:prepend="$emit('click:prepend', $event)"
      @click:append-outer="handlePencilClick"
    ></AjaxAutocomplete>
    <SupplierNewForm
      v-if="hasToOpenNewForm"
      :open-dialog.sync="supplierNewFormDialog"
      :path-to-go-back="null"
      :precompiled="precompiledSupplier"
      @submit="handleFormSubmit"
    ></SupplierNewForm>
    <SupplierEditForm
      v-if="supplierEditFormDialog"
      :open-dialog.sync="supplierEditFormDialog"
      :path-to-go-back="null"
      :supplier-id="!!selected ? selected.id : undefined"
      @submit="handleEditFormSubmit"
    ></SupplierEditForm>
  </div>
</template>

<script>
import AjaxAutocomplete from '@/components/common/AjaxAutocomplete.vue'
import supplierService from '@/services/suppliers/suppliers.service.js'
import SupplierNewForm from '@/components/areas/registries/suppliers/SuppliersRegistryNewForm.vue'
import SupplierEditForm from '@/components/areas/registries/suppliers/SuppliersRegistryEditForm.vue'

export default {
  name: "SupplierAutocomplete",
  components: {
    AjaxAutocomplete,
    SupplierNewForm,
    SupplierEditForm
  },
  data: function() {
    return {
      autoDetectNewSupplierSetting: undefined,
      selected: this.returnObject ? {...this.value} : this.value,
      supplierEditFormDialog: false,
      supplierNewFormDialog: false,
      precompiledSupplier: {}
    }
  },
  props: {
    value: {},
    returnObject: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    autoDetectNewSupplier: {
      type: Boolean,
      default: true
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    appendOuterIcon: {
      type: String,
      default: undefined
    },
    appendIcon: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean, 
      default: true
    },
    label: {
      type: String,
      default: 'Inserire ragione sociale ...'
    },
    error: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    externalFilters: {
      type: Object,
    },
    externalSearch: {
      type: String,
      default: undefined
    }
  },
  methods: {
    searchSupplier(searchText) {
      return new Promise((resolve, reject) => {
        supplierService.search(searchText, this.externalFilters).then((results) => {
          if(this.hasToOpenNewForm && results.length == 0) {
            this.precompiledSupplier = {
              lastname: searchText
            }
            this.supplierNewFormDialog = true
          }
          resolve(results)
        })
      })
    },
    handleInput() {
      this.$emit('input', this.selected)
    },
    searchById(supplier) {
      if(this.returnObject) {
        return supplierService.get(supplier.id)
      } else {
        return supplierService.get(supplier)
      }
    },
    handleFormSubmit(supplier) {
      if(this.returnObject) {
        this.selected = {...supplier}
      } else {
        this.selected = supplier.id
      }
      this.supplierNewFormDialog = false
      this.$emit('input', this.selected)
    },
    handleEditFormSubmit(supplier) {
      if(this.returnObject) {
        this.selected = {...supplier}
      } else {
        this.selected = supplier.id
      }
      this.$emit('input', this.selected)
      this.supplierEditFormDialog = false
    },
    handlePencilClick() {
      this.supplierEditFormDialog = true
    }
  },
  computed: {
    hasToOpenNewForm() {
      return this.autoDetectNewSupplier
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal
    }
  },
}
</script>

<style>

</style>