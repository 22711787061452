import hash from 'object-hash'
import Database from '@/services/offline/database.js'

class CalendarCache extends Database {

  constructor() {
    super()
  }

  _storeName() {
    return 'events'
  }

  _maxRows() {
    return 50
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {String} param.day - Day to cache (format: YYYYMMDD )
   * @param {Object} param.filters - Filters thats applied to the list
   * @param {Object[]} param.events - Events to cache
   */
  async cacheDay({ day, events, filters }) {
    let storageKey = this._createStorageKey({
      timestamp: day,
      filters: filters
    })

    const db = await this.dbPromise

    const keys = await db.getAllKeys(this._storeName())
    if(keys.length > this._maxRows()) {
      db.delete(this._storeName(), keys.reverse()[0])
    }

    await db.put(this._storeName(), events, storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.filters - Filters thats applied to the list
   * @param {String} param.day - Day to cache (format: YYYYMMDD )
   */
  async getDay({ day, filters }) {
    let storageKey = this._createStorageKey({
      timestamp: day,
      filters: filters
    })

    const db = await this.dbPromise
    return await db.get(this._storeName(), storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.filters - Filters thats applied to the list
   * @param {String} param.day - Day to cache (format: YYYYMMDD )
   */
  async cachePresent({ day, filters }) {
    return !!(await this.getDay({ day, filters }))
  }

  async deleteAllCache() {
     const db = await this.dbPromise
     return await db.clear(this._storeName())
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.filters - Filters thats applied to the list
   * @param {String} param.timestamp - Day to cache (format: YYYYMMDD )
   */
  _createStorageKey({ timestamp, filters}) {
    if(!!filters) {
      let filterHash = hash(filters)
      let storageKey = timestamp + ':' + filterHash
      return storageKey
    } else {
      return timestamp
    }
  }
}

export default new CalendarCache()