<template>
  <div class="px-10 pt-7" id="content-to-print">
    <!-- Header -->
    <v-row 
      class="d-flex align-center" 
      :style="{
        height: (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) ? '5dvh': 
          (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) ? '25dvh' : '4dvh'
      }"
    > 
      <div class="d-flex justify-center">
        <v-btn
          icon
          class="mr-2"
          @click="goBack"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div>
          <v-img
            max-width="125"
            src="@/assets/LOGO_LULUU.png"
          ></v-img>
        </div>
        <div class="ml-2 d-flex align-center">
          <v-icon color="#8a77e2" large>mdi-calendar</v-icon>
          <span class="title-custom" style="padding-top: 5px">Calendario Marketing</span> 
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div>
          <v-btn
            outlined
            class="mr-1 ml-1"
            text
            @click="goToNew"
          >
            Crea +
          </v-btn>
        </div>
        <div>
          <v-btn
            outlined
            class="mr-1 ml-1"
            text
            @click="print"
          >
            Stampa
          </v-btn>
        </div>
      </div>
      <div 
        class="d-flex justify-center align-center mb-1"
        :style="{
          width: this.$vuetify.breakpoint.xs ? '40vw' : 
            this.$vuetify.breakpoint.sm ? '30vw' : '20vw'
        }"
      >
        <v-btn
          icon
          @click="datePickerPrev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div style="width: 300px" class="text-center">
          <div>{{ calendarTitle }}</div>
        </div>
        <v-btn
          icon
          @click="datePickerNext"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-row>
    
    <!-- Content -->
    <v-row 
      :style="{
        height: (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) ? '92dvh' : 
          (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) ? '88dvh' :'94dvh'
      }" 
      class="mb-0">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="2"
        xl="2"
        :style="{
          height: this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl ? '100%': '29%',
          'padding-left': 0
        }" 
        class="d-flex flex-column"
        :order="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
          ? '2' : '1' "
      >
        <div style="height: 100%;">
          <div
            v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.md"
            class="mb-2 d-flex justify-center align-center"
          >
            <v-date-picker
              class="sidebar-calendar"
              no-title
              style="width: 100%"
              locale="it"
              v-model="selectedMonth"
              type="month"
              input-class="test"
              @input="selectMonth"
              first-day-of-week="1"
            ></v-date-picker>
          </div>
          <v-divider 
              v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.md"
          ></v-divider>
          <div 
            :style="{
              'overflow-y': 'auto', 
              'height': !!this.$vuetify.breakpoint.xs || !!this.$vuetify.breakpoint.sm || !!this.$vuetify.breakpoint.md
                ? '90%' : !!this.$vuetify.breakpoint.lg ? '56%' : '67%'
            }"
          >
            <div>
              <div class="ml-2 mb-2 text-subtitle-1 font-weight-bold">REPARTI</div>
              <v-checkbox
                class="mt-0 ml-0 mr-0 pa-0 pl-2"
                style="margin-bottom: -15px"
                v-for="(item, key) in serviceTypesCategories" :key="item['id']"
                v-model="filteredServiceTypesCategories[key]['checked']"
                @click="handleCategoriesClick"
              >
                <template v-slot:label>
                  <span
                    style="font-size: 13px;"
                  >
                    {{ item['name'] }}
                  </span>
                </template>
              </v-checkbox>
            </div>
            <v-divider 
                v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.md"
            ></v-divider>
            <div>
              <div class="ml-2 mb-2 text-subtitle-1 font-weight-bold">OBIETTIVI</div>
              <v-checkbox
                class="mt-0 ml-0 mr-0 pa-0 pl-2"
                style="margin-bottom: -15px"
                v-for="(item, key) in filteredGoals" :key="item['id']"
                v-model="filteredGoals[key]['checked']"
                @click="handleGoalsClick"
              >
                <template v-slot:label>
                  <span
                    style="font-size: 13px;"
                  >
                    {{ item['name'] }}
                  </span>
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
        xl="10"
        :style="{
          height: this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl ? '100%': '70%',
          'padding-left': 0
        }" 
        :order="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
          ? '1' : '2' "
      >
        <v-calendar
          v-model="focus"
          :events="events"
          event-overlap-mode="stack"
          style="height: 100%"
          :event-height="17"
          ref="calendar"
          type="month"
          locale="it"
          event-more-text="altri {0}"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          @change="handleCalendarChange"
          @click:day="handleDayClick"
          @click:more="handleMoreEventsClick"
          @mousedown:day="startCreateDrag"
          @mousemove:day="moveCreateDrag"
          @mouseup:day="endCreateDrag"
          @touchstart:day="startCreateDrag"
          @touchmove:day="moveCreateDrag"
          @touchend:day="endCreateDrag"
          @mousedown:event="startEventDrag"
          @mouseleave.native="cancelDrag"
        >
          <template v-slot:day-label="{day, date}">
            <v-btn 
              text 
              large
              :disabled="checkIfPast(date)" 
            >
              {{day}}
            </v-btn >
          </template>
          <template v-slot:event="{event}">
            <StandardMenu
              :fit-width="false"
              minWidth="400px"
              :close-on-content-click="closeDetailEvent"
            >
              <template 
                v-slot:activator="{ on, attrs }"
              >
                <div 
                  v-on="on" 
                  v-bind="attrs"
                  @contextmenu.prevent="on.click"
                >
                  <div @click="closeDetailEvent = false">
                    {{event.name}}
                  </div>
                </div>
              </template>
              <v-card width="400px">
                <v-card-title class="flex-column align-end pl-0">
                  <v-row>
                   <v-col cols="12">
                    <div>
                      <v-btn
                        icon 
                        @click="handleGoToLanding(event)"
                        v-if="!!event.action.landingPage"
                      >
                        <v-icon small>mdi-web</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!event.master || (!!event.master && !!event.importedFromId)" 
                        icon 
                        @click="handleEditEvent(event)" 
                        class
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!!event.master && !event.importedFromId" 
                        icon 
                        class
                        @click="handleImportEvent(event)"
                      >
                        <v-icon small>mdi-import</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="(!event.master || (!!event.master && !!event.importedFromId))
                          && (!event.action.hasStarted || event.duplicatedFromId) 
                          && new Date() <= new Date(event.end)
                          && !event.archived" 
                        icon
                        @click="deleteMarketingEvent(event)" 
                        class
                      >
                        <v-icon small>mdi-trash-can</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="(!event.master || (!!event.master && !!event.importedFromId))
                          && (!!event.action.hasStarted && !event.duplicatedFromId) 
                          && new Date() <= new Date(event.end)
                          && !event.archived" 
                        icon
                        @click="archiveMarketingEvent(event)" 
                        class
                      >
                        <v-icon small>mdi-archive</v-icon>
                      </v-btn>
                      <v-btn
                        icon 
                        @click="() => {
                          closeDetailEvent = true
                        }" 
                        class
                      >
                        <v-icon small>mdi-window-close</v-icon>
                      </v-btn>
                    </div>
                   </v-col> 
                  </v-row>
                  <v-row class="ml-0" style="width: 100%; margin-top: -24px;">
                    <v-col cols="12">
                      <span style="color: #8a77e2; font-size: large;"> {{event.name}} </span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row style="margin-bottom: -25px;">
                    <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                      · Data:
                    </v-col>
                    <v-col cols="auto">
                      Dal {{ new Date(event.start).toLocaleDateString('it-IT').replaceAll('/', '-') }} 
                      al {{ new Date(event.end).toLocaleDateString('it-IT').replaceAll('/', '-') }}
                      <span
                        v-if="!!event.sendTime"
                      >
                        | {{ event.sendTime }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row style="margin-bottom: -25px;">
                    <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                      · Obbiettivo:
                    </v-col>
                    <v-col cols="auto">
                      <span style="font-size: 13px;">{{ !!filteredGoals.find(el => el.id == event.action.goal) ? 
                        filteredGoals.find(el => el.id == event.action.goal).name : ''}}</span>
                    </v-col>
                  </v-row>
                  <v-row style="margin-bottom: -25px;">
                    <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                      · Reparti:
                    </v-col>
                    <v-col cols="auto">
                      {{ event.action.categories.join(', ') }}
                    </v-col>
                  </v-row>
                  <v-row style="margin-bottom: -25px;">
                    <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                      · Strumento:
                    </v-col>
                    <v-col cols="auto">
                      {{ event.action.tool }}
                    </v-col>
                  </v-row>
                  <v-row style="margin-bottom: -25px">
                    <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                      · Stato:
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -25px">
                    <v-col class="d-flex direction-row" cols="auto">
                      <div
                        :style="{                          
                          'background-color': event.status == 'draft' &&
                            new Date() <= new Date(event.end) ? '#ffda85' : '',
                          'font-size': '12px'
                        }"
                        class="ml-2 status-div font-weight-bold"
                      >
                        DRAFT
                      </div>
                      <div 
                        :style="{
                            'background-color': event.status == 'approved' &&
                              new Date() <= new Date(event.start) ? '#bfec83' : '',
                            'font-size': '12px'
                          }"
                        class="ml-2 status-div font-weight-bold"
                      >
                        APPROVATO
                      </div>
                      <div 
                        :style="{
                            'background-color': event.status == 'approved' &&
                              new Date() >= new Date(event.start) && 
                              new Date() <= new Date(event.end) 
                              && !event.archived ? '#9abcea' : '',
                            'font-size': '12px'
                          }"
                        class="ml-2 status-div font-weight-bold"
                      >
                        IN CORSO
                      </div>
                      <div 
                        :style="{
                            'background-color': event.status == 'draft' &&
                              new Date() > new Date(event.end) ? 'grey' : 
                              event.status == 'approved' &&
                              new Date() > new Date(event.end) ? '#cccccc' : 
                              !!event.archived ? '#cccccc' : '',
                            'font-size': '12px'
                          }"
                        class="ml-2 status-div font-weight-bold"
                      >
                        TERMINATO
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions 
                  class="d-flex justify-start" 
                  v-if="!event.master || (!!event.master && !!event.importedFromId)"
                >
                  <v-btn
                    text
                    outlined
                    @click="handleCreateFromEvent(event, 'createFrom')"
                  > 
                    Crea a partire da
                  </v-btn>
                  <v-btn
                    text
                    outlined
                    :disabled="!!event.action.hasStarted"
                    @click="handleCreateFromEvent(event, 'duplicateFrom')"
                  > 
                    Duplica
                  </v-btn>
                </v-card-actions>
              </v-card>
            </StandardMenu>
          </template>
        </v-calendar>
      </v-col>
    </v-row>
    <StandardDialog
      v-model="moreEventsDialog"
      :dialog-height="null"
      dialog-width="35vw"
      :title="'Eventi del ' + moreEventsDay"
    >
      <div>
       <template v-for="event in moreEventsList" >
        <StandardMenu 
          :fit-width="true" 
          :key="event.key"
          :close-on-content-click="closeDetailEvent"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-content>
                <v-row>
                  <v-col cols="auto">
                    <v-avatar :color="event.color"></v-avatar>
                  </v-col>
                  <v-col>
                    <div @click="closeDetailEvent = false">
                      {{event.name}}
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-card width="400px">
            <v-card-title class="flex-column align-end pl-0">
              <v-row>
                <v-col cols="12">
                  <div>
                    <v-btn
                      icon 
                      @click="handleGoToLanding(event)"
                      v-if="!!event.action.landingPage"
                    >
                      <v-icon small>mdi-web</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!event.master || (!!event.master && !!event.importedFromId)" 
                      icon 
                      @click="handleEditEvent(event)" 
                      class
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!!event.master && !event.importedFromId" 
                      icon 
                      class
                      @click="handleImportEvent(event)"
                    >
                      <v-icon small>mdi-import</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!event.master || (!!event.master && !!event.importedFromId)" 
                      icon
                      @click="deleteShift(event)" 
                      class
                    >
                      <v-icon small>mdi-trash-can</v-icon>
                    </v-btn>
                    <v-btn
                      icon 
                      @click="() => {
                        closeDetailEvent = true
                      }" 
                      class
                    >
                      <v-icon small>mdi-window-close</v-icon>
                    </v-btn>
                  </div>
                </v-col> 
              </v-row>
              <v-row class="ml-0" style="width: 100%; margin-top: -24px;">
                <v-col cols="12">
                  <span style="color: #8a77e2; font-size: large;"> {{event.name}} </span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row style="margin-bottom: -25px;">
                <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                  · Data:
                </v-col>
                <v-col cols="auto">
                  Dal {{ new Date(event.start).toLocaleDateString('it-IT').replaceAll('/', '-') }} 
                  al {{ new Date(event.end).toLocaleDateString('it-IT').replaceAll('/', '-') }}
                  <span
                    v-if="!!event.sendTime"
                  >
                    | {{ event.sendTime }}
                  </span>
                </v-col>
              </v-row>
              <v-row style="margin-bottom: -25px;">
                <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                  · Obbiettivo:
                </v-col>
                <v-col cols="auto">
                  <span style="font-size: 13px;">{{ !!filteredGoals.find(el => el.id == event.action.goal) ? 
                        filteredGoals.find(el => el.id == event.action.goal).name : ''}}</span>
                </v-col>
              </v-row>
              <v-row style="margin-bottom: -25px;">
                <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                  · Reparti:
                </v-col>
                <v-col cols="auto">
                  {{ event.action.categories.join(', ') }}
                </v-col>
              </v-row>
              <v-row style="margin-bottom: -25px;">
                <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                  · Strumento:
                </v-col>
                <v-col cols="auto">
                  {{ event.action.tool }}
                </v-col>
              </v-row>
              <v-row style="margin-bottom: -25px">
                <v-col cols="auto" class="text-subtitle-2 font-weight-bold">
                  · Stato:
                </v-col>
              </v-row>
              <v-row style="margin-top: -25px">
                <v-col class="d-flex direction-row" cols="auto">
                  <div
                    :style="{                          
                      'background-color': event.status == 'draft' &&
                        new Date() <= new Date(event.end) ? '#ffda85' : '',
                      'font-size': '12px'
                    }"
                    class="ml-2 status-div"
                  >
                    DRAFT
                  </div>
                  <div 
                    :style="{
                        'background-color': event.status == 'approved' &&
                          new Date() <= new Date(event.start) ? '#bfec83' : '',
                        'font-size': '12px'
                      }"
                    class="ml-2 status-div"
                  >
                    APPROVATO
                  </div>
                  <div 
                    :style="{
                        'background-color': event.status == 'approved' &&
                          new Date() >= new Date(event.start) && 
                          new Date() <= new Date(event.end) 
                          && !event.archived ? '#9abcea' : '',
                          'font-size': '12px'
                      }"
                    class="ml-2 status-div"
                  >
                    IN CORSO
                  </div>
                  <div 
                    :style="{
                        'background-color': event.status == 'draft' &&
                          new Date() > new Date(event.end) ? 'grey' : 
                          event.status == 'approved' &&
                          new Date() > new Date(event.end) ? '#cccccc' : 
                          !!event.archived ? '#cccccc' : '',
                        'font-size': '12px'
                      }"
                    class="ml-2 status-div"
                  >
                    TERMINATO
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions 
              v-if="!event.master || (!!event.master && !!event.importedFromId)"
              class="d-flex justify-start"
            >
              <v-btn
                text
                outlined
                @click="handleCreateFromEvent(event, 'createFrom')"
              > 
                Crea a partire da
              </v-btn>
              <v-btn
                text
                outlined
                :disabled="!!event.action.hasStarted"
                @click="handleCreateFromEvent(event, 'duplicateFrom')"
              > 
                Duplica
              </v-btn>
            </v-card-actions>
          </v-card>
        </StandardMenu>
      </template> 
      </div>
    </StandardDialog>

    <StandardDialog
      title="Elimina eventi ricorrenti"
      v-model="deleteRecurrentEventsDialog"
      :dialog-height="null"
      dialog-width="20vw"
    >
      <v-radio-group
        v-model="selectedDeleteRecurrentEventsOption"
        mandatory
      >
        <v-radio
          v-for="(item, key) in deleteRecurrentEventsOptions"
          :key="key"
          :label="item.text"
          :value="item.value"
        ></v-radio>
      </v-radio-group>

      <template v-slot:footer>
        <div
          class="d-flex justify-end"
          style="width: 100%"
        >
          <v-btn
            color="error"
            text
            :loading="loadingDeleteRecurrentEvents"
            @click="() => {
              deleteRecurrentEventsDialog = false
              selectedDeleteRecurrentEventsOption = 'onlyThis'
            }"
          >
            Chiudi
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="loadingDeleteRecurrentEvents"
            @click="deleteRecurrentEvents(selectedDeleteRecurrentEventsOption)"
          >
            OK  
          </v-btn>
        </div>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import html2canvas from '@/assets/js/html2canvas.min.js'

import GroupedSingleSelectList from '@/components/common/GroupedSelectList.vue';
import StandardDialog from '@/components/common/StandardDialog.vue'
import StandardMenu from "@/components/common/StandardMenu.vue";

import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js";
import marketingEventsService from "@/services/marketingEvents/marketingEvents.service.js";

import { cloneDeep } from "lodash";

export default {
  name: "CalendarMarketing",
  components: {
    GroupedSingleSelectList,
    StandardDialog,
    StandardMenu
  },
  data: function() {
    let props = this.$router.resolve({ name: 'Marketing' });

    return {
      routeFather: props.href,
      selectedMonth: undefined,
      calendarTitle: undefined,
      focus: "",
      events: [],
      filteredServiceTypesCategories: [],
      serviceTypesCategories: [],
      currentUser: undefined,
      moreEventsDialog: false,
      moreEventsList: [],
      moreEventsDay: undefined,
      filteredGoals: [
        {
          id: 'increaseCustomerFrequency',
          name: 'Aumenta Frequenza Clienti',
          checked: false
        },
        {
          id: 'devolopSales',
          name: 'Sviluppa vendite',
          checked: false
        },
        {
          id: 'developServiceTypeCategory',
          name: 'Sviluppa reparti',
          checked: false
        },
        {
          id: 'developServicesOnActiveCustomers',
          name: 'Sviluppa servizi su clienti attivi',
          checked: false
        },
        {
          id: 'reactivateInactiveCustomers',
          name: 'Riattiva clienti inattivi',
          checked: false
        },
        {
          id: 'newCustomers',
          name: 'Nuovi Clienti',
          checked: false
        },
        {
          id: 'mostLoyalCustomers',
          name: 'Premia clienti più fedeli',
          checked: false
        },
        {
          id: 'informational',
          name: 'Informazionale ',
          checked: false
        },
        {
          id: 'informAffiliatedActivities',
          name: 'Informa attività convenzionate',
          checked: false
        },        
      ],
      filtersValue: {},
      closeDetailEvent: false,
      dragDayIsStarted: false,
      createEvent: undefined,
      dragEventIsStarted: false,
      continueDrag: false,
      dragEvent: undefined,
      copyDragEvent: undefined,
      deleteRecurrentEventsDialog: false,
      deleteRecurrentEventsOptions: [
        {
          text: 'Questo evento',
          value: 'onlyThis'
        },
        {
          text: 'Questo e tutti gli eventi futuri',
          value: 'future'
        },
        {
          text: 'Tutti gli eventi della serie',
          value: 'all'
        },
      ],
      selectedDeleteRecurrentEventsOption: 'onlyThis',
      eventToDelete: undefined,
      loadingDeleteRecurrentEvents: false,
    };
  },
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  async mounted() {
    this.filtersValue = !!this.filters ? await this.filters : {}

    if (!!this.filtersValue.goals) {
      this.filtersValue.goals.map(filterGoal => this.filteredGoals.find(
            goal => goal.id == filterGoal)).map(el => el.checked = true)
    }
 
    if (!!this.filtersValue.month && this.filtersValue.year) {
      const tempDate = new Date(this.filtersValue.year + '-' + (Number(this.filtersValue.month) + 1))

      this.selectedMonth = tempDate.getFullYear() + '-' + tempDate.toLocaleString("it-It", { month: "2-digit" }) 
      this.selectMonth()
    }
    else
      this.selectedMonth = this.currentMonth()

    await this.loadServiceTypesCategories()
    await this.loadMarketingEvents()
  },
  methods: {
    checkIfPast(date) {
      let localDate = new Date(date)
      let today = new Date()
      localDate.setHours(0, 0, 0, 0)
      today.setHours(0, 0, 0, 0)
      if (localDate <= today)
        return true
      else
        return false
    },
    startCreateDrag({ date }, nativeEvent) {
      nativeEvent.stopPropagation();
      if (nativeEvent.button != 0)
        return;
      if (this.checkIfPast(date))
        return;
      
      if (!this.dragEvent) {
        this.dragDayIsStarted = true
        this.createEvent = {
          name: "(senza titolo)",
          start: new Date(date),
          end: undefined,
          recurrenceType: 'noRepeat',
          color: '#ffda85',
          action: {
            name: '',
            description: '',
            goal: '',
            receiptModelId: undefined,
            smsModelId: undefined,
            landingPageId: undefined,
            categories: [],
            hasStarted: false,
            sendTime: undefined
          },
          master: false,
          importedFromId: undefined,
          duplicatedFromId: undefined,
          status: 'draft',
          archived: false
        }
        this.events.push(this.createEvent)
      }

      if (!!this.dragEvent) {
        const today = new Date()
        const eventEnd = new Date(this.dragEvent.end)

        if (this.dragEvent.status == 'draft' && today <= eventEnd) {
          this.dragEventIsStarted = true
          this.copyDragEvent = cloneDeep(this.dragEvent)
        }
      }

    },
    moveCreateDrag({ date }, nativeEvent) {
      if (this.checkIfPast(date)) {
        return;
      }
      if (!this.dragDayIsStarted && !this.dragEventIsStarted) {
        return;
      }

      this.continueDrag = true

      if (!this.dragEvent) {
        this.createEvent.end = new Date(date)
        this.createEvent.end.setHours(0, 0, 0, 0)
        this.events[this.events.length - 1] = this.createEvent
        //this.events[this.events.length - 1].end.setHours(0, 0, 0, 0)
      }

      if (!!this.dragEvent && !!this.dragEventIsStarted) {
        const start = this.dragEvent.start
        const end = this.dragEvent.end
        const duration = end - start
        const newStart = new Date(date)
        newStart.setHours(0, 0, 0, 0)
        const newEnd = new Date(newStart.getTime() + duration)
        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
      }
    },
    async endCreateDrag({ date }, nativeEvent) {
      if (nativeEvent.button != 0)
        return;
      if (this.checkIfPast(date)) {
        this.dragEvent = undefined
        return;
      }
      if (!this.continueDrag) 
        return;
      
      this.dragDayIsStarted = false
      this.dragEventIsStarted = false
      this.continueDrag = false

      if (!this.dragEvent) {
        let localEvent = this.createEvent

        let localDate = new Date(date)
        let eventStartDate = new Date(localEvent.start)
        eventStartDate.setHours(0, 0, 0, 0)
        localDate.setHours(0, 0, 0, 0)

        if (localDate.getTime() != eventStartDate.getTime()) {
          await this.$router.push({
            name: 'EventMarketingNewForm',
            query: {
              pathToGoBack: this.$router.resolve({name: 'calendarMarketing'}).href,
              pathName: 'calendarMarketing'
            },
            params: {
              startDate: localEvent.start,
              endDate: localEvent.end,
              filters: this.filtersValue
            }
          })
        }
      }

      if (!!this.dragEvent && (this.dragEvent.start.getTime() !== this.copyDragEvent.start.getTime() 
        || this.dragEvent.end.getTime() !== this.copyDragEvent.end.getTime())) {
          let eventToUpdate = {
            id: this.dragEvent.id,
            from: this.dragEvent.start,
            to: this.dragEvent.end,
            status: this.dragEvent.status,
            actions: this.dragEvent.action
          }

          if (!!eventToUpdate.actions) {
            if (!!eventToUpdate.actions.landingPage && !!eventToUpdate.actions.landingPage.blocks 
              && eventToUpdate.actions.landingPage.blocks.length > 0) {
                for (let i = 0; i < eventToUpdate.actions.landingPage.blocks.length; i++) {
                  let block = eventToUpdate.actions.landingPage.blocks[i]

                  if (!!block.image) {
                    delete block.image
                  }
                }
              }
          }

          await marketingEventsService.update(eventToUpdate)
          this.dragEvent = undefined
          await this.loadMarketingEvents()
        }
    },
    startEventDrag({ event }) {
      this.dragEvent = event;
    },
    cancelDrag() {
      if (!!this.createEvent) {
        const i = this.events.indexOf(this.createEvent)
        if (i !== -1) {
          this.events.splice(i, 1)
        }
      }

      this.dragEventIsStarted = false
      this.dragEvent = undefined
    },
    async deleteMarketingEvent(event) {
      if (!!event.recurrenceType && event.recurrenceType != 'noRepeat') {
        this.deleteRecurrentEventsDialog = true
        this.eventToDelete = event
      } else {
        await marketingEventsService.delete(event)
        await this.loadMarketingEvents()
      }
    },
    async deleteRecurrentEvents(deleteType) {
      this.loadingDeleteRecurrentEvents = true
      await marketingEventsService.deleteRecurrentEvents(this.eventToDelete, deleteType)
      await this.loadMarketingEvents()
      this.loadingDeleteRecurrentEvents = false
      this.deleteRecurrentEventsDialog = false
      this.eventToDelete = undefined
      this.selectedDeleteRecurrentEventsOption = 'onlyThis'
    },
    async archiveMarketingEvent(event) {
      await marketingEventsService.archive(event)
      await this.loadMarketingEvents()
    },
    async handleGoalsClick() {
      let goals = this.filteredGoals.filter(el => !!el.checked).map(el => el.id)
      this.filtersValue.goals = goals.length > 0 ? goals : undefined
      await this.loadMarketingEvents()
    },
    async handleCategoriesClick() {
      let categories = this.filteredServiceTypesCategories.filter(el => !!el.checked).map(el => el.name)
      this.filtersValue.categories = categories.length > 0 ? categories : undefined
      await this.loadMarketingEvents()
    },
    async loadMarketingEvents() {
      marketingEventsService.list(1, 1000, this.filtersValue).then((results) => {
        this.events = results.map((el) => {
          let customAction = {
            ...el.actions,
            tool: !!el.actions.smsModelId ? 'SMS' : 
            !!el.actions.receiptModelId ? 'Scontrino' :
            !!el.actions.landingPageId ? 'Landing Page' : 'Non Definito'
          }
          
          const dateStart = new Date(el.from)
          const dateEnd = new Date(el.to)
          
          const sendTime = !!el.actions.sendTime ? el.actions.sendTime.slice(0, -3) : undefined
          return {
            id: el.id,
            name: el.actions.name,
            start: dateStart,
            end: dateEnd,
            recurrenceType: el.recurrenceType,
            color: this.calculateEventColor(el),
            action: customAction,
            master: el.master,
            importedFromId: el.importedFromId,
            duplicatedFromId: el.duplicatedFromId,
            key: el.key,
            sendTime: sendTime,
            status: el.status,
            archived: el.archived
          }
        })
      })
    },
    async loadServiceTypesCategories() {
      serviceTypesCategoriesService.list(undefined, undefined, {isBar: undefined}).then((serviceTypes) => {
        this.serviceTypesCategories = serviceTypes.rows
        this.filteredServiceTypesCategories = serviceTypes.rows.map((el) => {
          return {
            id: el['id'], 
            name: el['name'],
            checked: false
          }
        })

        if(!!this.filtersValue.categories)
          this.filtersValue.categories.map(filterCat => this.filteredServiceTypesCategories.find(
            cat => cat.name == filterCat)).map(el => el.checked = true)
      })
    },
    goBack() {
      this.$router.push(this.routeFather);
    },
    async goToNew() {
      await this.$router.push({
        name: 'EventMarketingNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'calendarMarketing'}).href,
          pathName: 'calendarMarketing'
        },
        params: {
          filters: this.filtersValue
        }
      })
    },
    async handleCreateFromEvent(event, type) {
      await this.$router.push({
        name: 'EventMarketingNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'calendarMarketing'}).href,
          pathName: 'calendarMarketing'
        },
        params: {
          startDate: undefined,
          endDate: undefined,
          id: event.id,
          type: type,
          filters: this.filtersValue
        }
      })
    },
    async handleEditEvent(event) {
      let startDate = new Date(event.start)
      let endDate = new Date(event.end)
      await this.$router.push({
        name: 'EventMarketingEditForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'calendarMarketing'}).href,
          pathName: 'calendarMarketing'
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          id: event.id,
          filters: this.filtersValue
        }
      })
    },
    async handleImportEvent(event) {
      let startDate = new Date(event.start)
      let endDate = new Date(event.end)
      await this.$router.push({
        name: 'EventMarketingImportForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'calendarMarketing'}).href,
          pathName: 'calendarMarketing'
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          id: event.id,
          filters: this.filtersValue
        }
      })
    },
    handleGoToLanding(event) {
      if(!!event.action && !!event.action.landingPage) {
        const url = `${process.env.VUE_APP_LANDING_PAGE_BASE_URL}/${this.currentUser.licenses[0].schemaName}/${event.action.landingPage.slug}`
        window.open(url)
      }
    },
    handleMoreEventsClick(params, nativeEvent) {
      nativeEvent.preventDefault();
      nativeEvent.stopPropagation();

      this.moreEventsDialog = true
      this.moreEventsDay = params.date
      this.moreEventsList = this.events.filter(el => {
        const localDate = new Date(new Date(params.date).setHours(0, 0, 0, 0))
        return (el.start <= localDate && el.end >= localDate)
      })
    },
    async handleDayClick({ date }, nativeEvent) {
      nativeEvent.preventDefault();
      nativeEvent.stopPropagation();
      if (this.checkIfPast(date))
        return;

      let startDate = new Date(date);
      let endDate = new Date(date)

      await this.$router.push({
        name: 'EventMarketingNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'calendarMarketing'}).href,
          pathName: 'calendarMarketing'
        },
        params: {
          startDate: startDate,
          endDate: endDate,
          filters: this.filtersValue
        }
      })
    },
    currentMonth() {
      let currentDate = new Date()
      return currentDate.getFullYear().toString() + '-' + (currentDate.getMonth() + 1).toString()
    },
    async handleCalendarChange() {
      this.calendarTitle = this.$refs.calendar.title
    },
    selectMonth() {
      this.focus = new Date(this.selectedMonth)
    },
    async datePickerPrev() {
      await this.$refs.calendar.prev()
      const calendarDate = new Date(this.$refs.calendar.value)
      this.selectedMonth = calendarDate.getFullYear() + '-' + calendarDate.toLocaleString("it-It", { month: "2-digit" }) 
    },
    async datePickerNext() {
      await this.$refs.calendar.next()
      const calendarDate = new Date(this.$refs.calendar.value)
      this.selectedMonth = calendarDate.getFullYear() + '-' + calendarDate.toLocaleString("it-It", { month: "2-digit" }) 
    },
    calculateEventColor(event) {
      const eventStart = new Date(event.from)
      const eventEnd = new Date(event.to)
      const today = new Date()

      if (!!event.master && !event.importedFromId) {
        return '#ada2e0'
      }

      if (!!event.archived) {
        return '#cccccc'
      }

      if (event.status == 'draft') {
        if (today > eventEnd)
          return 'grey'

        return '#ffda85'
      }

      if (event.status == 'approved') {
        if (today > eventEnd)
          return '#cccccc'

        if (today >= eventStart  && today <= eventEnd) {
          return '#9abcea'
        }

        return '#bfec83'
      }

      return 'red'
    },
    print() {
      let id = 'content-to-print'
      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Calendario_Marketing_" + this.calendarTitle.replace(' ', '_') + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    },
  },
  computed: {

  },
  watch: {
    async selectedMonth(newVal) {
      let date = new Date(newVal)
      
      this.filtersValue.month = date.getMonth()
      this.filtersValue.year = date.getFullYear()

      await this.loadMarketingEvents()
    },
  },
  currentUser: {
    bind: 'currentUser'
  }
};
</script>

<style>
  .title-custom {
    height: 2em;
    padding-right: 1em;
    padding-left: 0.4em;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background-color: var(--background-color);
    font-size: 18px;
    color: #8a77e2;
    font-weight: 500;
    text-align: center;
    text-transform: none;
  }

  .sidebar-calendar .v-picker__body {
    width: 100% !important;
  }
  
  .v-calendar .v-event-more {
    font-weight: 500;
  }

  .status-div {
    border: thin solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 5px;
  }
</style>