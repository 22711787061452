<script>
import { Line, mixins } from 'vue-chartjs'
import { getHexColorWithOpacity, hexToRgbA } from './colors'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['options'],
  mounted () {
    const canva = this.$refs.canvas
      .getContext('2d')

    for(let i = 0; i < this.chartData.datasets.length; i++) {
      const color = this.chartData.datasets[i].color

      /* 
      let gradient = canva.createLinearGradient(0, 0, 0, 450)
      gradient.addColorStop(0, hexToRgbA(getHexColorWithOpacity(color, 0.9)))
      gradient.addColorStop(0.7, hexToRgbA(getHexColorWithOpacity(color, 0.5)));
      gradient.addColorStop(1, hexToRgbA(getHexColorWithOpacity(color, 0.4))); 
      this.chartData.datasets[i].backgroundColor = gradient
      */

      this.chartData.datasets[i].borderColor = color
      //if(!this.chartData.datasets[i].borderWidth)
        this.chartData.datasets[i].borderWidth = 2
      //if(!this.chartData.datasets[i].pointRadius)
        this.chartData.datasets[i].pointRadius = 2
    }

    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style>

</style>