<template>
  <v-autocomplete
    v-model="selected"
    :items="tagsCategories"
    item-text="name"
    :item-value="itemValue"
    filled
    :label="label"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :dense="dense"
    :multiple="multiple"
    :clearable="clearable"
    :loading="localLoading"
    :return-object="returnObject"
    :prepend-icon="prependIcon"
    :chips="chips"
    :disabled="disabled"
    :readonly="readonly"
    :deletable-chips="deletableChips"
    :rounded="rounded"
    @input="emitInput"
    :rules="rules"
  >
    <template slot="append-outer">
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0">
        {{ item['name'] }}
      </span>
      <span
        v-else-if="index === 1"
        class="grey--text text-caption"
      >
        &nbsp (e altri {{ selected.length - 1 }})
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import tagsCategoriesService from '@/services/tagsCategories/tagsCategories.service.js'

export default {
  name: "TagsCategoriesAutocomplete",
  data: function() {
    let selection = this.value; 

    return {
      tagsCategories: [],
      selected: selection,
      localLoadingList: false
    }
  },
  props: {
    value: {
      default: function() {
        return []
      }
    },
    rules: {
      type: Array,
      default: function() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: undefined
    },
    dense: {
      type: Boolean,
      default: true
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'Categorie Tag'
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      default: 'auto'
    },
    prependIcon: {
      type: String,
      default: 'mdi-tag-multiple'
    },
    preloadAll: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted: function() {
    this.loadTagsCategories()
  },
  methods: {
    loadTagsCategories() {
      this.localLoadingList = true
      tagsCategoriesService.list(1, 4000, this.filters).then((response) => {
        this.tagsCategories = response.rows
        if (!!this.preloadAll) {
          this.selected = !!this.returnObject ? response.rows : response.rows.map(el => el.id)
          this.emitInput()
        }
        this.localLoadingList = false
      })
    },
    emitInput() {
      this.$emit('input', this.selected)
    }
  },
  computed: {
    localLoading() {
      return this.localLoadingList || this.loading
    }
  },
  watch: {
    value(newVal) {
      this.selected = newVal
    }
  }
}
</script>

<style>

</style>