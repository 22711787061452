class AnalyticNamesMappings {
  constructor() {
    this.nameMappings = {
      'registry': ['Dati', 'Anagrafici'],
      'turns': ['Orari di', 'Apertura'],
      'assistence': ['Assistenza'],
      'permissions': ['Permessi'],
      'controlPanel': ['Pannello', 'di Controllo'],
      'wheelOfFortune': ['Preset Ruota Della Fortuna’'],
      'developer': ['Sviluppatore'],
    }

    this.iconAnalyticsMappings = {
      'registry': 'mdi-book',
      'turns': 'mdi-store-clock',
      'assistence': "mdi-bell-ring",
      'permissions': 'mdi-account-cog',
      'controlPanel': 'mdi-bulletin-board',
      'wheelOfFortune': 'mdi-dharmachakra',
      'developer': 'mdi-code-json',
    }

    this.historyOperationsOpsMappings = {
      'create': 'Creazione',
      'update': 'Modifica',
      'delete': 'Eliminazione',
      'permission': 'Permessi'
    }
  }

  getHumanName(codedName) {
    return this.nameMappings[codedName]
  }

  getAnalyticIcon(analyticName) {
    return this.iconAnalyticsMappings[analyticName]
  }

  getBalanceTagsName(codeName) {
    return this.balangeByTagsMappings[codeName]
  }

  getOperatorBalanceByTags(codeName) {
    return this.operatorsBalanceByTagsMapping[codeName]
  }

  getHistoryOperationsOp(codename) {
    return this.historyOperationsOpsMappings[codename]
  }

  getHistoryOperationsContext(codename) {
    return this.historyOperationsContextMappings[codename]
  }

  getHistoryOperationsDetails(codename) {
    return this.historyOperationsDetailsMapping[codename]
  }
}

export default new AnalyticNamesMappings()