<template>
  <div
    class="relative mx-auto bg-gray-800"
    :style="{
      'border-width': '14px', 
      'border-radius': '2.5rem', 
      'width': this.width, 
      'height': this.height
    }"
  >
    <div
      class="mx-auto bg-gray-800 rounded-l-lg"
      style="position: absolute; width: 3px; height: 32px; top: 72px; left: -3px"
    ></div>
    <div
      class="mx-auto bg-gray-800 rounded-l-lg"
      style="position: absolute; width: 3px; height: 46px; top: 124px; left: -3px"
    ></div>
    <div
      class="mx-auto bg-gray-800 rounded-l-lg"
      style="position: absolute; width: 3px; height: 32px; top: 178px; left: -3px"
    ></div>
    <div
      class="mx-auto bg-gray-800 rounded-l-lg"
      style="position: absolute; width: 3px; height: 64px; top: 142px; right: -3px"
    ></div>
    <div
      style="border-radius: 2rem; overflow: hidden; width: 91%; height: 95%; 
        background-color: white; right: 4.3%; top: 2.3%; position: absolute"
    >
      <div
        v-if="!!smsModel && !!smsModel.text && smsModel.text != ''"
        class="mt-1 d-flex justify-center"
      >
        {{ sendTime.length === 8 ? sendTime.slice(0, -3) : sendTime }}
      </div>
      <div 
        class="yours messages mt-1"
        :style="{
          'max-height': this.$vuetify.breakpoint.xl ? '92%' : '92%',  
          'overflow': 'auto'
        }"  
      >
        <div
          v-if="!!smsModel && !!smsModel.text && smsModel.text != ''"
          :style="{
            'max-width': '80%',
            'font-size': '0.7rem',
            'margin-left': this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ?
              '0.5vw' : this.$vuetify.breakpoint.md ? '0.5vw' : '0.5vw'
          }"
          class="message last"
          ref="mobilePreview"
        >
          {{ calculateMessageText(smsModel) }}
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import galleryService from "@/services/gallery/gallery.service";

export default {
  name: 'SmsMobilePreview',
  components: {},
  data: function() {
    return {
      localSelectedImage: this.selectedImage,
    }
  },
  props: {
    selectedImage: {
      type: Object,
      default: () => undefined
    },
    smsModel: {
      type: Object,
      default: () => {}
    },
    sendTime: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: '300px'
    },
    height: {
      type: String,
      default: '600px'
    }
  },
  mounted() {
    if (!!this.selectedImage && this.selectedImage.id !== undefined 
      && this.selectedImage.url === undefined) {
      
      galleryService.get(this.selectedImage.id, this.selectedImage.isUserUploaded).then((result) => {
        this.localSelectedImage = result
        //this.calculateMessageText(this.smsModel)
      })
    }
    else {
      this.localSelectedImage = this.selectedImage
      //this.calculateMessageText(this.smsModel)
    }
  },
  methods: {
    createPlaceholderDomElement(name, text) {
      let placeholderToInsert = document.createElement('span')
      placeholderToInsert.style.fontSize = "14px"
      placeholderToInsert.style.color = "#8a77e2"
      placeholderToInsert.setAttribute("data-name", name)
      placeholderToInsert.innerHTML = text
      return placeholderToInsert
    },
    calculateMessageText(smsModel) {
      let finalText = smsModel.text

      if (!finalText) finalText = ''

      if (!!smsModel.values && smsModel.values.length > 0) {
        for (let i = 0; i < smsModel.values.length; i++) {
          const name = smsModel.values[i].name
          const text = smsModel.values[i].text
          const spanElement = this.createPlaceholderDomElement(name, text).outerHTML
          finalText = finalText.replaceAll('<' + name + '>', spanElement)

        }
      }

      let urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      finalText = finalText.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
      });

      const imgTag = this.localSelectedImage?.url
        ? `<img src="${this.localSelectedImage?.url}" width="150px" height="150px" style="object-fit: cover;" />`
        : ''

      this.$nextTick(() => {
        this.$refs.mobilePreview.innerHTML = finalText + imgTag
      })

    },
  },
  watch: {
    selectedImage: {
      handler: function (val) {
        if (!!val && val.id !== undefined && val.url === undefined) {
          galleryService.get(val.id).then((result) => {
            this.localSelectedImage = result
          })
        }
        else {
          this.localSelectedImage = val
        }
      },
      deep: true,
    }
  },
}
</script>

<style>
.messages {
  display: flex;
  flex-direction: column;
}

.yours {
  align-items: flex-start;
}

.message {
  border-radius: 17px;
  padding: 8px 15px;
  display: inline-block;
  white-space: pre-line;
}

.yours .message {
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}

.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
</style>