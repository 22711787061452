const hairColorsRoutes = [
  {
    path: "/registries/hairColors",
    name: "HairColorsRegistryNewForm",
    component: () => import("@/components/areas/registries/hairColors/HairColorsRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
  },
  {
    path: "/registries/hairColors/:id/edit",
    name: "HairColorsRegistryEditForm",
    component: () => import("@/components/areas/registries/hairColors/HairColorsRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
  },
  {
    path: "/registries/hairColors/list",
    name: "HairColorsRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/hairColors/HairColorsRegistryList.vue"
      ),
  }
];

export default hairColorsRoutes;