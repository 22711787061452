<template>
  <StandardDialog
    without-padding
    v-model="localDialog"
    @input="$emit('update:dialog', $event)"
    :dialog-height="null"
    :title="dateToString('dd/mm/yyyy', date)"
  >
    <v-list two-line>
      <template v-for="shift in shifts">
        <v-list-item v-bind:key="shift.id">
          <v-list-item-content class="text-left">
            <v-row class="ml-2">
              <v-col class="pa-0" md="auto">
                <v-list-item-title>{{ shift.timeTable.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-for="(turn, index) in shift.timeTable.timeTablesTurns" :key="turn.id">
                    dalle {{turn.start.slice(0, 5)}} alle {{turn.stop.slice(0, 5)}}
                    <span
                      v-if="index != shift.timeTable.timeTablesTurns.length - 1"
                    >,</span>
                  </span>
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="deleteShift(shift)" icon>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="shift.id + '-divider'"></v-divider>
      </template>
    </v-list>
  </StandardDialog>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import shiftService from "@/services/presences/shifts/shifts.service.js";
import StandardMenu from "@/components/common/StandardMenu.vue";
import dateUtils from "@/mixins/common/dateUtils";

export default {
  name: "DayTimeLogs",
  components: {
    StandardDialog,
    StandardMenu
  },
  mixins: [dateUtils],
  data: function() {
    return {
      localDialog: this.dialog,
      menus: {},
      shifts: this.value,
    };
  },
  mounted: function() {
  },
  props: {
    value: {
      type: Array
    },
    dialog: {
      type: Boolean,
      default: false
    },
    date: {}
  },
  methods: {
    closeMenu(timeLog) {
      this.menus[timeLog.id] = false;
    },
    deleteShift(shift) {
      let confirmed = confirm("Vuoi davvero eliminare il turno ?");
      if (confirmed) {
        shiftService.delete(shift).then(results => {
          this.shifts = this.shifts.filter(el => el.id != shift.id)
        });
      }
    }
  },
  watch: {
    value(newVal) {
      this.shifts = newVal;
    },
    dialog(newVal) {
      this.localDialog = newVal;
    },
  }
};
</script>

<style>
</style>