import axios from '@/services/axios'
import UrlKeeper from '@/services/UrlKeeper.js';
import eventsHandler from '@/services/common/eventsHandler'
import socketService from '@/services/socket/socket.service'
import baseLicenseSettings from './baseLicenseSettings'

class licenseSettingsService extends eventsHandler {
  constructor() {
    super()
    this.licenseSettings = {}
    this.baseLicenseSettings = baseLicenseSettings

    /*socketService.on('licenseSettings:set', (data) => {
      if (!!data.name) {
        this.get(data.name).then((setting) => {
          this._executeAsyncCallbacksOf('update', { name: data.name, value: this.licenseSettings[data.name].value })
          this._executeAsyncCallbacksOf('update-' + data.name, this.licenseSettings[data.name].value)
        })
      }
    })*/
  }

  get(name) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/admin/licenseSettings/get', { name: name }).then((response) => {
        if (response.success) {
          if (!this.licenseSettings[name]) {
            this.licenseSettings[name] = this.baseLicenseSettings[name]
          }
          this.licenseSettings[name] = response.results
          resolve(this.licenseSettings[name])
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getFromCache(name) {
    return this.licenseSettings[name] !== null && this.licenseSettings[name] !== undefined ? this.licenseSettings[name].value : this.baseLicenseSettings[name]
  }

  list(category) {
    return new Promise((resolve, reject) => {
      if (!category) {
        resolve(this.settings)
      } else {
        let results = {};
        for (let name in this.settings) {
          if (this.settings.hasOwnProperty(name) && this.settings[name]['category'] === category) {
            results[name] = this.settings[name];
          }
        }

        resolve(results);
      }
    })
  }

  load() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!apiUrl) {
        reject(new Error('not-found'))
        return
      }

      axios.get(apiUrl + '/admin/licenseSettings/publicList').then((response) => {
        for (let i = 0; i < response.results.results.length; i++) {
          if (this._isArray(response.results.results[i].value) || this._isObject(response.results.results[i].value) || response.results.results[i].value !== this.licenseSettings[response.results.results[i].name]) {
            this._executeAsyncCallbacksOf('update', { name: response.results.results[i].name, value: response.results.results[i].value })
            this._executeAsyncCallbacksOf('update-' + response.results.results[i].name, response.results.results[i].value)
          }
          this.licenseSettings[response.results.results[i].name] = response.results.results[i]
        }
        resolve(this.licenseSettings)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  clear() {
    this.licenseSettings = {}
  }

  _isArray(value) {
    return Array.isArray(value)
  }

  _isObject(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value)
  }
}

export default new licenseSettingsService()