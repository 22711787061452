var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"nudge-bottom":20,"elevation":0,"content-class":"elevation-2","offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.activatorIcon))])],1)]},null,{on, attrs})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-avatar',{attrs:{"color":"primary"}},[(_vm.avatarUrl)?_c('v-img',{attrs:{"src":_vm.avatarUrl,"alt":"avatar image"}}):(_vm.showNameInitials)?_c('span',[_vm._v(_vm._s(_vm.nameInitials))]):_c('span',[_c('v-icon',[_vm._v("mdi-account")])],1)],1)],1),_c('h4',{staticClass:"text-center mt-1"},[_vm._v(" "+_vm._s(_vm.fullname)+" ")]),_c('div',{staticClass:"caption text-center my-1"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),_c('v-divider'),_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.version)}})],1),_vm._t("options",function(){return [_c('SimpleList',{attrs:{"items":[
            /*{
              title: 'Profile',
              icon: 'mdi-account',
              emit: 'profile-click'
            },*/
            {
              title: 'Logout',
              icon: 'mdi-exit-to-app',
              emit: 'logout-click'
            },
          ]},on:{"item-click":_vm.handleItemClick}})]},{"user":_vm.user})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }