import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// icons
import BeardIcon from "./icons/BeardIcon"

Vue.use(Vuetify);
const vuetifyOpts = {
  rtl: false,
  theme: {
    dark: false,
/*     colorizeAppSelector: {
      primary: "#00897B"
    }, */
    themes: {
      dark: {
        primary: "#8a77e2",
        accent: "#FF4081",
        secondary: "#50bfe1",
        tertiary: "#FF9800",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
        background: "#092927"
      },
      light: {
        primary: "#8a77e2",
        accent: "#e91e63",
        secondary: "#50bfe1",
        tertiary: "#FF9800",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
        background: "#F4F4FA"
      },
    },
  },
  icons: {
    values: {
      beard: {
        component: BeardIcon
      },
    },
  },
};

export default new Vuetify(vuetifyOpts);
