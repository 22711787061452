<template>
  <div>
    <v-text-field
      class="pa-0 ma-0"
      hide-details="auto"
      v-model="localValue"
      @change="handleInput"
      filled
      dense
      :label="label"
      :rules="[presenceRule]"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "TextFieldSetting",
  data: function () {
    return {
      localValue: this.value,
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
    };
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    settingKey: {
      type: String,
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  methods: {
    handleInput() {
      this.$emit("input", this.localValue);
      this.$emit("set", this.settingKey, this.localValue);
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  }
};
</script>
