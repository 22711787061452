const itemsRoutes = [
  {
    path: "/warehouse/item/:id",
    name: "ItemHome",
    component: () => import("@/components/areas/warehouse/items/ItemHome.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tab,
        filters: route.params.filters,
      };
    },
  },
  {
    path: "/warehouse/items/list",
    name: "ItemList",
    component: () => import("@/components/areas/warehouse/items/ItemList.vue"),
    props: (route) => {
      return {
        filters: route.params.filters,
      };
    }
  },
];

export default itemsRoutes;
