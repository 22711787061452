import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class TagsService {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/tags/tags/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  create(tag) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/tags/tags/create', tag).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(tag) {
    const apiUrl = UrlKeeper.getUrl()

    if (!tag || !tag.id) {
      reject(new Error('tag must be specified'))
      return
    }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/tags/tags/update', tag).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(tag) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!tag || !tag.id) {
        reject(new Error('tag must be specified'))
        return
      }

      axios.get(apiUrl + '/tags/tags/get', { id: tag.id }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  delete(tag) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!tag || !tag.id) {
        reject(new Error('tag must be specified'))
        return
      }

      axios.get(apiUrl + '/tags/tags/delete', { id: tag.id }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  findeByDescription(description) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!description) {
        reject(new Error('description must be specified'))
        return
      }

      axios.get(apiUrl + '/tags/tags/findByDescription', { description: description }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new TagsService()