import eventsHandler from '@/services/common/eventsHandler.js'

class otherDialogForm extends eventsHandler {
  constructor() {
    super()
    this._otherCosts = {}
    this._valid = false
  }

  get otherCost() {
    const otherCost = {
      ...this._otherCosts
    }
    return otherCost
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._otherCosts.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetOtherCosts() {
    this._otherCosts = {
      type: 'standard'
    }
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {otherCost: {}})
  }

  updateField(key, value) {
    this._otherCosts[key] = value
    this._executeCallbacksOf('update', {otherCost: this._otherCosts})
    this._executeCallbacksOf('update-' + key, {key: this._otherCosts[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(otherCost) {
    this._otherCosts = otherCost
    this._executeCallbacksOf('update', {otherCost: this._otherCosts})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new otherDialogForm()