<template>
  <div style="overflow: auto;">
    <TitleWithBackButton
      title="Frequenza Clienti"
      back-route="Analytics"
    ></TitleWithBackButton>
    <div class="ml-3 mt-3">
<!--       <DateTimePicker
          v-model="startDate"
          filled
          dense
          hide-details="auto"
          label="Inizio"
          :show-time="false"
          :loading="loading"
          readonly
      >
      </DateTimePicker>  
      <DateTimePicker
          v-model="endDate"
          filled
          dense
          hide-details="auto"
          label="Fine"
          :show-time="false"
          :loading="loading"
          readonly
      >
      </DateTimePicker>  
      <v-btn
        class="mr-2 ml-2"
        text
        @click="() => {this.loadChartSummary(), this.loadSummary();}"
        :loading="loading"
        :disabled="!startDate || !endDate || tableOrSummaryIndex === 1"
      >Calcola</v-btn> -->
      <v-btn class="mr-2 ml-2" text @click="() => {this.summaryKind = 'week', this.loadChartSummary();}" :loading="loading">
          Settimanale</v-btn>
      <v-btn class="mr-2 ml-2" text @click="() => {this.summaryKind = 'month', this.loadChartSummary();}" :loading="loading">
          Mensile</v-btn>
      <v-btn class="mr-2 ml-2" text @click="() => {this.summaryKind = 'quarter', this.loadChartSummary();}" :loading="loading">
          Trimestrale</v-btn>
      <v-btn
        class="mr-2 ml-2"
        style="float: right"
        text
        @click="print"
        :loading="loading"
        :disabled="!startDate || !endDate || tableOrSummaryIndex === 1"
      >Stampa</v-btn>
    </div>
    <v-window
      v-model="tableOrSummaryIndex"
    >
      <v-window-item>
        <v-container fluid class="mt-2">
          <v-row>
            <v-col cols="12">
              <div class="pa-3 d-flex justify-center align-center">
                <v-autocomplete
                  label="Riepilogo Anno"
                  v-model="summaryYear"
                  :items="summaryYearOptions"
                  filled
                  dense
                  hide-details="auto"
                  :menu-props="{
                    offsetY: true
                  }"
                  style="max-width: 250px"
                  class="mr-2"
                  @input="() => {this.loadChartSummary(), this.loadSummary();}"
                ></v-autocomplete>
              </div>
            </v-col>
          </v-row>
          <div id="chart">
            <v-row>
              <v-col cols="12">
                <div 
                  class="d-flex justify-center"
                >
                  <DateBasedChart
                    :getYValue="(object) => {
                      return object[`Balance.customerNumber`]
                    }"
                    :getXDate="getXDate"
                    height="50vh"
                    width="90vw"
                    :data="[data]"
                    :tooltip-label-modifier="(tooltipLabel) => {
                      return 'N° Clienti: ' + tooltipLabel.yLabel
                      }"
                    :colors="colorSet"
                    :point-colors="colorSet"
                    :loading="chartLoading"
                    :filters="chartFilters"
                    :granularity="summaryKind"
                  ></DateBasedChart>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <DynamicContentTable
                  v-if="!summaryLoading"
                  :headers="headersSummary"
                  headers-key="value"
                  headers-text="text"
                  headers-width="minWidth"
                  :content-rows="summaryRows"
                  content-rows-key="customerId"
                  :loading="loading"
                  :show-load="false"
                  @click-on-cell="clickOnCell"
                >
                </DynamicContentTable>
                <v-progress-circular
                  v-else
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-window-item>
    </v-window>
    <StandardDialog
      v-model="messageToSend"
          :dialog-height="null"
          dialog-width="45%"
          title="Messaggio"
        >
          <SendSmsModel
            class="mt-4"
            :customers="customersToMsg"
            :numbers="numbersToMsg"
            @input="messageToSend = false"
            :whatsapp="true"
          ></SendSmsModel>

        </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import SendSmsModel from '@/components/calendar/SendSmsModel.vue';
import DynamicContentTable from '@/components/common/DynamicContentTable.vue';
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import DateBasedChart from '@/components/charts/DateBasedChart.vue'
import customersService from '@/services/customers/customers.service.js'
import balanceService from '@/services/balance/balance.service.js'
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import _ from 'lodash'
import html2canvas from '@/assets/js/html2canvas.min.js'

export default {
  name: "CustomersFrequencyList",
  components: {
    DateTimePicker,
    TitleWithBackButton,
    SendSmsModel,
    DynamicContentTable,
    RangeDateSelector,
    StandardDialog,
    DateBasedChart
  },
  data: function() {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), 0, 1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0)

    var lastDay = new Date();
    lastDay.setHours(0);
    lastDay.setMinutes(0);
    lastDay.setSeconds(0);

    return {
      results: undefined,
      summaryResults: [],
      weeklyCustomers: undefined,
      monthlyCustomers: undefined,
      quarterCustomers: undefined,
      occasionalCustomers: undefined,
      startDate: firstDay,
      endDate: lastDay,
      loading: false,
      chartLoading: false,
      summaryLoading: false,
      page: 1,
      tableOrSummaryIndex: 0,
      messageToSend: false,
      summaryYearOptions: [...Array(31).keys()].map(el => el + 2020),
      summaryYear: new Date().getUTCFullYear(),
      summaryKindOptions: [
        {
          value: 'week',
          text: 'Settimanale'
        }, 
        {
          value: 'month',
          text: 'Mensile'
        },
        {
          value: 'quarter',
          text: 'Trimestrale'
        }
      ],
      summaryKind: 'month',
      summaryValue: [],
      colorSet: ['rgba(138, 119, 226,0.5)', 'rgba(255,0,0,0.5)', 'rgba(255,0,255,0.5)', 'rgba(0,0,255,0.5)', 'rgba(236,125,16,0.5)', 'rgba(204,197,185,0.5)', 'rgba(236,90,131,0.5)', 'rgba(255,255,0,0.5)', 'rgba(0,255,255,0.5)', 'rgba(255,0,255,0.5)', 'rgba(169,113,75,0.5)', 'rgba(89.89.89,0.5)'],
      pointColorSet: ['rgba(138, 119, 226,1)', 'rgba(255,0,0,1)', 'rgba(255,0,255,1)', 'rgba(0,0,255,1)', 'rgba(236,125,16,1)', 'rgba(204,197,185,1)', 'rgba(236,90,131,1)', 'rgba(255,255,0,1)', 'rgba(0,255,255,1)', 'rgba(255,0,255,1)', 'rgba(169,113,75,1)', 'rgba(89.89.89,1)'],
      data: {},
      monthMapper: {
        'January': 0,
        'Febraury': 1,
        'March': 2,
        'April': 3,
        'May': 4,
        'June': 5,
        'July': 6,
        'Augus': 7,
        'September': 8,
        'October': 9,
        'November': 10,
        'December': 11,
      },
      labelModifier: ({ month, monthNameMapper, granularity, label, date }) => {
        if (granularity === 'month') {
          return monthNameMapper[month]
        } else if (granularity === 'week') {
          return 'week'
        } 
        else {
          return `${date.getDate()}/${date.getMonth() + 1}`
        }
      },
      chartFilters: undefined,
      localWeeklyCustomers: undefined,
      localMonthlyCustomers: undefined,
      localQuarterCustomers: undefined,
      localOccasionalCustomers: undefined,
      localTotalCustomers: undefined,
      customersToMsg: [],
      numbersToMsg: []
    }
  },
  mounted: async function() {
    await this.loadChartSummary()
    await this.loadSummary()
  },
  methods: {
    handleTooltipClick(value) {
      if (!!value) {
        if (this.summaryKind == 'week') {
          let firstDay = (1 + (Number(value['xLabel'].substring(5)) - 1) * 7);
          let dateFromWeek = new Date(this.summaryYear, 0, firstDay)
          this.startDate = dateFromWeek
          
          let localEndDate = new Date(dateFromWeek.getFullYear(), dateFromWeek.getMonth(), dateFromWeek.getDate())
          this.endDate = localEndDate
          this.endDate.setDate(localEndDate.getDate() + 7)
        } else if (this.summaryKind == 'month') {
          let monthValue = this.monthMapper[value['xLabel'].substring(0, value['xLabel'].indexOf(' '))]
          let firstDay = new Date(this.summaryYear, monthValue, 1)
          this.startDate = firstDay

          let lastDay = new Date(this.summaryYear, monthValue + 1, 0);
          lastDay.setHours(0)
          lastDay.setMinutes(0)
          this.endDate = lastDay
        } else if (this.summaryKind == 'quarter') {
          let monthValue = Math.floor((Number(value['xLabel'].substring(8)) * 3) - 3)
          let firstDay = new Date(this.summaryYear, monthValue, 1)
          this.startDate = firstDay

          let lastDay = new Date(this.summaryYear, monthValue + 3, 0);
          lastDay.setHours(0)
          lastDay.setMinutes(0)
          this.endDate = lastDay
        }

        this.tableOrSummaryIndex = 0
        this.calculate()
      }      
    },
    getXDate(object) {
      let date = new Date(object[`Balance.billDate.${this.summaryKind}`])
      return date
    },
    calculate() {
      this.loading = true

      this.page = 1
      customersService.list_pagination(this.page).then(({ rows: customers}) => {
        this.getResultsForCustomers(customers.map((cus) => cus.id)).then((results) => {
          this.results = results
          this.loading = false
        })
      })
    },
    loadMore() {
      this.page += 1
      this.loading = true
      customersService.list(this.page, 50).then(({rows: customers}) => {
        this.getResultsForCustomers(customers.map((cus) => cus.id)).then((results) => {
          this.results = [
            ...this.results,
            ...results
          ]
          this.loading = false
        })
      })
    },
    async customersSummary(year, granularity) {
      let localStartDate = new Date(year, 0, 1)
      let localEndDate = new Date(year, 11, 31)

      const results = await balanceService.periodSummary({
        from: localStartDate,
        to: localEndDate,
        granularity: granularity,
      })

      return results
    },
    loadChartSummary() {
      this.chartFilters = {
        from: new Date(this.summaryYear, 0, 1),
        to: new Date(this.summaryYear, 11, 31)
      }

      this.chartLoading = true

      this.getPeriodSummary(this.summaryYear, this.summaryKind).then((results) => {
        this.data = {
          name: this.summaryKind,
          label: 'N° Clienti',
          year: this.summaryYear,
          data: results
        }
        this.chartLoading = false
      })
    },
    async loadSummary() {    
      this.summaryLoading = true
      let today = new Date()
      let requiredWeekly = 52
      let requiredMonthly = 12
      let requiredQuarter = 4
      
      if (this.summaryYear == today.getFullYear()) {
        let actualMonth = today.getMonth() + 1
        let actualQuarter = Math.floor(today.getMonth() / 3 + 1);

        requiredWeekly = Math.floor((52 * Number(actualMonth)) / 12)
        requiredQuarter = actualQuarter
        requiredMonthly = actualMonth
      }

      let localSummaryResults = await this.getPeriodSummary(this.summaryYear, 'year')
      this.localTotalCustomers = await this.customersSummary(this.summaryYear, 'year')
      this.localWeeklyCustomers = await this.customersSummary(this.summaryYear, 'week')
      this.localMonthlyCustomers = await this.customersSummary(this.summaryYear, 'month')
      this.localQuarterCustomers = await this.customersSummary(this.summaryYear, 'quarter')
      this.localOccasionalCustomers = await this.customersSummary(this.summaryYear, 'day')
      let localTotalVisits = await this.getTotalVisits(this.summaryYear, 'year')
      let totalTransactions = await this.getTotalTransactions(this.summaryYear, 'year')

      this.summaryResults = []
      this.summaryResults.push({
        'totalCustomers': await localSummaryResults[0]['Balance.customerNumber'],
        'weeklyCustomers': await this.calculatePeriodSummary('Balance.customerId', this.localWeeklyCustomers, requiredWeekly),
        'monthlyCustomers': await this.calculatePeriodSummary('Balance.customerId', this.localMonthlyCustomers, requiredMonthly),
        'quarterCustomers': await this.calculatePeriodSummary('Balance.customerId', this.localQuarterCustomers, requiredQuarter),
        'occasionalCustomers': await this.calculatePeriodSummary('Balance.customerId', this.localOccasionalCustomers, 1),
        'totalVisits': await localTotalVisits[0]['UniqueBalanceVisits.totalVisits'],
        'totalTransactions': await totalTransactions[0]['PaymentTransactions.count'],
      })

      this.summaryLoading = false

      
    },
    async calculatePeriodSummary(objectId, object, requiredLength) {
      return Object.keys(Object.fromEntries(Object.entries(_.groupBy(object, el => el[objectId])).filter(([key, val]) => val.length === requiredLength))).length
    },
    async getPeriodSummary(year, granularity) {
      let startDate = new Date()
      startDate.setMonth(0)
      startDate.setDate(1)
      startDate.setFullYear(year)
      let endDate = new Date()
/*       endDate.setMonth(11)
      endDate.setDate(31)
      endDate.setFullYear(year) */
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      
      const results = await balanceService.customersFrequencySummary({
        from: startDate,
        to: endDate,
        granularity: granularity,
      })

      return results

    },
    async getTotalVisits(year, granularity) {
      let startDate = new Date()
      startDate.setDate(1)
      startDate.setMonth(0)
      startDate.setFullYear(year)
      let endDate = new Date()
      endDate.setDate(31)
      endDate.setMonth(11)
      endDate.setFullYear(year)
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      
      
      const results = await balanceService.totalVisits({
        from: startDate,
        to: endDate,
        granularity: granularity,
      })

      return results

    },

    async getTotalTransactions(year, granularity) {
      let startDate = new Date()
      startDate.setDate(1)
      startDate.setMonth(0)
      startDate.setFullYear(year)
      let endDate = new Date()
      endDate.setDate(31)
      endDate.setMonth(11)
      endDate.setFullYear(year)
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      
      
      const results = await balanceService.totalTransactionsByDate({
        from: startDate,
        to: endDate,
        granularity: granularity,
      })

      return results

    },

    async getResultsForCustomers(customerId) {
      const startDate = this.startDate,
      endDate = this.endDate
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      const results = await balanceService.customersFrequency({
        from: startDate,
        to: endDate,
        customerIds: customerId
      })
      return results
    },
    async clickOnCell(row){
      let mapped = []
      if(row.headersKey == "weeklyCustomers"){
        mapped = this.calculatePeriodIds(this.localWeeklyCustomers, 52)
      } else if(row.headersKey == "monthlyCustomers"){
        mapped = this.calculatePeriodIds(this.localMonthlyCustomers, 12)
      } else if(row.headersKey == "quarterCustomers"){
        mapped = this.calculatePeriodIds(this.localQuarterCustomers, 4) 
      } else if(row.headersKey == "occasionalCustomers"){
        mapped = this.calculatePeriodIds(this.localOccasionalCustomers, 1)
      } else {
        mapped = this.calculatePeriodIds(this.localTotalCustomers, 0, true)
      }
      this.customersToMsg = (await customersService.getFromListIds(mapped)).rows.filter(elem=>elem.cellphone!=null && elem.cellphone!=undefined)
      this.numbersToMsg = this.customersToMsg.map(elem => elem.cellphone)
      this.messageToSend = true
    },
    calculatePeriodIds(object, requiredLength, isTotal = false) {
      if(!isTotal)
        return Object.keys(Object.fromEntries(Object.entries(_.groupBy(object, el => el['Balance.customerId'])).filter(([key, val]) => val.length === requiredLength)))
      else 
        return Object.keys(Object.fromEntries(Object.entries(_.groupBy(object, el => el['Balance.customerId']))))
    },
    print() {
      this.printLoading = true

      let beginningOfDay = new Date(this.focus);
      html2canvas(document.getElementById('chart'), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Frequenza_Clienti_" + new Date(this.startDate).toLocaleDateString('it-IT') + '-' + new Date(this.endDate).toLocaleDateString('it-IT') + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
    customersRows() {
      if(!this.results || this.results.length == 0) {
        return []
      }

      let results = [], currentIndex = 0

      for (let i = 0; i < this.results.length; i += 1) {
        if(!results[currentIndex] || results[currentIndex].customerId != this.results[i]['Balance.customerId']) {
          results.push({
            customerId: this.results[i]['Balance.customerId'],
            fullname: this.results[i]['Balance.customerFullName'],
            cellphone: this.results[i]['Balance.customerCellphone'],
            email: this.results[i]['Balance.customerEmail'],
            city: this.results[i]['Balance.customerCity'],
            quantity: this.results[i]['Balance.billNumber'],
            prefix:  this.results[i]['Balance.customerPrefixCellphone'],
          })
          currentIndex = results.length - 1
        }
      }
      return _.orderBy(results, ['quantity'], ['desc'])
    },
    summaryRows() {
      if(!this.summaryResults || this.summaryResults.length == 0) {
        return []
      }

      let results = []

      for (let i = 0; i < this.summaryResults.length; i += 1) {
        results.push({
          totalCustomers: this.summaryResults[i]['totalCustomers'],
          totalVisits: this.summaryResults[i]['totalVisits'],
          weeklyCustomers: this.summaryResults[i]['weeklyCustomers'],
          monthlyCustomers: this.summaryResults[i]['monthlyCustomers'],
          quarterCustomers: this.summaryResults[i]['quarterCustomers'],
          occasionalCustomers: this.summaryResults[i]['occasionalCustomers'],
          totalTransactions: this.summaryResults[i]['totalTransactions'],


        })
      }

      return results
    },
    headersSummary() {
      if (!this.summaryResults || this.summaryResults.length == 0) return []
      else return [
        {
          value: 'totalVisits',
          text: 'Visite Totali',
          minWidth: '80px'
        },
        {
          value: 'totalCustomers',
          text: 'N° Clienti Totali',
          minWidth: '80px'
        },
        {
          value: 'weeklyCustomers',
          text: 'N° Clienti Settimanali',
          minWidth: '80px'
        },
        {
          value: 'monthlyCustomers',
          text: 'N° Clienti Mensili',
          minWidth: '80px'
        },
        {
          value: 'quarterCustomers',
          text: 'N° Clienti Trimestrali',
          minWidth: '80px'
        },
        {
          value: 'occasionalCustomers',
          text: 'N° Clienti Occasionali',
          minWidth: '80px'
        },
        {
          value: 'totalTransactions',
          text: 'Passaggi Cassa Totali',
          minWidth: '80px'
        },
      ]
    },
    headers() {
      if(!this.results || this.results.length == 0) return []
      else return [
        {
          value: 'fullname',
          text: 'Nome cliente',
          minWidth: "200px",
        },
        {
          value: 'quantity',
          text: 'Quantità',
          minWidth: '80px'
        },
        {
          value: 'cellphone',
          text: 'Cellulare',
          minWidth: '150px'
        },
        {
          value: 'email',
          text: 'Email',
          minWidth: '200px'
        },        
        {
          value: 'city',
          text: 'Città',
          minWidth: '150px'
        },
        {
          value: 'message',
          text: 'Messaggio',
          minWidth: '60px'
        },
      ]
    }
  }
}

</script>

<style>
  .bordered {
    border-spacing: 0;
  }

  .bordered th {
    /* border-bottom: 1px solid; */
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
  }

  .hoverable-table-cell:hover {
    background-color: rgb(0, 0, 0, 0.06) !important;
  }

  .red-background {
    background-color: rgb(255 94 94 / 20%);
  }

  .green-background {
    background-color: rgb(119 255 94 / 20%);
  }

</style>
