<template>
  <div class="">
    <FullScreenDialog
      v-model="openDialog"
      title="Procacciatori D'Affari"
      :routeFather="pathToGoBack"
    >
      <template v-slot:content>
        <div class="justify-center ml-3 mt-3 d-flex">
          <SuppliersAutocomplete
            class="mr-1"
            v-model="company"
            :clearable="false"
            return-object
            prepend-icon="mdi-account-cowboy-hat"
            :auto-detect-new-supplier="false"
            label="Procacciatore..."
          ></SuppliersAutocomplete>
          <RangeDateSelector
            :start-date.sync="startDate"
            :end-date.sync="endDate"
            :show-chips="false"
            width="30%"
          >
          </RangeDateSelector>
        </div>
        <v-tabs v-model="tab" class="pt-2">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="tab in listTabs" :key="tab.key">{{ tab.title }}</v-tab>
        </v-tabs>
        <v-divider class="mb-2"></v-divider>
        <TypeDataTable
          v-if="tab != undefined && tab==0"
          :show-select="false"
          item-key="id"
          fixed-header
          :show-actions="false"
          :headers="customerHeaders"
          :items="tableItems"
          :page.sync="page"
          @rowClicked="opendDetailDialog"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
        ></TypeDataTable>
        <IncentivePlan
          v-else-if="!!tab && tab==1"
          :from="startDate"
          :to="endDate"
          :companyId="!!company ? company.id : undefined"
          :lastServicesWithPercentages="lastServicesWithPercentages"
          :lastProductPercentage="lastProductPercentage"
          :ignoredServices="ignoredServices"
          :ignoredItems="ignoredItems"
          @newPayment="newPayment"
        ></IncentivePlan>
        <TypeDataTable
          v-if="tab != undefined && tab==2"
          :show-select="false"
          item-key="id"
          fixed-header
          :show-actions="false"
          :headers="historyPaymentsHeaders"
          :items="historyPaymentsItems"
          :page.sync="pageHistoryPayments"
          :row-per-page.sync="rowPerPageHistoryPayments"
          :total-pages.sync="totalPagesHistoryPayments"
        >
          <template v-slot:custom-fullname="{ item }">
            <v-chip v-if="!!item.operator">
              {{ item.operator.firstname }} {{ item.operator.lastname }}
            </v-chip>
          </template>
        
        </TypeDataTable>
      </template>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="$router.push({path: pathToGoBack})"
        >Chiudi</v-btn>
      </template>
    </FullScreenDialog>
    <OtherCostsNewForm
      :openDialog.sync="showNewPaymentDialog"
      :pathToGoBack="null"
      :precompiledForm="preCompiledForm"
      @update:openDialog="handleClosureDialog"
    ></otherCostsNewForm>
    <StandardDialog
      v-model="detailDialog"
      @close="detailDialog = false"
      dialog-width="80vw"
      :dialog-height="null"
      title="Dettaglio vendite"
    >
      <TypeDataTable
        :show-select="false"
        item-key="id"
        fixed-header
        :show-actions="false"
        :headers="detailHeaders"
        :items="detailItems"
        :disablePagination="true"
        :loading="loadingDetail"
      >
        <template v-slot:custom-customer="{ item }">
          <v-chip>{{item.customer == " " ? "Cliente dal bar" : item.customer}}</v-chip>
        </template>
        <template v-slot:custom-service="{ item }">
          {{item.object.name}}
        </template>
        <template v-slot:custom-item="{ item }">
          {{item.object.description}}
        </template>
        <template v-slot:custom-operator="{ item }">
          {{item.object.operators.map(op => `${op.firstname} ${op.lastname}`).join(', ')}}
        </template>
        <template v-slot:custom-date="{ item }">
          <DateTimeFormattor
            :with-time="true"
            :value="new Date(item.date)"
            not-specified-text="Non specificato"
          ></DateTimeFormattor>
        </template>
        <template v-slot:custom-realPrice="{ item }">
          <span 
            :style="{ color: item.object.ghost ? 'red' : 'inherit' }"
          >€ {{item.object.realPrice}}</span>
        </template>
      </TypeDataTable>
    </StandardDialog>
  </div>

</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import DateTimeFormattor from "@/components/common/DateTimeFormattor";
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import IncentivePlan from '@/components/areas/marketing/intermediary/IncentivePlan.vue';
import customerService from '@/services/customers/customers.service.js'
import PaymentTransactionsGivenNewForm from '@/components/areas/payments/paymentTransactionsGiven/PaymentTransactionsGivenNewForm.vue';
import otherCostService from '@/services/otherCosts/otherCosts.service.js'
import paymentCollectedService from "@/services/paymentCollected/paymentCollected.service.js";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import OtherCostsNewForm from "@/components/areas/payments/otherCosts/OtherCostsNewForm.vue"
import intermediaryService from "@/services/intermediary/intermediary.service.js"
import dateUtils from "@/mixins/common/dateUtils.js"

export default {
  name: "IntermediaryList",
  components: {
    FullScreenDialog,
    RangeDateSelector,
    OperatorsAutocomplete,
    TypeDataTable,
    IncentivePlan,
    PaymentTransactionsGivenNewForm,
    StandardDialog,
    DateTimeFormattor,
    SuppliersAutocomplete,
    OtherCostsNewForm
  },
  mixins: [dateUtils],
  data: function () {
    return {
      openDialog: true,
      detailDialog: false,
      customerDetailOf: undefined,
      loadingDetail: false,
      startDate: new Date(),
      endDate: new Date(),
      company: undefined,
      tab: undefined,
      ghost: true,
      dialogBarcode: false,
      ghostBarcode: undefined,
      showNewPaymentDialog: false,
      preCompiledForm: undefined,
      tableItems: [],
      historyPaymentsItems: [],
      detailItems: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      ignoredItems: [],
      ignoredServices: [],
      pageHistoryPayments: 1,
      rowPerPageHistoryPayments: 100,
      totalPagesHistoryPayments: 0,
      lastProductPercentage: 0,
      lastServicesWithPercentages: [],
      listTabs: [
        {
          key: 0,
          title: "Clienti",
          name: "customers",
        },
        {
          key: 1,
          title: "Piano Incentivazione",
          name: "incentivePlan",
        },
        {
          key: 2,
          title: "Storico Pagamenti Operatore",
          name: "historyPayments",
        }
      ],
      customerHeaders: [
        { text: "Nome", value: "firstname" },
        { text: "Cognome", value: "lastname" },
        { text: "Cellulare", value: "cellphone"},
        { text: "Spesa Totale", value: "totPaid", type:"price"},
      ],
      historyPaymentsHeaders: [
        { text: "Operatore", value: "fullname", type:"custom" },
        { text: "Data Pagamento", value: "createdAt", type:"date" },
        { text: "Totale", value: "amount", type:"price"},
        { text: "Descrizione", value: "description" },
      ],
      detailHeaders: [
        { text: "Cliente", value: "customer", type: "custom" },
        { text: "Servizio", value: "service", type: "custom" },
        { text: "Prodotto", value: "item", type: "custom" },
        { text: "Operatore", value: "operator", type: "custom" },
        { text: "Data", value: "date", type: "custom" },
        { text: "Incassato", value: "realPrice", type: "custom" },
      ]
    };
  },
  props: {
    pathToGoBack: {
      type: String,
      default: "/marketing"
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if(!this.verified) {
      this.$router.push({
        name: 'Marketing',
      })
    }

    this.fetchIgnoredServicesAndItems()
  },
  methods: {
    async fetchIgnoredServicesAndItems() {
      intermediaryService.ignoredItemsAndServices().then((results) => {
        this.ignoredItems = results.items
        this.ignoredServices = results.services
      })
    },
    async fetchCustomers(){
      try {
        let results = await customerService.list(this.page,this.rowPerPage, {
          intermediaryCompanyId: Number(this.company.id),
          from: new Date(this.startDate.setHours(0,0,0,0)),
          to: new Date(this.endDate.setHours(23,59,59,999)),
        })
        this.tableItems = [] 
        for(const element of results.rows){
          let totPaid = 0
          let obj = {}
          obj.firstname = element.firstname
          obj.lastname = element.lastname
          obj.cellphone = element.cellphone
          obj.customerId = element.id
          for (const billCustomer of element.billCustomers) {
            if(!!billCustomer.bill){
              for (const el of billCustomer.bill.billItems) {
                if(this.ignoredItems.map((el) => el.itemId).includes(Number(el.itemId))) continue
                if(this.ghost || (!this.ghost && !el.ghost)) {
                  totPaid += Number(el.price) * Number(el.quantity)
                }
              }
              for (const el of billCustomer.bill.billServices) {
                if(this.ignoredServices.map((el) => el.serviceId).includes(Number(el.serviceId))) continue
                if(this.ghost || (!this.ghost && !el.ghost)) {
                  totPaid += Number(el.price) * Number(el.quantity)
                }
              }
            }
          }
          obj.totPaid = totPaid.toFixed(2)      
          this.tableItems.push(obj)
        }
        if (this.rowPerPage != results.rowPerPage) 
          this.rowPerPage = results.rowPerPage;

        if (this.page != results.page) 
          this.page = results.page;   
          
        this.totalPages = results.totalPages;

        if (this.totalPages < this.page && this.totalPages != 0) 
          this.page = this.totalPages;
        
      } catch (error) {
        console.log(error)
      }
    },
    async fetchLastPaymentPercentages() {
      this.lastProductPercentage = 0
      this.lastServicesWithPercentages = []
      otherCostService.list(1, 1, [
        {
          operator: 'custom', 
          field: 'intermediary', 
          value: true
        },
        {
          operator: 'custom', 
          field: 'supplierId', 
          value: this.company.id
        },
      ]).then(results => {
        let lastPaymentItem = results.rows[0]
        if (!!lastPaymentItem) {
          this.lastProductPercentage = Number(Number(lastPaymentItem.intermediaryProductPercentage).toFixed(0))
          this.lastServicesWithPercentages = lastPaymentItem.otherCostIntermediaryServices
        }
      })
    },
    async fetchHistoryPayments(){
      otherCostService.list(this.pageHistoryPayments, this.rowPerPageHistoryPayments, [
        {
          operator: 'custom', 
          field: 'intermediary', 
          value: true
        },
        {
          operator: 'custom', 
          field: 'supplierId', 
          value: this.company.id
        },
      ]).then(results => {
        this.historyPaymentsItems = results.rows
        if (this.rowPerPageHistoryPayments != results.rowPerPage) 
          this.rowPerPageHistoryPayments = results.rowPerPage;

        if (this.pageHistoryPayments != results.page) 
          this.pageHistoryPayments = results.page;   
          
        this.totalPagesHistoryPayments = results.totalPages;

        if (this.totalPagesHistoryPayments < this.pageHistoryPayments && this.totalPagesHistoryPayments != 0) 
          this.pageHistoryPayments = this.totalPagesHistoryPayments;

      })  
    },
    async handleClosureDialog() {
      await this.fetchHistoryPayments()
      await this.fetchLastPaymentPercentages()
      this.showNewPaymentDialog = false
      this.tab = 2
    },
    opendDetailDialog(row) {
      this.detailDialog = true
      this.loadingDetail = true
      this.customerDetailOf = row

      paymentCollectedService
        .detailList(1, 1000, [
          { type: "date", operator: "between", field: "date", value: [this.startDate, this.endDate]},
          { type: 'custom', operator: 'custom', field: 'ghost', value: this.ghost },
          { type: 'custom', operator: 'custom', field: 'notIgnoredIntermediaryServiceOrItems', value: true },
          { type: "custom", operator: "custom", field: "customer", value: { id: this.customerDetailOf.customerId }}
        ]).then((results) => {
          this.detailItems = results.rows
          this.loadingDetail = false
        })
    },
    /*
    {
      amount: Number,
      companyId: Number
    }
    */
    async newPayment(params){
      let startDate = new Date(this.startDate.setHours(0))
      startDate = new Date(startDate.setMinutes(0))
      startDate = new Date(startDate.setSeconds(0))

      let endDate = new Date(this.endDate.setHours(23))
      endDate = new Date(endDate.setMinutes(59))
      endDate = new Date(endDate.setSeconds(59))

      try {
        this.preCompiledForm = {
          description: `Saldo periodico Procacciatore ${this.dateToString("dd/mm/yyyy", startDate)} - ${this.dateToString("dd/mm/yyyy", endDate)}`,
          emittedAt: new Date(),
          expirationDate: new Date(),
          otherCostAmounts: [
            {
              taxable: params.amount
            }
          ],
          supplierId: this.company.id,
          supplier: this.company,
          type: 'intermediary',
          intermediaryProductPercentage: params.intermediaryProductPercentage,
          servicesWithPercentages: params.servicesWithPercentages,
        }

        this.showNewPaymentDialog = true
      } catch (error) {
        console.log(error)
      }
    }
  },
  watch: {
    page() {
      if(!!this.startDate && !!this.endDate && !!this.company)
        this.fetchCustomers();
    },
    rowPerPage() {
      if(!!this.startDate && !!this.endDate && !!this.company)
        this.fetchCustomers();
    },
    async company(newVal) {
      if(!!this.startDate && !!this.endDate && !!this.company)
        await this.fetchCustomers();
      if(!!newVal) {
        await this.fetchHistoryPayments()
        await this.fetchLastPaymentPercentages()
      }
    },
    startDate(newVal) {
      if(!!this.startDate && !!this.endDate && !!this.company)
        this.fetchCustomers();
    },
    endDate(newVal) {
      if(!!this.startDate && !!this.endDate && !!this.company)
        this.fetchCustomers();
    },
    pageHistoryPayments() {
      if(!!this.startDate && !!this.endDate && !!this.company)
        this.fetchHistoryPayments();
    },
    rowPerPageHistoryPayments() {
      if(!!this.startDate && !!this.endDate && !!this.company)
        this.fetchHistoryPayments();
    },
  }
};
</script>

<style>
</style>



