import UrlKeeper from '../UrlKeeper'
import { io } from 'socket.io-client'
import currentUser from "@/services/currentUser.service";
import CookieService from "@/services/cookies.service";

class Socket {
  constructor() {
    this.connect()
    this.connectionTimeout = undefined
  }

  connect() {
    if (!!this.connectionTimeout) return
    else {
      const tryConnection = () => {
        if (!!UrlKeeper.getUrl('socket')) {
          this._socket = io(UrlKeeper.getUrl('socket'), {
            transports: ['websocket'],
            auth: {
              token: CookieService.get("auth-token"),
              userId: CookieService.get('user-id')
            }
          })
        } else {
          this.connectionTimeout = setTimeout(() => {
            tryConnection()
          }, 2000);
        }
      }

      this.connectionTimeout = setTimeout(() => {
        tryConnection()
      }, 2000);
    }
  }

  on(eventName, callback) {
    if (!this.connected) this.connect()

    let tryConnection = () => {
      if (this.connected) {
        this._socket.on(eventName, (data) => {
          currentUser.getUser().then((user) => {
            if (
              !!user &&
              !!user.licenses &&
              user.licenses.length > 0 &&
              !!data.context &&
              !!data.context.schema &&
              !!data.context.schema.name &&
              user.licenses[0].schemaName == data.context.schema.name
            ) callback(data)
          })
        })
      } else {
        timeout = setTimeout(() => {
          tryConnection()
        }, 2000);
      }
    }
    
    let timeout = setTimeout(() => {
      tryConnection()
    }, 2000);
  }

  get connected() {
    return !!this._socket && this._socket.connected
  }
}

export default new Socket()