import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import _ from 'lodash'

class EnablingService {
  list(operator) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if(!operator.id) {
        reject('operator id not specified')
        return
      }
      axios.post(apiUrl + '/registries/operators/' + operator.id + '/enablings/list').then((response) => {
        resolve(response.results)
      })
    })
  }

  enablingTypeOfOperator(operator) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!operator.id) {
        reject('operator id not specified')
        return
      }


      axios.post(apiUrl + '/registries/operators/' + operator.id + '/enablings/list').then((response) => {
        if (response.success) {
          let rows = response.results
          rows = rows.map((enabling) => {
            return {
              ...enabling.services
            }
          })
          resolve(_.uniqBy(rows, 'id'))
        }
        else {
          reject(response.results)
        }
      })
    })
  }

  set(operator, services) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!operator.id) {
        reject('operator id not specified')
        return
      }

      axios.post(apiUrl + '/registries/operators/' + operator.id + '/enablings/set', services).then((response) => {
        resolve(response)
      })

    })
  }
}

export default new EnablingService()