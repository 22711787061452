<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    :title="dialogTitle"
    :route-father="routeFather"
    id-prop="print"
  >
    <template v-slot:header-actions>
      <div class="d-flex"></div>
      <v-btn 
        icon 
        class="mx-1"
        @click="openNewAbsenceDialog"
        :disabled="isLicenseBlocked"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!--<v-btn 
        icon
        class="mx-1"
        :loading="loadingExport"
        @click="exportAbsences"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>-->
      <v-btn 
        class="mr-2 ml-2"
        text 
        @click="print" 
        :loading="loadingExport" 
      >
        Stampa
      </v-btn>
    </template>

    <template v-slot:toolbar-extension>
      <div class="d-flex justify-center align-center" style="width: 100%">
        <v-btn
          icon
          @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div style="width: 300px" class="text-center">
          <div>{{ calendarTitle }}</div>
        </div>
        <v-btn
          icon
          @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>
    
    <template v-slot:content>
      <v-calendar
        ref="calendar"
        v-model="focus"
        type="month"
        event-start="start"
        event-end="end"
        :event-name="eventName"
        :events="absences"
        color="primary"
        :event-color="eventColor"
        :event-text-color="eventTextColor"
        @click:event="handleEventClick"
        @change="handleCalendarChange"
        @click:more="handleMoreClick"
        @click:day="handleDayClick"
        locale="it"
      >
        <template v-slot:day-label="{day, date}">
          <div @click="handleDayClick({date}, $event)">{{day}}</div>
        </template>
      </v-calendar>
      <AbsenceForm
        v-model="absence"
        :operator="localOperator"
        :dialog.sync="newAbsenceDialog"
        @save="createAbsence"
        :show-delete="false"
        :disabled="isLicenseBlocked"
      ></AbsenceForm>
      <AbsenceForm
        v-model="absence"
        :operator="localOperator"
        :dialog.sync="editAbsenceDialog"
        @save="updateAbsence"
        @delete="deleteAbsence"
        :show-frequency-options="false"
      ></AbsenceForm>
      <StandardDialog
        v-model="operatorAbsenceErrorDialog"
        :dialog-height="null"
        dialog-width="400px"
        title="Appuntamenti trovati"
        @close="operatorAbsenceErrorDialog = false"
      >
        <v-alert 
          border="left"
          class="py-2 my-3"
          color="error"
        >
          L'operatore ha degli eventi nei giorni selezionati! 
          Spostarli prima di poter registrare l'assenza.
        </v-alert>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import EditTurns from "@/components/areas/presences/turns/EditTurns.vue";
import absencesService from "@/services/presences/absences/absences.service.js"
import operatorService from "@/services/operators/operators.service.js"
import colorContrast from "@/mixins/common/colorContrast"
import colorsService from "@/services/colors/colors.service.js"
import AbsenceForm from '@/components/areas/presences/absences/AbsenceForm.vue';
import calendarManagment from "@/services/calendar/calendar.service.js";
import html2canvas from '@/assets/js/html2canvas.min.js'

export default {
  name: "OperatorAbsences",
  components: {
    FullScreenDialog,
    EditTurns,
    StandardMenu,
    StandardDialog,
    AbsenceForm
  },
  mixins: [colorContrast],
  data: function() {
    let props = this.$router.resolve({ name: "Absences" });

    return {
      dialog: true,
      absences: [],
      routeFather: props.href,
      focus: new Date(),
      selectedEvent: {},
      calendarTitle: undefined,
      localOperator: {...this.operator},
      colorMapping: {},
      newAbsenceDialog: false,
      editAbsenceDialog: false,
      absence: {},
      loadingExport: false,
      operatorAbsenceErrorDialog: false,
      isLicenseBlocked: undefined
    };
  },
  props: {
    operator: {
      type: Object,
    }
  },
  mounted: async function() {
    this.$refs.calendar.checkChange();
    // this.handleCalendarChange()

    if(!this.localOperator.firstname || !this.localOperator.lastname) {
      await this.loadOperator()
    }
  },
  methods: {
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    editTurns() {
      this.editTurnDialog = true;
    },
    eventName({input: absence}) {
      return absence.absenceType.name
    },
    eventColor(absence) {
      if(!!this.colorMapping[absence.id]) {
        return this.colorMapping[absence.id]
      } else {
        this.colorMapping[absence.id] = colorsService.random(Object.values(this.colorMapping))
        return this.colorMapping[absence.id]
      }
    },
    eventTextColor(absence) {
      return this.blackOrWhiteContrast(this.eventColor(absence))
    },
    async loadOperator() {
      this.localOperator = await operatorService.get(this.localOperator.id)
    },
    reloadAbsences() {
      let currentFocus = new Date((this.focus || new Date()))
      let startOfMonth = new Date(currentFocus.getFullYear(), currentFocus.getMonth(), 1);
      let endOfMonth = new Date(currentFocus.getFullYear(), currentFocus.getMonth() + 1, 0);
      startOfMonth.setHours(0)
      startOfMonth.setMinutes(0)
      endOfMonth.setHours(23)
      endOfMonth.setMinutes(59)

      if(this.localOperator && this.localOperator.id) {
        absencesService.list(1, 400, {
          start: startOfMonth,
          end: endOfMonth,
          operatorId: this.localOperator.id
        }).then((results) => {
          for(let i = 0; i < results.rows.length; i += 1) {
            results.rows[i].start = new Date(results.rows[i].start)
            results.rows[i].end = new Date(results.rows[i].end)
          }
          this.absences = results.rows
        })
      }
    },
    async handleCalendarChange() {
      this.calendarTitle = this.$refs.calendar.title
      await this.reloadAbsences()
    },
    handleDayClick({ date }, nativeEvent) {
      nativeEvent.preventDefault()
      nativeEvent.stopPropagation()
      let beginningOfDay = new Date(date)
      beginningOfDay.setHours(0)
      beginningOfDay.setMinutes(0)
      let beginningOfNexDay = new Date(beginningOfDay.getTime() + (60000 * 60 * 24))

      this.absence = {
        start: beginningOfDay,
        end: beginningOfNexDay
      }
      this.newAbsenceDialog = true
    },
    handleMoreClick(event) {
      console.log(event)
    },
    handleEventClick({event: absence}, nativeEvent) {
      nativeEvent.preventDefault()
      nativeEvent.stopPropagation()
      this.absence = {...absence}
      this.editAbsenceDialog = true
    },
    exportAbsences() {
      let currentFocus = new Date(this.focus)
      let startOfMonth = new Date(currentFocus.getFullYear(), currentFocus.getMonth(), 1);
      let endOfMonth = new Date(currentFocus.getFullYear(), currentFocus.getMonth() + 1, 0);

      this.loadingExport = true
      absencesService.export(1, 400, {
        start: new Date(startOfMonth),
        end: new Date(endOfMonth),
        operatorId: this.localOperator.id
      }).then(() => {
        this.loadingExport = false
      })
    },
    openNewAbsenceDialog() {
      this.absence = {
        start: new Date(),
        end: new Date(),
      }
      this.newAbsenceDialog = true
    },
    async createAbsence(absence, rule){
      let eventsList;
      let checkOperatorId;
      let startDate = new Date(absence.start);
      let endDate = new Date(absence.end);

      eventsList = await calendarManagment.eventsList({
        start: new Date(startDate),
        end: new Date(endDate)
      })
      
      for(let i = 0; i < eventsList.length; i++) {
        if(!!eventsList[i].eventServices && eventsList[i].eventServices.length > 0) {
          if(!!eventsList[i].eventServices[0] && !!eventsList[i].eventServices[0].operators[0] && eventsList[i].eventServices[0].operators[0].id == absence.operatorId) {
            checkOperatorId = true;
          }
        } else {
          checkOperatorId = false;
        }
      }

      if(!checkOperatorId) {
        absencesService.createWithRule(absence, rule).then((results) => {
          this.newAbsenceDialog = false
          this.reloadAbsences()
        })
      } else if(!!checkOperatorId){
        this.operatorAbsenceErrorDialog = true
      }
    },
    updateAbsence(absence) {
      absencesService.update(absence).then((results) => {
        this.editAbsenceDialog = false
        this.reloadAbsences()
      })
    },
    deleteAbsence(absence) {
      let confirmed = confirm("Sei sicuro di voler eliminare l'assenza ?")

      if(confirmed) {
        absencesService.delete(absence).then((results) => {
          this.editAbsenceDialog = false
          this.reloadAbsences()
        })
      }
    },
    print() {
      this.loadingExport = true

      let currentFocus = new Date(this.focus)
      html2canvas(document.getElementById('print'), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Assenze_" + this.localOperator.firstname + "_" + this.localOperator.lastname 
          + "_" + (currentFocus.getMonth() + 1) + "_" + currentFocus.getFullYear() + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.loadingExport = false
      }).catch((error) => {
        console.error(error)
        this.loadingExport = false
      });
    },
  },
  computed: {
    dialogTitle() {
      return !!this.localOperator ? this.localOperator.firstname + ' ' + this.localOperator.lastname : 'Modifica assenze'
    }
  },
  watch: {},
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
</style>