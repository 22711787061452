<template>
  <div>
    <TitleWithBackButton
      title="Impostazioni Sms"
      back-route="Marketing"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <v-row>
      <v-col>
        <v-card flat color="transparent">
          <v-card-title class="pb-0">MODELLI SMS</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">
              Sezione dedicata per la creazione e la modifica dei modelli SMS
            </div>
            <v-btn color="primary" @click="openSmsModelDialog">
              MODELLI
              <v-icon class="ml-3">mdi-forum</v-icon>
            </v-btn>
            <StandardDialog
              v-model="smsModelDialog"
              title="Modifica Modelli"
              without-padding
              dialog-height="fit-content"
            >
              <div class="text-center">
                <v-btn icon class="my-2" @click="handleNewSmsModel">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-list v-if="smsModels.length > 0" two-line color="transparent">
                <div v-for="smsModel in smsModels" v-bind:key="smsModel.id">
                  <v-list-item v-if="searchMatch(smsModel)">
                    <v-list-item-content class="text-left">
                      <v-list-item-title>
                        {{ smsModel.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <SmsTextVisualizer
                          :value="smsModel.text"
                        ></SmsTextVisualizer>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="handleEditSmsModel(smsModel)">
                        <v-icon medium>mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action>
                      <StandardMenu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon medium>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            v-on:click="handleEditSmsModel(smsModel)"
                          >
                            <v-list-item-title>Modifica</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            v-on:click="deleteSmsModel(smsModel)"
                          >
                            <v-list-item-title>Elimina</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </StandardMenu>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
              <div
                v-else
                class="font-weight-thin font-italic d-flex align-content-center mt-5"
                style="width: 100%"
              >
                <span style="margin: auto">Nessun Modello</span>
              </div>
              <template v-slot:footer-actions>
                <v-btn text v-on:click="goBack" color="error">
                  Annulla
                </v-btn>
              </template>
            </StandardDialog>

            <StandardDialog
              v-model="newSmsModelDialog"
              title="Creazione Modello"
              :dialog-height="null"
              persistent
              dialog-width="1000"
            >
              <div>
                <SmsModelForm
                  v-model="newSmsModel"
                  class="py-2"
                  :valid.sync="newSmsModelValid"
                  :hide-upload-image="true"
                ></SmsModelForm>
              </div>
              <template v-slot:footer>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="error"
                  @click="newSmsModelDialog = false"
                  :disabled="loadingCreate"
                  >Annulla</v-btn
                >
                <v-btn
                  text
                  color="default"
                  @click="createSmsModel"
                  :loading="loadingCreate"
                  :disabled="!newSmsModelValid"
                  >Salva</v-btn
                >
              </template>
            </StandardDialog>

            <StandardDialog
              v-model="editSmsModelDialog"
              title="Modifica Modello"
              :dialog-height="null"
              persistent
              dialog-width="1000"
            >
              <div>
                <SmsModelForm
                  v-model="editSmsModel"
                  class="py-2"
                  :valid.sync="editSmsModelValid"
                  :hide-upload-image="true"
                ></SmsModelForm>
              </div>
              <template v-slot:footer>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="error"
                  @click="editSmsModelDialog = false"
                  :disabled="loadingUpdate"
                  >Annulla</v-btn
                >
                <v-btn
                  text
                  color="default"
                  @click="updateSmsModel"
                  :loading="loadingUpdate"
                  :disabled="!editSmsModelValid"
                  >Salva</v-btn
                >
              </template>
            </StandardDialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat color="transparent">
          <v-card-title class="pb-0"
            >Invio SMS all'assegnazione di una promo</v-card-title
          >
          <v-card-text class="text-left">
            <div class="pb-2">
              Viene inviato automaticamente un SMS quando una promozione viene
              assegnata ad un cliente
            </div>
            <v-autocomplete
              v-if="!!smsPromo"
              v-model="promoModelSelect"
              :rules="[presenceRule]"
              :items="smsModels"
              item-value="text"
              item-text="name"
              :clearable="true"
              dense
              filled
              hide-details="auto"
              label="Selezionare Modello promo"
              @change="setSetting('promoModelSelect', $event)"
            ></v-autocomplete>
            <v-switch
              class="pa-0 ma-0 mt-4"
              hide-details="auto"
              v-model="smsPromo"
              @change="setSetting('smsPromo', $event)"
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
<!--     <v-row v-if="!!viewBar.active">
      <v-col>
        <v-card flat color="transparent">
          <v-card-title class="pb-0"
            >Viene inviato un messaggio Whatsapp dopo una prenotazione</v-card-title
          >
          <v-card-text class="text-left">
            <div class="pb-2">
              Viene inviato un Whatsapp quando viene creata una prenotazione nell'agenda del ristorante
            </div>
            <v-switch
              class="pa-0 ma-0 mt-4"
              hide-details="auto"
              v-model="whatsappReservation"
              @change="setSetting('whatsappReservation', $event)"
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="5">
        <v-card flat color="transparent">
          <v-card-title class="pb-0"
            >Modello SMS Appuntamenti</v-card-title
          >
          <v-card-text class="text-left">
            <div class="pb-2">
              Selezionare il modello da utilizzare per l'invio giornaliero degli appuntamenti
            </div>
            <v-autocomplete
              v-model="appointmentModelSelect"
              :rules="[presenceRule]"
              :items="smsModels"
              item-value="text"
              item-text="name"
              :clearable="true"
              dense
              filled
              hide-details="auto"
              label="Selezionare Modello Appuntamenti"
              @change="setSetting('appointmentModelSelect', $event)"
            ></v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card flat color="transparent">
          <v-card-title class="pb-0"
            >Modello SMS Compleanno</v-card-title
          >
          <v-card-text class="text-left">
            <div class="pb-2">
              Selezionare il modello da utilizzare per l'invio dei messaggi il giorno del Compleanno
            </div>
            <v-autocomplete
              v-model="birthdayModelSelect"
              :rules="[presenceRule]"
              :items="smsModels"
              item-value="text"
              item-text="name"
              :clearable="true"
              dense
              filled
              hide-details="auto"
              label="Selezionare Modello Compleanno"
              @change="setSetting('birthdayModelSelect', $event)"
            ></v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card flat color="transparent">
          <v-card-title class="pb-0">Numero Cellulare Salone</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">
              Includere prefisso 39 davanti
            </div>
            <v-text-field
              v-model="whatsappNumber"
              :clearable="true"
              dense
              filled
              hide-details="auto"
              prepend-icon="mdi-whatsapp"
              label="Inserire numero di telefono"
              @change="setSetting('whatsappNumber', $event)"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card flat color="transparent">
          <v-card-title class="pb-0">{{
            $translations.t("settings.waboxappToken.title")
          }}</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">
              {{ $translations.t("settings.waboxappToken.description") }}
            </div>
            <v-text-field
              filled
              dense
              class="pa-0 ma-0"
              hide-details="auto"
              label="Waboxapp token"
              v-model="waboxappToken"
              @change="setSetting('waboxappToken', $event)"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card flat color="transparent">
          <v-card-title class="pb-0">{{
            $translations.t("settings.esendexCredentials.title")
          }}</v-card-title>
          <v-card-text class="text-left">
            <div class="pb-2">
              {{ $translations.t("settings.esendexUsername.description") }}
            </div>
            <v-text-field
              filled
              dense
              class="pa-0 ma-0"
              hide-details="auto"
              label="Esendex Username"
              v-model="esendexUsername"
              @change="setSetting('esendexUsername', $event)"
            ></v-text-field>
            <div class="pb-2">
              {{ $translations.t("settings.esendexPassword.description") }}
            </div>
            <v-text-field
              filled
              dense
              class="pa-0 ma-0"
              hide-details="auto"
              label="Esendex API Password"
              v-model="esendexPassword"
              @change="setSetting('esendexPassword', $event)"
            ></v-text-field>
            <div class="pb-2">
              {{ $translations.t("settings.esendexName.description") }}
            </div>
            <v-text-field
              filled
              dense
              class="pa-0 ma-0"
              hide-details="auto"
              label="Esendex Nome Mittente"
              v-model="esendexName"
              @change="setSetting('esendexName', $event)"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitleWithBackButton from "@/components/common/TitleWithBackButton.vue";
import settingService from "@/services/settings/settings.service.js";
import smsModelsService from "@/services/smsModels/smsModels.service.js";
import SmsModelEdit from "@/components/setting/sms/SmsModelEdit.vue";
import SmsModelForm from "@/components/setting/sms/SmsModelForm.vue";
import SmsTextVisualizer from "@/components/setting/sms/SmsTextVisualizer.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import Vue from "vue";

export default {
  name: "SmsSettings",
  components: {
    TitleWithBackButton,
    SmsModelEdit,
    StandardDialog,
    SmsTextVisualizer,
    StandardMenu,
    SmsModelForm,
  },
  data: function() {
    return {
      newFormValid: false,
      editFormValid: false,
      waboxappToken: undefined,
      esendexUsername: undefined,
      esendexPassword: undefined,
      esendexName: undefined,
      smsPromo: undefined,
      promoModelSelect: undefined,
      appointmentModelSelect: undefined,
      birthdayModelSelect: undefined,
      whatsappNumber: undefined,
      smsModels: [],
      smsModelDialog: false,
      loadingSmsModels: false,
      editSmsModel: {},
      editSmsModelDialog: undefined,
      newSmsModel: {},
      newSmsModelDialog: false,
      newSmsModelValid: false,
      editSmsModelValid: false,
      loadingUpdate: false,
      loadingCreate: false,
      whatsappReservation: undefined,
      viewBar: undefined,
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
    };
  },
  mounted: function() {
    this.loadSettings();
    this.fetchSmsModels();
  },
  methods: {
    setSetting: function(key, value) {
      this.$settings.set(key, value);
      this.loadSettings();
    },
    loadSettings: async function() {
      this.loading = false;
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    fetchSmsModels() {
      smsModelsService.list().then((models) => {
        this.smsModels = models.rows;
      });
    },
    openSmsModelDialog() {
      this.smsModelDialog = true;
    },
    createSmsModel() {
      this.loadingCreate = true;
      smsModelsService.create(this.newSmsModel).then((models) => {
        this.fetchSmsModels();
        this.newSmsModel = {};
        this.loadingCreate = false;
        this.newSmsModelDialog = false;
      });
    },
    searchMatch(smsModel) {
      if (
        smsModel == undefined ||
        smsModel == null ||
        this.searchText == undefined ||
        this.searchText == null ||
        this.searchText == ""
      ) {
        return true;
      } else {
        return smsModel.name
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      }
    },
    handleEditSmsModel(smsModel) {
      this.editSmsModel = smsModel;
      this.editSmsModelDialog = true;
    },
    deleteSmsModel(smsModel) {
      let confirmed = confirm("Vuoi davvero cancellare il modello SMS?");

      if (confirmed) {
        smsModelsService.delete(smsModel).then((models) => {
          this.fetchSmsModels();
        });
      }
    },
    goBack() {
      this.smsModelDialog = false;
    },
    updateSmsModel() {
      this.loadingUpdate = true;
      smsModelsService.update(this.editSmsModel).then((models) => {
        this.fetchSmsModels();
        this.editSmsModel = {};
        this.loadingUpdate = false;
        this.editSmsModelDialog = false;
      });
    },
    handleNewSmsModel() {
      this.newSmsModel = {};
      this.newSmsModelDialog = true;
    },
    openSmsModel() {
      this.smsModelsDialog = true;
    },
  },
  computed: {},
  settings: {
    smsPromo: {
      bind: "smsPromo",
    },
    promoModelSelect: {
      bind: "promoModelSelect",
    },
    appointmentModelSelect: {
      bind: "appointmentModelSelect",
    },
    birthdayModelSelect: {
      bind: "birthdayModelSelect",
    },
    whatsappNumber: {
      bind: "whatsappNumber",
    },
    waboxappToken: {
      bind: "waboxappToken"
    },
    whatsappReservation:{
      bind: "whatsappReservation"
    },
    esendexUsername: {
      bind: "esendexUsername",
    },
    esendexPassword: {
      bind: "esendexPassword",
    },
    esendexName: {
      bind: "esendexName",
    },
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar",
    },
  }
};
</script>

<style></style>
