import eventsHandler from '@/services/common/eventsHandler.js'

class SupplierForm extends eventsHandler {
  constructor() {
    super()
    this._supplier = {}
    this._valid = false
  }

  get supplier() {
    const supplier = {
      ...this._supplier
    }
    return supplier
  }

  get valid() {
    return this._valid
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetSupplier() {
    this._supplier = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {supplier: {}})
  }

  updateField(key, value) {
    this._supplier[key] = value
    this._executeCallbacksOf('update', {supplier: this._supplier})
    this._executeCallbacksOf('update-' + key, {key: this._supplier[key]})
  }

  updateObject(supplier) {
    this._supplier = supplier
    this._executeCallbacksOf('update', {supplier: this._supplier})
  }
}

export default new SupplierForm()