import Builder from "./builder";
import axios from "axios";
import cashDeskService from "@/services/cashDesks/cashDesks.service.js"
import billService from '@/services/bills/bills.service.js'
import settingService from '@/services/settings/settings.service.js'
let { xml2js } = require("xml-js");

class Sender {
  constructor() {
    this._builder = new Builder();
    this._address = undefined;
    this._timeout = 10000
  }

  set address(address) {
    this._address = address
  }

  get body() {
    return this._builder.object;
  }

  get headers() {
    return {
      "Content-Type": "text/xml",
    };
  }

  get printerUrl() {
    if (!this._address) {
      return undefined;
    } else {
      return `https://${this._address}/cgi-bin/fpmate.cgi?devid=local_printer&timeout=10000`;
    }
  }

  get settingUrl() {
    if (!this._address) {
      return undefined;
    } else {
      return `https://${this._address}/cgi-bin/upload.cgi`;
    }
  }

  get departmentMapper() {
    return {
      '10%': 4,
      '0%': 10
    }
  }

  async getStatus() {
    await this.loadPrinterUrl()
    this._builder.directIO('status', '00')
    return new Promise((resolve, reject) => {
      axios.post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml"
        },
        timeout: this._timeout
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  /*
    options: {
      paymentTypes: [
        ...,
        {
          type: 'cash' | 'cheque' | 'card' | 'ticket' | 'credit',
          amount: Number
        }
      ],
      items: [
        ...,
        {
          name: String,
          price: Number,
          quantity: Number (optional),
          description: String (optional),
          tax: Number(optional), department { 10% = 4 , 22% = 1, 0% = 10}  
          discount: String (optional),
          discountDescription: String (optional),
        }
      ],
      lotteryCode: String,
      discounts: [
        ...,
        {
          description: String,
          amount: Number
        }
      ],
      additionalRows: [
        ...,
        String
      ]
    }
  */
  sendFiscalReceipt(options, message) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.loadPrinterUrl();

        this._builder.beginFiscalReceipt();
        if (
          !options.items ||
          !Array.isArray(options.items) ||
          options.items.length == 0
        ) {
          reject(new Error("items are not defined"));
          return;
        } else {
          for (const item of options.items) {
            let operator;
            if (!!item.additionalDescription) {
              operator =
                " - eseguito da " + item.additionalDescription;
            }
            let department = this.departmentMapper[item.tax]
            if (!department) department = 1

            //console.log(department, item.tax, this.departmentMapper)

            this._builder.printRecItem(
              item.name,
              item.quantity,
              department,
              item.price,
              item.description,
              item.discount,
              item.discountDescription
            );
          }
        }

        if (
          !!options.discounts &&
          Array.isArray(options.discounts) &&
          options.discounts.length > 0
        ) {
          for (let i = 0; i < options.discounts.length; i += 1) {
            let department = this.departmentMapper[options.discounts[i].tax]

            this._builder.printRecItemAdjustment(
              options.discounts[i].description,
              options.discounts[i].amount,
              department
            );
          }
        }

        if (!!options.lotteryCode) {
          this._builder.printLotteryCode(options.lotteryCode)
        }

        for (let i = 0; i < options.paymentTypes.length; i += 1) {
          if (options.paymentTypes.length == 1)
            this._builder.printRecTotal(
              options.amount ? options.amount : options.paymentTypes[i].amount,
              options.paymentTypes[i].type
            );
          else
            this._builder.printRecTotal(
              options.paymentTypes[i].amount ? options.paymentTypes[i].amount : options.amount,
              options.paymentTypes[i].type
            );
        }

        if (!!options.additionalRows && Array.isArray(options.additionalRows)) {
          for (let i = 0; i < options.additionalRows.length; i += 1) {
            this._builder.printRecMessage(options.additionalRows[i], "row");
          }
        }

        if(!!options.promoRows && Array.isArray(options.promoRows)) {
          for (let i = 0; i < options.promoRows.length; i += 1) {
            this._builder.printRecMessage(options.promoRows[i], "promo");
          }
        }
        
        if (!!options.qrcode) {
          this._builder.printQRCode(options.qrcode);
        }
        if (!!options.barcode) {
          this._builder.printBarcode(options.barcode);
        }

        this._builder.endFiscalReceipt();
      } catch (error) {
        reject(error);
      }

      axios
        .post(this.printerUrl, this._builder.xml, {
          headers: {
            "Content-Type": "text/xml",
          },
          timeout: this._timeout
        })
        .then((res) => {
          let transactionId = !!options.transactionId ? options.transactionId : -1
          billService.saveXml(transactionId, this._builder.xml)
          billService.logRTPrintedBillData(message, options, this._builder.xml)
          resolve(this._translateResponse(res.data));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async reprintFiscalReceipt({ date, number }) {
    await this.loadPrinterUrl();
    let dateFormatted = ('0' + date.getDate()).slice(-2) + ('0' + (date.getMonth() + 1)).slice(-2) + date.getFullYear().toString().slice(-2)
    let numberFormatted = number.slice(-4)

    this._builder.directIO('printByNumber', '01' + dateFormatted + numberFormatted + numberFormatted)
    //this._builder.directIO('printByNumber', '01' + dateFormatted + 9999 + 9999)

    return new Promise((resolve, reject) => {
      axios.post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
        timeout: this._timeout
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async sendPrintZReport(cashDeskIP) {
    await this.loadPrinterUrl();
    this._builder.directIO('zreport', '00')

    return new Promise((resolve, reject) => {
      axios.post(cashDeskIP || this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
        timeout: this._timeout
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async setLogo() {
    await this.loadPrinterUrl()
    this._builder.directIO('selectLogo', '050100210                                                             ')

    return new Promise((resolve, reject) => {
      axios.post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml"
        },
        timeout: this._timeout
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async saveLogo() {
    await this.loadPrinterUrl()
    this._builder.directIO('enableLogo', '09001')

    return new Promise((resolve, reject) => {
      axios.post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml"
        },
        timeout: this._timeout
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async setHeader(val) {
    await this.loadPrinterUrl()
    this._builder.directIO('setHeader', val)
    return new Promise((resolve, reject) => {
      axios.post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml"
        },
        timeout: this._timeout
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async sendSchedulePrintZReport(time, cashDeskIP) {
    await this.loadPrinterUrl();
    this._builder.directIO('zreportSchedule', '12' + time.replace(':', ''))

    return new Promise((resolve, reject) => {
      axios.post(cashDeskIP || this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async sendSchedulePrintZReportReset(cashDeskIP) {
    await this.loadPrinterUrl();
    this._builder.directIO('zreportSchedule', '0')

    return new Promise((resolve, reject) => {
      axios.post(cashDeskIP || this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
      }).then((res) => {
        resolve(this._translateResponse(res.data));
      }).catch((err) => {
        reject(err);
      });
    })
  }

  async sendPromoReport(promo, customer) {
    if (!promo.notes)
      throw new Error("message undefined");
    if (!promo.name)
      throw new Error("promo name undefined");
    try {
      await this.loadPrinterUrl();
      let promoMessages = promo.notes.split('\n')

      this._builder.beginNonFiscal();

      this._builder.printNormal(3, promo.name);
      for (let promoMessage of promoMessages) {
        if (!!customer) {
          promoMessage = promoMessage.replaceAll('<customer.firstname>', customer.firstname)
          promoMessage = promoMessage.replaceAll('<customer.lastname>', customer.lastname)
          promoMessage = promoMessage.replaceAll('<customer.fullname>', customer.firstname + ' ' + customer.lastname)
        }

        promoMessage = promoMessage.replaceAll(' ', ' ')

        for (let message of promoMessage.split('$')) {
          if (message.length > 45) {
            let i = 0
            let j = 45
            while (j < (message.length + 45)) {
              this._builder.printNormal(1, message.substring(i, j));
              i += 45
              j += 45
            }
          }
          else
            this._builder.printNormal(1, message);
        }
      }

      this._builder.endNonFiscal();
    } catch (error) {
      throw new Error(error);
    }

    axios
      .post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
        timeout: this._timeout
      })
      .then((res) => {
        return this._translateResponse(res.data)
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async giftCardReport(promo, customer, customerGiftCard) {
    if (!promo)
      throw new Error("promo undefined");
    if (!customer) {
      throw new Error("customer undefined");
    }
    if (!customerGiftCard)
      throw new Error("customerGiftCard undefined");
    try {
      await this.loadPrinterUrl();
      let promoDateValid = new Date();
      promoDateValid.setFullYear(promoDateValid.getFullYear() + 2);
      let promoDateFormat = promoDateValid.getDate() + "/" + promoDateValid.getMonth() + "/" + promoDateValid.getFullYear()
      let promoMessagesStart = "Ti regala un buono spesa presso i nostri";
      let promoMessagesEnd = "saloni per una spesa di " + promo.amount + " euro";
      let promoMessagesDate = "Ti consigliamo di spendere il tuo regalo";

      this._builder.beginNonFiscal();
      this._builder.printNormal(3, "Gent.ma/o " + customerGiftCard.lastname + " " + customerGiftCard.firstname);
      this._builder.printNormal(2, " ");
      this._builder.printNormal(2, customer.lastname + " " + customer.firstname);
      this._builder.printNormal(1, promoMessagesStart);
      this._builder.printNormal(1, promoMessagesEnd);
      this._builder.printNormal(1, promoMessagesDate);
      this._builder.printNormal(1, "entro il " + promoDateFormat);

      this._builder.endNonFiscal();
    } catch (error) {
      throw new Error(error);
    }

    axios
      .post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
        timeout: this._timeout
      })
      .then((res) => {
        return this._translateResponse(res.data)
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  /**
   * @param {Array} models - An array of objects containing the marketing report data.
   * @param {String} models[].title - The title of the marketing report.
   * @param {Array} models[].text - An array of strings containing the text of the marketing report.
   * @param {Object} models[].barcode - An object containing the barcode data.
   * @param {String} models[].barcode.code - The barcode code.
   * @param {String} models[].qrcode - The site of the QRCode.
   * @param {Array} models[].additionalText - An array of strings containing the additional text of the marketing report.
   * @param {Boolean} models[].cashDeskOperatorThanks - if true, the marketing report will contain the cash desk operator thanks.
   * @param {Boolean} models[].factoryData - if true, the marketing report will contain the factory data.
   * @param {String} models[].type - The type of the marketing report.
   */
  async marketingReport(models) {
    try {
      await this.loadPrinterUrl();
      
      this._builder.beginNonFiscal();

      for (let i = 0; i < models.length; i++) {
        let model = models[i]
        if (!!model.factoryData) {
          let companyBusinessName = await settingService.get('companyBusinessName')
          let companyAddress = await settingService.get('companyAddress')
          let companyCity = await settingService.get('companyCity')
          let companyPostalCode = await settingService.get('companyPostalCode')
          let companyVATNumber = await settingService.get('companyVATNumber')
          let companyPhone = await settingService.get('companyPhone')

          const checkFactoryData = !!companyBusinessName && companyBusinessName.value !== '' 
            && !!companyAddress && companyAddress.value !== '' && !!companyCity && companyCity.value !== ''
            && !!companyPostalCode && companyPostalCode.value !== '' && !!companyVATNumber 
            && companyVATNumber.value !== '' && !!companyPhone && companyPhone.value !== ''

          if (checkFactoryData) {
              let companyName = companyBusinessName.value
              let fullAddress = companyAddress.value + ' - ' + companyCity.value + ' ' + companyPostalCode.value
              let pIVA = "P.I. " + companyVATNumber.value
              let phone = "Tel " + companyPhone.value

              let charToSpaceBusinessName = Math.floor((46 - companyName.length) / 2)
              let emptyBusinessName = " ".repeat(charToSpaceBusinessName)
              companyName = emptyBusinessName + companyName

              let charToSpaceFullAddress = Math.floor((46 - fullAddress.length) / 2)
              let emptyFullAddress = " ".repeat(charToSpaceFullAddress)
              fullAddress = emptyFullAddress + fullAddress

              let charToSpacePIVA = Math.floor((46 - pIVA.length) / 2)
              let emptyPIVA = " ".repeat(charToSpacePIVA)
              pIVA = emptyPIVA + pIVA

              let charToSpacePhone = Math.floor((46 - phone.length) / 2)
              let emptyPhone = " ".repeat(charToSpacePhone)
              phone = emptyPhone + phone

              this._builder.printNormal(1, companyName);
              this._builder.printNormal(1, fullAddress);
              this._builder.printNormal(1, pIVA);
              this._builder.printNormal(1, phone);
          }
        }

        let title = model.title
        let charToSpaceTitle = Math.floor((46 - title.length) / 2)
        let emptyTitle = " ".repeat(charToSpaceTitle)
        title = emptyTitle + title
        
        this._builder.printNormal(3, " ");
        this._builder.printNormal(4, title);
        this._builder.printNormal(3, " ");

        for (let j = 0; j < model.text.length; j++) {
          let row = model.text[j]

          let charToSpaceRow = Math.floor((46 - row.length) / 2)
          let emptyRow = " ".repeat(charToSpaceRow)
          row = emptyRow + row
          
          this._builder.printNormal(3, row);
        }
        
        this._builder.printNormal(3, " ");

        if (!!model.barcode)
          this._builder.printBarcode(model.barcode.code)

        this._builder.printNormal(3, " ");
        
        if (!!model.qrcode)
          this._builder.printQRCode(model.qrcode)

        this._builder.printNormal(3, " ");

        if (model.additionalText) {
          for (let j = 0; j < model.additionalText.length; j++) {
            let row = model.additionalText[j]

            let charToSpaceRow = Math.floor((46 - row.length) / 2)
            let emptyRow = " ".repeat(charToSpaceRow)
            row = emptyRow + row
            
            this._builder.printNormal(3, row);
          }
        }
        
        this._builder.printNormal(3, " ");

        if (!!model.cashDeskOperatorThanks) {
          const cashDesk = await cashDeskService.getOpened()
          let operatorFullName = "Nome Operatore"
          if (!!cashDesk && !!cashDesk.operator) {
            operatorFullName = cashDesk.operator.firstname + ' ' + cashDesk.operator.lastname
          }

          let thanks = operatorFullName +  " è stata lieta di servirla"
          let charToSpaceThanks = Math.floor((46 - thanks.length) / 2)
          let emptyThanks = " ".repeat(charToSpaceThanks)
          thanks = emptyThanks + thanks

          this._builder.printNormal(2, thanks);
        }

        this._builder.printNormal(1, "----------------------------------------------");

        
      }

      this._builder.endNonFiscal();


    } catch (error) {
      throw new Error(error);
    }

    axios
      .post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
        timeout: this._timeout
      })
      .then((res) => {
        return this._translateResponse(res.data)
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  /*
    options: {
      amount: Number,
      invoiceData: {
        lastname: String,
        firstname: String,
        address: String,
        cap: String,
        city: String,
        iva: String,
        fiscalCode: String,
        email: String,
        destinataryCode: String,
        paymentType: String,
        paymentCondition: String,
        noIva: String,
      },
      items: [
        ...,
        {
          name: String,
          price: Number,
          quantity: Number (optional),
          description: String (optional),
          discount: String (optional),
          discountDescription: String (optional),
        }
      ]
    }
  */
  sendSummary(options, isInvoice, message) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.loadPrinterUrl();

        this._builder.beginNonFiscal();
        this._builder.printNormal(2, "[Copia di cortesia]");
        this._builder.printNormal(2, "- copia per uso interno");
        this._builder.printNormal(1, "");
        this._builder.printNormal(
          2,
          "DESCRIZIONE                    IVA   PREZZO(€)"
        );

        if (
          !options.items ||
          !Array.isArray(options.items) ||
          options.items.length == 0
        ) {
          reject(new Error("items are not defined"));
          return;
        } else {
          for (let i = 0; i < options.items.length; i += 1) {
            const item = options.items[i];
            let string15 = "               ";
            let string20 =
              item.quantity + " x " + (item.name || item.description) + "                       ";
            let stringOperator20;
            let stringDiscount;
            if (!!item.discount && item.discount > 0) {
              if (!!item.discountDescription)
                stringDiscount = item.discountDescription.padEnd(38, ' ') + " -" + Number(item.discount).toFixed(2);
              else
                stringDiscount =
                  "SCONTO                                " + " -" + Number(item.discount).toFixed(2);
            }
            if (!!item.additionalDescription) {
              stringOperator20 =
                " eseguito da " + item.additionalDescription;
            }
            let lastString15 =
              (item.tax || "   ") +
              "  " +
              Number(Number(item.quantity) * Number(item.price)).toFixed(2);

            this._builder.printNormal(
              1,
              string20.substring(0, 20) +
              string15.substring(0, 10) +
              lastString15.substring(0, 15)
            );
            if (!!stringDiscount) {
              this._builder.printNormal(1, stringDiscount);
            }
            if (!!stringOperator20) {
              this._builder.printNormal(1, stringOperator20.substring(0, 25));
            }
          }
          if (options.discount) {
            let stringDiscount =
              "SCONTO                                " + " -" + options.discount.amount.toFixed(2);
            this._builder.printNormal(1, stringDiscount);
          }
        }
        this._builder.printNormal(1, "");
        this._builder.printNormal(2, "TOTALE DA PAGARE: " + Number(options["amount"]).toFixed(2));
        this._builder.printNormal(1, "");

        if (isInvoice) {
          this._builder.printNormal(
            1,
            "Dettaglio IVA (EURO €)   IMPONIBILE    IMPOSTA"
          );
          this._builder.printNormal(1, "Aliquota IVA al " + "  " + "22,00 %");
          this._builder.printNormal(1, "");
          this._builder.printNormal(1, "Dati Cliente");
          this._builder.printNormal(
            2,
            options.invoiceData["firstname"] +
            "  " +
            options.invoiceData["companyNameOrLastname"]
          );
          this._builder.printNormal(1, options.invoiceData["address"]);
          this._builder.printNormal(
            1,
            options.invoiceData["cap"] + "  " + options.invoiceData["city"] + " " + options.invoiceData["province"]
          );
          this._builder.printNormal(
            2,
            "Partita Iva:    " + options.invoiceData["vatNumber"]
          );
          this._builder.printNormal(
            1,
            "Codice Fiscale:   " + options.invoiceData["fiscalCode"]
          );
          if (options.invoiceData["email"])
            this._builder.printNormal(
              1,
              "PEC:    " + options.invoiceData["mail"]
            );
          if (options.invoiceData["destinataryCode"])
            this._builder.printNormal(
              1,
              "Cod.Dest.:    " + options.invoiceData["destinataryCode"]
            );
          this._builder.printNormal(1, "");
          this._builder.printNormal(
            1,
            "Segue fattura elettronica, emessa e inviata"
          );
          this._builder.printNormal(1, "al SDI secondo la normativa vigente.");
        }

        if (!!options.additionalRows && Array.isArray(options.additionalRows)) {
          for (let i = 0; i < options.additionalRows.length; i += 1) {
            this._builder.printNormal(1, options.additionalRows[i])
          }
        }

        this._builder.endNonFiscal();
      } catch (error) {
        reject(error);
      }

      axios
        .post(this.printerUrl, this._builder.xml, {
          headers: {
            "Content-Type": "text/xml",
          },
          timeout: this._timeout
        })
        .then((res) => {
          billService.logRTPrintedBillData(message, options, this._builder.xml)
          resolve(this._translateResponse(res.data));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async sendPaymentDebit(params) {
    if (!params.info || !params.info.items || !Array.isArray(params.info.items))
      throw new Error("items are undefined");
    if (!params.documentNumber)
      throw new Error("documentNumber undefined");
    if (!params.createdAt)
      throw new Error("date undefined");

    try {
      await this.loadPrinterUrl();
      this._builder.printRecMessage("RESO MERCE N." + params.documentNumber + " del " + params.createdAt.substring(0, 10), "debit");
      this._builder.beginFiscalReceipt();
      for (const item of params.info.items) {
        this._builder.printRecRefund(item, this.departmentMapper[params.tax]);
      }
      this._builder.printRecTotal(params.amount, 'debit');
      this._builder.endFiscalReceipt();
      let responseRt = await axios.post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
        timeout: this._timeout
      })
      return this._translateResponse(responseRt.data);
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * Send a custom text to print.
   *
   * @param {Object} params - An object containing the text to print and the other options.
   * @param {string} params.text - The text to print.
  */
  async sendCustomText(params) {
    await this.loadPrinterUrl();
    this._builder.beginNonFiscal();
    let rows = params.text.split('\n')
    for(let i = 0; i < rows.length; i += 1) {
      let iterations = Math.ceil(rows[i].length / 46)
      for(let k = 0; k < iterations; k += 1) {
        let row = rows[i].substring(k * 46, (k + 1) * 46)
        if(row == '') row = ' '
        this._builder.printNormal(2, row)
      }
    }

    this._builder.endNonFiscal();

    let responseRt = await axios.post(this.printerUrl, this._builder.xml, {
      headers: {
        "Content-Type": "text/xml",
      },
      timeout: this._timeout
    })

    return this._translateResponse(responseRt.data);
  }

  async sendVoucherReport(amount) {
    if (!amount)
      throw new Error("amount voucher undefined");
    try {
      await this.loadPrinterUrl();
      this._builder.beginNonFiscal();

      this._builder.printNormal(3, 'Gift Card Da Storno');
      this._builder.printNormal(2, 'GIFT CARD ' + amount);
      this._builder.printNormal(2, 'Regalati una coccola al bn1 district');
      this._builder.printNormal(2, 'Il Voucher GIFT CARD ' + amount);
      this._builder.printNormal(2, 'Da diritto a uno sconto a pagare di ' + amount + '€');
      this._builder.printNormal(2, 'Promozione non cumulabile con altre attive');

      this._builder.endNonFiscal();
      let responseRt = await axios.post(this.printerUrl, this._builder.xml, {
        headers: {
          "Content-Type": "text/xml",
        },
        timeout: this._timeout
      })
      return this._translateResponse(responseRt.data);
    } catch (error) {
      throw new Error(error);
    }
  }

  loadPrinterUrl() {
    if (!this._address) {
      return this.reloadPrinterUrl();
    } else {
      return Promise.resolve(this._address);
    }
  }

  reloadPrinterUrl() {
    return new Promise((resolve, reject) => {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this._address = openedCashDesk.printer;
        resolve(openedCashDesk.printer);
      })
    })
  }

  _translateResponse(xml) {
    let json = xml2js(xml, {
      compact: true,
      ignoreDoctype: true,
      attributesKey: "attributes",
    });

    if (
      !!json &&
      !!json["soapenv:Envelope"] &&
      !!json["soapenv:Envelope"]["soapenv:Body"] &&
      !!json["soapenv:Envelope"]["soapenv:Body"]["response"]
    ) {
      if (!!json["soapenv:Envelope"]["soapenv:Body"]["response"]["addInfo"]) {
        for (let [key, value] of Object.entries(
          json["soapenv:Envelope"]["soapenv:Body"]["response"]["addInfo"]
        )) {
          json["soapenv:Envelope"]["soapenv:Body"]["response"]["addInfo"][key] =
            value["_text"];
        }
      }

      return json["soapenv:Envelope"]["soapenv:Body"]["response"];
    } else {
      return {};
    }
  }
}

export default Sender;
