<template>
  <div>
    <v-select
      :label="label"
      :no-data-text="label"
      dense
      :solo="solo"
      :solo-inverted="soloInverted"
      :filled="filled"
      :rounded="rounded"
      :flat="flat"
      hide-details="auto"
      :value="selectedItems"
      :items="[selectedItems]"
      :rules="rules"
      :error="error"
      :readonly="readonly"
      ref="selectInput"
    >
      <template v-slot:append-item>
        <GroupedSelectList
          v-model="localValue"
          :items="serviceTypes"
          categories-identifier-key="id"
          items-identifier-key="id"
          categories-text-key="name"
          items-key="services"
          items-text-key="name"
          :multiple="multiple"
          :allow-select="multiple"
          @input="checkSelectedTag($event)"
        >
        </GroupedSelectList>
      </template>

      <template v-if="!!localValue && Array.isArray(localValue)" v-slot:item={}>
        <span v-if="localValue.length > 0" class="pa-1">{{localValue[0]['name']}}</span>
        <span 
          v-if="localValue.length > 1"
          class="grey--text text-caption"
        >
        (+{{ localValue.length - 1}} altri)
        </span>
      </template>
      <template v-if="!!localValue && Array.isArray(localValue)" v-slot:selection="{}">
        <span v-if="localValue.length > 0" class="pa-1">{{localValue[0]['name']}}</span>
        <span 
          v-if="localValue.length > 1"
          class="grey--text text-caption"
        >
        (+{{ localValue.length - 1}} altri)
        </span>
      </template>
    </v-select>
  
  </div>
</template>

<script>
import Vue from 'vue'
import serviceTypesManagment from "@/services/serviceTypes/serviceTypes.service.js";
import GroupedSelectList from '@/components/common/GroupedSelectList';

export default {
  name: "ServiceTypesServicesSelect",
  components: {
    GroupedSelectList,
  },
  data: function() {
    return {
      localValue: this.value,
      loading: false,
      serviceTypes: [],
      selectedItems: '',
      currentEvent: undefined,
    }
  },
  props: {
    value: {
    },
    label: {
      type: String,
      default: 'Tag Abbinato'
    },
    placeholder: {
      type: String,
      default: undefined
    },
    hideDetails: {
      type: String,
      default: 'auto'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    bus: {
      type: Object,
      default: () => new Vue()
    },
    rules: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    soloInverted: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    elementsToExclude: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.loadServiceTypes()

    if(!!this.localValue && Array.isArray(this.localValue))
        this.selectedItems = this.localValue.map((el) => el.name).join(', ')
      else 
        this.selectedItems = '' 

    if(!!this.bus) {
      this.bus.$on('reload', () => {
        this.loadServiceTypes()
      })
    }
  },
  methods: {
    loadServiceTypes() {
      this.loading = true
      serviceTypesManagment.list(undefined, undefined, this.filters).then((serviceTypes) => {
        for(let i = 0; i < serviceTypes.rows.length; i++) {
          serviceTypes.rows[i]['id'] = serviceTypes.rows[i]['id'].toString()
        }
        this.serviceTypes = serviceTypes.rows
        if (this.elementsToExclude.length > 0) {
          this.serviceTypes = this.filterServices(serviceTypes.rows, this.elementsToExclude)
        }
        this.loading = false
      })
    },
    checkSelectedTag(event) {
      if(!this.multiple && this.localValue.length > 1) {
        this.localValue = [this.localValue[this.localValue.length - 1]]
        this.$emit('input', this.localValue)
        return
      }

      this.$emit('input', event)

      this.currentEvent = event
    },
    filterServices(categories, servicesAlreadyPresent) {
      const flattenServices = servicesAlreadyPresent.flat()

      const filteredCategories = categories.map(category => {
        const filteredServices = category.services.filter(service => {
          return !flattenServices.find(flattenService => flattenService.id === service.id)
        })
        return {
          ...category,
          services: filteredServices
        }
      })

      return filteredCategories
    }
  },
  computed: {
  },
  watch: {
    value(newVal) {
      !!newVal ? this.localValue = newVal : this.localValue = []
      if(Array.isArray(newVal) && newVal.length > 0 && typeof newVal[0] !== 'object'){  // entro quando ho i tag da backend, me li da come array di stringhe
        this.localValue = []
        newVal.map((tagNameString) => {
          let obj = {}
          obj.name = tagNameString
          this.localValue.push(obj)
        })
        this.$emit('input',this.localValue)
      }
      if(!!newVal && Array.isArray(newVal))
        this.selectedItems = newVal.map((el) => el.name).join(', ')
      else 
        this.selectedItems = '' 
    },
  }
}
</script>

<style>

</style>