import Database from '@/services/offline/database.js'

class interestsCache extends Database {

  constructor() {
    super()
  }

  _storeName() {
    return 'interests'
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object[]} param.interests - Color lines to cache
   */
  async cache({ interests }) {
    let storageKey = this._createStorageKey()

    const db = await this.dbPromise
    await db.put(this._storeName(), interests, storageKey)
  }

  async get() {
    let storageKey = this._createStorageKey()

    const db = await this.dbPromise
    return await db.get(this._storeName(), storageKey)
  }

  async cachePresent() {
    return !!(await this.get())
  }

  async deleteAllCache() {
    const db = await this.dbPromise
    return await db.clear(this._storeName())
  }

  _createStorageKey() {
    return 'color-lines'
  }
}

export default new interestsCache()