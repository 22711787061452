import cubeAxios from "./cube.axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class CubeService {
  constructor() {

  }

  load(query) {
    return new Promise((resolve, reject) => {
      const apiUrl = UrlKeeper.getUrl('cube')

      cubeAxios.get(apiUrl + '/load',
        {
          query: query
        }
      ).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    })
  }
}

export default new CubeService()