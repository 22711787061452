export const auth = {
  namespaced: true,
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
  },

  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {        
        commit("SET_USER", {
          id: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          system: user.system
        });
      } else {
        commit("SET_USER", null);
      }
    },
  },
};
