import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import enablingsManager from '@/services/enablings/enablings.service.js'
import recentOperationsDatabase from '@/services/offline/registries/registriesRecentOperations.database.js'
import operatorsCache from './operator.cache'
import calendarService from "../calendar/calendar.service";
import socketService from '../socket/socket.service'
import { xor } from 'lodash'

class operatorManagment {

  constructor() {
    this._alreadyLoaded = false

    socketService.on('operators:update', () => {
      this.reloadCache()
    })

    socketService.on('operators:create', () => {
      this.reloadCache()
    })

    socketService.on('operators:archive', () => {
      this.reloadCache()
    })

    socketService.on('operators:updateOrder', async () => {
      await this.reloadCache()
      calendarService.emit('operators-cached-changes')
    })
  }

  /**
   * @param {Object} params - Params
   * @param {Object} params.filters - Cached filters
   * @param {String} params.filters.notInStateOperator - Filter operators by state
   * @param {Object} params.filters.calendar - Filter operators by calendar
   * @param {String[]} params.filters.calendar.id - Filter operators by calendar id
   * @param {String[]} params.filters.calendar.name - Filter operators by calendar name
   * @returns 
   */
  async cachedList(params) {
    if (!this._alreadyLoaded) {
      await operatorsCache.deleteAllCache()
      this._alreadyLoaded = true
    }

    let operators = []
    if (await operatorsCache.cachePresent()) {
      operators = await operatorsCache.get()
    } else {
      await this.reloadCache({ filters: params.filters })
      operators = await operatorsCache.get()
    }

    return operators.filter((operator) => {
      let visible = true
      if (!!params && !!params.filters) {
        if (!!params.filters.notInStateOperator) {
          visible = visible && operator.state != params.filters.notInStateOperator
        }

        if (!!params.filters.state) {
          visible = visible && operator.state == params.filters.state
        }

        if (!!params.filters.calendar) {
          if (!!params.filters.calendar.id) {
            visible = visible && operator.calendars.some((calendar) => params.filters.calendar.id.includes(calendar.id))
          }

          if (!!params.filters.calendar.name) {
            visible = visible && operator.calendars.some((calendar) => params.filters.calendar.name.includes(calendar.name))
          }
        }
      }
      return visible
    })
  }

  async reloadCache() {
    const operators = await this.list(1, 1000)
    await operatorsCache.cache({ operators: operators.rows })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/operators/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          for (let i = 0; i < response.results.rows.length; i++) {
            response.results.rows[i].fullname = ''
            if (!!response.results.rows[i].firstname) response.results.rows[i].fullname += response.results.rows[i].firstname
            if (!!response.results.rows[i].lastname) response.results.rows[i].fullname += ' ' + response.results.rows[i].lastname
          }

          resolve(response.results)
        }
      }).catch((err) => {
        console.log(err)
      })
    });
  }

  async create(operator, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    operator['id'] = undefined
    const operatorParams = this._extractOperatorParams(operator)
    const response = await axios.post(apiUrl + '/registries/operators/create', operatorParams)

    if (response.success) {
      let promises = []
      if (operator.calendarIds) {
        for (let i = 0; i < operator.calendarIds.length; i++) {
          promises.push(
            axios.post(apiUrl + '/calendars/' + operator.calendarIds[i] + '/operators/add', {
              operator: {
                id: response.results.id
              },
            })
          )
        }
      }

      if (operator.avatar) {
        response.results.avatar = operator.avatar
        promises.push(this.uploadAvatar(response.results))
      }

      if (operator.enablingServices) {
        let localEnablingServices = operator.enablingServices.map((el) => { return { id: el.id } })
        promises.push(enablingsManager.set(response.results, localEnablingServices))
      }

      promisesResponse = await Promise.all(promises)

      if (recentOperation) {
        recentOperationsDatabase.pushOperation({
          "text": "Aggiungi nuovo Operatore",
          "name": "add_operators",
          "operationName": "add",
          "registry": "operators",
          "params": {
            operator: operator,
            routeName: 'OperatorsRegistryNewForm'
          }
        }).then(() => { })
      }

      await this.reloadCache()
      return promisesResponse
    } else {
      throw response.results
    }
  }

  async archive(operator) {
    const apiUrl = UrlKeeper.getUrl()

    if (!operator.id)
      throw new Error('id undefined')

    const response = await axios.post(apiUrl + 'registries/operators/archive', operator)
    if (response.success) {
      await this.reloadCache()
      return response.results
    } else {
      throw response.results
    }
  }

  uploadAvatar(operator) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!operator.id) {
        reject('id not specified')
        return
      }

      if (operator.avatar) {
        var form = new FormData();
        form.append("avatar", operator['avatar']);
      }

      axios.post(apiUrl + '/registries/operators/' + operator.id + '/uploadAvatar', form).then((response) => {
        resolve(response.results)
      })
    })
  }

  uploadAvatarPhoto(operatorId, data) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!operatorId) {
        reject('id not specified')
        return
      }

      axios.post(apiUrl + '/registries/operators/' + operatorId + '/uploadAvatar', data).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }
  reloadAvatar(operatorId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!operatorId) {
        reject('id not specified')
        return
      }

      axios.get(apiUrl + '/registries/operators/reloadAvatar/' + operatorId).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(operator, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!operator.id) {
        reject('id undefined')
      }

      const operatorParams = this._extractOperatorParams(operator)

      this.get(operator.id).then((operatorFromGet) => {
        axios.post(apiUrl + '/registries/operators/update', operatorParams).then((response) => {
          if (response.success) {
            let promises = []

            if (operator.calendarIds) {
              const calendarToAddIds = xor(operatorFromGet.calendarIds, operator.calendarIds).filter((el) => { return operator.calendarIds.includes(el) })
              const calendarToRemoveIds = xor(operatorFromGet.calendarIds, operator.calendarIds).filter((el) => { return operatorFromGet.calendarIds.includes(el) })

              for (let i = 0; i < calendarToAddIds.length; i++) {
                promises.push(
                  axios.post(apiUrl + '/calendars/' + calendarToAddIds[i] + '/operators/add', {
                    operator: {
                      id: response.results.id
                    },
                  })
                )
              }

              for (let i = 0; i < calendarToRemoveIds.length; i++) {
                promises.push(
                  axios.post(apiUrl + '/calendars/' + calendarToRemoveIds[i] + '/operators/remove', {
                    operator: {
                      id: response.results.id
                    },
                  })
                )
              }
            }

            if (operator.avatar) {
              response.results.avatar = operator.avatar
              promises.push(this.uploadAvatar(response.results))
            }

            if (operator.enablingServices) {
              let localEnablingServices = operator.enablingServices.map((el) => { return { id: el.id } })
              promises.push(enablingsManager.set(operator, localEnablingServices))
            }

            if (promises.length > 0) {
              Promise.all(promises).then((responses) => {
                this.reloadCache().then(() => {
                  resolve(response.results)
                })
              })
            } else {
              this.reloadCache().then(() => {
                resolve(response.results)
              })
            }

            if (recentOperation) {
              recentOperationsDatabase.pushOperation({
                "text": "Modifica Operatore",
                "extraText": operator.firstname + ' ' + operator.lastname,
                "name": "edit_operators",
                "operationName": "edit",
                "registry": "operators",
                "params": {
                  operator: operator,
                  routeName: 'OperatorsRegistryEditForm',
                  routeParams: {
                    id: operator.id
                  }
                }
              }).then(() => { })
            }
          } else {
            reject('error during the call')
          }
        })
      })
    })
  }

  delete(operator) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!operator.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/operators/delete', {
        id: operator.id
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(operatorId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/operators/get', { id: operatorId }).then((response) => {
        if (response.success) {
          axios.get(apiUrl + '/calendars/getCalendarsOfOperator', { id: response.results.id }).then(({ results: calendars }) => {
            response.results['calendarIds'] = calendars.map((el) => { return el.id })
            response.results['calendars'] = calendars
            resolve(response.results)
          })
        }
      })
    })
  }

  getByBarcode(barcode) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/operators/getByBarcode', { barcode: barcode }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  canPerformOperation(barcode, operation, action, needOperator = false) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/operators/canPerformOperation', { barcode: barcode, operation: operation, action: action }).then((response) => {
        if (response.success) {
          axios.setHeader("operator-barcode", barcode, 365)
          if (!needOperator)
            resolve(response.results.result)
          else {
            resolve(response.results)
          }
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  updateOrderOperators(operators) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/operators/updateOrderOperators', { operators: operators }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  print(operatorId, filename = "operator" + operatorId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.postWithDownload(apiUrl + "/registries/operators/" + operatorId + "/print", { id: operatorId }, filename + ".pdf").then((response) => {
        resolve(response)
      }).catch((err) => {
        console.log(err)
      })
    });
  }

  _extractOperatorParams(params) {
    let operator = {}
    let keys = Object.keys(params)
    for (let i = 0; i < keys.length; i++) {
      if (!['calendarIds', 'avatar'].includes(keys[i])) {
        operator[keys[i]] = params[keys[i]]
      }
      if (keys[i] == 'enablingServices') {
        operator[keys[i]] = params[keys[i]].map((el) => { return { id: el.id } })
      }
    }
    return operator
  }



  fields() {
    return Promise.resolve([
      { text: 'Cognome', value: 'lastname' },
      { text: 'Nome', value: 'firstname' },
      { text: 'Stato', value: 'state', type: 'enum' },
      { text: 'Ore Contrattuali', value: 'contractualHours' },
      { text: 'Colore del testo', value: 'textColor', type: 'color' },
      { text: 'Colore dello sfondo', value: 'color', type: 'color' },
      { text: 'Qualifica', value: 'qualification', type: 'custom' },
    ])
  }

  _fieldsToExport() {
    return {
      'Cognome': 'lastname',
      'Nome': 'firstname',
      'Stato': {
        field: 'state',
        callback: (value) => {
          let map = {
            'valid':'Operatore in forza',
            'disabled':'Operatore cessato' 
          }
          
          return map[value]
        }
      },
      'Qualifica': {
        field: 'qualificationOperators',
        callback: (value) => {
          return value.qualification.name
        }
      },
      'Ore Contrattuali': 'contractualHours',
      'Calendari': {
        field: 'calendars',
        callback: (calendars) => {
          return calendars.map((el) => { return el.name }).join(', ')
        }
      }
    }
  }
}

export default new operatorManagment();
