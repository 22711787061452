<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Registri Sms"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
    </template>
    <template v-slot:content>
      <AdvancedFilter
        style="width:99%"
        v-model="filtersValue"
        :filters="filterTypes"
        :advance-filters="advanceFilters"
        :external-filter="externalFilter"
        @apply-filters="applyFilters"
        class="mb-3"
      >
        <template v-slot:chip-custom-customer="{filter}">
          Nome: {{ filter.value}}
        </template>

        <template v-slot:custom-smsProviderId="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <v-switch
                v-model="filter.value"
                false-value="2"
                true-value="1"
                color="orange"
                :label="filter.value == '2' ? 'Whatsapp' : 'Esendex'"
                :class="filter.value == '2' ? 'custom-blue-switch' : 'custom-orange-switch'"
                flat
              >
              </v-switch>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-smsStatus="{filter}">
          <v-row class="pa-2">
            <v-col>
              <v-select
                v-model="filter.value"
                :items="[
                  { type: 'OK', name: 'OK' },
                  { type: 'ERROR', name: 'ERROR' },
                ]"
                :label="filter.label"
                :multiple="true"
                item-text="name"
                item-value="type"
                hide-details
                dense
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <template v-slot:chip-custom-smsStatus="{filter}">
          Stato: {{ filter.value.join(', ')}}
        </template>
      </AdvancedFilter>
      <div style="height: 80%" ref="dataTableWrapper" class="mb-10">
        <TypeDataTable
          :headers="localHeaders"
          :items="localItems"
          :showSelect="false"
          :showActions="false"
          :loading="loading"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :disablePagination="false"
          item-key="id"
          :height="dataTableHeight"
        >
          <template v-slot:custom-fullname="{ item }">
            <span v-if="!!item.firstname || !!item.lastname">
              {{item.firstname + " " + item.lastname}}
            </span>
            <span v-else>
              Nessun cliente
            </span>
          </template>
          <template v-slot:custom-providerId="{ item }">
            <v-icon>{{Number(item.smsProviderId) == 1 ? 'mdi-message-fast' :  'mdi-whatsapp'}}</v-icon>
          </template>
          <template v-slot:custom-createdAt="{ item }">
            {{item.createdAt}}
          </template>
          <template v-slot:custom-status="{ item }">
            {{item.status}}
          </template>
        </TypeDataTable>
      </div>
    </template>
  </FullScreenDialog>
</template>

<script>
import smsService from '@/services/sms/sms.service.js';
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "DiaryFormTab",
  components: {
    TypeDataTable,
    DateTimeFormattor,
    AdvancedFilter,
    FullScreenDialog,
    ExportExcelTable
  },
  data: function () {
    let props = this.$router.resolve({ name: "Marketing" });
    return {
      dialog: true,
      routeFather: props.href,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      localHeaders: [
        { text: "Cliente", value: "fullname", type: "custom"},
        { text: "Messaggio", value: "message"},
        {
          text: "Provider SMS",
          value: "providerId",
          type: "custom",
        },
        { text: "Data Invio", value: "createdAt", type: "datetime" },
        { text: "Stato SMS", value: "status", type: "custom" },
      ],
      localItems: [],
      selectedCustomerId: undefined,
      loading: true,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'sms_register.createdAt', name: 'Data', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'equal', field: 'smsProviderId', name: 'Provider', icon: 'mdi-cellphone-wireless', label: 'Provider', color: "", value: '1' },
        { type: 'custom', operator: 'custom', field: 'smsStatus', name: 'Stato', label:'Stato', color: "", value: []},
        { type: 'string', operator: 'like', field: 'message', name: 'Messaggio', label: 'Messaggio', color: "", value: undefined}
      ],
      advanceFilters: [],
      externalFilter: { type: 'custom', operator: 'custom', field: 'fullname', name: 'Nome', label: 'Nome Cliente', color: "", value: undefined },
      filtersValue: [],
      page: 1,
      rowPerPage: 200,
      totalPages: 0,
      worksheetName: "Registro SMS",
      fileName: "",
      fieldsToExport: {},
    };
  },
  mixins:[dateUtils],
  mounted: function () {
    this.fetchRegister();

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 1;

    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 1;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);

    this.fileName = "Registro_SMS_" + this._buildDateToExport(new Date()) +
      ".xls";

    this.fieldsToExport = smsService._fieldsToExport()
  },
  methods: {
    fetchRegister() {
      let filters = [...this.filtersValue];
      return new Promise((resolve, reject) => {
        smsService.list(this.page, this.rowPerPage, filters).then((result) => {
          if (this.rowPerPage != result.rowPerPage) {
            this.rowPerPage = result.length;
          }

          if (this.page != result.page) {
            this.page = result.page;
          }

          this.totalPages = result.totalPages;
          if (this.totalPages < this.page && this.totalPages != 0) {
            this.page = this.totalPages;
          }
          this.loading = false;
          this.localItems = result.rows;
        });
      });
    },
    applyFilters(filters) {
      this.fetchRegister();
    },
    async fetchExcel(){
      let filters = [...this.filtersValue];
      let result = await smsService.list(this.page, 15000, filters)
      return result.rows
    }
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.applyFilters();
    },
    rowPerPage() {
      this.applyFilters();
    },
  },
};
</script>


<style>
.custom-blue-switch .v-input--selection-controls__input div {
  color: blue !important;
  caret-color: blue !important;
}
.custom-orange-switch .v-input--selection-controls__input div {
  color: orange !important;
  caret-color: orange !important;
}
</style>