import authService from "@/services/auth/auth.service";
const paymentTransactionsGivenRoutes = [
  {
    path: "/payments/paymentsGiven",
    name: "PaymentTransactionsGivenNewForm",
    component: () => import("@/components/areas/payments/paymentTransactionsGiven/PaymentTransactionsGivenNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/paymentsGiven/list",
    name: "PaymentTransactionsGivenList",
    component: () =>
      import(
        "@/components/areas/payments/paymentTransactionsGiven/PaymentTransactionsGivenList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters,
      }
    }
  },
  {
    path: "/payments/paymentsGiven/:id/edit",
    name: "PaymentTransactionsGivenEditForm",
    component: () =>
      import(
        "@/components/areas/payments/paymentTransactionsGiven/PaymentTransactionsGivenEditForm.vue"
      ),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },

]
export default paymentTransactionsGivenRoutes;