import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import interestsCache from "./interest.cache";

class interestsService {
  async cachedList(filters) {
    let localFilters = !!filters ? filters : {}
    if (!this._alreadyLoaded) {
      await interestsCache.deleteAllCache()
      this._alreadyLoaded = true
    }

    if (await interestsCache.cachePresent()) {
      return await interestsCache.get()
    } else {
      await this.reloadCache(localFilters)
      return await interestsCache.get()
    }
  }

  async reloadCache(filters) {
    const interests = await this.list(1, 1000, filters)
    await interestsCache.cache({ interests: interests.rows })
  }

  setAlreadyLoaded(value) {
    this._alreadyLoaded = value
  }
  
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/interests/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  create(customerInterest) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/interests/create", customerInterest)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  update(customerInterest) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/interests/update", customerInterest)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  delete(customerInterest) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customerInterest.id) {
        reject("id undefined");
      }

      axios
        .get(apiUrl + "/registries/interests/delete", {
          id: customerInterest.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        });
    });
  }

  get(customerInterestId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/interests/get", { id: customerInterestId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  async fields() {
    return [
      { text: "Nome", value: "name" },
    ];
  }
}

export default new interestsService();
