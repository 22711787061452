var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"content-to-print"}},[_c('TitleWithBackButton',{attrs:{"title":"Efficientamento Prodotti","back-route":"Analytics","icon":"mdi-graphql"}}),_c('div',{staticClass:"d-flex align-center ml-3 mt-3 pr-5 flex-wrap"},[_c('RangeDateSelector',{ref:"dateSelector",attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"loading":_vm.loading,"disabled":_vm.loading,"max-width":!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : this.$vuetify.breakpoint.md ? '60vw' : '50vw'},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event}}}),_c('v-btn',{staticClass:"mr-2 ml-2",class:{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm},attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.calculate}},[_vm._v("Calcola")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v("Salva")]),_c('v-btn',{staticClass:"btn-export",attrs:{"loading":_vm.loading},on:{"click":_vm.exportToExcel}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading || _vm.printLoading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.print}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),_c('v-btn',{staticStyle:{"float":"right","margin-right":"2.5%"},attrs:{"icon":"","large":"","color":!!_vm.isB ? 'red' : ''},on:{"click":() => { _vm.dialogBarcode = !_vm.dialogBarcode }}},[_c('v-icon',[_vm._v("mdi-shield-account-variant")])],1)],1),_c('div',{staticClass:"pa-4 mt-5"},[_c('div',{staticStyle:{"overflow-x":"auto","overflow-y":"hidden","margin-bottom":"15px"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"fit-content","gap":"15px"}},[_c('div',{style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '6dvw' : _vm.$vuetify.breakpoint.md ? '6dvw' : '4dvw'
            })}),_c('div',{staticClass:"text-center",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '35dvw' : _vm.$vuetify.breakpoint.md ? '25dvw' : '20dvw'
            })},[_vm._v(" Tag di Costo ")]),_c('div',{staticClass:"text-center",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '35dvw' : _vm.$vuetify.breakpoint.md ? '25dvw' : '25dvw'
            })},[_vm._v(" Famiglia Servizio ")]),_c('div',{staticClass:"text-center",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '20dvw' : _vm.$vuetify.breakpoint.md ? '10dvw' : '10dvw'
            })},[_vm._v(" Rapporto Efficienza ")]),_c('div',{staticClass:"text-center",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '25dvw' : _vm.$vuetify.breakpoint.md ? '16dvw' : '16dvw'
            })},[_vm._v(" Grado Efficienza ")]),_c('div',{style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '15dvw' : _vm.$vuetify.breakpoint.md ? '6dvw' : '3dvw'
            })})]),_vm._l((_vm.rows),function(row,idx){return _c('div',{key:idx,staticClass:"d-flex align-center",staticStyle:{"width":"fit-content","gap":"15px"}},[_c('div',{staticClass:"d-flex align-center justify-end",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '6dvw' : _vm.$vuetify.breakpoint.md ? '6dvw' : '4dvw'
            }),attrs:{"cols":"1"}},[(row.error)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert ")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center justify-center",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '35dvw' : _vm.$vuetify.breakpoint.md ? '25dvw' : '25dvw'
            }),attrs:{"cols":"3"}},[_c('TagsAutocomplete',{staticStyle:{"width":"100%"},attrs:{"multiple":false,"solo-inverted":"","flat":"","return-object":"","filters":{ typeCost: true }},on:{"input":function($event){return _vm.handleInput(row)}},model:{value:(row.tag),callback:function ($$v) {_vm.$set(row, "tag", $$v)},expression:"row.tag"}})],1),_c('div',{staticClass:"d-flex align-center justify-center",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '35dvw' : _vm.$vuetify.breakpoint.md ? '25dvw' : '20dvw'
            }),attrs:{"cols":"3"}},[_c('ServiceTypeServicesSelect',{staticStyle:{"width":"100%"},attrs:{"flat":"","dense":"","label":"","multiple":true,"clearable":false,"height":"15px","filters":{
                categoriesToExclude: ['BISTROT'],
              }},on:{"input":function($event){return _vm.handleInput(row)}},model:{value:(row.services),callback:function ($$v) {_vm.$set(row, "services", $$v)},expression:"row.services"}})],1),_c('div',{style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '20dvw' : _vm.$vuetify.breakpoint.md ? '10dvw' : '10dvw'
            }),attrs:{"cols":"2"}},[_c('v-row',{staticClass:"d-flex align-center justify-center"},[_c('div',[_c('v-autocomplete',{staticClass:"mt-6",attrs:{"dense":"","solo-inverted":"","flat":"","rounded":"","items":_vm.listItemCoefficients},on:{"change":function($event){return _vm.handleInput(row)}},model:{value:(row.itemCoefficent),callback:function ($$v) {_vm.$set(row, "itemCoefficent", $$v)},expression:"row.itemCoefficent"}})],1)])],1),_c('div',{staticClass:"d-flex align-center justify-center",style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '25dvw' : _vm.$vuetify.breakpoint.md ? '16dvw' : '16dvw'
            }),attrs:{"cols":"2"}},[(!!row.efficency)?_c('v-chip',{attrs:{"color":_vm.color(row.efficency)}},[_vm._v(" "+_vm._s(Number(row.efficency).toFixed(0))+"% ")]):_c('v-chip',{attrs:{"color":"grey"}},[_vm._v(" Non calcolato ")])],1),_c('div',{style:({
              'padding': '5px',
              'width': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? '15dvw' : _vm.$vuetify.breakpoint.md ? '6dvw' : '3dvw'
            })},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"error","fab":"","small":"","outlined":"","elevation":"0"},on:{"click":function($event){return _vm.removeRow(idx)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])})],2),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center mb-3"},[_c('v-btn',{staticClass:"px-10 mr-2",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.addRow()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),_c('StandardDialog',{attrs:{"title":"Area Riservata Sviluppatori","dialog-height":null,"dialog-max-width":"500px"},model:{value:(_vm.dialogBarcode),callback:function ($$v) {_vm.dialogBarcode=$$v},expression:"dialogBarcode"}},[_c('ManualBarcodeInput',{attrs:{"scan-your-barcode-message":"Immettere Codice"},on:{"confirm":_vm.activeBarcode},model:{value:(_vm.operatorBarcode),callback:function ($$v) {_vm.operatorBarcode=$$v},expression:"operatorBarcode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }