import Database from '@/services/offline/database.js'

class FrequentServiceCache extends Database {

  constructor() {
    super()
  }

  _storeName() {
    return 'frequent-services'
  }

  _maxRows() {
    return 50
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.customer - Customer to cache
   * @param {String} param.customer.id - Customer id
   * @param {Object[]} param.frequentServices - Services to cache
   */
  async cacheCustomer({ customer, frequentServices }) {
    let storageKey = this._createStorageKey({
      customer
    })

    const db = await this.dbPromise

    const keys = await db.getAllKeys(this._storeName())
    if (keys.length > this._maxRows()) {
      db.delete(this._storeName(), keys.reverse()[0])
    }

    await db.put(this._storeName(), frequentServices, storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.customer - Customer to get cache
   * @param {String} param.customer.id - Customer id
   */
  async getCustomer({ customer }) {
    let storageKey = this._createStorageKey({
      customer
    })

    const db = await this.dbPromise
    return await db.get(this._storeName(), storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.customer - Customer to get cache
   * @param {String} param.customer.id - Customer id
   */
  async cachePresent({ customer }) {
    return !!(await this.getCustomer({ customer }))
  }

  async deleteAllCache() {
    const db = await this.dbPromise
    return await db.clear(this._storeName())
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.customer - Customer storage key 
   * @param {String} param.customer.id - Customer id
   */
  _createStorageKey({ customer }) {
    if(!!customer && !!customer.id) {
      return customer.id
    } else {
      return 'store'
    }
  }
}

export default new FrequentServiceCache()