<template> 
  <div> 
    <v-autocomplete
      v-model="selected" 
      :items="formattedItems" 
      :loading="loading" 
      :search-input.sync="search" 
      :color="color" 
      :item-text="typeof itemText == 'function' ? '__text' : itemText" 
      :item-value="itemValue" 
      :label="label" 
      :placeholder="placeholder" 
      :prepend-icon="prependIcon" 
      :append-outer-icon="appendOuterIcon" 
      :append-icon="appendIcon"
      :return-object="returnObject" 
      :multiple="multiple" 
      :error="error || localError" 
      :menu-props="menuProps" 
      :filled="filled"
      :rounded="rounded"
      :solo="solo" 
      :shaped="shaped" 
      :dense="dense" 
      :outlined="outlined"
      :clearable="clearable" 
      :chips="chips" 
      :hide-details="hideDetails" 
      :no-data-text="message" 
      :hint="selectedPresent ? undefined : message" 
      :hide-no-data="hideNoData" 
      :disabled="disabled"
      @change="$emit('input', selected)" 
      @click:prepend="$emit('click:prepend', selected)" 
      @click:append-outer="$emit('click:append-outer', selected)"
      ref="autocomplete"
    ></v-autocomplete> 
  </div> 
</template> 
 
<script> 
  import {VAutocomplete} from 'vuetify/lib' 
   
  export default { 
    name: "AjaxAutocomplete", 
    components: { 
      VAutocomplete 
    }, 
    data: function() { 
      return { 
        items: [], 
        loading: false, 
        selected: this.value, 
        search: null, 
        localError: false, 
        hint: this.keepTypeMessage, 
        message: this.keepTypeMessage, 
        runningSearch: undefined 
      } 
    }, 
    props: {
      focusText: {
        type: Boolean,
        default: false
      },
      value: { }, 
      color: { 
        type: String, 
        default: 'primary' 
      }, 
      label: { 
        type: String, 
        default: undefined 
      }, 
      filled: { 
        type: Boolean, 
        default: true 
      },
      rounded: { 
        type: Boolean, 
        default: true 
      },
      solo: { 
        type: Boolean, 
        default: false 
      }, 
      dense: { 
        type: Boolean, 
        default: false 
      }, 
      shaped: { 
        type: Boolean, 
        default: false 
      }, 
      outlined: { 
        type: Boolean, 
        default: false 
      },
      returnObject: { 
        type: Boolean, 
        default: true 
      }, 
      multiple: { 
        type: Boolean, 
        default: false 
      }, 
      chips: { 
        type: Boolean, 
        default: false 
      }, 
      hideDetails: { 
        default: 'auto' 
      }, 
      clearable: { 
        type: Boolean,  
        default: true 
      }, 
      placeholder: { 
        type: String, 
        default: 'Start typing ...' 
      }, 
      prependIcon: { 
        type: String, 
        default: undefined 
      }, 
      appendOuterIcon: { 
        type: String, 
        default: undefined 
      }, 
      appendIcon: { 
        type: String, 
        default: undefined 
      },
      itemText: { 
        default: 'text' 
      }, 
      itemValue: { 
        type: String, 
        default: 'id' 
      }, 
      textLimit: { 
        type: Number, 
        default: 60 
      }, 
      menuProps: { 
        type: Object, 
        default: function() { 
          return { 
            offsetY: true 
          } 
        } 
      }, 
      searchThreshold: { 
        type: Number, 
        default: 3 
      }, 
      searcher: { 
        default: function() { 
          return (search) => { 
            return Promise.resolve(search) 
          } 
        } 
      }, 
      valueSearcher: { 
        default: function() { 
          return (key) => { 
            return Promise.resolve({id: key}) 
          } 
        } 
      }, 
      noDataText: { 
        type: String, 
        default: "No results for the search" 
      }, 
      keepTypeMessage: { 
        type: String, 
        default: "Keep typing ..." 
      }, 
      hideNoData: { 
        type: Boolean, 
        default: false 
      },
      error:{
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      externalSearch: {
        type: String,
        default: undefined
      }
    }, 
    mounted: function() { 
      const isSelected = (!this.returnObject && !this.multiple && !!this.selected) || 
        (!this.multiple && this.returnObject && Object.keys(this.selected).length > 0) ||  
        (this.multiple && this.selected.length > 0) 
 
      if(isSelected && this.items.length == 0) { 
        this.items.push(this.selected) 
      }
      
      if(!!this.focusText) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.autocomplete.focus()
          })
        }, 500)
      }

      if (!!this.externalSearch) {
        this.search = this.externalSearch
      }
    }, 
    methods: { 
      fetchItems(searchText) { 
        if(!!searchText && searchText.length > this.searchThreshold) { 
          this.message = this.noDataText 
          this.hint = null 
          this.loading = true 
          this.runningSearch = this.searcher(searchText) 
          this.runningSearch.then(items => { 
              if(this.selectedPresent && items.length == 0) { 
                items = [this.selected] 
              } else if(this.selectedPresent && items.length != 0) { 
                items.push(this.selected) 
              } 
              this.items = items 
            }) 
            .catch(err => { 
              this.localError = true 
            }) 
            .finally(() => { 
              this.loading = false 
              this.runningSearch = undefined 
              if(this.search != searchText) { 
                this.fetchItems(this.search) 
              } 
            }) 
        } else { 
          this.message = this.keepTypeMessage 
          this.hint = this.keepTypeMessage 
        } 
      }, 
      handleValueChange(newValue) { 
        this.selected = newValue 
        if(!newValue) { 
          return 
        } else if(this.multiple) { 
          this.items = [...newValue] 
        } else if(this.returnObject) { 
          this.items = [{...newValue}] 
        } else { 
          this.valueSearcher(newValue).then((response) => { 
            this.items = [response] 
          }) 
        } 
      } 
    }, 
    computed: { 
      formattedItems () { 
        if(typeof this.itemText == "string") { 
          return this.items.map(entry => { 
            const text = !!entry[this.itemText] && entry[this.itemText].length > this.textLimit 
              ? entry[this.itemText].slice(0, this.textLimit) + '...' 
              : entry[this.itemText] 
 
            let newObject = {...entry} 
            newObject[this.itemText] = text 
            return newObject 
          }) 
        } else if(typeof this.itemText == "function") { 
          return this.items.map(entry => { 
            const entryText = this.itemText(entry) 
            const text = !!entryText && entryText.length > this.textLimit 
              ? entryText.slice(0, this.textLimit) + '...' 
              : entryText 
 
            let newObject = {...entry} 
            newObject['__text'] = text 
            return newObject 
          }) 
        } 
      }, 
      selectedPresent() { 
        return this.selected !== undefined && this.selected !== null && Object.keys(this.selected).length != 0 
      } 
    }, 
    watch: { 
      search (val) { 
        if (this.loading) return 
        this.fetchItems(val) 
      }, 
      value(newVal) { 
        if(this.runningSearch) { 
          this.runningSearch.then(() => { 
            this.handleValueChange(newVal) 
          }) 
        } else { 
          this.handleValueChange(newVal) 
        } 
      } 
    }, 
  } 
</script>