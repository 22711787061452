<template>
  <div style="width: 100; height: 100%" id='content-to-print'>
    <TitleWithBackButton
      title="Riepilogo Finanziario"
      back-route="Analytics"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <div>
      <div 
        class="d-flex align-center ml-3 mt-3 flex-wrap"
      >
        <RangeDateSelector
          ref="dateSelector"
          :start-date.sync="startDate"
          :end-date.sync="endDate"
          :loading="loading"
          :disabled="loading"
          :max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
            ? 'fit-content' : this.$vuetify.breakpoint.md ? '70vw' : '50vw'"
        ></RangeDateSelector>
        <v-btn
          class="mr-1 ml-8"
          text
          @click="calculate"
          :loading="loading"
          :disabled="!startDate || !endDate"
        >Calcola</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="btn-export"
          @click="exportToExcel"
          :loading="loading"
        ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
        <v-btn
          class="mr-1 ml-1"
          text
          @click="print"
          :loading="loading"
          :disabled="!startDate || !endDate"
        ><v-icon> mdi-printer </v-icon></v-btn>
        <v-btn
          class="mr-1 ml-1"
          text
          @click="handleSplitCost"
          :loading="loading"
        >Ripartizione Utenze</v-btn>
        <v-btn 
          icon large 
          :disabled="groupedRevenueData.length == 0 || loading" 
          style="float: right; margin-right: 2.5%"
          @click="() => { dialogBarcode = !dialogBarcode }"
          :color="!!isB ? 'red' : ''"
        >
          <v-icon>mdi-shield-account-variant</v-icon>
        </v-btn>
      </div>
      <div v-if="loading" class="text-center mt-2">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div v-else 
        :class="{
          'ml-8': !$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl,
        }"
      >
        <v-row 
          style="height: 45%; margin-bottom: 15px; margin-top: 20px;
            margin-left: auto; margin-right: auto;
            padding-left: 5px; padding-right: 0px;" 
          class="d-flex align-content justify-center"
        >
          <v-col 
            cols="12"
            sm="12"
            md="6" 
            lg="4"
            xl="4"
            :style="{
              'max-height': '105%',
              'max-width': '100%',
              'border-right': $vuetify.breakpoint.md || $vuetify.breakpoint.lg
                || $vuetify.breakpoint.xl ? '1px solid lightgrey' : null
            }"
          >
            <v-row class="d-flex align-content justify-center" style="margin-top: 10px; margin-bottom: 20px">
              <v-col cols="12" class="d-flex align-content justify-center">
                <div class="title-section" :style="backgroundColorRevenue">{{Number(Number(revenueTotal).toFixed(2)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}} d'Incassi</div>
              </v-col>
            </v-row>
            <v-row v-if="groupedRevenueData.length > 0" class="d-flex align-content justify-center" style="margin-bottom: 5px">
              <DoughnutChart
                :chart-data="revenueChartData"
                :styles="{
                  height: '300px',
                  width: '300px'
                }"
                :options="chartOptions"
              ></DoughnutChart>
            </v-row>
            <v-row v-if="groupedRevenueData.length > 0">
              <TypeDataTable
                headersColor="rgb(212, 212, 212)"
                style="width: 100%; background-color: transparent; overflow-x: hidden;"
                :headers="chartHeadersTable"
                :items="groupedRevenueData"
                item-key="tagCategoryName"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :show-expand="true"
                :disablePagination="true"
                overflow-y="hidden"
                font-size-row="0.76rem"
                :table-wrapper-max-height="null"
                @expandItem="openDetail('revenue', $event)"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-5">
                    <div
                      class="d-flex align-content justify-center"
                      style="margin-bottom: 10px"
                    >
                      <DoughnutChart
                        :chart-data="!!item && !!item.expandedChildrenChartData ? item.expandedChildrenChartData
                          : { labels: [], datasets: [] }"
                        :styles="{
                          height: '300px',
                          width: '300px'
                        }"
                        :options="chartOptions"
                      ></DoughnutChart>
                    </div>

                    <TypeDataTable
                      headersColor="rgb(212, 212, 212)"
                      style="width: 100%; background-color: transparent; overflow-x: hidden;"
                      :headers="chartDetailsHeaderTable"
                      :items="!!item && !!item.expandedChildren ? item.expandedChildren
                        : []"
                      item-key="tagName"
                      :show-select="false"
                      :select-on-row-click="false"
                      :show-actions="false"
                      :disablePagination="true"
                      :show-expand="!!item && !!item.expandedChildren && item.expandedChildren.length > 0
                        ? item.expandedChildren.some(el => el.canClick) : false"
                      overflow-y="hidden"
                      font-size-row="0.76rem"
                      :table-wrapper-max-height="null"
                      @expandItem="openDetail('subRevenue', $event)"
                    >
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                          <div
                            class="d-flex align-content justify-center"
                            style="margin-bottom: 10px"
                          >
                            <DoughnutChart
                              :chart-data="!!item && !!item.expandedChildrenChartData ? item.expandedChildrenChartData
                                : { labels: [], datasets: [] }"
                              :styles="{
                                height: '300px',
                                width: '300px'
                              }"
                              :options="chartOptions"
                            ></DoughnutChart>
                          </div>

                          <TypeDataTable
                            headersColor="rgb(212, 212, 212)"
                            style="width: 100%; background-color: transparent; overflow-x: hidden;"
                            :headers="chartDetailsHeaderTable"
                            :items="!!item && !!item.expandedChildren ? item.expandedChildren
                              : []"
                            :show-select="false"
                            :select-on-row-click="false"
                            :show-actions="false"
                            overflow-y="hidden"
                            font-size-row="0.76rem"
                            :table-wrapper-max-height="null"
                            :disablePagination="true"
                          ></TypeDataTable>
                        </td>
                      </template>
                    </TypeDataTable>
                  </td>
                </template>
              
              </TypeDataTable>
            </v-row>
            <v-row v-else class="d-flex align-content justify-center">
              <span>Nessun dato disponibile</span>
            </v-row>
          </v-col>

          <!-- DELTA -->
          <v-col 
            cols="12"
            sm="12"
            md="6" 
            lg="3"
            xl="4"
            :style="{
              'max-height': '105%',
              'max-width': '100%',
              'border-right': $vuetify.breakpoint.md || $vuetify.breakpoint.lg
                || $vuetify.breakpoint.xl ? '1px solid lightgrey' : null
            }"
          >
            <v-row class="d-flex align-content justify-center" style="margin-top: 10px; margin-bottom: 20px">
              <v-col cols="12" class="d-flex align-content justify-center">
                <div class="title-section" :style="backgroundColorDelta"> {{Number(Number(Number(revenueTotal) - Number(costTotal)).toFixed(2)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}} di Utile </div>
              </v-col>
            </v-row>
            <v-row v-if="groupedDeltaData.length > 0" class="d-flex align-content justify-center" style="margin-bottom: 5px">
              <DoughnutChart
                :chart-data="deltaChartData"
                :styles="{
                  height: '300px',
                  width: '300px'
                }"
                :options="chartOptions"
              ></DoughnutChart>
            </v-row>
            <v-row v-if="groupedRevenueData.length > 0">
              <TypeDataTable
                headersColor="rgb(212, 212, 212)"
                style="width: 100%; background-color: transparent; overflow-x: hidden;"
                :headers="deltaHeadersTable"
                :items="groupedDeltaData"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :disablePagination="true"
                font-size-row="0.76rem"
              ></TypeDataTable>
            </v-row>
            <v-row v-else class="d-flex align-content justify-center">
              <span>Nessun dato disponibile</span>
            </v-row>
          </v-col>

          <!-- COSTI -->
          <v-col
            cols="12"
            sm="12"
            md="12" 
            lg="4"
            xl="4" 
            style="max-height: 105%; max-width: 100%;"
          >
            <v-row class="d-flex align-content justify-center" style="margin-top: 10px; margin-bottom: 20px">
              <v-col cols="12" class="d-flex align-content justify-center">
                <div class="title-section" :style="backgroundColorCost">{{Number(Number(costTotal).toFixed(2)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}  di Costi</div>
              </v-col>
            </v-row>
            <v-row v-if="groupedCostData.length > 0" class="d-flex align-content justify-center" style="margin-bottom: 5px">
              <DoughnutChart
                :chart-data="costChartData"
                :styles="{
                  height: '300px',
                  width: '300px'
                }"
                :options="chartOptions"
              ></DoughnutChart>
            </v-row>
            <v-row v-if="groupedCostData.length > 0" style="margin-bottom: auto">
              <TypeDataTable
                headersColor="rgb(212, 212, 212)"
                style="width: 97%; background-color: transparent; overflow-x: hidden"
                :headers="chartHeadersTable"
                :items="groupedCostData"
                item-key="tagCategoryName"
                :show-select="false"
                :select-on-row-click="false"
                :show-actions="false"
                :disablePagination="true"
                :show-expand="true"
                overflow-y="hidden"
                font-size-row="0.76rem"
                :table-wrapper-max-height="null"
                @expandItem="openDetail('cost', $event)"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-5">
                    <div
                      class="d-flex align-content justify-center"
                      style="margin-bottom: 10px"
                    >
                      <DoughnutChart
                        :chart-data="!!item && !!item.expandedChildrenChartData ? item.expandedChildrenChartData
                          : { labels: [], datasets: [] }"
                        :styles="{
                          height: '300px',
                          width: '300px'
                        }"
                        :options="chartOptions"
                      ></DoughnutChart>
                    </div>

                    <TypeDataTable
                      headersColor="rgb(212, 212, 212)"
                      style="width: 100%; background-color: transparent; overflow-x: hidden;"
                      :headers="chartDetailsHeaderTable"
                      :items="!!item && !!item.expandedChildren ? item.expandedChildren
                        : []"
                      item-key="tagName"
                      :show-select="false"
                      :select-on-row-click="false"
                      :show-actions="false"
                      :disablePagination="true"
                      :show-expand="!!item && !!item.expandedChildren && item.expandedChildren.length > 0
                        ? item.expandedChildren.some(el => el.canClick) : false"
                      overflow-y="hidden"
                      font-size-row="0.76rem"
                      :table-wrapper-max-height="null"
                      @expandItem="openDetail('subCost', $event)"
                    >
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                          <div
                            class="d-flex align-content justify-center"
                            style="margin-bottom: 10px"
                          >
                            <DoughnutChart
                              :chart-data="!!item && !!item.expandedChildrenChartData ? item.expandedChildrenChartData
                                : { labels: [], datasets: [] }"
                              :styles="{
                                height: '300px',
                                width: '300px'
                              }"
                              :options="chartOptions"
                            ></DoughnutChart>
                          </div>

                          <TypeDataTable
                            headersColor="rgb(212, 212, 212)"
                            style="width: 100%; background-color: transparent; overflow-x: hidden;"
                            :headers="chartDetailsHeaderTable"
                            :items="!!item && !!item.expandedChildren ? item.expandedChildren
                              : []"
                            :show-select="false"
                            :select-on-row-click="false"
                            :show-actions="false"
                            overflow-y="hidden"
                            font-size-row="0.76rem"
                            :table-wrapper-max-height="null"
                            :disablePagination="true"
                          ></TypeDataTable>
                        </td>
                      </template>
                    </TypeDataTable>
                  </td>
                </template>
              </TypeDataTable>
            </v-row>
            <v-row v-else class="d-flex align-content justify-center">
              <span>Nessun dato disponibile</span>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <StandardDialog
      v-model="dialogBarcode"
      title="Area Riservata Sviluppatori" 
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput
        v-model="operatorBarcode"
        scan-your-barcode-message="Immettere Codice"
        @confirm="activeBarcode"
      >
      </ManualBarcodeInput>
    </StandardDialog>
    <StandardDialog
      title="Ripartizione"
      v-model="dialogSplitCost"
      dialog-height="max-content"
      :dialog-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? '90vw'
        : '60vw'"
      persistent
    >
      <v-row style="margin-top: 10px">
        <v-col cols="5">
          <v-list>

            <v-list-group
              v-for="(item, idx) in tagCategoriesDistributed"
              :key="idx"
              no-action
            >
              <template v-slot:activator>
                <v-tooltip allow-overflow top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content>
                      <v-list-item-title v-bind="attrs" v-on="on">
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span> {{ item.name }}</span>
                </v-tooltip>
              </template>

              <v-list-item
                v-for="(child, idx) in item.tags"
                :key="idx"
                link
                @click="handleSelectionTagDistribution(child)"
              >
                <v-tooltip allow-overflow top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: inherit" v-bind="attrs" v-on="on">
                        {{ child.description }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span> {{ child.description }}</span>
                </v-tooltip>


              </v-list-item>
            </v-list-group>

          </v-list>
        </v-col>

        <v-col cols="1" class="d-flex justify-center align-content">
          <v-divider vertical></v-divider>
        </v-col>

        <v-col v-if="selectedDistribution != undefined" cols="6">
          <div class="d-flex justify-center" style="font-size: larger; font-weight: 500">
            <span>{{selectedDistribution.description}}</span>
          </div>
          <v-row      
            v-for="(item, idx) in tagCategoriesRevenue"
            :key="idx"
            class="d-flex align-content justify"
          >
            <v-col cols="4" class="d-flex justify-center" style="align-self: center">
              <span style="font-weight: 500">{{item.name}}</span>
            </v-col>
            <v-col class="d-flex" style="padding-top: 30px; gap: 5px;">
              <v-slider
                v-model="item.distribution"
                :thumb-size="24"
                thumb-color="red"
                thumb-label
                @end="handleUpdateSlider"
              ></v-slider>
              <!-- ------------- QUI VA INSERITA LA TEXTFIELD CHE FA ------------- -->
              <input style="width: 30px; height: fit-content; margin-top: 5px;" type="text" v-model="item.distribution" @input="handleUpdateSlider">
              <!-- ------------- INSERIRE PIÙ VELOCEMENTE LE SPARTIZIONI ------------- -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-slot:header-actions>
        <v-btn class="ml-auto" icon @click="infoSplit = true">
          <v-icon>mdi-alert-circle-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="dialogSplitCost = false"
        >
          Chiudi
        </v-btn>
        <v-btn
          :disabled="checkSumSlider()"
          color="default"
          text
          @click="saveDistribution"
        >
          Salva
        </v-btn>
      </template>
    </StandardDialog>
    <StandardDialog
      title="Info Ripartizione"
      v-model="infoSplit"
      :dialog-height="null"
      :dialog-width="null"
    >
      <div class="mt-3 d-flex justify-center align-center">
        <span class="font-weight-normal">La percentuale ripartita tra le categorie deve essere uguale al 100%, altrimenti non è possibile il salvataggio</span>
      </div>
    </StandardDialog>
  </div>
</template>

<script>
import balanceService from '@/services/balance/balance.service.js'
import Hierarchy from "@/components/common/Hierarchy.vue";
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js"
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue"
import FlowyGraph from "@/components/areas/analytics/FlowyGraph.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import operatorService from '@/services/operators/operators.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import DoughnutChart from "@/components/charts/DoughnutChart";
import chartMixin from '@/components/charts/chart.mixin'
import _ from "lodash";
import html2canvas from '@/assets/js/html2canvas.min.js'
import * as XLSX from "xlsx";

export default {
  name: "FinancialByTags",
  mixins: [chartMixin],
  components: {
    RangeDateSelector,
    TitleWithBackButton,
    TagsCategoriesAutocomplete,
    FlowyGraph,
    TypeDataTable,
    StandardDialog,
    ManualBarcodeInput,
    Hierarchy,
    DoughnutChart
  },
  data: function() {
    return {
      selectedTagCategories: undefined,
      results: undefined,
      startDate: undefined,
      endDate: undefined,
      loading: false,
      active: undefined,
      open: undefined,
      search: undefined,
      appendSummary: undefined,
      generalTotal: 0,
      pinnedItem: undefined,
      isB: false,
      infoSplit: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      headersTables: [
        { text: "CATEGORIA", value: "tagCategoryName", type: 'rowColor'},
        { text: "TOTALE", value: "total", type: 'price'},
        { text: "di cui IVA", value: "tax", type: 'price'},
        { text: "%", value: "percentageOverTotal"}
      ],
      chartHeadersTable: [
        { text: "", value: "color", type: 'color'},
        { text: "CATEGORIA", value: "tagCategoryName", type: 'rowColor'},
        { text: "TOTALE", value: "total", type: 'price'},
        { text: "di cui IVA", value: "tax", type: 'price'},
        { text: "%", value: "percentageOverTotal"}
      ],
      chartDetailsHeaderTable: [
        { text: "", value: "color", type: 'color'},
        { text: "CATEGORIA", value: "tagName", type: 'rowColor'},
        { text: "TOTALE", value: "total", type: 'price'},
        { text: "INC. % TOT.", value: "percentageOverTotal"},
        { text: "INC. % REP.", value: "percentageOverCategory"}
      ],
      deltaHeadersTable: [
        { text: "CATEGORIA", value: "tagCategoryName", type: 'rowColor'},
        { text: "TOTALE", value: "total", type: 'price'},
        { text: "di cui IVA", value: "tax", type: 'price'},
      ],
      results: {},
      tags: {},
      tagsWithB: {},
      resultsNameMapping: {
        'service': 'Servizi',
        'item': 'Prodotti',
        'invoice': 'Fatture',
        'otherCash': 'Altri Ricavi',
        'customerCard': 'Carte Cliente',
        'promo': 'Promozioni'
      },
      load: false,
      selectedValue: undefined,
      showDetail: false,
      detailCostData: [],
      groupedCostData: [],
      groupedCostDataWithHidden: [],
      revenueTotal: 0,
      costTotal: 0,
      groupedRevenueData: [],
      groupedCostByCategory: [],
      groupedRevenueByCategory: [],
      groupedCostBySubCategory: [],
      groupedRevenueBySubCategory: [],
      groupedDeltaData: [],
      dialogSplitCost: false,
      tableOrSummaryIndex: 1,
      revenueDetailTitle: "",
      revenueSubDetailTitle: "",
      costDetailTitle: "",
      costSubDetailTitle: "",
      tagCategoriesRevenue: [],
      tagCategoriesDistributed: [],
      finalDistribution: [],
      selectedDistribution: undefined,
      colorSet: [
        '#264653','#E9C46A', '#2A9D8F', '#E76F51', '#F2E863',
        '#5eaee1', '#7196e2', '#877be2',
        '#61E8E1','#6A0136', '#0D2C54', '#0000FF', '#FF00FF',
        '#0D2C54','#F6511D', '#00FFFF', '#FCD3DE', '#72A1E5',
        '#8C6057','#390040', '#FFFF00', '#734B5E', '#FFCF99',
        '#542344','#5762D5', '#093A3E', '#5C8001', '#52AD9C',
        '#443850', '#BCAA99', '#BBBE64', '#8E5572', '#8EF9F3', 
        '#F87666', '#226CE0', '#465362', '#F4B266', '#832161', 
        '#BBD5ED', '#967AA1', '#192A51', '#F0B7B3', '#A833B9', 
      ],
      chartOptions: { 
        mainAspectRatio: true,
        responsive: true,
        legend: {
          display: false
        },
        showTooltips: false,
      },
      fileName: ""
    }
  },
  mounted: async function() {
    await this.$refs.dateSelector.setThisMonth()
    await this.fetchTagCategories({typeCost: false, excludeInBalance: false}).then(result => this.tagCategoriesRevenue = result)
    await this.fetchTagCategories({allowDistribution: true, excludeInBalance: false, notGeneral: true}).then(result => this.tagCategoriesDistributed = result)
    await this.loadSetting()
    await this.calculate()
  },
  methods: {
    exportToExcel() {
      // Export the tables to excel

      let workbook = XLSX.utils.book_new();
      let level1RevenueDatas = []
      for (let i = 0; i < this.groupedRevenueData.length; i++) {
        level1RevenueDatas.push(this.groupedRevenueData[i]);           
      }
      var ws = XLSX.utils.json_to_sheet(level1RevenueDatas.map((element) => {
        return {
          "Categoria": element.tagCategoryName,
          "Totale": element.total,
          "Totale con (conc.)": element.totalWithB,
          "Di cui iva": element.tax,
          "%": element.percentageOverTotal
        }  
      }));
      let level1DeltaDatas = []
      for (let i = 0; i < this.groupedDeltaData.length; i++) {
        level1DeltaDatas.push(this.groupedDeltaData[i]);           
      }
      var ws1 = XLSX.utils.json_to_sheet(level1DeltaDatas.map((element) => {
        return {
          "Categoria": element.tagCategoryName,
          "Totale": element.total,
          "Di cui iva": element.tax,
        }  
      }));
      let level1CostDatas = []
      for (let i = 0; i < this.groupedCostData.length; i++) {
        level1CostDatas.push(this.groupedCostData[i]);           
      }
      var ws2 = XLSX.utils.json_to_sheet(level1CostDatas.map((element) => {
        return {
          "Categoria": element.tagCategoryName,
          "Totale": element.total,
          "Costi (conc.)": element.ghost,
          "Di cui iva": element.tax,
          "%": element.percentageOverTotal
        }  
      }));
      let level2RevenueDatas = []
      for (let i = 0; i < this.detailRevenueData.length; i++) {
        level2RevenueDatas.push(this.detailRevenueData[i]);           
      }
      var ws3 = XLSX.utils.json_to_sheet(level2RevenueDatas.map((element) => {          
        return {
          "Tag": element.tagName,
          "Famiglia": element.subTagCategoryName,
          "Categoria": element.tagCategoryName,
          "Totale": element.total,
          "Totale con (conc.)": element.totalWithB,
          "Di cui Iva": element.tax
        }
      }));
      let level2CostDatas = []
      for (let i = 0; i < this.detailCostData.length; i++) {
        level2CostDatas.push(this.detailCostData[i]);           
      }
      var ws4 = XLSX.utils.json_to_sheet(level2CostDatas.map((element) => {
        return {
          "Tag": element.tagName,
          "Famiglia": element.subTagCategoryName,
          "Categoria": element.tagCategoryName,
          "Totale": element.total,
          "Costi (conc.)": element.ghost,
          "Iva 10%": element.tax10,
          "Iva 22%": element.tax22,
          "Altre tasse": element.taxOther
        }  
      }));
      XLSX.utils.book_append_sheet(workbook, ws, "Incassi");
      XLSX.utils.book_append_sheet(workbook, ws1, "Utile");
      XLSX.utils.book_append_sheet(workbook, ws2, "Costi");
      XLSX.utils.book_append_sheet(workbook, ws3, "Dettaglio Incassi");
      XLSX.utils.book_append_sheet(workbook, ws4, "Dettaglio Costi");
      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    openDetail(type, expandedItem) {
      if (!!expandedItem && expandedItem.value === false)
        return

      let element = expandedItem.item
            
      if(element.canClick == false)
        return
      if (type == 'cost') {
        this.groupedCostBySubCategory = []
        this.costDetailTitle = element["tagCategoryName"] + ':  ' + Number(element["total"]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        this.groupedCostByCategory = this.detailCostData.filter(el => el['tagCategoryId'] == element['tagCategoryId'])
       
        let idx = -1
        this.groupedCostByCategory = _.chain(this.groupedCostByCategory)
          .groupBy("subTagCategoryName")
          .map((value, key) => {
            idx++
            let total = value.reduce((accumulator, currentValue) => {
              if (!!element["b"])
                return accumulator + Number(currentValue["ghost"])
              else
                return accumulator + Number(currentValue["total"])
            }, 0)
            
            let idxChildren = -1
            let childrenComplete = value.map(subCat => {
              idxChildren++
              let subCatTotal = !!element["b"] ? Number(subCat["ghost"]).toFixed(2) 
                : Number(subCat["total"]).toFixed(2)

              let percentageOverTotal = (Number(this.costTotal) * 100) != 0 ? Number((Number(subCatTotal)/Number(this.costTotal) * 100).toFixed(2))
                : 0.00
              
              let percentageOverCategory = (Number(element["total"]) * 100) != 0 ? Number((Number(subCatTotal)/Number(element["total"]) * 100).toFixed(2))
                : 0.00

              return { 
                ...subCat,
                color: this.colorSet[idxChildren % this.colorSet.length],
                "total": subCatTotal,
                "percentageOverTotal": percentageOverTotal + ' %',
                "percentageOverCategory": percentageOverCategory + ' %'
              }
            })

            let percentageOverTotal = (Number(this.costTotal) * 100) != 0 ? Number((total/Number(this.costTotal) * 100).toFixed(2))
              : 0.00
            
            let percentageOverCategory = (Number(element["total"]) * 100) != 0 ? Number((total/Number(element["total"]) * 100).toFixed(2))
              : 0.00

            return { 
              color: this.colorSet[idx % this.colorSet.length],
              tagName: key,
              tagCategoryName: element["tagCategoryName"],
              total: Number(total).toFixed(2),  
              percentageOverTotal: percentageOverTotal + ' %',
              percentageOverCategory: percentageOverCategory  + ' %',
              children: childrenComplete 
            }
          })
          .value().filter((el) => { return Number(el["total"]) != 0});

          
        
          const chartData = {
            labels: !!this.groupedCostByCategory && this.groupedCostByCategory.length > 0 ? 
              this.groupedCostByCategory.filter(el => !!Number(el["total"])).map(el => el["tagName"]) : 
              [],
            datasets: !!this.groupedCostByCategory && this.groupedCostByCategory.length > 0 ? 
              [
                {
                  backgroundColor: (() => {
                    let arrayColor = []; 
                    for(let i = 0; i < this.groupedCostByCategory.length; i++) { 
                      if(!!Number(this.groupedCostByCategory[i]["total"]))
                        arrayColor.push(this.colorSet[i % this.colorSet.length])
                    } 
                    return arrayColor
                  })(),
                  data: this.groupedCostByCategory.filter(el => !!Number(el["total"])).map(el => el["total"])
                }
              ] : []
          }
          
          let indexGroupedCostData = this.groupedCostData.findIndex((el) => { return el.tagCategoryName == element.tagCategoryName })
          this.groupedCostData[indexGroupedCostData].expandedChildren = this.groupedCostByCategory
          this.groupedCostData[indexGroupedCostData].expandedChildrenChartData = chartData

      } else if (type == 'revenue') {
        this.groupedRevenueBySubCategory = []
        this.revenueDetailTitle = element["tagCategoryName"] + ':  ' + Number(element["total"]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        this.groupedRevenueByCategory = this.detailRevenueData.filter(el => el['tagCategoryId'] == element['tagCategoryId'])
        
        let idx = -1
        this.groupedRevenueByCategory = _.chain(this.groupedRevenueByCategory)
          .groupBy("type")
          .map((value, key) => {
            idx++
            let total = value.reduce((accumulator, currentValue) => {
              if (!!element["b"])
                return accumulator + Number(currentValue["totalWithB"]) - Number(currentValue["total"])
              else
                return accumulator + Number(currentValue["total"])
            }, 0)

            let idxChildren = -1
            let childrenComplete = _.chain(value)
              .groupBy("subTagCategoryName")
              .map((childValue, childKey) => {
                idxChildren++
                let internalTotal = childValue.reduce((accumulator, currentValue) => {
                  if (!!element["b"])
                    return accumulator + Number(currentValue["totalWithB"]) - Number(currentValue["total"])
                  else
                    return accumulator + Number(currentValue["total"])
              }, 0)

              let idxSubChildren = -1
              let subChildrenComplete = value.map(subCat => {
                idxSubChildren++
                let subCatTotal = !!element["b"] ? Number(Number(subCat["totalWithB"]) - Number(subCat["total"])).toFixed(2) 
                    : Number(subCat["total"]).toFixed(2)

                let percentageOverTotal = (Number(this.revenueTotal) * 100) != 0 ? Number((Number(subCatTotal)/Number(this.revenueTotal) * 100).toFixed(2))
                  : 0.00
                
                let percentageOverCategory = (Number(element["total"]) * 100) != 0 ? Number((Number(subCatTotal)/Number(element["total"]) * 100).toFixed(2))
                  : 0.00
                return { 
                  ...subCat,
                  color: this.colorSet[idxSubChildren % this.colorSet.length],
                  total: subCatTotal, 
                  "percentageOverTotal": percentageOverTotal + ' %',
                  "percentageOverCategory": percentageOverCategory + ' %'
                }
              })
              
              let percentageOverTotal = (Number(this.revenueTotal) * 100) != 0 ? Number((Number(internalTotal)/Number(this.revenueTotal) * 100).toFixed(2))
                : 0.00

              let percentageOverCategory = (Number(element["total"]) * 100) != 0 ? Number((internalTotal/Number(element["total"]) * 100).toFixed(2))
                : 0.00

              return {
                color: this.colorSet[idxChildren % this.colorSet.length],
                tagName: childKey,
                total: Number(internalTotal).toFixed(2),
                percentageOverTotal: percentageOverTotal + ' %',
                percentageOverCategory: percentageOverCategory + ' %',
                children: subChildrenComplete
              }
            }).value().filter((el) => { return Number(el["total"]) != 0 })

            let percentageOverTotal = (Number(this.revenueTotal) * 100) != 0 ? Number((total/Number(this.revenueTotal) * 100).toFixed(2))
              : 0
            
            let percentageOverCategory = (Number(element["total"]) * 100) != 0 ? Number((total/Number(element["total"]) * 100).toFixed(2))
              : 0

            return { 
              color: this.colorSet[idx % this.colorSet.length],
              tagName: this.resultsNameMapping[key],
              tagCategoryName: element["tagCategoryName"],
              total: Number(total).toFixed(2),  
              percentageOverTotal: percentageOverTotal  + ' %',
              percentageOverCategory: percentageOverCategory  + ' %',
              children: childrenComplete,
              canClick: element['tagCategoryId'] != -1
            }
          })
          .value().filter((el) => { return Number(el["total"]) != 0});


        const chartData = {
          labels: this.groupedRevenueByCategory.filter(el => !!Number(el["total"])).map(el => el["tagName"]),
          datasets: [
            {
              backgroundColor: (() => {
                let arrayColor = []; 
                for(let i = 0; i < this.groupedRevenueByCategory.length; i++) { 
                  if(!!Number(this.groupedRevenueByCategory[i]["total"]))
                    arrayColor.push(this.colorSet[i % this.colorSet.length])
                } 
                return arrayColor
              })(),
              data: this.groupedRevenueByCategory.filter(el => !!Number(el["total"])).map(el => el["total"])
            }
          ]
        }


        let indexGroupedRevenueData = this.groupedRevenueData.findIndex((el) => { return el.tagCategoryName == element.tagCategoryName })
        this.groupedRevenueData[indexGroupedRevenueData].expandedChildren = this.groupedRevenueByCategory
        this.groupedRevenueData[indexGroupedRevenueData].expandedChildrenChartData = chartData
      }
      else if (type === 'subCost') {
        this.costSubDetailTitle = element["tagName"] + ':  ' + Number(element["total"]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        this.groupedCostBySubCategory = element["children"].filter((el) => { return Number(el["total"]) != 0})

        const chartData = {
          labels: this.groupedCostBySubCategory.map(el => el["tagName"]),
          datasets: [
            {
              backgroundColor: (() => {
                let arrayColor = []; 
                for(let i = 0; i < this.groupedCostBySubCategory.length; i++) {
                   arrayColor.push(this.colorSet[i % this.colorSet.length])
                } 
                return arrayColor
              })(),
              data: this.groupedCostBySubCategory.map(el => el["total"])
            }
          ]
        }

        let findIndexCategory = this.groupedCostData.findIndex((el) => { return el.tagCategoryName == element.tagCategoryName })
        let findIndexSubCategory = this.groupedCostData[findIndexCategory].expandedChildren.findIndex((el) => { return el.tagName == element.tagName })

        this.groupedCostData[findIndexCategory].expandedChildren[findIndexSubCategory].expandedChildren = this.groupedCostBySubCategory
        this.groupedCostData[findIndexCategory].expandedChildren[findIndexSubCategory].expandedChildrenChartData = chartData
      }
      else if (type === 'subRevenue') {
        this.revenueSubDetailTitle = element["tagName"] + ':  ' + Number(element["total"]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
        this.groupedRevenueBySubCategory = element["children"][0]["children"].filter((el) => { return Number(el["total"]) != 0})

        const chartData = {
          labels: this.groupedRevenueBySubCategory.map(el => el["tagName"]),
          datasets: [
            {
              backgroundColor: (() => {
                let arrayColor = []; 
                for(let i = 0; i < this.groupedRevenueBySubCategory.length; i++) {
                   arrayColor.push(this.colorSet[i % this.colorSet.length])
                } 
                return arrayColor
              })(),
              data: this.groupedRevenueBySubCategory.map(el => el["total"])
            }
          ]
        }

        let findIndexCategory = this.groupedRevenueData.findIndex((el) => { return el.tagCategoryName == element.tagCategoryName })
        let findIndexSubCategory = this.groupedRevenueData[findIndexCategory].expandedChildren.findIndex((el) => { return el.tagName == element.tagName })

        this.groupedRevenueData[findIndexCategory].expandedChildren[findIndexSubCategory].expandedChildren = this.groupedRevenueBySubCategory
        this.groupedRevenueData[findIndexCategory].expandedChildren[findIndexSubCategory].expandedChildrenChartData = chartData
      }
    },
    async calculate() {
      const startDate = new Date(this.startDate),
      endDate = new Date(this.endDate)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      this.loading = true
      this.fileName = "Riepilogo-Finanziario_da_" + startDate.toLocaleDateString('it-IT') + "_a_" + endDate.toLocaleDateString('it-IT') + ".xls";
      balanceService.financialByTags({
        from: startDate,
        to: endDate
      }).then(async (results) => {
        this.groupedCostByCategory = []
        this.groupedRevenueByCategory = []
        this.groupedCostBySubCategory = []
        this.groupedRevenueBySubCategory = []
        let revenueCategory = []
        
        this.results = results

        let idx = -1
        let categoriesTotal = 0
        let categoriesTotalWithB = 0
        this.groupedRevenueData = _.chain(results["revenueDetail"])
          .groupBy("tagCategoryName")
          .map((value, key) => {
            idx++
            revenueCategory.push(key)

            let total = value.reduce((accumulator, currentValue) => {
              return accumulator + Number(currentValue["total"])
            }, 0)
            
            categoriesTotal += total

            let totalWithB = value.reduce((accumulator, currentValue) => {
              return accumulator + Number(currentValue["totalWithB"])
            }, 0)

            categoriesTotalWithB += totalWithB

            let tax = value.reduce((accumulator, currentValue) => {
              return accumulator + Number(currentValue["tax"])
            }, 0)

            let percentageOverTotal = Number(results["revenueGeneralTotal"][0]["total"]) != 0 ? Number((Number(total)/Number(results["revenueGeneralTotal"][0]["total"])) * 100).toFixed(2)
              : 0.00
            return {
              tagCategoryId: value[0]["tagCategoryId"],
              color: this.colorSet[idx % this.colorSet.length],
              tagCategoryName: key,
              total: Number(total).toFixed(2),  
              percentageOverTotal: percentageOverTotal + ' %',
              totalWithB: Number(totalWithB).toFixed(2),
              tax: Number(tax).toFixed(2)
            }
          })
          .value()

        let response = await tagsCategoriesService.list(1, 4000, { 'typeCost': false, excludeInBalance: false, notGeneral: true })
        let allRevenueCategory = response.rows
        for(let i = 0; i < allRevenueCategory.length; i++){
          let finded = revenueCategory.find((element) => {
            return element === allRevenueCategory[i].name
          })
          if(!finded){
            revenueCategory.push(allRevenueCategory[i].name)
            idx++
            this.groupedRevenueData.splice(this.groupedRevenueData.length, 0, {
              tagCategoryName: allRevenueCategory[i].name,
              total: 0,
              tax: 0,
              percentageOverTotal: 0.00 + ' %',
              canClick: false,
              color: this.colorSet[this.groupedRevenueData.length % this.colorSet.length],
            })
          }
        }

        let generalDetailRevenueData = []

        if (results["invoiceTotal"].length > 0 || results["otherCashTotal"].length > 0 || results["promosTotal"].length > 0) {
          if(!results["invoiceTotal"].length > 0)
            results["invoiceTotal"] = [{total: 0, tax: 0, totalWithB: 0}]
          if(!results["otherCashTotal"].length > 0)
            results["otherCashTotal"] = [{total: 0, tax: 0, totalWithB: 0}]
          if(!results["promosTotal"].length > 0)
            results["promosTotal"] = [{total: 0, tax: 0, totalWithB: 0}]

          let customerCardDifference = Number(results["revenueGeneralTotal"][0]["total"]) - categoriesTotal - Number(results["invoiceTotal"][0]["total"]) - Number(results["otherCashTotal"][0]["total"]) - Number(results["promosTotal"][0]["total"])
          let customerCardDifferenceWithB = Number(results["revenueGeneralTotal"][0]["totalWithB"]) - categoriesTotalWithB - Number(results["invoiceTotal"][0]["totalWithB"]) - Number(results["otherCashTotal"][0]["totalWithB"]) - Number(results["promosTotal"][0]["totalWithB"])
          let customerCardIva = (customerCardDifference * 22) / 122

          let percentageOverTotal = Number(results["revenueGeneralTotal"][0]["total"]) != 0 ? Number((Number(Number(customerCardDifference) + Number(results["invoiceTotal"][0]["total"]) + Number(results["otherCashTotal"][0]["total"]) + Number(results["promosTotal"][0]["total"]))/Number(results["revenueGeneralTotal"][0]["total"])) * 100).toFixed(2)
            : 0.00

          this.groupedRevenueData = this.groupedRevenueData.concat([
            {
              tagCategoryName: "Generale",
              tagCategoryId: -1,
              tax: Number(Number(results["invoiceTotal"][0]["tax"]) + Number(results["otherCashTotal"][0]["tax"]) + Number(results["promosTotal"][0]["tax"]) + customerCardIva).toFixed(2),
              total:  Number(Number(customerCardDifference) + Number(results["invoiceTotal"][0]["total"]) + Number(results["otherCashTotal"][0]["total"]) + Number(results["promosTotal"][0]["total"])).toFixed(2),
              totalWithB:  Number(Number(customerCardDifferenceWithB) + Number(results["invoiceTotal"][0]["totalWithB"]) + Number(results["otherCashTotal"][0]["totalWithB"]) + Number(results["promosTotal"][0]["totalWithB"])).toFixed(2),
              color: this.colorSet[(idx + 1) % this.colorSet.length], 
              percentageOverTotal: percentageOverTotal + ' %',
            }])

            generalDetailRevenueData.push({
              subTagCategoryName: "Generale",
              tagCategoryId: -1,
              tagCategoryName: "Generale",
              tagId:-1,
              tagName:"Fatture",
              tax: Number(results["invoiceTotal"][0]["tax"]),
              total: Number(results["invoiceTotal"][0]["total"]),
              totalWithB: Number(results["invoiceTotal"][0]["totalWithB"]),
              type: 'invoice',
            })
            generalDetailRevenueData.push({
              subTagCategoryName: "Generale",
              tagCategoryId: -1,
              tagCategoryName: "Generale",
              tagId:-1,
              tagName:"Altri Incassi",
              tax: Number(results["otherCashTotal"][0]["tax"]),
              total: Number(results["otherCashTotal"][0]["total"]),
              totalWithB: Number(results["otherCashTotal"][0]["totalWithB"]),
              type: 'otherCash',
            })
            generalDetailRevenueData.push({
              subTagCategoryName: "Generale",
              tagCategoryId: -1,
              tagCategoryName: "Generale",
              tagId:-1,
              tagName:"Promozioni",
              tax: Number(results["promosTotal"][0]["tax"]),
              total: Number(results["promosTotal"][0]["total"]),
              totalWithB: Number(results["promosTotal"][0]["totalWithB"]),
              type: 'promo',
            })
            generalDetailRevenueData.push({
              subTagCategoryName: "Generale",
              tagCategoryId: -1,
              tagCategoryName: "Generale",
              tagId:-1,
              tagName:"Carte Cliente",
              tax: Number(customerCardIva),
              total: Number(customerCardDifference),
              totalWithB: Number(customerCardDifferenceWithB),
              type: 'customerCard',
            })
        } else {
          let customerCardDifference = Number(results["revenueGeneralTotal"][0]["total"]) - categoriesTotal
          let customerCardDifferenceWithB = Number(results["revenueGeneralTotal"][0]["totalWithB"]) - categoriesTotalWithB
          let customerCardIva = (customerCardDifference * 22) / 122
          
          let percentageOverTotal = Number(results["revenueGeneralTotal"][0]["total"]) != 0 ? Number((Number(customerCardDifference)/Number(results["revenueGeneralTotal"][0]["total"])) * 100).toFixed(2)
            : 0.00

          this.groupedRevenueData.push({
            tagCategoryName: "Generale",
            tagCategoryId: -1,
            color: this.colorSet[(idx + 1) % this.colorSet.length],
            total: Number(customerCardDifference).toFixed(2),
            tax: Number(customerCardIva).toFixed(2),
            totalWithB: Number(customerCardDifferenceWithB).toFixed(2),
            percentageOverTotal: percentageOverTotal + ' %',
          })

          generalDetailRevenueData.push({
            subTagCategoryName: "Generale",
            tagCategoryId: -1,
            tagCategoryName: "Generale",
            tagId:-1,
            tagName:"Carte Cliente",
            tax: Number(customerCardIva),
            total: Number(customerCardDifference),
            totalWithB: Number(customerCardDifferenceWithB),
            type: 'customerCard',
          })
        }

        this.groupedCostData = results["costsTotal"]
        for(let i = 0; i < revenueCategory.length; i++){
          let finded = results["costsTotal"].find((element) => {
            return element["tagCategoryName"] === revenueCategory[i]
          })

          if(!finded){
            this.groupedCostData.splice(i, 0, {
              tagCategoryName: revenueCategory[i],
              color: this.colorSet[(i + 1) % this.colorSet.length],
              total: 0,
              tax: 0,
              canClick: false
            })
          }
        }
        
        this.groupedCostDataWithHidden = this.groupedCostData

        this.revenueTotal = results["revenueGeneralTotal"][0]["total"]
        this.costTotal = results["costGeneralTotal"][0]["total"]
        this.detailCostData = results["costsDetails"]
        this.detailRevenueData = results["revenueDetail"]

        this.detailRevenueData = [...this.detailRevenueData, ...generalDetailRevenueData]
        
        if (this.finalDistribution.length > 0) {
          const groupedCostDataGeneralIndex = this.groupedCostData.findIndex((gcd) => gcd.tagCategoryName === 'Generale')
          let costDataToAdd = []

          const costCategory = [...revenueCategory, 'Generale']
          for(let i = 0; i < this.finalDistribution.length; i += 1) {
            let finalDistributionRow = this.finalDistribution[i]
            
            for(let k = 0; k < this.detailCostData.length; k += 1) {
              if(this.detailCostData[k].tagName !== finalDistributionRow.key) continue
              let totalPercentageAdded = 0
              let ghostPercentageAdded = 0
              let taxPercentageAdded = 0

              for(let x = 0; x < costCategory.length; x += 1) {
                let category = costCategory[x]
                let totalToRidistribute = this.detailCostData[k].total
                let ghostToRidistribute = !!this.detailCostData[k].ghost ? this.detailCostData[k].ghost : 0
                let taxToRidistribute = Number((Number(this.detailCostData[k].tax10) + Number(this.detailCostData[k].tax22) + Number(this.detailCostData[k].taxOther)).toFixed(2))
                let percentageToCalculate = finalDistributionRow[costCategory[x]]

                const groupedCostDataIndex = this.groupedCostData.findIndex((gcd) => gcd.tagCategoryName === category)
                let currentTagCategoryId
                if(this.groupedCostData.some((gcd) => gcd.tagCategoryId !== undefined && gcd.tagCategoryName === category)) {
                  currentTagCategoryId = this.groupedCostData.find((gcd) => gcd.tagCategoryId !== undefined && gcd.tagCategoryName === category).tagCategoryId
                } else {
                  const maxTagCategoryIdFound = this.groupedCostData.reduce((p, c, i, a) => {
                    if(c.tagCategoryId !== undefined && c.tagCategoryId > p) {
                      return c.tagCategoryId
                    } else {
                      return p
                    }
                  }, 0)

                  currentTagCategoryId = maxTagCategoryIdFound + 1
                }
                 
                this.groupedCostData[groupedCostDataIndex].canClick = true
                if(this.groupedCostData[groupedCostDataIndex].tagCategoryId === undefined)
                  this.groupedCostData[groupedCostDataIndex].tagCategoryId = currentTagCategoryId

                let currentGhostPercentage = Number((Number((Number(percentageToCalculate) * Number(ghostToRidistribute)).toFixed(2)) / 100).toFixed(2))
                if (!!this.groupedCostData[groupedCostDataIndex].ghost)
                  this.groupedCostData[groupedCostDataIndex].ghost = Number(this.groupedCostData[groupedCostDataIndex].ghost) + currentGhostPercentage
                else {
                  this.groupedCostData[groupedCostDataIndex].ghost = currentGhostPercentage
                }
                ghostPercentageAdded += currentGhostPercentage

                let currentTotalPercentage = Number((Number((Number(percentageToCalculate) * Number(totalToRidistribute)).toFixed(2)) / 100).toFixed(2))
                this.groupedCostData[groupedCostDataIndex].total = Number(this.groupedCostData[groupedCostDataIndex].total) + currentTotalPercentage
                totalPercentageAdded += currentTotalPercentage

                let currentTaxPercentage = Number((Number((Number(percentageToCalculate) * Number(taxToRidistribute)).toFixed(2)) / 100).toFixed(2))
                this.groupedCostData[groupedCostDataIndex].tax = Number(this.groupedCostData[groupedCostDataIndex].tax) + currentTaxPercentage
                taxPercentageAdded += currentTaxPercentage

                costDataToAdd.push({
                  ghost: 0,
                  subTagCategoryName: this.detailCostData[k].subTagCategoryName,
                  tagCategoryId: currentTagCategoryId,
                  tagCategoryName: category,
                  tagId: this.detailCostData[k].tagId,
                  tagName: this.detailCostData[k].tagName,
                  total: currentTotalPercentage
                })
              }

              this.detailCostData[k].total = Number((Number(this.detailCostData[k].total) - totalPercentageAdded).toFixed(2))
              if (!!this.detailCostData[k].ghost) {
                this.detailCostData[k].ghost = Number((Number(this.detailCostData[k].ghost) - ghostPercentageAdded).toFixed(2))
              }
              
              if (!!this.groupedCostData[groupedCostDataGeneralIndex].ghost){
                this.groupedCostData[groupedCostDataGeneralIndex].ghost = Number(this.groupedCostData[groupedCostDataGeneralIndex].ghost) - ghostPercentageAdded
              }
              this.groupedCostData[groupedCostDataGeneralIndex].total = Number(this.groupedCostData[groupedCostDataGeneralIndex].total) - totalPercentageAdded
              this.groupedCostData[groupedCostDataGeneralIndex].tax = Number(this.groupedCostData[groupedCostDataGeneralIndex].tax) - taxPercentageAdded
            }
          }

          for(let j = 0; j < costDataToAdd.length; j += 1) {
            this.detailCostData.push(costDataToAdd[j])
          }
        }

        this.groupedCostData.forEach((el, index) => {
          let percentageOverTotalCost = Number(results["costGeneralTotal"][0]["total"]) != 0 ? Number((Number(el["total"])/Number(results["costGeneralTotal"][0]["total"])) * 100).toFixed(2)
            : 0.00
          el['color'] = this.colorSet[index % this.colorSet.length]
          el['percentageOverTotal'] = percentageOverTotalCost + ' %'
        })

        if (this.groupedCostData.find((el) => el.tagCategoryName === 'Generale') === undefined) {
          this.groupedCostData.push({
            tagCategoryName: 'Generale',
            color: this.colorSet[this.groupedCostData.length % this.colorSet.length],
            total: 0,
            percentageOverTotal: 0.00 + ' %',
            tax: 0,
            canClick: true
          })
        }

        if (this.isB) {
          this.calculateBlack()
        }
        this.calculateDeltaTable()

        this.loading = false
      })
    },
    calculateDeltaTable() {
      this.groupedDeltaData = []
      if (this.groupedRevenueData.length >= this.groupedCostData.length) {
        this.groupedRevenueData.forEach(el => {
          let costTotal = !!this.groupedCostData.filter(costEl => !!costEl["tagCategoryName"] && !!el["tagCategoryName"] && costEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0] 
            ? this.groupedCostData.filter(costEl => !!costEl["tagCategoryName"] && !!el["tagCategoryName"] && costEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0]["total"] 
            : 0
          let costTax = !!this.groupedCostData.filter(costEl => !!costEl["tagCategoryName"] && !!el["tagCategoryName"] && costEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0] 
            ? this.groupedCostData.filter(costEl => !!costEl["tagCategoryName"] && !!el["tagCategoryName"] && costEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0]["tax"] 
            : 0
            
          this.groupedDeltaData.push({
            tagCategoryName: el["tagCategoryName"],
            total: Number(Number(el["total"]) - Number(costTotal)).toFixed(2),
            tax: Number(el["tax"] != undefined ? Math.abs(Number(el["tax"]) - Number(costTax)) : 0).toFixed(2)
          })
        })
      } else {
        this.groupedCostData.forEach(el => {
          let revenueTotal = !!this.groupedRevenueData.filter(revEl => revEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0] 
            ? this.groupedRevenueData.filter(revEl => revEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0]["total"] 
            : 0
          let revenueTax = !!this.groupedRevenueData.filter(revEl => revEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0] 
            ? this.groupedRevenueData.filter(revEl => revEl["tagCategoryName"].toLowerCase() == el["tagCategoryName"].toLowerCase())[0]["tax"] 
            : 0

          this.groupedDeltaData.push({
            tagCategoryName: el["tagCategoryName"],
            total: Number(Number(revenueTotal) - Number(el["total"])).toFixed(2),
            tax: Number(revenueTax != undefined ? Math.abs(Number(revenueTax) - Number(el["tax"])) : 0).toFixed(2)
          })
        })
      }
    },
    calculateBlack() {
      this.groupedRevenueData = this.groupedRevenueData.flatMap((el, index) => {
        let idx = index * 2

        let percentageOverTotal = !!Number(this.results["revenueGeneralTotal"][0]["totalWithB"]) ? Number(Number(el["total"])/Number(this.results["revenueGeneralTotal"][0]["totalWithB"]) * 100).toFixed(2)
          : 0.00
        
        let percentageOverTotalBlack = !!Number(this.results["revenueGeneralTotal"][0]["totalWithB"]) ? Number((Number(el["totalWithB"]) - Number(el["total"]))/Number(this.results["revenueGeneralTotal"][0]["totalWithB"]) * 100).toFixed(2)
          : 0.00

        let total = Number(Number(el["totalWithB"]) - Number(el["total"]))

        percentageOverTotal = Number(percentageOverTotal) || 0.00
        percentageOverTotalBlack = Number(percentageOverTotalBlack) || 0.00
        total = Number(total) || 0.00

        return [{
            ...el,
            color: this.colorSet[idx % this.colorSet.length],
            percentageOverTotal: percentageOverTotal + ' %',
            }, {
            ...el,
            color: this.colorSet[(idx + 1) % this.colorSet.length],
            tagCategoryName: el["tagCategoryName"] + ' (conc.)', 
            total: total.toFixed(2), 
            percentageOverTotal: percentageOverTotalBlack + ' %',
            b: true, 
            tax: undefined,
            rowColor: "red"
          }]
        }
      )
      this.groupedCostData = this.groupedCostDataWithHidden.flatMap((el, index) => {
        let idx = index * 2

        let costGhost = el["ghost"] != undefined ? Number(el["ghost"]).toFixed(2) : 0.00

        let percentageOverTotal = !!Number(this.results["costGeneralTotal"][0]["totalWithB"]) ? Number(Number(el["total"])/Number(this.results["costGeneralTotal"][0]["totalWithB"]) * 100).toFixed(2)
          : 0.00
        
        let percentageOverTotalBlack = !!Number(this.results["costGeneralTotal"][0]["totalWithB"]) ? Number(costGhost/Number(this.results["costGeneralTotal"][0]["totalWithB"]) * 100).toFixed(2)
          : 0.00

        return [{
            ...el,
            color: this.colorSet[idx % this.colorSet.length],
            percentageOverTotal: percentageOverTotal + ' %',
            }, {
            ...el,
            color: this.colorSet[(idx + 1) % this.colorSet.length],
            tagCategoryName: el["tagCategoryName"] + ' (conc.)', 
            total: costGhost, 
            percentageOverTotal: percentageOverTotalBlack  + ' %',
            b: true, 
            tax: undefined,
            rowColor: "red"
          }]
        }
      )

      this.revenueTotal = !!this.results["revenueGeneralTotal"][0]["totalWithB"] ? this.results["revenueGeneralTotal"][0]["totalWithB"] : 0
      this.costTotal = !!this.results["costGeneralTotal"][0]["totalWithB"] ? this.results["costGeneralTotal"][0]["totalWithB"] : 0
    },
    changeWithB() {
      if (!!this.isB) {
        this.groupedRevenueData = this.groupedRevenueData.filter(el => !el["b"]).map((el, index) => ({...el, color: this.colorSet[index % this.colorSet.length]}))
        this.groupedCostData = this.groupedCostData.filter(el => !el["b"]).map((el, index) => ({...el, color: this.colorSet[index % this.colorSet.length]}))
        this.revenueTotal = !!this.results["revenueGeneralTotal"][0]["total"] ? this.results["revenueGeneralTotal"][0]["total"] : 0
        this.costTotal = !!this.results["costGeneralTotal"][0]["total"] ? this.results["costGeneralTotal"][0]["total"] : 0

      }
      else {
        this.calculateBlack()
      } 
      this.isB = !this.isB
      this.calculateDeltaTable()
      this.groupedCostByCategory = []
      this.groupedRevenueByCategory = []
      this.groupedCostBySubCategory = []
      this.groupedRevenueBySubCategory = []
    },
    activeBarcode(){
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.changeWithB()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    fetchTagCategories(params){
      return new Promise((resolve,reject) => {
        tagsCategoriesService.list(undefined, 2000, {...params, tags: true}).then((results) => {
          resolve(results.rows)
        }).catch((err) => {
            reject(err)
        })
      })
    },
    checkSumSlider() {
      let value = this.tagCategoriesRevenue.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue["distribution"]), 0)
      return value != 100
    },
    handleUpdateSlider() {
      let obj = {}
      this.tagCategoriesRevenue.map(el => { 
        obj[el.name] = el.distribution != undefined ? el.distribution : 0
      })
      if (this.finalDistribution.filter(el => el.key === this.selectedDistribution.description).length > 0) {
        let comparedObj =  this.finalDistribution.filter(el => el.key === this.selectedDistribution.description)
        this.finalDistribution[this.finalDistribution.indexOf(comparedObj[0])] = {
          key: this.selectedDistribution.description,
          ...obj
        }
      } 
      else {
        this.finalDistribution.push({
          key: this.selectedDistribution.description,
          ...obj
        })
      }
    },
    handleSplitCost() {
      this.dialogSplitCost = !this.dialogSplitCost
    },
    handleSelectionTagDistribution(item) {
      if (item != this.selectedDistribution) {
        this.selectedDistribution = item
        
        if (this.finalDistribution.filter(el => el.key === item.description).length > 0) {
          let comparedObject = this.finalDistribution.filter(el => el.key === item.description)[0]

          this.tagCategoriesRevenue = this.tagCategoriesRevenue.map(t => { 
            return {
              ...t,
              distribution: comparedObject[t.name]
            }
          })
        }
        else {
          this.tagCategoriesRevenue = this.tagCategoriesRevenue.map(el => { 
            let distribution = 0
            if (el["tags"].length == 0) {
              distribution = 100
            }
            return {
              ...el,
              distribution: distribution
            }
          })
        }        
      }
    },
    saveDistribution() {
      this.$settings.set('financialDistribution', this.finalDistribution)
      this.dialogSplitCost = false
    },
    loadSetting() {
      this.$settings.get("financialDistribution").then((result) => {
        if (result.value) this.finalDistribution = result.value;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    print() {
      let id = 'content-to-print'
      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Riepilogo_Finanziario_" + new Date(this.startDate).toLocaleDateString('it-IT') + '-' + new Date(this.endDate).toLocaleDateString('it-IT') + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    },
    checkObjectLength() {
      return Object.keys(this.results).length == 0
    }
  },
  computed: {
    deltaChartData() {
      let arrayDelta = [
        {name: 'Incassi', value: this.revenueTotal},
        {name: 'Costi', value: this.costTotal},
        {name: 'Utile', value: Number(Number(this.revenueTotal) - Number(this.costTotal)).toFixed(2) },
      ]
      return {
        labels: arrayDelta.map(el => el["name"]),
        datasets: [
          {
            backgroundColor: ['#4caf508f', '#e91e6391', '#50bfe198',],
            data: arrayDelta.map(el => el["value"])
          }
        ]
      }
    },
    revenueChartData() {
      return {
        labels: this.groupedRevenueData.filter(el => !!Number(el["total"])).map(el => el["tagCategoryName"]),
        datasets: [
          {
            backgroundColor: (() => {
              let arrayColor = []; 
              for(let i = 0; i < this.groupedRevenueData.length; i++) { 
                if(!!Number(this.groupedRevenueData[i]["total"]))
                  arrayColor.push(this.colorSet[i % this.colorSet.length])
              } 
              return arrayColor
            })(),
            data: this.groupedRevenueData.filter(el => !!Number(el["total"])).map(el => el["total"])
          }
        ]
      }
    },
    costChartData() {
      return {
        labels: this.groupedCostData.filter(el => !!Number(el["total"])).map(el => el["tagCategoryName"]),
        datasets: [
          {
            backgroundColor: (() => {
              let arrayColor = []; 
              for(let i = 0; i < this.groupedCostData.length; i++) { 
                if(!!Number(this.groupedCostData[i]["total"]))
                  arrayColor.push(this.colorSet[i % this.colorSet.length])
              } 
              return arrayColor
            })(),
            data: this.groupedCostData.filter(el => !!Number(el["total"])).map(el => el["total"])
          }
        ]
      }
    },
    backgroundColorDelta() {
      return {
        '--background-color': '#50bfe198',
      }
    },
    backgroundColorRevenue() {
      return {
        '--background-color': '#4caf508f',
      }
    },
    backgroundColorCost() {
      return {
        '--background-color': '#e91e6391',
      }
    }
  },
}
</script>

<style scoped>

.table-gradient {
  background-image: linear-gradient(#50bfe198, #8b77e29c);
  box-shadow: 0px 3px 8px 1px;
}

.title-section {
    display: flex;
    height: 2em;
    padding-right: 1em;
    padding-left: 1em;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background-color: var(--background-color);
    font-size: 20px;
    font-weight: 500;
    width: "fit-content";
    text-align: center;
    text-transform: none;
    color: white;
}

/* tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
} */

@media (max-width:1490px) {
  .row-table {
    max-width: 33%; margin: auto; flex-wrap: nowrap
  }

}

</style>