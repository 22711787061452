<template>
  <div>
    <label v-if="label" :for="id">{{label}}</label>
    <input 
      v-model="localValue" 
      class="time-input" 
      type="time" 
      :id="id" 
      :name="id" 
      :min="min" 
      :max="max"
    />
  </div>
</template>

<script>
export default {
  name: "TimePicker",
  data: function() {
    return {
      localValue: this.value
    }
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    bus: {
      type: Object,
      default: undefined
    },
    id: {
      type: String,
      default: "time-picker"
    },
    label: {
      type: String,
      default: "Ora"
    },
    min: {
      type: Number,
      default: 8,
      validator: function(value) {
        return value >= 0 && value < 24
      }
    },
    max: {
      type: Number,
      default: 22,
      validator: function(value) {
        return value >= 0 && value < 24
      }
    }
  },
  methods: {
    emit(event, ...params) {
      this.$emit(event, ...params)
      if (this.bus) {
        this.bus.$emit(event, ...params)
      }
    }
  },
  watch: {
    localValue(newVal) {
      this.emit('input', newVal)
    },
    value(newVal) {
      if(this.localValue != newVal) {
        this.localValue = newVal
      }
    }
  }
}
</script>

<style scoped>
  .time-input {
    margin-left: 10px
  }

  .time-input:focus {
    outline: none;
  }
</style>