export default {
  viewBar: {
    active: false,
  },
  viewBooking: {
    active: false,
  },
  viewWheelOfFortune: {
    active: false,
  },

  viewFinancialByTags: {
    active: false,
  },
  viewBalanceByTags: {
    active: false,
  },
  viewOperatorsBalance: {
    active: false,
  },
  viewCustomersByFidelization: {
    active: false,
  },
  viewCustomersTurnover: {
    active: false,
  },
  viewCustomersByAgeGroup: {
    active: false,
  },
  viewTopSellingItems: {
    active: false,
  },
  viewTimeRanges: {
    active: false,
  },
  viewHistoryOperations: {
    active: false,
  },
  viewServiceConsume: {
    active: false,
  },
  viewWarehouseStatus: {
    active: false,
  },
  viewBistrotBalance: {
    active: false,
  },
  viewBalanceByYear: {
    active: false,
  },
  viewServiceTypesBalance: {
    active: false,
  },
  viewGoalsBalance: {
    active: false,
  },

  viewMarketingCalendar: {
    active: false,
  },
}
