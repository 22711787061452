import axios from "@/services/axios";
import UrlKeeper from "@/services/UrlKeeper.js";
import settingService from "@/services/settings/settings.service.js";

import _linkAudioPath1 from "@/assets/audio/link/link_0.mp3";
import _linkAudioPath2 from "@/assets/audio/link/link_1.mp3";
import _linkAudioPath3 from "@/assets/audio/link/link_2.mp3";
import _linkAudioPath4 from "@/assets/audio/link/link_3.mp3";

const linksAudioPath = [_linkAudioPath1, _linkAudioPath2, _linkAudioPath3, _linkAudioPath4];
class chatService {
  ask(entity, action, keyWords) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/chat/ask", {
          entity: entity,
          action: action,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  generator(text) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/chat/generator", {
          text: text,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  _defaultOpenCommands() {
    return [
      "Ehi Lulu",
      "Ciao Lulu",
      "Buongiorno Lulu",
      "Buon giorno Lulu",
      "Buonasera Lulu",
      "Buona sera Lulu",
    ];
  }

  _defaultCloseCommands() {
    return [
      "Chiudi Lulu",
      "Chiudi",
      "Esci Lulu",
      "Esci",
      "Esci da Lulu",
      "Esci da",
    ]}


  _defaultAnswer() {
    return [
      "Non conosco questo comando scusa.",
      "Sei sicuro di aver fatto la domanda corretta? Non ho capito.",
      "Hai sbagliato a scrivere forse?",
      "Non ho trovato dei risultati !! Sto ancora imparando",
    ];
  }

  async _defaultQuestion() {
    let zohoLink = "https://forms.zohopublic.eu/gildasrl/form/Test/formperma/3QNkgj8rbWzuPrVcIvGmFplFAYqPFJn-MtUdPgFYAbs?"
    
    let nameForTicket = await settingService.get('nameForTicket')
    let lastNameForTicket = await settingService.get('lastNameForTicket')
    let salonName = await settingService.get('salonName')
    let companyBusinessName = await settingService.get('companyBusinessName')
    let companyEmail = await settingService.get('companyEmail')
    let companyPhone = await settingService.get('companyPhone')
    
    zohoLink += "Nome=" + nameForTicket.value + "&Cognome=" + lastNameForTicket.value + 
      "&NomeSalone=" + salonName.value + "&RagioneSociale=" + companyBusinessName.value + "&eMail=" + companyEmail.value + 
      "&Telefono=" + companyPhone.value
    
    return {
      title: "Ecco alcuni esempi di quello che posso fare",
      questions: [
        // {
        //   text: "Quanti clienti hai in archivio?",
        //   entity: "customers",
        //   action: "list",
        //   query: true,
        // },
        // {
        //   text: "Quanti cliente hai in tutto oggi ?",
        //   entity: "customers",
        //   action: "list",
        //   query: true,
        // },
        {
          text: "Dove posso consultare i video dedicati all'agenda?",
          answer: "Ecco il link per i video manuali:",
          links: [
            {
              label: "Clicca qui",
              url: "http://vimeo.com/showcase/agenda-luluu",
            },
          ],
          query: false,
        },
        {
          text: "Dove posso consultare i video dedicati all'anagrafica?",
          answer: "Ecco il link per i video manuali:",
          links: [
            {
              label: "Clicca qui",
              url: "http://vimeo.com/showcase/anagrafica-luluu",
            },
          ],
          query: false,
        },
        {
          text: "Dove posso consultare i video dedicati all'homepage?",
          answer: "Ecco il link per i video manuali:",
          links: [
            {
              label: "Clicca qui",
              url: "http://vimeo.com/showcase/home-luluu",
            },
          ],
          query: false,
        },
        {
          text: "Dove posso consultare i video dedicati al marketing?",
          answer: "Ecco il link per i video manuali:",
          links: [
            {
              label: "Clicca qui",
              url: "http://vimeo.com/showcase/marketing-luluu",
            },
          ],
          query: false,
        },
        {
          text: "Dove posso consultare i video dedicati ai pagamenti?",
          answer: "Ecco il link per i video manuali:",
          links: [
            {
              label: "Clicca qui",
              url: "http://vimeo.com/showcase/pagamenti-luluu",
            },
          ],
          query: false,
        },
        {
          text: "Dove posso consultare i video dedicati alle presenze?",
          answer: "Ecco il link per i video manuali:",
          links: [
            {
              label: "Clicca qui",
              url: "http://vimeo.com/showcase/presenze-luluu",
            },
          ],
          query: false,
        },
        {
          text: "Dove posso consultare i video dedicati alle timbrature?",
          answer: "Ecco il link per i video manuali:",
          links: [
            {
              label: "Clicca qui",
              url: "http://vimeo.com/showcase/timbrature-luluu",
            },
          ],
          query: false,
        },
        {
          text: "Come posso contattare l'assistenza al gestionale?",
          answer: "Ecco il link per contattare l'assistenza:",
          links: [
            {
              label: "Clicca qui",
              url: zohoLink,
            },
          ],
          query: false,
        },
        // {
        //   text: "Come posso accedere ai manuali utente?",
        //   answer: "Arriveranno presto",
        //   query: false,
        // },
        {
          text: "Come posso generare una fattura?",
          answer:
            "Per generare una fattura recati nella sezione dei pagamenti, qui troverai le Fatture Emesse da Incassare e le Fatture Ricevute da Pagare.",
          query: false,
          audio: linksAudioPath[Math.floor(Math.random() * 3 + 1)],
        },
        {
          text: "Come faccio a fare uno storno?",
          answer:
            "Per generare uno storno recati nella sezione dei pagamenti, le Transazioni di Cassa Incassati è la lista di tutte le transazioni incassate: al suo interno potrai creare un nuovo storno associandolo ad una di queste transazioni.",
          query: false,
          audio: linksAudioPath[Math.floor(Math.random() * 3 + 1)],
        },
        {
          text: "Come posso gestire le diverse aliquote iva?",
          answer:
            "Per gestire le diverse aliquote iva recati nella sezione dei pagamenti, sotto nella parte dei setting trovi Aliquote IVA dove potrai gestire le tue aliquote.",
          query: false,
        },
        {
          text:
            "Come posso attivare una tessera ricaricabile/sconti per il cliente?  ",
          answer:
            "Per attivare una tessera ricaricabile/sconti puoi farlo sia dalla sezione Anagrafiche Clienti sia in Homepage. In homepage fai entrare in salone il cliente al quale associare la tessera, nella sua scheda in basso clicca sull'icona a forma di tessera.",
          query: false,
        },
        {
          text: "Come posso far effettuare le timbrature?",
          answer:
            "Per effettuare una timbratura recati in Homepage e scannerizza il barcode di un operatore, verrà automaticamente effettuata la timbratura.",
          query: false,
        },
        {
          text: "Come posso far firmare i moduli di consenso alla Privacy?",
          answer:
            "Per trovare il modulo della Privacy recati nella sezione Anagrafiche Clienti, all'interno di una anagrafica ci sarà il tasto per farlo firmare e il modulo",
          query: false,
        },
        {
          text: "Come posso fare l'aggiornamento all'ultima versione?",
          answer:
            "Ogni volta che i nostri sviluppatori rilasceranno un aggiornamento comparirà un messaggio in basso a destra con scritto 'Aggiorna'",
          query: false,
        },
        {
          text:
            "Come posso fare un estrazione di dati in excel dei miei prodotti?",
          answer:
            "Per fare un'estrazione dei tuoi prodotti recati nella sezione Magazzino, nella lista dei Prodotti in alto a destra troverai l'icona di excel che farà automaticamente l'export nei tuoi download.",
          query: false,
        },
      ],
    };
  }
}
export default new chatService();
