var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center",class:{'flex-wrap': !!this.$vuetify.breakpoint.xs || !!this.$vuetify.breakpoint.sm},style:({
    'max-width': _vm.maxWidth,
    'padding': '5px'
  })},[_c('div',[_c('DateTimePicker',{attrs:{"filled":"","dense":"","hide-details":"auto","label":"Inizio","show-time":false,"loading":_vm.loading,"disabled":_vm.disabled},on:{"input":($event) => {
        _vm.$emit('update:start-date', $event)
        _vm.$emit('change-date')
      }},model:{value:(_vm.localStartDate),callback:function ($$v) {_vm.localStartDate=$$v},expression:"localStartDate"}})],1),_c('div',{staticClass:"ml-3"},[_c('DateTimePicker',{attrs:{"filled":"","dense":"","hide-details":"auto","label":"Fine","show-time":false,"loading":_vm.loading,"disabled":_vm.disabled},on:{"input":($event) => {
        _vm.$emit('update:end-date', $event)
        _vm.$emit('change-date')
      }},model:{value:(_vm.localEndDate),callback:function ($$v) {_vm.localEndDate=$$v},expression:"localEndDate"}})],1),(!!_vm.showChips && 
        (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs))?_c('div',{staticClass:"d-flex align-center justify-content ml-3",style:({
        'max-width': this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.md ? '80%' : '100%',
        'margin-top': this.$vuetify.breakpoint.xs || !!this.$vuetify.breakpoint.sm ? '10px' : '0px'
      })},[_c('v-select',{attrs:{"label":"Seleziona Periodo","items":_vm.chipItems,"item-text":"text","item-value":"value","filled":"","rounded":"","dense":"","disabled":_vm.disabled,"hide-details":"auto"},on:{"input":() => {
          _vm.onClickSelect(_vm.selectedPeriod)
          _vm.$emit('change-date')
        }},model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}})],1):_vm._e(),(!!_vm.showChips && (this.$vuetify.breakpoint.xl))?_c('div',{staticClass:"d-flex align-center",style:({'max-width': this.$vuetify.breakpoint.xl ? '60%' : '50%'})},_vm._l((_vm.chipItems),function(item){return _c('v-chip',{key:item['value'],staticClass:"ml-2",staticStyle:{"font-size":"12px"},attrs:{"disabled":_vm.disabled,"color":"primary"},on:{"click":() => {
          _vm.onClickSelect(item['value'])
          _vm.$emit('change-date')
        }}},[_vm._v(" "+_vm._s(item['text'])+" ")])}),1):_vm._e(),_vm._t("buttonConfirm")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }