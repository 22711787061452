var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-text-field',{attrs:{"filled":"","dense":"","hide-details":"auto","label":"Nome","rules":[_vm.max255CharRule, _vm.presenceRule]},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{staticClass:"mt-5"},[_vm._v("Contenuto del messaggio")]),_c('div',{ref:"editableMessage",staticClass:"editable-message mt-3",attrs:{"contenteditable":"true","contentdisabled":_vm.disabled},on:{"input":_vm.calculateTextFromHtml}}),_c('div',{ref:"hiddenEditableMessage",staticClass:"hidden-editable-message"}),_c('div',[_c('div',{style:({
      'color': _vm.countCharStringColor
      })},[_vm._v(" "+_vm._s(_vm.countCharString)+" ")])]),_c('div',{staticClass:"mt-2"},_vm._l((_vm.possiblePlaceholders),function(placeholder){return _c('v-chip',{key:placeholder.name,staticClass:"ma-1",attrs:{"color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.addPlaceholder(placeholder.name, placeholder.text)}}},[_vm._v(_vm._s(placeholder.text))])}),1),(!!_vm.value && Object.keys(_vm.value).length > 0 && !!_vm.images && _vm.images.length > 0)?_c('div',{staticClass:"images-list"},_vm._l((_vm.images),function(image){return _c('div',{key:image.id,staticClass:"image-item",class:{'selected': _vm.selectedImage && image.id === _vm.selectedImage.id
        && _vm.selectedImage.url === image.url
      },attrs:{"contentdisabled":_vm.disabled},on:{"click":function($event){return _vm.setSelectedImage(image)}}},[_c('img',{attrs:{"src":image.url,"alt":image.name}})])}),0):_vm._e(),(!!_vm.value && Object.keys(_vm.value).length > 0 && 
      !!_vm.images && _vm.images.length > 0 && !!_vm.selectedImage)?_c('a',{attrs:{"href":"#","contentdisabled":_vm.disabled},on:{"click":function($event){return _vm.setSelectedImage(undefined)}}},[_vm._v(" Deseleziona immagine ")]):_vm._e(),(!_vm.hideUploadImage)?_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"mt-2 mb-2",attrs:{"text":"","outlined":"","x-small":_vm.$vuetify.breakpoint.xs,"small":(_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.md),"large":(_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl),"disabled":_vm.disabled},on:{"click":_vm.uploadImageFromLocal}},[_vm._v(" Carica immagine da pc ")])],1):_vm._e(),_c('SimplePopUp',{attrs:{"width":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '80vw' :
        _vm.$vuetify.breakpoint.xl ? '26vw' : 
        _vm.$vuetify.breakpoint.lg ? '30vw' : '35vw',"alert-text":"Il file non può superare i 50MB"},model:{value:(_vm.alertImageSize),callback:function ($$v) {_vm.alertImageSize=$$v},expression:"alertImageSize"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }