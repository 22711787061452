var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-low-gap"},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.goBack}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.handleCategoryClick()}}},[_vm._v(" Tutte le categorie ")]),_vm._l((_vm.dataCategories),function(category,idx){return _c('v-btn',{key:idx,attrs:{"text":""},on:{"click":function($event){return _vm.handleCategoryClick(category)}}},[_vm._v(" "+_vm._s(category.attributes.Nome)+" ")])})],2),_c('v-col',{staticClass:"mobile-full",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Cerca Notizie","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1),_c('v-col',[_c('v-row',{staticClass:"news-row-container"},_vm._l((_vm.dataNews),function(news,idx){return _c('v-col',{key:idx,staticClass:"news-container",attrs:{"cols":"3"}},[_c('v-card',{staticClass:"news-card d-flex flex-column justify-center mt-3",attrs:{"flat":""},on:{"click":function($event){return _vm.openNews(news)}}},[_c('v-row',[_c('v-img',{attrs:{"height":'20vh',"src":!!news.attributes.Immagine.data ? 
                (
                  !!news.attributes.Immagine.data.attributes.formats.small ?
                  _vm.url + news.attributes.Immagine.data.attributes.formats.small.url
                  : _vm.url + news.attributes.Immagine.data.attributes.formats.thumbnail.url
                ) 
                : undefined}})],1),_c('v-row',{staticClass:"px-4 my-0 pb-0"},[_c('v-col',{staticClass:"d-flex justify-start align-top"},[(!!news.attributes.Categorie.data)?_c('v-chip',{staticClass:"cyan--text font-weight-medium",attrs:{"color":"rgba(80, 191, 225, 0.25)"}},[_vm._v(" "+_vm._s(news.attributes.Categorie.data.attributes.Nome)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-end align-top"},[_vm._v(" "+_vm._s((new Date(news.attributes.publishedAt)).toLocaleDateString('it-IT', { year: 'numeric', month: 'long', day: 'numeric' }))+" ")])],1),_c('v-row',{staticClass:"px-7 my-0"},[_c('div',{staticClass:"text-h6 cyan--text text--darken-4"},[_vm._v(" "+_vm._s(news.attributes.Titolo)+" ")])]),_c('v-row',{staticClass:"px-7 pb-4"},[_c('div',{staticClass:"text-justify blue-grey--text",staticStyle:{"overflow":"hidden","height":"4.3em"}},[_vm._v(" "+_vm._s(news.attributes.Descrizione)+" ")])])],1)],1)}),1)],1),(_vm.newsDialog)?_c('StandardDialog',{attrs:{"dialog-height":null,"dialog-max-width":"1600px","title":_vm.openedNews.attributes.Titolo},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":() => { _vm.newsDialog = false }}},[_vm._v(" Chiudi ")])]},proxy:true}],null,false,3917736334),model:{value:(_vm.newsDialog),callback:function ($$v) {_vm.newsDialog=$$v},expression:"newsDialog"}},[_c('NewsView',{attrs:{"opened-news":_vm.openedNews}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }