const settingsRoutes = [
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
      };
    },
  },
  {
    path: "/settings/registry",
    name: "SettingsRegistry",
    component: () => import("@/components/setting/store/StoreData.vue"),
  },
  {
    path: "/settings/turns",
    name: "SettingsTurns",
    component: () => import("@/components/setting/store/StoreHours.vue"),
  },
  {
    path: "/settings/aspect",
    name: "SettingsAspect",
    component: () => import("@/components/setting/Theme.vue"),
  },
  {
    path: "https://forms.clickup.com/f/hffyw-4428/1M1PSFG5AOV8DUQCUG",
    name: "SettingsAssistence",
  },
  {
    path: "/settings/permissions",
    name: "SettingsPermissions",
    component: () => import("@/components/setting/Groups.vue"),
  },
  {
    path: "/settings/controlPanel",
    name: "SettingsControlPanel",
    component: () => import("@/components/setting/store/ControlPanel.vue"),
  },
  {
    path: "/settings/developer",
    name: "SettingsDeveloper",
    component: () => import("@/components/setting/DevelopersSettings.vue"),
  },
];

export default settingsRoutes;
