<template>
  <v-avatar
    :color="localValue.color ? localValue.color : color"
    :size="size"
  >
    <v-img 
      :src="avatarLocal"
      v-if="!!localValue.avatar"
    ></v-img>
    <div 
      v-else
      :style="{
        'color': localValue.textColor ? localValue.textColor : 'black'
      }"
    >
      {{initials}}
    </div>
  </v-avatar>
</template>

<script>
import imageLoader from '@/services/images.js'

export default {
  name: "UserAvatar",
  data: function() {
    return {
      localValue: {...this.value},
      avatarLocal: undefined
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        firstname: "",
        lastname: "",
      })
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: Number,
      default: undefined
    }
  },
  mounted: function() {
    this.loadAvatarWithToken()
  },
  methods: {
    loadAvatarWithToken() {
      if(this.localValue.avatar) {
        imageLoader.getImageUrl(this.localValue.avatar).then((newUrl) => {
          this.localValue.avatarLocal = newUrl
          this.avatarLocal = newUrl
        })
      }
    }
  },
  computed: {
    initials() {
      let init = ''
      if(!!this.value) {
        if(!!this.value.firstname && !!this.value.firstname[0]) init += this.value.firstname[0]
        if(!!this.value.lastname && !!this.value.lastname[0]) init += this.value.lastname[0]
      }
      return init
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
      this.loadAvatarWithToken()
    }
  }
}
</script>

<style>

</style>