<template>
  <v-bottom-sheet
    v-model="localOpened"
    :dark="dark"
    width="100vw"
  >
    <template v-slot:activator="{attrs, on}">
      <slot name="activator" v-bind="{attrs, on}">
        <div class="d-flex justify-center align-center flex-column">
          <v-icon 
            size="60"
            class="my-2"
            v-on="on"
            v-bind="attrs"
          >mdi-barcode-scan</v-icon>
          <div class="text-center text-h6">
            {{scanYourBarcodeMessage}}
          </div>
        </div>
      </slot>
    </template>
    <v-sheet
      class="d-flex align-center justify-center flex-column"
      max-height="50vh"
      height="200px"
    >
      <BarcodeDrawer
        v-model="localValue"
        filled
        style="max-width: 90vw; width: 400px"
        dense
        variant="onlyText"
        :bus="bus"
        :type="type"
      ></BarcodeDrawer>
      <div class="d-flex justify-center align-center mt-5">
        <v-btn 
          text
          color="error"
          @click="closeBottomSheet"
        >
          <v-icon left>mdi-close</v-icon>
          Chiudi
        </v-btn>
        <v-btn 
          text
          color="default"
          @click="confirmBarcode"
        >
          <v-icon left>mdi-check</v-icon>
          Conferma
        </v-btn>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import Vue from 'vue'
import BarcodeDrawer from '@/components/common/BarcodeDrawer.vue';

export default {
  name: "ManualBarcodeInput",
  components: {
    BarcodeDrawer
  },
  data: function() {
    return {
      localValue: undefined,
      localOpened: this.opened,
      bus: new Vue()
    }
  },
  props: {
    value: {},
    opened: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: true
    },
    scanYourBarcodeMessage: {
      type: String,
      default: "Scannerizza il tuo badge ..."
    },
    closeOnConfirm: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "password"
    }
  },
  mounted: function() {
    if(this.localOpened) {
      this.$nextTick(() => {
        this.bus.$emit('focus')
      })
    }
  },
  methods: {
    closeBottomSheet() {
      this.localOpened = false
      this.$emit('update:opened', false)
      this.localValue = ""
    },
    confirmBarcode() {
      this.$emit('input', this.localValue)
      this.$emit('confirm', this.localValue)
      if(this.closeOnConfirm) {
        this.closeBottomSheet()
        this.localValue = ""
      }
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    },
    opened(newVal) {
      this.localOpened = newVal
    },
    localOpened(newVal) {
      if(newVal) {
        this.$nextTick(() => {
          this.bus.$emit('focus')
        })
      }
    }
  }
}
</script>

<style>

</style>