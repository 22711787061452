var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"auto"}},[_c('TitleWithBackButton',{attrs:{"title":"Confronta Periodo Commerciale","back-route":"Analytics"}}),_c('div',{staticClass:"pa-3 d-flex justify-center align-center"},[_c('v-select',{staticStyle:{"max-width":"250px"},attrs:{"label":"Tipo comparazione","items":_vm.comparationKindOptions,"filled":"","dense":"","hide-details":"auto","menu-props":{
        offsetY: true
      }},on:{"input":_vm.loadData},model:{value:(_vm.comparationKind),callback:function ($$v) {_vm.comparationKind=$$v},expression:"comparationKind"}}),_c('v-select',{staticClass:"ml-2",staticStyle:{"max-width":"350px"},attrs:{"label":"Valori di comparazione","items":_vm.comparationValueOptions,"small-chips":"","deletable-chips":"","filled":"","dense":"","multiple":"","hide-details":"auto","menu-props":{
        offsetY: true
      }},on:{"input":_vm.loadData},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item["text"]))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.comparationValue.length - 1)+" altri) ")]):_vm._e()]}}]),model:{value:(_vm.comparationValue),callback:function ($$v) {_vm.comparationValue=$$v},expression:"comparationValue"}}),(!!_vm.comparationKind)?_c('v-autocomplete',{staticClass:"ml-2",staticStyle:{"max-width":"250px"},attrs:{"label":"Anni da comparare","items":_vm.comparationYearsOptions,"small-chips":"","deletable-chips":"","multiple":"","filled":"","dense":"","hide-details":"auto","menu-props":{
        offsetY: true
      }},on:{"input":_vm.loadData},model:{value:(_vm.comparationYears),callback:function ($$v) {_vm.comparationYears=$$v},expression:"comparationYears"}}):_vm._e(),(_vm.comparationKind == 'month')?_c('v-autocomplete',{staticClass:"ml-2",staticStyle:{"max-width":"250px"},attrs:{"label":"Mese da comparare","items":_vm.comparationMonthOptions,"small-chips":"","deletable-chips":"","filled":"","dense":"","hide-details":"auto","menu-props":{
        offsetY: true
      }},on:{"input":_vm.loadData},model:{value:(_vm.comparationMonth),callback:function ($$v) {_vm.comparationMonth=$$v},expression:"comparationMonth"}}):_vm._e(),_c('v-btn-toggle',{staticClass:"ml-2",attrs:{"mandatory":"","color":"default"},model:{value:(_vm.chartOrTableIndex),callback:function ($$v) {_vm.chartOrTableIndex=$$v},expression:"chartOrTableIndex"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-table")])],1),_c('v-btn',[_c('v-icon',[_vm._v("mdi-chart-areaspline")])],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","disabled":_vm.tableRows.length == 0},on:{"click":_vm.printRows}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1),_c('v-window',{model:{value:(_vm.chartOrTableIndex),callback:function ($$v) {_vm.chartOrTableIndex=$$v},expression:"chartOrTableIndex"}},[_c('v-window-item',[_c('div',{staticClass:"d-flex justify-center mt-10"},[(_vm.comparationKind == 'year')?_c('v-data-table',{staticStyle:{"width":"90vw"},attrs:{"height":"60vh","headers":_vm.tableHeaders,"items":_vm.tableRows,"disable-pagination":"","hide-default-footer":"","fixed-header":"","loading":_vm.loading,"group-by":"month"},scopedSlots:_vm._u([{key:"group.header",fn:function({ groupBy, group }){return [_c('td',{attrs:{"colspan":_vm.tableHeaders.length}},[(groupBy == 'month')?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getMonthName(group)))]):_vm._e()])]}},_vm._l((_vm.tableHeaders.filter(header => header.colorize).map(header => ({ ...header, slotName: 'item.' + header.value}))),function(header,index){return {key:header.slotName,fn:function({ item }){return [_c('div',{key:index,style:({
                'color': item.delta ? Number(item[header.value]) > 0 ? 'green' : 'red' : undefined,
                'font-weight': item.delta ? '800' : undefined
              })},[_vm._v(" "+_vm._s(item[header.value])+" ")])]}}})],null,true)}):_c('div',[_vm._v(" Tabella al momento non disponibile ")])],1)]),_c('v-window-item',_vm._l((_vm.comparationValue),function(cv){return _c('div',{key:cv['value'],staticClass:"d-flex justify-center mt-10"},[_c('DateBasedChart',{attrs:{"getYValue":(object) => {
            return object[`Balance.${cv}`]
          },"getXDate":_vm.getXDate,"height":"60vh","width":"90vw","data":_vm.data[cv],"label-modifier":_vm.labelModifier,"tooltip-label-modifier":(tooltipLabel) => tooltipLabel.yLabel,"loading":_vm.loading,"filters":_vm.chartFilters,"granularity":_vm.granularity}})],1)}),0)],1),_c('div')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }