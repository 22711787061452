<template>
  <div 
    :style="{
      height: tableHeight
    }"
    ref="dataTableWrapper"
  >
    <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 150px">
      <AdvancedFilter
        style="width:99%; height: 100%"
        v-model="filtersValue"
        :filters="filterTypes"
        :advance-filters="advanceFilters"
        :external-filter="externalFilter"
        @apply-filters="applyFilters"
      >
        <template v-slot:chip-custom-name="{filter}">
          Nome: {{ filter.value }}
        </template>

        <template v-slot:chip-custom-tags="{filter}">
          Tag Abbinato: {{ filter.value.map(el => el.description).join(', ') }}
        </template>

        <template v-slot:chip-custom-serviceType="{filter}">
          Tipo Famiglia: {{ filter.value.name }}
        </template>

        <template v-slot:chip-custom-color="{filter}">
          <div
            class="d-flex justify-center align-center"
            style="gap: 5px"
          >
            <span>Colore: </span>
            <v-avatar
              :color="!!filter.value ? filter.value.hex : 'transparent'"
              class="d-inline-block"
              size="20"
            ></v-avatar>
          </div>
        </template>

        <template v-slot:custom-tags="{filter}">
          <v-row>
            <v-col class="pa-4">
              <TagsAutocomplete
                v-model="filter.value"
                :multiple="false"
                dense
                :filled="false"
                return-object
                :filters="{ typeCost: false }"
              ></TagsAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-serviceType="{filter}">
          <v-row>
            <v-col class="pa-4">
              <ServiceTypeAutocomplete
                v-model="filter.value"
                :multiple="false"
                dense
                :filled="false"
                return-object
              >
            </ServiceTypeAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-disabled="{filter}">
          <v-row>
            <v-col class="pa-4">
              <v-checkbox
                v-model="filter.value"
                :label="filter.label"
              ></v-checkbox>
            </v-col>
          </v-row>
        </template>
        <template v-slot:chip-custom-disabled="{filter}">
          {{ !!filter.value ? 'Disabilitati' : 'Non Disabilitati' }}
        </template>

        <template v-slot:custom-tagCategory="{filter}">
          <v-row class="pa-6">
            <v-col>
              <v-autocomplete
                v-model="filter.value"
                itemText="name"
                itemValue="id"
                :chips="true"
                :deletableChips="true"
                :smallChips="true"
                label="Categoria Tag"
                :items="tagsCategoriesItems"
                dense
                filled
                hide-details="auto"
              ></v-autocomplete> 
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-color="{filter}">
          <v-row>
            <v-col 
              class="d-flex justify-center"
              cols="12"
            >
              <v-color-picker
                :width="!!$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '300px' : '500px'"
                v-model="filter.value"
                label="Colore"
                show-swatches
              ></v-color-picker>
            </v-col>
          </v-row>
        </template>
      </AdvancedFilter>
    </div>
    <div>
      <TypeDataTable
        v-model="localSelection"
        loading-text="Caricamento Servizi ..."
        :headers="headers"
        :loading="loading"
        :items="filteredServices"
        :translator="translator"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
        :height="dataTableHeight"
        :deletable="false"
        @input="handleInput"
        @edit="handleEdit"
        :show-select="selectable"
        :show-actions="showActions"
        :single-select="false"
        @dblclick:row="handleEditDoubleClick"
      >
        <template v-slot:custom-disabled="{ item }">
          <v-checkbox
            readonly
            :input-value="item.disabled"
            hide-details="auto"
            dense
          ></v-checkbox>
        </template>
      </TypeDataTable>
    </div>
  </div>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import TagsAutocomplete from '@/components/common/TagsAutocomplete.vue'
import servicesService from '@/services/services/services.service.js'
import ServiceTypeCategoriesAutocomplete from '@/components/common/ServiceTypeCategoriesAutocomplete.vue'
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js"
import dateUtils from "@/mixins/common/dateUtils"
import Vue from 'vue'
import ServiceTypeAutocomplete from '@/components/common/ServiceTypeAutocomplete.vue';

export default {
  name: "ServicesTable",
  components: {
    FullScreenDialog,
    TypeDataTable,
    AdvancedFilter,
    TagsAutocomplete,
    ServiceTypeCategoriesAutocomplete,
    ServiceTypeAutocomplete
  },
  mixins: [dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      localSelection: undefined,
      dataTableHeightValue: 400,
      services: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      tagsCategoriesItems: [],
      filterTypes: [
        { type: 'custom', operator: 'custom', field: 'tagCategory', name: 'Reparto', label: 'Reparto', color: "", value: undefined, icon: 'mdi-tag-multiple' },
        { type: 'custom', operator: 'custom', field: 'tags', name: 'Tag Abbinato', label: 'Tag Abbinato', color: "", value: undefined, icon: 'mdi-tag' },
        { type: 'custom', operator: 'custom', field: 'serviceType', name: 'Tipo Famiglia', label: 'Tipo Famiglia', color: "", value: undefined, icon: 'mdi-tag' },
        { type: 'custom', operator: 'custom', field: 'color', name: 'Colore', color: "", value: undefined, icon: 'mdi-format-color-fill'},
        { type: 'cash', operator: 'equal', field: 'price', name: 'Prezzo', label: 'Prezzo', color: "", value: undefined },
      ],
      advanceFilters: [
        { type: 'custom', operator: 'custom', field: 'disabled', name: 'Disabilitati', label: 'Disabilitati', color: "", value: false, icon: 'mdi-circle-off-outline' },
      ],
      externalFilter: { type: 'custom', operator: 'like', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
    }
  },
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    },
    tableHeight: {
      type: String,
      default: undefined
    },
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    showActions: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selection: {}
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchTagCategories()
    this.fetchServices(this.filtersValue)

    setTimeout(() => {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 200
  
      this.resizeListener = function() {
        this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 200
      }
      this.resizeListener = this.resizeListener.bind(this)
  
      window.addEventListener('resize', this.resizeListener)
    }, 200);

    this.bus.$on('reload', () => this.fetchServices(this.filtersValue))
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchServices(filters) {
      this.loadingItems = true
      servicesService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.services = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      servicesService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    fetchTagCategories(){
      return new Promise((resolve,reject) => {
        tagsCategoriesService.list(undefined, 2000, { typeCost: false }).then((results) => {
          this.tagsCategoriesItems = results.rows
          resolve()
        }).catch((err) => {
            reject(err)
        })
      })
    },
    handleEdit(service) {
      this.$emit('service-detail', service)
    },
    handleEditDoubleClick(row, {item: service}) {
      this.$emit('service-double-click', service)
    },
    handleInput(services) {
      this.$emit('update:selection', services)
    },
    translator(fieldName, fieldValue) {
      if(fieldName == 'serviceType') {
        return fieldValue['name']
      }
    },
    applyFilters(filters) {
      this.$emit('update:filters', this.filtersValue)
      this.fetchServices(filters)
    }
  },
  computed: {
    filteredServices() {
      return this.services
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchServices(this.filtersValue)
    },
    rowPerPage() {
      this.fetchServices(this.filtersValue)
    },
    filters(){
      this.filtersValue = this.filters
      this.fetchServices(this.filtersValue)
    }
  }
}
</script>