import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import absencesCache from "./absences.cache";
import socketService from '../../socket/socket.service'
import EventEmitter from "events";


class AbsenceService extends EventEmitter {
  constructor() {
    super()
    this._alreadyLoaded = false

    socketService.on('absences:update', async () => {
      await this.deleteCache()
      this.emit('absences-cached-changes')
    })

    socketService.on('absences:create', async () => {
      await this.deleteCache()
      this.emit('absences-cached-changes')
    })

    socketService.on('absences:delete', async () => {
      await this.deleteCache()
      this.emit('absences-cached-changes')
    })
  }

  async deleteCache() {
    await absencesCache.deleteAllCache()
    this.alreadyLoaded = false
  }

  async cachedList({ start, end }) {
    if (!this.alreadyLoaded) {
      await absencesCache.deleteAllCache()
      this.alreadyLoaded = true
    }

    let differenceInTime = new Date(end).getTime() - new Date(start).getTime()
    let differenceInDays = differenceInTime / (1000 * 3600 * 24)

    let results = []
    for (let i = 0; i <= differenceInDays; i += 1) {
      const d = new Date(new Date(start).getTime() + (i * (1000 * 3600 * 24)))
      const month = d.getMonth() + 1
      const date = d.getDate()
      const timestamp = d.getFullYear().toString() + (month < 9 ? '0' + month : month).toString() + (date < 9 ? '0' + date : date).toString()

      if (await absencesCache.cachePresent({ day: timestamp })) {
        results = results.concat(await absencesCache.getDay({
          day: timestamp
        }))
      } else {
        const startFilter = new Date(d)
        startFilter.setHours(0, 0, 0, 0)
        const endFilter = new Date(d)
        endFilter.setHours(23, 59, 59, 999)

        await this.reloadDayCache({ date: d })
        results = results.concat(await absencesCache.getDay({
          day: timestamp
        }))
      }
    }

    return results
  }

  async reloadDayCache({ date }) {
    if (!date) throw new Error('date should be provided')
    const startFilter = new Date(date)
    startFilter.setHours(0, 0, 0, 0)
    const endFilter = new Date(date)
    endFilter.setHours(23, 59, 59, 999)

    const absences = await this.list(1, 1000, {
      start: startFilter,
      end: endFilter,
      inclusive: true
    })

    const month = new Date(date).getMonth() + 1
    const day = new Date(date).getDate()
    const timestamp = new Date(date).getFullYear().toString() + (month < 9 ? '0' + month : month).toString() + (day < 9 ? '0' + day : day).toString()

    await absencesCache.cacheDay({
      day: timestamp,
      absences: absences
    })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/absences/list', { page, rowPerPage, filters }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  export(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.postWithDownload(apiUrl + '/presences/absences/export', { page, rowPerPage, filters }, 'Report Assenze.pdf').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!params) reject(new Error('no params specified'))
      else {
        axios.post(apiUrl + '/presences/absences/create', params).then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  createWithRule(params, rule) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!params) reject(new Error('no params specified'))
      else if (!rule || !rule.type) reject(new Error('no rule specified'))
      else {
        params.rule = rule
        axios.post(apiUrl + '/presences/absences/createWithRule', params).then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  createDaily(params, rule) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      rule.type = 'daily'

      if (!params) reject(new Error('no params specified'))
      else {
        params.rule = rule

        axios.post(apiUrl + '/presences/absences/createWithRule', params).then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  update(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/absences/update', params).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(absence) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!absence || !absence.id) {
        reject(new Error("no absence specified"))
        return
      }

      axios.get(apiUrl + '/presences/absences/delete', { id: absence.id }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default new AbsenceService()