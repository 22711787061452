var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('table',{staticClass:"bordered",style:({
      'width': _vm.tableContentWidth,
      ..._vm.cssProps
    })},[_c('thead',{staticStyle:{"position":"sticky","top":"0px"}},[_vm._l((_vm.headers),function(header){return [_c('th',{key:header[_vm.headersKey],staticClass:"px-3 text-center headerColorTable",style:({
            'min-width': header[_vm.headersWidth],
          })},[_vm._v(" "+_vm._s(header[_vm.headersText])+" ")])]})],2),_c('tbody',_vm._l((_vm.contentPaginated),function(row){return _c('tr',{key:row[_vm.contentRowsKey] + 'row',on:{"click":function($event){return _vm.$emit('click-on-row', row)}}},_vm._l((_vm.headers),function(header){return _c('v-lazy',{key:header[_vm.headersKey] + row[header[_vm.headersKey]],staticClass:"text-center py-2 px-1 hoverable-table-cell",class:_vm.classContent,style:({
            'min-width': header[_vm.headersWidth],
            'border-bottom': '1px solid rgb(177 177 177)',
          }),attrs:{"min-height":"50","tag":"td"},on:{"click":function($event){return _vm.$emit('click-on-cell', {row: row, headersKey: header[_vm.headersKey]})}}},[_c('div',{staticClass:"d-flex justify-center align-content"},[(header['type'] == 'color')?_c('v-avatar',{key:header['value'],attrs:{"color":row[header['value']],"size":20}}):_c('span',[_vm._v(_vm._s(row[header[_vm.headersKey]]))]),_vm._t("extra-content",null,{"table":{header: header, row: row}})],2)])}),1)}),0)]),_c('v-divider'),(_vm.showPagination)?_c('div',{staticClass:"d-flex justify-end align-center my-1"},[_c('div',[_vm._v("Righe per pagina")]),_c('v-select',{staticClass:"mx-2",staticStyle:{"max-width":"60px"},attrs:{"filled":"","dense":"","hide-details":"auto","items":_vm.rowPerPageOptions,"append-icon":null,"menu-props":{ offsetY: true }},model:{value:(_vm.rowPerPage),callback:function ($$v) {_vm.rowPerPage=$$v},expression:"rowPerPage"}}),_c('div',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.page)+" di "+_vm._s(_vm.totalPages))]),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","disabled":_vm.minPage},on:{"click":_vm.decrementPage}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","disabled":_vm.maxPage},on:{"click":_vm.incrementPage}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }