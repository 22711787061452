import authService from "@/services/auth/auth.service";
const movementsRoutes = [
  {
    path: "/warehouse/movement-items/list",
    name: "MovementItemList",
    component: () =>
      import("@/components/areas/warehouse/movements/MovementItemList.vue"),
    props: (route) => {
      return {
        filters: route.params.filters,
      };
    }
  },
  {
    path: "/warehouse/movements/upload-list",
    name: "MovementUploadList",
    component: () =>
      import(
        "@/components/areas/warehouse/movementUploads/uploader/MovementUploadList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters,
      };
    }
  },
  {
    path: "/warehouse/movements/unload-list",
    name: "MovementUnloadList",
    component: () =>
      import(
        "@/components/areas/warehouse/movementUnloads/MovementUnloadList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters,
      };
    }
  },
  {
    path: "/warehouse/movements/adjustment-home",
    name: "MovementAdjustmentsHome",
    component: () =>
      import(
        "@/components/areas/warehouse/movementAdjustments/MovementAdjustmentsHome.vue"
      ),
  },
  {
    path: "/warehouse/movements/order/:orderId",
    name: "MovementOrderDetail",
    component: () =>
      import(
        "@/components/areas/warehouse/movements/detail/MovementDetail.vue"
      ),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tab,
        filters: route.params.filters,
      };
    },
  },
  {
    path: "/warehouse/movements/scanner/:movementId",
    name: "MovementScannerDetail",
    component: () =>
      import(
        "@/components/areas/warehouse/movements/detail/MovementDetail.vue"
      ),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tab,
        filters: route.params.filters,
      };
    },
  },
  {
    path: "/warehouse/movements/import/:importId",
    name: "MovementImportDetail",
    component: () =>
      import(
        "@/components/areas/warehouse/movements/detail/MovementDetail.vue"
      ),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tab,
        filters: route.params.filters,
      };
    },
  },
];

export default movementsRoutes;
