<template>
  <div class="mobile-low-gap">
    <div>
      <v-row>
        <v-col cols="8"> 
          <v-btn
            icon
            @click="goBack"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>    
          <v-btn text @click="handleCategoryClick()">
            Tutte le categorie
          </v-btn>  
          <v-btn text v-for="(category, idx) in dataCategories" :key="idx" @click="handleCategoryClick(category)">
            {{category.attributes.Nome}}
          </v-btn>
        </v-col>
        <v-col class="mobile-full" cols="4">
          <v-text-field
            dense
            outlined
            v-model="searchText" 
            label="Cerca Notizie" 
            @keyup.enter="search"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-col>
      <v-row class="news-row-container">
        <v-col class="news-container" cols="3" v-for="(news, idx) in dataNews" :key="idx">
          <v-card 
            class="news-card d-flex flex-column justify-center mt-3" 
            flat
            @click="openNews(news)"
          >
            <v-row>
              <v-img
                :height="'20vh'"
                :src="!!news.attributes.Immagine.data ? 
                  (
                    !!news.attributes.Immagine.data.attributes.formats.small ?
                    url + news.attributes.Immagine.data.attributes.formats.small.url
                    : url + news.attributes.Immagine.data.attributes.formats.thumbnail.url
                  ) 
                  : undefined"
              ></v-img>
            </v-row>

            <v-row class="px-4 my-0 pb-0">
              <v-col class="d-flex justify-start align-top">
                <v-chip color="rgba(80, 191, 225, 0.25)" class="cyan--text font-weight-medium" v-if="!!news.attributes.Categorie.data">
                  {{news.attributes.Categorie.data.attributes.Nome}}
                </v-chip>
              </v-col>
              <v-col class="d-flex justify-end align-top">
                {{ (new Date(news.attributes.publishedAt)).toLocaleDateString('it-IT', { year: 'numeric', month: 'long', day: 'numeric' }) }}
              </v-col>
            </v-row>

            <v-row class="px-7 my-0">
              <div class="text-h6 cyan--text text--darken-4">
                {{ news.attributes.Titolo }}
              </div>
            </v-row>
            
            <v-row class="px-7 pb-4" >
              <div class="text-justify blue-grey--text" style="overflow: hidden; height: 4.3em;">
                {{news.attributes.Descrizione}}
              </div>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Dialog -->

    <StandardDialog
      v-if="newsDialog"
      v-model="newsDialog"
      :dialog-height="null"
      dialog-max-width="1600px"
      :title="openedNews.attributes.Titolo"
    >    
      <NewsView
        :opened-news="openedNews"
      >

      </NewsView>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="() => { newsDialog = false }"
        >
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import newsService from "@/services/marketing/news.service.js";
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import NewsView from '@/components/areas/marketing/News.vue'
import UrlKeeper from '@/services/UrlKeeper.js'

export default {
  name: "NewsList",
  components: {
    FullScreenDialog,
    StandardDialog,
    NewsView
  },
  data: function() {
    let props = this.$router.resolve({ name: 'Marketing' });
    let url = UrlKeeper.getUrl('strapi')

    return {
      url: url,
      dialog: true,
      routeFather: props.href,
      dataNews: [
        {
          id: 1,
          attributes: {
            Notizia: [
              {
                Sottotitolo: "Porcaaaasjdnajfnaj",
                Testo: "Porcaaaaasnciksbnidjkbcnaikbncaavofyilbcaoyildbhcolibhaasjdnajfnaj",
                Carosello: {
                  data: [
                    {
                      attributes: {
                        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fit.wikipedia.org%2Fwiki%2FPippo&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAH",
                      }
                    }
                  ]
                }
              }
            ],
            Titolo: "Titolo 1",
            Link: "https://likablehair.it/",
            Immagine: {
              data: {
                attributes: {
                  formats: {
                    small: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    },
                    thumbnail: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    }
                  }
                }
              }
            },
            Categorie: {
              data: {
                attributes: {
                  Nome: "Categoria 1"
                }
              }
            },
            publishedAt: "2024-01-30T00:00:00.000Z"
          }
        },
        {
          id: 1,
          attributes: {
            Notizia: [
              {
                Sottotitolo: "Porcaaaasjdnajfnaj",
                Testo: "Porcaaaaasnciksbnidjkbcnaikbncaavofyilbcaoyildbhcolibhaasjdnajfnaj",
                Carosello: {
                  data: [
                    {
                      attributes: {
                        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fit.wikipedia.org%2Fwiki%2FPippo&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAH",
                      }
                    }
                  ]
                }
              }
            ],
            Titolo: "Titolo 1",
            Link: "https://likablehair.it/",
            Immagine: {
              data: {
                attributes: {
                  formats: {
                    small: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    },
                    thumbnail: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    }
                  }
                }
              }
            },
            Categorie: {
              data: {
                attributes: {
                  Nome: "Categoria 1"
                }
              }
            },
            publishedAt: "2024-01-30T00:00:00.000Z"
          }
        },
        {
          id: 1,
          attributes: {
            Notizia: [
              {
                Sottotitolo: "Porcaaaasjdnajfnaj",
                Testo: "Porcaaaaasnciksbnidjkbcnaikbncaavofyilbcaoyildbhcolibhaasjdnajfnaj",
                Carosello: {
                  data: [
                    {
                      attributes: {
                        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fit.wikipedia.org%2Fwiki%2FPippo&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAH",
                      }
                    }
                  ]
                }
              }
            ],
            Titolo: "Titolo 1",
            Link: "https://likablehair.it/",
            Immagine: {
              data: {
                attributes: {
                  formats: {
                    small: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    },
                    thumbnail: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    }
                  }
                }
              }
            },
            Categorie: {
              data: {
                attributes: {
                  Nome: "Categoria 1"
                }
              }
            },
            publishedAt: "2024-01-30T00:00:00.000Z"
          }
        },
        {
          id: 1,
          attributes: {
            Notizia: [
              {
                Sottotitolo: "Porcaaaasjdnajfnaj",
                Testo: "Porcaaaaasnciksbnidjkbcnaikbncaavofyilbcaoyildbhcolibhaasjdnajfnaj",
                Carosello: {
                  data: [
                    {
                      attributes: {
                        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fit.wikipedia.org%2Fwiki%2FPippo&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAH",
                      }
                    }
                  ]
                }
              }
            ],
            Titolo: "Titolo 1",
            Link: "https://likablehair.it/",
            Immagine: {
              data: {
                attributes: {
                  formats: {
                    small: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    },
                    thumbnail: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    }
                  }
                }
              }
            },
            Categorie: {
              data: {
                attributes: {
                  Nome: "Categoria 1"
                }
              }
            },
            publishedAt: "2024-01-30T00:00:00.000Z"
          }
        },
        {
          id: 1,
          attributes: {
            Notizia: [
              {
                Sottotitolo: "Porcaaaasjdnajfnaj",
                Testo: "Porcaaaaasnciksbnidjkbcnaikbncaavofyilbcaoyildbhcolibhaasjdnajfnaj",
                Carosello: {
                  data: [
                    {
                      attributes: {
                        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fit.wikipedia.org%2Fwiki%2FPippo&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAH",
                      }
                    }
                  ]
                }
              }
            ],
            Titolo: "Titolo 1",
            Link: "https://likablehair.it/",
            Immagine: {
              data: {
                attributes: {
                  formats: {
                    small: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    },
                    thumbnail: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    }
                  }
                }
              }
            },
            Categorie: {
              data: {
                attributes: {
                  Nome: "Categoria 1"
                }
              }
            },
            publishedAt: "2024-01-30T00:00:00.000Z"
          }
        },
        {
          id: 1,
          attributes: {
            Notizia: [
              {
                Sottotitolo: "Porcaaaasjdnajfnaj",
                Testo: "Porcaaaaasnciksbnidjkbcnaikbncaavofyilbcaoyildbhcolibhaasjdnajfnaj",
                Carosello: {
                  data: [
                    {
                      attributes: {
                        url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fit.wikipedia.org%2Fwiki%2FPippo&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAH",
                      }
                    }
                  ]
                }
              }
            ],
            Titolo: "Titolo 1",
            Link: "https://likablehair.it/",
            Immagine: {
              data: {
                attributes: {
                  formats: {
                    small: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    },
                    thumbnail: {
                      url: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.topolino.it%2Fpersonaggio%2Fpippo%2F&psig=AOvVaw1-E0zg1TRar8SyGcNyqToI&ust=1706712634960000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNiWtpWuhYQDFQAAAAAdAAAAABAD"
                    }
                  }
                }
              }
            },
            Categorie: {
              data: {
                attributes: {
                  Nome: "Categoria 1"
                }
              }
            },
            publishedAt: "2024-01-30T00:00:00.000Z"
          }
        }
      ],
      dataCategories: [],
      newsDialog: false,
      openedNews: {},
      searchText: '',
    };
  },
  mounted() {
    this.getNews();
    this.getCategories();
  },
  methods: {
    async getNews() {
      newsService.getNews(undefined, undefined)
        .then((response) => {
          this.dataNews = response.data;
        })
    },
    async getCategories() {
      newsService.getCategories()
        .then((response) => {
          this.dataCategories = response.data;
        })
    },
    openNews(news){
      this.openedNews = news
      this.newsDialog = true
    },
    async handleCategoryClick(category){
      if(!!category)
        newsService.getNews(undefined, undefined, { 'filters[Categorie][Nome][$in]': [category.attributes.Nome] })
          .then((response) => {
            this.dataNews = response.data;          
          })
      else 
        this.getNews()
    },
    async search(){
      if(this.searchText.length > 0)
        newsService.getNews(undefined, undefined, { 
          'filters[$or][0][Titolo][$contains]': this.searchText,
          'filters[$or][1][Descrizione][$contains]': this.searchText
        })
          .then((response) => {
            this.dataNews = response.data;          
          })
      else 
        this.getNews()
    },
    goBack() {
      this.$router.push(this.routeFather);
    },
  },
  computed: {
  },
  watch: {
  }
};
</script>
<style scoped>
  .news-card {
    height: 40vh; 
    width: 100%; 
    border-radius: 12px; 
    overflow: hidden;
    transition: all 0.2s;
    transform: scale(1);
  }
  .news-card:hover {
    transition: all 0.2s;
    transform: scale(1.05);
    z-index: 10;
  }
  .mobile-low-gap {
    padding: 28px 40px;
  }
  @media screen and (max-width: 768px) {
    .news-row-container {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100%;
    }
    .news-container {
      flex: 1;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .news-row-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .news-container {
      flex: auto;
      max-width: 100%;
    }
    .mobile-low-gap {
      padding: 28px 10px;
    }
    .mobile-full {
      flex: auto;
      width: 100%;
      max-width: 100%;
    }
  }
</style>