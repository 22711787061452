import axios from "@/services/axios";
import UrlKeeper from "@/services/UrlKeeper.js";
import DateHandler from "@/services/common/dateHandler";

class orderManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/orders/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  get(orderId) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/orders/get", { id: orderId }).then((response) => {
        resolve(response.results);
      });
    });
  }

  getLastCode() {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/orders/getLastCode", {}).then((response) => {
        if (response.success) {
          resolve(response.results);
        }
      });
    });
  }
  updateOrder(order) {
    const apiUrl = UrlKeeper.getUrl();

    delete order.currentItemsSelected

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/orders/update", order).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject("error during the call");
        }
      });
    });
  }

  print(order) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .postWithDownload(apiUrl + "/orders/print", {
          id: order.id
        }, order.code + ".pdf")
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  createOrder(order) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/orders/create", order).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject(response.results.error);
        }
      });
    });
  }

  deleteOrder(order) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/orders/delete", order).then((response) => {
        if (response.success) {
          resolve(response.results);
        } else {
          reject(response.results.error);
        }
      });
    });
  }

  addItem(
    orderId,
    itemId,
    qtyToOrder,
    grossPrice,
    price,
    discount,
    code,
    qtyDelivered
  ) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/orders/items/" + orderId + "/add", {
          itemId: itemId,
          qtyToOrder: qtyToOrder,
          grossPrice: grossPrice,
          price: price,
          discount: discount,
          code: code,
          qtyDelivered: qtyDelivered,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            console.log(response);
            reject("error during the call");
          }
        });
    });
  }

  addGenericItems(orderId, genericItems) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl + "/orders/genericItem/" + orderId + "/addGenericItems",
          genericItems
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }

  getGenericOrderItems(orderId, page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl();

    console.log("getGenericOrderItems", orderId, page, rowPerPage)
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/orders/genericItems/" + orderId + "/list", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }

  updateGenericOrderItems(orderId, orderItemsToUpdate) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl + "/orders/genericItems/" + orderId + "/updateGenericOrderItems",
          orderItemsToUpdate
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }
  removeGenericItem(orderId, genericItem) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/orders/genericItems/" + orderId + "/removeGenericItem", genericItem)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }
  getOrderItems(orderId, page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/orders/items/" + orderId + "/list", {
          page: page,
          rowPerPage: rowPerPage,
        })
        .then((response) => {
          if (response.success) {
            response.results.rows.map((orderItem) => {
              orderItem.qtyToDeliver =
                Number(orderItem.qtyToOrder) - Number(orderItem.qtyDelivered);
            });
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }

  removeItem(orderId, item) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/orders/items/" + orderId + "/removeItem", item)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }
  updateOrderItems(orderId, orderItemsToUpdate) {
    const apiUrl = UrlKeeper.getUrl();

    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl + "/orders/items/" + orderId + "/updateOrderItems",
          orderItemsToUpdate
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }

  _fields() {
    return Promise.resolve([
      {
        text: "Stato ordine",
        value: "status",
        type: "custom",
      },

      {
        text: "Codice interno",
        value: "code",
      },
      {
        text: "Descrizione",
        value: "description",
      },
      {
        text: "Fornitore",
        value: "supplier",
        type: "object",
        style: "chip",
      },
      {
        text: "Totale ordine",
        type: "custom",
        value: "amount",
      },
      {
        text: "Sconto",
        type: "custom",

        value: "discount",
      },

      {
        text: "Data creazione ordine",
        value: "createdAt",
        type: "date",
      },
      {
        text: "Bozza commerciale generata",
        value: "invoicePresent",
        type: "custom",
      },
      {
        text: "Data creazione bozza",
        value: "invoiceCreatedAt",
        type: "custom",
      },
    ]);
  }

  _fieldsToExport() {
    return {
      'Stato ordine': {
        field: 'status',
        callback: (value) => {
          const map = {
            created: 'Creato',
            waiting: 'In attesa',
            pending: 'Evaso parzialmente',
            fulfilled: 'Evaso totalmente',
            rejected: 'Chiuso forzatamente',
          }

          return map[value]
        }
      },
      'Descrizione': 'description',
      'Fornitore': {
        field: 'supplier',
        callback: (value) => {
          return value.businessName
        }
      },
      'Totale ordine': 'amount',
      'Sconto': 'discount',
      'Data creazione ordine': {
        field: 'createdAt',
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      'Bozza commerciale generata': {
        field: 'invoiceOrder',
        callback: (value) => {
          return value.length > 0 ? 'Si' : 'No'
        }
      },
      'Data creazione bozza': {
        field: 'invoiceCreatedAt',
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      'Prodotti': {
        field: 'orderItems',
        callback: (value) => {
          return value.map((item) => {
            return item.qtyToOrder + ' - ' + item.item.description
          }).join(', ')
        }
      }
    }
  }

}

export default new orderManagment();
