<template>
  <div id="content-to-print">
    <TitleWithBackButton title="Analisi Obiettivi" back-route="Analytics" icon="mdi-graphql"></TitleWithBackButton>
    <div class="d-flex align-center ml-3 mt-3 pr-5">
      <v-select v-model="selectedYear" label="Seleziona Anno" style="max-width: 300px;" :items="years" filled rounded
        dense mandatory>
      </v-select>
      <v-spacer></v-spacer>
      <v-btn class="mr-2 ml-2" text @click="calculate" :loading="loading">Calcola
      </v-btn>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn class="mr-2 ml-2" text @click="print" :loading="loading">Stampa</v-btn>
      <v-btn icon large :disabled="loading" style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }" :color="!!ghost ? 'red' : ''">
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>
    <div class="pa-4 mt-5">
      <div class="pt-5">
        <v-row class="d-flex justify-center pb-5">
          <v-chip 
            class="text-button text-center" 
            style="font-size: 1.2rem !important;" 
            color="primary"
          >
            Obiettivi incasso generali
          </v-chip>
        </v-row>
        <TypeDataTable 
          class="balance-table" 
          headersColor="rgb(224, 224, 230)"
          style="background-color: transparent; width: 95vw;" 
          item-key="name" 
          :headers="balanceTableHeaders"
          :items="totalData" 
          :show-select="false" 
          :select-on-row-click="false" 
          :show-actions="false"
          :disablePagination="true" 
          dense
          disable-sort
        >
          <template v-slot:custom-legend>
            <div 
              class="d-flex flex-column align-center"
              style="border-right: 1px solid rgba(50,50,50,0.1);"
            >
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                </template>
                <span>Obiettivo</span>
              </v-tooltip>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                </template>
                <span>Risultato</span>
              </v-tooltip>
              <v-tooltip bottom color="blue">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                </template>
                <span>Differenza</span>
              </v-tooltip>
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                </template>
                <span>Scostamento</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:custom-monthTotal="{ value }">
            <div class="d-flex flex-column pa-0 ma-0" v-if="!!value">
              <span class="green--text font-weight-bold">{{ value.goal }}</span>
              <span class="black--text font-weight-bold">{{ value.total }}</span>
              <span class="blue--text font-weight-bold">{{ value.delta }}</span>
              <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
            </div>
          </template>
          <template v-slot:custom-monthSummary="{ value }">
            <div :style="{
                'background-color': summaryColorMapping[value] + ' !important',
                'height': '100%',
                'border-right': '2px solid rgba(50,50,50,0.3)',
              }" class="d-flex align-center justify-center"
            >
            </div>
          </template>
        </TypeDataTable>
      </div>
      <div class="pt-5">
        <v-row class="d-flex justify-center pb-5">
          <v-chip 
            class="text-button text-center" 
            style="font-size: 1.2rem !important;" 
            color="primary"
          >
            Obiettivi generali parrucchiere
          </v-chip>
        </v-row>
        <TypeDataTable 
          class="balance-table" 
          headersColor="rgb(224, 224, 230)"
          style="background-color: transparent; width: 95vw;" 
          item-key="name" 
          :headers="balanceTableHeaders" 
          :items="generalDataBarber" 
          :show-select="false" 
          :select-on-row-click="false" 
          :show-actions="false"
          :disablePagination="true" 
          dense
          disable-sort
        >
          <template v-slot:custom-legend>
            <div 
              class="d-flex flex-column align-center"
              style="border-right: 1px solid rgba(50,50,50,0.1);"
            >
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                </template>
                <span>Obiettivo</span>
              </v-tooltip>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                </template>
                <span>Risultato</span>
              </v-tooltip>
              <v-tooltip bottom color="blue">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                </template>
                <span>Differenza</span>
              </v-tooltip>
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                </template>
                <span>Scostamento</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:custom-monthTotal="{ value }">
            <div class="d-flex flex-column pa-0 ma-0" v-if="!!value">
              <span class="green--text font-weight-bold">{{ value.goal }}</span>
              <span class="black--text font-weight-bold">{{ value.total }}</span>
              <span class="blue--text font-weight-bold">{{ value.delta }}</span>
              <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
            </div>
          </template>
          <template v-slot:custom-monthSummary="{ value }">
            <div :style="{
                'background-color': summaryColorMapping[value] + ' !important',
                'height': '100%',
                'border-right': '2px solid rgba(50,50,50,0.3)',
              }" class="d-flex align-center justify-center"
            >
            </div>
          </template>
        </TypeDataTable>
      </div>
      <v-divider class="mb-10"></v-divider>
      <div>
        <v-btn-toggle
          v-model="barberChartType"
          mandatory
          rounded
          dense
          borderless
          color="primary"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <span>Clienti</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-cash</v-icon>
              </v-btn>
            </template>
            <span>Incassi</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-cash-register</v-icon>
              </v-btn>
            </template>
            <span>P.Cassa</span>
          </v-tooltip>
        </v-btn-toggle>
      </div>
      <v-window
        v-model="barberChartType"
      >
        <v-window-item>
          <div class="pt-5">
            <v-row class="d-flex justify-center pb-5" >
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;" 
                color="primary"
              >
                Obiettivi per famiglia parrucchiere - Clienti
              </v-chip>
            </v-row>
            <TypeDataTable 
              class="balance-table" 
              headersColor="rgb(224, 224, 230)"
              style="background-color: transparent; width: 95vw;" 
              item-key="name" 
              :headers="balanceTableHeaders" 
              :items="serviceTypesCountDataBarber" 
              :show-select="false" 
              :select-on-row-click="false" 
              :show-actions="false"
              :disablePagination="true" 
              dense
              disable-sort
            >
              <template v-slot:custom-legend>
                <div 
                  class="d-flex flex-column align-center"
                  style="border-right: 1px solid rgba(50,50,50,0.1);"
                >
                  <v-tooltip bottom color="green">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                    </template>
                    <span>Obiettivo</span>
                  </v-tooltip>
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                    </template>
                    <span>Risultato</span>
                  </v-tooltip>
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                    </template>
                    <span>Differenza</span>
                  </v-tooltip>
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                    </template>
                    <span>Scostamento</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:custom-monthTotal="{ value }">
                <div class="d-flex flex-column" v-if="!!value">
                  <span class="green--text font-weight-bold">{{ value.goal }}</span>
                  <span class="black--text font-weight-bold">{{ value.total }}</span>
                  <span class="blue--text font-weight-bold">{{ value.delta }}</span>
                  <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
                </div>
              </template>
              <template v-slot:custom-monthSummary="{ value }">
                <div :style="{
                    'background-color': summaryColorMapping[value] + ' !important',
                    'height': '100%',
                    'border-right': '2px solid rgba(50,50,50,0.3)',
                  }" class="d-flex align-center justify-center">
                </div>
              </template>
            </TypeDataTable>
          </div>
        </v-window-item>
        <v-window-item>
          <div class="pt-5">
            <v-row class="d-flex justify-center pb-5" >
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;" 
                color="primary"
              >
                Obiettivi per famiglia parrucchiere - Incassi
              </v-chip>
            </v-row>
            <TypeDataTable 
              class="balance-table" 
              headersColor="rgb(224, 224, 230)"
              style="background-color: transparent; width: 95vw;" 
              item-key="name" 
              :headers="balanceTableHeaders" 
              :items="serviceTypesTotalDataBarber" 
              :show-select="false" 
              :select-on-row-click="false" 
              :show-actions="false"
              :disablePagination="true" 
              dense
              disable-sort
            >
              <template v-slot:custom-legend>
                <div 
                  class="d-flex flex-column align-center"
                  style="border-right: 1px solid rgba(50,50,50,0.1);"
                >
                  <v-tooltip bottom color="green">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                    </template>
                    <span>Obiettivo</span>
                  </v-tooltip>
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                    </template>
                    <span>Risultato</span>
                  </v-tooltip>
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                    </template>
                    <span>Differenza</span>
                  </v-tooltip>
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                    </template>
                    <span>Scostamento</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:custom-monthTotal="{ value }">
                <div class="d-flex flex-column" v-if="!!value">
                  <span class="green--text font-weight-bold">{{ value.goal }}</span>
                  <span class="black--text font-weight-bold">{{ value.total }}</span>
                  <span class="blue--text font-weight-bold">{{ value.delta }}</span>
                  <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
                </div>
              </template>
              <template v-slot:custom-monthSummary="{ value }">
                <div :style="{
                    'background-color': summaryColorMapping[value] + ' !important',
                    'height': '100%',
                    'border-right': '2px solid rgba(50,50,50,0.3)',
                  }" class="d-flex align-center justify-center">
                </div>
              </template>
            </TypeDataTable>
          </div>
        </v-window-item>
        <v-window-item>
          <div class="pt-5">
            <v-row class="d-flex justify-center pb-5" >
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;" 
                color="primary"
              >
                Obiettivi per famiglia parrucchieei - Passaggi Cassa
              </v-chip>
            </v-row>
            <TypeDataTable 
              class="balance-table" 
              headersColor="rgb(224, 224, 230)"
              style="background-color: transparent; width: 95vw;" 
              item-key="name" 
              :headers="balanceTableHeaders" 
              :items="serviceTypesTransactionDataBarber" 
              :show-select="false" 
              :select-on-row-click="false" 
              :show-actions="false"
              :disablePagination="true" 
              dense
              disable-sort
            >
              <template v-slot:custom-legend>
                <div 
                  class="d-flex flex-column align-center"
                  style="border-right: 1px solid rgba(50,50,50,0.1);"
                >
                  <v-tooltip bottom color="green">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                    </template>
                    <span>Obiettivo</span>
                  </v-tooltip>
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                    </template>
                    <span>Risultato</span>
                  </v-tooltip>
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                    </template>
                    <span>Differenza</span>
                  </v-tooltip>
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                    </template>
                    <span>Scostamento</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:custom-monthTotal="{ value }">
                <div class="d-flex flex-column" v-if="!!value">
                  <span class="green--text font-weight-bold">{{ value.goal }}</span>
                  <span class="black--text font-weight-bold">{{ value.total }}</span>
                  <span class="blue--text font-weight-bold">{{ value.delta }}</span>
                  <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
                </div>
              </template>
              <template v-slot:custom-monthSummary="{ value }">
                <div :style="{
                    'background-color': summaryColorMapping[value] + ' !important',
                    'height': '100%',
                    'border-right': '2px solid rgba(50,50,50,0.3)',
                  }" class="d-flex align-center justify-center">
                </div>
              </template>
            </TypeDataTable>
          </div>
        </v-window-item>
      </v-window>
      <v-divider class="mb-10"></v-divider>
      <div class="pt-5">
        <v-row class="d-flex justify-center pb-5">
          <v-chip 
            class="text-button text-center" 
            style="font-size: 1.2rem !important;" 
            color="primary"
          >
            Obiettivi generali estetica
          </v-chip>
        </v-row>
        <TypeDataTable 
          class="balance-table" 
          headersColor="rgb(224, 224, 230)"
          style="background-color: transparent; width: 95vw;" 
          item-key="name" 
          :headers="balanceTableHeaders" 
          :items="generalDataBeauty" 
          :show-select="false" 
          :select-on-row-click="false" 
          :show-actions="false"
          :disablePagination="true" 
          dense
          disable-sort
        >
          <template v-slot:custom-legend>
            <div 
              class="d-flex flex-column align-center"
              style="border-right: 1px solid rgba(50,50,50,0.1);"
            >
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                </template>
                <span>Obiettivo</span>
              </v-tooltip>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                </template>
                <span>Risultato</span>
              </v-tooltip>
              <v-tooltip bottom color="blue">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                </template>
                <span>Differenza</span>
              </v-tooltip>
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                </template>
                <span>Scostamento</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:custom-monthTotal="{ value }">
            <div class="d-flex flex-column pa-0 ma-0" v-if="!!value">
              <span class="green--text font-weight-bold">{{ value.goal }}</span>
              <span class="black--text font-weight-bold">{{ value.total }}</span>
              <span class="blue--text font-weight-bold">{{ value.delta }}</span>
              <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
            </div>
          </template>
          <template v-slot:custom-monthSummary="{ value }">
            <div :style="{
                'background-color': summaryColorMapping[value] + ' !important',
                'height': '100%',
                'border-right': '2px solid rgba(50,50,50,0.3)',
              }" class="d-flex align-center justify-center"
            >
            </div>
          </template>
        </TypeDataTable>
      </div>
      <v-divider class="mb-10"></v-divider>
      <div>
        <v-btn-toggle
          v-model="beautyChartType"
          mandatory
          rounded
          dense
          borderless
          color="primary"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <span>Clienti</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-cash</v-icon>
              </v-btn>
            </template>
            <span>Incassi</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary lighten-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-cash-register</v-icon>
              </v-btn>
            </template>
            <span>P.Cassa</span>
          </v-tooltip>
        </v-btn-toggle>
      </div>
      <v-window
        v-model="beautyChartType"
      >
        <v-window-item>
          <div class="pt-5">
            <v-row class="d-flex justify-center pb-5" >
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;" 
                color="primary"
              >
                Obiettivi per famiglia estetica - Clienti
              </v-chip>
            </v-row>
            <TypeDataTable 
              class="balance-table" 
              headersColor="rgb(224, 224, 230)"
              style="background-color: transparent; width: 95vw;" 
              item-key="name" 
              :headers="balanceTableHeaders" 
              :items="serviceTypesCountDataBeauty" 
              :show-select="false" 
              :select-on-row-click="false" 
              :show-actions="false"
              :disablePagination="true" 
              dense
              disable-sort
            >
              <template v-slot:custom-legend>
                <div 
                  class="d-flex flex-column align-center"
                  style="border-right: 1px solid rgba(50,50,50,0.1);"
                >
                  <v-tooltip bottom color="green">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                    </template>
                    <span>Obiettivo</span>
                  </v-tooltip>
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                    </template>
                    <span>Risultato</span>
                  </v-tooltip>
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                    </template>
                    <span>Differenza</span>
                  </v-tooltip>
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                    </template>
                    <span>Scostamento</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:custom-monthTotal="{ value }">
                <div class="d-flex flex-column" v-if="!!value">
                  <span class="green--text font-weight-bold">{{ value.goal }}</span>
                  <span class="black--text font-weight-bold">{{ value.total }}</span>
                  <span class="blue--text font-weight-bold">{{ value.delta }}</span>
                  <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
                </div>
              </template>
              <template v-slot:custom-monthSummary="{ value }">
                <div :style="{
                    'background-color': summaryColorMapping[value] + ' !important',
                    'height': '100%',
                    'border-right': '2px solid rgba(50,50,50,0.3)',
                  }" class="d-flex align-center justify-center">
                </div>
              </template>
            </TypeDataTable>
          </div>
        </v-window-item>
        <v-window-item>
          <div class="pt-5">
            <v-row class="d-flex justify-center pb-5" >
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;" 
                color="primary"
              >
                Obiettivi per famiglia estetica - Incassi
              </v-chip>
            </v-row>
            <TypeDataTable 
              class="balance-table" 
              headersColor="rgb(224, 224, 230)"
              style="background-color: transparent; width: 95vw;" 
              item-key="name" 
              :headers="balanceTableHeaders" 
              :items="serviceTypesTotalDataBeauty"
              :show-select="false" 
              :select-on-row-click="false" 
              :show-actions="false"
              :disablePagination="true" 
              dense
              disable-sort
            >
              <template v-slot:custom-legend>
                <div 
                  class="d-flex flex-column align-center"
                  style="border-right: 1px solid rgba(50,50,50,0.1);"
                >
                  <v-tooltip bottom color="green">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                    </template>
                    <span>Obiettivo</span>
                  </v-tooltip>
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                    </template>
                    <span>Risultato</span>
                  </v-tooltip>
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                    </template>
                    <span>Differenza</span>
                  </v-tooltip>
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                    </template>
                    <span>Scostamento</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:custom-monthTotal="{ value }">
                <div class="d-flex flex-column" v-if="!!value">
                  <span class="green--text font-weight-bold">{{ value.goal }}</span>
                  <span class="black--text font-weight-bold">{{ value.total }}</span>
                  <span class="blue--text font-weight-bold">{{ value.delta }}</span>
                  <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
                </div>
              </template>
              <template v-slot:custom-monthSummary="{ value }">
                <div :style="{
                    'background-color': summaryColorMapping[value] + ' !important',
                    'height': '100%',
                    'border-right': '2px solid rgba(50,50,50,0.3)',
                  }" class="d-flex align-center justify-center">
                </div>
              </template>
            </TypeDataTable>
          </div>
        </v-window-item>
        <v-window-item>
          <div class="pt-5">
            <v-row class="d-flex justify-center pb-5" >
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;" 
                color="primary"
              >
                Obiettivi per famiglia estetica - Passaggi Cassa
              </v-chip>
            </v-row>
            <TypeDataTable 
              class="balance-table" 
              headersColor="rgb(224, 224, 230)"
              style="background-color: transparent; width: 95vw;" 
              item-key="name" 
              :headers="balanceTableHeaders" 
              :items="serviceTypesTransactionDataBeauty"
              :show-select="false" 
              :select-on-row-click="false" 
              :show-actions="false"
              :disablePagination="true" 
              dense
              disable-sort
            >
              <template v-slot:custom-legend>
                <div 
                  class="d-flex flex-column align-center"
                  style="border-right: 1px solid rgba(50,50,50,0.1);"
                >
                  <v-tooltip bottom color="green">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="green--text font-weight-bold">O</span>
                    </template>
                    <span>Obiettivo</span>
                  </v-tooltip>
                  <v-tooltip bottom color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="black--text font-weight-bold">R</span>
                    </template>
                    <span>Risultato</span>
                  </v-tooltip>
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="blue--text font-weight-bold">D</span>
                    </template>
                    <span>Differenza</span>
                  </v-tooltip>
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="red--text font-weight-bold">S</span>
                    </template>
                    <span>Scostamento</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:custom-monthTotal="{ value }">
                <div class="d-flex flex-column" v-if="!!value">
                  <span class="green--text font-weight-bold">{{ value.goal }}</span>
                  <span class="black--text font-weight-bold">{{ value.total }}</span>
                  <span class="blue--text font-weight-bold">{{ value.delta }}</span>
                  <span class="red--text font-weight-bold">{{ value.onDelta }}</span>
                </div>
              </template>
              <template v-slot:custom-monthSummary="{ value }">
                <div :style="{
                    'background-color': summaryColorMapping[value] + ' !important',
                    'height': '100%',
                    'border-right': '2px solid rgba(50,50,50,0.3)',
                  }" class="d-flex align-center justify-center">
                </div>
              </template>
            </TypeDataTable>
          </div>
        </v-window-item>
      </v-window>
    </div>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import balanceService from '@/services/balance/balance.service.js'
import operatorService from '@/services/operators/operators.service.js'
import html2canvas from '@/assets/js/html2canvas.min.js'
import _ from "lodash";
import * as XLSX from "xlsx";

export default {
  name: "GoalsBalance",
  components: {
    TitleWithBackButton,
    GroupBasedChart,
    TypeDataTable,
    RangeDateSelector,
    ManualBarcodeInput,
    StandardDialog
  },
  data: function () {
    let startDate = new Date();
    startDate.setDate(1)
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    return {
      loading: false,
      printLoading: false,
      selectedYear: undefined,
      years: [],
      ghost: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      summaryColorMapping: {
        success: '#00C852',
        warning: '#FFBB33',
        error: '#FF4444',
      },
      totalData: [],
      generalDataBarber: [],
      generalDataBeauty: [],
      barberChartType: 0,
      beautyChartType: 0,
      serviceTypesCountDataBarber: [],
      serviceTypesTotalDataBarber: [],
      serviceTypesTransactionDataBarber: [],
      serviceTypesCountDataBeauty: [],
      serviceTypesTotalDataBeauty: [],
      serviceTypesTransactionDataBeauty: [],
      monthMapping: ['', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
      balanceTableHeaders: [
        { text: 'Categoria', value: 'name', class: ['sticky-column-header', 'column-header-gray'], cellClass: ['sticky-column', 'column-gray'], overrideClass: true },
        { text: 'L', value: 'legend', type: 'custom' },

        { text: 'Gennaio', value: '1total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '1sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Febbraio', value: '2total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '2sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Marzo', value: '3total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '3sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Aprile', value: '4total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '4sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Maggio', value: '5total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '5sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Giugno', value: '6total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '6sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Luglio', value: '7total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '7sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Agosto', value: '8total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '8sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Settembre', value: '9total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '9sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Ottobre', value: '10total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '10sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Novembre', value: '11total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '11sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Dicembre', value: '12total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: '12sum', type: 'custom-type', customType: 'monthSummary' },

        { text: 'Totale', value: 'total', type: 'custom-type', customType: 'monthTotal' },
        { text: '', value: 'sum', type: 'custom-type', customType: 'monthSummary' },
      ],
      fileName: ""
    }
  },
  async mounted() {
    let today = new Date()
    let length = today.getFullYear() - 2020 + 1
    this.years = Array.from({ length: length }, (v, k) => k + 2020)

    this.selectedYear = today.getFullYear()

    this.calculate()
  },
  methods: {
    exportToExcel() {
      this.loading = true
      let workbook = XLSX.utils.book_new();
      this.fileName = "Analisi_Obiettivi_" + this.selectedYear + ".xls"

      let ws = XLSX.utils.aoa_to_sheet([[]]);

      let totalDataGoal = this.totalData.map((el) => {
        return [
          el.name + " - Obiettivo",
          el["1total"].goal,
          el["2total"].goal,
          el["3total"].goal,
          el["4total"].goal,
          el["5total"].goal,
          el["6total"].goal,
          el["7total"].goal,
          el["8total"].goal,
          el["9total"].goal,
          el["10total"].goal,
          el["11total"].goal,
          el["12total"].goal,
          el["total"].goal
        ]
      })

      let totalDataTotal = this.totalData.map((el) => {
        return [
          el.name + " - Risultato",
          el["1total"].total,
          el["2total"].total,
          el["3total"].total,
          el["4total"].total,
          el["5total"].total,
          el["6total"].total,
          el["7total"].total,
          el["8total"].total,
          el["9total"].total,
          el["10total"].total,
          el["11total"].total,
          el["12total"].total,
          el["total"].total
        ]
      })

      let totalDataDelta = this.totalData.map((el) => {
        return [
          el.name + " - Differenza",
          el["1total"].delta,
          el["2total"].delta,
          el["3total"].delta,
          el["4total"].delta,
          el["5total"].delta,
          el["6total"].delta,
          el["7total"].delta,
          el["8total"].delta,
          el["9total"].delta,
          el["10total"].delta,
          el["11total"].delta,
          el["12total"].delta,
          el["total"].delta
        ]
      })

      let totalDataOnDelta = this.totalData.map((el) => {
        return [
          el.name + " - Scostamento",
          el["1total"].onDelta,
          el["2total"].onDelta,
          el["3total"].onDelta,
          el["4total"].onDelta,
          el["5total"].onDelta,
          el["6total"].onDelta,
          el["7total"].onDelta,
          el["8total"].onDelta,
          el["9total"].onDelta,
          el["10total"].onDelta,
          el["11total"].onDelta,
          el["12total"].onDelta,
          el["total"].onDelta
        ]
      })

      XLSX.utils.sheet_add_aoa(ws, [
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...totalDataGoal,
        ...totalDataTotal,
        ...totalDataDelta,
        ...totalDataOnDelta
      ])

      XLSX.utils.book_append_sheet(workbook, ws, "Obiettivi Incasso Generali");

      let infoTypes = ["goal", "total", "delta", "onDelta"]
      let infoTypesNames = ["Obiettivo", "Risultato", "Differenza", "Scostamento"]
      let generalDataBarberExcel = []
      for (let i = 0; i < this.generalDataBarber.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.generalDataBarber[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.generalDataBarber[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.generalDataBarber[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        generalDataBarberExcel.push(insideObject)
      }
      
      let ws1 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws1, [
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...generalDataBarberExcel.flat()
      ])

      XLSX.utils.book_append_sheet(workbook, ws1, "Obiettivi Generali Parrucchiere");

      let serviceTypesCountDataBarberExcel = []
      for (let i = 0; i < this.serviceTypesCountDataBarber.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.serviceTypesCountDataBarber[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.serviceTypesCountDataBarber[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.serviceTypesCountDataBarber[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        serviceTypesCountDataBarberExcel.push(insideObject)
      }

      let serviceTypesTotalDataBarberExcel = []
      for (let i = 0; i < this.serviceTypesTotalDataBarber.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.serviceTypesTotalDataBarber[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.serviceTypesTotalDataBarber[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.serviceTypesTotalDataBarber[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        serviceTypesTotalDataBarberExcel.push(insideObject)
      }

      let serviceTypesTransactionDataBarberExcel = []
      for (let i = 0; i < this.serviceTypesTransactionDataBarber.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.serviceTypesTransactionDataBarber[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.serviceTypesTransactionDataBarber[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.serviceTypesTransactionDataBarber[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        serviceTypesTransactionDataBarberExcel.push(insideObject)
      }

      let wsParrucchiereDettaglio = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(wsParrucchiereDettaglio, [
        ["Clienti"],
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...serviceTypesCountDataBarberExcel.flat(),
        [],
        ["Incassi"],
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...serviceTypesTotalDataBarberExcel.flat(),
        [],
        ["Passaggi di Cassa"],
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...serviceTypesTransactionDataBarberExcel.flat()
      ])

      XLSX.utils.book_append_sheet(workbook, wsParrucchiereDettaglio, "Famiglia Parrucchiere Dettaglio");
      
      let generalDataBeautyExcel = []
      for (let i = 0; i < this.generalDataBeauty.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.generalDataBeauty[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.generalDataBeauty[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.generalDataBeauty[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        generalDataBeautyExcel.push(insideObject)
      }

      let ws2 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws2, [
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...generalDataBeautyExcel.flat()
      ])

      XLSX.utils.book_append_sheet(workbook, ws2, "Obiettivi Generali Estetica");
      
      let serviceTypesCountDataBeautyExcel = []
      for (let i = 0; i < this.serviceTypesCountDataBeauty.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.serviceTypesCountDataBeauty[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.serviceTypesCountDataBeauty[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.serviceTypesCountDataBeauty[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        serviceTypesCountDataBeautyExcel.push(insideObject)
      }

      let serviceTypesTotalDataBeautyExcel = []
      for (let i = 0; i < this.serviceTypesTotalDataBeauty.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.serviceTypesTotalDataBeauty[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.serviceTypesTotalDataBeauty[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.serviceTypesTotalDataBeauty[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        serviceTypesTotalDataBeautyExcel.push(insideObject)
      }

      let serviceTypesTransactionDataBeautyExcel = []
      for (let i = 0; i < this.serviceTypesTransactionDataBeauty.length; i += 1) {
        let insideObject = []
        for (let j = 0; j < infoTypes.length; j += 1) {
          let insideObjectInfoTypes = [
            this.serviceTypesTransactionDataBeauty[i].name + " - " + infoTypesNames[j]
          ]
          for (let k = 1; k <= 13; k += 1) {
            if (k === 13) {
              insideObjectInfoTypes.push(this.serviceTypesTransactionDataBeauty[i]["total"][infoTypes[j]])
            } else {
              insideObjectInfoTypes.push(this.serviceTypesTransactionDataBeauty[i][k + "total"][infoTypes[j]])
            }
          }
          insideObject.push(insideObjectInfoTypes)
        }
        insideObject.push([])
        serviceTypesTransactionDataBeautyExcel.push(insideObject)
      }

      let wsEsteticaDettaglio = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(wsEsteticaDettaglio, [
        ["Clienti"],
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...serviceTypesCountDataBeautyExcel.flat(),
        [],
        ["Incassi"],
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...serviceTypesTotalDataBeautyExcel.flat(),
        [],
        ["Passaggi di Cassa"],
        [
          'Categoria',
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
          'Totale'
        ],
        ...serviceTypesTransactionDataBeautyExcel.flat()
      ])

      XLSX.utils.book_append_sheet(workbook, wsEsteticaDettaglio, "Famiglia Estetica Dettaglio");

      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
      this.loading = false
    },
    async calculate() {
      this.loading = true
      let res = await balanceService.goalsBalance({
        year: this.selectedYear,
        ghost: this.ghost
      })
      this.fileName = "Analisi_Obiettivi_" + this.selectedYear + ".xls"
      let categoryGoals = res.categoryGoals.results
      let goals = res.goals.results
      if (categoryGoals.length === 0 || goals.length === 0) {
        this.$delegates.snackbar('Attenzione! Obiettivi non trovati')
        return
      }

      this.calculateTotal(categoryGoals, res)
      const barberGeneral = this.calculateGeneral(categoryGoals, res, 'Parrucchiere', this.generalDataBarber)
      const beautyGeneral = this.calculateGeneral(categoryGoals, res, 'Estetica', this.generalDataBeauty)
      this.calculateServiceTypes(goals, barberGeneral, res, 'Parrucchiere', this.serviceTypesCountDataBarber, this.serviceTypesTotalDataBarber, this.serviceTypesTransactionDataBarber)
      this.calculateServiceTypes(goals, beautyGeneral, res, 'Estetica', this.serviceTypesCountDataBeauty, this.serviceTypesTotalDataBeauty, this.serviceTypesTransactionDataBeauty)

      this.loading = false
    },
    calculateTotal(categoryGoals, res){
      let revenueTotalGoalBarber = 0
      const barberRegex = /parrucchier[ie]/i
      if(!!categoryGoals.find(cg => barberRegex.test(cg.categoryName)))
        revenueTotalGoalBarber = Number(categoryGoals.find(cg => barberRegex.test(cg.categoryName)).revenueGoal)
      let revenueTotalGoalBeauty = 0
      if(!!categoryGoals.find(cg => cg.categoryName == 'Estetica'))
        revenueTotalGoalBeauty += Number(categoryGoals.find(cg => cg.categoryName == 'Estetica').revenueGoal)

      let revenueObj = { name: 'Incassi' }

      for (let i = 1; i <= 12; i++) {
        let monthKey = this.monthMapping[i] + 'RevenueGoal'
        let totalGoalBarber = 0
        if(!!categoryGoals.find(cg => barberRegex.test(cg.categoryName)))
          totalGoalBarber = Number(categoryGoals.find(cg => barberRegex.test(cg.categoryName))[monthKey])
        totalGoalBarber = revenueTotalGoalBarber / 100 * totalGoalBarber
        let totalGoalBeauty = 0
        if(!!categoryGoals.find(cg => cg.categoryName == 'Estetica'))
          totalGoalBeauty += Number(categoryGoals.find(cg => cg.categoryName == 'Estetica')[monthKey])
        totalGoalBeauty = revenueTotalGoalBeauty / 100 * totalGoalBeauty

        let total = 0
        if (!!res.total.find(t => t.month == (i) && barberRegex.test(t.categoryName)))
          total = Number(res.total.find(t => t.month == (i) && barberRegex.test(t.categoryName)).total)
        if (!!res.total.find(t => t.month == (i) && t.categoryName == 'Estetica'))
          total += Number(res.total.find(t => t.month == (i) && t.categoryName == 'Estetica').total)

        res.paymentDebits.filter(t => t.month == (i)).forEach(t => total -= Number(t.total))

        revenueObj[(i) + 'total'] = {
          goal: (totalGoalBarber + totalGoalBeauty).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          total: total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          delta: (total - (totalGoalBarber + totalGoalBeauty)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          onDelta: this.calculateOnDelta(total, (totalGoalBarber + totalGoalBeauty)).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        revenueObj[(i) + 'sum'] = this.sumColor(total, (totalGoalBarber + totalGoalBeauty))
      }

      let total = res.total.filter(t => barberRegex.test(t.categoryName) || t.categoryName == 'Estetica').reduce((a, b) => a + Number(b.total), 0)
      total -= res.paymentDebits.filter(t => barberRegex.test(t.categoryName) || t.categoryName == 'Estetica').reduce((a, b) => a + Number(b.total), 0)

      revenueObj['total'] = {
        goal: (revenueTotalGoalBarber + revenueTotalGoalBeauty).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        total: (total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        delta: (total - (revenueTotalGoalBarber + revenueTotalGoalBeauty)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        onDelta: this.calculateOnDelta(total, (revenueTotalGoalBarber + revenueTotalGoalBeauty)).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }

      this.totalData = [revenueObj]
    },
    calculateGeneral(categoryGoals, res, categoryName, chartData) {
      chartData.length = 0
      let categoryGoal = categoryGoals.find(cg => cg.categoryName == categoryName)

      let revenueTotalGoal = Number(categoryGoal.revenueGoal)
      let revenueObj = { name: 'Incassi' }
      let averageTransactionGoal = Number(categoryGoal.averageFicheGoal)
      let averageTransaction = { name: 'Fiche Media' }
      let totalCustomers = { name: 'Clienti Totali' }
      let femaleCustomers = { name: 'Clienti Donna' }
      let maleCustomers = { name: 'Clienti Uomo' }
      let countTransactions = { name: 'Transazioni' }
      let averageProduct = { name: 'Fiche Media Prodotti' }
      let averageProductGoal = Number(categoryGoal.averageProductFicheGoal)
      let onCustomerProduct = { name: '% Rivendita Clienti' }
      let onCustomerProductGoal = 12

      //////////////////////////////
      //////////////////////////////
      //////////////////////////////

      let totalBarberOrBeauty = res.total.filter(f => f.categoryName == categoryName)
      for (let i = 1; i <= 12; i++) {
        let monthKey = this.monthMapping[i] + 'RevenueGoal'
        let totalGoal = Number(categoryGoal[monthKey])
        totalGoal = revenueTotalGoal / 100 * totalGoal

        let monthTotal = totalBarberOrBeauty.find(t => t.month == (i))
        let total = 0
        if (!!monthTotal)
          total = Number(monthTotal.total)

        let monthDebits = res.paymentDebits.filter(t => t.month == (i) && t.categoryName == categoryName)
        if(!!monthDebits)
          monthDebits.forEach(t => total -= Number(t.total))

        revenueObj[(i) + 'total'] = {
          goal: totalGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          total: total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          delta: (total - totalGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          onDelta: this.calculateOnDelta(total, totalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        revenueObj[(i) + 'sum'] = this.sumColor(total, totalGoal)

        //////////////////////////////

        let monthTransaction = 0
        if (!!monthTotal && monthTotal.countTransactions !== undefined && monthTotal.countTransactions !== null && monthTotal.countTransactions !== 0) {
          monthTransaction = total / Number(monthTotal.countTransactions)
        }
        averageTransaction[(i) + 'total'] = {
          goal: averageTransactionGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          total: (monthTransaction).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          delta: (monthTransaction - averageTransactionGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          onDelta: this.calculateOnDelta(monthTransaction, averageTransactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        averageTransaction[(i) + 'sum'] = this.sumColor(monthTransaction, averageTransactionGoal)

        //////////////////////////////

        let customersTotalGoal = Math.ceil(Number((totalGoal / averageTransactionGoal).toFixed(0)) * 0.5)
        let customersTotal = 0
        if (!!monthTotal && !!monthTotal.countCustomers) {
          customersTotal = Number(monthTotal.countCustomers)
        }

        totalCustomers[(i) + 'total'] = {
          goal: customersTotalGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          total: customersTotal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          delta: (customersTotal - customersTotalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          onDelta: this.calculateOnDelta(customersTotal, customersTotalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        totalCustomers[(i) + 'sum'] = this.sumColor(customersTotal, customersTotalGoal)

        //////////////////////////////

        let femaleCustomersTotalGoal = Math.ceil(customersTotalGoal * 0.8)
        let femaleCustomersTotal = 0
        if (!!monthTotal && !!monthTotal.countFemaleCustomers) {
          femaleCustomersTotal = Number(monthTotal.countFemaleCustomers)
        }

        femaleCustomers[(i) + 'total'] = {
          goal: femaleCustomersTotalGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          total: femaleCustomersTotal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          delta: (femaleCustomersTotal - femaleCustomersTotalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          onDelta: this.calculateOnDelta(femaleCustomersTotal, femaleCustomersTotalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        femaleCustomers[(i) + 'sum'] = this.sumColor(femaleCustomersTotal, femaleCustomersTotalGoal)

        //////////////////////////////

        let maleCustomersTotalGoal = Math.ceil(customersTotalGoal * 0.2)
        let maleCustomersTotal = 0
        if (!!monthTotal && !!monthTotal.countMaleCustomers) {
          maleCustomersTotal = Number(monthTotal.countMaleCustomers)
        }

        maleCustomers[(i) + 'total'] = {
          goal: maleCustomersTotalGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          total: maleCustomersTotal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          delta: (maleCustomersTotal - maleCustomersTotalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          onDelta: this.calculateOnDelta(maleCustomersTotal, maleCustomersTotalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        maleCustomers[(i) + 'sum'] = this.sumColor(maleCustomersTotal, maleCustomersTotalGoal)

        //////////////////////////////

        let countTransactionsGoal = !!averageTransactionGoal ? Number((totalGoal / averageTransactionGoal).toFixed(0)) : 0
        let countTransactionsTotal = 0
        if (!!monthTotal && !!monthTotal.countTransactions) {
          countTransactionsTotal = Number(monthTotal.countTransactions)
        }

        countTransactions[(i) + 'total'] = {
          goal: countTransactionsGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          total: countTransactionsTotal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          delta: (countTransactionsTotal - countTransactionsGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          onDelta: this.calculateOnDelta(countTransactionsTotal, countTransactionsGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        countTransactions[(i) + 'sum'] = this.sumColor(countTransactionsTotal, countTransactionsGoal)

        //////////////////////////////

        let monthProductTransaction = 0
        let monthProductTotalSubtractDebits = !!monthTotal && monthTotal.totalItems !== undefined && monthTotal.totalItems !== null ?
          Number(monthTotal.totalItems) : 0
        const monthDebitsProduct = monthDebits.filter(t => t.type == 'item')
        if (!!monthDebitsProduct && monthDebitsProduct.length > 0) {
          monthDebitsProduct.forEach(t => monthProductTotalSubtractDebits -= Number(t.total))
        }
        if (!!monthTotal && monthTotal.numberOfItems !== undefined && monthTotal.numberOfItems !== null && 
          Number(monthTotal.numberOfItems) !== 0 && monthProductTotalSubtractDebits !== undefined && monthProductTotalSubtractDebits !== null) {
            monthProductTransaction = Number(monthProductTotalSubtractDebits) / Number(monthTotal.numberOfItems)
        }
        averageProduct[(i) + 'total'] = {
          goal: averageProductGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          total: (monthProductTransaction).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          delta: (monthProductTransaction - averageProductGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          onDelta: this.calculateOnDelta(monthProductTransaction, averageProductGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        averageProduct[(i) + 'sum'] = this.sumColor(monthProductTransaction, averageProductGoal)

        //////////////////////////////

        let onCustomerProductTotal = 0
        if (!!monthTotal && monthTotal.countCustomers !== undefined && monthTotal.countCustomers !== null &&
          Number(monthTotal.countCustomers) !== 0 && !!monthTotal.countItemCustomers) {
            onCustomerProductTotal = Number(Number((Number(monthTotal.countItemCustomers) / Number(monthTotal.countCustomers)) * 100).toFixed(2))
        }

        onCustomerProduct[(i) + 'total'] = {
          goal: onCustomerProductGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%',
          total: onCustomerProductTotal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%',
          delta: (onCustomerProductTotal - onCustomerProductGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%',
          onDelta: this.calculateOnDelta(onCustomerProductTotal, onCustomerProductGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }
        onCustomerProduct[(i) + 'sum'] = this.sumColor(onCustomerProductTotal, onCustomerProductGoal)
      }

      //////////////////////////////
      //////////////////////////////
      //////////////////////////////

      let total = 0
      let countTransactionsTotal = 0
      let countCustomers = 0
      let countFemaleCustomers = 0
      let countMaleCustomers = 0
      let totalItems = 0
      let countItemTransactions = 0
      let countItemCustomers = 0
      let numberOfItems = 0
      for (let i = 1; i <= 12; i++) {
        let monthTotal = totalBarberOrBeauty.find(t => t.month == (i))
        let localTotal = 0
        if (!!monthTotal)
          localTotal = Number(monthTotal.total)
        total += localTotal

        let monthDebits = res.paymentDebits.filter(t => t.month == (i) && t.categoryName == categoryName)
        if(!!monthDebits && monthDebits.length > 0)
          monthDebits.forEach(t => total -= Number(t.total))

        if (!!monthTotal && !!monthTotal.countTransactions) 
          countTransactionsTotal += Number(monthTotal.countTransactions)

        if (!!monthTotal && !!monthTotal.countCustomers)
          countCustomers += Number(monthTotal.countCustomers)

        if (!!monthTotal && !!monthTotal.countFemaleCustomers)
          countFemaleCustomers += Number(monthTotal.countFemaleCustomers)

        if (!!monthTotal && !!monthTotal.countMaleCustomers)
          countMaleCustomers += Number(monthTotal.countMaleCustomers)

        if (!!monthTotal && !!monthTotal.totalItems)
          totalItems += Number(monthTotal.totalItems)

        if (!!monthTotal && !!monthTotal.numberOfItems)
          numberOfItems += Number(monthTotal.numberOfItems)

        if (!!monthTotal && !!monthTotal.countItemTransactions)
          countItemTransactions += Number(monthTotal.countItemTransactions)

        if (!!monthTotal && !!monthTotal.countItemCustomers)
          countItemCustomers += Number(monthTotal.countItemCustomers)
      }

      //////////////////////////////
      //////////////////////////////
      //////////////////////////////

      revenueObj['total'] = {
        goal: revenueTotalGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        total: total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        delta: (total - revenueTotalGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        onDelta: this.calculateOnDelta(total, revenueTotalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      revenueObj['sum'] = this.sumColor(total, revenueTotalGoal)

      //////////////////////////////

      let avg = 0
      if (!!countTransactionsTotal)
        avg = Number(total / countTransactionsTotal)

      averageTransaction['total'] = {
        goal: averageTransactionGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        total: (avg).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        delta: (avg - averageTransactionGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        onDelta: this.calculateOnDelta(avg, averageTransactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      averageTransaction['sum'] = this.sumColor(avg, averageTransactionGoal)

      //////////////////////////////

      let totalCustomerGoal = Math.ceil(Number((revenueTotalGoal / averageTransactionGoal).toFixed(0)) * 0.5)

      totalCustomers['total'] = {
        goal: (totalCustomerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        total: countCustomers.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        delta: (countCustomers - totalCustomerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        onDelta: this.calculateOnDelta(countCustomers, totalCustomerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      totalCustomers['sum'] = this.sumColor(countCustomers, totalCustomerGoal)

      //////////////////////////////

      femaleCustomers['total'] = {
        goal: (totalCustomerGoal * 0.8).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        total: countFemaleCustomers.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        delta: (countFemaleCustomers - (totalCustomerGoal * 0.8)).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        onDelta: this.calculateOnDelta(countFemaleCustomers, (countTransactionsTotal * 0.5 * 0.8)).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      femaleCustomers['sum'] = this.sumColor(countFemaleCustomers, (totalCustomerGoal * 0.8))

      //////////////////////////////

      maleCustomers['total'] = {
        goal: (totalCustomerGoal * 0.2).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        total: countMaleCustomers.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        delta: (countMaleCustomers - (totalCustomerGoal * 0.2)).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        onDelta: this.calculateOnDelta(countMaleCustomers, (totalCustomerGoal * 0.2)).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      maleCustomers['sum'] = this.sumColor(countMaleCustomers, (totalCustomerGoal * 0.2))

      //////////////////////////////

      let countTransactionGoal = 0
      if (!!averageTransactionGoal)
        countTransactionGoal = Number(revenueTotalGoal / averageTransactionGoal)

      countTransactions['total'] = {
        goal: countTransactionGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        total: countTransactionsTotal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        delta: (countTransactionsTotal - countTransactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        onDelta: this.calculateOnDelta(countTransactionsTotal, countTransactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      countTransactions['sum'] = this.sumColor(countTransactionsTotal, countTransactionGoal)

      //////////////////////////////

      let averageProductTotal = 0
      if (numberOfItems !== undefined && numberOfItems !== null && numberOfItems !== 0)
        averageProductTotal = Number(totalItems / numberOfItems)

      averageProduct['total'] = {
        goal: averageProductGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        total: averageProductTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        delta: (averageProductTotal - averageProductGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
        onDelta: this.calculateOnDelta(averageProductTotal, averageProductGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      averageProduct['sum'] = this.sumColor(averageProductTotal, averageProductGoal)

      //////////////////////////////

      let onCustomerProductTotal = 0
      if (!!countCustomers)
        onCustomerProductTotal = Number(countItemCustomers / countCustomers) * 100

      onCustomerProduct['total'] = {
        goal: (onCustomerProductGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%',
        total: onCustomerProductTotal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%',
        delta: (onCustomerProductTotal - onCustomerProductGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%',
        onDelta: this.calculateOnDelta(onCustomerProductTotal, onCustomerProductGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
      }
      onCustomerProduct['sum'] = this.sumColor(onCustomerProductTotal, onCustomerProductGoal)

      //////////////////////////////
      //////////////////////////////
      //////////////////////////////

      chartData.push(revenueObj)
      chartData.push(averageTransaction)
      chartData.push(totalCustomers)
      chartData.push(femaleCustomers)
      chartData.push(maleCustomers)
      chartData.push(countTransactions)
      chartData.push(averageProduct)

      let monthlyGoalRevenues = []
      let month = 1
      for (const [key, value] of Object.entries(revenueObj)) {
        if (key.includes('total')) {
          let regexGoal = value.goal.replaceAll('.', '').replaceAll(',', '.').replaceAll(/[^0-9\.-]+/g,"")
          const numberGoal = regexGoal !== undefined && regexGoal !== null && regexGoal !== '' ? Number(regexGoal) : 0
          monthlyGoalRevenues.push({
            month: month,
            goal: numberGoal
          })
          month++
        }
      }

      let monthlyGoalCustomers = []
      month = 1
      for (const [key, value] of Object.entries(totalCustomers)) {
        if (key.includes('total')) {
          let regexGoal = value.goal.replaceAll('.', '').replaceAll(',', '.').replaceAll(/[^0-9\.-]+/g,"")
          const numberGoal = regexGoal !== undefined && regexGoal !== null && regexGoal !== '' ? Number(regexGoal) : 0
          monthlyGoalCustomers.push({
            month: month,
            goal: numberGoal
          })
          month++
        }
      }

      let monthlyGoalTransactions = []
      month = 1
      for (const [key, value] of Object.entries(countTransactions)) {
        if (key.includes('total')) {
          let regexGoal = value.goal.replaceAll('.', '').replaceAll(',', '.').replaceAll(/[^0-9\.-]+/g,"")
          const numberGoal = regexGoal !== undefined && regexGoal !== null && regexGoal !== '' ? Number(regexGoal) : 0
          monthlyGoalTransactions.push({
            month: month,
            goal: numberGoal
          })
          month++
        }
      }
      return {
        'revenueObj': monthlyGoalRevenues,
        'totalCustomers': monthlyGoalCustomers,
        'countTransactions': monthlyGoalTransactions,
        'category': categoryName 
      }
    },
    calculateServiceTypes(goals, generalGoals, res, categoryName, serviceTypesCountData, serviceTypesTotalData, serviceTypesTransactionData){
      serviceTypesCountData.length = 0
      serviceTypesTotalData.length = 0
      serviceTypesTransactionData.length = 0

      let countTotalCustomers = generalGoals.totalCustomers.find((st) => {
        return st.month == 13
      }).goal
      let countTotalTransactions = generalGoals.countTransactions.find((st) => {
        return st.month == 13
      }).goal
      let totalTotal = generalGoals.revenueObj.find((st) => {
        return st.month == 13
      }).goal

      let goalsCategory = goals.filter(g => g.categoryName == categoryName)

      for(let goal of goalsCategory) {
        let goalCountObj = {
          name: goal.serviceTypeName
        }
        let goalTransactionObj = {
          name: goal.serviceTypeName
        }
        let goalTotalObj = {
          name: goal.serviceTypeName
        }

        let totalCustomers = 0
        let totalTransactions = 0
        let total = 0
        for(let i = 1; i <= 12; i++){
          let findedServiceType = res.serviceTypes.find((st) => {
            return st.name.toLowerCase() == goal.serviceTypeName.toLowerCase() && Number(st.month) == i
          })

          let monthlyGoalRevenues = generalGoals.revenueObj.find((st) => {
            return st.month == i
          }).goal

          let monthlyGoalTransactions = generalGoals.countTransactions.find((st) => {
            return st.month == i
          }).goal

          let monthlyGoalCustomers = generalGoals.totalCustomers.find((st) => {
            return st.month == i
          }).goal

          let countCustomers = monthlyGoalCustomers !== undefined && monthlyGoalCustomers !== null ? Number(monthlyGoalCustomers) : 0
          let countTransactions = monthlyGoalTransactions !== undefined && monthlyGoalTransactions !== null ? Number(monthlyGoalTransactions) : 0
          let localTotal = monthlyGoalRevenues !== undefined && monthlyGoalRevenues !== null ? Number(monthlyGoalRevenues) : 0

          let customerGoal = Math.floor(countCustomers / 100 * Number(goal.revenuePercentGoal))
          let transactionGoal = Math.floor(countTransactions / 100 * Number(goal.revenuePercentGoal))
          let totalGoal = Math.floor(localTotal / 100 * Number(goal.revenuePercentGoal))
          if(!!findedServiceType) {
            goalCountObj[(i + 'total')] = {
              goal: customerGoal,
              total: Number(findedServiceType.countCustomers).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              delta: (findedServiceType.countCustomers - customerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              onDelta: this.calculateOnDelta(findedServiceType.countCustomers, customerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
            }

            totalCustomers += Number(findedServiceType.countCustomers)

            goalCountObj[(i + 'sum')] = this.sumColor(findedServiceType.countCustomers, customerGoal)

            goalTransactionObj[(i + 'total')] = {
              goal: transactionGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              total: Number(findedServiceType.countTransactions).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              delta: (findedServiceType.countTransactions - transactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              onDelta: this.calculateOnDelta(findedServiceType.countTransactions, transactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
            }

            totalTransactions += Number(findedServiceType.countTransactions)

            goalTransactionObj[(i + 'sum')] = this.sumColor(findedServiceType.countTransactions, transactionGoal)

            goalTotalObj[(i + 'total')] = {
              goal: totalGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
              total: Number(findedServiceType.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
              delta: (findedServiceType.total - totalGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
              onDelta: this.calculateOnDelta(findedServiceType.total, totalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
            }

            total += Number(findedServiceType.total)

            goalTotalObj[(i + 'sum')] = this.sumColor(findedServiceType.total, totalGoal)
          } else {
            goalCountObj[(i + 'total')] = {
              goal: customerGoal,
              total: 0,
              delta: (0 - customerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              onDelta: this.calculateOnDelta(0, customerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
            }

            goalCountObj[(i + 'sum')] = this.sumColor(0, customerGoal)

            goalTransactionObj[(i + 'total')] = {
              goal: transactionGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              total: 0,
              delta: (0 - transactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
              onDelta: this.calculateOnDelta(0, transactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
            }

            goalTransactionObj[(i + 'sum')] = this.sumColor(0, transactionGoal)

            goalTotalObj[(i + 'total')] = {
              goal: totalGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
              total: 0,
              delta: (0 - totalGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
              onDelta: this.calculateOnDelta(0, totalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
            }

            goalTotalObj[(i + 'sum')] = this.sumColor(0, totalGoal)
          }
        }

        let customerGoal = Math.floor(countTotalCustomers / 100 * Number(goal.revenuePercentGoal))
        goalCountObj['total'] = {
          goal: customerGoal,
          total: totalCustomers.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          delta: (totalCustomers - customerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          onDelta: this.calculateOnDelta(totalCustomers, customerGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }

        goalCountObj['sum'] = this.sumColor(totalCustomers, customerGoal)

        serviceTypesCountData.push(goalCountObj)

        let transactionGoal = Math.floor(countTotalTransactions / 100 * Number(goal.revenuePercentGoal))
        goalTransactionObj['total'] = {
          goal: transactionGoal.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          total: totalTransactions.toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          delta: (totalTransactions - transactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
          onDelta: this.calculateOnDelta(totalTransactions, transactionGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }

        goalTransactionObj['sum'] = this.sumColor(totalTransactions, transactionGoal)

        serviceTypesTransactionData.push(goalTransactionObj)

        let totalGoal = Math.floor(totalTotal / 100 * Number(goal.revenuePercentGoal))
        goalTotalObj['total'] = {
          goal: totalGoal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          total: total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          delta: (total - totalGoal).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          onDelta: this.calculateOnDelta(total, totalGoal).toLocaleString('it-IT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
        }

        goalTotalObj['sum'] = this.sumColor(total, totalGoal)

        serviceTypesTotalData.push(goalTotalObj)
      }
    },
    sumColor(total, goal) {
      let delta = this.calculateOnDelta(total, goal)
      return delta > 0 ? 'success' : (delta > -20 ? 'warning' : 'error')
    },
    calculateOnDelta(total, goal) {
      if (!Number(goal)) return 0
      total = Number(total)
      goal = Number(goal)
      return (total - goal) * 100 / goal
    },
    getServiceTypes(x, type) {
      switch (type) {
        case 'transaction':
          return this.serviceTypesByTransactions.data.find(st => st.x == x)
        case 'customer':
          return this.serviceTypesByCustomers.data.find(st => st.x == x)
      }
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost
          this.calculate()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    print() {
      let id = 'content-to-print'
      this.printLoading = true

      let today = new Date()
      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Analisi_Obbiettivi_"
          + this.selectedYear + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error) => {
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
  }
}
</script>

<style></style>