var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.items),function(item){return _c('div',{key:item[_vm.itemKey],staticClass:"item-container",class:{
      selected: _vm.selectable && !!_vm.localValue && item[_vm.itemKey] == _vm.localValue[_vm.itemKey],
      disabled: _vm.isDisabled(item)
    },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleItemContainerClick(item)}}},[_c('div',{staticClass:"d-flex align-center item-title-container justify-start py-2"},[(_vm.iconVisible)?_c('div',{staticClass:"item-icon"},[_c('v-icon',[_vm._v(_vm._s(item[_vm.itemIcon]))])],1):_vm._e(),_c('div',{staticClass:"pl-2"},[_vm._t("item-title",function(){return [_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(item[_vm.itemTitle])+" ")]),_c('div',{staticClass:"item-subtitle text-caption"},[_vm._v(" "+_vm._s(item[_vm.itemSubtitle])+" ")])]},null,{item})],2),(_vm.expandible)?_c('div',{staticClass:"item-collapser d-flex"},[_c('v-icon',{staticClass:"collapser-icon",class:{
            expanded: !!_vm.localExpanded && item[_vm.itemKey] == _vm.localExpanded[_vm.itemKey],
            collapsed: !_vm.localExpanded || item[_vm.itemKey] != _vm.localExpanded[_vm.itemKey],
          }},[_vm._v(_vm._s(_vm.collapserIcon))])],1):_vm._e(),_vm._t("extra-icons",null,null,{item})],2),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.localExpanded && item[_vm.itemKey] == _vm.localExpanded[_vm.itemKey]),expression:"!!localExpanded && item[itemKey] == localExpanded[itemKey]"}],staticClass:"expansion pl-2"},[_vm._t("expansion",null,null,{item})],2)]),(_vm.divided)?_c('v-divider'):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }