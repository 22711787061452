<template>
  <StandardDialog
    v-model="localValue"
    without-padding
    :title="completeTitle"
    :dialog-width="null"
    :dialog-height="dialogHeight"
    :dialog-max-width="dialogMaxWidth"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <div>
        <v-btn :disabled="loading || (!!customerSelected && customerSelected.length == 0)" text @click="sendMassive()">
          <v-icon class="mr-2">mdi-message-text-fast-outline</v-icon> Invia Messaggio ai Selezionati
        </v-btn>
        <v-btn :disabled="loading || customers.length == 0" text @click="sendMassiveAll()">
          <v-icon class="mr-2">mdi-message-text-fast-outline</v-icon> Invia Messaggio a Tutti
        </v-btn>
      </div>
    </template>
    <div
      style="width: 99%;"
    >
      <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Descrizione: {{ filter.value }}
          </template>

          <template v-slot:chip-custom-sendByWhatsapp="{filter}">
            Descrizione: {{ filter.value }}
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%; overflow-y: hidden">
        <TypeDataTable
          v-model="customerSelected"
          loading-text="Caricamento clienti"
          :headers="headers"
          :loading="loading || loadingHeaders"
          :translator="translator"
          :items="paginatedCustomers"
          :item-key="itemKey"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          actions-width="80px"
          :single-select="false"
          :show-actions="false"
        >
          <template v-slot:custom-sendByWhatsapp="{ item }">
            <v-icon>{{item.sendByWhatsapp == 1 ? 'mdi-whatsapp' : 'mdi-message-fast'}}</v-icon>
          </template>
        </TypeDataTable>
      </div>
      
      <StandardDialog
        v-model="sendSmsDialog"
        :dialog-height="null"
        :title="!!customersSms ? 'Invia Messaggio a ' + customersSms.length + ' clienti'
          : 'Invio Messaggio'"
        dialog-max-width="50%"
      >
        <SendPromoSmsModel
          :with-list="true"
          :send-all="sendAll"
          :customers="customersSms"
          :filters="filtersValue"
          alert-text="Stai per fare un invio massivo di messaggi. Sei sicuro di volero fare?"
          @close="handleSmsClose"
          :send-sms-model-method="sendSmsModel"
        >
        </SendPromoSmsModel>
        <template v-slot:footer>
          <v-spacer></v-spacer>
            <v-btn 
              text 
              color="error" 
              @click="sendSmsDialog = false" 
            >Annulla</v-btn>
        </template>
      </StandardDialog>
    </div>
  </StandardDialog>
</template>

<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import SendPromoSmsModel from "@/components/areas/marketing/SendPromoSmsModel.vue";
import InterestsAutocomplete from '@/components/common/InterestsAutocomplete.vue'
import StandardDialog from '@/components/common/StandardDialog.vue';

export default {
  name: "SendMassiveSms",
  components: {
    TypeDataTable,
    DateTimeFormattor,
    AdvancedFilter,
    FullScreenDialog,
    SendPromoSmsModel,
    StandardDialog,
    InterestsAutocomplete
  },
  data: function () {
    return {
      localValue: this.value,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      headers: [
        { text: 'Cognome', value: 'lastname' },
        { text: 'Nome', value: 'firstname' },
        { text: 'Cellulare', value: 'cellphone' },
        { text: 'Tipologia SMS', value: 'sendByWhatsapp', type: 'custom' },
      ],
      advanceFilters: [
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'customer', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      customerSelected: [],
      loadingHeaders: false,
      paginatedCustomers: [],
      sendSmsDialog: false,
      loading: false,
      page: 1,
      rowPerPage: 200,
      totalPages: 0,
      sendAll: false,
      customersSms: [],
      filtersSms: []
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    startTitle: {
      type: String,
      default: "Invio Sms a "
    },
    endTitle: {
      type: String,
      default: " clienti"
    },
    routeFather: {
      type: String,
      default: ''
    },
    customers: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: "id",
    },
    filterTypes: {
      type: Array,
      default: () => [
        { type: 'string', operator: 'equal', field: 'cellphone', name: 'Cellulare', label: 'Cellulare', color: "", value: undefined, icon: "mdi-cellphone" },
        { type: 'boolean', operator: 'equal', field: 'sendByWhatsapp', name: 'Invio messaggi tramite Whatsapp', label: 'Invio messaggi tramite Whatsapp', color: "", value: false, icon: "mdi-whatsapp" },
      ],
    },
    sendSmsModel: {
      type: Function,
      default: undefined
    },
    dialogMaxWidth: {
      type: String,
      default: '75%'
    },
    dialogHeight: {
      type: String,
      default: '100%'
    }
  },
  mounted: function () {
  },
  methods: {
    calculatePaginatedCustomers() {
      if (!this.customers || this.customers.length == 0) {
        this.paginatedCustomers = []
      }

      this.totalPages = Math.ceil(this.customers.length / this.rowPerPage)
      
      let offset = (this.page - 1) * this.rowPerPage
      this.paginatedCustomers = this.customers.slice(offset, (this.rowPerPage * this.page))
    },
    applyFilters(filters) {
      this.loading = true
      this.$emit('fetch-customers', filters)
    },
    translator(fieldName, fieldValue) {
      if (fieldName == "job" && !!fieldValue) {
        return fieldValue["name"];
      }
      if (fieldName == "gender" && !!fieldValue) {
        return this.$translations.t("enums.customers.gender." + fieldValue);
      }
      if (fieldName == "hairColor" && !!fieldValue) {
        return fieldValue["name"];
      }
      if (fieldName == "hairType" && !!fieldValue) {
        return fieldValue["name"];
      }
    },
    sendMassive(){
      this.customersSms = this.customerSelected.map(c => c.id)
      this.sendAll = false
      this.filtersSms = undefined
      this.sendSmsDialog = true
    },
    sendMassiveAll(){
      this.customersSms = this.customers.map(c => c.id)
      this.sendAll = false
      this.filtersSms = undefined
      this.sendSmsDialog = true
    },
    handleSmsClose(){
      this.sendSmsDialog = false
      this.customerSelected = undefined
      this.customersSms = undefined
      this.sendAll = false
    }
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
    computedCustomers() {
      if (!this.customers || this.customers.length == 0)
        return []
      else 
        return this.customers
    },
    completeTitle() {
      if (!!this.customerSelected)
        return this.startTitle + this.customerSelected.length + this.endTitle
      else
        return "Invio sms"
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
      if (!!newVal) {
        this.loading = true
        this.$emit('fetch-customers')
      }
      else {
        this.page = 1
        this.rowPerPage = 200
        this.totalPages = 0
        this.filtersValue = []
        this.externalFilter['value'] = undefined
        this.paginatedCustomers = []
        this.customerSelected = []
      }
    },
    localValue(newVal) {
      if (newVal != this.value) {
        this.$emit("input", newVal);
      }
    },
    customers() {
      this.calculatePaginatedCustomers()
      this.loading = false
    },
    page() {
      this.calculatePaginatedCustomers()
    },
    rowPerPage() {
      this.calculatePaginatedCustomers()
    },
  },
};
</script>


<style>
.custom-blue-switch .v-input--selection-controls__input div {
  color: blue !important;
  caret-color: blue !important;
}
.custom-orange-switch .v-input--selection-controls__input div {
  color: orange !important;
  caret-color: orange !important;
}
</style>