import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import calendarCache from "./calendar.cache";
import socketService from '../socket/socket.service'
import EventEmitter from "events";

class calendarManagment extends EventEmitter {

  constructor() {
    super()
    this.filtersCached = []
    this.alreadyLoaded = false

    socketService.on('calendar:createAppointment', async (data) => {
      if (!!data.startDate) {
        let date = new Date(data.startDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:createEvent', async (data) => {
      if (!!data.event && !!data.event.startDate) {
        let date = new Date(data.event.startDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:deleteEvent', async (data) => {
      if (!!data.event.startDate) {
        let date = new Date(data.event.startDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:archiveEvent', async (data) => {
      if (!!data.event && !!data.event.startDate) {
        let date = new Date(data.event.startDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:updateEvent', async (data) => {
      if (!!data.event.startDate) {
        let date = new Date(data.event.startDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:updateEventGroup', async (data) => {
      if (!!data.event.startDate) {
        let date = new Date(data.event.startDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:moveDate', async (data) => {
      if (!!data.originalDate) {
        let date = new Date(data.originalDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
      if (!!data.newDate) {
        let date = new Date(data.newDate)
        await this.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })
  }

  get socketConnected() {
    return !!socketService && socketService.connected
  }

  async cachedEventsList({ start, end, filters }) {
    if (!this.alreadyLoaded) {
      await calendarCache.deleteAllCache()
      this.alreadyLoaded = true
    }


    let differenceInTime = new Date(end).getTime() - new Date(start).getTime()
    let differenceInDays = differenceInTime / (1000 * 3600 * 24)

    let results = []
    for (let i = 0; i <= differenceInDays; i += 1) {
      const d = new Date(new Date(start).getTime() + (i * (1000 * 3600 * 24)))
      const month = d.getMonth() + 1
      const date = d.getDate()
      const timestamp = d.getFullYear().toString() + (month < 9 ? '0' + month : month).toString() + (date < 9 ? '0' + date : date).toString()

      if (await calendarCache.cachePresent({ day: timestamp, filters: filters })) {
        results = results.concat(await calendarCache.getDay({
          day: timestamp,
          filters: filters
        }))
      } else {
        const startFilter = new Date(d)
        startFilter.setHours(0, 0, 0, 0)
        const endFilter = new Date(d)
        endFilter.setHours(23, 59, 59, 999)

        const events = await this.eventsList({
          start: startFilter,
          end: endFilter,
          filters
        })

        if (!!filters) this.filtersCached.push(filters)
        await calendarCache.cacheDay({
          day: timestamp,
          filters: filters,
          events: events
        })

        results = results.concat(events)
      }
    }

    return results
  }

  async reloadDayCache({ date }) {
    if (!date) throw new Error('date should be provided')
    const startFilter = new Date(date)
    startFilter.setHours(0, 0, 0, 0)
    const endFilter = new Date(date)
    endFilter.setHours(23, 59, 59, 999)

    const events = await this.eventsList({
      start: startFilter,
      end: endFilter,
    })

    const month = new Date(date).getMonth() + 1
    const day = new Date(date).getDate()
    const timestamp = new Date(date).getFullYear().toString() + (month < 9 ? '0' + month : month).toString() + (day < 9 ? '0' + day : day).toString()

    await calendarCache.cacheDay({
      day: timestamp,
      events: events
    })

    for (let i = 0; i < this.filtersCached.length; i += 1) {
      const events = await this.eventsList({
        start: startFilter,
        end: endFilter,
        filters: this.filtersCached[i]
      })

      await calendarCache.cacheDay({
        day: timestamp,
        events: events,
        filters: this.filtersCached[i]
      })
    }
  }

  /**
   * @param {Object} param - params to load events
   * @param {Date} param.start - start date
   * @param {Date} param.end - end date
   * @param {Object} param.filters - filters to send
   */
  async eventsList({ start, end, filters }) {
    const apiUrl = UrlKeeper.getUrl()

    let response = await axios.post(apiUrl + '/events/list', {
      start: start,
      end: end,
      filters: filters
    })

    if (response.success) {
      return response.results
    } else {
      throw response.results
    }
  }

  createEvent(event) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/events/create', event).then((responseFromCreate) => {
        if (responseFromCreate.success) {
          if (event.invitees) {
            this.addInvitees(responseFromCreate.results, event.invitees).then((responseFromInvitees) => {
              resolve(responseFromInvitees.results)
            })
          } else {
            resolve(responseFromCreate.results)
          }
        } else {
          reject('error during the call')
        }
      })
    })
  }

  createCustomEvent(event) {
    event['custom'] = true
    return this.createEvent(event)
  }

  /**
  * @param {Object} params - Appointment params
  * @param {Object} params.group - General information about the appointment
  * @param {String} params.group.startDate - Initial date of the appointment
  * @param {Boolean} params.group.highPriority - Hign priority boolean
  * @param {String} params.group.color - Group color to set (currently not used)
  * @param {Object} params.group.customer - Customer to set to the event group
  * @param {Number} params.group.customer.id - Customer id
  * @param {Object[]} params.eventServices - Event services to add to the group
  * @param {Object} params.eventServices[].service - Service of the event service
  * @param {Number} params.eventServices[].service.id - Service id
  * @param {Object[]} params.eventServices[].operators - Operators of the event service
  * @param {Number} params.eventServices[].operators[].id - Operator id
  * @param {Date} params.eventServices[].startDate - Start date
  * @param {Date} params.eventServices[].endDate - End date
  * @param {Boolean} params.eventServices[].operatorsLocked - The operators are locked
  * @returns 
  */
  async createAppointment(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!params.group) {
        reject(new Error('group is not specified'))
        return
      }

      if (!params.eventServices || !Array.isArray(params.eventServices) || params.eventServices.length == 0) {
        reject(new Error('event services is not specified or not right form'))
        return
      }

      axios.post(apiUrl + '/events/createAppointment', params).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async createEventAndAggregate(event, masterEvent, avoidCacheReload = false) {
    const apiUrl = UrlKeeper.getUrl()
    if (!masterEvent || !masterEvent.id) {
      throw new Error('event to aggregate not specified')
    }

    const responseFromCreate = await axios.post(apiUrl + '/events/createAndAggregate', { event: event, masterEvent: masterEvent })
    if (responseFromCreate.success) {
      if (event.invitees) {
        await this.addInvitees(responseFromCreate.results, event.invitees)
      }

      if (!avoidCacheReload) {
        await this.reloadDayCache({ date: event.startDate })
      }

      return responseFromCreate.results
    } else {
      throw responseFromCreate.results
    }
  }

  async createEventAndGroup(event, group, avoidCacheReload) {
    const apiUrl = UrlKeeper.getUrl()
    if (!group || !group.id) {
      throw new Error('event group not specified')
    }

    const responseFromCreate = await axios.post(apiUrl + '/events/createAndGroup', { event: event, group: group })
    if (responseFromCreate.success) {
      if (event.invitees) {
        await this.addInvitees(responseFromCreate.results, event.invitees)
      }

      if (!avoidCacheReload) {
        await this.reloadDayCache({ date: event.startDate })
      }

      return responseFromCreate.results
    } else {
      throw responseFromCreate.results
    }
  }

  archiveEvent(event) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!event['id']) {
        reject('id undefined')
        return
      }

      axios.get(apiUrl + '/events/archive', { id: event['id'] }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject('error during the call')
        }
      })
    })
  }

  deleteEvent(event) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!event['id']) {
        reject('id undefined')
        return
      }

      axios.post(apiUrl + '/events/delete', { id: event['id'] }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject('error during the call')
        }
      })
    })
  }

  async updateEvent(event) {
    const apiUrl = UrlKeeper.getUrl()

    event.createdAt = undefined
    event.archived = undefined

    const response = await axios.post(apiUrl + '/events/update', event)

    if (response.success) {
      if (!!event.startDate) {
        await this.reloadDayCache({ date: event.startDate })
      }

      return response.results
    } else {
      throw response.results
    }
  }

  async updateMultipleEvents(events) {
    const apiUrl = UrlKeeper.getUrl()
    console.log(events)

    const response = await axios.post(apiUrl + '/events/updateMultipleEvents', { events: events })

    if (response.success) {
      if (!!event.startDate) {
        await this.reloadDayCache({ date: event.startDate })
      }

      return response.results
    } else {
      throw response.results
    }
  }

  async setExtraordinaryClosure(closures) {
    const apiUrl = UrlKeeper.getUrl()

    const response = await axios.post(apiUrl + '/extraordinaryClosure/set', { closures: closures })

    if (response.success) {
      return response.results
    } else {
      throw response.results
    }
  }

  async getExtraordinaryClosure(filters) {
    const apiUrl = UrlKeeper.getUrl()

    const response = await axios.post(apiUrl + '/extraordinaryClosure/list', { filters })

    if (response.success) {
      return response.results
    } else {
      throw response.results
    }
  }

  getInvitees(event) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/events/invitees/' + event.id + '/list').then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject('error during the call')
        }
      })
    })
  }

  addInvitees(event, operators) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      let operatorIds = operators.map((element) => {
        return element.id
      })

      axios.get(apiUrl + '/events/invitees/' + event.id + '/invite', {
        operatorIds: operatorIds
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject('error during the call')
        }
      })
    })
  }

  cancelInvitee(event, operator) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/events/invitees/' + event.id + '/cancel', {
        operatorId: operator.id
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject('error during the call')
        }
      })
    })
  }

  calendarsList() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/calendars/list').then((response) => {
        if (response.success) {
          resolve(response.results.rows)
        } else {
          reject('error during the call')
        }
      })
    })
  }

  duplicateEvent(eventGroups, params) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/events/' + eventGroups[0].id + '/duplicateEvent', {
        ruleType: params.ruleType,
        startDate: params.startDate,
        endDate: params.endDate,
        daysOfWeek: params.daysOfWeek,
        daysOfMonth: params.daysOfMonth,
        operatorChoice: params.operatorChoice,
        calendarType: params.calendarType,
      }).then((responseFromCreate) => {
        if (responseFromCreate.success) {
          resolve(responseFromCreate.results)
        } else {
          reject('error during the call')
        }
      })
    })
  }

  analyticsPrevisional(date) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/calendar/analytics/previsional', { date }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  analyticsCustomer(customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/calendar/analytics/customer', { customer }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  customerAnalyticsFields() {
    return Promise.resolve([
      { text: 'Servizio', value: 'name' },
      { text: 'Descrizione', value: 'description' },
      { text: 'Operatore', value: 'operator', type: 'custom' },
      { text: 'Fatto il', value: 'startDate', type: 'datetime' }
    ])
  }
}

export default new calendarManagment();
