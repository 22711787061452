var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-checkbox',{staticClass:"mb-5",staticStyle:{"width":"fit-content"},on:{"change":function($event){return _vm.$emit('input', _vm.localValue)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"title-section-receipt"},[_vm._v(" INSERISCI DATI AZIENDA ")])]},proxy:true}]),model:{value:(_vm.factoryData),callback:function ($$v) {_vm.factoryData=$$v},expression:"factoryData"}}),_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"title-section-receipt"},[_vm._v("TITOLO")]),_c('v-text-field',{staticClass:"mt-1",attrs:{"filled":"","dense":"","hide-details":"auto","placeholder":"Inserisci titolo","rules":[_vm.max46CharRule, _vm.presenceRule]},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"title-section-receipt"},[_vm._v("DESCRIZIONE")]),_c('v-textarea',{staticClass:"mt-1",attrs:{"filled":"","dense":"","hide-details":"auto","placeholder":"Inserisci descrizione","rules":[_vm.presenceRule]},on:{"input":() => {
        _vm.descriptionSplitted = _vm.handleTextData(_vm.description)
        _vm.$emit('input', _vm.localValue)
      }},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('div',[(!!_vm.localType && _vm.localType == 'barcode')?_c('span',{staticClass:"title-section-receipt"},[_vm._v(" BARCODE ")]):_vm._e(),(!!_vm.localType && _vm.localType == 'barcode')?_c('v-img',{staticClass:"mt-1",staticStyle:{"width":"50%","margin-left":"-1vw"},attrs:{"src":require("@/assets/code-39.png")}}):_vm._e(),(!!_vm.localType && _vm.localType == 'qrcode')?_c('span',{staticClass:"title-section-receipt"},[_vm._v(" QRCODE ")]):_vm._e(),(!!_vm.localType && _vm.localType == 'qrcode')?_c('v-img',{staticClass:"mt-1",staticStyle:{"width":"20%"},attrs:{"src":require("@/assets/qr-code.png")}}):_vm._e()],1),(!!_vm.localType && _vm.localType == 'qrcode')?_c('v-text-field',{staticClass:"mt-2 mb-5",attrs:{"filled":"","dense":"","hide-details":"auto","label":"Contenuto","rules":[_vm.presenceRule]},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},model:{value:(_vm.qrcode),callback:function ($$v) {_vm.qrcode=$$v},expression:"qrcode"}}):_vm._e(),(!!_vm.localType && _vm.localType == 'barcode')?_c('v-select',{staticClass:"mt-2 mb-5",attrs:{"label":"Seleziona Promozione","items":_vm.promoWithBarcode,"return-object":"","item-text":"name","item-value":"id","filled":"","dense":"","rules":[_vm.presenceRule],"hide-details":"auto"},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)}},model:{value:(_vm.selectedPromo),callback:function ($$v) {_vm.selectedPromo=$$v},expression:"selectedPromo"}}):_vm._e(),_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"title-section-receipt"},[_vm._v("ALTRO TESTO")]),_c('v-textarea',{staticClass:"mt-1",attrs:{"filled":"","dense":"","hide-details":"auto","placeholder":"Specifiche e ringraziamenti"},on:{"input":() => {
        _vm.additionalTextSplitted = _vm.handleTextData(_vm.additionalText)
        _vm.$emit('input', _vm.localValue)
      }},model:{value:(_vm.additionalText),callback:function ($$v) {_vm.additionalText=$$v},expression:"additionalText"}})],1),_c('v-checkbox',{staticClass:"mb-5",staticStyle:{"width":"fit-content"},on:{"change":function($event){return _vm.$emit('input', _vm.localValue)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"title-section-receipt"},[_vm._v(" INSERISCI AUTOMATICAMENTE NOME OPERATORE NEI RINGRAZIAMENTI ")])]},proxy:true}]),model:{value:(_vm.cashDeskOperatorThanks),callback:function ($$v) {_vm.cashDeskOperatorThanks=$$v},expression:"cashDeskOperatorThanks"}}),_c('span',{staticClass:"title-section-receipt"},[_vm._v(" DATA, ORA, NUMERO DOCUMENTO E CASSA SONO DATI OBBLIGATORI ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }