<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
    :persistent="persistent"
  >
    <v-card>
      <v-card-title class="text-h5" v-if="!!title">
        {{title}}
      </v-card-title>

      <v-card-text v-if="!!message">
        {{message}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="error"
          @click="dialog = false"
        >
          Chiudi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warningDialogDelegate from '@/delegates/warningDialog.delegate'

export default {
  name: "WarningDialog",
  data: function() {
    return {
      dialog: false,
      title: undefined,
      message: undefined,
      persistent: false,
    }
  },
  mounted: function() {
    let handler = ({ message, timeout }) => {
      this.message = message
      if(!!timeout) {
        this.timeout = timeout
      } else {
        this.timeout = undefined
      }
      this.snackbar = true
    }
    handler = handler.bind(this)
    warningDialogDelegate.on('call', handler)
  }
}
</script>

<style>

</style>