import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import cubeService from "@/services/cube/cube.service.js"
import DateHandler from "@/services/common/dateHandler";

class BalanceService {
  constructor() {

  }

  calculateByTags(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/calculateByTags', {
        from: options.from,
        to: options.to,
        tagCategoriesId: options.tagCategoriesId,
        ghost: options.ghost
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  financialByTags(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/financialByTags', {
        from: options.from,
        to: options.to
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  operatorByTags(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/operatorByTags', {
        from: options.from,
        to: options.to,
        operatorId: options.operatorId,
        ghost: options.ghost
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  totalOperators(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/totalOperators', {
        ...options
      }).then((response) => {
        if (response.success)
          resolve(response.results)
        else
          reject(response.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /* 
    options: {
      periods: [
        ...
        {
          from: Date (from date)
          to: Date (to date)
        }
      ],
      measures: [String] (measure name)
      granularity: 'day' | 'week' | 'month' | 'quarter',
    }
  */
  async compareByTags(options) {
    let queries = []

    if (!options)
      throw new Error('empty options')
    else if (!options.periods || !Array.isArray(options.periods) || options.periods.length == 0)
      throw new Error('empty periods')
    else if (!options.measures || !Array.isArray(options.measures) || options.measures.length == 0)
      throw new Error('no measures')
    else if (!options.granularity)
      throw new Error('no granularity')

    for (let i = 0; i < options.periods.length; i += 1) {
      let query = {
        "measures": options.measures.map((el) => `Balance.${el}`),
        "timeDimensions": [
          {
            "dimension": "Balance.billDate",
            "granularity": options.granularity,
            "dateRange": [
              new Date(options.periods[i].from),
              new Date(options.periods[i].to)
            ]
          }
        ],
        "order": {
          "Balance.billDate": "asc"
        },
        "dimensions": [
        ]
      }

      queries.push(query)
    }

    return await Promise.all(queries.map((query) => {
      return cubeService.load(query)
    }))
  }

  /** 
    * @param {Object} options - options used to parametrize the query  
    * @param {Date} options.from - initial period date
    * @param {Date} options.to - finish period date
    * @param {Date} options.since - date for newCustomers
    * @param {Object[]} options.serviceTypes - array containing the ids of various serviceTypes
  */
  async fidelityInfoAndTotalCustomers(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/fidelityInfoAndTotalCustomers', {
        from: options.from,
        to: options.to,
        since: options.since,
        serviceTypes: options.serviceTypes,
        ghost: options.ghost
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /** 
  * @param {Object} options - options used to parametrize the query  
  * @param {Date} options.from - initial period date
  * @param {Date} options.to - finish period date
  * @param {Date} options.category - specify the category of the customer
*/
  async fidelityCustomersDetail(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/fidelityCustomersDetail', {
        from: options.from,
        to: options.to,
        category: options.category,
        ghost: options.ghost,
        filters: options.filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /** 
    * @param {Object} options - options used to parametrize the query  
    * @param {Date} options.from - initial period date
    * @param {Date} options.to - finish period date
    * @param {Date} options.category - specify the category of the customer
  */
  async fidelityActiveCustomersDetail(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/fidelityActiveCustomersDetail', {
        from: options.from,
        to: options.to,
        category: options.category,
        ghost: options.ghost,
        filters: options.filters,
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async fidelityInactiveCustomersDetail(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/fidelityInactiveCustomersDetail', {
        from: options.from,
        to: options.to,
        category: options.category,
        ghost: options.ghost,
        filters: options.filters,
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async fidelityActiveCustomersDetailDetail(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/fidelityActiveCustomersDetailDetail', {
        from: options.from,
        to: options.to,
        category: options.category,
        ghost: options.ghost,
        filters: options.filters,
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async fidelityActiveAndNewCustomers(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/fidelityActiveAndNewCustomers', {
        from: options.from,
        to: options.to,
        ghost: options.ghost
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
        }).catch((err) => {
          reject(err)
        })
    })
  }


  /** 
* @param {Object} options - options used to parametrize the query  
* @param {Date} options.from - initial period date
* @param {Date} options.to - finish period date
* @param {Date} options.since - date for newCustomers
* @param {Object[]} options.serviceTypes - array containing the ids of various serviceTypes
*/
  async frequentServiceTypeActiveCustomers(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/frequentServiceTypeActiveCustomers', {
        from: options.from,
        to: options.to,
        category: options.category,
        ghost: options.ghost
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /** 
  * @param {Object} options - options used to parametrize the query  
  * @param {Date} options.from - initial period date
  * @param {Date} options.to - finish period date
  * @param {number[]} options.tagCategories - specify the category of the customer
  * @param {Boolean} options.ghost - 
  * @param {Boolean} options.showInactiveCustomers -
  * @param {Boolean} options.showOnlyNewCustomers -
*/
  async turnoverBalance(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/turnoverBalance', {
        from: options.from,
        to: options.to,
        tagCategories: options.tagCategories,
        ghost: options.ghost,
        showInactiveCustomers: options.showInactiveCustomers,
        showOnlyNewCustomers: options.showOnlyNewCustomers
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /**
   * @param {Object} options - options used to parametrize the query
   * @param {Date} options.from - initial period date
   * @param {Date} options.to - finish period date
   * @param {number[]} options.tagCategories - specify the category of the customer
   * @param {Boolean} options.ghost -
   * @param {Number} options.customerId - the id of the customer
   * @param {Number} options.month - the month to filter
   **/
  async detailTurnoverBalance(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/detailTurnoverBalance', {
        from: options.from,
        to: options.to,
        tagCategories: options.tagCategories,
        ghost: options.ghost,
        customerId: options.customerId,
        month: options.month,
        year: options.year
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async homepageBalance(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/homepageBalance', {
        from: options.from,
        to: options.to,
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async serviceConsume(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/serviceConsume', {
        from: options.from,
        to: options.to,
        ghost: options.ghost
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async warehouseStatus(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/warehouseStatus', {}).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async bistrotBalance(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/bistrotBalance', {
        from: options.from,
        to: options.to,
        ghost: options.ghost
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async balanceByYear(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/balanceByYear', {
        ...options
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async balanceByYearOperator(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/balanceByYearOperator', {
        ...options
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async deleteCacheBalance() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/deleteBalanceCache')
        .then((response) => {
          if (response.success) {
            resolve(response.success)
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err)
        })
    })
  }

  async serviceTypesBalance(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/serviceTypesBalance', {
        ...options
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async getTimeRange(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/timeRange', {
        ...options
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  async goalsBalance(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/goalsBalance', {
        ...options
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /** 
   * @param {Object} options - options used to parametrize the query  
   * @param {Date} options.from - initial period date
   * @param {Date} options.to - finish period date
   * @param {Object[]} options.customerIds - array containing the ids of customers
 */
  async customersFrequency(options, externalFilters) {
    if (!options.from || !options.to)
      throw new Error('empty period')

    let filters = []

    if (!!externalFilters) {
      for (let externalFilter of externalFilters) {
        if (externalFilter["field"] == 'customer') {
          filters.push({
            or: [
              {
                member: "Balance.customerFullName",
                operator: "contains",
                values: [externalFilter["value"]]
              },
              {
                member: "Balance.customerReverseFullName",
                operator: "contains",
                values: [externalFilter["value"]]
              },
            ]
          })
        }

        if (externalFilter["field"] == 'sendByWhatsapp') {
          filters.push({
            member: "Balance.customerSendByWhatsapp",
            operator: "equals",
            values: [Number(externalFilter["value"]).toString()]
          })
        }

        if (externalFilter["field"] == 'cellphone') {
          filters.push({
            member: "Balance.customerCellphone",
            operator: "equals",
            values: [externalFilter["value"]]
          })
        }
      }
    }

    if (!!options.customerIds) {
      filters.push({
        member: "Balance.customerId",
        operator: "equals",
        values: options.customerIds.map(el => el.toString()) // options.customerIds.map(el => Number(el.toString()))
      })
    }

    if (!!options.ageGroup) {
      filters.push({
        member: "Balance.customerAgeGroup",
        operator: "equals",
        values: [options.ageGroup]
      })
    }

    if (!options.ghost || options.ghost == undefined) {
      filters.push({
        and: [
          {
            member: "Balance.ghostPaid",
            operator: "equals",
            values: ['0']
          },
          {
            or: [
              {
                member: "Balance.cardPaid",
                operator: "equals",
                values: ['0']
              },
              {
                member: "Balance.customerCardGhost",
                operator: "equals",
                values: ['0']
              },
            ]
          }
        ]
      })
    }

    let query = {
      "measures": [
        "Balance.billNumber",
      ],
      "timeDimensions": [
        {
          "dimension": "Balance.billDate",
          "granularity": undefined,
          "dateRange": [
            this.convertDateToString(new Date(options.from)),
            this.convertDateToString(new Date(options.to))
          ]
        }
      ],
      "order": [
        [
          "Balance.billNumber",
          "desc"
        ]
      ],
      "dimensions": [
        "Balance.customerId",
        "Balance.customerLastName",
        "Balance.customerFirstName",
        "Balance.customerGender",
        "Balance.customerCap",
        "Balance.customerProvince",
        "Balance.customerCity",
        "Balance.customerFiscalCode",
        "Balance.customerBirthday",
        "Balance.customerTelephone",
        "Balance.customerCellphone",
        "Balance.customerSendByWhatsapp",
        "Balance.customerPrefixCellphone",
        "Balance.customerEmail",
      ],
      "filters": filters,
    }

    return cubeService.load(query)
  }

  /* 
    options: {
      from: Date (from date),
      to: Date (to date),
      granularity
    }
    Used to calculate the periodically frequency of the customers
  */
  async customersFrequencySummary(options) {
    if (!options.from || !options.to)
      throw new Error('empty period')

    let filters = []

    let query = {
      "measures": [
        "Balance.billNumber",
        "Balance.customerNumber",
      ],
      "timeDimensions": [
        {
          "dimension": "Balance.billDate",
          "granularity": options.granularity,
          "dateRange": [
            this.convertDateToString(new Date(options.from)),
            this.convertDateToString(new Date(options.to))
          ]
        }
      ],
      "order": [
        [
          "Balance.billNumber",
          "desc"
        ]
      ],
      "dimensions": [
      ],
      "filters": filters,
    }

    return cubeService.load(query)
  }

  async totalTransactionsByDate(options) {
    if (!options.from || !options.to)
      throw new Error('empty period')

    let filters = []

    filters.push({
      member: "PaymentTransactions.type",
      operator: "equals",
      values: ['bill']
    })

    let query = {
      "measures": [
        "PaymentTransactions.count",
      ],
      "timeDimensions": [
        {
          "dimension": "PaymentTransactions.date",
          "granularity": options.granularity,
          "dateRange": [
            this.convertDateToString(new Date(options.from)),
            this.convertDateToString(new Date(options.to))
          ]
        }
      ],
      "order": [
        [
          "PaymentTransactions.count",
          "desc"
        ]
      ],
      "dimensions": [
      ],
      "filters": filters,
    }

    return cubeService.load(query)
  }

  async getTopSellingItems(options) {
    let filters = []

    if (!options.ghost)
      filters.push({
        member: "BillItems.ghost",
        operator: "notEquals",
        values: ['1']
      })

    if (!!options.supplierId) {
      filters.push({
        member: "Items.supplierid",
        operator: "equals",
        values: [options.supplierId.toString()]
      })
    }

    let query = {
      "measures": [
        "BillItems.quantity"
      ],
      "timeDimensions": [{
        "dimension": "BillItems.createdat",
        "granularity": options.granularity,
        "dateRange": [
          this.convertDateToString(new Date(options.from)),
          this.convertDateToString(new Date(options.to))
        ]
      }],
      "order": [
        [
          "BillItems.quantity",
          "desc"
        ]
      ],
      "dimensions": [
        "Items.id",
        "Items.description"
      ],
      "limit": options.limit,
      "filters": filters,
    }

    return cubeService.load(query)
  }

  async getTimeSlots(options) {
    let timezone = "1"
    let startDay = new Date(options.from)
    let lastSundayMarch = startDay
    lastSundayMarch.setMonth(2)
    lastSundayMarch.setDate(31)
    lastSundayMarch.setDate(lastSundayMarch.getDate() - lastSundayMarch.getDay())

    let lastSundayOctober = startDay
    lastSundayOctober.setMonth(9)
    lastSundayOctober.setDate(31)
    lastSundayOctober.setDate(lastSundayOctober.getDate() - lastSundayOctober.getDay())

    if (startDay >= lastSundayMarch && startDay <= lastSundayOctober)
      timezone = "2"

    let filters = [{
      member: "EventGroupsWithTimezones.timespan",
      operator: "equals",
      values: [timezone]
    }]

    let query = {
      "measures": [
        "EventGroupsWithTimezones.count"
      ],
      "timeDimensions": [{
        "dimension": "EventGroupsWithTimezones.startdate",
        "granularity": "day",
        "dateRange": [
          this.convertDateToString(new Date(options.from)),
          this.convertDateToString(new Date(options.to))
        ]
      }],
      "order": [
        ["EventGroupsWithTimezones.startdate", "asc"],
        ["EventGroupsWithTimezones.timeslot", "asc"],
      ],
      "dimensions": [
        "EventGroupsWithTimezones.timeslot"
      ],
      "limit": options.limit,
      "filters": filters,
    }

    return cubeService.load(query)
  }

  /* 
  options: {
    from: Date (from date),
    to: Date (to date),
    granularity
  }
  Used to calculate the total visits of the year
*/
  async totalVisits(options) {
    if (!options.from || !options.to)
      throw new Error('empty period')

    let filters = []

    let query = {
      "measures": [
        "UniqueBalanceVisits.totalVisits",
      ],
      "timeDimensions": [
        {
          "dimension": "UniqueBalanceVisits.billDay",
          "granularity": options.granularity,
          "dateRange": [
            this.convertDateToString(new Date(options.from)),
            this.convertDateToString(new Date(options.to))
          ]
        }
      ],
      "order": [
      ],
      "dimensions": [
      ],
      "filters": filters,
    }

    return cubeService.load(query)
  }

  /* 
    options: {
      from: Date (from date),
      to: Date (to date),
      granularity
    }
  */
  async periodSummary(options) {
    if (!options.from || !options.to)
      throw new Error('empty period')

    let filters = []

    let query = {
      "measures": [
        "Balance.billNumber",
      ],
      "timeDimensions": [
        {
          "dimension": "Balance.billDate",
          "granularity": options.granularity,
          "dateRange": [
            this.convertDateToString(new Date(options.from)),
            this.convertDateToString(new Date(options.to))
          ]
        }
      ],
      "order": [
        [
          "Balance.billNumber",
          "desc"
        ]
      ],
      "dimensions": [
        "Balance.customerId",
      ],
      "filters": filters,
    }

    return cubeService.load(query)
  }

  async countAllCustomersByAgeGroup() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/countAllCustomersByAgeGroup', {})
        .then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err)
        })
    })
  }

  /** 
   * @param {Object} options - options used to parametrize the query  
   * @param {String} options.ageGroup - age group of the customers
 */
  async allCustomersByAgeGroupDetails(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/allCustomersByAgeGroupDetails', {
        ageGroup: options.ageGroup
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /** 
   * @param {Object} options - options used to parametrize the query  
   * @param {Date} options.from - initial period date
   * @param {Date} options.to - finish period date
   * @param {Boolean} options.ghost - show ghost
 */
  async countTransactionsCustomersByAgeGroup(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/countTransactionsCustomersByAgeGroup', options)
        .then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err)
        })
    })
  }

  /** 
   * @param {Object} options - options used to parametrize the query  
   * @param {Date} options.from - initial period date
   * @param {Date} options.to - finish period date
   * @param {Boolean} options.ghost - show ghost
   * @param {String} options.ageGroup - age group of the customers
 */
  async customerTransactionsByAgeGroupDetails(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/customerTransactionsByAgeGroupDetails', options)
        .then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err)
        })
    })
  }

    /** 
   * @param {Object} options - options used to parametrize the query  
   * @param {Date} options.from - initial period date
   * @param {Date} options.to - finish period date
   * @param {Boolean} options.ghost - show ghost
   * @param {String} options.ageGroup - age group of the customers
 */
  async servicesTransactionsByAgeGroupDetails(options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/balance/servicesTransactionsByAgeGroupDetails', options)
        .then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err)
        })
    })
  }

  convertDateToString(date) {
    // convert date to string
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    if (month < 10)
      month = '0' + month

    if (day < 10)
      day = '0' + day

    return year + '-' + month + '-' + day
  }

  _fieldsToExportCustomersByFidelization() {
    return {
      'Cognome': 'lastname',
      'Nome': 'firstname',
      'Sesso': {
        field: 'gender',
        callback: (value) => {
          return value == 'male' ? 'Maschio' : 'Femmina'
        }
      },
      'CAP': 'cap',
      'Provincia': 'province',
      'Citta': 'city',
      'Codice Fiscale': 'fiscalCode',
      "Data di nascita": {
        field: "birthday",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      'Telefono': 'telephone',
      'Cellulare': 'cellphone',
      'Email': 'email',
    }
  }

  _fieldsToExportCubeCustomers() {
    return {
      'Cognome': "Balance.customerLastName",
      'Nome': "Balance.customerFirstName",
      'Sesso': {
        field: "Balance.customerGender",
        callback: (value) => {
          return value == 'male' ? 'Maschio' : 'Femmina'
        }
      },
      'CAP': "Balance.customerCap",
      'Provincia': "Balance.customerProvince",
      'Citta': "Balance.customerCity",
      'Codice Fiscale': "Balance.customerFiscalCode",
      "Data di nascita": {
        field: "Balance.customerBirthday",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      'Telefono': "Balance.customerTelephone",
      'Cellulare': "Balance.customerCellphone",
      'Email': "Balance.customerEmail",
    }
  }
}

export default new BalanceService()