import { openDB } from 'idb';

class Database {
  constructor() {
    this.dbPromise = undefined
    this.createDatabase()
  }

  _storeName() {
    return 'database'
  }

  createDatabase() {
    var self = this
    this.dbPromise = openDB('one-ware-' + self._storeName(), 1, {
      upgrade(db) {
        db.createObjectStore(self._storeName());
      },
    });
  }

}

export default Database