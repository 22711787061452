<template>
  <FullScreenDialog
    v-model="supplierDialog"
    title="Aziende"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in supplierTabs" :key="tab.key">{{
          tab.title
        }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in supplierTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close"
          >Annulla</v-btn
        >
        <v-btn text color="default" :disabled="!formValid || isLicenseBlocked" @click="submitForm"
          >Salva</v-btn
        >
      </div>
    </template>

<!-- KEEP COMMENT - ONEWARE-562     
  <template v-slot:header-actions>
      <div>
        <StandardMenu close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error-text"
                  >Resetta</v-list-item-content
                >
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template> -->
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import supplierService from "@/services/suppliers/suppliers.service.js";
import SuppliersRegistryEditFormTab from "@/components/areas/registries/suppliers/edit/SuppliersRegistryEditFormTab.vue";
import supplierForm from "@/services/suppliers/supplier.form.js";

export default {
  name: "SupplierRegistryEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    SuppliersRegistryEditFormTab,
  },
  data: function () {
    return {
      originalUrl: window.location.pathname,
      tab: null,
      id: undefined,
      supplierDialog: this.openDialog,
      supplierTabs: this.tabs,
      currentRoute: undefined,
      supplier: undefined,
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.currentRoute = this.$route.path;

    this.id = this.$route.params.id;
    if (this.id) {
      this.fetchSupplier();
    }

    let self = this
    supplierForm.on('update', function(data) {
      self.supplier = data.supplier
    })

    self.valid = supplierForm.valid
    supplierForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/registries",
    },
    pathName: {
      type: String,
    },
    filters : {
      type: Array
    },
    tabs: {
      type: Array,
      default: function () {
        return [
          {
            key: 0,
            title: "Generale",
            name: "general",
            component: "SuppliersRegistryEditFormTab",
          }
        ];
      },
    },
  },
  methods: {
    submitForm() {
      supplierService.update(this.supplier).then((result) => {
        this.close()
      });
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchSupplier() {
      supplierForm.resetSupplier()
      supplierService.get(this.id).then((supplier) => {
        supplierForm.updateObject(supplier)
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.supplierDialog = newVal;
    },
  },
  computed: {
    formValid() {
      return this.$store.state.registries.suppliers.data.valid;
    },
  },
  watch: {
    tab(newVal) {
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.supplierTabs[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
</style>