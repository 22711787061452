<template>
  <div class="pa-2">
    <v-subheader>Utility</v-subheader>
    <v-divider class="pa-2"></v-divider>

    <CardList
      :cards="presencesUtility"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          @click="handleListClick(card)"
          class="border-default mx-2 my-2 d-flex flex-column"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div class="text-center text-h6 pt-2 mx-6" @click.stop.prevent="handleListClick(card)">
            {{ card.title }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large @click.stop.prevent="handleListClick(card)">{{
              card.icon
            }}</v-icon>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-end">
            <v-btn icon class="mr-2" @click.stop.prevent="handleListClick(card)">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>
    <v-subheader>Settings</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="presencesSetting"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          @click="handleListClick(card)"
          class="border-default  mx-2 my-1 d-flex flex-column"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div class="text-center text-h6 pt-2 mx-6" @click.stop.prevent="handleListClick(card)">
            {{ card.title }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large @click.stop.prevent="handleListClick(card)">{{
              card.icon
            }}</v-icon>
          </div>
          <v-divider v-if="!!card.newRoute"></v-divider>
          <div v-if="!!card.newRoute" class="d-flex justify-end">
            <v-btn icon class="mr-2" @click.stop.prevent="handleListClick(card)">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn icon class="mr-2" @click.stop.prevent="handlePlusClick(card)" :disabled="isLicenseBlocked">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>
    <StandardDialog
      v-model="showBarcode"
      title="Modifica Turno"
      :dialog-height="null"
      dialog-max-width="500px"
      persistent
    >
      <div>
        <v-row>
          <v-col>
            <ManualBarcodeInput
              v-model="handleTimeLogBarcode"
              @confirm="editTimeLogPermission"
            >
            </ManualBarcodeInput>
          </v-col>
        </v-row>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$router.push('/')"
        >
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import { CardList } from "likablehair-ui-components";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import operatorService from '@/services/operators/operators.service.js';

export default {
  name: "PresencesHome",
  components: {
    CardList,
    ManualBarcodeInput,
    StandardDialog
  },
  data: function() {
    return {
      presencesList: [
        {
          title: "Turni",
          name: "turns",
          icon: "mdi-ballot-outline",
          listRoute: "Turns",
        },
        {
          title: "Presenze",
          name: "presences",
          icon: "mdi-clipboard-clock",
          listRoute: "OperatorsTimeLogs",
        },
        {
          title: "Assenze",
          name: "absences",
          icon: "mdi-airplane",
          listRoute: "Absences",
        },
        {
          title: "Tipologie Assenza",
          name: "absenceTypes",
          icon: "mdi-airplane-takeoff",
          newRoute: "AbsenceTypeNewForm",
          listRoute: "AbsenceTypeList",
        },
        {
          title: "Minuti Minimi",
          name: "minimumMinutesPresences",
          icon: "mdi-timer",
          listRoute: "minimumMinutesSettings",
        }
      ],
      searchText: undefined,
      finalSearchText: undefined,
      showBarcode: true,
      handleTimeLogBarcode: undefined,
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined
    };
  },
  mounted() {
    if (this.$route.params.showBarcode != undefined)
      this.showBarcode = this.$route.params.showBarcode
  },
  methods: {
    handleListClick(section) {
      this.openDialogRoute(section.listRoute);
    },
    handlePlusClick(section) {
      this.openDialogRoute(section.newRoute);
    },
    openDialogRoute(routeName) {
      this.$router.push({
        name: routeName,
        props: {
          openDialog: true
        }
      });
    },
    editTimeLogPermission() {      
      operatorService.canPerformOperation(this.handleTimeLogBarcode, "TimeLog").then((result) => {
        if (result) {
          
        } else {
          this.$router.push({path: '/'})
          alert("Non hai i permessi per eseguire questa azione!")    
        }
      }).catch(() => {
        this.$router.push({path: '/'})
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false;
      this.handleTimeLogBarcode = undefined;
    },
  },
  computed: {
    presencesUtility() {
      if(this.searchText && this.searchText != '') {
        return this.presencesList.filter((el) => { 
          return el.text.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 
            && [
              'turns',
              'presences',
              'absences',
            ].includes(el.name)
        })
      } else {
        return this.presencesList.filter(el => {
          return [
            'turns',
            'presences',
            'absences',
          ].includes(el.name)
        })
      }
    },
    presencesSetting() {
      if (this.searchText && this.searchText != '') {
        return this.presencesList.filter((el) => {
          return el.text.toLowerCase().indexOf(this.searchText.toLowerCase()) != 1
            && [
              'absenceTypes',
              'minimumMinutesPresences'
            ].includes(el.name)
        })
      } else {
        return this.presencesList.filter(el => {
          return [
            'absenceTypes',
            'minimumMinutesPresences',
          ].includes(el.name)
        })
      }
    }
  },
  watch: {
    searchText(newVal) {
      if(!newVal || newVal == '') {
        this.finalSearchText = undefined
      }
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
  isOnlyWheelOfFortune: {
    bind: 'isOnlyWheelOfFortune'
  }
};
</script>

<style scoped>
.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1);
  background-image: linear-gradient(rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  width: 200px;
  height: 190px;
  background-color: white;
}
.border-default:hover {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1.05);
  z-index: 10;
}
</style>