var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,staticClass:"d-flex flex-wrap",class:{
    'justify-center': _vm.justifyCenter,
    'justify-space-around': _vm.justifySpaceAround,
    'align-center': _vm.alignCenter
  },style:(_vm.cssVariables)},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center",style:({
      'min-heght': _vm.cardMinHeight,
      'width': '100%'
    })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):[(_vm.categorize && _vm.categories.length != 0)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%","overflow-y":"auto"}},[_c('v-btn-toggle',{staticClass:"mb-2",attrs:{"dense":""},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},_vm._l((_vm.categories),function(category){return _c('v-btn',{key:category.value,attrs:{"value":category.value}},[_c('span',{class:{
            'hidden-sm-and-down': !!category.icon
          }},[_vm._v(_vm._s(category.text))]),(category.icon)?_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(category.icon))]):_vm._e()],1)}),1)],1):_vm._e(),(_vm.paginate)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({hover}){return [_c('v-card',{staticClass:"animation-properties ma-1",class:{
          'hovered': hover,
        },attrs:{"min-width":_vm.cardMinWidth,"min-height":_vm.cardMinHeight,"max-width":_vm.cardMaxWidth}},[_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({'min-height': _vm.cardMinHeight.indexOf('px') != -1 ? _vm.cardMinHeight : _vm.cardMinHeight + 'px'}),on:{"click":_vm.previousPage}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)])]}}],null,false,3918059917)}):_vm._e(),_vm._l((_vm.itemsToShow),function(item,i){return _c('div',{key:item[_vm.itemKey],staticClass:"ma-1",style:({cursor: _vm.getIfCardDisabled(item) ? 'initial' : 'pointer'}),on:{"click":function($event){return _vm.select(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"animation-properties",class:{
            'selected': _vm.multiple ? _vm.localSelected.includes(item[_vm.itemKey]) : _vm.localSelected == item[_vm.itemKey],
            'hovered': hover && !_vm.getIfCardDisabled(item),
            'card-disabled': _vm.getIfCardDisabled(item)
          },staticStyle:{"overflow":"hidden","text-overflow":"ellipsis"},attrs:{"min-width":_vm.cardMinWidth,"min-height":_vm.cardMinHeight,"max-width":_vm.cardMaxWidth,"max-height":_vm.cardMaxHeight,"elevation":(_vm.multiple ? _vm.localSelected.includes(item[_vm.itemKey]) : _vm.localSelected == item[_vm.itemKey]) ? 5 : 2,"shaped":_vm.shaped,"outlined":_vm.outlined,"color":_vm.colorOnSelected ? ((_vm.multiple ? _vm.localSelected.includes(item[_vm.itemKey]) : _vm.localSelected == item[_vm.itemKey]) ? _vm.getColor(i) : 'white') : _vm.getColor(i)}},[_c('div',{class:{
              'd-flex' : _vm.applyDFlex
            },style:({
              'background-color': _vm.getItemColor(item),
              'min-height': _vm.cardMinHeight + 'px',
              'justify-content': _vm.justifyContentItem,
              'align-items': _vm.alignItemsItem,
            })},[_vm._t("item",function(){return [_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({
                  'min-height': _vm.cardMinHeight.indexOf('px') != -1 ? _vm.cardMinHeight : _vm.cardMinHeight + 'px',
                })},[_c('div',{staticClass:"text-center",style:({
                  'color': _vm.getItemTextColor(item)
                })},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])])]},null,{
              item, 
              cardMinWidth: _vm.cardMinWidth, 
              cardMinHeight: _vm.cardMinHeight, 
              cardMaxWidth: _vm.cardMaxWidth, 
              cardMaxHeight: _vm.cardMaxHeight, 
              color: _vm.getItemColor(item),
              textColor: _vm.adaptiveTextColor ? _vm.getItemTextColor(item) : '#000000'
            })],2)])]}}],null,true)})],1)}),(_vm.paginate)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({hover}){return [_c('v-card',{staticClass:"animation-properties ma-1",class:{
          'hovered': hover,
        },attrs:{"min-width":_vm.cardMinWidth,"min-height":_vm.cardMinHeight,"max-width":_vm.cardMaxWidth}},[_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({'min-height': _vm.cardMinHeight.indexOf('px') != -1 ? _vm.cardMinHeight : _vm.cardMinHeight + 'px'}),on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)])]}}],null,false,3961216256)}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }