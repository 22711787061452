module.exports = {
  props: {
    bus: {
      type: Object,
      default: undefined
    },
  },
  methods: {
    emit(event, ...params) {
      this.$emit(event, ...params)
      if (this.bus) {
        this.bus.$emit(event, ...params)
      }
    }
  }
}