import eventsHandler from '@/services/common/eventsHandler'
import axios from '@/services/axios'
import UrlKeeper from '@/services/UrlKeeper.js'
import currentUser from '@/services/currentUser.service'

class permissionsManagement extends eventsHandler {
  constructor() {
    super()
    this._hasLoadedDefaultPermissions = false
    this._currentUser = null;
    this._permissions = {}

    currentUser.getUser().then((user) => {
      this._currentUser = user
    })

    let self = this
    currentUser.on('change', function(user) {
      self._currentUser = user
      self._permissions = {}
      self.load().then(() => { 
      }).catch(() => {
      })
    })
  }

  loadDefaultPermissions() {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {

      axios.get(apiUrl + '/users/groups/permissions/default').then((response) => {
        if(response.success) {
          for (const [resource, value] of Object.entries(response.results)) {
            if (!!value) {
              for (const [action, isPermitted] of Object.entries(value)) {
                if (!this._permissions[resource]) this._permissions[resource] = {}
                this._permissions[resource][action] = isPermitted
              }
            }
          }
          this._hasLoadedDefaultPermissions = true
          resolve()
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  load() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise(async (resolve, reject) => {
      if (!apiUrl) {
        reject(new Error('not-found'))
        return
      }
      
      try {
        if(!this._hasLoadedDefaultPermissions) {
          await this.loadDefaultPermissions()
        }

        let response = await axios.get(apiUrl + "/users/groups/permissions/mine")
        if (response.success) {
          for (const [resource, value] of Object.entries(response.results)) {
            if (!!value) {
              for (const [action, isPermitted] of Object.entries(value)) {
                if (!this._permissions[resource]) this._permissions[resource] = {}

                if (!!this._currentUser && this._currentUser.system) {
                  this._permissions[resource][action] = true
                  await this._executeAsyncCallbacksOf('update-' + resource, { action: action, isPermitted: true })
                  await this._executeAsyncCallbacksOf('update', { resource: resource, action: action, isPermitted: true })
                } else if (this._permissions[resource][action] !== isPermitted) {
                  this._permissions[resource][action] = isPermitted
                  await this._executeAsyncCallbacksOf('update-' + resource, { action: action, isPermitted: isPermitted })
                  await this._executeAsyncCallbacksOf('update', { resource: resource, action: action, isPermitted: isPermitted })
                }
              }
            }
          }
          resolve(this._permissions)
        } else {
          reject(response.results)
        }
      } catch(err) {
        reject(err)
      }
    })
  }

  can(action, resource) {
    return new Promise((resolve) => {      
      if (!this._currentUser) 
        resolve(false)
      else if (this._currentUser.system) {
        resolve(true)
      }

      else {
        let resourcePermissions = this._permissions[resource]
        if (resourcePermissions == undefined) {
          resolve(false)
        } else {
          for (var i = 0; i < this._permissions[resource].length; i++) {
            if (this._permissions[resource][i]['permission'] == action) {
              resolve(true)
            }
          }
          resolve(false)
        } 
      }
    })
  }

  getActionsFromResource(resource) {
    if (!!this._permissions[resource]) {
      let permittedActions = []
      const allActions = Object.keys(this._permissions[resource])
      for (let i = 0; i < allActions.length; i++) {
        if (this._permissions[resource][allActions[i]] || this._currentUser.system)
          permittedActions.push(allActions[i])
      }
      return permittedActions
    } else {
      return []
    }
  }

  set(resource, action, groups) {
    const apiUrl = UrlKeeper.getUrl('authenticator')

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/users/groups/permissions/set', { 
        resource: resource, 
        action: action, 
        groups: groups
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  addToGroup(resource, action, group) {
    const apiUrl = UrlKeeper.getUrl('authenticator')

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/users/groups/addPermission', {
        resource: resource,
        action: action,
        group: group
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  removeFromGroup(resource, action, group) {
    const apiUrl = UrlKeeper.getUrl('authenticator')

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/users/groups/removePermission', {
        resource: resource,
        action: action,
        group: group
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  list() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/users/groups/permissions/list", {
        filters: {
          license: this._currentUser.licenses[0]
        }
      }).then((response) => {
        if(response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default new permissionsManagement()