var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"auto"}},[_c('TitleWithBackButton',{attrs:{"title":"Frequenza Clienti","back-route":"Analytics"}}),_c('div',{staticClass:"ml-3 mt-3"},[_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading},on:{"click":() => {this.summaryKind = 'week', this.loadChartSummary();}}},[_vm._v(" Settimanale")]),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading},on:{"click":() => {this.summaryKind = 'month', this.loadChartSummary();}}},[_vm._v(" Mensile")]),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading},on:{"click":() => {this.summaryKind = 'quarter', this.loadChartSummary();}}},[_vm._v(" Trimestrale")]),_c('v-btn',{staticClass:"mr-2 ml-2",staticStyle:{"float":"right"},attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate || _vm.tableOrSummaryIndex === 1},on:{"click":_vm.print}},[_vm._v("Stampa")])],1),_c('v-window',{model:{value:(_vm.tableOrSummaryIndex),callback:function ($$v) {_vm.tableOrSummaryIndex=$$v},expression:"tableOrSummaryIndex"}},[_c('v-window-item',[_c('v-container',{staticClass:"mt-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pa-3 d-flex justify-center align-center"},[_c('v-autocomplete',{staticClass:"mr-2",staticStyle:{"max-width":"250px"},attrs:{"label":"Riepilogo Anno","items":_vm.summaryYearOptions,"filled":"","dense":"","hide-details":"auto","menu-props":{
                    offsetY: true
                  }},on:{"input":() => {this.loadChartSummary(), this.loadSummary();}},model:{value:(_vm.summaryYear),callback:function ($$v) {_vm.summaryYear=$$v},expression:"summaryYear"}})],1)])],1),_c('div',{attrs:{"id":"chart"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('DateBasedChart',{attrs:{"getYValue":(object) => {
                      return object[`Balance.customerNumber`]
                    },"getXDate":_vm.getXDate,"height":"50vh","width":"90vw","data":[_vm.data],"tooltip-label-modifier":(tooltipLabel) => {
                      return 'N° Clienti: ' + tooltipLabel.yLabel
                      },"colors":_vm.colorSet,"point-colors":_vm.colorSet,"loading":_vm.chartLoading,"filters":_vm.chartFilters,"granularity":_vm.summaryKind}})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[(!_vm.summaryLoading)?_c('DynamicContentTable',{attrs:{"headers":_vm.headersSummary,"headers-key":"value","headers-text":"text","headers-width":"minWidth","content-rows":_vm.summaryRows,"content-rows-key":"customerId","loading":_vm.loading,"show-load":false},on:{"click-on-cell":_vm.clickOnCell}}):_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1)],1)],1)],1),_c('StandardDialog',{attrs:{"dialog-height":null,"dialog-width":"45%","title":"Messaggio"},model:{value:(_vm.messageToSend),callback:function ($$v) {_vm.messageToSend=$$v},expression:"messageToSend"}},[_c('SendSmsModel',{staticClass:"mt-4",attrs:{"customers":_vm.customersToMsg,"numbers":_vm.numbersToMsg,"whatsapp":true},on:{"input":function($event){_vm.messageToSend = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }