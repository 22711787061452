<template>
  <div class="px-10 pt-7">
    <v-row class="d-flex" style="height: 5dvh;">
      <v-btn
        icon
        @click="goBack"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>  
      <v-btn
        style="height: 100%;"
        class="ml-auto"
        outlined
        color="secondary"
        x-large
        elevation="2"
        @click="openGetVoucherDialog"
      >
        Convalida Voucher
      </v-btn>
    </v-row>
    <v-row style="height: 15vh;">
      <AdvancedFilter
        style="width:99%; height: 100%"
        v-model="filtersValue"
        :filters="filterTypes"
        :advance-filters="advanceFilters"
        :external-filter="externalFilter"
        @apply-filters="applyFilters"
      >
        <template v-slot:custom-voucherState="{filter}">
          <v-select
            v-model="filter.value"
            :items="voucherStates"
            label="Stato"
            outlined
            dense
            hide-details
          >
          </v-select>
        </template>
        <template v-slot:custom-promotionType="{filter}">
          <v-select
            v-model="filter.value"
            :items="promotionTypes"
            label="Tipo Promozione"
            outlined
            dense
            hide-details
          >
          </v-select>
        </template>
      </AdvancedFilter>
    </v-row>
    <v-row style="height: 70vh;">
      <TypeDataTable
        header-color="transparent"
        loading-text="Caricamento Vouchers..."
        :headers="headers"
        :items="vouchers"
        :loading="loading"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
        :show-actions="false"
        :show-select="false"
        @input="rowClicked($event)"
        :rowPerPageOptions="[100, 50, 20]"
        style="background-color: #f4f4fa;width: 100%;height: 100%;"
      >
        <template v-slot:custom-voucherState="{ item }">
          <v-chip
            :color="getState(item) == 'Da utilizzare' ? 'green' : (getState(item) == 'Utilizzato' ? 'orange' : 'red')"
            dark
          >
            {{ getState(item) }}
          </v-chip>
        </template>

        <template v-slot:custom-promotionType="{ item }">
          <v-chip 
            color="secondary"
          >
            {{ getSource(item) }}
          </v-chip>
        </template>

        <template v-slot:custom-value="{ item }">
          {{ getValue(item) }}
        </template>
      </TypeDataTable>
    </v-row>

    <StandardDialog 
      v-model="voucherDialog" 
      :dialog-height="null" 
      title="Voucher"
      dialog-max-width="700px"
    >
      <v-row class="d-flex justify-center mt-3">
        <v-col cols="8" class="d-flex flex-column justify-space-between" v-if="!!foundedVoucher">
          <div class="d-flex justify-space-between">
            <span>Codice: </span>
            <span>{{ foundedVoucher.code }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Nome Cliente: </span>
            <span>{{ getCustomerName(foundedVoucher) }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Provenienza: </span>
            <span>{{ getSource(foundedVoucher) }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Data di emissione: </span>
            <span>{{ translateDate(foundedVoucher.validFrom) }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Scadenza: </span>
            <span>{{ translateDate(foundedVoucher.validTo) }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Stato: </span>
            <span>{{ getState(foundedVoucher) }}</span>
          </div>
          <div class="d-flex justify-space-between mt-2">
            <span>Valore: </span>
            <div class="d-flex flex-column align-end">
              <span v-for="(text, idx) in getValue(foundedVoucher)" :key="idx" class="text-right">
                {{ text }}
              </span> 
            </div>
          </div>
          <div class="d-flex justify-space-between mt-2" v-if="foundedVoucher.type == 'CarpeDiem'">
            <span>Evento: </span>
            <div class="d-flex flex-column align-end">
              <span v-for="(text, idx) in getEvent(foundedVoucher)" :key="idx" class="text-right">
                {{ text }}
              </span> 
            </div>
          </div>
          <div class="d-flex justify-space-between" v-if="foundedVoucher.type == 'CarpeDiem'">
            <span>Servizi Evento: </span>
            <div class="d-flex flex-column align-end">
              <span v-for="(text, idx) in getServices(foundedVoucher)" :key="idx" class="text-right">
                {{ text }}
              </span> 
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!!foundedVoucher" class="d-flex justify-center">
        <v-btn
          outlined
          color="secondary"
          :disabled="(getState(foundedVoucher) != 'Da utilizzare')"
          @click="redeemVoucher"
        >
          Convalida
        </v-btn>
      </v-row>
    </StandardDialog>

    <StandardDialog 
      v-model="voucherCodeDialog" 
      :dialog-height="null" 
      title="Scannerizza voucher"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="insertedVoucherCode" 
        @confirm="handleConfirmVoucherCode"
        scan-your-barcode-message="Scannerizza il voucher..." 
        type="text"
      >
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import ManualBarcodeInput from "@/components/common/ManualBarcodeInput.vue";
import bookingService from '@/services/booking/booking.service.js'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";

export default {
  name: "BookingVoucher",
  components: {
    FullScreenDialog,
    StandardDialog,
    ManualBarcodeInput,
    AdvancedFilter,
    TypeDataTable,
  },
  data: function() {
    let props = this.$router.resolve({ name: 'Marketing' });

    return {
      routeFather: props.href,
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      loading: false,
      vouchers: [],
      headers: [
        { text: 'Cliente', value: 'recipient' },
        { text: 'Valido da', value: 'validFrom', type: 'date' },
        { text: 'Scadenza', value: 'validTo', type: 'date' },
        { text: 'Stato', value: 'voucherState', type: 'custom' },
        { text: 'Utilizzato il', value: 'redeemedAt', type: 'date' },
        { text: 'Provenienza', value: 'promotionType', type: 'custom' },
        { text: 'Codice', value: 'code' }
      ],
      voucherCodeDialog: false,
      insertedVoucherCode: undefined,
      foundedVoucher: undefined,
      voucherDialog: false,
      mappedType: {
        CarpeDiem: 'Carpe Diem',
        WheelOfFortune: 'Ruota della fortuna'
      },
      mappedOperator: {
        'equal': 'Eq',
        'more': 'Gt',
        'less': 'Lt',
        'moreEqual': 'Gte',
        'lessEqual': 'Lte',
        'notEqual': 'Neq',
      },
      filterTypes: [
        { type: 'date', operator: 'equal', field: 'validFrom', name: 'Valido da', label: 'Il', color: "", value: undefined },
        { type: 'date', operator: 'equal', field: 'validTo', name: 'Scade il', label: 'Il', color: "", value: undefined },
        { type: 'custom', operator: 'equal', field: 'voucherState', name: 'Stato', label: '', color: "", value: undefined, icon: 'mdi-timer-sync-outline' },
        { type: 'custom', operator: 'equal', field: 'promotionType', name: 'Tipo Promozione', label: '', color: "", value: undefined, icon: 'mdi-ticket' },
        { type: 'string', operator: 'equal', field: 'code', name: 'Codice', label: 'Codice', color: "", value: undefined}
      ],
      advanceFilters: [
        { type: 'date', operator: 'equal', field: 'redeemedAt', name: 'Utilizzato il', label: 'Il', color: "", value: undefined },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'customer', name: 'Cliente', label: 'Cliente', color: "", value: undefined },
      filtersValue: [],
      voucherStates: [
        { text: 'Da utilizzare', value: 'NotRedeemed' },
        { text: 'Utilizzato', value: 'Redeemed' },
        { text: 'Scaduto', value: 'Expired' }
      ],
      promotionTypes: [
        { text: 'Carpe Diem', value: 'CarpeDiem' },
        { text: 'Ruota della fortuna', value: 'WheelOfFortune' }
      ]
    };
  },
  mounted() {
    this.fetchVouchers();
  },
  methods: {
    fetchVouchers() {
      let params = {}
      this.loading = true;

      let findedValidFrom = this.filtersValue.find(x => x.field === 'validFrom');
      if(!!findedValidFrom) {
        if(['equal', 'more', 'moreEqual'].includes(findedValidFrom.operator)) {
          let date = new Date(findedValidFrom.value)
          params['validFrom.start'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
        if(['equal', 'less', 'lessEqual'].includes(findedValidFrom.operator)) {
          let date = new Date(findedValidFrom.value)
          date.setDate(date.getDate() + 1)
          params['validFrom.end'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
        if(findedValidFrom.operator == 'between'){
          let date = new Date(findedValidFrom.value[0])
          params['validFrom.start'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)

          date = new Date(findedValidFrom.value[1])
          date.setDate(date.getDate() + 1)
          params['validFrom.end'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
      }

      let findedValidTo = this.filtersValue.find(x => x.field === 'validTo');
      if(!!findedValidTo) {
        if(['equal', 'more', 'moreEqual'].includes(findedValidTo.operator)) {
          let date = new Date(findedValidTo.value)
          params['validTo.start'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
        if(['equal', 'less', 'lessEqual'].includes(findedValidTo.operator)) {
          let date = new Date(findedValidTo.value)
          date.setDate(date.getDate() + 1)
          params['validTo.end'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
        if(findedValidTo.operator == 'between'){
          let date = new Date(findedValidTo.value[0])
          params['validTo.start'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)

          date = new Date(findedValidTo.value[1])
          date.setDate(date.getDate() + 1)
          params['validTo.end'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
      }

      let findedVoucherState = this.filtersValue.find(x => x.field === 'voucherState');
      if(!!findedVoucherState) {
        params['voucherState'] = findedVoucherState.value;
      }

      let findedPromotionType = this.filtersValue.find(x => x.field === 'promotionType');
      if(!!findedPromotionType) {
        params['promotionType'] = findedPromotionType.value;
      }

      let findedRedeemedAt = this.filtersValue.find(x => x.field === 'redeemedAt' && x.value);
      if(!!findedRedeemedAt) {
        if(['equal', 'more', 'moreEqual'].includes(findedRedeemedAt.operator)) {
          let date = new Date(findedRedeemedAt.value)
          params['redeemedAt.start'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
        if(['equal', 'less', 'lessEqual'].includes(findedRedeemedAt.operator)) {
          let date = new Date(findedRedeemedAt.value)
          date.setDate(date.getDate() + 1)
          params['redeemedAt.end'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
        if(findedRedeemedAt.operator == 'between'){
          let date = new Date(findedRedeemedAt.value[0])
          params['redeemedAt.start'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)

          date = new Date(findedRedeemedAt.value[1])
          date.setDate(date.getDate() + 1)
          params['redeemedAt.end'] = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
        }
      }

      let findedCode = this.filtersValue.find(x => x.field === 'code');
      if(!!findedCode) {
        params['voucherCode'] = findedCode.value;
      }

      let findedCustomer = this.externalFilter;
      if(!!findedCustomer) {
        params['customer'] = findedCustomer.value;
      }

      params['page'] = this.page - 1;
      params['pageSize'] = this.rowPerPage;

      bookingService.getVoucherList(params).then((response) => {
        this.totalPages = response.totalPages;
        this.vouchers = response.results;
        this.loading = false;
      })
    },
    goBack() {
      this.$router.push(this.routeFather);
    },
    openGetVoucherDialog() {
      this.voucherCodeDialog = true;
    },
    handleConfirmVoucherCode() {
      bookingService.getVoucherInfo(this.insertedVoucherCode).then((response) => {
        this.foundedVoucher = response;
        this.voucherDialog = true;
      })

      this.voucherCodeDialog = false;
      this.insertedVoucherCode = undefined;
    },
    getCustomerName(voucher) {
      if(!!voucher.recipient)
        return voucher.recipient;
      return `Voucher generico`;
    },
    getSource(voucher) {
      return this.mappedType[voucher.type];
    },
    getValue(voucher) {
      if(!!voucher.wheelOfFortune) 
        return voucher.wheelOfFortune.prizeItems.map(pi => pi.description)
      else if(!!voucher.carpeDiem) {
        return [
          `Prezzo Originale: ${voucher.carpeDiem.originalPrice} €`,
          `Prezzo Scontato: ${voucher.carpeDiem.finalPrice} €`,
          `Sconto: ${voucher.carpeDiem.discountPercentage}% (${voucher.carpeDiem.discountValue} €)`
        ]
      }
      else
        return [`Voucher generico`];
    },
    getEvent(voucher){
      if(!voucher.carpeDiem)
        return [];

      return [
        `Data: ${new Date(voucher.carpeDiem.reservation.start).toLocaleDateString()}`,
        `Ora: ${new Date(voucher.carpeDiem.reservation.start).toLocaleTimeString()}`
      ]
    },
    getServices(voucher){
      if(!voucher.carpeDiem)
        return [];

      return [
        ...voucher.carpeDiem.reservation.events.map(event => {
          return event.service.name
        })
      ]
    },
    getState(voucher) {
      if(!!voucher.redeemed || voucher.state == 'Redeemed')
        return `Utilizzato`;
      if(new Date(voucher.validTo) < new Date())
        return `Scaduto`;
      return `Da utilizzare`;
    },
    translateDate(date) {
      return new Date(date).toLocaleDateString();
    },
    applyFilters() {
      this.fetchVouchers();
    },
    rowClicked(voucher) {
      bookingService.getVoucherInfo(voucher[0].code).then((response) => {
        this.foundedVoucher = response;
        this.voucherDialog = true;
      })
    },
    redeemVoucher() {
      bookingService.redeemVoucher(this.foundedVoucher.id).then((response) => {
        this.voucherDialog = false;
        this.foundedVoucher = undefined
        this.fetchVouchers();
      })
    }
  },
  computed: {

  },
  watch: {

  }
};
</script>