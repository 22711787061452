import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class servicePhaseService {

  create(servicePhase) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if(!servicePhase.serviceId) {
        reject('service id not specified')
      }

      servicePhase['position'] = undefined

      axios.post(apiUrl + '/registries/services/addPhase', servicePhase).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(servicePhase) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!servicePhase.serviceId || !servicePhase.id) {
        reject('service id or service phase id not specified')
      }

      servicePhase['position'] = undefined

      axios.post(apiUrl + '/registries/services/updatePhase', servicePhase).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  remove(servicePhase) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!servicePhase.serviceId || !servicePhase.id) {
        reject('service id or service phase id not specified')
      }

      servicePhase['position'] = undefined

      axios.post(apiUrl + '/registries/services/removePhase', servicePhase).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  move(servicePhase, position) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!servicePhase.serviceId || !servicePhase.id) {
        reject('service id or service phase id not specified')
      }

      servicePhase['position'] = position

      axios.post(apiUrl + '/registries/services/movePhase', servicePhase).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }
}

export default new servicePhaseService();
