import authService from "@/services/auth/auth.service";

const jobsRoutes = [
  {
    path: "/registries/jobs",
    name: "JobsRegistryNewForm",
    component: () => import("@/components/areas/registries/jobs/JobsRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/registries/jobs/:id/edit",
    name: "JobsRegistryEditForm",
    component: () => import("@/components/areas/registries/jobs/JobsRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'registry',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/registries/jobs/list",
    name: "JobsRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/jobs/JobsRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default jobsRoutes;