<template>
  <v-row justify="center">
    <StandardDialog
      v-model="localDialog"
      title="Lista turni"
      persistent
      dialog-max-width="600px"
      without-padding
      :dialog-height="null"
      @input="$emit('update:dialog', $event)"
    >
      <template v-slot:header-actions>
        <v-btn @click="newTimeTable" icon>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <div>
        <v-list v-if="timeTableList.length > 0" two-line color="transparent" style="max-height: 70vh">
          <div class="text-center"></div>
          <v-divider></v-divider>
          <v-list-item-group v-model="selectedItemIndex" mandatory @change="$emit('input', selectedItem)" color="primary">
            <div v-for="timeTable in timeTableList" v-bind:key="timeTable.id">
                <v-list-item>
                  <v-list-item-content class="text-left">
                    <v-row class="ml-2">
                      <v-col class="pa-0" md="auto">
                        <v-list-item-title>{{ timeTable.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-for="(turn, index) in timeTable.timeTablesTurns" :key="turn.id">
                            dalle {{turn.start.slice(0, 5)}} alle {{turn.stop.slice(0, 5)}} <span v-if="index != timeTable.timeTablesTurns.length - 1">,</span>
                          </span>
                        </v-list-item-subtitle>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="editTimeTable(timeTable)" icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn @click="deleteTimeTable(timeTable)" icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list-item-group>
        </v-list>
        <div
          v-else
          class="font-weight-light font-italic text-center my-5"
          style="width: 100%"
        >Nessun turno trovato</div>
      </div>
    </StandardDialog>
    <StandardDialog
      title="Crea turno"
      persistent
      dialog-max-width="300px"
      :dialog-height="null"
      v-model="newTimeTableDialog"
    >
      <div 
        class="my-2"
        style="max-height: 70vh; overflow-y: auto"
      >
        <v-form v-model="createValid" class="d-flex justify-center flex-column align-center">
          <v-text-field
            :rules="[presenceRule]" 
            filled 
            label="Nome"
            style="width: 100%"
            v-model="timeTableName"
          ></v-text-field>
          <TimeTablePicker 
            :height="400" 
            v-model="editingTurns" 
            :step="30"
          ></TimeTablePicker>
          <v-subheader>Pausa</v-subheader>
          <v-text-field
            filled
            label="Durata pausa"
            v-model="breakDuration"
          ></v-text-field>
        </v-form>
      </div>
      <template v-slot:footer-actions>
        <v-btn color="error" text @click="newTimeTableDialog = false">annulla</v-btn>
        <v-btn color="default" @click="createTimetable" :disabled="!createValid" text>salva</v-btn>
      </template>
    </StandardDialog>

     <StandardDialog
      title="Modifica turno"
      persistent
      dialog-max-width="300px"
      :dialog-height="null"
      v-model="editTimeTableDialog"
    >
      <div style="max-height: 70vh; overflow-y: auto">
        <v-form v-model="editValid" class="d-flex justify-center flex-column align-center">
          <v-text-field
            :rules="[presenceRule]"
            filled 
            label="Nome"
            style="width: 100%"
            v-model="timeTableName"
          ></v-text-field>
          <TimeTablePicker 
            :height="400" 
            v-model="editingTurns" 
            :step="30"
          ></TimeTablePicker>
          <v-subheader>Pausa</v-subheader>
          <v-text-field
            filled
            label="Durata pausa"
            v-model="breakDuration"
          ></v-text-field>
        </v-form>
      </div>
      <template v-slot:footer-actions>
        <v-btn color="error" text @click="editTimeTableDialog = false">annulla</v-btn>
        <v-btn color="default" @click="updateTimetable" :disabled="!editValid" text>salva</v-btn>
      </template>
    </StandardDialog>
    
  </v-row>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog";
import timeTableService from "@/services/presences/timeTables/timeTables.service.js";
import TimeTablePicker from "@/components/common/TimeTablePicker";

export default {
  name: "EditTurns",
  components: {
    StandardDialog,
    TimeTablePicker
  },
  data: function() {
    return {
      localDialog: this.dialog,
      timeTableList: [],
      selectedItemIndex: undefined,
      newTimeTableDialog: false,
      editingTurns: [],
      timeTableName: undefined,
      timeTableId: undefined,
      editTimeTableDialog: false,
      createValid: false,
      editValid: false,
      breakDuration: undefined,
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      }
    };
  },
  props: {
    dialog: {},
    value: {},
    timeTables: {
      type: Array,
      default: () => []
    },
  },
  mounted: function() {
    this.loadingtimeTable();

  },
  methods: {
    loadingtimeTable() {
      this.loadingTurns = true;
      timeTableService
        .list()
        .then(results => {
          this.timeTableList = results.rows;
          this.$emit('update:time-tables', this.timeTableList )
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loadingTurns = false;
        });
    },
    newTimeTable() {
      this.timeTableId = undefined
      this.editingTurns = [["08:00", "13:00"],["13:30", "20:00"]]
      this.timeTableName = undefined
      this.newTimeTableDialog = true
    },
    createTimetable() {
      timeTableService.create(this.timeTable, this.editingTurnsFormatted).then((results) => {
        this.newTimeTableDialog = false;
        this.loadingtimeTable();
      }).catch((error) => {
        if(error.message == 'Turns must have valid start and stop')
          this.$delegates.snackbar('Gli orari devono essere validi')
        else
          this.$delegates.snackbar('Errore durante la creazione: ' + error.message)
      });
    },
    editTimeTable(timeTable) {
      this.timeTableId = timeTable.id
      this.timeTableName = timeTable.name
      this.breakDuration = timeTable.break
      this.editingTurns = timeTable.timeTablesTurns.map((ttt) => {
        return [ttt.start.slice(0, 5), ttt.stop.slice(0, 5)]
      })
      this.editTimeTableDialog = true;
    },
    updateTimetable() {
      timeTableService.update(this.timeTable, this.editingTurnsFormatted).then((results) => {
        this.editTimeTableDialog = false;
        this.$emit('on-update')
        this.loadingtimeTable();
      });
    },
    deleteTimeTable(timeTable) {
      let confirmed = confirm("Vuoi davvero eliminare il turno " + timeTable.name + " ?")
      if (confirmed) {
        timeTableService.delete(timeTable).then((results) => {
          if(!!results && results.error == 33)
            this.$delegates.snackbar('Attenzione! Hai dei turni assegnati')
          else
            this.loadingtimeTable();
        });
      }
    }
  },
  computed: {
    timeTable() {
      return {
        name: this.timeTableName,
        id: this.timeTableId,
        break: this.breakDuration
      };
    },
    editingTurnsFormatted() {
      let results = []
      for(let i = 0; i < this.editingTurns.length; i++) {
        results.push({
          start: this.editingTurns[i][0],
          stop: this.editingTurns[i][1]
        })
      }
      return results
    },
    selectedItem() {
      return this.timeTableList[this.selectedItemIndex]
    }
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
    },
    value(newVal) {
      if (!newVal) {
        return 
      }

      for(let i = 0; i < this.timeTableList.length ; i+=1) {
        if (this.timeTableList[i].id == newVal.id) {
          this.selectedItemIndex = i;
        }
      }
    }
  }
};
</script>

<style>
</style>