var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"chart"}},[_c('TitleWithBackButton',{attrs:{"title":"Prodotti Best Seller","back-route":"Analytics"}}),_c('div',{staticClass:"d-flex align-center ml-3 mt-3 flex-wrap"},[_c('RangeDateSelector',{attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"loading":_vm.loading,"disabled":_vm.loading,"width":"50vw"},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event}}}),_c('SuppliersAutocomplete',{staticClass:"ml-1",class:{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
        || this.$vuetify.breakpoint.md},style:({
        'margin-top': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          || this.$vuetify.breakpoint.md ? '10px' : '0px'
      }),attrs:{"return-object":true,"auto-detect-new-supplier":false},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}}),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.calculate}},[_vm._v(" Calcola")]),_c('v-btn',{staticClass:"btn-export",attrs:{"loading":_vm.loading},on:{"click":_vm.exportToExcel}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.print}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right","margin-right":"2.5%"},attrs:{"icon":"","large":"","color":!!_vm.ghost ? 'red' : ''},on:{"click":() => { _vm.dialogBarcode = !_vm.dialogBarcode }}},[_c('v-icon',[_vm._v("mdi-shield-account-variant")])],1)],1),_c('div',{staticClass:"d-flex justify-center mt-10"},[_c('GroupBasedChart',{attrs:{"type":"bar","height":"70vh","width":"85vw","colors":_vm.colors,"point-colors":_vm.borderColors,"getYValue":(object) => {
          return object[`BillItems.quantity`];
        },"getXValue":(object) => {
          return object[`Items.description`];
        },"tooltip-label-modifier":(tooltipLabel) => {
          return 'Quantità: ' + tooltipLabel.yLabel;
        },"x-axes-modifier":_vm.xAxes,"data":[_vm.data],"loading":_vm.loading}})],1),_c('v-container',{staticClass:"mt-10 pt-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-h4 font-weight-bold pl-3 pb-3"},[_vm._v(" Tutti i prodotti venduti ")]),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-start",attrs:{"lg":"4","xl":"4","md":"12","cols":"12","sm":"12"}},[(!_vm.loading && _vm.groupedRows1.length > 0)?_c('DynamicContentTable',{attrs:{"id":"table","headers":_vm.headers,"headers-key":"value","headers-text":"text","headers-width":"minWidth","headers-color":"#C4BBF0","table-content-width":_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl
            ? null : '98dvw',"content-rows":_vm.groupedRows1,"content-rows-key":"itemId","results-for-loading":!_vm.items,"loading":_vm.loading,"show-load":false,"pagination":false}}):(!_vm.loading && _vm.groupedRows1.length == 0)?_c('div',{staticClass:"text-center font-weight-light mt-2"},[_vm._v(" Nessun risultato ")]):_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('v-col',{staticClass:"d-flex justify-center align-start",attrs:{"lg":"4","xl":"4","md":"12","cols":"12","sm":"12"}},[(!_vm.loading && _vm.groupedRows2.length > 0)?_c('DynamicContentTable',{attrs:{"id":"table","headers":_vm.headers,"headers-key":"value","headers-text":"text","headers-width":"minWidth","headers-color":"#C4BBF0","table-content-width":_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl 
            ? null : '98dvw',"content-rows":_vm.groupedRows2,"content-rows-key":"itemId","results-for-loading":!_vm.items,"loading":_vm.loading,"show-load":false,"pagination":false}}):(!_vm.loading && _vm.groupedRows2.length == 0)?_c('div',{staticClass:"text-center font-weight-light mt-2"}):_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('v-col',{staticClass:"d-flex justify-center align-start",attrs:{"lg":"4","xl":"4","md":"12","cols":"12","sm":"12"}},[(!_vm.loading && _vm.groupedRows3.length > 0)?_c('DynamicContentTable',{attrs:{"id":"table","headers":_vm.headers,"headers-key":"value","headers-text":"text","headers-width":"minWidth","headers-color":"#C4BBF0","table-content-width":_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl 
            ? null : '98dvw',"content-rows":_vm.groupedRows3,"content-rows-key":"itemId","results-for-loading":!_vm.items,"loading":_vm.loading,"show-load":false,"pagination":false}}):(!_vm.loading && _vm.groupedRows3.length == 0)?_c('div',{staticClass:"text-center font-weight-light mt-2"}):_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1),_c('StandardDialog',{attrs:{"title":"Area Riservata Sviluppatori","dialog-height":null,"dialog-max-width":"500px"},model:{value:(_vm.dialogBarcode),callback:function ($$v) {_vm.dialogBarcode=$$v},expression:"dialogBarcode"}},[_c('ManualBarcodeInput',{attrs:{"scan-your-barcode-message":"Immettere Codice"},on:{"confirm":_vm.activeBarcode},model:{value:(_vm.operatorBarcode),callback:function ($$v) {_vm.operatorBarcode=$$v},expression:"operatorBarcode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }