<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Modelli Scontrino"
      :route-father="routeFather"
    >
      <template v-slot:content>
        <div 
          class="d-flex justify-center align-center mb-3 flex-column" 
          :style="{
            'height': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? 
              '28%' : '40%',
            'width': '100%'
          }" 
        >
          <v-row style="width: 100%;">
            <v-col>
              <AdvancedFilter
                style="width:99%; height: 100%"
                v-model="filtersValue"
                :filters="filterTypes"
                :advance-filters="advanceFilters"
                :external-filter="externalFilter"
                @apply-filters="applyFilters"
              >

                <template v-slot:chip-custom-receiptTitle="{filter}">
                  Nome: {{ filter.value }}
                </template>

                <template v-slot:chip-custom-categories="{filter}">
                  Reparti: {{ filter.value.map(el => el.name).join(', ') }}
                </template>

                <template v-slot:chip-custom-receiptText="{filter}">
                  Descrizione: {{ filter.value }}
                </template>

                <template v-slot:custom-categories="{filter}">
                  <ServiceTypesCategoryAutocomplete
                    v-model="filter.value"
                    :multiple="true"
                    :return-object="true"
                  ></ServiceTypesCategoryAutocomplete>
                </template>

                <template v-slot:custom-receiptText="{filter}">
                  <v-text-field
                    v-model="filter.value"
                    :label="filter.label"
                    :placeholder="filter.label"
                    :outlined="true"
                    :clearable="true"
                    :rows="1"
                    :dense="true"
                    :filled="true"
                    :hide-details="true"
                    :counter="100"
                    :max-height="100"
                    :max-width="300"
                    :min-height="100"
                    :min-width="300"
                  ></v-text-field>
                </template>
              </AdvancedFilter>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ResponsiveCardSelector
                :items="goals"
                v-model="selectedGoal"
                item-key="name"
                card-max-height="80"
                card-min-height="80"
                :card-max-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                  $vuetify.breakpoint.lg ? '200' : '250'"
                :card-min-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                  $vuetify.breakpoint.lg ? '200' : '250'"
                align-items-item="center"
                :justify-center="true"
                :colors="['#ada2e0']"
                :colorOnSelected="true"
                @input="() => {
                  fetchReceiptModels()
                }"
              >
                <template v-slot:item="{ item }">
                  <div class="pa-2 d-flex flex-column justify-center align-center">
                    <div 
                      class="text-center"
                      style="font-size: 0.85rem"
                    >
                      {{ item.text }}
                  </div>
                  </div>
                </template>
              </ResponsiveCardSelector>
            </v-col>
          </v-row>
        </div>

        <div 
          :style="{
            'height': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? 
              '70%' : '58%',
            'overflow-y': 'auto'
          }" 
          ref="dataTableWrapper"
        >
          <TypeDataTable
            loading-text="Caricamento modelli ..."
            :headers="headers"
            :loading="loading"
            :items="paginatedReceiptModelPresets"
            :page.sync="page"
            :row-per-page.sync="rowPerPage"
            :total-pages.sync="totalPages"
            :height="dataTableHeight"
            :show-select="false"
            :show-actions="true"
          >
            <template v-slot:actions="{ item }">
              <div 
                class="d-flex align-center justify-center"
                style="gap: 10px;"
              >
                <v-btn
                  icon
                  small
                  @click.stop="() => {
                    selectedReceiptPreset = {
                      ...item,
                      'text': item.text.split(', '),
                      'additionalText': item.additionalText.split(', '),
                    }
                    previewDialog = true
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  @click.stop="() => {
                    selectedReceiptPreset = {
                      ...item,
                      'text': item.text.split(', '),
                      'additionalText': item.additionalText.split(', '),
                    }
                    if (!!item.type && item.type == 'barcode') {
                      printBarcodeDialog = true
                    } else {
                      printReceiptModel()
                    }
                  }"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </div>
            </template>
          </TypeDataTable>
        </div>
      </template>
    </FullScreenDialog>

    <StandardDialog
      v-model="printBarcodeDialog"
      :dialog-height="null"
      :dialog-max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '90%' : '50%'"
      title="Seleziona Promozione"
    >
      <v-row
        class="d-flex justify-center align-center"
      >
        <v-col
          class="d-flex justify-center align-center"
        >
          <span
            class="mt-3"
            style="font-size: 1.1rem"
          > Il seguente modello contiene un barcode. Selezionare la promozione</span>
        </v-col>
      </v-row>
      <v-row
        class="d-flex justify-center align-center"
        style="width: 100%"
      >
          <v-col
            class="d-flex justify-center align-center"
            cols="6"
            style="width: 100%"
          >
            <v-select
              v-model="selectedPromo"
              label="Seleziona Promozione"
              :items="promoWithBarcode"
              return-object
              item-text="name"
              item-value="id"
              filled
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
      
      <template v-slot:footer>
        <div
          class="d-flex justify-end"
          style="width: 100%"
        >
          <v-btn
            color="error"
            text
            @click="() => {
              selectedPromo = undefined
              printBarcodeDialog = false
            }"
          >
            Chiudi
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="() => {
              selectedReceiptPreset = {
                ...selectedReceiptPreset,
                'barcode': selectedPromo.barcode,
                'barcodeId': selectedPromo.barcode.id
              }
              printReceiptModel()
              selectedPromo = undefined
              printBarcodeDialog = false
            }"
          >
            Fine  
          </v-btn>
        </div>
      </template>
    </StandardDialog>

    <StandardDialog
      v-model="previewDialog"
      :dialog-height="null"
      :title="!!selectedReceiptPreset ? selectedReceiptPreset.title : ''"
      :dialog-max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '90%' : '50%'"
    >
      <div
        class="d-flex justify-center align-center mt-12"
      >
        <ReceiptPreviewForm
          :height="null"
          :width="null"
          :min-height="'90%'"
          :max-height="'90%'"
          :receipt-model="selectedReceiptPreset"
        ></ReceiptPreviewForm>
      </div>
    </StandardDialog>
  </div>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardDialog from '@/components/common/StandardDialog.vue';
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import ServiceTypesCategoryAutocomplete from '@/components/common/ServiceTypesCategoryAutocomplete.vue'
import ReceiptPreviewForm from '@/components/areas/marketing/actions/previews/ReceiptPreviewForm.vue';
import Printer from '@/services/devices/rtPrinter';

import marketingActionsService from "@/services/marketingActions/marketingActions.service.js";
import promosService from '@/services/promos/promos.service.js'
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";

export default {
  name: "ReceiptModelPresetList",
  components: {
    FullScreenDialog,
    AdvancedFilter,
    TypeDataTable,
    ResponsiveCardSelector,
    ServiceTypesCategoryAutocomplete,
    StandardDialog,
    ReceiptPreviewForm
  },
  data: function() {
    let props = this.$router.resolve({ name: 'Marketing' });

    return {
      routeFather: props.href,
      dialog: true,
      filterTypes: [
        { type: 'custom', operator: 'custom', field: 'receiptText', name: 'Descrizione', label: 'Descrizione', color: "", value: [], icon: 'mdi-text-search' },
        { type: 'custom', operator: 'custom', field: 'categories', name: 'Reparti', label: 'Reparti', color: "", value: [], icon: 'mdi-tag-multiple' }
      ],
      advanceFilters: [
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'receiptTitle', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      loading: false,
      promoWithBarcode: [],
      printBarcodeDialog: false,
      selectedPromo: undefined,
      dataTableHeightValue: 400,
      headers: [
        { text: 'Nome modello scontrino', value: 'title' },
        { text: 'Descrizione', value: 'text' },
        { text: 'Obiettivo', value: 'goal.text' },
        { text: 'Reparto', value: 'categories'}
      ],
      goals: [
        {
          name: 'increaseCustomerFrequency',
          text: 'Aumenta Frequenza Clienti',
          icon: 'mdi-human-capacity-increase'
        },
        {
          name: 'developSales',
          text: 'Sviluppa vendite',
          icon: 'mdi-cash-plus'
        },
        {
          name: 'developServiceTypeCategory',
          text: 'Promuovi un servizio o un reparto',
          icon: 'mdi-bullseye-arrow'
        },
        {
          name: 'newCustomers',
          text: 'Acquisisci nuovi Clienti',
          icon: 'mdi-account-plus'
        },
        {
          name: 'mostLoyalCustomers',
          text: 'Premia clienti più fedeli',
          icon: 'mdi-account-heart'
        },
        {
          name: 'informational',
          text: 'Informazionale ',
          icon: 'mdi-email-newsletter'
        },
      ],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      receiptModelPresets: [],
      paginatedReceiptModelPresets: [],
      selectedReceiptPreset: undefined,
      selectedGoal: undefined,
      previewDialog: false
    };
  },
  async mounted() {
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.9
    await this.fetchReceiptModels()
    if (this.receiptModelPresets.length > 0 
      && this.receiptModelPresets.filter(el => el.type == 'barcode').length > 0) {
      await this.loadPromoWithBarcode()
    }
  },
  methods: {
    goBack() {
      this.$router.push(this.routeFather);
    },
    async loadPromoWithBarcode() {
      await promosService.list(1, 1000, undefined).then((results) => {
        this.promoWithBarcode = results.rows.filter(el => !!el.barcode)
      })
    },
    calculatePaginatedReceiptModelPresets() {
      if (!this.receiptModelPresets || this.receiptModelPresets.length == 0) {
        this.paginatedReceiptModelPresets = []
      }

      this.totalPages = Math.ceil(this.receiptModelPresets.length / this.rowPerPage)

      let offset = (this.page - 1) * this.rowPerPage
      this.paginatedReceiptModelPresets = this.receiptModelPresets.slice(offset, (this.rowPerPage * this.page))
    },
    async fetchReceiptModels() {
      this.loading = true
      let advancedSearchFilters = [
        ...this.filtersValue,
      ]
      
      await marketingActionsService.importedList({
        advancedSearchFilters,
        licenseId: true,
        tool: 'receipt',
        goal: !!this.selectedGoal ? this.selectedGoal : undefined
      }).then((results) => {
        this.receiptModelPresets = !!results ? results.map((el) => {
          return {
            'id': el.receiptModel.id,
            'title': el.receiptModel.title,
            'text': !!el.receiptModel.text ? el.receiptModel.text.join(', ') : '',
            'goal': this.goals.find((g) => {
              return g.name == el.goal
            }),
            'categories': !!el.categories ? el.categories.join(', ')
              : '',
            'additionalText': !!el.receiptModel.additionalText ? el.receiptModel.additionalText.join(', ') : '',
            'factoryData': el.receiptModel.factoryData,
            'cashDeskOperatorThanks': el.receiptModel.cashDeskOperatorThanks,
            'qrcode': el.receiptModel.qrcode,
            'barcodeId': el.receiptModel.barcodeId,
            'type': el.receiptModel.type,
          }
        }) : []
        this.loading = false
      })
    },
    async printReceiptModel() {
      try {
        let openedCashDesk = await cashDeskService.getOpened()
        if(!openedCashDesk){
          this.$delegates.snackbar('Per stampare un modello scontrino aprire la cassa')
          return
        }

        await Printer.marketingReport([this.selectedReceiptPreset])
      } catch (error) {
        console.error('printReceiptModel', error)
        this.$delegates.snackbar('Errore nella stampa del modello scontrino')
      }
    },
    applyFilters() {
      this.fetchReceiptModels()
    },
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    },
  },
  watch: {
    receiptModelPresets() {
      this.calculatePaginatedReceiptModelPresets()
    },
    page() {
      this.calculatePaginatedReceiptModelPresets()
    },
    rowPerPage() {
      this.page = 1
      this.calculatePaginatedReceiptModelPresets()
      this.totalPages = Math.ceil(this.receiptModelPresets.length / this.rowPerPage)
    },
  }
};
</script>

<style>
  .title-section {
    height: 2em;
    padding-right: 1em;
    padding-left: 1em;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background-color: var(--background-color);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-transform: none;
  }
</style>