import eventsHandler from '@/services/common/eventsHandler.js'

class paymentDebitForm extends eventsHandler {
  constructor() {
    super()
    this._paymentDebit = {}
    this._valid = false
  }

  get paymentDebit() {
    const paymentDebit = {
      ...this._paymentDebit
    }
    return paymentDebit
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._paymentDebit.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetPaymentDebit() {
    this._paymentDebit = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {paymentDebit: {}})
  }

  updateField(key, value) {
    this._paymentDebit[key] = value
    this._executeCallbacksOf('update', {paymentDebit: this._paymentDebit})
    this._executeCallbacksOf('update-' + key, {key: this._paymentDebit[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(paymentDebit) {
    this._paymentDebit = paymentDebit
    this._executeCallbacksOf('update', {paymentDebit: this._paymentDebit})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new paymentDebitForm()