import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class HistoryOperationsService {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "historyOperations/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        });
    });
  }


}

export default new HistoryOperationsService();