<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      :nudge-bottom="20"
      :elevation="0"
      content-class="elevation-2"
      offset-y
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind="{on, attrs}">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{activatorIcon}}</v-icon>
          </v-btn>
        </slot>
      </template>
      <v-card
        outlined
        elevation="0"
      >
        <v-card-text>
          <div class="d-flex justify-center">
            <v-avatar
              color="primary"
            >
              <v-img
                v-if="avatarUrl"
                :src="avatarUrl"
                alt="avatar image"
              ></v-img>
              <span
                v-else-if="showNameInitials"
              >{{nameInitials}}</span>
              <span v-else>
                <v-icon>mdi-account</v-icon>
              </span>
            </v-avatar>
          </div>
          <h4 class="text-center mt-1">
            {{fullname}}
          </h4>
          <div class="caption text-center my-1">
            {{user.email}}
          </div>
          <v-divider></v-divider>
          <div class="text-center" v-html="version">
          </div>
        </v-card-text>
        <slot name="options" v-bind:user="user">
          <SimpleList
            :items="[
              /*{
                title: 'Profile',
                icon: 'mdi-account',
                emit: 'profile-click'
              },*/
              {
                title: 'Logout',
                icon: 'mdi-exit-to-app',
                emit: 'logout-click'
              },
            ]"
            v-on:item-click="handleItemClick"
          ></SimpleList>
        </slot>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import emittable from "@/mixins/common/emittable.js"
import SimpleList from '@/components/common/SimpleList.vue'

import {
  VMenu,
  VBtn,
  VIcon,
  VCard,
  VCardText,
  VAvatar,
  VImg
} from 'vuetify/lib'

export default {
  name: "UserMenu",
  mixins: [emittable],
  components: {
    SimpleList,
    VMenu,
    VBtn,
    VIcon,
    VCard,
    VCardText,
    VAvatar,
    VImg
  },
  data: function() {
    return {
      menu: false,
      version: process.env.VUE_APP_VERSION_ID || '<a target="_blank" href="https://www.youtube.com/watch?v=oHg5SJYRHA0">v0.0.0</a>'
    }
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    activatorIcon: {
      type: String,
      default: 'mdi-account'
    },
    avatarUrl: {
      type: String,
      default: undefined
    },
    showNameInitials: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    handleItemClick(item) {
      this.emit(item.emit)
    }
  },
  computed: {
    nameInitials() {
      if(this.user.firstname && this.user.lastname) {
        return this.user.firstname[0] + this.user.lastname[0]
      } else {
        return "AA"
      }
    },
    fullname() {
      if(this.user.firstname && this.user.lastname) {
        return this.user.firstname + " " + this.user.lastname
      } else {
        return ""
      }
    }
  }
}
</script>

<style>

</style>