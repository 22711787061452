<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu1"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="localValue"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="localValue"
          label="Ora"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
        <v-time-picker
          v-if="menu1"
          v-model="localValue"
          full-width
          @click:minute="$refs.menu.save(localValue)"
        ></v-time-picker>
      </v-menu>
  </div>
</template>

<script>
export default {
  name: "TimePickerCircular",
  data: function() {
    return {
      localValue: null,
      menu1: false,
      localValueTextField: undefined,
    }
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    bus: {
      type: Object,
      default: undefined
    },
    id: {
      type: String,
      default: "time-picker"
    },
    label: {
      type: String,
      default: "Ora"
    },
  },
  methods: {
    emit(event, ...params) {
      this.$emit(event, ...params)
      if (this.bus) {
        this.bus.$emit(event, ...params)
      }
    }
  },
  watch: {
    localValue(newVal) {
      this.emit('input', newVal)
    },
    value(newVal) {
      if(this.localValue != newVal) {
        this.localValue = newVal
      }
    }
  }
}
</script>

<style>
</style>