import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class smsModelsService {

  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/smsModels/list', {
        page: page,
        rowPerPage: rowPerPage
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(smsModel) {
    const apiUrl = UrlKeeper.getUrl()
    
    const smsModelToCreate = {
      ...smsModel,
    }
    delete smsModelToCreate.imageTags;
    delete smsModelToCreate.selectedImage;

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/smsModels/create', smsModelToCreate).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }

      }).catch((error) => {
        reject(error)
      })
    })
  }

  update(smsModel) {
    const apiUrl = UrlKeeper.getUrl()
    
    const smsModelToUpdate = {
      ...smsModel,
    }
    delete smsModelToUpdate.imageTags;
    delete smsModelToUpdate.selectedImage;

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/smsModels/update', smsModelToUpdate).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(smsModel) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!smsModel || !smsModel.id) {
        reject(new Error('sms model is not specify'))
      }

      axios.get(apiUrl + '/smsModels/delete', {
        id: smsModel.id
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  get(smsModelId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/smsModels/get', { id: smsModelId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }


  send(smsModel, number, params) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if(!smsModel || !smsModel.id) {
        reject(new Error('sms model is not specified'))
        return
      } else if (!number) {
        reject(new Error('number is not specified'))
        return
      }

      axios.post(apiUrl + '/smsModels/send', { 
        params: params,
        number: number,
        model: smsModel
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }


}

export default new smsModelsService();
