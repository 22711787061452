import authService from "@/services/auth/auth.service";

const suppliersRoutes = [
  {
    path: "/registries/suppliers/new",
    name: "SuppliersRegistryForm",
    component: () =>
      import(
        "@/components/areas/registries/suppliers/SuppliersRegistryNewForm.vue"
      ),
    children: [
      {
        path: "tabs",
        name: "SuppliersRegistryNewFormTab",
        component: () =>
          import(
            "@/components/areas/registries/suppliers/new/SuppliersRegistryNewFormTab.vue"
          ),
      },
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/registries/suppliers/:id/edit",
    name: "SuppliersRegistryEditForm",
    component: () => import("@/components/areas/registries/suppliers/SuppliersRegistryEditForm.vue"),
    children: [
      {
        path: "tabs",
        name: "SuppliersRegistryEditFormTab",
        component: () => import("@/components/areas/registries/suppliers/edit/SuppliersRegistryEditFormTab.vue"),
      },
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        filters: route.params.filters,
      }
    },
  },
  {
    path: "/registries/suppliers/list",
    name: "SuppliersRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/suppliers/SuppliersRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters,
      }
    }
  }
];

export default suppliersRoutes;
