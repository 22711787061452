<template>
  <div
      class="flex-grow"
  >
    <Flowy
      :nodes="localNodes"
      :beforeMove="beforeMove"
      :beforeAdd="beforeAdd"
    ></Flowy>
  </div>
</template>

<script>
import Flowy from "@/components/areas/analytics/FlowyComponent/Flowy.vue"
import CustomFlowyNode from "@/components/areas/analytics/CustomFlowyNode.vue"

export default {
  name: "FlowyGraph",
  components: {
    CustomFlowyNode,
    Flowy
  },
  data: function() {
    return {
      
    }
  },
  props: {
    cardColor: {
      type: String,
      default: 'White'
    },
    nodes: {
      type: Array,
      default: () => [
        {
          id: '1',
          parentId: -1,
          nodeComponent: CustomFlowyNode,
          data: {
            text: 'Parent block',
            title: 'New Visitor',
            description: '<span>When a <b>new visitor</b> goes to <i>Site 1</i></span>',
          },
        },
        {
          id: '2',
          parentId: '1',
          nodeComponent: CustomFlowyNode,
          data: {
            text: 'Parent block',
            title: 'New Visitor',
            description: '<span>When a <b>new visitor</b> goes to <i>Site 1</i></span>',
          },
        },
        {
          id: '3',
          parentId: '1',
          nodeComponent: CustomFlowyNode,
          data: {
            text: 'Parent block',
            title: 'New Visitor',
            description: '<span>When a <b>new visitor</b> goes to <i>Site 1</i></span>',
          },
        },
      ] 
    }
  },
  mounted: function() {
  },
  methods: {
    beforeMove ({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      //console.log('beforeMove', to, from);

      // we cannot drag upper parent nodes in this demo
      if (from && from.parentId === -1) {
        return false;
      }
      // we're adding a new node (not moving an existing one)
      if (from === null) {
        // we've passed this attribute to the demo-node
        if (this.newDraggingBlock['custom-attribute'] === false) {
          return false
        }
      }

      return true;
    },
    // REQUIRED
    beforeAdd ({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      //console.log('beforeAdd', to, from);

      // we've passed this attribute to the demo-node
      if (this.newDraggingBlock['custom-attribute'] === false) {
        return false
      }

      return true;
    },
    randomInteger () {
      return Math.floor(Math.random() * 10000) + 1;
    },
    generateId (nodes) {
      let id = this.randomInteger();
      // _.find is a lodash function
      while (_.find(nodes, { id }) !== undefined) {
        id = this.randomInteger();
      }
      return id;
    },
    addNode (_event) {
      const id = this.generateId();
      this.nodes.push({
        ..._event.node,
        id,
      });
    },
    remove (event) {
      //console.log('remove', event)

      // node we're dragging to
      const { node } = event

      // we use lodash in this demo to remove node from the array
      const nodeIndex = _.findIndex(this.nodes, { id: node.id });
      this.nodes.splice(nodeIndex, 1);
    },
    move (event) {
      //console.log('move', event);

      // node we're dragging to and node we've just dragged
      const { dragged, to } = event;

      // change panentId to id of node we're dragging to
      dragged.parentId = to.id;
    },
    add (event) {
      // every node needs an ID
      const id = this.generateId();

      // add to array of nodes
      this.nodes.push({
        id,
        ...event.node,
      });
    },
    searchForChildren(rootNode, rootIndex, arrayLength) {
      let result = []
      let index = arrayLength
      if (!!rootNode["children"] && rootNode["children"].length > 0) {
        for (let i = 0; i < rootNode["children"].length; i++) {
          result.push({
            id: index,
            parentId: rootIndex,
            nodeComponent: CustomFlowyNode,
            data: {
              title: rootNode["children"][i]["name"],
              description: rootNode["children"][i]["description"],
              color: this.cardColor
            } 
          })
          index++
          let objectResult = this.searchForChildren(rootNode["children"][i], index - 1, index)
          index = objectResult["index"]
          result.push(...objectResult["result"])
        }
      }
      return {
        index: index,
        result: result
      }
    }
  },
  computed: {
    localNodes() {
      let results = []
      let rootNode = this.nodes[0]
      let localRootNode = {
        id: 1,
        parentId: -1,
        nodeComponent: CustomFlowyNode,
        data: {
          title: rootNode["name"],
          description: rootNode["description"],
          color: this.cardColor
        }
      }
      results.push(localRootNode)
      this.searchForChildren(rootNode, 1, 2)["result"].map(el => results.push(el))
      return results
    }
  }
}

</script>