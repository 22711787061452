var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FullScreenDialog',{attrs:{"routeFather":_vm.pathToGoBack,"can-close":false,"can-close-right":true},on:{"input":function($event){return _vm.$emit('update:open-dialog', $event)}},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',[_c('v-img',{attrs:{"max-width":"125","src":require("@/assets/LOGO_LULUU.png")}})],1),_c('div',{staticClass:"ml-2 d-flex align-center"},[_c('span',{staticClass:"title-custom",staticStyle:{"padding-top":"5px"}},[_vm._v("Modifica Campagna")])])])]},proxy:true},{key:"header-actions",fn:function(){return [_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',{staticClass:"text-left"},[_c('v-row',{staticClass:"justify-end",staticStyle:{"width":"31vw"}},[_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex direction-row flex-wrap justify-end",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-2 status-div font-weight-bold",style:({                          
                    'background-color': _vm.status == 'draft' &&
                      new Date() <= new Date(_vm.endDate) ? '#ffda85' : '',
                    'font-size': '12px'
                  }),attrs:{"disabled":(_vm.status == 'approved' && (!!_vm.eventMarketing.actions && !!_vm.eventMarketing.actions.hasStarted)),"depressed":""},on:{"click":() => {
                    _vm.checkIfInputFired()
                    _vm.status = 'draft'
                  }}},[_vm._v(" DRAFT ")]),_c('v-btn',{staticClass:"ml-2 status-div font-weight-bold",style:({
                      'background-color': _vm.status == 'approved' &&
                        new Date() <= new Date(_vm.startDate) ? '#bfec83' : '',
                      'font-size': '12px'
                    }),attrs:{"disabled":(_vm.status == 'approved' && (!!_vm.eventMarketing.actions && !!_vm.eventMarketing.actions.hasStarted)),"depressed":""},on:{"click":() => {
                    _vm.checkIfInputFired()
                    _vm.status = 'approved'
                  }}},[_vm._v(" APPROVATO ")]),_c('div',{staticClass:"ml-2 status-div font-weight-bold",style:({
                      'background-color': _vm.status == 'approved' &&
                        _vm.archived == false &&
                        new Date() >= new Date(_vm.startDate) && 
                        new Date() <= new Date(_vm.endDate) 
                         ? '#9abcea' : 
                         (!!_vm.eventMarketing.actions && !!_vm.eventMarketing.actions.hasStarted) ?
                        'rgba(0, 0, 0, 0.12)' : '',
                      'font-size': '12px',
                      'padding': '6px 16px 6px 16px'
                    })},[_vm._v(" IN CORSO ")]),_c('div',{staticClass:"ml-2 status-div font-weight-bold",style:({
                      'background-color': _vm.status == 'draft' &&
                        new Date() > new Date(_vm.endDate) ? 'grey' : 
                        _vm.status == 'approved' &&
                        new Date() > new Date(_vm.endDate) ||
                        _vm.archived == true ? '#cccccc' : 
                        (!!_vm.eventMarketing.actions && !!_vm.eventMarketing.actions.hasStarted) ?
                        'rgba(0, 0, 0, 0.12)' : '',
                      'font-size': '12px',
                      'padding': '6px 16px 6px 16px'
                    })},[_vm._v(" TERMINATO ")])],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('EventMarketingGeneralForm',{attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"valid":_vm.formValid,"edit-event-type":true},on:{"update:valid":function($event){_vm.formValid=$event},"input":_vm.checkIfInputFired,"update:img":_vm.handleSelectedImg},model:{value:(_vm.eventMarketing),callback:function ($$v) {_vm.eventMarketing=$$v},expression:"eventMarketing"}})]},proxy:true},{key:"footer-actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","loading":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"text":"","color":"default","loading":_vm.loading,"disabled":!_vm.formValid || (!!_vm.formValid && !_vm.firedInput)},on:{"click":_vm.submitForm}},[_vm._v("Salva")])]},proxy:true}]),model:{value:(_vm.presetDialog),callback:function ($$v) {_vm.presetDialog=$$v},expression:"presetDialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }