import authService from "@/services/auth/auth.service";
import currentUser from '@/services/currentUser.service'
const serviceTypesRoutes = [
  {
    path: "/registries/serviceTypes/new",
    name: "ServiceTypesRegistryNewForm",
    component: () => import("@/components/areas/registries/serviceTypes/ServiceTypesRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      if (currentUser.isSystem()) {
        next()
      } else {      
        next(from)
      }
    }
  },
  {
    path: "/registries/serviceTypes/:id/edit",
    name: "ServiceTypesRegistryEditForm",
    component: () => import("@/components/areas/registries/serviceTypes/ServiceTypesRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry',
        pathName: route.query.pathName,
        filters: route.params.filters
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      if (currentUser.isSystem()) {
        next()
      } else {      
        next(from)
      }
    }
  },
  {
    path: "/registries/serviceTypes/list",
    name: "ServiceTypesRegistryList",
    component: () => import("@/components/areas/registries/serviceTypes/ServiceTypesRegistryList.vue"),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    },
  }
];

export default serviceTypesRoutes;