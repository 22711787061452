<template>
  <v-form v-model="formValid">
    <v-text-field
      v-if="showTitle"
      dense
      filled
      rounded
      hide-details="auto"
      label="Titolo"
      :rules="[max255CharRule]"
      v-model="title"
      @input="$emit('input', localValue)"
    ></v-text-field>
    <v-textarea
      v-if="showDescription"
      class="mt-2"
      dense
      filled
      rounded
      hide-details="auto"
      label="Descrizione"
      :rules="[]"
      v-model="title"
      @input="$emit('input', localValue)"
    ></v-textarea>
    <v-select
      class="mt-2"
      label="Seleziona Layout"
      v-model="layout"
      :items="landingPageLayoutOptions"
      dense
      filled
      rounded
      hide-details="auto"
      :rules="[presenceRule]"
      @change="handleLayoutChange"
    ></v-select>

    <div v-if="!!layout" class="mt-6">
      <div>
        <v-row v-if="(layout == 'headerImageAndSidebar' || layout == 'fullHeaderImageAndSidebar') && !!headerBlock">
          <v-col>
            <v-file-input
              v-model="headerBlock.image"
              label="Carica copertina"
              @change="() => {$emit('input', localValue); showPreview(headerBlock.id)}"
            ></v-file-input>
            <v-img
              v-if="!!headerBlock.image || !!headerBlock.imageUrl"
              style="width: 100%"
              class="mb-3"
              :src="headerBlock.imageUrl"
              height="300"
              :contain="layout == 'headerImageAndSidebar'"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div v-for="block in leftSidebarBlocks" :key="block.id" class="my-3">
              <div class="d-flex justify-center align-center">
                <v-btn text @click="() => switchBlockType(block.id)">
                  {{ translateBlockType(block.type) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                  icon 
                  color="error" 
                  @click="() => deleteBlock(block.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  icon 
                  color="default" 
                  @click="() => editBlockDescription(block.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div v-if="block.type == 'image' || block.type == 'imageAndParagraph'">
                <v-file-input
                  v-model="block.image"
                  label="Carica immagine"
                  @change="() => { $emit('input', localValue); showPreview(block.id) }"
                ></v-file-input>
                <v-img
                  v-if="!!block.image || !!block.imageUrl"
                  style="width: 100%"
                  class="mb-3"
                  :src="block.imageUrl"
                ></v-img>
              </div>
              <div v-if="block.type == 'paragraph' || block.type == 'imageAndParagraph'">
                <textarea
                  class="text-h5 auto-height full-width auto-grow px-8"
                  :value="block.title"
                  @input="(e) => { autoGrow(e.target); handleBlockTitleChange(block.id, e) }"
                ></textarea>
                <v-dialog
                  v-model="editBlockDialogs[block.id]"
                  transition="scroll-y-transition"
                  width="90vw"
                >
                  <v-md-editor 
                    v-model="block.body" 
                    height="400px"
                    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link code"
                    right-toolbar="preview toc sync-scroll fullscreen"
                  ></v-md-editor>
                </v-dialog>
                <v-md-preview :text="block.body"></v-md-preview>
              </div>
            </div>
            <v-btn
              color="primary"
              @click="addLeftSidebarBlock"
            >
              <v-icon>mdi-plus</v-icon>
              Aggiungi
            </v-btn>
          </v-col>
          <v-col cols="6" style="border-right: 1px solid grey; border-left: 1px solid grey">
            <div v-for="block in bodyBlocks" :key="block.id" class="my-3">
              <div class="d-flex justify-center align-center">
                <v-btn text @click="() => switchBlockType(block.id)">
                  {{ translateBlockType(block.type) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                  icon 
                  color="error" 
                  @click="() => deleteBlock(block.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  icon 
                  color="default" 
                  @click="() => editBlockDescription(block.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div v-if="block.type == 'image' || block.type == 'imageAndParagraph'">
                <v-file-input
                  v-model="block.image"
                  label="Carica Immagine"
                  @change="() => {$emit('input', localValue); showPreview(block.id)}"
                ></v-file-input>
                <v-img
                  v-if="!!block.image || !!block.imageUrl"
                  style="width: 100%"
                  class="mb-3"
                  :src="block.imageUrl"
                ></v-img>
              </div>
              <div v-if="block.type == 'paragraph' || block.type == 'imageAndParagraph'">
                <textarea
                  class="text-h5 auto-height full-width auto-grow px-8"
                  :value="block.title"
                  @input="(e) => { autoGrow(e.target); handleBlockTitleChange(block.id, e) }"
                ></textarea>
                <v-dialog
                  v-model="editBlockDialogs[block.id]"
                  transition="scroll-y-transition"
                  width="90vw"
                >
                  <v-md-editor 
                    v-model="block.body" 
                    height="400px"
                    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link code"
                    right-toolbar="preview toc sync-scroll fullscreen"
                  ></v-md-editor>
                </v-dialog>
                <v-md-preview :text="block.body"></v-md-preview>
              </div>
            </div>
            <v-btn
              color="primary"
              @click="addBodyBlock"
            >
              <v-icon>mdi-plus</v-icon>
              Aggiungi
            </v-btn>
          </v-col>
          <v-col cols="3">
            <div v-for="block in rightSidebarBlocks" :key="block.id" class="my-3">
              <div class="d-flex justify-center align-center">
                <v-btn text @click="() => switchBlockType(block.id)">
                  {{ translateBlockType(block.type) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                  icon 
                  color="error" 
                  @click="() => deleteBlock(block.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  icon 
                  color="default" 
                  @click="() => editBlockDescription(block.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div v-if="block.type == 'image' || block.type == 'imageAndParagraph'">
                <v-file-input
                  v-model="block.image"
                  label="Carica Immagine"
                  @change="() => {$emit('input', localValue); showPreview(block.id)}"
                ></v-file-input>
                <v-img
                  v-if="!!block.image || !!block.imageUrl"
                  style="width: 100%"
                  class="mb-3"
                  :src="block.imageUrl"
                ></v-img>
              </div>
              <div v-if="block.type == 'paragraph' || block.type == 'imageAndParagraph'">
                <textarea
                  class="text-h5 auto-height full-width auto-grow px-8"
                  :value="block.title"
                  @input="(e) => { autoGrow(e.target); handleBlockTitleChange(block.id, e) }"
                ></textarea>
                <v-dialog
                  v-model="editBlockDialogs[block.id]"
                  transition="scroll-y-transition"
                  width="90vw"
                >
                  <v-md-editor 
                    v-model="block.body" 
                    height="400px"
                    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link code"
                    right-toolbar="preview toc sync-scroll fullscreen"
                  ></v-md-editor>
                </v-dialog>
                <v-md-preview :text="block.body"></v-md-preview>
              </div>
            </div>
            <v-btn
              color="primary"
              @click="addRightSidebarBlock"
            >
              <v-icon>mdi-plus</v-icon>
              Aggiungi
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-form>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import imageLoader from '@/services/images.js'

export default {
  Name: "LandingPageModelForm",
  data: function() {
    return {
      id: undefined,
      formValid: false,
      layout: "",
      title: "",
      description: "",
      text: "",
      editBlockDialogs: {},
      landingPageLayoutOptions: [
        {
          value: 'headerImageAndSidebar',
          text: 'Immagine + sidebar'
        }, 
        {
          value: 'sidebar',
          text: 'Sidebar'
        },
        {
          value: 'fullHeaderImageAndSidebar',
          text: 'Immagine piena + sidebar'
        }
      ],
      blocks: [],
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    }
  },
  props: {
    value: {
      type: Object,
    },
    valid: {
      type: Boolean
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    showDescription: {
      type: Boolean,
      default: false
    }
  },
  mounted: async function() {
    this.$emit('update:valid', this.formValid)
    if(!!this.value) {
      this.title = this.value.title
      this.description = this.value.description
      this.layout = this.value.layout
      this.id = this.value.id
      for(let i = 0; i < this.value.blocks.length; i += 1) {
        if(!!this.value.blocks[i].imageLink) {
          this.value.blocks[i].imageUrl = await imageLoader.getImageUrl(this.value.blocks[i].imageLink)
        } else if (!!this.value.blocks[i].copyImageUrl) {
          this.value.blocks[i].imageUrl = await imageLoader.getImageUrl(this.value.blocks[i].copyImageUrl)
        }
      }
      this.blocks = this.value.blocks
    }

    let self = this
    setTimeout(() => {
      const elements = document.getElementsByClassName('auto-grow')
      for(let i = 0; i < elements.length; i += 1) {
        self.autoGrow(elements[i])
      }
    }, 200);
  },
  methods: {
    showPreview(blockId) {
      setTimeout(() => {
        const index = this.blocks.findIndex((el) => el.id == blockId)
        if (index >= 0 && !!this.blocks[index].image) {
          this.blocks[index].imageUrl = URL.createObjectURL(this.blocks[index].image)
        }
        this.blocks = [...this.blocks]
      }, 50);
    },
    addLeftSidebarBlock() {
      this.blocks.push({
        id: uuidv4(),
        position: 'leftSidebar',
        type: 'paragraph',
        title: 'Titolo',
        body: 'Descrizione del paragrafo',
        _new: true
      })
      this.$emit('input', this.localValue)
    },
    addRightSidebarBlock() {
      this.blocks.push({
        id: uuidv4(),
        position: 'rightSidebar',
        type: 'paragraph',
        title: 'Titolo',
        body: 'Descrizione del paragrafo',
        _new: true
      })
      this.$emit('input', this.localValue)
    },
    addBodyBlock() {
      this.blocks.push({
        id: uuidv4(),
        position: 'body',
        type: 'paragraph',
        title: 'Titolo',
        body: 'Descrizione del paragrafo',
        _new: true
      })
      this.$emit('input', this.localValue)
    },
    switchBlockType(blockId) {
      const index = this.blocks.findIndex((el) => el.id == blockId)
      if(index >= 0) {
        const currentType = this.blocks[index].type

        if (currentType == 'paragraph') this.blocks[index].type = 'imageAndParagraph'
        if (currentType == 'imageAndParagraph') this.blocks[index].type = 'image'
        if (currentType == 'image') this.blocks[index].type = 'paragraph'

        this.blocks = [...this.blocks]

        this.$emit('input', this.localValue)
      }
    },
    translateBlockType(blockType) {
      const mapper = {
        paragraph: 'Paragrafo',
        imageAndParagraph: 'Immagine + Paragrafo',
        image: 'Immagine'
      }

      return mapper[blockType]
    },
    handleBlockTitleChange(blockId, event) {
      const index = this.blocks.findIndex((el) => el.id == blockId)
      if (index >= 0) {
        const newTitle = event.target.value
        this.blocks[index].title = newTitle
        this.$emit('input', this.localValue)
      }
    },
    handleBlockBodyChange(blockId, event) {
      const index = this.blocks.findIndex((el) => el.id == blockId)
      if (index >= 0) {
        const newBody = event.target.value
        this.blocks[index].body = newBody
        this.$emit('input', this.localValue)
      }
    },
    autoGrow(element) {
      element.style.height = "5px";
      element.style.height = (element.scrollHeight) + "px";
    },
    handleLayoutChange() {
      if(this.layout == 'headerImageAndSidebar' || this.layout == 'fullHeaderImageAndSidebar') {
        if(!this.headerBlock || this.headerBlock.length == 0) {
          this.blocks.push({
            id: uuidv4(),
            position: 'header',
            type: 'image',
            _new: true
          })
        }
      } else if(this.layout == 'sidebar') {
        this.blocks = this.blocks.filter((e) => e.position != 'header')
      }
      this.$emit('input', this.localValue)
    },
    deleteBlock(blockId) {
      const index = this.blocks.findIndex((el) => el.id == blockId)
      if (index >= 0) {
        this.blocks[index]._delete = true
        this.blocks = [...this.blocks]
        this.$emit('input', this.localValue)
      }
    },
    editBlockDescription(blockId) {
      this.editBlockDialogs[blockId] = true
      this.editBlockDialogs = {...this.editBlockDialogs}
    }
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
    },
    async value(value) {
      this.title = value.title
      this.description = value.description
      this.layout = value.layout
      for (let i = 0; i < value.blocks.length; i += 1) {
        if (!!value.blocks[i].imageLink) {
          value.blocks[i].imageUrl = await imageLoader.getImageUrl(value.blocks[i].imageLink)
        } else if(!!value.blocks[i].copyImageUrl) {
          value.blocks[i].imageUrl = await imageLoader.getImageUrl(value.blocks[i].copyImageUrl)
        }
      }
      this.blocks = value.blocks
      this.id = value.id
    }
  },
  computed: {
    localValue() {
      return {
        id: this.id,
        title: this.title,
        description: this.description,
        layout: this.layout,
        blocks: this.blocks
      }
    },
    leftSidebarBlocks() {
      return !!this.blocks ? this.blocks.filter((el) => el.position == 'leftSidebar' && !el._delete) : undefined
    },
    rightSidebarBlocks() {
      return !!this.blocks ? this.blocks.filter((el) => el.position == 'rightSidebar' && !el._delete) : undefined
    },
    bodyBlocks() {
      return !!this.blocks ? this.blocks.filter((el) => el.position == 'body' && !el._delete) : undefined
    },
    headerBlock() {
      return !!this.blocks ? this.blocks.filter((el) => el.position == 'header' && !el._delete)[0] : undefined
    }
  }
}
</script>

<style scoped>
  textarea.auto-height {
    resize: none;
    overflow: hidden;
    height: 20px;
    min-height: 50px;
  }

  textarea.full-width {
    width: 100%;
  }

  textarea:focus, input:focus {
    outline: none;
    border: none;
  }
</style>

<style>
  .vuepress-markdown-body {
    word-break: break-all;
  }
</style>
