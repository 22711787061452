<template>
    <FullScreenDialog
      v-model="presetDialog"
      title="Importa Evento"
      :routeFather="pathToGoBack"
      @input="$emit('update:open-dialog', $event)"
      :canClose="false"
    >
      <template v-slot:content>
        <EventMarketingGeneralForm
          v-model="eventMarketing"
          :start-date="startDate"
          :end-date="endDate"
          :valid.sync="formValid"
          :edit-event-type="true"
          :disabled="true"
        ></EventMarketingGeneralForm>
      </template>
  
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="closeDialog"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :loading="loading"
          @click="importForm"
        >Importa</v-btn>
      </template>
    </FullScreenDialog>
  </template>
  
  <script>
    import Vue from 'vue';
    
    import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
    import EventMarketingGeneralForm from "@/components/areas/marketing/events/EventMarketingGeneralForm.vue";
    
    import marketingEventsService from "@/services/marketingEvents/marketingEvents.service.js";
    
    
    export default {
      name: "EventMarketingImportForm",
      components: {
        FullScreenDialog,
        EventMarketingGeneralForm
      },
      data: function() {
        return {
          presetDialog: this.openDialog,
          formValid: false,
          loading: false,
          eventMarketing: {},
          bus: new Vue(),
          id: undefined,
        };
      },
      mounted() {
          this.id = this.$route.params.id
          if (!!this.id) {
              this.fetchEvent()
          }
      },
      props: {
        openDialog: {
          type: Boolean,
          default: false
        },
        pathToGoBack: {
          type: String,
          default: '/marketing/calendar'
        },
        pathName: {
          type: String,
        },
        startDate: {
          type: Date,
          default: () => new Date(),
        },
        endDate: {
          type: Date,
          default: () => new Date(),
        },
        filters: {
          type: Object,
        }
      },
      methods: {
        importForm() {
          this.eventMarketing.selectedImageId = !!this.eventMarketing.selectedImages 
            && this.eventMarketing.selectedImages.length > 0 ? this.eventMarketing.selectedImages[0].id : undefined
                      
          marketingEventsService.import(this.eventMarketing).then(() => {
            this.closeDialog()
          }) 
        },
        fetchEvent() {
          marketingEventsService.getMasterEvent(this.id).then((result) => {
            if(!!result.actions && !!result.actions.landingPage && !!result.actions.landingPage.blocks) {
              for(let i = 0; i < result.actions.landingPage.blocks.length; i += 1) {
                result.actions.landingPage.blocks[i]['imageLink'] = result.actions.landingPage.blocks[i].image
                delete result.actions.landingPage.blocks[i].image
              }
            }
  
            this.eventMarketing = result
          })
        },
        updateObject() {
    
        },
        closeDialog() {
          if(!!this.pathName) {
          this.$router.push({name: this.pathName, params: { filters: this.filters }})
          } else {
            this.$router.push({path:this.pathToGoBack})
          }
        }
      },
      watch: {
        openDialog(newVal) {
          this.presetDialog = newVal;
        },
      },
      computed: {
      }
    }
    </script>
    
    <style>
    </style>