const pdfMake = require('pdfmake')

class printTableService {
  constructor() {

  }

  /* 
    options: {
      headers: [
        {
          text: String,
          value: String,
        }
      ] (column names)
      rows: [Object] (row data),
      title: String (table title),
      orientation: 'landscape' | 'portrait' (default: 'portrait'),
      margins: margin to be uses (default: [10, 10, 10, 10]),
      tableLayout: {
        hLineWidth: ({i, node, row}) => Number,
        vLineWidth: ({i, node, row}) => Number,
        hLineColor: ({i, node, row}) => Color Hex,
        paddingLeft: ({i, node, row}) => Number,
        paddingRight: ({i, node, row}) => Number,
      }
    }
  */
  async print(options) {
    pdfMake.fonts = {
      info: {
        title: 'Tabella statistiche'
      },
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      },
    }

    const orientation = options.orientation || 'portrait'
    const margins = options.margins || [10, 10, 10, 10]

    let docDefinition = {
      pageOrientation: orientation,
      pageMargins: margins,
      content: [
        {
          text: options.title,
          style: 'header'
        }, 
        {
          layout: !!options.tableLayout ? 'customTableLayout' : 'lightHorizontalLines',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: options.headers.map((header, index) => {
              return index === 0 ? '*' : 'auto'
            }),
            body: [
              options.headers.map(header => header.text),
              ...options.rows.map(row => {
                return options.headers.map(header => row[header.value] || '')
              })
            ]
          }
        }
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true
        },
      },
    };

    let customTableLayout
    if (!!options.tableLayout) {
      customTableLayout = {
        hLineWidth: function (i, node) {
          const row = options.rows[i - 1]
          if(!!options.tableLayout.hLineWidth) 
            return options.tableLayout.hLineWidth({
              i, node, row
            })
          else {
            if (i === 0 || i === node.table.body.length) {
              return 0;
            }
            return (i === node.table.headerRows) ? 2 : 1;
          }
        },
        vLineWidth: function (i) {
          const row = options.rows[i - 1]
          if (!!options.tableLayout.vLineWidth)
            return options.tableLayout.vLineWidth({
              i, node, row
            })
          else {
            return 0
          }
        },
        hLineColor: function (i, node) {
          const row = options.rows[i - 1]
          if (!!options.tableLayout.hLineColor)
            return options.tableLayout.hLineColor({
              i, node, row
            })
          else {
            return i === 1 ? 'black' : '#aaa';
          }
        },
        paddingLeft: function (i) {
          const row = options.rows[i - 1]
          if (!!options.tableLayout.paddingLeft)
            return options.tableLayout.paddingLeft({
              i, node, row
            })
          else {
            return i === 0 ? 0 : 8;
          }
        },
        paddingRight: function (i, node) {
          const row = options.rows[i - 1]
          if (!!options.tableLayout.paddingRight)
            return options.tableLayout.paddingRight({
              i, node, row
            })
          else {
            return (i === node.table.widths.length - 1) ? 0 : 8;
          }
        }
      }
    }

    pdfMake.createPdf(docDefinition, {
      customTableLayout: customTableLayout 
    }).open()
  }
}

module.exports = new printTableService()