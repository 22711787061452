<template>
  <div>
    <v-text-field
      v-model="localValue"
      :filled="filled"
      :dense="dense"
      :hide-details="hideDetails"
      :label="label"
      :rules="localRules"
      @input="$emit('input', $event)"
    >
      <template v-slot:prepend>
        <slot name="prepend"></slot>
      </template>
      <template v-slot:append-outer>
        <slot name="append-outer"></slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'IpInput',
  data: function() {
    return {
      localValue: this.value,
    }
  },
  props: {
    value: {
      type: String,
    },
    dense: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: String,
      default: 'auto'
    },
    filled: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Indirizzo Ip'
    },
    rules: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: "Indirizzo IP non valido"
    }
  },
  methods: {
    validate(ipAddress) {
      const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      return regex.test(ipAddress)
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  },
  computed: {
    localRules() {
      let validateIp = (value) => {
        if(!this.validate(value)) {
          return this.errorMessage
        } else {
          return true
        }
      }

      return [
        ...this.rules,
        validateIp.bind(this)
      ]
    }
  }
}
</script>

<style>

</style>