<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    :title="dialogTitle"
    :route-father="routeFather"
    id-prop="print"
  >
    <template v-slot:header-actions>
      <div class="d-flex align-center" style="width: 30vw">
        <v-btn
          class="mr-2 ml-2"
          text
          @click="print"
          :loading="loading || printLoading"
        >Stampa</v-btn>
        <v-select
          class="py-1"
          filled
          dense
          hide-details="auto"
          v-model="selectedTimeTable"
          :items="timeTables"
          return-object
          item-text="name"
          item-value="id"
          label="Turno"
          :disabled="isLicenseBlocked"
        ></v-select>
        <v-btn @click="editTurns" icon color="grey darken-2" class="ml-2">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:toolbar-extension>
      <div class="d-flex justify-center align-center" style="width: 100%">
        <v-btn icon @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div style="width: 300px" class="text-center">
          <div>{{ calendarTitle }}</div>
        </div>
        <v-btn icon @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:content>
      <v-calendar
        ref="calendar"
        v-model="focus"
        type="month"
        event-start="date"
        event-end="date"
        :events="shifts"
        color="primary"
        :event-color="eventColor"
        :event-text-color="eventTextColor"
        :event-ripple="false"
        @change="handleCalendarChange"
        @click:more="handleMoreClick"
        @click:day="handleDayClick"
        locale="it"
      >
        <template v-slot:day-label="{day, date}">
          <v-btn icon @click="handleDayClick({date}, $event)">
            {{day}}
          </v-btn>
        </template>
        <template v-slot:event="{event}">
          <StandardMenu>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">{{eventName(event)}}</div>
            </template>
            <v-card width="350px">
              <v-card-title>
                {{eventName(event)}}
                <v-spacer></v-spacer>
                <v-btn icon @click="deleteShift(event)" class>
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div
                  v-for="turn in event.timeTable.timeTablesTurns"
                  :key="turn.id"
                  class="mt-2"
                >Dalle {{turn.start.slice(0, 5)}} alle {{turn.stop.slice(0, 5)}}</div>
              </v-card-text>
            </v-card>
          </StandardMenu>
        </template>
      </v-calendar>
      <EditTurns
        v-model="selectedTimeTable"
        :dialog.sync="editTurnDialog"
        :time-tables.sync="timeTables"
        @on-update="reloadShifts"
      ></EditTurns>
      <DayTimeTableDetail
        v-model="detailShifts"
        :dialog.sync="dayDialog"
        :date="dateDialog">
        
      </DayTimeTableDetail>
    </template>
  </FullScreenDialog>
</template>

<script>
  import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
  import StandardMenu from "@/components/common/StandardMenu.vue";
  import EditTurns from "@/components/areas/presences/turns/EditTurns.vue";
  import shiftService from "@/services/presences/shifts/shifts.service.js";
  import operatorService from "@/services/operators/operators.service.js";
  import colorContrast from "@/mixins/common/colorContrast";
  import colorsService from "@/services/colors/colors.service.js";
  import DayTimeTableDetail from '@/components/areas/presences/turns/DayTimeTableDetail'
  import dateUtils from '@/mixins/common/dateUtils'
  import html2canvas from '@/assets/js/html2canvas.min.js'

  export default {
    name: "EditOperatorTurn",
    components: {
      FullScreenDialog,
      EditTurns,
      StandardMenu,
      DayTimeTableDetail
    },
    mixins:[dateUtils],
    mixins: [colorContrast],
    data: function() {
      let props = this.$router.resolve({
        name: "Turns",
      });

      return {
        selectedTimeTable: undefined,
        timeTables: [],
        shifts: [],
        dialog: true,
        type: "month",
        routeFather: props.href,
        focus: "",
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        editTurnDialog: false,
        calendarTitle: undefined,
        localOperator: { ...this.operator },
        colorMapping: {},
        dayDialog: false,
        dateDialog: undefined,
        detailShifts: [],
        dailyTurn: undefined,
        isLicenseBlocked: undefined,
        loading: false,
        printLoading: false
      };
    },
    props: {
      operator: {
        type: Object
      }
    },
    mounted: function() {
      this.$refs.calendar.checkChange();

      if (!this.localOperator.firstname || !this.localOperator.lastname) {
        this.loadOperator();
      }
    },
    methods: {
      setToday() {
        this.focus = "";
      },
      prev() {
        this.$refs.calendar.prev();
      },
      next() {
        this.$refs.calendar.next();
      },
      editTurns() {
        this.editTurnDialog = true;
      },
      eventName(shift) {
        return shift.timeTable.name;
      },
      eventProps(shift) {
        return shift.timeTable.timeTableTurns;
      },
      deleteShift(shift) {
          shiftService.delete(shift).then(results => {
            this.focus = shift.date
            this.reloadShifts();
          });
      },
      eventColor(shift) {
        const timeTableId = shift.timeTable.id;
        if (!!this.colorMapping[timeTableId]) {
          return this.colorMapping[timeTableId];
        } else {
          this.colorMapping[timeTableId] = colorsService.random(
            Object.values(this.colorMapping)
          );
          return this.colorMapping[timeTableId];
        }
      },
      eventTextColor(shift) {
        return this.blackOrWhiteContrast(this.eventColor(shift));
      },
      loadOperator() {
        operatorService.get(this.localOperator.id).then(result => {
          this.localOperator = result;
        });
      },
      reloadShifts() {
        let currentFocus = new Date()
        if(!!this.focus)
          currentFocus = new Date(this.focus);
          
        let startOfMonth = new Date(
          currentFocus.getFullYear(),
          currentFocus.getMonth(),
          1
        );

        startOfMonth.setHours(0)
        startOfMonth.setMinutes(0)
        let endOfMonth = new Date(
          currentFocus.getFullYear(),
          currentFocus.getMonth() + 1,
          0
        );
        endOfMonth.setHours(23)
        endOfMonth.setMinutes(59)

        this.handleCalendarChange({
          start: {
            date: startOfMonth
          },
          end: {
            date: endOfMonth
          }
        });
      },
      handleCalendarChange({ start, end }) {
        this.calendarTitle = this.$refs.calendar.title;
        if (this.localOperator && this.localOperator.id) {
          shiftService
            .list(1, 400, {
              start: new Date(start.date),
              end: new Date(end.date),
              operatorId: this.localOperator.id
            })
            .then(results => {
              for (let i = 0; i < results.rows.length; i += 1) {
                results.rows[i].date = new Date(results.rows[i].date);
              }
              this.shifts = results.rows;
            });
        }
      },
      handleDayClick({ date }, nativeEvent) {
        nativeEvent.preventDefault()
        nativeEvent.stopPropagation()
        date = new Date(date);
        if (!this.selectedTimeTable) {
          this.$delegates.snackbar("Selezionare almeno un turno da inserire");
          return;
        }
        const alreadyPresentShift = this.shifts.filter(shift => { 
          let compareDate = new Date(date)
          return (new Date(shift.date)).getTime() === compareDate.getTime()
        })
        console.log(alreadyPresentShift.length)
        if(alreadyPresentShift.length == 0) {

          shiftService
            .create(this.selectedTimeTable, this.localOperator, { date: date })
            .then(() => {
              this.focus = date
              this.reloadShifts();
            });                                                                     
        } else {
          this.$delegates.snackbar("E' possibile inserire massimo un turno per giorno");
          return;
        }
      },
      handleMoreClick(params, nativeEvent) {
        nativeEvent.preventDefault()
        nativeEvent.stopPropagation()
        this.detailShifts = this.shifts.filter(el => {
          return (el.date.getMonth() + 1) == params.month && el.date.getDate() == params.day
        })
        this.dayDialog = true
        this.dateDialog = new Date(params.date)
      },
      print() {
        let id = 'print'
        this.printLoading = true

        html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
          let a = document.createElement("a");
          console.log(a, canvas)
          a.download = "Turni_" + this.localOperator.firstname + "_" + this.localOperator.lastname + "_" + this.calendarTitle.replace(' ', '_') + ".png";
          a.href = canvas.toDataURL("image/png");
          a.click(); // MAY NOT ALWAYS WORK!
          this.printLoading = false
        }).catch((error)=>{
          console.error(error)
          this.printLoading = false
        });
      }
    },
    computed: {
      dialogTitle() {
        return !!this.localOperator
          ? this.localOperator.firstname + " " + this.localOperator.lastname
          : "Modifica turni";
      }
    },
    watch: {},
    isLicenseBlocked: {
      bind: 'isLicenseBlocked'
    }
  };
</script>

<style>
</style>