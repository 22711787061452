<template>
  <div>
    <TitleWithBackButton
      title="Categorie Tag"
      back-route="Analytics"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <h2 class="ml-2 mt-2">Centri di Incasso</h2>
    <crudCardList
      :service="service"
      class="ma-2"      
      :new-valid="newFormValid"
      :edit-valid="editFormValid"
      :hide-filter="true"
      :hide-loading="true"
      :deletable="currentUser.system"
      :editable="currentUser.system"
      :creatable="currentUser.system"
      item-type="tagCategories"
      no-data-message="Nessuna categoria tag disponibile"
      :bus="bus"
    >      
      <template v-slot:new-form="{ item }">
        <v-form v-model="newFormValid" class="mt-2">
          <v-text-field
            v-model="item.name"
            label="Nome"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
          ></v-text-field>   
          <div class="mt-2">
            <v-select
              v-model="item.typeCost"
              :items="typeOptions"
              item-text="state"
              item-value="val"
              label="Tipo di centro"      
              hide-details="auto"
              filled
              dense
              @input="onTypeCostChange(item)"
              :rules="[presenceRule]"
            ></v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-select
              v-model="item.profitTagCategoryId"
              :items="tagCategories"
              item-text="name"
              item-value="id"
              label="Categoria tag incasso"
              hide-details="auto"
              filled
              dense
              clearable
            ></v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-checkbox
              v-model="item.allowDistribution"
              label="Permetti Ripartizione Costi"
            ></v-checkbox>
          </div>
          <div class="mt-2" v-if="!item.typeCost">
            <v-select
              v-model="item.excludeInBalance"
              item-text="name"
              item-value="value"
              :items="[{name:'A corrispettivo', value: false}, {name:'Non a corrispettivo', value: true}]"
              filled
              dense
            ></v-select>
          </div>
        </v-form>
      </template>
      <template v-slot:update-form="{ item }">
        <v-form v-model="editFormValid" class="mt-2">
          <v-text-field
            v-model="item.name"
            label="Nome"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
          ></v-text-field>
          <div class="mt-2">
            <v-select
              v-model="item.typeCost"
              :items="typeOptions"
              item-text="state"
              item-value="val"
              label="Tipo di centro"      
              hide-details="auto"
              filled
              dense
              @input="onTypeCostChange(item)"
              :rules="[presenceRule]"
            ></v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-select
              v-model="item.profitTagCategoryId"
              :items="tagCategories"
              item-text="name"
              item-value="id"
              label="Categoria tag incasso"
              hide-details="auto"
              filled
              clearable
              dense
            >
            </v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-checkbox
              v-model="item.allowDistribution"
              label="Permetti Ripartizione Costi"
            ></v-checkbox>
          </div>
          <div class="mt-2" v-if="!item.typeCost">
            <v-select
              v-model="item.excludeInBalance"
              item-text="name"
              item-value="value"
              :items="[{name:'A corrispettivo', value: false}, {name:'Non a corrispettivo', value: true}]"
              filled
              dense
            ></v-select>
          </div>
        </v-form>
      </template>
    </crudCardList>
    <v-divider class="my-5"></v-divider>
    <h2 class="ml-2">Centri di Costo</h2>
    <crudCardList
      :service="serviceCost"
      class="ma-2"      
      :new-valid="newFormValid"
      :edit-valid="editFormValid"
      :hide-filter="true"
      :hide-loading="true"
      :deletable="currentUser.system"
      :editable="currentUser.system"
      :creatable="currentUser.system"
      item-type="tagCategories"
      no-data-message="Nessuna categoria tag disponibile"
      :bus="bus"
    >      
      <template v-slot:new-form="{ item }">
        <v-form v-model="newFormValid" class="mt-2">
          <v-text-field
            v-model="item.name"
            label="Nome"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
          ></v-text-field>   
          <div class="mt-2">
            <v-select
              v-model="item.typeCost"
              :items="typeOptions"
              item-text="state"
              item-value="val"
              label="Tipo di centro"      
              hide-details="auto"
              filled
              dense
              @input="onTypeCostChange(item)"
              :rules="[presenceRule]"
            ></v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-select
              v-model="item.profitTagCategoryId"
              :items="tagCategories"
              item-text="name"
              item-value="id"
              label="Categoria tag incasso"
              hide-details="auto"
              filled
              dense
              clearable
            ></v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-checkbox
              v-model="item.allowDistribution"
              label="Permetti Ripartizione Costi"
            ></v-checkbox>
          </div>
          <div class="mt-2" v-if="!item.typeCost">
            <v-select
              v-model="item.excludeInBalance"
              item-text="name"
              item-value="value"
              :items="[{name:'A corrispettivo', value: false}, {name:'Non a corrispettivo', value: true}]"
              filled
              dense
            ></v-select>
          </div>
        </v-form>
      </template>
      <template v-slot:update-form="{ item }">
        <v-form v-model="editFormValid" class="mt-2">
          <v-text-field
            v-model="item.name"
            label="Nome"
            hide-details="auto"
            filled
            dense
            :rules="[presenceRule]"
          ></v-text-field>
          <div class="mt-2">
            <v-select
              v-model="item.typeCost"
              :items="typeOptions"
              item-text="state"
              item-value="val"
              label="Tipo di centro"      
              hide-details="auto"
              filled
              dense
              @input="onTypeCostChange(item)"
              :rules="[presenceRule]"
            ></v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-select
              v-model="item.profitTagCategoryId"
              :items="tagCategories"
              item-text="name"
              item-value="id"
              label="Categoria tag incasso"
              hide-details="auto"
              filled
              clearable
              dense
            >
            </v-select>
          </div>
          <div class="mt-2" v-if="item.typeCost">
            <v-checkbox
              v-model="item.allowDistribution"
              label="Permetti Ripartizione Costi"
            ></v-checkbox>
          </div>
          <div class="mt-2" v-if="!item.typeCost">
            <v-select
              v-model="item.excludeInBalance"
              item-text="name"
              item-value="value"
              :items="[{name:'A corrispettivo', value: false}, {name:'Non a corrispettivo', value: true}]"
              filled
              dense
            ></v-select>
          </div>
        </v-form>
      </template>
    </crudCardList>
  </div>
</template>

<script>
import Vue from 'vue'

import crudCardList from "@/components/common/crudCardList.vue"
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js"
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "TagsCategories",
  components: {
    crudCardList,
    TitleWithBackButton
  },
  data: function() {
    return {
      bus: new Vue(),
      service: {
        list: () => {
          return tagsCategoriesService.list(1, 4000, {
            tags: true,
            typeCost: false,
          }).then((response) => {
            return response.rows
          })
        },
        create: async (param) => { await tagsCategoriesService.create(param); this.fetchTagCategories() },
        update: tagsCategoriesService.update,
        delete: tagsCategoriesService.delete
      },
      serviceCost: {
        list: () => {
          return tagsCategoriesService.list(1, 4000, {
            tags: true,
            typeCost: true,
          }).then((response) => {
            return response.rows
          })
        },
        create: async (param) => { await tagsCategoriesService.create(param); this.fetchTagCategories() },
        update: tagsCategoriesService.update,
        delete: tagsCategoriesService.delete
      },
      newFormValid: false,
      editFormValid: false,
      typeOptions: [{state:'Centro di incasso', val: false}, {state:'Centro di costo', val: true}],
      tagCategories: [],
      currentUser: {},
      presenceRule: (v) => {
        return v != undefined || 'Il campo è obbligatorio'
      },
    }
  },
  mounted: function() {
    this.fetchTagCategories()
  },
  currentUser: {
    bind: 'currentUser'
  },
  methods: {
    onTypeCostChange(item){
      if(!item.typeCost) {
        item.profitTagCategoryId = undefined
        item.allowDistribution = false
      }
    },
    fetchTagCategories(){
      return new Promise((resolve,reject) => {
        tagsCategoriesService.list(undefined, 2000, { typeCost: false }).then((results) => {
          this.tagCategories = results.rows
          resolve()
        }).catch((err) => {
            reject(err)
        })
      })
    },
  }
}
</script>

<style>

</style>