var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FullScreenDialog',{attrs:{"without-padding":"","title":"Modelli Scontrino","route-father":_vm.routeFather},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center mb-3 flex-column",style:({
          'height': _vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg ? 
            '28%' : '40%',
          'width': '100%'
        })},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',[_c('AdvancedFilter',{staticStyle:{"width":"99%","height":"100%"},attrs:{"filters":_vm.filterTypes,"advance-filters":_vm.advanceFilters,"external-filter":_vm.externalFilter},on:{"apply-filters":_vm.applyFilters},scopedSlots:_vm._u([{key:"chip-custom-receiptTitle",fn:function({filter}){return [_vm._v(" Nome: "+_vm._s(filter.value)+" ")]}},{key:"chip-custom-categories",fn:function({filter}){return [_vm._v(" Reparti: "+_vm._s(filter.value.map(el => el.name).join(', '))+" ")]}},{key:"chip-custom-receiptText",fn:function({filter}){return [_vm._v(" Descrizione: "+_vm._s(filter.value)+" ")]}},{key:"custom-categories",fn:function({filter}){return [_c('ServiceTypesCategoryAutocomplete',{attrs:{"multiple":true,"return-object":true},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}},{key:"custom-receiptText",fn:function({filter}){return [_c('v-text-field',{attrs:{"label":filter.label,"placeholder":filter.label,"outlined":true,"clearable":true,"rows":1,"dense":true,"filled":true,"hide-details":true,"counter":100,"max-height":100,"max-width":300,"min-height":100,"min-width":300},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})]}}]),model:{value:(_vm.filtersValue),callback:function ($$v) {_vm.filtersValue=$$v},expression:"filtersValue"}})],1)],1),_c('v-row',[_c('v-col',[_c('ResponsiveCardSelector',{attrs:{"items":_vm.goals,"item-key":"name","card-max-height":"80","card-min-height":"80","card-max-width":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.md) ? '130' : 
                _vm.$vuetify.breakpoint.lg ? '200' : '250',"card-min-width":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.md) ? '130' : 
                _vm.$vuetify.breakpoint.lg ? '200' : '250',"align-items-item":"center","justify-center":true,"colors":['#ada2e0'],"colorOnSelected":true},on:{"input":() => {
                _vm.fetchReceiptModels()
              }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"text-center",staticStyle:{"font-size":"0.85rem"}},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}]),model:{value:(_vm.selectedGoal),callback:function ($$v) {_vm.selectedGoal=$$v},expression:"selectedGoal"}})],1)],1)],1),_c('div',{ref:"dataTableWrapper",style:({
          'height': _vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg ? 
            '70%' : '58%',
          'overflow-y': 'auto'
        })},[_c('TypeDataTable',{attrs:{"loading-text":"Caricamento modelli ...","headers":_vm.headers,"loading":_vm.loading,"items":_vm.paginatedReceiptModelPresets,"page":_vm.page,"row-per-page":_vm.rowPerPage,"total-pages":_vm.totalPages,"height":_vm.dataTableHeight,"show-select":false,"show-actions":true},on:{"update:page":function($event){_vm.page=$event},"update:rowPerPage":function($event){_vm.rowPerPage=$event},"update:row-per-page":function($event){_vm.rowPerPage=$event},"update:totalPages":function($event){_vm.totalPages=$event},"update:total-pages":function($event){_vm.totalPages=$event}},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"gap":"10px"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return (() => {
                  _vm.selectedReceiptPreset = {
                    ...item,
                    'text': item.text.split(', '),
                    'additionalText': item.additionalText.split(', '),
                  }
                  _vm.previewDialog = true
                }).apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return (() => {
                  _vm.selectedReceiptPreset = {
                    ...item,
                    'text': item.text.split(', '),
                    'additionalText': item.additionalText.split(', '),
                  }
                  if (!!item.type && item.type == 'barcode') {
                    _vm.printBarcodeDialog = true
                  } else {
                    _vm.printReceiptModel()
                  }
                }).apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1)]}}])})],1)]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('StandardDialog',{attrs:{"dialog-height":null,"dialog-max-width":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '90%' : '50%',"title":"Seleziona Promozione"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":() => {
            _vm.selectedPromo = undefined
            _vm.printBarcodeDialog = false
          }}},[_vm._v(" Chiudi ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":() => {
            _vm.selectedReceiptPreset = {
              ..._vm.selectedReceiptPreset,
              'barcode': _vm.selectedPromo.barcode,
              'barcodeId': _vm.selectedPromo.barcode.id
            }
            _vm.printReceiptModel()
            _vm.selectedPromo = undefined
            _vm.printBarcodeDialog = false
          }}},[_vm._v(" Fine ")])],1)]},proxy:true}]),model:{value:(_vm.printBarcodeDialog),callback:function ($$v) {_vm.printBarcodeDialog=$$v},expression:"printBarcodeDialog"}},[_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"mt-3",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" Il seguente modello contiene un barcode. Selezionare la promozione")])])],1),_c('v-row',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%"},attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Seleziona Promozione","items":_vm.promoWithBarcode,"return-object":"","item-text":"name","item-value":"id","filled":"","dense":"","hide-details":"auto"},model:{value:(_vm.selectedPromo),callback:function ($$v) {_vm.selectedPromo=$$v},expression:"selectedPromo"}})],1)],1)],1),_c('StandardDialog',{attrs:{"dialog-height":null,"title":!!_vm.selectedReceiptPreset ? _vm.selectedReceiptPreset.title : '',"dialog-max-width":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '90%' : '50%'},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('div',{staticClass:"d-flex justify-center align-center mt-12"},[_c('ReceiptPreviewForm',{attrs:{"height":null,"width":null,"min-height":'90%',"max-height":'90%',"receipt-model":_vm.selectedReceiptPreset}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }