<template>
  <div class="d-flex justify-center">
    <span :class="isWhite ? 'font-white' : 'font-black'" class="mr-2 d-flex flex-column">
      <div class="text-h4 font-weight-medium">
        <span v-text="hours"></span>:<span v-text="minutes"></span><span v-if="viewSeconds">:{{seconds}}</span>
      </div>
      <span class="text-center" style="font-size:9.5pt" v-text="this.getDay(date)"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "Clock",
  data() {
    const now = new Date();

    return {
      hours:  this.getZeroPadded(now.getHours()),
      minutes: this.getZeroPadded(now.getMinutes()),
      seconds: this.getZeroPadded(now.getSeconds()),
      date: now,
      monthMapped: {
        0: "Gennaio",
        1: "Febbraio",
        2: "Marzo",
        3: "Aprile",
        4: "Maggio",
        5: "Giugno",
        6: "Luglio",
        7: "Agosto",
        8: "Settembre",
        9: "Ottobre",
        10: "Novembre",
        11: "Dicembre",
      }
    };
  },
  props: {
    viewSeconds: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    const timer = window.setInterval(this.updateDateTime, 1000);
    this.$on("hook:destroyed", () => window.clearInterval(timer));
  },
  methods: {
    updateDateTime() {
      const now = new Date();
      this.hours = this.getZeroPadded(now.getHours());
      this.minutes = this.getZeroPadded(now.getMinutes());
      this.seconds = this.getZeroPadded(now.getSeconds());
    },
    getZeroPadded(number) {
      return number < 10 ? '0' + number.toString() : number
    },
    getDay(date){
      return date.getDate() + ' ' + this.monthMapped[date.getMonth()] + ' ' + date.getFullYear()
    }
  },
};
</script>

<style>
.font-white {
  color: white;
}
.font-black {
  color: black;
}
</style>