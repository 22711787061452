var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"chart"}},[_c('TitleWithBackButton',{attrs:{"title":"Fidelizzazione Clienti","back-route":"Analytics"}}),_c('div',{staticClass:"d-flex align-center ml-3 mt-3 flex-wrap"},[_c('RangeDateSelector',{ref:"dateSelector",attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"loading":_vm.loading,"disabled":_vm.loading,"chip-items":[
        {text: 'Ultimi 365 giorni', value: 'last365Days'},
        {text: 'Quest\'anno', value: 'thisYear'},
        {text: 'Scorso anno', value: 'lastYear'},
      ],"max-width":!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
        ? 'fit-content' : this.$vuetify.breakpoint.md ? '70vw' : '50vw'},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event}}}),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":function($event){return _vm.calculate()}}},[_vm._v("Calcola")]),_c('v-spacer'),_c('v-btn',{staticClass:"btn-export",attrs:{"loading":_vm.loading},on:{"click":_vm.exportToExcel}},[_c('v-icon',[_vm._v(" mdi-microsoft-excel ")])],1),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"text":"","loading":_vm.loading,"disabled":!_vm.startDate || !_vm.endDate},on:{"click":_vm.print}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),_c('v-btn',{staticStyle:{"float":"right","margin-right":"2.5%"},attrs:{"icon":"","large":"","disabled":_vm.loading,"color":!!_vm.ghost ? 'red' : ''},on:{"click":() => { _vm.dialogBarcode = !_vm.dialogBarcode }}},[_c('v-icon',[_vm._v("mdi-shield-account-variant")])],1)],1),_c('div',[_c('v-row',{staticClass:"px-2 py-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"py-5"},[_c('span',{staticClass:"card-title ml-10"},[_vm._v(" Clienti Attivi ")])]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '2' : '1'}},[(_vm.loading)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
                height: '100%'
              })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('TypeDataTable',{staticClass:"pb-5",staticStyle:{"background-color":"transparent","width":"70%","min-width":"400px"},attrs:{"fixed-headers":"","headersColor":"transparent","headers":[
                { text: '', value: 'color', type: 'color' },
                { text: 'Categoria', value: 'name' },
                { text: 'N° Clienti', value: 'value', type: 'number' },
              ],"items":_vm.activeCustomerTableData,"show-select":false,"select-on-row-click":false,"show-actions":true,"disablePagination":true,"loading":_vm.loading},on:{"rowClicked":_vm.openActiveCustomers},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('v-row',{staticClass:"d-flex justify-end",staticStyle:{"min-width":"80px"},on:{"mouseover":function($event){return _vm.preFetch(item, 1)}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return (() => { _vm.customersSendMessage = true }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"dense":"","color":"blue darken-2"}},[_vm._v("mdi-message-text-fast")])],1),_c('ExportExcelTable',{attrs:{"worksheetName":_vm.worksheetName,"fileName":_vm.generateExcelName(item, 1),"fieldsToExport":_vm.fieldsToExport,"fetch":_vm.fetchExcel},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"dense":"","color":"green darken-2"}},[_vm._v("mdi-microsoft-excel")])],1)]},proxy:true}],null,true)})],1)]}}])})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '1' : '2'}},[_c('v-card',{attrs:{"width":"400","height":"400","color":"transparent","flat":""}},[(_vm.loading)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
                  height: '100%'
                })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('DoughnutChart',{attrs:{"chart-data":_vm.activeCustomerChartData,"styles":{
                  height: '400px',
                  width: '400px'
                },"options":_vm.chartOptions}})],1)],1)],1),(_vm.selectedActiveCustomer)?_c('v-row',{staticClass:"py-5"},[_c('span',{staticClass:"card-title ml-10"},[_vm._v(" Dettaglio Clienti Attivi ")])]):_vm._e(),(_vm.selectedNewCustomer)?_c('v-row',{staticClass:"py-5"},[_c('span',{staticClass:"card-title ml-10"},[_vm._v(" Dettaglio Nuovi Clienti ")])]):_vm._e(),(_vm.selectedInactiveCustomer)?_c('v-row',{staticClass:"py-5"},[_c('span',{staticClass:"card-title ml-10"},[_vm._v(" Dettaglio Clienti Inattivi ")])]):_vm._e(),(_vm.selectedActiveCustomer || _vm.selectedNewCustomer
          || _vm.selectedInactiveCustomer)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '2' : '1'}},[(_vm.selectedActiveCustomer || _vm.selectedNewCustomer || _vm.selectedInactiveCustomer)?_c('TypeDataTable',{staticClass:"pb-5",staticStyle:{"background-color":"transparent","min-width":"400px"},attrs:{"fixed-headers":"","headersColor":"transparent","headers":[
                { text: '', value: 'color', type: 'color' },
                { text: 'Categoria', value: 'name' },
                { text: 'N° Clienti', value: 'value', type: 'number' },
              ],"items":_vm.selectedActiveCustomer ? _vm.activeCustomerDetailTableData : _vm.selectedNewCustomer ? 
                _vm.newCustomerDetailTableData : _vm.inactiveCustomerDetailTableData,"show-select":false,"select-on-row-click":false,"show-actions":true,"disablePagination":true,"loading":_vm.loadingSecondLevel},on:{"rowClicked":_vm.openActiveCustomersDetail},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('v-row',{on:{"mouseover":function($event){return _vm.preFetch(item, 2)}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return (() => { _vm.customersSendMessage = true }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"dense":"","color":"blue darken-2"}},[_vm._v("mdi-message-text-fast")])],1),_c('ExportExcelTable',{attrs:{"worksheetName":_vm.worksheetName,"fileName":_vm.generateExcelName(item, 2),"fieldsToExport":_vm.fieldsToExport,"fetch":_vm.fetchExcel},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"dense":"","color":"green darken-2"}},[_vm._v("mdi-microsoft-excel")])],1)]},proxy:true}],null,true)})],1)]}}],null,false,8360031)}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6","order":this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '1' : '2'}},[_c('v-card',{attrs:{"width":"420","height":"400","color":"transparent","flat":""}},[(_vm.loading || _vm.loadingSecondLevel)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center",style:({
                  height: '100%'
                })},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('DoughnutChart',{attrs:{"chart-data":_vm.selectedActiveCustomer ? _vm.activeCustomerDetailChartData : _vm.selectedNewCustomer ? 
                  _vm.newCustomerDetailChartData : _vm.inactiveCustomerDetailChartData,"styles":{
                  height: '400px',
                  width: '420px'
                },"options":_vm.chartOptions}})],1)],1)],1):_vm._e(),(!!_vm.selectedActiveCustomerDetailDataset && _vm.selectedActiveCustomer)?_c('v-row',{staticClass:"py-5"},[_c('span',{staticClass:"card-title ml-10"},[_vm._v(" Dettaglio del Dettaglio Clienti Attivi ")])]):_vm._e(),_c('div',{attrs:{"id":"active-customer-detail"}},[(!!_vm.selectedActiveCustomerDetailDataset && _vm.selectedActiveCustomer)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-card',{attrs:{"height":"750","color":"transparent","flat":""}},[_c('v-row',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"60%"}},[(!!_vm.selectedActiveCustomerDetailDataset)?_c('GroupBasedChart',{staticStyle:{"width":"80vw"},attrs:{"transparent":"","type":"bar","data":_vm.activeCustomerDetailDataset[_vm.selectedActiveCustomerDetailDataset],"getYValue":(object) => {
                      return object[`value`];
                    },"getXValue":(object) => {
                      return object[`category`];
                    },"tooltip-label-modifier":(tooltipLabel, idk) => {
                      return idk.datasets[tooltipLabel.datasetIndex].label + ': ' + tooltipLabel.yLabel;
                    },"loading":_vm.loading}}):_vm._e()],1),_c('v-row',{staticStyle:{"height":"40%"}},[(!!_vm.selectedActiveCustomerDetailDataset)?_c('TypeDataTable',{staticStyle:{"background-color":"transparent","width":"100%","height":"100%","overflow-x":"auto"},attrs:{"fixed-headers":"","headersColor":"transparent","headers":[
                    { text: 'N° Clienti', value: 'countCustomer', type: 'number' },
                    { text: 'N° Settimane', value: 'nWeek', type: 'number' },
                    { text: '%', value: 'perc' },
                    { text: 'Target Fidelizzazione', value: 'name' },
                  ],"items":_vm.activeCustomerDetailDataSetTableData[_vm.selectedActiveCustomerDetailDataset],"show-select":false,"select-on-row-click":false,"disablePagination":true},on:{"rowClicked":_vm.loadFrequentServicesCustomers},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('v-row',{on:{"mouseover":function($event){return _vm.preFetch(item, 3)}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return (() => { _vm.customersSendMessage = true }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"dense":"","color":"blue darken-2"}},[_vm._v("mdi-message-text-fast")])],1),_c('ExportExcelTable',{attrs:{"worksheetName":_vm.worksheetName,"fileName":_vm.fileNameThirdLevel,"fieldsToExport":_vm.fieldsToExport,"fetch":_vm.fetchExcel},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"dense":"","color":"green darken-2"}},[_vm._v("mdi-microsoft-excel")])],1)]},proxy:true}],null,true)})],1)]}}],null,false,734826235)}):_vm._e()],1)],1)],1):_vm._e()],1),(!!_vm.selectedActiveCustomerDetailDataset && _vm.selectedNewCustomer)?_c('v-row',{staticClass:"py-5"},[_c('span',{staticClass:"card-title ml-10"},[_vm._v(" Dettaglio del Dettaglio Nuovi Clienti ")])]):_vm._e(),_c('div',{attrs:{"id":"new-customer-detail"}},[(!!_vm.selectedActiveCustomerDetailDataset && _vm.selectedNewCustomer)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-card',{attrs:{"height":"750","color":"transparent","flat":""}},[_c('v-row',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"60%"}},[(!!_vm.selectedActiveCustomerDetailDataset)?_c('GroupBasedChart',{staticStyle:{"width":"80vw"},attrs:{"transparent":"","type":"bar","data":_vm.newCustomerDetailDataset[_vm.selectedActiveCustomerDetailDataset],"getYValue":(object) => {
                      return object[`value`];
                    },"getXValue":(object) => {
                      return object[`category`];
                    },"tooltip-label-modifier":(tooltipLabel, idk) => {
                      return idk.datasets[tooltipLabel.datasetIndex].label + ': ' + tooltipLabel.yLabel;
                    },"loading":_vm.loading}}):_vm._e()],1),_c('v-row',{staticStyle:{"height":"40%"}},[(!!_vm.selectedActiveCustomerDetailDataset)?_c('TypeDataTable',{staticStyle:{"background-color":"transparent","width":"80vw","height":"100%"},attrs:{"fixed-headers":"","headersColor":"transparent","headers":[
                    { text: 'N° Clienti', value: 'countCustomer', type: 'number' },
                    { text: 'N° Settimane', value: 'nWeek', type: 'number' },
                    { text: '%', value: 'perc' },
                    { text: 'Target Fidelizzazione', value: 'name' },
                  ],"items":_vm.newCustomerDetailDataSetTableData[_vm.selectedActiveCustomerDetailDataset],"show-select":false,"select-on-row-click":false,"disablePagination":true},on:{"rowClicked":_vm.loadFrequentServicesCustomers},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [_c('v-row',{on:{"mouseover":function($event){return _vm.preFetch(item, 3)}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return (() => { _vm.customersSendMessage = true }).apply(null, arguments)}}},[_c('v-icon',{attrs:{"dense":"","color":"blue darken-2"}},[_vm._v("mdi-message-text-fast")])],1),_c('ExportExcelTable',{attrs:{"worksheetName":_vm.worksheetName,"fileName":_vm.fileNameThirdLevel,"fieldsToExport":_vm.fieldsToExport,"fetch":_vm.fetchExcel},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"dense":"","color":"green darken-2"}},[_vm._v("mdi-microsoft-excel")])],1)]},proxy:true}],null,true)})],1)]}}],null,false,734826235)}):_vm._e()],1)],1)],1):_vm._e()],1),(_vm.loadingCustomerServices)?_c('div',{staticClass:"text-caption font-weight-light d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),(!!_vm.selectedFrequentSTCustomersDataset 
          && _vm.selectedFrequentSTCustomersDataset.length > 0)?_c('v-row',{staticClass:"py-5"},[_c('span',{staticClass:"card-title ml-10"},[_vm._v(" Famiglie più utilizzate ")])]):_vm._e(),_c('div',{attrs:{"id":"frequent-customer-service"}},[(!!_vm.selectedFrequentSTCustomersDataset 
            && _vm.selectedFrequentSTCustomersDataset.length > 0)?_c('v-row',{staticClass:"d-flex justify-center mb-2"},[_c('v-card',{attrs:{"height":"750","color":"transparent","flat":""}},[_c('v-row',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"60%"}},[(!!_vm.selectedFrequentSTCustomersDataset && _vm.selectedFrequentSTCustomersDataset.length > 0)?_c('GroupBasedChart',{staticStyle:{"width":"80vw"},attrs:{"transparent":"","type":"bar","data":_vm.selectedFrequentSTCustomersDataset,"getYValue":(object) => {
                      return object[`numServices`];
                    },"getXValue":(object) => {
                      return object[`serviceTypeName`];
                    },"tooltip-label-modifier":(tooltipLabel, idk) => {
                      return idk.datasets[tooltipLabel.datasetIndex].label + ': ' + tooltipLabel.yLabel;
                    },"loading":_vm.loadingCustomerServices}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('StandardDialog',{attrs:{"title":"Area Riservata Sviluppatori","dialog-height":null,"dialog-max-width":"500px"},model:{value:(_vm.dialogBarcode),callback:function ($$v) {_vm.dialogBarcode=$$v},expression:"dialogBarcode"}},[_c('ManualBarcodeInput',{attrs:{"scan-your-barcode-message":"Immettere Codice"},on:{"confirm":_vm.activeBarcode},model:{value:(_vm.operatorBarcode),callback:function ($$v) {_vm.operatorBarcode=$$v},expression:"operatorBarcode"}})],1),_c('div',[_c('SendCustomersSms',{attrs:{"dialog-height":null,"customers":_vm.messageCustomers,"loading":_vm.smsLoading},on:{"fetch-customers":_vm.smsFetchCustomers},model:{value:(_vm.customersSendMessage),callback:function ($$v) {_vm.customersSendMessage=$$v},expression:"customersSendMessage"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }