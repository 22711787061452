<template>
  <div class="pa-2">
    <v-subheader>Tutte le analisi statistiche</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="analyticUtilities"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          class="border-default mx-2 my-2 d-flex flex-column"
          @click="handleListClick(card)"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
        <div
            v-if="Array.isArray(card.text)"
            class="text-center text-h6"
            style="margin-bottom: -28px"
          >
            <div v-for="t in card.text" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else class="text-center text-h6 pt-2" @click="handleListClick(card)">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large>{{ card.icon }}</v-icon>
          </div>
        </v-card>
      </template>
    </CardList>
    <v-subheader>Settings</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="analyticSettings"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          @click="handleListClick(card)"
          class="border-default  mx-2 my-1 d-flex flex-column"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div
            v-if="Array.isArray(card.text)"
            class="text-center text-h6"
            style="margin-bottom: -28px"
          >
            <div v-for="t in card.text" :key="t">
              {{ t }}
            </div>
          </div>
          <div v-else class="text-center text-h6 pt-2" @click="handleListClick(card)">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large>{{ card.icon }}</v-icon>
          </div>
        </v-card>
      </template>
    </CardList>
    <StandardDialog
      v-model="showBarcode"
      title="Visualizza Analitiche"
      dialog-max-width="500px"
      persistent
    >
      <div>
        <v-row>
          <v-col>
            <ManualBarcodeInput
              v-model="analyticsBarcode"
              @confirm="viewAnalyticsPermission"
            >
            </ManualBarcodeInput>
          </v-col>
        </v-row>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$router.push('/')"
        >
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import { CardList, SearchWithButton } from "likablehair-ui-components";
import analyticService from "@/services/analytics/analytics.service.js";
import CollapsableList from '@/components/common/CollapsableList'
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import operatorService from '@/services/operators/operators.service.js';

export default {
  name: "AnalyticsHome",
  components: {
    CollapsableList,
    TitleWithBackButton,
    CardList,
    SearchWithButton,
    ManualBarcodeInput,
    StandardDialog
  },
  data: function() {
    return {
      analyticList: [],
      analyticsBarcode: undefined,
      showBarcode: false,
      viewBar: undefined,

      viewFinancialByTags: undefined,
      viewBalanceByTags: undefined,
      viewOperatorsBalance: undefined,
      viewCustomersByFidelization: undefined,
      viewCustomersTurnover: undefined,
      viewCustomersByAgeGroup: undefined,
      viewTopSellingItems: undefined,
      viewTimeRanges: undefined,
      viewHistoryOperations: undefined,
      viewServiceConsume: undefined,
      viewWarehouseStatus: undefined,
      viewBistrotBalance: undefined,
      viewBalanceByYear: undefined,
      viewServiceTypesBalance: undefined,
      viewGoalsBalance: undefined,

      isOnlyWheelOfFortune: undefined,
    }
  },
  mounted() {
    if (this.$route.params.showBarcode != undefined)
      this.showBarcode = this.$route.params.showBarcode
    this.loadAnalytics();
  },
  methods: {
    goToAnalytic(analytic) {
      this.$router.push({ name: analytic.route })
    },
    handleListClick(section) {
      this.goTo(section.listRoute);
      
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    loadAnalytics() {
      analyticService.list().then((data) => {
        this.analyticList = data;
      });
    },
    viewAnalyticsPermission() {      
      operatorService.canPerformOperation(this.analyticsBarcode, "Ghost").then((result) => {
        if (result) {
          
        } else {
          this.$router.push({path: '/'})
          alert("Non hai i permessi per eseguire questa azione!")    
        }
      }).catch(() => {
        this.$router.push({path: '/'})
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false;
      this.handleTimeLogBarcode = undefined;
    },
  },
  computed: {
    analyticUtilities() {
      return this.analyticList.filter((el) => {
        if(el.name == 'financialByTags' && (!this.viewFinancialByTags.active))
          return false
        if(el.name == 'balanceByTags' && (!this.viewBalanceByTags.active))
          return false
        if(el.name == 'operatorsBalance' && (!this.viewOperatorsBalance.active))
          return false
        if(el.name == 'customersByFidelization' && (!this.viewCustomersByFidelization.active))
          return false
        if (el.name == 'customersTurnover' && (!this.viewCustomersTurnover.active))
          return false
        if(el.name == 'customersByAgeGroup' && (!this.viewCustomersByAgeGroup.active))
          return false
        if(el.name == 'topSellingItems' && (!this.viewTopSellingItems.active))
          return false
        if(el.name == 'timeRanges' && (!this.viewTimeRanges.active))
          return false
        if(el.name == 'historyOperations' && (!this.viewHistoryOperations.active))
          return false
        if(el.name == 'serviceConsume' && (!this.viewServiceConsume.active))
          return false
        if(el.name == 'warehouseStatus' && (!this.viewWarehouseStatus.active))
          return false
        if(el.name == 'bistrotBalance' && (!this.viewBistrotBalance.active))
          return false
        if(el.name == 'balanceByYear' && (!this.viewBalanceByYear.active))
          return false
        if(el.name == 'serviceTypesBalance' && (!this.viewServiceTypesBalance.active))
          return false
        if(el.name == 'goalsBalance' && (!this.viewGoalsBalance.active))
          return false

        return [
          "financialByTags",
          "balanceByTags",
          "operatorsBalance",
          "customersByFidelization",
          "customersTurnover",
          "customersByAgeGroup",
          "topSellingItems",
          "timeRanges",
          "historyOperations",
          "serviceConsume",
          "warehouseStatus",
          "bistrotBalance",
          "balanceByYear",
          "serviceTypesBalance",
          "goalsBalance",
        ].includes(el.name);
      });
    },
    analyticSettings() {
      return this.analyticList.filter((el) => {
        return [
          "tags",
          "tagsCategories"
        ].includes(el.name);
      });
    },
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    },

    viewFinancialByTags: {
      bind: "viewFinancialByTags"
    },
    viewBalanceByTags: {
      bind: "viewBalanceByTags"
    },
    viewOperatorsBalance: {
      bind: "viewOperatorsBalance"
    },
    viewCustomersByFidelization: {
      bind: "viewCustomersByFidelization"
    },
    viewCustomersTurnover: {
      bind: "viewCustomersTurnover"
    },
    viewCustomersByAgeGroup: {
      bind: "viewCustomersByAgeGroup"
    },
    viewTopSellingItems: {
      bind: "viewTopSellingItems"
    },
    viewTimeRanges: {
      bind: "viewTimeRanges"
    },
    viewHistoryOperations: {
      bind: "viewHistoryOperations"
    },
    viewServiceConsume: {
      bind: "viewServiceConsume"
    },
    viewWarehouseStatus: {
      bind: "viewWarehouseStatus"
    },
    viewBistrotBalance: {
      bind: "viewBistrotBalance"
    },
    viewBalanceByYear: {
      bind: "viewBalanceByYear"
    },
    viewServiceTypesBalance: {
      bind: "viewServiceTypesBalance"
    },
    viewGoalsBalance: {
      bind: "viewGoalsBalance"
    },
  },
  isOnlyWheelOfFortune: {
    bind: 'isOnlyWheelOfFortune'
  }
}
</script>

<style scoped>
.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1);
  background-image: linear-gradient(rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  width: 200px;
  height: 190px;
  background-color: white;
}
.border-default:hover {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1.05);
  z-index: 10;
}
</style>