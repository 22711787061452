<template>
  <v-overlay :value="localValue">
    <v-alert
      v-model="localValue"
      :border="border"
      :prominent="prominent"
      :dismissible="dismissible"
      :style="{width: this.width, backgroundImage: this.backgroundImage, zIndex: 100}"
      :color="color"
      :icon="icon"
      @input="$emit('close-popup')"
      dark
    >
      <v-col>
        <v-row :class="textClass">
         <span style="white-space: pre-wrap;width:100%">{{ alertText }}</span>
        </v-row>
        <slot name="content">
        </slot>
        <slot name="footer">
        </slot>
      </v-col>
    </v-alert>
  </v-overlay>
</template>

<script>
export default {
  name: "SimplePopUp",
  components: {},
  data: function () {
    return {
      localValue: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    alertText: {
      type: String,
      default: ''
    },
    border: {
      type: String,
      default: 'left'
    },
    prominent: {
      type: Boolean,
      default: true
    },
    backgroundImage: {
      type: String,
      default: 'linear-gradient(45deg,rgb(138, 119, 226, 1),rgb(80, 191, 225, 1))'
    },
    color: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: '30vw'
    },
    dismissible: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'mdi-alert'
    },
    textClass: {
      type: Object,
      default: () => {
        return {
          'text-center': true,
        }
      }
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    localValue(newVal) {
      if (newVal != this.value) {
        this.$emit("input", newVal);
      }
    },
  },
};
</script>