import authService from "@/services/auth/auth.service";
const paymentDebitsRoutes = [
  {
    path: "/payments/paymentDebits",
    name: "PaymentDebitsNewForm",
    component: () => import("@/components/areas/payments/paymentDebits/PaymentDebitsNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/paymentDebits/:id/edit",
    name: "PaymentDebitsEditForm",
    component: () => import("@/components/areas/payments/paymentDebits/PaymentDebitsEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
  },
  {
    path: "/payments/paymentDebits/list",
    name: "PaymentDebitsList",
    component: () =>
      import(
        "@/components/areas/payments/paymentDebits/PaymentDebitsList.vue"
      ),
  },
  {
    path: "/payments/operatorPaymentDebits",
    name: "OperatorPaymentDebitsNewForm",
    component: () => import("@/components/areas/payments/paymentDebits/operatorPaymentDebit/OperatorPaymentDebitsNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/operatorPaymentDebits/:id/edit",
    name: "OperatorPaymentDebitsEditForm",
    component: () => import("@/components/areas/payments/paymentDebits/operatorPaymentDebit/OperatorPaymentDebitsEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
  },
  {
    path: "/payments/operatorPaymentDebits/list",
    name: "OperatorPaymentDebitsList",
    component: () =>
      import(
        "@/components/areas/payments/paymentDebits/operatorPaymentDebit/OperatorPaymentDebitsList.vue"
      ),
  }
];

export default paymentDebitsRoutes;