<template>
  <FullScreenDialog
    v-model="supplierDialog"
    title="Aziende"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in supplierTabs" :key="tab.key">{{
          tab.title
        }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in supplierTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="$router.push({ path: pathToGoBack })"
          >Annulla</v-btn
        >
        <v-btn text color="default" :disabled="!formValid" @click="submitForm" :loading="loading"
          >Salva</v-btn
        >
      </div>
    </template>

    <template v-slot:header-actions>
      <div>
        <StandardMenu close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error--text"
                  >Resetta</v-list-item-content
                >
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import supplierService from "@/services/suppliers/suppliers.service.js";
import supplierForm from "@/services/suppliers/supplier.form.js";

import SuppliersRegistryNewFormTab from "@/components/areas/registries/suppliers/new/SuppliersRegistryNewFormTab.vue";

export default {
  name: "SupplierRegistryForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    SuppliersRegistryNewFormTab,
  },
  data: function () {
    return {
      originalUrl: window.location.pathname,
      tab: null,
      supplierDialog: this.openDialog,
      supplierTabs: this.tabs,
      formValid: false,
      supplier: undefined,
      loading: false,
    };
  },
  mounted() {
    let self = this
    supplierForm.on('update', function(data) {
      self.supplier = data.supplier
    })

    self.valid = supplierForm.valid
    supplierForm.on('valid-change', function(valid) {
      self.formValid = valid
    })

    supplierForm.resetSupplier();
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/registries/suppliers/list",
    },
    tabs: {
      type: Array,
      default: function () {
        return [
          {
            key: 0,
            title: "Generale",
            name: "general",
            component: "SuppliersRegistryNewFormTab",
          }
        ];
      },
    },
  },
  methods: {
    submitForm() {
      this.loading = true;
      supplierService
        .create(this.supplier)
        .then((result) => {
          this.loading = false;
          if(typeof result == "string"){
            this.$delegates.snackbar('Partita IVA già presente nel database')
          } else {
            if(!!this.pathToGoBack)
              this.$router.push({ path: this.pathToGoBack });
            else
              this.$emit('submit', result)
          }
        }).catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    openDialog(newVal) {
      this.supplierDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.supplierTabs[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
  computed: {
  },
};
</script>

<style>
</style>