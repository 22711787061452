import EventsHandler from '@/services/common/eventsHandler'

class Privileges extends EventsHandler {
  constructor() {
    super()
  }

  /* 
    params: {
      timeout: Number (seconds before reacquiring default privileges)
    }
  */
  async call(params) {
    if(!params) params = {}
    if (!params.timeout) params.timeout = 120
    return await this._executeAsyncCallbacksOf('call', params)
  }
}

export default new Privileges()