const qualificationsRoutes = [
  {
    path: "/registries/qualifications",
    name: "QualificationsRegistryNewForm",
    component: () => import("@/components/areas/registries/qualifications/QualificationsRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
  },
  {
    path: "/registries/qualifications/:id/edit",
    name: "QualificationsRegistryEditForm",
    component: () => import("@/components/areas/registries/qualifications/QualificationsRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'registry',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/registries/qualifications/list",
    name: "QualificationsRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/qualifications/QualificationsRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default qualificationsRoutes;