<template>
  <v-dialog
    v-model="localValue"
    transition="scroll-y-transition"
    :max-height="dialogMaxHeight"
    :max-width="dialogMaxWidth"
    :min-height="dialogMinHeight"
    :min-width="dialogMinWidth"
    :height="dialogHeight"
    :width="dialogWidth"
    :persistent="persistent"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"> </slot>
    </template>
    <v-card :height="dialogHeight" class="flex-container" :id="idProp">
      <v-card-title 
        ref="header" 
        style="height: fit-content;"
      >
      <v-tooltip>
      </v-tooltip>
      <v-icon 
        class="mr-4"
        @click="$emit('pre-icon-click')"
        :v-if="!preIconTitle" 
        :color="preIconTitleColor"> 
        {{preIconTitle}}
      </v-icon>
        {{ this.title }}
        <v-spacer></v-spacer>
        <slot name="header-actions"></slot>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text 
        style="overflow-y: auto;"
        :style="{
          'max-height': bodyMaxHeight
        }"
        :class="{ 'pa-0': withoutPadding }"
        ref="body"
      >
        <slot></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions 
        ref="footer"
        style="height: fit-content;"
      >
        <slot name="footer">
          <v-spacer></v-spacer>
          <slot name="footer-actions">
            <v-btn text color="error" v-on:click="localValue = false">
              {{closeButtonText}}
            </v-btn>
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardDialog",
  data: function () {
    return {
      localValue: this.value,
      bodyMaxHeight: undefined
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
    dialogMinWidth: {
      type: String,
      default: "20dvh",
    },
    dialogMinHeight: {
      type: String,
      default: "20dvh",
    },
    dialogMaxWidth: {
      type: String,
      default: "90vw",
    },
    dialogMaxHeight: {
      type: String,
      default: "90dvh",
    },
    dialogWidth: {
      type: String,
      default: "90vw",
    },
    dialogHeight: {
      type: String,
      default: "90dvh",
    },
    closeButtonText: {
      type: String,
      default: 'Chiudi'
    },
    preIconTitleColor:{
      type: String,
      default: 'default'
    },
    preIconTitle :{
      type: String,
      default: undefined
    },
    tooltipPreIconTitle:{
      type: String,
      default: 'tooltip'
    },
    idProp: {
      type: String,
      default: undefined
    }
  },
  mounted: function() {
    if(this.scrollable) {
      this.calculateBodyHeight()
    }
  },
  methods: {
    calculateBodyHeight() {
      const header = this.$refs.header, footer = this.$refs.footer, body = this.$refs.body
      if(!!header && !!footer && !!body) {
        this.bodyMaxHeight = "calc(" + this.dialogMaxHeight + " - " + header.offsetHeight + "px - " + footer.offsetHeight + "px - 2px)"
      }
      this.$emit('calc-body-height', this.bodyMaxHeight)
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    localValue(newVal) {
      if (newVal != this.value) {
        this.$emit("input", newVal);
      }

      if(this.scrollable) {
        setTimeout(() => {
          this.calculateBodyHeight()
        }, 100);
      }
    },
  },
};
</script>

<style>

.flex-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.body {
  flex: 0 0 auto;
  flex-shrink: 1;
  flex-grow: 1
}
</style>