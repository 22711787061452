<template>
  <div class="d-flex justify-center align-center" v-if="loadingSmsModels">
    <v-progress-circular indeterminate class="mt-2"></v-progress-circular>
  </div>
  <div v-else>
    <CollapsableList
      :items="smsModelsAndCustomText"
      item-key="id"
      item-title="name"
    >
      <template v-slot:expansion="{item}">
        <SmsTextVisualizer
          v-if="item.id != '_personalized'"
          :value="item.text"
        ></SmsTextVisualizer>
        <v-textarea
          filled
          hide-details="auto"
          label="Testo del messaggio"
          v-model="customTextMessage"
          v-else
          @click.stop.prevent
          counter
        >
          <template v-slot:counter="{ props }">
            <span v-bind="props" :style="{
              'color': countCharStringColor
            }">{{ countCharString }} </span>
          </template>
        </v-textarea>
      </template>
      <template v-slot:extra-icons="{item}">
        <v-btn
          v-if="item.id != '_personalized'"
          icon
          @click="handleEditSmsModel(item)"
        >
          <v-icon medium>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="item.id != '_personalized'"
          icon 
          class="ml-2"
          :loading="sendingSms"
          @click.stop.prevent="sendModel(item)"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon 
          class="ml-2" 
          :disabled="item.id == '_personalized' && !customTextMessage"
          @click.stop.prevent="sendCustomText"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
    </CollapsableList>

    <StandardDialog
      v-model="editSmsModelDialog" 
      :title="editSmsModelDialogTitle" 
      :dialog-height="null" 
      persistent
      dialog-width="1000"
    >
      <div>
        <SmsModelForm 
          v-model="editSmsModel" 
          class="py-2"
          :valid.sync="editSmsModelValid"
          :hide-upload-image="true"
        ></SmsModelForm>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn 
          text 
          color="error" 
          @click="editSmsModelDialog = false" 
          :disabled="loadingUpdate"
        >Annulla</v-btn>
        <v-btn 
          text 
          color="default" 
          @click="updateSmsModel"
          :loading="loadingUpdate" 
          :disabled="!editSmsModelValid"
        >Salva</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import smsModelsService from '@/services/smsModels/smsModels.service.js'
import smsService from '@/services/sms/sms.service.js'
import CollapsableList from '@/components/common/CollapsableList.vue'
import SmsTextVisualizer from '@/components/setting/sms/SmsTextVisualizer.vue'
import SmsModelForm from '@/components/setting/sms/SmsModelForm.vue'
import StandardDialog from '@/components/common/StandardDialog.vue';
import settingService from "@/services/settings/settings.service.js";

export default {
  name: "SendSmsModel",
  components: {
    SmsTextVisualizer,
    CollapsableList,
    SmsModelForm,
    StandardDialog
  },
  data: function() {
    return { 
      smsModels: [],
      loadingSmsModels: false,
      customTextMessage: '',
      sendingSms: false,
      editSmsModelDialog: false,
      editSmsModelValid: false,
      editSmsModel: {},
      loadingUpdate: false,
      whatsappNumber: undefined,
    }
  },
  mounted: function() {
    this.fetchSmsModels();
    this.loadSettings();
  },
  props: {
    customers: {
      type: Array,
      default: () => []
    },
    numbers: {
    },
    eventGroup: {
    },
    whatsapp: {
      default: false
    }
  },
  methods: {
    fetchSmsModels() {
      this.loadingSmsModels = true
      smsModelsService.list().then((models) => {
        this.smsModels = models.rows
        this.loadingSmsModels = false
      })
    },
    loadSettings: async function () {
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    sendModel(primaryModel) {
      let model = {...primaryModel}
      if(!!this.customers && !!this.numbers) {
        let phoneNumbers = []
        this.sendingSms = true
        let params = {}
        if(!!model.values && model.values.length > 0) {
          for(let i = 0; i < model.values.length; i += 1) {
            for (let j = 0; j < this.customers.length; j += 1) {
              let substitutionText
              if(model.values[i].name == 'customer.firstname' && !!this.customers[j].firstname) {
                substitutionText = this.customers[j].firstname
                model.text = model.text.replace('<customer.firstname>', substitutionText)
              } else if(model.values[i].name == 'customer.lastname' && !!this.customers[j].lastname) {
                substitutionText = this.customers[j].lastname
                model.text = model.text.replace('<customer.lastname>', substitutionText)
              } else if(model.values[i].name == 'customer.fullname' && !!this.customers[j].firstname && !!this.customers[j].lastname) {
                substitutionText = `${this.customers[j].firstname} ${this.customers[j].lastname}`
                model.text = model.text.replace('<customer.fullname>', substitutionText)
                params[model.values[i].fullname] = substitutionText
              } else if(model.values[i].name == 'customer.appointment') {
                let minutes = this.eventGroup.startDate.getMinutes()
                if( minutes < 10 )
                  minutes = "0" + minutes
                substitutionText = `${this.eventGroup.startDate.getHours()}:${minutes}`
                model.text = model.text.replace('<customer.appointment>', substitutionText)
                params[model.values[i].name] = substitutionText
              } else if (model.values[i].name == 'customer.date') {
                substitutionText = `${this.eventGroup.startDate.getDate() + "-" + (this.eventGroup.startDate.getMonth() + 1) + "-" + this.eventGroup.startDate.getFullYear()}`
                model.text = model.text.replace('<customer.date>', substitutionText)
                params[model.values[i].name] = substitutionText
              } else if(model.values[i].name == 'customer.service') {
                let serviceName = [];
                for (let k = 0; k < this.eventGroup.eventGroups[0].eventsInGroup.length; k++) {
                  if (!!this.eventGroup.eventGroups[0].eventsInGroup[k].eventServices[0])
                    serviceName.push(this.eventGroup.eventGroups[0].eventsInGroup[k].eventServices[0].service.name + ' eseguito da ' + this.eventGroup.eventGroups[0].eventsInGroup[k].eventServices[0].operators[0].firstname )
                }
                model.text = model.text.replace('<customer.service>', serviceName.join(", "))
              } else if(model.values[i].name == 'customer.operator') {
                  substitutionText = `${this.eventGroup.eventServices[0].operators[0].firstname}`
                  model.text = model.text.replace('<customer.operator>', '')
                  params[model.values[i].name] = substitutionText
              } else if(model.values[i].name == 'customer.date') {
                substitutionText = `${this.eventGroup.startDate.getDate()}/${(this.eventGroup.startDate.getMonth())+1}`
                model.text = model.text.replace('<customer.date>', substitutionText)
                params[model.values[i].name] = substitutionText
              }
              params[model.values[i].name] = substitutionText
            }
          }
        }
        this.numbers.map((number) => {
          phoneNumbers.push(number)
        })

        for (let i= 0; i < this.customers.length; i++){
          if(!!this.customers[i].sendByWhatsapp || this.whatsapp)
          {
            this.numbers[i] = this.numbers[i].replace("+","")
            smsService.sendWhatsappMessage(model.text, this.whatsappNumber, this.numbers[i], this.customers[i].id).then((response) => {
              this.emit('input')
              this.$delegates.snackbar("Messaggio Whatsapp inviato con successo");
            }).catch((error) => {
              this.emit('input')
              this.$delegates.snackbar("Errore nell'invio del messaggio Whatsapp");
            })
          } else {
            smsService.sendSmsEsendex(model.text, phoneNumbers, this.customers[i].id).then((response) => {
              this.$delegates.snackbar("Sms inviato con successo");
            }).catch((error) => {
              this.$delegates.snackbar("Errore nell'invio del messaggio");
            })
          }
        }
        this.sendingSms = false
      } else {
        this.$delegates.snackbar("Informazioni errate, impossibile inviare il messaggio");
      }
    },
    sendCustomText() {
      let phoneNumbers = []
      this.sendingSms = true
      
      if(!!this.customers) {
        this.numbers.map((number) => {
          phoneNumbers.push(number)
        })
        for (let i= 0; i < this.customers.length; i++){
          if(!!this.customers[i].sendByWhatsapp || this.whatsapp)
          {
            this.numbers[i] = this.numbers[i].replace("+","")
            smsService.sendWhatsappMessage(this.customTextMessage, this.whatsappNumber, this.numbers[i], this.customers[i].id).then((result) => {
              this.$emit('input')
              this.$delegates.snackbar("Messaggio Whatsapp inviato con successo");
            })
          } else {
            smsService.sendSmsEsendex(this.customTextMessage, phoneNumbers, this.customers[i].id).then((result) => {
              this.$emit('input')
              this.$delegates.snackbar("Sms inviato con successo");
            })
          }
        }
        this.customTextMessage = undefined
        this.sendingSms = false
      } else {
        this.$delegates.snackbar("Informazioni errate, impossibile inviare il messaggio");
        this.sendingSms = false;
      }
    },
    handleEditSmsModel(smsModel) {
      this.editSmsModel = smsModel
      this.editSmsModelDialog = true
    },
    updateSmsModel() {
      this.loadingUpdate = true
      smsModelsService.update(this.editSmsModel).then((models) => {
        this.fetchSmsModels()
        this.editSmsModel = {}
        this.loadingUpdate = false
        this.editSmsModelDialog = false
      })
    },
  },
  computed: {
    smsModelsAndCustomText() {
      return [...this.smsModels, {
        id: '_personalized',
        name: 'Testo Personalizzato',
      }]
    },
    editSmsModelDialogTitle() {
      return !!this.editSmsModel && !!this.editSmsModel.name ? this.editSmsModel.name : 'Nuovo modello'
    },
    countCharString(){
      return this.customTextMessage.length % 160 + '/160 - ' + Math.ceil(this.customTextMessage.length / 160) + ' Messaggi'
    },
    countCharStringColor(){
      return this.customTextMessage.length > 160 ? 'red' : 'black'
    },
  },
  settings: {
    whatsappNumber: {
      bind: 'whatsappNumber'
    },
  }
}
</script>

<style>

</style>