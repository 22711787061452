import authService from "@/services/auth/auth.service";
const paymentTransactionsCollectedRoutes = [
  {
    path: "/payments/paymentsCollected",
    name: "PaymentTransactionsCollectedNewForm",
    component: () => import("@/components/areas/payments/paymentTransactionsCollected/PaymentTransactionsCollectedNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/paymentsCollected/list",
    name: "PaymentTransactionsCollectedList",
    component: () =>
      import(
        "@/components/areas/payments/paymentTransactionsCollected/PaymentTransactionsCollectedList.vue"
      ),
  },
  {
    path: "/payments/paymentsCollectedDetails/list",
    name: "OperatorDetailPaymentTransaction",
    component: () =>
      import(
        "@/components/areas/payments/paymentTransactionsCollected/OperatorDetailPaymentTransactionDialog.vue"
      ),
  },
]

export default paymentTransactionsCollectedRoutes;