<template>
  <FullScreenDialog
    v-model="presetDialog"
    :routeFather="pathToGoBack"
    @input="$emit('update:open-dialog', $event)"
    :can-close="false"
    :can-close-right="true"
  >

    <template v-slot:toolbar-title>
      <div class="d-flex justify-center align-center">
        <div>
          <v-img
            max-width="125"
            src="@/assets/LOGO_LULUU.png"
          ></v-img>
        </div>
        <div class="ml-2 d-flex align-center">
          <span class="title-custom" style="padding-top: 5px">Nuova Campagna</span> 
        </div>
      </div>
    </template>

    <template v-slot:header-actions>
      <v-card flat color="transparent">
        <v-card-text class="text-left">
          <v-row class="justify-end" style="width: 31vw;">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="d-flex align-center flex-wrap"
            >
              <v-row>
                <v-col class="d-flex direction-row flex-wrap justify-end" cols="auto">
                  <v-btn
                    :style="{                          
                      'background-color': status == 'draft' &&
                        (new Date() <= new Date(endDate) || (!startDate && !endDate)) ? '#ffda85' : '',
                      'font-size': '12px'
                    }"
                    class="ml-2 status-div font-weight-bold"
                    :disabled="(status == 'approved' && (!!eventMarketing.actions && !!eventMarketing.actions.hasStarted))"
                    depressed
                    @click="status = 'draft'"
                  >
                    DRAFT
                  </v-btn>
                  <v-btn 
                    :style="{
                        'background-color': status == 'approved' &&
                          (new Date() <= new Date(startDate) || (!startDate && !endDate)) ? '#bfec83' : '',
                        'font-size': '12px'
                      }"
                    class="ml-2 status-div font-weight-bold"
                    :disabled="(status == 'approved' && (!!eventMarketing.actions && !!eventMarketing.actions.hasStarted))"
                    depressed                  
                    @click="status = 'approved'"
                  >
                    APPROVATO
                  </v-btn>
                  <div 
                    :style="{
                        'background-color': status == 'approved' &&
                          new Date() >= new Date(startDate) && 
                          new Date() <= new Date(endDate) 
                           ? '#9abcea' : '',
                        'font-size': '12px',
                        'padding': '6px 16px 6px 16px'
                      }"
                    class="ml-2 status-div font-weight-bold"
                    plain
                  >
                    IN CORSO
                  </div>
                  <div 
                    :style="{
                        'background-color': status == 'draft' &&
                          new Date() > new Date(endDate) ? 'grey' : 
                          status == 'approved' &&
                          new Date() > new Date(endDate) ? '#cccccc' : 
                          '',
                        'font-size': '12px',
                        'padding': '6px 16px 6px 16px'
                      }"
                    class="ml-2 status-div font-weight-bold"
                  >
                    TERMINATO
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <template v-slot:content>
      <EventMarketingGeneralForm
        v-model="eventMarketing"
        :start-date="startDate"
        :end-date="endDate"
        :valid.sync="formValid"
        :type="type"
        @update:img="handleSelectedImg"
      ></EventMarketingGeneralForm>
    </template>

    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        :loading="loading"
        @click="closeDialog"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :loading="loading"
        :disabled="!formValid"
        @click="submitForm"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import Vue from 'vue'

import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import EventMarketingGeneralForm from "@/components/areas/marketing/events/EventMarketingGeneralForm.vue";

import marketingEventsService from "@/services/marketingEvents/marketingEvents.service.js";


export default {
  name: "EventMarketingNewForm",
  components: {
    FullScreenDialog,
    EventMarketingGeneralForm
  },
  data: function() {
    return {
      selectedImg: null,
      presetDialog: this.openDialog,
      formValid: false,
      loading: false,
      eventMarketing: {},
      bus: new Vue(),
      id: undefined,
      status: 'draft',
      statusOptions: [
        {
          value: 'draft',
          text: 'Draft'
        }, 
        {
          value: 'approved',
          text: 'Approvato'
        },
      ],
    };
  },
  mounted() {
    this.id = this.$route.params.id
    if (!!this.id) {
      this.fetchEvent()
    }
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/marketing/calendar"
    },
    pathName: {
      type: String,
    },
    startDate: {
      type: Date,
      default: () => undefined,
    },
    endDate: {
      type: Date,
      default: () => undefined,
    },
    type: {
      type: String,
      default: 'new'
    },
    filters: {
      type: Object,
    }
  },
  methods: {
    handleSelectedImg(img) {
      this.selectedImg = img
    },
    calculateEventColor() {
      const today = new Date()
      
      if (this.status == 'draft') {
        if (today > this.endDate)
          return 'grey'

        return '#ffda85'
      }

      if (this.status == 'approved') {
        if (today > this.endDate)
          return '#cccccc'

        if (today >= this.startDate  && today <= this.endDate) {
          return '#9abcea'
        }

        return '#bfec83'
      }

      return 'red'
    },
    submitForm() {
      console.log('selectedImg', this.selectedImg)
      this.loading = true
      this.eventMarketing.status = this.status
      this.eventMarketing.selectedImageId = !!this.selectedImg ? this.selectedImg.id : undefined
      this.eventMarketing.imageFromLocalSchema = !!this.selectedImg ? this.selectedImg.isUserUploaded : false

      if (this.type == 'new') {
        marketingEventsService.create(this.eventMarketing).then(() => {
          this.loading = false
          this.closeDialog()
        })
      }

      if (this.type == 'createFrom') {
        let objectToCreate = {
          ...this.eventMarketing,
          fromMarketingEvent: {
            id: this.eventMarketing.id
          }
        }
        delete objectToCreate.id
        
        marketingEventsService.createFrom(objectToCreate).then(() => {
          this.loading = false
          this.closeDialog()
        })
      }

      if (this.type == 'duplicateFrom') {
        let objectToCreate = {
          from: this.eventMarketing.from,
          to: this.eventMarketing.to,
          status: this.eventMarketing.status,
          fromMarketingEvent: {
            id: this.eventMarketing.id
          },
          selectedImageId: this.eventMarketing.selectedImageId,
        }

        marketingEventsService.duplicate(objectToCreate).then(() => {
          this.loading = false
          this.closeDialog()
        })

      }
    },
    updateObject() {

    },
    fetchEvent() {
      marketingEventsService.get(this.id).then((result) => {
        if (this.type == 'createFrom' && !!result.actions && !!result.actions.landingPage && !!result.actions.landingPage.blocks) {
          for(let i = 0; i < result.actions.landingPage.blocks.length; i += 1) {
            if(!!result.actions.landingPage.blocks[i].image) {
              result.actions.landingPage.blocks[i].copyImageUrl = result.actions.landingPage.blocks[i].image
              delete result.actions.landingPage.blocks[i].image
            }
          }
        }
        this.eventMarketing = result
        if (this.type == 'createFrom' || this.type == 'duplicateFrom') {
          delete this.eventMarketing.from
          delete this.eventMarketing.to
          delete this.eventMarketing.actions.hasStarted
          this.eventMarketing.status = 'draft'
        }
      })
    },
    closeDialog() {
      if(!!this.pathName) {
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      } else {
        this.$router.push({path:this.pathToGoBack})
      }
    }
  },
  watch: {
    openDialog(newVal) {
      this.presetDialog = newVal;
    },
  },
  computed: {
  }
};
</script>

<style>
  .title-custom {
    height: 2em;
    padding-right: 1em;
    padding-left: 0.4em;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background-color: var(--background-color);
    font-size: 1.3rem;
    color: #8a77e2;
    font-weight: 500;
    text-align: center;
    text-transform: none;
  }
</style>