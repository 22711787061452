<template>
  <div id="content-to-print">
    <TitleWithBackButton
      title="Stato Magazzino"
      back-route="Analytics"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <div class="d-flex align-center ml-3 mt-3 pr-5">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="calculate"
        :loading="loading"
      >Calcola</v-btn>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="print"
        :loading="loading || printLoading"
      ><v-icon> mdi-printer </v-icon></v-btn>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
    </div>
    <div class="pa-4 mt-5">
      <v-row class="d-flex align-center">
        <v-col>
          <v-row class="d-flex justify-center">
            <PieChart 
            :chart-data="level1ChartData" 
            :styles="{
              height: '300px',
              width: '300px'
            }" 
            :options="{
              mainAspectRatio: true,
              responsive: true,
              legend: {
                display: false
              },
              showTooltips: false,
            }"
          >
            </PieChart>
          </v-row>
          <v-row class="pt-2 d-flex justify-center">
            <TypeDataTable 
              class="mb-10" 
              :show-tooltips="true" 
              fixed-headers
              headersColor="transparent" 
              style="background-color: transparent;"
              :tooltip-delay="1500" 
              :headers="[
                { text: '', value: 'color', type: 'color' },
                { text: '', value: 'name' },
                { text: 'N° Pezzi', value: 'qty' },
                { text: '% N° Pezzi', value: 'percentageQty' },
                { text: 'Valore €', value: 'total', type: 'price' },
                { text: '% Valore €', value: 'percentageTotal' },
              ]" 
              :items="level1TableData" 
              :show-select="false"
              :select-on-row-click="false" 
              :show-actions="false" 
              :disablePagination="true"
            >
            </TypeDataTable>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex">
        <v-col 
          v-for="(level2ChartData, idx) in level2ChartDatas" 
          :key="idx"
          cols="12"
          sm="12"
          md="6" 
          lg="6"
          :xl="Math.min(4, Math.round(12 / level2ChartDatas.length))"
        >
          <v-row class="d-flex justify-center mb-2" style="font-size: 1.3rem">
            {{ level2Names[idx] }} 
          </v-row>
          <v-row class="d-flex justify-center">
            <PieChart 
            :chart-data="level2ChartData" 
            :styles="{
              height: '300px',
              width: '300px'
            }" 
            :options="{
              mainAspectRatio: true,
              responsive: true,
              legend: {
                display: false
              },
              showTooltips: false,
            }"
          >
            </PieChart>
          </v-row>
          <v-row class="pt-2 d-flex justify-center">
            <TypeDataTable 
              class="mb-10" 
              :show-tooltips="true" 
              fixed-headers
              headersColor="transparent" 
              style="background-color: transparent;"
              :tooltip-delay="1500" 
              :headers="[
                { text: '', value: 'color', type: 'color' },
                { text: '', value: 'name' },
                { text: 'N° Pezzi', value: 'qty' },
                { text: '% N° Pezzi', value: 'percentageQty' },
                { text: 'Valore €', value: 'total', type: 'price' },
                { text: '% Valore €', value: 'percentageTotal' },
              ]" 
              :items="level2TableDatas[idx]" 
              :show-select="false"
              :select-on-row-click="false" 
              :show-actions="false" 
              :disablePagination="true"
            >
            </TypeDataTable>
          </v-row>
          <v-expansion-panels popout flat multiple class="mb-5">
            <v-expansion-panel>
              <v-expansion-panel-header class="d-flex justify-center" style="font-size: 1.3rem">
                Uso Interno - {{ level2Names[idx] }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <GroupBasedChart 
                    height="600px" 
                    width="100%"
                    type="horizontalBar"
                    transparent
                    :colors="level3InternalColors[idx]"
                    :point-colors="[]"
                    :getYValue="
                      (object) => {
                        return object[`y`];
                      }
                    "
                    :getXValue="
                      (object) => {
                        return object[`x`];
                      }
                    "
                    :tooltip-label-modifier="
                      (tooltipLabel) => {
                        return 'Valore: ' + Number(tooltipLabel.xLabel).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) ;
                      }
                    "
                    :additional-y-axes-modifier="yAxes[0]"
                    :data="[level3InternalChartDatas[idx]]"
                  >
                  </GroupBasedChart>
                </v-row>
                <v-row>
                  <TypeDataTable 
                    class="mb-10" 
                    :show-tooltips="true" 
                    fixed-headers
                    headersColor="transparent" 
                    style="background-color: transparent;"
                    :tooltip-delay="1500" 
                    :headers="[
                      { text: '', value: 'color', type: 'color' },
                      { text: '', value: 'name' },
                      { text: 'N° Pezzi', value: 'qty' },
                      { text: '% N° Pezzi', value: 'percentageQty' },
                      { text: 'Valore €', value: 'total', type: 'price' },
                      { text: '% Valore €', value: 'percentageTotal' },
                    ]" 
                    :items="level3InternalTableDatas[idx]" 
                    @rowClicked="row => handleSelectLevel4(row, idx)"
                    :show-select="false"
                    :select-on-row-click="false" 
                    :show-actions="false" 
                    :disablePagination="true"
                    dense
                  >
                  </TypeDataTable>
                </v-row>
                <v-row>
                  <TypeDataTable 
                    :loading="reload"
                    class="mt-10" 
                    :show-tooltips="true" 
                    fixed-headers
                    headersColor="transparent" 
                    style="background-color: transparent;"
                    :tooltip-delay="1500" 
                    :headers="[
                      { text: '', value: 'name' },
                      { text: 'N° Pezzi', value: 'qty' },
                      { text: '% N° Pezzi', value: 'percentageQty' },
                      { text: 'Valore €', value: 'total', type: 'price' },
                      { text: '% Valore €', value: 'percentageTotal' },
                    ]" 
                    :items="getLevel4TableData(level2DepartmentIds[idx], selectedSupplierIds[idx], true)" 
                    :show-select="false"
                    :select-on-row-click="false" 
                    :show-actions="false" 
                    :disablePagination="true"
                    dense
                  >
                  </TypeDataTable>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="d-flex justify-center" style="font-size: 1.3rem">
                Retail - {{ level2Names[idx] }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <GroupBasedChart 
                    height="600px" 
                    width="100%"
                    type="horizontalBar"
                    transparent
                    :colors="level3RetailColors[idx]"
                    :point-colors="[]"
                    :getYValue="
                      (object) => {
                        return object[`y`];
                      }
                    "
                    :getXValue="
                      (object) => {
                        return object[`x`];
                      }
                    "
                    :tooltip-label-modifier="
                      (tooltipLabel) => {
                        return 'Valore: ' + Number(tooltipLabel.xLabel).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) ;
                      }
                    "
                    :additional-y-axes-modifier="yAxes[0]"
                    :data="[level3RetailChartDatas[idx]]"
                  >
                  </GroupBasedChart>
                </v-row>
                <v-row>
                  <TypeDataTable 
                    class="mb-10" 
                    :show-tooltips="true" 
                    fixed-headers
                    headersColor="transparent" 
                    style="background-color: transparent;"
                    :tooltip-delay="1500" 
                    :headers="[
                      { text: '', value: 'color', type: 'color' },
                      { text: '', value: 'name' },
                      { text: 'N° Pezzi', value: 'qty' },
                      { text: '% N° Pezzi', value: 'percentageQty' },
                      { text: 'Valore €', value: 'total', type: 'price' },
                      { text: '% Valore €', value: 'percentageTotal' },
                    ]" 
                    :items="level3RetailTableDatas[idx]" 
                    @rowClicked="row => handleSelectLevel4(row, idx)"
                    :show-select="false"
                    :select-on-row-click="false" 
                    :show-actions="false" 
                    :disablePagination="true"
                    dense
                  >
                  </TypeDataTable>
                </v-row>
                <v-row>
                  <TypeDataTable 
                    :loading="reload"
                    class="mb-10" 
                    :show-tooltips="true" 
                    fixed-headers
                    headersColor="transparent" 
                    style="background-color: transparent;"
                    :tooltip-delay="1500" 
                    :headers="[
                      { text: '', value: 'name' },
                      { text: 'N° Pezzi', value: 'qty' },
                      { text: '% N° Pezzi', value: 'percentageQty' },
                      { text: 'Valore €', value: 'total', type: 'price' },
                      { text: '% Valore €', value: 'percentageTotal' },
                    ]" 
                    :items="getLevel4TableData(level2DepartmentIds[idx], selectedSupplierIds[idx], false)" 
                    :show-select="false"
                    :select-on-row-click="false" 
                    :show-actions="false" 
                    :disablePagination="true"
                    dense
                  >
                  </TypeDataTable>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import PieChart from "@/components/charts/PieChart";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import colorConstrast from "@/mixins/common/colorContrast"
import balanceService from '@/services/balance/balance.service.js'
import html2canvas from '@/assets/js/html2canvas.min.js'
import _ from "lodash";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import * as XLSX from "xlsx";

export default {
  name: "WarehouseStatus",
  components: {
    TitleWithBackButton,
    TagsAutocomplete,
    PieChart,
    GroupBasedChart,
    TypeDataTable,
    ExportExcelTable
  },
  mixins: [colorConstrast],
  data: function() {
    return {
      selectedSupplierIds: [],
      loading: false,
      printLoading: false,
      rows: [],
      level1ChartData: {},
      level1TableData: [],
      level2Names: [],
      level2DepartmentIds: [],
      level2ChartDatas: [{}],
      level2TableDatas: [[]],
      level3InternalChartDatas: [{}],
      level3InternalTableDatas: [[]],
      level3RetailChartDatas: [{}],
      level3RetailTableDatas: [[]],
      level3InternalColors: [[]],
      level3RetailColors: [[]],
      level4GroupedDatas: {},
      reload: false,
      colorSet: ['#F7B9249c', '#96B4379c', '#3EA05E9c', '#0085769c', '#0067749c', '#2F48589c'],
      alternativeColorSet: ['#ff595e9c', '#ff924c9c', '#ffca3a9c', '#c5ca309c', '#8ac9269c', '#36949d9c', '#1982c49c', '#4267ac9c', '#565aa09c', '#6a4c939c'],
      yAxes: [{
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          callback: function(label) {
            if(label.length > 11) {
              return label.substring(0, 8) + '...'
            } else {
              return label
            }
          },
        },
      }],
      headers: [
        { text: '', value: 'name' },
        { text: '€', value: 'total', type: 'price' },
        { text: 'N°', value: 'count' },
        { text: '% su N°Servizi', value: 'onServices' },
        { text: '% su N°Clienti', value: 'onCustomers' },
        { text: '% su N°Transazioni', value: 'onTransactions' },
        { text: '% su €', value: 'onTotal' },
      ],
      fileName: "",
      level4AllDatas: [],
      completeMagazineStatusList: []
    }
  },
  async mounted() {
    this.calculate()
  },
  methods: {
    exportToExcel() {
      // Export the tables to excel

      let workbook = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.level1TableData.map((element) => {
        return {
            " ": element.newName,
            "N° pezzi": element.qty,
            "% N° pezzi": element.percentageQty,
            "Valore €": element.total,
            "% Valore €": element.percentageTotal
          }  
      }));
      let level2Datas = []
      for (let i = 0; i < this.level2TableDatas.length; i++) {
        for (let j = 0; j < this.level2TableDatas[i].length; j++) {
          level2Datas.push(this.level2TableDatas[i][j]); 
        }        
      }
      var ws2 = XLSX.utils.json_to_sheet(level2Datas.map((element) => {
        return {
            " ": element.newName,
            "N° pezzi": element.qty,
            "% N° pezzi": element.percentageQty,
            "Valore €": element.total,
            "% Valore €": element.percentageTotal,
            "Reparto": element.departmentName
          }  
      }));
      let level3Datas = []
      for (let i = 0; i < this.level3InternalTableDatas.length; i++) {
        for (let j = 0; j < this.level3InternalTableDatas[i].length; j++) {
          level3Datas.push(this.level3InternalTableDatas[i][j]); 
        }        
      }
      for (let i = 0; i < this.level3RetailTableDatas.length; i++) {
        for (let j = 0; j < this.level3RetailTableDatas[i].length; j++) {
          level3Datas.push(this.level3RetailTableDatas[i][j]); 
        }        
      }
      var ws3 = XLSX.utils.json_to_sheet(level3Datas.map((element) => {
        return {
            " ": element.name,
            "N° pezzi": element.qty,
            "% N° pezzi": element.percentageQty,
            "Valore €": element.total,
            "% Valore €": element.percentageTotal,
            "Reparto": element.departmentName,
            "Uso Interno/Retail": element.usageTypeName
          }  
      }));
      var ws4 = XLSX.utils.json_to_sheet(this.level4AllDatas.map((element) => {
        return {
            " ": element.newName,
            "N° pezzi": element.qty,
            "% N° pezzi": element.percentageQty,
            "Valore €": element.total,
            "% Valore €": element.percentageTotal,
            "Reparto": element.departmentName,
            "Uso Interno/Retail": element.usageTypeName,
            "Categoria": element.supplierName
          }  
      }));
      XLSX.utils.book_append_sheet(workbook, ws, "Level 1");
      XLSX.utils.book_append_sheet(workbook, ws2, "Level 2");
      XLSX.utils.book_append_sheet(workbook, ws3, "Level 3");
      XLSX.utils.book_append_sheet(workbook, ws4, "Level 4");
      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    async calculate(){
      this.loading = true
      let res = await balanceService.warehouseStatus()

      this.level1ChartData = {
        labels: ['Uso interno', 'Retail'],
        datasets: [
          {
            label: 'Label',
            pointBackgroundColor: 'black',
            borderWidth: 2,
            pointBorderColor: 'black',
            data: [Number(res.level1.totalInternal).toFixed(2), Number(res.level1.totalRetail).toFixed(2)],
            backgroundColor: [this.colorSet[0], this.colorSet[3]],
          }
        ]
      }

      let total = Number(res.level1.totalInternal) + Number(res.level1.totalRetail)
      let totalQty = Number(res.level1.totalQtyInternal) + Number(res.level1.totalQtyRetail)
      this.level1TableData = [
        {
          color: undefined,
          name: 'Totale',
          qty: Number(totalQty).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
          percentageQty: '100,00%',
          total: total,
          percentageTotal: '100,00%',
        },
        {
          color: this.colorSet[0],
          name: 'Uso interno',
          qty: Number(res.level1.totalQtyInternal).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
          percentageQty: (res.level1.totalQtyInternal / totalQty * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          total: res.level1.totalInternal,
          percentageTotal: (res.level1.totalInternal / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        },
        {
          color: this.colorSet[3],
          name: 'Retail',
          qty: Number(res.level1.totalQtyRetail).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
          percentageQty: (res.level1.totalQtyRetail / totalQty * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          total: res.level1.totalRetail,
          percentageTotal: (res.level1.totalRetail / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        }
      ]

      this.level2ChartDatas = []
      this.level2TableDatas = []
      this.level2Names = []
      this.level2DepartmentIds = []
      this.level3InternalChartDatas = []
      this.level3InternalTableDatas = []
      this.level3RetailChartDatas = []
      this.level3RetailTableDatas = []
      this.level3InternalColors = []
      this.level3RetailColors = []
      this.selectedSupplierIds = []

      for(let level2 of res.level2){
        let departmentId = level2.departmentId
        this.level2Names.push(level2.departmentName)
        this.level2DepartmentIds.push(departmentId)
        this.selectedSupplierIds.push(undefined)

        let level2ChartData = {
          labels: ['Uso interno', 'Retail'],
          datasets: [
            {
              label: 'Label',
              pointBackgroundColor: 'black',
              borderWidth: 2,
              pointBorderColor: 'black',
              data: [Number(level2.totalInternal).toFixed(2), Number(level2.totalRetail).toFixed(2)],
              backgroundColor: [this.colorSet[0], this.colorSet[3]],
            }
          ]
        }
        this.level2ChartDatas.push(level2ChartData)
        
        let total = Number(level2.totalInternal) + Number(level2.totalRetail)
        let totalQty = Number(level2.totalQtyInternal) + Number(level2.totalQtyRetail)
        let level2TableData = [
          {
            color: undefined,
            name: 'Totale',
            qty: Number(totalQty).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
            percentageQty: '100,00%',
            total: total,
            percentageTotal: '100,00%',
          },
          {
            color: this.colorSet[0],
            name: 'Uso interno',
            qty: Number(level2.totalQtyInternal).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
            percentageQty: !totalQty ? '0,00%' : (level2.totalQtyInternal / totalQty * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            total: level2.totalInternal,
            percentageTotal: !total ? '0,00%' : (level2.totalInternal / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          },
          {
            color: this.colorSet[3],
            name: 'Retail',
            qty: Number(level2.totalQtyRetail).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
            percentageQty: !totalQty ? '0,00%' : (level2.totalQtyRetail / totalQty * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            total: level2.totalRetail,
            percentageTotal: !total ? '0,00%' : (level2.totalRetail / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          }
        ]
        this.level2TableDatas.push(level2TableData)

        let level3Datas = res.level3.filter((level3) => level3.departmentId == departmentId)
        let level3InternalDatas = level3Datas.filter((level3) => level3.isInternal)
        let level3RetailDatas = level3Datas.filter((level3) => !level3.isInternal)

        let level3InternalChartData = {
          name: level2.departmentName + ' - Uso interno',
          label: level2.departmentName + ' - Uso interno',
          data: [],
        }
        let colors = []
        for(let i = 0; i < level3InternalDatas.length; i++){
          colors.push(this.alternativeColorSet[i % this.alternativeColorSet.length])
          level3InternalChartData.data.push({
            x: level3InternalDatas[i].supplierName,
            y: Number(level3InternalDatas[i].total),
          })
        }
        level3InternalChartData.data.sort((a, b) => {
          return b.y - a.y
        })
        level3InternalChartData.data = level3InternalChartData.data.filter((data, idx) => {
          if(idx >= 10)
            return Number(data.y) > 0
          return true
        })

        let additionalRows = 10 - level3InternalChartData.data.length
        for(let i = 0; i < additionalRows; i++){
          level3InternalChartData.data.push({
            x: 'Vuoto' + i,
            y: 0,
          })
        }

        this.level3InternalChartDatas.push(level3InternalChartData)
        this.level3InternalColors.push(colors)

        let level3InternalTableData = []
        totalQty = _.sumBy(level3InternalDatas, (elem) => Number(elem.totalQty))
        total = _.sumBy(level3InternalDatas, (elem) => Number(elem.total))
        for(let i = 0; i < level3InternalDatas.length; i++){
          level3InternalTableData.push({
            id: level3InternalDatas[i].supplierId,
            name: level3InternalDatas[i].supplierName,
            qty: Number(level3InternalDatas[i].totalQty).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
            percentageQty: !totalQty ? '0,00%' : (level3InternalDatas[i].totalQty / totalQty * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            total: Number(level3InternalDatas[i].total),
            percentageTotal: !total ? '0,00%' : (level3InternalDatas[i].total / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          })
        }
        level3InternalTableData.sort((a, b) => {
          return b.total - a.total
        })
        level3InternalTableData.map((data, idx) => {
          data.color = this.alternativeColorSet[idx % this.alternativeColorSet.length]
        })

        this.level3InternalTableDatas.push(level3InternalTableData)

        let level3RetailChartData = {
          name: level2.departmentName + ' - Retail',
          label: level2.departmentName + ' - Retail',
          data: [],
        }
        colors = []
        for(let i = 0; i < level3RetailDatas.length; i++){
          colors.push(this.alternativeColorSet[i % this.alternativeColorSet.length])
          level3RetailChartData.data.push({
            x: level3RetailDatas[i].supplierName,
            y: Number(level3RetailDatas[i].total),
          })
        }
        level3RetailChartData.data.sort((a, b) => {
          return b.y - a.y
        })
        level3RetailChartData.data = level3RetailChartData.data.filter((data, idx) => {
          if(idx >= 10)
            return Number(data.y) > 0
          return true
        })

        additionalRows = 10 - level3RetailChartData.data.length
        for(let i = 0; i < additionalRows; i++){
          level3RetailChartData.data.push({
            x: 'Vuoto' + i,
            y: 0,
          })
        }

        this.level3RetailChartDatas.push(level3RetailChartData)
        this.level3RetailColors.push(colors)

        let level3RetailTableData = []        
        totalQty = _.sumBy(level3RetailDatas, (elem) => Number(elem.totalQty))
        total = _.sumBy(level3RetailDatas, (elem) => Number(elem.total))
        for(let i = 0; i < level3RetailDatas.length; i++){
          level3RetailTableData.push({
            id: level3RetailDatas[i].supplierId,
            name: level3RetailDatas[i].supplierName,
            qty: Number(level3RetailDatas[i].totalQty).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
            percentageQty: !totalQty ? '0,00%' : (level3RetailDatas[i].totalQty / totalQty * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            total: Number(level3RetailDatas[i].total),
            percentageTotal: !total ? '0,00%' : (level3RetailDatas[i].total / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          })
        }
        level3RetailTableData.sort((a, b) => {
          return b.total - a.total
        })
        level3RetailTableData.map((data, idx) => {
          data.color = this.alternativeColorSet[idx % this.alternativeColorSet.length]
        })

        this.level3RetailTableDatas.push(level3RetailTableData)
      }

      this.level4GroupedDatas = _.groupBy(res.level4, (item) => item.departmentId + '_' + item.supplierId + '_' + item.isInternal)

      this.loading = false

      this.completeMagazineStatusList = []
      for (let i = 0; i < this.level1TableData.length; i++) {
        let element = {}
        this.level1TableData[i].total = parseFloat(Number(this.level1TableData[i].total).toFixed(2))
        element = this.level1TableData[i]
        element.newName = element.name
        
        // AGGIUNTA DEL LIVELLO 1
        this.completeMagazineStatusList.push(element)        
      }
      for (let j = 0; j < this.level2TableDatas.length; j++) {
        if (this.level2TableDatas[j].length > 0) {
          for (let k = 0; k < this.level2TableDatas[j].length; k++) {
            let element = {}
            if (!!this.level2TableDatas[j][k].total) {
              this.level2TableDatas[j][k].total = parseFloat(Number(this.level2TableDatas[j][k].total).toFixed(2))
              element = this.level2TableDatas[j][k]
              element.departmentName = this.level2Names[j]
              element.newName = element.name
              // AGGIUNTA DEL LIVELLO 2      
              this.completeMagazineStatusList.push(element)
            }
            
          }
        } else {
          if (!!this.level2TableDatas[j].total)
            this.level2TableDatas[j].total = parseFloat(Number(this.level2TableDatas[j].total).toFixed(2))
          this.completeMagazineStatusList.push(this.level2TableDatas[j])
        }
      }
      for (let j = 0; j < this.level3InternalTableDatas.length; j++) {
        if (this.level3InternalTableDatas[j].length > 0) {
          for (let k = 0; k < this.level3InternalTableDatas[j].length; k++) { 
            let element = {}
            if (!!this.level3InternalTableDatas[j][k].total) {
              this.level3InternalTableDatas[j][k].total = parseFloat((Number(this.level3InternalTableDatas[j][k].total)).toFixed(2))              
              element = this.level3InternalTableDatas[j][k]
              element.departmentName = this.level2Names[j]
              element.usageTypeName = "USO INTERNO"
              element.newName = element.name
              // AGGIUNTA DEL LIVELLO 3 INTERNAL    
              this.completeMagazineStatusList.push(element)
            }
          }
        } else {
          if (!!this.level3InternalTableDatas[j].total)
            this.level3InternalTableDatas[j].total = parseFloat((Number(this.level3InternalTableDatas[j].total)).toFixed(2))
          this.completeMagazineStatusList.push(this.level3InternalTableDatas[j])
        }
      }
      for (let j = 0; j < this.level3RetailTableDatas.length; j++) {        
        if (this.level3RetailTableDatas[j].length > 0) {
          for (let k = 0; k < this.level3RetailTableDatas[j].length; k++) {   
            let element = {}
            if (!!this.level3RetailTableDatas[j][k].total) {
              this.level3RetailTableDatas[j][k].total = parseFloat((Number(this.level3RetailTableDatas[j][k].total)).toFixed(2))
              element = this.level3RetailTableDatas[j][k]
              element.departmentName = this.level2Names[j]
              element.usageTypeName = "RETAIL"
              element.newName = element.name
              // AGGIUNTA DEL LIVELLO 3 RETAIL    
              this.completeMagazineStatusList.push(element)
            }
          }
        } else {
          if (!!this.level3RetailTableDatas[j].total)
            this.level3RetailTableDatas[j].total = parseFloat((Number(this.level3RetailTableDatas[j].total)).toFixed(2))
          this.completeMagazineStatusList.push(this.level3RetailTableDatas[j])
        }
      }
      Object.values(this.level4GroupedDatas).forEach(gd => {
        // AGGIUNTA DEL LIVELLO 4
        if(gd.length > 0) {
          for (let i = 0; i < gd.length; i++) {
            let listedGd = this.getLevel4TableData(gd[i].departmentId, gd[i].supplierId, gd[i].isInternal)
            if (!!gd[i].total) {
              for (let j = 0; j < listedGd.length; j++) {                
                if(gd[i].tagName === listedGd[j].name) {
                  gd[i].percentageQty = listedGd[j].percentageQty
                  gd[i].percentageTotal = listedGd[j].percentageTotal     
                }
                else if(gd[i].tagName === null && listedGd[j].name === "Nessuna categoria") {
                  gd[i].percentageQty = listedGd[j].percentageQty
                  gd[i].percentageTotal = listedGd[j].percentageTotal   
                }                
              }
              if (gd[i].isInternal){
                if(!!gd[i].tagName)
                  gd[i].newName = gd[i].tagName
                else
                  gd[i].newName = "Nessuna Categoria"
                gd[i].usageTypeName = "USO INTERNO"
              }
              else {
                if(!!gd[i].tagName)
                  gd[i].newName = gd[i].tagName
                else
                  gd[i].newName = "Nessuna Categoria"
                gd[i].usageTypeName = "RETAIL"
              }
              gd[i].qty = gd[i].totalQty
              gd[i].total = parseFloat((Number(gd[i].total)).toFixed(2))
            }
            this.level4AllDatas.push(gd[i])
            this.completeMagazineStatusList.push(gd[i])            
          }
        }
      });
      console.log({"Tutti i costi": this.completeMagazineStatusList})

      let formattedDate = new Date()
      formattedDate = formattedDate.toLocaleDateString('it-IT')
      this.fileName = "Stato-Magazzino_" + formattedDate.toString() + ".xls";
    },
    getLevel4TableData(departmentId, supplierId, isInternal){
      let level4Datas = this.level4GroupedDatas[departmentId + '_' + supplierId + '_' + isInternal]
      let level4TableData = []

      if(!level4Datas)
        return level4TableData

      let totalQty = 0
      let total = 0
      for(let i = 0; i < level4Datas.length; i++){
        totalQty += Number(level4Datas[i].totalQty)
        total += Number(level4Datas[i].total)
      }

      for(let i = 0; i < level4Datas.length; i++){
        let level4 = level4Datas[i]
        level4TableData.push({
          color: this.colorSet[i % this.colorSet.length],
          name: level4.tagName || 'Nessuna categoria',
          qty: Number(level4.totalQty).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 }),
          percentageQty: !totalQty ? '0,00%' : (level4.totalQty / totalQty * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          total: Number(level4.total),
          percentageTotal: !total ? '0,00%' : (level4.total / total * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
        })
      }

      level4TableData.sort((a, b) => {
        return b.total - a.total
      })

      return level4TableData
    },
    handleSelectLevel4(row, idx){
      this.selectedSupplierIds[idx] = row.id
      this.reload = true
      let self = this
      setTimeout(() => {
        self.reload = false
      }, 100)
    },
    print() {
      let id = 'content-to-print'
      this.printLoading = true

      let today = new Date()
      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Stato_Magazzino_" 
          + today.getDate() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + today.getFullYear() + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
  }
}
</script>

<style>
 .btn-export {
  background-color: transparent !important;
  color: #000000 !important;
  box-shadow: none;
  transition: ease-in-out .3s;
 }
 .btn-export:hover {
  background-color: rgb(227, 227, 232) !important;
  transition: ease-in-out .3s;
 }
</style>