<template>
  <v-row class="px-6 news-container" >
    <v-col class="column-unset">
      <div style="width:100%;" class="d-flex align-center flex-wrap mobile-mid">
        <v-col class="img-centered-align">
          <v-row class="row-centered-align" style="width:100%; height: 50vh">
            <v-img 
              v-if="!!openedNews.attributes.Notizia[0].Carosello.data[currentSlider].attributes.formats"
              style="max-height: 100%; max-width:100%; border-radius: 12px"
              :src="url + openedNews.attributes.Notizia[0].Carosello.data[currentSlider].attributes.url" 
            >
            </v-img>
            <video v-else  
              controls
              autoplay
              style="max-height: 100%; max-width:100%; border-radius: 12px"
            >
              <source 
                :src="url + openedNews.attributes.Notizia[0].Carosello.data[currentSlider].attributes.url" type="video/mp4"
              >
            </video>
          </v-row> 
          
          <v-row style="width:100%; height: 14vh" class="d-flex justify-center">
            <v-col
              :style="{
                height: Math.ceil(12/openedNews.attributes.Notizia[0].Carosello.data.length) < 5 ? '100%' : '70%',
              }"
              class="d-flex justify-center my-0" v-for="(item, i) in openedNews.attributes.Notizia[0].Carosello.data" :key="i" :cols="Math.ceil(12/openedNews.attributes.Notizia[0].Carosello.data.length)">
              <div 
                style="overflow:hidden;height:100%;border-radius: 12px; max-width: 14vh;" 
                class="d-flex justify-center"
              >
                <v-img  
                  v-if="!!item.attributes.formats"
                  style="cursor:pointer; height: 100%;"
                  @click="handleChangeSlider(i)"
                  :src="url + item.attributes.url" 
                >
                </v-img>
                <video v-else  
                  style="cursor:pointer; height: 100%;"
                  @click="handleChangeSlider(i)"
                >
                  <source 
                    :src="url + item.attributes.url" type="video/mp4"
                  >
                </video>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-col>
    <v-col class="column-unset" style="overflow-y: hidden;">
      <div class="mobile-subtitle text-h4 cyan--text text--darken-2 font-weight-medium text-center">
        {{ openedNews.attributes.Notizia[0].Sottotitolo }}
      </div>
      <div class="mobile-text text-body-1 pt-2" v-html="getMarkdown(openedNews.attributes.Notizia[0].Testo)">

      </div>
      <div class="d-flex justify-center">
        <v-btn large :href="openedNews.attributes.Link" target="_blank" color="primary" elevation="0">
          SCOPRI DI PIÙ
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { marked } from '@/assets/js/marked.min.js'
import UrlKeeper from '@/services/UrlKeeper.js'

export default {
  name: "News",
  components: {
  },
  props:{
    openedNews: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    let url = UrlKeeper.getUrl('strapi')
    return {
      currentSlider: 0,
      url: url
    }
  },
  mounted() {
    console.log(this.openedNews)
  },
  methods: {
    getMarkdown(md){
      console.log("test", md)
      let res = marked(md)
      console.log(res)

      res = res.replace(/<img/g, '<img style="max-width:100%; border-radius: 5px"')

      return res
    },
    callUs(email){
      window.open('mailto:' + email)
    },
    handleChangeSlider(n) {
      this.currentSlider = n
      var length = this.openedNews.attributes.Notizia[0].Carosello.data.length
      if (this.currentSlider > length) {this.currentSlider = 0}
      if (this.currentSlider < 0) {this.currentSlider = length}
    },
  },
  computed: {
  },
  watch: {
  }
};
</script>
<style scoped>
  .news-container {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: 20px;
  }
  .row-centered-align {
    justify-content: center;
  }
  .column-unset {
    height: 75vh;
  }
  .mobile-mid {
    height: 75vh;
  }
  @media screen and (max-width: 600px) {
    .mobile-mid {
      /* max-height: 50vh; */
      height: fit-content;
      flex-direction: column;
    }
    .img-centered-align {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .column-unset {
      flex-basis: unset;
      flex-grow: unset;
      height: fit-content;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .news-container {
      display: flex;
      flex-direction: column;
    }
    .mobile-subtitle {
      font-size: 1.6rem !important;
    }
    .mobile-text {
      font-size: 0.8rem !important;
    }
  }
</style>