const paymentCreditsRoutes = [
  // {
  //   path: "/payments/paymentCredits",
  //   name: "PaymentCreditsNewForm",
  //   component: () => import("@/components/areas/payments/paymentCredits/PaymentCreditsNewForm.vue"),
  //   props: (route) => {
  //     return {
  //       openDialog: true,
  //       pathToGoBack: route.query.pathToGoBack,
  //       tabName: route.query.tabName || 'general'
  //     }
  //   },
  // },
  {
    path: "/payments/paymentCredits/:id/edit",
    name: "PaymentCreditsEditForm",
    component: () => import("@/components/areas/payments/paymentCredits/PaymentCreditsEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/paymentCredits/list",
    name: "PaymentCreditsList",
    component: () =>
      import(
        "@/components/areas/payments/paymentCredits/PaymentCreditsList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default paymentCreditsRoutes;