import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class timeTablesManagment {
  async create(timeTable, turns) {
    const apiUrl = UrlKeeper.getUrl()

    const response = await axios.post(apiUrl + '/presences/timeTables/create', {
      timeTable: timeTable,
      turns: turns
    })

    if(response.success) {
      return response.results
    } else {
      throw response.results
    }
  }

  list() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/timeTables/list', {
        page: 1,
        rowPerPage: 10000
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    });
  }

  setTurns(timeTable, turns) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/timeTables/' + timeTable.id +'/turns/set', turns ).then((response) => {
        if(response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    });
  }

  update(timeTable, turns) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise( async (resolve, reject) => {
      try {
        let { success: success, results: results } = await axios.post(apiUrl + '/presences/timeTables/update', timeTable)
        if (success) {
          if(!!turns) {
            await this.setTurns(results, turns)
            resolve(results)
          } else {
            resolve(results)
          }
        } else {
          reject(results)
        }
      } catch(error) {
        reject(error)
      }
    });
  }

  async delete(timeTable){
    const apiUrl = UrlKeeper.getUrl()
    if(!timeTable || !timeTable.id) throw new Error('time table must be defined')
    
    const response = await axios.get(apiUrl + '/presences/timeTables/delete', {
      id: timeTable.id
    })

    if(response.success) {
      return response.results
    } else {
      throw response.results
    }
  }

}

export default new timeTablesManagment();